import { API_ROUTES } from 'constants/configs'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'

export type FetchCardRaritiesParams = Api.GetCardRarities.RequestQuery
type FetchCardRaritiesResponse = Api.GetCardRarities.ResponseBody

const getCardRaritiesUri = (params?: FetchCardRaritiesParams) => {
  const qs = stringify(params ?? {})
  return [API_ROUTES.cardRarities.root, qs].join('?')
}

export const usePaginatedCardRarities = (queryKey: QueryKey, params?: FetchCardRaritiesParams) => {
  return usePaginatedQuery<FetchCardRaritiesResponse['hydra:member'][0]>(queryKey, getCardRaritiesUri(params))
}
