import {
  FetchPaginatedCollaboratorsResponse,
  getCollaboratorsUri,
  patchCollaborator,
  PatchCollaboratorBody,
  usePaginatedCollaborators
} from 'api/collaborators'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Title2 } from 'components/Title/Title.styles'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { DeleteIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { InfiniteData, useQueryClient } from 'react-query'
import { Collaborator, StoreDetails } from 'types/entities'
import { CollectionResults } from 'types/playinApi'
import { RoleAction } from 'types/playInApiInterfaces'
import { useStoreContext } from '../helper'
import InternalCustomers from './components/InternalCustomers'
import StoreRanges from './components/StoreRanges'
import AddCashboxForm from './form/AddCashboxForm'
import DefaultCashboxDisplay from './form/DefaultCashboxDisplay'
import DefaultCashboxForm from './form/DefaultCashboxForm'
import { ListActions, StoreManageLayout } from './StoreManageTab.style'

interface Props {
  store: StoreDetails
}

export default function StoreManageTab({ store }: Props) {
  const { t } = useTranslation()
  const { checkMatch } = useCheckMatch()

  const collaboratorsQuery = getCollaboratorsUri({ mainStore: `${store.id}`, 'exists[cashboxName]': true })

  const [selectedCollaborators, setSelectedCollaborators] = useState<number[]>([])
  const [deleteModalOpen, toggleDeleteModal] = useState(false)
  const {
    data: collaborators,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItems,
    refetch
  } = usePaginatedCollaborators(['collaboratorsWithCashbox', { store: store.id }], collaboratorsQuery, {
    enabled: !!collaboratorsQuery
  })

  const queryClient = useQueryClient()

  const { mutateAsync: mutateCollaborator, isLoading } = useMutation(
    ({ body, id }: { id: string; body: PatchCollaboratorBody }) => patchCollaborator({ body, id }),
    {
      onSuccess: (_, { id }) => {
        const queryKey = ['collaboratorsWithCashbox', { store: store.id }]
        const prev =
          queryClient.getQueryData<InfiniteData<CollectionResults<FetchPaginatedCollaboratorsResponse>>>(queryKey)

        const pages = prev?.pages.map((page) => ({
          ...page,
          data: page.data.filter((e) => `${e.id}` !== id),
          totalItems: page.totalItems - 1
        }))
        queryClient.setQueryData(queryKey, { ...prev, pages })
      }
    }
  )

  const handleDelete = async () => {
    for (let collaborator of selectedCollaborators) {
      await mutateCollaborator({
        id: `${collaborator}`,
        // @ts-ignore TODO Useless required data in patch
        body: { cashboxName: null }
      })
    }
    toggleDeleteModal(false)
  }

  const { isMyStore } = useStoreContext()

  const columns = _.filter<Column<Collaborator>>(
    [
      {
        key: 'checkbox',
        decorator: (collaborator) => (
          <Cell>
            <Checkbox
              onClick={() => setSelectedCollaborators((prev) => _.xor(prev, [collaborator.id!]))}
              className={classNames({ checked: selectedCollaborators.includes(collaborator.id!) })}
            />
          </Cell>
        )
      },
      {
        key: 'cashboxName',
        name: t('page.stores.details.storeManage.cashbox.listing.cashboxName'),
        decorator: (collaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={collaborator.id}>
            {collaborator.cashboxName}
          </ClickableCell>
        )
      },
      {
        key: 'name',
        name: t('common.label.name'),
        decorator: (collaborator) => (
          <ClickableCell
            route={RouteName.CollaboratorDetails}
            value={collaborator.id}
          >{`${collaborator.lastname} ${collaborator.firstname}`}</ClickableCell>
        )
      }
    ],
    (column) => {
      if (column.key === 'checkbox') {
        return checkMatch([RoleAction.ROLE_ACTION_STORE_EDIT]) && isMyStore
      }
      return true
    }
  )

  return (
    <>
      <StoreManageLayout>
        <div>
          <CardLayout>
            <InternalCustomers store={store} />
          </CardLayout>
          <CardLayout>
            <Title2Tooltip
              id="defaultCashboxTooltip"
              text={
                <Trans
                  i18nKey="page.stores.details.storeManage.tooltips.cashboxDefaultValue"
                  components={{ br: <br /> }}
                />
              }
              title={t('page.stores.details.storeManage.cashbox.cashFundTitle')}
            />
            <CanAccess
              permissions={[RoleAction.ROLE_ACTION_STORE_EDIT]}
              deniedExtraCondition={!isMyStore}
              allowedComponent={<DefaultCashboxForm store={store} />}
              deniedComponent={<DefaultCashboxDisplay store={store} />}
            />
          </CardLayout>
          <CardLayout>
            <Title2>{t('page.stores.details.storeManage.cashbox.title')}</Title2>
            <ShouldHide permissions={[RoleAction.ROLE_ACTION_STORE_EDIT]} deniedExtraCondition={!isMyStore}>
              <AddCashboxForm store={store} callback={refetch} currentCollaborators={collaborators} />
            </ShouldHide>
            <ShouldHide
              permissions={[RoleAction.ROLE_ACTION_STORE_EDIT]}
              deniedExtraCondition={!totalItems || !isMyStore}
            >
              <ListActions>
                <Checkbox
                  onClick={() =>
                    setSelectedCollaborators((prev) =>
                      prev.length === collaborators.length ? [] : collaborators.map((e) => e.id!)
                    )
                  }
                  className={classNames({ checked: selectedCollaborators?.length === collaborators.length })}
                />
                <Button
                  shape="circle"
                  icon={DeleteIcon}
                  variant="white"
                  disabled={!selectedCollaborators.length}
                  onClick={() => toggleDeleteModal(true)}
                />
              </ListActions>
            </ShouldHide>
            <Listing
              data={collaborators}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              columns={columns}
              emptyText={t('page.stores.details.storeManage.cashbox.listing.empty')}
              totalCount={totalItems}
            />
          </CardLayout>
        </div>
        <CardLayout>
          <StoreRanges store={store} />
        </CardLayout>
      </StoreManageLayout>
      <ConfirmModal
        open={deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        callback={handleDelete}
        title={t('page.stores.details.storeManage.cashbox.delete.title', { count: selectedCollaborators.length })}
        closeOnCallback={false}
        confirmBtnProps={{ isLoading }}
      >
        <Listing
          columns={columns.filter((e) => e.key !== 'checkbox')}
          data={collaborators.filter((e) => selectedCollaborators.includes(e.id!))}
        />
      </ConfirmModal>
    </>
  )
}
