import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  gap: 0.5rem;
  height: 20rem;
  width: 25rem;
  border: 1px solid #c4c4c4;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
  background-color: white;
  flex-shrink: 0;
  padding-bottom: 1rem;
  margin-right: 2rem;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 10rem;
`

export const ImageContainer = styled.div<{ backgroundImage: string }>`
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 10rem;
`

export const ImageCardActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`

export const ModalTitle = styled(Title2)`
  position: absolute;
  top: 2rem;
  left: 2rem;
`

export const ModalWrapper = styled.div`
  width: 70rem;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding-top: 5rem;
`

export const FormWrapper = styled.div`
  width: 40rem;
  max-width: 100%;
  display: grid;
  gap: 3.5rem;
`

export const FormLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`

export const ModalImage = styled.img`
  width: 100%;
`

export const DateLabel = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
