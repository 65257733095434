import { DefaultTheme } from 'styled-components'

// and extend them!
declare module 'styled-components' {
  export interface DefaultTheme {
    colors: Colors
    backgroundColors: BackgroundColors
    borderColors: BorderColors
    hoverLinearColors: HoverLinearColors
    linearColors: LinearColors
    buttonBackgroundColors: ButtonBackgroundColors
    buttonBackgroundHoverColors: ButtonBackgroundHoverColors
    textColors: TextColors
    fontWeight: FontWeight
    screenWidth: ScreenWidth
    breakPoints: BreakPoints
    fontStyle: FontStyle
  }
}

export interface Colors {
  white: string
  grey1: string
  grey2: string
  grey3: string
  grey4: string
  grey5: string
  grey6: string
  black: string

  blue1: string
  blue2: string
  blue3: string
  blue4: string
  blue5: string
  blue6: string
  blue7: string
  blue8: string
  blue9: string
  blue10: string

  green1: string
  green2: string
  green3: string
  green4: string

  purple1: string
  purple2: string
  purple3: string
  purple4: string
  purple5: string
  purple6: string

  yellow0: string
  yellow1: string
  yellow2: string
  yellow3: string
  yellow4: string
  yellow5: string
  yellow6: string

  orange0: string
  orange1: string
  orange2: string
  orange3: string
  orange4: string

  red1: string
  red2: string
  red3: string

  danger: string
  default: string
  info: string
  link: string
  secondary: string
  success: string
  warning: string
}

interface BackgroundColors {
  danger: string
  info: string
  link: string
  preorder: string
  secondary: string
  secondary2: string
  cardtrader: string
  cardmarket: string
  sellermania: string
  success: string
  warning: string
  purple: string
  lightPurple: string
}

interface ButtonBackgroundColors {
  white: string
  disabled: string
  success: string
  danger: string
  warning: string
  default: string
}

interface ButtonBackgroundHoverColors {
  white: string
  disabled: string
  success: string
  danger: string
  warning: string
  default: string
}

export interface BorderColors {
  danger: string
  info: string
  link: string
  mysteryBlue: string
  preorder: string
  secondary: string
  secondary2: string
  secondary3: string
  cardtrader: string
  cardmarket: string
  sellermania: string
  success: string
  warning: string
  black: string
}

interface HoverLinearColors {
  danger: string
  info: string
  link: string
  preorder: string
  secondary: string
  cardtrader: string
  cardmarket: string
  sellermania: string
  success: string
  warning: string
}

interface LinearColors {
  danger: string
  info: string
  link: string
  preorder: string
  secondary: string
  cardtrader: string
  cardmarket: string
  sellermania: string
  success: string
  warning: string
}

export interface TextColors {
  danger: string
  info: string
  link: string
  preorder: string
  mysteryBlue: string
  secondary: string
  secondary2: string
  sellermania: string
  cardtrader: string
  cardmarket: string
  success: string
  warning: string
  black: string
  purple: string
  white: string
  darkPurple: string
  yellow: string
}

export interface FontWeight {
  light: number
  regular: number
  medium: number
  semiBold: number
  bold: number
  bolder: number
}

export interface FontStyle {
  normal: string
  italic: string
}
interface ScreenWidth {
  largeScreen: string
  desktopScreen: string
  laptopScreen: string
  tabletScreen: string
  mobileScreen: string
  maxLargeScreen: string
  maxDesktopScreen: string
  maxLaptopScreen: string
  maxTabletScreen: string
  maxMobileScreen: string
  minLargeScreen: string
  minDesktopScreen: string
  minLaptopScreen: string
  minTabletScreen: string
  minMobileScreen: string
}

interface BreakPoints {
  largeScreen: number
  desktopScreen: number
  laptopScreen: number
  tabletScreen: number
  mobileScreen: number
}

const breakPoints: BreakPoints = {
  largeScreen: 1600,
  desktopScreen: 1280,
  laptopScreen: 1024,
  tabletScreen: 780,
  mobileScreen: 480
}

const theme: DefaultTheme = {
  colors: {
    white: '#fff',
    grey1: '#F4F4F4',
    grey2: '#ECECEC',
    grey3: '#E4E4E4',
    grey4: '#C4C4C4',
    grey5: '#B4B4B4',
    grey6: '#545454',
    black: '#000',

    blue1: '#E7FCFF',
    blue2: '#43BBCE',
    blue3: '#17A2B8',
    blue4: '#4FB4E2', // Sellermania
    blue5: '#008ED2',
    blue6: '#138597',
    blue7: '#265A88',
    blue8: '#1b3d89',
    blue9: '#012169',
    blue10: '#01143F',

    green1: '#D5F5E6',
    green2: '#46A478',
    green3: '#198754',
    green4: '#13633E',

    purple1: '#F65E91',
    purple2: ' #E75D89',
    purple3: '#C00375',
    purple4: '#9688B0',
    purple5: ' #30214B',
    purple6: '#190F2C', // Mystery Blue Playin

    yellow0: '#FEF9EB',
    yellow1: '#FAC738',
    yellow2: '#FAB428', // Yellow Playin
    yellow3: '#F0AD4E',
    yellow4: '#FFC34D',
    yellow5: '#FFB217',
    yellow6: '#FFAA00',

    orange0: '#FFC36D',
    orange1: '#EB9316',
    orange2: '#F9964F',
    orange3: '#F86C28',
    orange4: '#FF5E10',

    red1: '#DC3545',
    red2: '#D60115',
    red3: '#BB0314',

    danger: '#DC3545',
    default: '#000',
    info: '#43BBCE',
    link: '#265A88',
    secondary: '#ECECEC',
    success: '#46A478',
    warning: '#EB9316'
  },
  backgroundColors: {
    danger: '#DC3545',
    info: '#43BBCE',
    link: '#265A88',
    preorder: '#FFE2A9',
    secondary: '#F4F4F4',
    secondary2: '#E4E4E4',
    sellermania: '#4FB4E2',
    cardtrader: '#4694B1',
    cardmarket: '#01236E',
    success: '#46A478',
    warning: '#EB9316',
    purple: '#34274E',
    lightPurple: '#CDC8D7'
  },
  borderColors: {
    danger: '#D60115',
    info: '#17A2B8',
    link: '#012169',
    mysteryBlue: '#9688B0',
    preorder: '#FFC36D',
    secondary: '#C4C4C4',
    secondary2: '#E4E4E4',
    secondary3: '#F4F4F4',
    sellermania: '#4FB4E2',
    cardtrader: '#4694B1',
    cardmarket: '#01236E',
    success: '#198754',
    warning: '#F9964F',
    black: '#000'
  },
  hoverLinearColors: {
    danger: 'linear-gradient(180deg, #DC3545 0%, #BB0314 100%)',
    info: 'linear-gradient(180deg, #17A2B8 0%, #0479B1 100%)',
    link: 'linear-gradient(180deg, #1b3d89 0%, #01143F 100%)',
    preorder: 'linear-gradient(180deg, #F9964F 0%, #EB9316 100%)',
    secondary: 'linear-gradient(180deg, #ECECEC 0%, #C4C4C4 100%)',
    sellermania: 'linear-gradient(180deg, #4FB4E2 0%, #138597 100%)',
    cardtrader: 'linear-gradient(180deg, #4694B1 0%, #36738a 100%)',
    cardmarket: 'linear-gradient(180deg, #01236E 0%, #00194f 100%)',
    success: 'linear-gradient(180deg, #46A478 0%, #13633E 100%)',
    warning: 'linear-gradient(180deg, #FFC34D 0%, #FFAA00 100%)'
  },
  linearColors: {
    danger: 'linear-gradient(180deg, #DC3545 0%, #DC3545 0.01%, #D60115 100%)',
    info: 'linear-gradient(180deg, #43BBCE 0%, #17A2B8 100%)',
    link: 'linear-gradient(180deg, #1B3D89 0%, #012169 100%)',
    preorder: 'linear-gradient(180deg, #F9964F 0%, #F86C28 100%)',
    secondary: 'linear-gradient(180deg, #ECECEC 0%, #E4E4E4 100%)',
    sellermania: 'linear-gradient(180deg, #4FB4E2 0%, #17A2B8 100%)',
    cardtrader: 'linear-gradient(180deg, #4694B1 0%, #36738a 100%)',
    cardmarket: 'linear-gradient(180deg, #01236E 0%, #00194f 100%)',
    success: 'linear-gradient(180deg, #46A478 0%, #198754 100%)',
    warning: 'linear-gradient(180deg, #FFC34D 0%, #FFB217 100%)'
  },
  buttonBackgroundColors: {
    white: 'radial-gradient(77.5% 77.5% at 7.5% 87.5%, #FFFFFF 0%, #F4F4F4 49.48%, #FFFFFF 100%)',
    disabled: 'radial-gradient(131.64% 144.42% at -9% 144.42%, #FFFFFF 12.5%, #F4F4F4 41.67%, #C4C4C4 100%)',
    success: 'radial-gradient(119% 128.71% at -3.67% 128.71%, #FFFFFF 0%, #5DE984 27.08%, #38C693 100%)',
    danger:
      'radial-gradient(115.29% 135.85% at 0% 135.85%, #FFFFFF 0%, #FFAEB6 34.38%, #F37581 51.04%, #DC3545 84.38%)',
    warning: 'radial-gradient(108.95% 112.99% at 0% 120.13%, #FFFFFF 0%, #FFE2A9 66.67%, #FFD277 100%)',
    default:
      'radial-gradient(81.32% 121.56% at -3.33% 140.13%, #FFFFFF 0%, #9688B0 40.1%, #5B4F72 63.02%, #3B2E55 100%)'
  },
  buttonBackgroundHoverColors: {
    white: 'radial-gradient(88.27% 100% at 11% 100%, #FFFFFF 0%, #F4F4F4 58.33%, #F4F4F4 100%)',
    disabled: 'radial-gradient(131.64% 144.42% at -9% 144.42%, #FFFFFF 12.5%, #F4F4F4 41.67%, #C4C4C4 100%)',
    success: 'radial-gradient(119% 128.71% at -3.67% 128.71%, #FFFFFF 0%, #5DE984 27.08%, #198E64 100%)',
    danger:
      'radial-gradient(115.29% 135.85% at 0% 135.85%, #FFFFFF 0%, #FFAEB6 34.38%, #F37581 51.04%, #C80F21 84.38%)',
    warning: 'radial-gradient(108.95% 112.99% at 0% 120.13%, #FFFFFF 0%, #FFE2A9 66.67%, #F0A817 100%)',
    default:
      'radial-gradient(81.32% 121.56% at -3.33% 140.13%, #FFFFFF 0%, #9688B0 40.1%, #5B4F72 63.02%, #190F2C 100%)'
  },
  textColors: {
    danger: '#D60115',
    info: '#17A2B8',
    link: '#012169',
    preorder: '#F86C28',
    mysteryBlue: '#190F2C',
    secondary: '#545454',
    secondary2: '#C4C4C4',
    sellermania: '#4FB4E2',
    cardtrader: '#4694B1',
    cardmarket: '#01236E',
    success: '#198754',
    warning: '#F9964F',
    black: '#000',
    white: '#FFF',
    purple: '#9688B0',
    darkPurple: '#5B4F72',
    yellow: '#FAB428'
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    bolder: 900
  },
  fontStyle: {
    normal: 'normal',
    italic: 'italic'
  },
  breakPoints,
  screenWidth: {
    largeScreen: `${breakPoints.largeScreen}px`,
    desktopScreen: `${breakPoints.desktopScreen}px`,
    laptopScreen: `${breakPoints.laptopScreen}px`,
    tabletScreen: `${breakPoints.tabletScreen}px`,
    mobileScreen: `${breakPoints.mobileScreen}px`,
    maxLargeScreen: `max-width : ${breakPoints.largeScreen - 1}px`,
    maxDesktopScreen: `max-width : ${breakPoints.desktopScreen - 1}px`,
    maxLaptopScreen: `max-width : ${breakPoints.laptopScreen - 1}px`,
    maxTabletScreen: `max-width : ${breakPoints.tabletScreen}px`,
    maxMobileScreen: `max-width : ${breakPoints.mobileScreen}px`,

    minLargeScreen: `min-width : ${breakPoints.largeScreen}px`,
    minDesktopScreen: `min-width : ${breakPoints.desktopScreen}px`,
    minLaptopScreen: `min-width : ${breakPoints.laptopScreen}px`,
    minTabletScreen: `min-width : ${breakPoints.tabletScreen + 1}px`,
    minMobileScreen: `min-width : ${breakPoints.mobileScreen + 1}px`
  }
}

export default theme
