import { yupResolver } from '@hookform/resolvers/yup'
import { FetchCustomersQueryParams, getCustomersUri, usePaginatedCustomers } from 'api/customers'
import { editOrder, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import { SearchCustomerButtonWrapper } from 'app/StoreEventDetails/modals/AddCustomerModal/AddCustomerModal.style'
import Button from 'components/Button/Button'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { ModalProps } from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { SortingDirection } from 'types/sorting'
import * as yup from 'yup'
import { CustomerResultsList } from './CustomerEditModal.style'
import SearchCustomerForm, { CustomerSearchQueryInputs } from './SearchCustomerForm'
import SelectableCustomer from './SelectableCustomer'

interface Props extends Omit<ModalProps, 'children'> {
  order?: GetOrderByIdResponse
}

const schema = yup.object().shape({
  customer: yup.string().trim().required()
})

export type FormData = {
  customer?: string
}

export default function EditOrderCustomerForm({ onClose, order }: Props) {
  const { t } = useTranslation()
  const [customerParams, setCustomerParams] = useState<FetchCustomersQueryParams>({})
  const { handleSubmit, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  })
  const watchCustomer = watch('customer')

  const onSearch = (params: CustomerSearchQueryInputs) => {
    setCustomerParams(params)
  }
  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)

  const { mutate } = useMutation((body: FormData) => editOrder(body, order?.id!), {
    onSuccess: (data) => {
      const prev = queryClient.getQueryData<Order>(key)
      queryClient.setQueryData(key, { ...prev, ...data })
    }
  })

  const {
    data: customers,
    isFetched,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching
  } = usePaginatedCustomers(
    ['customers:side-search', customerParams],
    getCustomersUri({
      email: customerParams.email,
      name: [customerParams.lastname, customerParams.firstname].join(' '),
      'order[lastname]': SortingDirection.Asc,
      'order[firstname]': SortingDirection.Asc
    }),
    {
      enabled: !!customerParams.email || !!customerParams.lastname || !!customerParams.firstname,
      onSuccess: (customers) => {
        // Select first search result when there is only one.
        if (customers?.length === 1) setValue('customer', customers?.[0]['@id'])
      }
    }
  )
  const onSubmit = (data: FormData) => {
    onClose()
    mutate(data)
  }

  return (
    <>
      <BlockLayout>
        <SearchCustomerForm onSearch={onSearch} isLoading={isFetching && !isFetchingNextPage} />
      </BlockLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isFetched && customers && customers?.length > 0 && (
          <>
            <CustomerResultsList>
              {customers.map((el) => (
                <SelectableCustomer
                  key={el['@id']}
                  customer={el}
                  onClick={(id) => setValue('customer', id)}
                  selected={watchCustomer === el['@id']}
                />
              ))}
            </CustomerResultsList>
            {hasNextPage && (
              <SearchCustomerButtonWrapper>
                <Button variant="white" isLoading={isFetchingNextPage} onClick={() => fetchNextPage()}>
                  {t('common.button.nextPage')}
                </Button>
              </SearchCustomerButtonWrapper>
            )}
          </>
        )}
        {isFetched && !customers?.length && (
          <BlockLayout>
            <Text color="danger">{t('page.order.detail.customerModal.emptyCustomerText')}</Text>
          </BlockLayout>
        )}
        <Button disabled={!watchCustomer} icon={SaveIcon} buttonType="submit">
          {t('common.label.edit')}
        </Button>
      </form>
    </>
  )
}
