import { Text, TextProps } from 'components/Text/Text.styles'
import { ReactNode } from 'react'
import { DetailsRow } from '../CashboxManagement.style'

interface Props extends TextProps {
  label: string
  children: ReactNode
}
export default function InfoRow({ color, fontStyle, label, children, fontWeight = 'light' }: Props) {
  return (
    <DetailsRow>
      <Text color={color} fontWeight={fontWeight} fontStyle={fontStyle}>
        {label}
      </Text>
      {children}
    </DetailsRow>
  )
}
