import Button from 'components/Button/Button'
import styled from 'styled-components'

export const FormLayout = styled.form`
  display: grid;
  gap: 2rem;
  margin-top: 3rem;
  width: 250px;
  max-width: 95%;
`

export const SubmitButton = styled(Button)`
  width: 100%;
`
