import { reportStockError } from 'api/orderEntries'
import { SplitOrderBody } from 'api/orders'
import Button from 'components/Button/Button'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { ModalButtonWrapper } from 'components/Modal/Modal.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { WarningIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import { useMutation } from 'hooks/useAxiosMutation'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { OrderEntry, OrderEntryItem } from 'types/entities'
import { StyledCell } from '../forms/orderEntriesTable/OrderEntriesTable.styles'
import { DescriptionText } from './BaseModal.style'
import EntryQuantity from './components/EntryQuantity'

interface Props extends ModalProps {
  orderId: number
  orderEntries: OrderEntry[]
  columns: Columns<OrderEntry>
  getCellProperties: (entry: OrderEntry) => any
  callback?: () => any
}

type EntryQty = { id: string; quantity: number }

export default function StockErrorModal({
  open,
  onClose,
  orderId,
  orderEntries,
  columns,
  getCellProperties,
  callback
}: Props) {
  const { t } = useTranslation()
  const [entriesQty, setEntriesQty] = useState<EntryQty[]>([])

  const getQuantity = (id: string): number =>
    entriesQty.find((entry) => entry.id === id)?.quantity ??
    orderEntries.find((entry) => entry['@id'] === id)?.quantity ??
    0

  const handleQuantity = useCallback((entry: OrderEntry, quantity: number) => {
    setEntriesQty((prev) => {
      let entries = [...prev]
      let index = entries.findIndex((prevEntry) => prevEntry.id === entry['@id'])
      if (index >= 0) {
        entries[index] = { ...prev[index], quantity }
      } else {
        entries.push({ id: entry['@id']!, quantity })
      }
      return entries
    })
  }, [])

  const queryClient = useQueryClient()

  const { mutate: reportErrorMutate, isLoading } = useMutation(
    (body: SplitOrderBody) => reportStockError(body, { orderId }),
    {
      onSuccess: (updatedOrder) => {
        queryClient.setQueriesData<OrderEntryItem[]>(['orderEntries', orderId], updatedOrder.entries ?? [])
        onClose()
        if (callback) callback()
      }
    }
  )

  const handleStockError = () => {
    let splitEntries = orderEntries.map((entry) => ({
      orderEntry: entry['@id'],
      quantityToSplit: getQuantity(entry['@id']!)
    }))

    reportErrorMutate({ splitEntries })
  }

  columns = [
    {
      key: 'quantity',
      name: t('common.label.quantity'),
      decorator: (entry) => {
        return (
          <StyledCell className={getCellProperties(entry).classname}>
            <EntryQuantity entry={entry} handleQuantity={handleQuantity} />
          </StyledCell>
        )
      }
    },
    ...columns?.filter((e) => ['cardOrProduct', 'lang'].includes(e.key)),

    {
      key: 'price',
      name: t('common.label.totalUnitPrice'),
      decorator: (entry) => (
        <StyledCell className={getCellProperties(entry).classname}>
          <TextNumber value={entry.paidPrice! * getQuantity(entry['@id']!)} suffix=" €" decimalScale={2} />
        </StyledCell>
      )
    }
  ]

  return (
    <Modal open={open} onClose={onClose}>
      <div className="test">
        <Title2>{t('page.order.detail.modals.stockError.title')}</Title2>
        <DescriptionText color="secondary">{t('page.order.detail.modals.stockError.description')}</DescriptionText>
        <Listing data={orderEntries} columns={columns} />
      </div>
      <ModalButtonWrapper>
        <Button icon={WarningIcon} isLoading={isLoading} onClick={handleStockError}>
          {t('page.order.detail.modals.stockError.button')}
        </Button>
        <Button variant="white" onClick={onClose}>
          {t('common.label.cancel')}
        </Button>
      </ModalButtonWrapper>
    </Modal>
  )
}
