import angleUpSvg from 'assets/svg/icon/angle-up.svg'
import { Container, Option, Options } from 'components/InputAutoComplete/InputAutoComplete.styles'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const StyledOptions = styled(Options)`
  &.open {
    opacity: 1;
    pointer-events: auto;
    z-index: 10;
  }
`

export const OpenIcon = styled.div`
  width: 1rem;
  height: 1rem;

  background-image: url(${angleUpSvg});
  background-repeat: no-repeat;
  background-size: 1rem;

  cursor: pointer;

  &.close {
    transform: rotate(180deg);
  }
`

export const InputContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0 1rem;
  cursor: pointer;
  gap: 1rem;
`

export const SelectedLabel = styled(Text)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  span {
    &:not(:last-of-type) {
      &::after {
        content: ', ';
      }
    }
  }
`

export const PlaceholderLabel = styled(Text)`
  font-size: 1.3rem;
  color: ${(props) => props.theme.textColors.secondary};
  font-weight: ${(props) => props.theme.fontWeight.light};
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const OptionElement = styled(Option)`
  padding: 0.25rem 1rem;

  &.header {
    cursor: pointer;
    pointer-events: auto;
  }

  &.disabled {
    pointer-events: none;
    cursor: none;
    background-color: ${(props) => props.theme.backgroundColors.secondary};
    color: ${(props) => props.theme.textColors.secondary};
    font-style: italic;
  }

  &.empty {
    pointer-events: none;
    cursor: none;
    font-style: italic;
  }
`
export const SelectContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const CheckboxOption = styled(OptionElement)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
