import { CashboxCollectionBody, getCashboxCollectionUri, usePaginatedCashboxes } from 'api/cashbox'
import { getDifferenceColor, getDifferenceFontWeight, getDifferencePrefix } from 'app/CashboxDetails/utils/cashHelper'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import Listing from 'features/Listing/Listing'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Cashbox, Store } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatDateAndHour } from 'utils/dates'
import { mergeFormQueryParams } from 'utils/queryParams'
import { ButtonContainer } from './CashboxManagement.style'
import DetailsModal from './components/DetailsModal'
interface Props {
  formData?: CashboxCollectionBody
  watchLoading: (loading: boolean) => void
  store: Store
}

const defaultSort: OrderParam<CashboxCollectionBody> = {
  sortedBy: 'closedAt',
  direction: SortingDirection.Desc,
  queryParams: { 'order[closedAt]': SortingDirection.Desc }
}
export default function ClosedCashboxList({ formData, watchLoading, store }: Props) {
  const [currentCashbox, setCurrentCashbox] = useState<Cashbox>()
  const [modalOpen, toggleModal] = useState<boolean>(false)
  const [orderParams, setOrderParams] = useState<OrderParam<CashboxCollectionBody>>(defaultSort)
  const [cashboxQueryUri, setCashboxQueryUri] = useState<string>('')

  const { t } = useTranslation()
  const {
    data: closedCashboxes,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    totalItems,
    hasBeenCalled,
    refetch,
    isRefetching,
    hasNextPage
  } = usePaginatedCashboxes(cashboxQueryUri, cashboxQueryUri, {
    enabled: !!cashboxQueryUri
  })

  useEffect(() => {
    if (formData) {
      const newUri = getCashboxCollectionUri(
        mergeFormQueryParams<CashboxCollectionBody>({
          data: { ...formData, 'exists[closedAt]': true },
          orderParams: orderParams?.queryParams
        })
      )

      if (newUri === cashboxQueryUri) refetch()
      else setCashboxQueryUri(newUri)
    }
  }, [formData, orderParams, cashboxQueryUri, refetch])

  useEffect(() => {
    watchLoading(isLoading || isRefetching)
  }, [isLoading, watchLoading, isRefetching])

  const handleModal = (cashbox: Cashbox) => {
    setCurrentCashbox(cashbox)
    toggleModal(true)
  }

  const columns: Column<Cashbox, CashboxCollectionBody>[] = [
    {
      key: 'createdAt',
      name: t('common.label.openedAt'),
      sortable: true,
      orderQueryParam: 'order[createdAt]',
      decorator: (cashbox: Cashbox) => (
        <Cell>
          <Text>{formatDateAndHour(cashbox.createdAt)}</Text>
        </Cell>
      )
    },
    {
      key: 'closedAt',
      name: t('common.label.closedAt'),
      sortable: true,
      orderQueryParam: 'order[closedAt]',
      decorator: (cashbox: Cashbox) => (
        <Cell>
          <Text>{formatDateAndHour(cashbox.closedAt)}</Text>
        </Cell>
      )
    },
    {
      key: 'createdBy',
      name: t('common.label.collaborator'),
      decorator: (cashbox: Cashbox) => (
        <Cell>
          <Text>{`${cashbox.createdBy!.firstname} ${cashbox.createdBy!.lastname}`}</Text>
        </Cell>
      )
    },
    {
      key: 'cashFundStartCalculated',
      name: t('page.cashbox.manage.cashFundStart'),
      decorator: (cashbox: Cashbox) => (
        <Cell>
          <TextNumber value={cashbox.cashFundStartCalculated} suffix={'\u00a0€'} decimalScale={2} />
        </Cell>
      )
    },
    {
      key: 'expectedDifference',
      name: t('page.cashbox.manage.difference'),
      decorator: (cashbox: Cashbox) => (
        <Cell>
          <TextNumber
            color={getDifferenceColor(cashbox.cashFundStartCalculated! - cashbox.cashFundStart!)}
            value={cashbox.cashFundStartCalculated! - cashbox.cashFundStart!}
            suffix={'\u00a0€'}
            prefix={getDifferencePrefix(cashbox.cashFundStartCalculated! - cashbox.cashFundStart!)}
            fontWeight={getDifferenceFontWeight(cashbox.cashFundStartCalculated! - cashbox.cashFundStart!)}
            decimalScale={2}
          />
        </Cell>
      )
    },
    {
      key: 'cashDeposit',
      name: t('page.cashbox.manage.cashDeposit'),
      decorator: (cashbox: Cashbox) => (
        <Cell>
          <TextNumber
            value={cashbox.totalCashCalculated! - cashbox.cashFundEndCalculated!}
            suffix={'\u00a0€'}
            decimalScale={2}
          />
        </Cell>
      )
    },
    {
      key: 'expectedTotalDifference',
      name: t('page.cashbox.manage.difference'),
      decorator: (cashbox: Cashbox) => {
        const expectedTotalCash =
          (cashbox.totalCashCalculated ?? 0) - (cashbox.totalCash ?? 0) - (cashbox.cashFundEndCalculated ?? 0)

        return (
          <Cell>
            <TextNumber
              color={getDifferenceColor(expectedTotalCash)}
              value={expectedTotalCash}
              suffix={'\u00a0€'}
              prefix={getDifferencePrefix(expectedTotalCash)}
              fontWeight={getDifferenceFontWeight(expectedTotalCash)}
              decimalScale={2}
            />
          </Cell>
        )
      }
    },
    {
      key: 'action',
      decorator: (cashbox: Cashbox) => (
        <ButtonContainer>
          <Button size="small" variant="white" onClick={() => handleModal(cashbox)} fontWeight="regular">
            {t('common.label.seeDetails')}
          </Button>
        </ButtonContainer>
      )
    }
  ]

  return (
    <>
      {formData && hasBeenCalled && (
        <CardLayout>
          <Listing
            title={t('page.cashbox.manage.closeTitle', { store: store.shortName })}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isFetching={isFetching && !isFetchingNextPage}
            data={closedCashboxes}
            columns={columns}
            emptyText={t('page.cashbox.manage.cashboxEmpty')}
            totalCount={totalItems}
            countText={t('page.cashbox.manage.cashboxCountText', {
              current: closedCashboxes.length,
              total: totalItems
            })}
            onOrderChanged={setOrderParams}
            defaultSort={defaultSort}
            hasNextPage={hasNextPage}
          />
        </CardLayout>
      )}
      <DetailsModal open={modalOpen} onClose={() => toggleModal(!modalOpen)} cashbox={currentCashbox} />
    </>
  )
}
