import { yupResolver } from '@hookform/resolvers/yup'
import { FamilyStatus, fetchRanges } from 'api/ranges'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import { Title2 } from 'components/Title/Title.styles'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { SearchIcon } from 'constants/icons'
import useAuth from 'hooks/useAuth'
import { useProductCategoriesOptions, useProductSubcategoriesOptions } from 'hooks/useSelectOptions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { SortingDirection } from 'types/sorting'
import { hasOneOfFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import { CategoryCol, FormWrapper, InputCol, SearchProductForm } from './ProductForms.styles'

const schema = yup
  .object({
    name: yup.string().trim(),
    barCode: yup.string().trim(),
    id: yup.number(),
    family: yup.string().trim(),
    search: yup.string().trim(),
    category: yup.string().trim(),
    subcategory: yup.string().trim()
  })
  .test(hasOneOfFieldsMethod())

export interface ProductFormInputs extends yup.InferType<typeof schema> {}

const ProductSearchForm = ({ onSearch, isSubmitting, currentSearch }) => {
  const { t } = useTranslation()
  const { me } = useAuth()
  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors }
  } = useForm<ProductFormInputs>({
    resolver: yupResolver(schema)
  })

  const [watchFamily, watchCategory] = watch(['family', 'category'])

  const { data: ranges } = useQuery(['my-ranges', me?.stores], () =>
    fetchRanges({ 'stores[]': me!.stores?.map((e) => e['@id'] as string), 'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage], 'order[name]': 'asc' })
  )

  useEffect(() => {
    setValue('name', currentSearch)
  }, [currentSearch, setValue])

  const {
    productCategoriesOptions: mainCategoriesOptions,
    productCategoriesOptionsPagination: mainCategoriesOptionsPagination
  } = useProductCategoriesOptions(
    { 'exists[parent]': false, 'order[name]': SortingDirection.Asc, family: watchFamily },
    'id'
  )

  const {
    productSubcategoriesOptionsPagination: subcategoriesOptionsPagination,
    productSubcategoriesOptions: subcategoriesOptions
  } = useProductSubcategoriesOptions(watchCategory, 'id')

  useEffect(() => {
    setValue('subcategory', undefined)
  }, [watchCategory, setValue])

  useEffect(() => {
    setValue('category', undefined)
  }, [watchFamily, setValue])

  return (
    <CardLayout>
      <Title2>{t('page.product.management.search_title')}</Title2>
      <SearchProductForm onSubmit={handleSubmit(onSearch)}>
        <FormWrapper>
          <InputCol>
            <Input label={t('common.label.productName')} id="name" register={register} />
            <Input label={t('common.label.barcode')} id="barCode" register={register} />
          </InputCol>
          <InputCol>
            <Select
              label={t('common.label.range')}
              control={control}
              id="family"
              options={
                ranges ? ranges.map((range) => ({ value: range['@id'] as string, label: range.name as string })) : []
              }
              placeholder={t('common.placeholder.choose_range')}
              enableUnselect={true}
            />
            <Input
              type="text"
              label={t('common.label.number')}
              id="search"
              register={register}
              tooltip={<TextTooltip id="productNumberTooltip" text={t('page.product.management.tooltips.number')} />}
            />
          </InputCol>
          <CategoryCol>
            <Select
              control={control}
              id="category"
              options={mainCategoriesOptions}
              label={t('common.label.category')}
              placeholder={t('common.select.defaultOptions.categories')}
              enableUnselect={true}
              {...mainCategoriesOptionsPagination}
            />

            <Select
              control={control}
              id="subcategory"
              options={subcategoriesOptions}
              label={t('common.label.subcategory')}
              placeholder={t('common.select.defaultOptions.subcategories')}
              enableUnselect={true}
              disabled={!watchCategory}
              {...subcategoriesOptionsPagination}
            />
          </CategoryCol>
        </FormWrapper>

        <Button buttonType="submit" isLoading={isSubmitting} icon={SearchIcon} shape="circle" />
      </SearchProductForm>
      <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')} />
    </CardLayout>
  )
}

export default ProductSearchForm
