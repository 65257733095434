import { yupResolver } from '@hookform/resolvers/yup'
import { useDownloadFile } from 'api/basePlayInApi'
import { FamilyStatus, fetchRanges } from 'api/ranges'
import Button from 'components/Button/Button'
import { Section } from 'components/Section/Section.styles'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { ExportIcon } from 'constants/icons'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { ExportRangeCard, StockRangeForm } from './StockRange.style'

interface SelectRange {
  range: number
}

const schema = yup.object().shape({
  range: yup.number().required()
})

function StockRange() {
  const { t } = useTranslation()
  const [store] = useAtom(defaultStoreAtom)

  const { data: ranges } = useQuery(['ranges', { 'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage], 'order[name]': 'asc' }, store?.['@id']], () =>
    fetchRanges({
      stores: store?.['@id'],
      'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage],
      'order[name]': 'asc'
    })
  )

  const { formState, reset, watch, control } = useForm<SelectRange>({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  })

  const { downloadFile, isLoading } = useDownloadFile()

  useEffect(() => {
    reset({
      range: ranges?.[0]?.id
    })
  }, [store, reset, ranges])

  const selectedRange = watch('range')

  return (
    <Section>
      <MainTitle title={t('page.range.stockExport.title')} showStoreSelect={true} />

      <ExportRangeCard>
        <Title2>{t('page.range.stockExport.exportTitle')}</Title2>
        <Text color="secondary" fontWeight="light" style={{ marginBottom: 20 }}>
          {t('page.range.stockExport.exportDescription')}
        </Text>

        <StockRangeForm onSubmit={(e) => e.preventDefault()}>
          <Select
            label={t('common.label.range')}
            id="range"
            options={ranges?.map((range) => ({ label: range['name'] as string, value: range['id'] as number })) ?? []}
            control={control}
          />

          <Button
            onClick={() => downloadFile(`/exports/stock-products-by-family/${selectedRange}/${store?.id}`)}
            disabled={!formState?.isValid}
            shape="circle"
            icon={ExportIcon}
            isLoading={isLoading}
          />
        </StockRangeForm>
      </ExportRangeCard>
    </Section>
  )
}

export default StockRange
