import { AlertProps } from '@mui/material/Alert'
import { CheckIcon, WarningIcon } from 'constants/icons'
import React from 'react'
import Svg from '../Svg/Svg'
import { Container, ErrorsList, RowContainer, StyledAlert, SubText, Text, Title } from './Alert.styles'

interface Props extends AlertProps {
  title: string
  text?: string | string[]
  subtext?: string
}

const Alert = React.forwardRef<HTMLDivElement, Props>(function Alert({ title, text, subtext, ...props }, ref) {
  return (
    <Container>
      <StyledAlert ref={ref} variant="filled" icon={false} {...props}>
        <RowContainer>
          {props.severity === 'success' ? (
            <Svg size="2.5rem" svg={CheckIcon} color="white" />
          ) : (
            <Svg size="2.5rem" svg={WarningIcon} />
          )}
          <Title>{title}</Title>
        </RowContainer>
        <RowContainer>
          {subtext && <SubText>{subtext}</SubText>}
          {text &&
            (typeof text === 'string' ? (
              <Text>{text}</Text>
            ) : (
              <ErrorsList>
                {text.map((e, index) => (
                  <Text key={`error-${index}`}>{e}</Text>
                ))}
              </ErrorsList>
            ))}
        </RowContainer>
      </StyledAlert>
    </Container>
  )
})

export default Alert
