import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'

type FetchCouponsQueryParams = Api.GetCoupons.RequestQuery
type FetchCouponsResponse = Api.GetCoupons.ResponseBody
export type Coupon = FetchCouponsResponse['hydra:member'][0]

export const getCouponsUri = (params: FetchCouponsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.coupons.root, queryString].join('?')
}
