import classNames from 'classnames'
import { ComponentType, ReactNode } from 'react'
import {
  ColumnContainer,
  InputContainer,
  LabelStyled,
  LabelWrapper,
  RowContainer,
  RowReverseContainer
} from './InputLayout.styles'

type Layout = 'row' | 'column' | 'row-reverse'

export interface LayoutProps {
  label?: string | ReactNode
  layout?: Layout
  tooltip?: ReactNode
  className?: string
  disabled?: boolean
  labelComponent?: ComponentType
  secondaryText?: ReactNode
}

interface Props extends LayoutProps {
  children: ReactNode
  inputRef?: any
}

const getContainer = (layout: Layout) => {
  switch (layout) {
    case 'column':
      return ColumnContainer
    case 'row':
      return RowContainer
    case 'row-reverse':
      return RowReverseContainer
  }
}

const InputLayout = ({
  children,
  inputRef,
  className,
  label,
  layout = 'column',
  tooltip,
  disabled,
  labelComponent,
  secondaryText
}: Props) => {
  const Container = getContainer(layout)
  const LabelAndTooltipComponent = labelComponent ?? LabelStyled

  return (
    <Container className={className} ref={inputRef}>
      {(label || tooltip) && (
        <LabelWrapper>
          <LabelAndTooltipComponent>
            {typeof label === 'string' ? <label>{label}</label> : label}
            {tooltip}
          </LabelAndTooltipComponent>
          {secondaryText && secondaryText}
        </LabelWrapper>
      )}
      <InputContainer className={classNames({ disabled }, layout + '-layout', className)}>{children}</InputContainer>
    </Container>
  )
}

export default InputLayout
