import { StoreDetails } from 'types/entities'
import GameRentalFormDisplay from './display/GameRentalFormDisplay'
import PaybackFormDisplay from './display/PaybackFormDisplay'
import PlayAreaFormDisplay from './display/PlayAreaFormDisplay'
import StoreFormDisplay from './display/StoreFormDisplay'
import StoreSellFormDisplay from './display/StoreSellFormDisplay'

interface Props {
  store: StoreDetails
}

export default function StoreSeoTab({ store }: Props) {
  return (
    <>
      <StoreFormDisplay store={store} />
      <StoreSellFormDisplay store={store} />
      <PlayAreaFormDisplay store={store} />
      <GameRentalFormDisplay store={store} />
      <PaybackFormDisplay store={store} />
    </>
  )
}
