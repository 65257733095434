import { validateCashbox, ValidateCashboxBody } from 'api/cashbox'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { useMutation } from 'hooks/useAxiosMutation'
import { isNil } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Cashbox } from 'types/entities'
import { formatCalendarDate } from 'utils/dates'
import { CashboxesCountDetails, setCashboxAccountDetails } from 'utils/localStorage'
import CashboxDetailForm from '../form/CashboxDetailForm'
import { ValidationButton } from '../form/CashboxDetailsForm.style'
import { DepositFields, getCashboxCount } from '../utils/cashHelper'
import AccountResume from './AccountResume'
import { ActiveStepLayout, SectionBottom, TotalText } from './CashboxStep.style'
interface Props {
  isActive: boolean
  cashbox: Cashbox
}

export default function CashboxValidateStep({ isActive, cashbox }: Props) {
  const { t } = useTranslation()
  const [totalChecksCalculated, setTotalChecksCalculated] = useState<number>(0)
  const [totalGiftVouchersCalculated, setTotalGiftVouchersCalculated] = useState<number>(0)
  const [totalCashCalculated, setTotalCashCalculated] = useState<number>()
  const [expectedChecksDifference, setExpectedChecksDifference] = useState<number>(0)
  const [expectedGiftVoucherDifference, setExpectedGiftVouchersDifference] = useState<number>(0)
  const [expectedCashDifference, setExpectedCashDifference] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>()
  const [cashboxDetails, setCashboxDetails] = useState<CashboxesCountDetails>()

  const queryClient = useQueryClient()

  const handleSubmit = (formData) => {
    let { depositTotal = 0, depositGiftTotal = 0, ...cashCount } = formData
    setTotalChecksCalculated(depositTotal)
    setTotalGiftVouchersCalculated(depositGiftTotal)
    setTotalCashCalculated(getCashboxCount(cashCount))
    setExpectedCashDifference(
      getCashboxCount(cashCount) - (cashbox?.totalCash ?? 0) - (cashbox.cashFundStartCalculated ?? 0)
    )
    if (cashbox?.totalChecks) setExpectedChecksDifference(depositTotal - cashbox?.totalChecks)
    if (cashbox?.totalGiftVouchers) setExpectedGiftVouchersDifference(depositGiftTotal - cashbox?.totalGiftVouchers)

    setTotalCount(getCashboxCount(cashCount) + depositGiftTotal + depositTotal)
    setCashboxDetails(formData)
  }

  const { mutate: validateCashboxMutation, isLoading } = useMutation(
    (body: ValidateCashboxBody) => validateCashbox(cashbox.id as number, body),
    {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData(['cashbox', `${data.id}`])
        queryClient.setQueryData(['cashbox', `${data.id}`], { ...(prev as any), ...data })
        setCashboxAccountDetails(cashboxDetails)
      }
    }
  )

  const handleValidation = () => {
    validateCashboxMutation({
      totalCashCalculated,
      totalChecksCalculated,
      totalGiftVouchersCalculated
    })
  }

  const resume = [
    {
      label: t('page.cashbox.accountStep.cashTotal'),
      expectedDifference: expectedCashDifference
    },
    {
      label: t('page.cashbox.accountStep.checkTotal'),
      total: totalChecksCalculated,
      expectedDifference: expectedChecksDifference
    },
    {
      label: t('page.cashbox.accountStep.giftTotal'),
      total: totalGiftVouchersCalculated,
      expectedDifference: expectedGiftVoucherDifference
    }
  ]

  return (
    <>
      {cashbox &&
        (isActive ? (
          <ActiveStepLayout>
            <CardLayout>
              <Title2>{t('page.cashbox.cashboxContentCalculate')}</Title2>

              <CashboxDetailForm
                enableDeposit={true}
                onSubmit={handleSubmit}
                tooltipText={t('page.cashbox.accountStep.calculate')}
                defaultValues={{
                  [DepositFields.Deposit]: 0,
                  [DepositFields.Gift]: 0
                }}
              />
            </CardLayout>
            <CardLayout>
              <Title2 style={{ marginBottom: 0 }}>{t('page.cashbox.accountStep.cashTotal')}</Title2>
              <TextNumber
                color={totalCashCalculated ? 'black' : 'secondary2'}
                fontWeight={totalCashCalculated ? 'light' : 'regular'}
                value={totalCashCalculated}
                placeholder={t('common.label.notAvailable')}
                ContainerComponent={TotalText}
                suffix="€"
                decimalScale={2}
              />

              <SectionBottom border={false}>
                <AccountResume resume={resume} />

                <ValidationButton isLoading={isLoading} onClick={handleValidation} disabled={isNil(totalCount)}>
                  {t('page.cashbox.accountStep.validate')}
                </ValidationButton>
              </SectionBottom>
            </CardLayout>
          </ActiveStepLayout>
        ) : (
          <>
            <CardLayout>
              <Title2>
                {t('page.cashbox.accountStep.dateTitle', { date: formatCalendarDate(cashbox?.validatedAt) })}
              </Title2>

              <TextNumber
                value={cashbox.totalCashCalculated}
                suffix="€"
                size="2.8rem"
                fontWeight="light"
                decimalScale={2}
              />
            </CardLayout>
          </>
        ))}
    </>
  )
}
