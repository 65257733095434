import styled from 'styled-components'
import { DiscountPercentText, DiscountPriceText, OneLineText, PriceText, TextContainer } from '../StoreLabels.styles'

export const CercleDiscount = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50% 50% 50% 50%;
  border: 2px solid #1b1437;
  background-color: white;
  position: absolute;

  &.down {
    top: 50px; left: 240px;
  }

  &.up {
    top: 35px; left: 240px;
  }
`

export const HalfMoonRectangleDiscount = styled.div`
  width: 100%;
  height: 16.4rem;
  background-color: #d7002a;
  border: 2px solid #1b1437;

  &.down {
    margin-top: 5.2rem;
    border-radius: 50% 50% 0% 0%;
  }

  &.up {
    margin-top: -7.2rem;
    border-radius: 0% 0% 50% 50%;
  }
`

export const RectangleDiscountContainer = styled.div`
  position: relative;
  width: 32rem;
  height: 14.4rem;
  overflow: hidden;

  cursor: pointer;
`

export const Subtitle = styled(OneLineText)`
  height: 1.8rem;
  width: 32rem;
  top: 4.8rem;
`

export const StyledPriceTextDown = styled(PriceText)`
  bottom: 0.7rem;
`

export const StyledPriceTextUp = styled(PriceText)`
  top: 0.7rem;
`

export const StyledDiscountPriceTextDown = styled(DiscountPriceText)`
  bottom: 5rem;
`

export const StyledDiscountPriceTextUp = styled(DiscountPriceText)`
  top: 5rem;
`

export const StyledDiscountPercentTextDown = styled(DiscountPercentText)`
  top: 67px; left: 247px;
  color: #d7002a;
`

export const StyledDiscountPercentTextUp = styled(DiscountPercentText)`
  top: 53px; left: 247px;
  color: #d7002a;
`

export const StyledTextContainer = styled(TextContainer)`
  &.down {
    top: 0.8rem;
  }

  &.up {
    bottom: 0.8rem;
  }
`
