import { DefaultContainer } from 'components/InputFormattedNumber/InputFormattedNumber.styles'
import { LabelStyled } from 'components/Layouts/TextLayout/TextLayout.style'
import Select from 'components/Select/Select'
import { PlaceholderLabel, SelectedLabel } from 'components/Select/Select.styles'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const StockBlock = styled.div`
  flex-grow: 1;
`

export const StockLabel = styled(LabelStyled)`
  margin-bottom: 0.4rem;
`

type Props = { prioritySend: boolean }
export const DangerLabelInputRadioContainer = styled.div<Props>`
  display: flex;
  gap: 1rem;
  align-items: stretch;
  ${(props) => props.prioritySend && `text-transform: uppercase;`}

  & > :first-child {
    width: 2.5rem;
  }

  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
`

export const LabelOption = styled(Text)`
  display: flex;
  gap: 0.5rem;

  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
`

export const StyledSelect = styled(Select)`
  &.is-invalid {
    background: ${(props) => props.theme.colors.white};

    ${PlaceholderLabel} {
      color: ${(props) => props.theme.textColors.danger};
    }
  }

  ${SelectedLabel} {
    ${LabelOption} {
      & > :first-child {
        font-size: 1.8rem;
      }
    }
  }
` as typeof Select

export const ShippingPrice = styled.div`
  display: flex;
  align-items: center;
  &::before {
    content: '(';
  }
  &::after {
    content: ')';
  }
`

export const DiscountPriceSpan = styled(DefaultContainer)`
  text-decoration: line-through;
  margin-right: 0.5rem;
`
