import { BlockLayout, RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const OrderLitigationModalTitle = styled(Title2)`
  margin-bottom: 3.5rem;
`

export const LitigationModalContainer = styled(RowContainer)`
  align-items: stretch;
  gap: 3rem;
`

export const LitigationModalBlock = styled(BlockLayout)`
  min-width: 32.5rem;
`

export const LitigationFormContainer = styled(RowContainer)`
  gap: 2.8rem;

  & > :last-child {
    margin-top: auto;
  }
`

export const LitigationRow = styled(RowContainer)`
  margin-bottom: 2rem;

  & > :first-child {
    flex-grow: 1;
  }
`

export const LitigationName = styled(Text)`
  max-width: 15.5rem;
`

export const LitigationDate = styled.div`
  max-width: 12rem;
  margin-right: 3.5rem;

  &:before {
    content: '- ';
  }
`

export const LitigationCategorySelect = styled(Select)`
  width: 25rem;
` as typeof Select
