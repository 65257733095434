import classnames from 'classnames'
import TextNumber from 'components/TextNumber/TextNumber'
import { ArrowIcon as ArrowSvg } from 'constants/icons'
import { ArrowIcon, LabelTitle, StrokeTextNumber, TitleContainer } from '../StoreLabels.styles'
import { ArrowLabelEnum, getTitleFontSize } from '../storeLabelUtils'
import {
  CercleDiscount,
  HalfMoonSquareDiscount,
  SquareDiscountContainer,
  StyledDiscountPercentText,
  StyledDiscountPrice,
  StyledPriceText,
  StyledTextContainer,
  Subtitle
} from './SquareDiscountLabel.styles'

type Props = {
  title: string
  subtitle?: string
  price: number
  discountPrice: number
  discountPercent: number
  arrow?: ArrowLabelEnum
  onClick: () => void
}

const SquareDiscountLabel = ({ title, subtitle, price, discountPrice, discountPercent, arrow, onClick }: Props) => {
  return (
    <SquareDiscountContainer onClick={onClick}>
      <HalfMoonSquareDiscount/>
      <CercleDiscount/>
      <TextNumber
        value={discountPrice}
        color="white"
        fontWeight="bold"
        size="3.6rem"
        decimalScale={2}
        suffix={`\u00a0€`}
        ContainerComponent={StyledPriceText}
      />

      <StrokeTextNumber
        value={price}
        color="white"
        fontWeight="light"
        size="2.4rem"
        decimalScale={2}
        suffix={`\u00a0€`}
        ContainerComponent={StyledDiscountPrice}
      />
      <StyledTextContainer>
        <TitleContainer>
          {arrow && arrow !== ArrowLabelEnum.none && (
            <ArrowIcon
              className={classnames({ 'arrow-up': arrow === ArrowLabelEnum.up })}
              svg={ArrowSvg}
              size={{ height: '4.2rem' }}
            />
          )}
          <LabelTitle
            className={classnames(subtitle ? 'clamp-2' : 'clamp-3', 'red')}
            fontWeight="medium"
            size={getTitleFontSize(title.trim(), subtitle)}
          >
            {title.trim()}
          </LabelTitle>
        </TitleContainer>
        {subtitle && (
          <Subtitle className="clamp-2" size="1.6rem">
            {subtitle.trim()}
          </Subtitle>
        )}
      </StyledTextContainer>

      <TextNumber
        value={discountPercent}
        color="white"
        fontWeight="bolder"
        size="2.2rem"
        decimalScale={0}
        suffix="%"
        ContainerComponent={StyledDiscountPercentText}
      />
    </SquareDiscountContainer>
  )
}

export default SquareDiscountLabel
