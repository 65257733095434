import { getCollaboratorsUri, usePaginatedCollaborators } from 'api/collaborators'
import { Option } from 'components/Select/Select'
import { CheckIcon, CrossIcon, QuestionIcon } from 'constants/icons'
import { useTranslation } from 'react-i18next'
import { ProductCreationRequestStatus, RoleAction } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'

export const useProductSheetStatusOptions = () => {
  const { t } = useTranslation()
  const getSheetStateColor = (status: ProductCreationRequestStatus) =>
    ({
      [ProductCreationRequestStatus.Validated]: 'success',
      [ProductCreationRequestStatus.Created]: 'orange1',
      [ProductCreationRequestStatus.Rejected]: 'danger'
    }[status])

  const getSheetStateIcon = (status: ProductCreationRequestStatus) =>
    ({
      [ProductCreationRequestStatus.Validated]: CheckIcon,
      [ProductCreationRequestStatus.Created]: QuestionIcon,
      [ProductCreationRequestStatus.Rejected]: CrossIcon
    }[status])

  const getSheetStatusLabel = (status: ProductCreationRequestStatus) =>
    ({
      [ProductCreationRequestStatus.Validated]: t('common.select.sheetStatus.validated'),
      [ProductCreationRequestStatus.Created]: t('common.select.sheetStatus.waiting'),
      [ProductCreationRequestStatus.Rejected]: t('common.select.sheetStatus.rejected')
    }[status])

  const sheetStatusOptions: Option[] = Object.values(ProductCreationRequestStatus).map((status) => ({
    value: status,
    label: getSheetStatusLabel(status)
  }))

  return {
    getSheetStateColor,
    getSheetStateIcon,
    getSheetStatusLabel,
    sheetStatusOptions
  }
}

export const useSheetsCollaboratorsOptions = () => {
  //TODO Fetch collaborators by action
  const collaboratorsQuery = getCollaboratorsUri({
    'order[lastname]': SortingDirection.Asc,
    role: RoleAction.ROLE_ACTION_PRODUCT_CREATE_REQUEST
  })
  const {
    data: collaborators,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = usePaginatedCollaborators('sheetsCollaborators', collaboratorsQuery)

  const collaboratorOptions: Option[] =
    collaborators?.map((collaborator) => ({
      value: `${collaborator.id}`,
      label: `${collaborator.lastname} ${collaborator.firstname}`
    })) ?? []

  const getCollaboratorLabel = (value: string) => collaboratorOptions.find((e) => e.value === value)?.label

  return {
    collaboratorOptions,
    collaboratorOptionsPagination: { hasNextPage, isFetchingNextPage, fetchNextPage },
    getCollaboratorLabel
  }
}
