import { productCollectionByIdQueryKey } from 'api/collection'
import { postCollectionCategory, PostCollectionCategoryBody } from 'api/collectionCategories'
import { postCollectionEntry, PostCollectionEntryBody } from 'api/collectionEntries'
import { useApiNotifications } from 'hooks/useApiNotifications'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { ProductCollection } from 'types/entities'
import { getCollectionCategoriesKey } from '../../utils'
import CategoryForm, { CollectionCategoryFormDatas } from '../form/CategoryForm'

interface Props {
  isActive: boolean
  productCollection: ProductCollection
}

export default function CreateCategory({ isActive, productCollection }: Props) {
  const queryClient = useQueryClient()
  const [isLoading, setLoading] = useState(false)
  const { emitSuccessNotification } = useApiNotifications()
  const { mutateAsync } = useMutation((body: PostCollectionCategoryBody) => postCollectionCategory(body), {
    emitDefaultSuccessNotification: false
  })
  const { mutateAsync: postCollectionEntryAsync } = useMutation(
    (body: PostCollectionEntryBody) => postCollectionEntry(body),
    {
      emitDefaultSuccessNotification: false
    }
  )

  const onSubmit = (formData: CollectionCategoryFormDatas): Promise<void> =>
    new Promise(async (resolve) => {
      setLoading(true)
      try {
        const { linkedCategories, products, nameEn, descriptionEn, description, ...restData } = formData

        //First we create the category
        const collectionCategory = await mutateAsync({
          collection: productCollection['@id']!,
          linkedCategories: linkedCategories ?? [],
          nameEn: nameEn ?? null,
          descriptionEn: descriptionEn ?? null,
          description: description ?? null,
          ...restData
        })

        //Then, we need to create a productCollectionEntry for each products, linked to the new category
        for (let productEntry of products) {
          await postCollectionEntryAsync({
            product: productEntry.product['@id']!,
            category: collectionCategory['@id']!,
            baseBox: productEntry.rank === 'baseBox',
            expansionBox: productEntry.rank === 'expansionBox'
          })
        }

        //Refetch collection & categories in background
        await queryClient.refetchQueries(getCollectionCategoriesKey(`${productCollection.id}`))
        await queryClient.refetchQueries(productCollectionByIdQueryKey(`${productCollection.id}`))
        resolve(undefined)

        emitSuccessNotification()
      } catch {
        setLoading(false)
      }

      setLoading(false)
    })
  return <CategoryForm onSubmit={onSubmit} isActive={isActive} isFormLoading={isLoading} id="create" />
}
