import { yupResolver } from '@hookform/resolvers/yup'
import { } from 'app/CollectionCreate/form/CollectionCreateForm.style'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import FiltersList, { Filter } from 'components/FiltersList/FiltersList'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputRadio from 'components/InputRadio/InputRadio'
import MultipleSelect from 'components/Select/MultipleSelect'
import Textarea from 'components/Textarea/Textarea'
import Tooltip from 'components/Tooltip/Tooltip'
import { CreateIcon, SaveIcon } from 'constants/icons'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { getRequiredLabel } from 'utils/formHelper'
import * as yup from 'yup'
import { useCollectionCategories } from '../../utils'
import AddProductsForm, { ProductCollectionEntry } from './AddProductsForm'
import { CategoryFormWrapper, FieldsWrapper, SmallGapWrapper, StyledFiltersContainer } from './CategoryForm.style'

const schema = yup.object({
  name: yup.string().required(),
  nameEn: yup.string(),
  description: yup.string(),
  descriptionEn: yup.string(),
  linkedCategories: yup.array().of(yup.string().required()),
  ownProductsShown: yup.boolean()
})

export interface CollectionCategoryFormInputs extends yup.InferType<typeof schema> {}

export interface CollectionCategoryFormDatas extends CollectionCategoryFormInputs {
  products: ProductCollectionEntry[]
}

interface Props {
  onSubmit: (formData: CollectionCategoryFormDatas) => Promise<void>
  isActive: boolean
  isFormLoading?: boolean
  isEdit?: boolean
  defaultValues?: CollectionCategoryFormInputs
  productsList?: ProductCollectionEntry[]
  id: string
}

const CategoryNameTooltip = (
  <Tooltip id="categoryNameTooltip">
    <Trans i18nKey="page.collection.details.form.tooltips.name" />
  </Tooltip>
)
const CategoryDescriptionTooltip = (
  <Tooltip id="categoryDescriptionTooltip">
    <Trans i18nKey="page.collection.details.form.tooltips.description" />
  </Tooltip>
)

export default function CategoryForm({
  onSubmit,
  isActive,
  isFormLoading,
  productsList,
  defaultValues,
  isEdit,
  id
}: Props) {
  const { t } = useTranslation()

  const [selectedProducts, setSelectedProducts] = useState<ProductCollectionEntry[]>(productsList ?? [])
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset
  } = useForm<CollectionCategoryFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ownProductsShown: true,
      ...defaultValues
    }
  })

  const { collectionCategoriesOptions, collectionCategoriesOptionsPagination, getCollectionCategoriesFilterList } =
    useCollectionCategories({
      isActive
    })

  const handleCategoryFilter = (filter: Filter) => {
    setValue(
      'linkedCategories',
      getValues('linkedCategories')?.filter((e) => e !== filter.value)
    )
  }

  const submitHandler = async (formData: CollectionCategoryFormInputs) => {
    await onSubmit({
      ...formData,
      products: selectedProducts
    })
    if (!isEdit) {
      reset({ name: '', nameEn: '', description: '', descriptionEn: '', linkedCategories: [], ownProductsShown: true })
      setSelectedProducts([])
    }
  }

  return (
    <>
      <CategoryFormWrapper onSubmit={handleSubmit(submitHandler)} id={`categoryForm-${id}`}>
        <FieldsWrapper>
          <div>
            <Input
              label={getRequiredLabel(t('common.label.name'))}
              register={register}
              id="name"
              placeholder={t('page.collection.details.form.placeholder.name')}
              tooltip={CategoryNameTooltip}
              className={classNames({ 'is-invalid': errors.name })}
            />
            <BasicFieldValidationError error={errors.name} message={t('form.validation.required.generic')} />
          </div>

          <Input
            label={t('common.label.nameEn')}
            register={register}
            id="nameEn"
            placeholder={t('page.collection.details.form.placeholder.nameEn')}
          />

          <Textarea
            register={register}
            id="description"
            rows={2}
            label={t('page.collection.details.form.label.description')}
            tooltip={CategoryDescriptionTooltip}
            className={classNames({ 'is-invalid': errors.description })}
          />

          <Textarea
            register={register}
            id="descriptionEn"
            rows={2}
            label={t('page.collection.details.form.label.descriptionEn')}
          />
        </FieldsWrapper>
        <SmallGapWrapper>
          <InputRadio
            control={control}
            id="ownProductsShown"
            label={t('page.collection.details.form.label.ownProductsShown')}
          />
          <MultipleSelect
            placeholder={t('common.select.defaultOptions.categories')}
            label={t('page.collection.details.form.label.categories')}
            options={collectionCategoriesOptions}
            control={control}
            id="linkedCategories"
            disabled={collectionCategoriesOptions?.length === 0}
            {...collectionCategoriesOptionsPagination}
          />
          <FiltersList
            filters={getCollectionCategoriesFilterList(watch('linkedCategories'))}
            filterComponent={StyledFiltersContainer}
            onRemoveFilter={handleCategoryFilter}
            showClearAll={false}
          />
        </SmallGapWrapper>
      </CategoryFormWrapper>

      <AddProductsForm products={selectedProducts} handleProducts={setSelectedProducts} />

      <Button
        buttonType="submit"
        icon={isEdit ? SaveIcon : CreateIcon}
        size="long"
        isLoading={isFormLoading}
        form={`categoryForm-${id}`}
      >
        {isEdit ? t('common.button.save') : t('page.collection.details.form.btn')}
      </Button>
    </>
  )
}
