import Button from 'components/Button/Button'
import styled from 'styled-components'

export const SearchCardFormLayout = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem 5rem;

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }
`

export const TwoColsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(20rem, 1fr));
  gap: 2rem 3.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const SearchByNameWrapper = styled.div`
  @media screen and (${(props) => props.theme.screenWidth.minTabletScreen}) {
    grid-column: span 2;
  }
`

export const SubmitButton = styled(Button)`
  margin-top: 2rem;
`

export const TextFilterLabel = styled.div`
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
