import { CircleLoaderIcon } from 'constants/icons'
import { useEffect, useState } from 'react'
import { Colors } from 'theme/theme'
import { PageContainerWrapper, SpinningSvg } from './Loader.styles'

interface Props {
  size?: string
  color?: keyof Colors
  className?: string
  delay?: number
}

export default function Loader({ size, color, className, delay = 0 }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true)
    }, delay)
    return () => clearTimeout(timer)
  }, [delay])

  return <>{isLoading && <SpinningSvg className={className} svg={CircleLoaderIcon} size={size} color={color} />}</>
}

export const PageLoader = () => (
  <PageContainerWrapper>
    <Loader size="2.5rem" delay={1000} />
  </PageContainerWrapper>
)
