import { GetOrderByIdResponse } from 'api/orders'
import { VoucherCategory } from 'api/vouchers'
import { FormattedNumberComponent } from 'components/InputFormattedNumber/InputFormattedNumber'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { useGetPaymentModeLabel } from 'hooks/entityHooks/paymentModeHooks'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { getIdFromIri } from 'utils/queryParams'
import {
  BillMinusRowContainer,
  BillPlusRowContainer,
  BillRowContainer,
  BillTitle2,
  BolderTitle2,
  Disclaimer,
  InnerBolderTitle2,
  InnerTitle2,
  TotalTitle
} from './BillSection.style'

const getAsterisks = (index) => {
  return '*'.repeat(index + 1)
}

type Props = {
  order: GetOrderByIdResponse
}

function BillSection({ order }: Props) {
  const { t } = useTranslation()
  const { getPaymentModeLabel } = useGetPaymentModeLabel()

  const rentalGestures = order?.vouchers?.filter(
    (el) => getIdFromIri(el.category?.['@id']!) === `${VoucherCategory.RentalGesture}`
  )
  const commercialGestures = order?.vouchers?.filter(
    (el) => getIdFromIri(el.category?.['@id']!) === `${VoucherCategory.CommercialGesture}`
  )

  return (
    <>
      <BillRowContainer>
        <BillTitle2>{t('page.order.detail.billing.subTotal')}</BillTitle2>
        <FormattedNumberComponent
          ContainerComponent={InnerTitle2}
          value={order?.subTotal}
          decimalScale={2}
          suffix={'\u00a0€'}
        />
      </BillRowContainer>
      <BlockLayout>
        <BillRowContainer>
          <BillPlusRowContainer>{t('page.order.detail.billing.paymentFees')}</BillPlusRowContainer>
          <FormattedNumberComponent value={order?.paymentFees} decimalScale={2} suffix={'\u00a0€'} />
        </BillRowContainer>
        <BillRowContainer>
          <BillPlusRowContainer>{t('page.order.detail.billing.shippingFees')}</BillPlusRowContainer>
          <FormattedNumberComponent value={order?.shippingFees} decimalScale={2} suffix={'\u00a0€'} />
        </BillRowContainer>
      </BlockLayout>
      <BillRowContainer>
        <BolderTitle2>{t('page.order.detail.billing.total')}</BolderTitle2>
        <FormattedNumberComponent
          ContainerComponent={InnerBolderTitle2}
          value={order?.total}
          decimalScale={2}
          suffix={'\u00a0€'}
        />
      </BillRowContainer>
      <BlockLayout>
        <BillRowContainer>
          <BillMinusRowContainer>{t('page.order.detail.billing.couponDiscount')}</BillMinusRowContainer>
          <div>
            {order?.coupons?.length ? (
              // Currently there should be max one order coupon, but it may change.
              order?.coupons.map((item, index) =>
                item.coupon?.gift || !item.value ? (
                  <Text key={index}>{`${item?.coupon?.code}\u00a0${getAsterisks(index)}`}</Text>
                ) : (
                  <TextNumber
                    key={index}
                    value={item?.value}
                    suffix={`\u00a0€\u00a0${getAsterisks(index)}`}
                    decimalScale={2}
                  />
                )
              )
            ) : (
              <Text fontStyle="italic" color="secondary" whiteSpace="unset">
                {t('page.order.detail.billing.couponDiscountEmpty')}
              </Text>
            )}
          </div>
        </BillRowContainer>
        {rentalGestures && (
          <BillRowContainer>
            <BillMinusRowContainer>{t('page.order.detail.tab.payment.rentCoupon')}</BillMinusRowContainer>
            <FormattedNumberComponent
              value={_.sum(_.map(rentalGestures, 'value'))}
              decimalScale={2}
              suffix={'\u00a0€'}
            />
          </BillRowContainer>
        )}
        {commercialGestures && (
          <BillRowContainer>
            <BillMinusRowContainer>{t('page.order.detail.tab.payment.commercialGesture')}</BillMinusRowContainer>
            <FormattedNumberComponent
              value={_.sum(_.map(commercialGestures, 'value'))}
              decimalScale={2}
              suffix={'\u00a0€'}
            />
          </BillRowContainer>
        )}
      </BlockLayout>
      <BlockLayout>
        <hr></hr>
      </BlockLayout>
      <BillRowContainer>
        <TotalTitle>{t('page.order.detail.billing.totalToPay')}</TotalTitle>
        <FormattedNumberComponent
          ContainerComponent={InnerTitle2}
          value={order?.totalToPay}
          decimalScale={2}
          suffix={'\u00a0€'}
        />
      </BillRowContainer>
      {order?.coupons?.map((item, index) => (
        <Disclaimer fontWeight="light" color="info" size="1.2rem" key={index}>
          {`${getAsterisks(index)} ${
            item?.coupon?.gift
              ? t('page.order.detail.gift')
              : t('page.order.detail.usedCouponCode', { code: item?.coupon?.code })
          }`}
        </Disclaimer>
      ))}

      {_.chain(order?.paybacks)
        .groupBy('paymentMode.codeName')
        .map((paybacksGroup, codeName) => ({ codeName, value: _.sumBy(paybacksGroup, 'total').toFixed(2) }))
        .map(({ value, codeName }, index) => (
          <Disclaimer fontWeight="light" color="info" size="1.2rem" key={index}>
            {t('page.order.detail.payback', { value, paymentMode: getPaymentModeLabel(codeName) })}
          </Disclaimer>
        ))
        .value()}
    </>
  )
}

export default BillSection
