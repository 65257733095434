import { useGetLogEntriesByOrder } from 'api/logEntry'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import Listing from 'features/Listing/Listing'
import { useTranslation } from 'react-i18next'
import { LogEntry } from 'types/entities'
import { formatDateAndHourWithSeconds } from 'utils/dates'

interface ActionLogListingProps {
  target: string
  isActive: boolean
}

function ActionLogListing({ target, isActive }: ActionLogListingProps) {
  const { t } = useTranslation()
  const {
    data: logEntries,
    totalItems,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    fetchNextPage
  } = useGetLogEntriesByOrder(target, { enabled: isActive })

  const columns: Column<LogEntry>[] = [
    {
      name: t('common.label.date'),
      key: 'loggedAt',
      decorator: (log) => <Cell>{formatDateAndHourWithSeconds(log.loggedAt)}</Cell>
    },
    {
      name: t('common.label.action'),
      key: 'message',
      decorator: (log) => <Cell>{log.message}</Cell>
    },
    {
      name: t('common.label.collaborator'),
      key: 'collaborator',
      decorator: (log) => (
        <Cell>
          <Text fontWeight="light" color="secondary">
            {[log.collaborator?.firstname, log.collaborator?.lastname].join(' ')}
          </Text>
        </Cell>
      )
    }
  ]

  return (
    <>
      <Listing
        emptyText={t('common.modals.actionLogModal.emptyText')}
        columns={columns}
        data={logEntries ?? []}
        totalCount={totalItems}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        countText={t('common.modals.actionLogModal.logsCount', {
          count: logEntries?.length,
          total: totalItems ?? ''
        })}
      />
    </>
  )
}

export default ActionLogListing
