import FieldDisplay from 'components/Text/FieldDisplay'
import TextNumber from 'components/TextNumber/TextNumber'
import { useTranslation } from 'react-i18next'
import { StoreDetails } from 'types/entities'
import { DefaultCashboxFormWrapper } from '../StoreManageTab.style'

interface Props {
  store: StoreDetails
}

export default function DefaultCashboxForm({ store }: Props) {
  const { t } = useTranslation()

  return (
    <DefaultCashboxFormWrapper>
      <FieldDisplay
        label={t('page.stores.details.storeManage.cashbox.labels.float')}
        value={<TextNumber value={store.defaultCashboxValue} suffix={`\u00a0€`} decimalScale={2} fontWeight="medium" />}
      />
    </DefaultCashboxFormWrapper>
  )
}
