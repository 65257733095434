import { yupResolver } from '@hookform/resolvers/yup'
import { getStoreEventFormats, getStoreEventRanges } from 'api/storeEvents'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker/DatePicker'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputNumber from 'components/InputNumber/InputNumber'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import RichTextDisplay from 'components/RichTextEditor/RichTextDisplay'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import Select from 'components/Select/Select'
import FieldDisplay from 'components/Text/FieldDisplay'
import TextNumberFieldDisplay from 'components/Text/TextNumberFieldDisplay'
import TimePicker from 'components/TimePicker/TimePicker'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { CreateIcon, SaveIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useMyStoresOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { StoreEvent } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { formatCalendarDate, hourFormat } from 'utils/dates'
import { hasOneOfGroupFieldsMethod, isHourAfterValidation, isTodayOrAfterValidation } from 'utils/formHelper'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import EventDescriptionTooltip from './EventDescriptionTooltip'
import {
  DescriptionWrapper,
  FieldsSection,
  Form,
  FormBlock,
  FormSection,
  SubmitWrapper,
  TimeFieldsSection
} from './StoreEventForm.style'

const schema = {
  store: yup.string().trim().required(),
  occursAt: yup.string().required(),
  startsAt: yup.string().trim().required(),
  endsAt: yup
    .string()
    .required()
    .test('isBeforeStart', 'End hour need to take place before start hour', (value, context) =>
      isHourAfterValidation(value, context.parent.startsAt)
    ),
  eventRange: yup.string().trim().required(),
  format: yup.string().trim().nullable(),

  name: yup.string().trim().required(),
  nameEn: yup.string().trim(),
  subtitle: yup.string().trim().nullable(),
  subtitleEn: yup.string().trim().nullable(),
  description: yup.string().trim(),
  descriptionEn: yup.string().trim(),
  price: yup.number(),
  raisedPrice: yup
    .number()
    .nullable(true)
    .transform((value) => (isNaN(value) ? undefined : value))
    .test('isSuperior', 'Invalid price', (value, context) => {
      if (typeof value === 'number') return context.parent.price < value
      return true
    }),
  maxCapacity: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  highlighted: yup.boolean(),
  deliversVouchers: yup.boolean(),
  internalRegistration: yup.boolean(),
  openRegistration: yup.boolean(),
  requiredDataName: yup.string().trim(),
  requiredDataNameEn: yup.string().trim()
}

const basicSchema = yup.object(schema)

export interface CreateEventInputs extends yup.InferType<typeof basicSchema> {}

interface Props {
  onSubmit: (formData: CreateEventInputs) => void
  isLoading?: boolean
  editMode?: boolean
  canEdit?: boolean
  storeEvent?: StoreEvent
}

export default function StoreEventForm({ onSubmit, isLoading, editMode = false, canEdit = true, storeEvent }: Props) {
  const { t } = useTranslation()

  const { myStoresOptions } = useMyStoresOptions()

  const [defaultStore] = useAtom(defaultStoreAtom)

  const validationSchema = useMemo(() => {
    const validation =
      editMode && !isTodayOrAfterValidation(storeEvent?.occursAt)
        ? schema
        : {
            ...schema,
            occursAt: schema.occursAt.test('isPast', "Date can't be in past", (value) =>
              isTodayOrAfterValidation(value)
            )
          }

    return yup.object(validation).test(hasOneOfGroupFieldsMethod(['name', 'format'])())
  }, [editMode, storeEvent?.occursAt])

  const {
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    register,
    formState: { errors, touchedFields },
    watch
  } = useForm<CreateEventInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: storeEvent
      ? {
          store: storeEvent?.store?.['@id'] ?? '',
          occursAt: storeEvent?.occursAt,
          startsAt: hourFormat(storeEvent?.startsAt),
          endsAt: hourFormat(storeEvent?.endsAt),
          eventRange: storeEvent?.eventRange?.['@id'],
          format: storeEvent?.format?.['@id'],
          price: storeEvent?.price,
          raisedPrice: storeEvent?.priceWithoutDiscount ?? undefined,
          openRegistration: storeEvent?.openRegistration,
          internalRegistration: storeEvent?.internalRegistration,
          deliversVouchers: storeEvent?.deliversVouchers,
          highlighted: storeEvent?.highlighted,
          name: storeEvent?.name,
          nameEn: storeEvent?.nameEn,
          subtitle: storeEvent?.subtitle,
          subtitleEn: storeEvent?.subtitleEn,
          description: storeEvent?.description,
          descriptionEn: storeEvent?.descriptionEn,
          requiredDataName: storeEvent?.requiredDataName ?? undefined,
          requiredDataNameEn: storeEvent?.requiredDataNameEn ?? undefined,
          maxCapacity: storeEvent?.maxCapacity ?? undefined
        }
      : {
          store: defaultStore?.['@id'],
          openRegistration: true,
          internalRegistration: true,
          deliversVouchers: true,
          highlighted: false
        }
  })

  const [selectedRange, selectedFormat, openRegistration, inputName] = watch(['eventRange', 'format', 'openRegistration', 'name'])
  const [isAutoSet, setIsAutoSet] = useState(false)

  const { data: ranges } = useQuery(['store-event-ranges'], () => getStoreEventRanges({ 'order[name]': 'asc' }))
  const { data: formats } = useQuery(
    ['store-event-formats', selectedRange],
    () => getStoreEventFormats({ range: selectedRange, 'order[name]': 'asc' }),
    {
      enabled: !!selectedRange
    }
  )

  const rangeOptions = useMemo(() => (ranges || []).map((range) => ({
      value: range['@id'],
      label: range.name
    })),[ranges])

  const formatOptions = useMemo(() => (formats || []).map((format) => ({
      value: format['@id'],
      label: format.name ?? ''
    })),[formats])

  useEffect(() => {
    if (touchedFields.eventRange) {
      setValue('format', null)
    }
  }, [selectedRange, setValue, touchedFields.eventRange])

  useEffect(() => {
    const rangeName = rangeOptions?.find(e => e.value === selectedRange)?.label ?? ''
    const formatName = formatOptions?.find(e => e.value === selectedFormat)?.label
    const inputName = getValues('name')
    
    if(inputName === rangeName || inputName === rangeName+' : '+formatName || !inputName){
      setIsAutoSet(true)
    }else{
      setIsAutoSet(false)
    }

    let newName = rangeName ?? ''
    if(!!selectedFormat){
      if(!!formatName){
        newName = rangeName+' : '+formatName
      }
    }

    if(isAutoSet){
      setValue('name', newName)
    }
  }, [selectedRange, selectedFormat, rangeOptions, formatOptions, getValues, setValue, setIsAutoSet, isAutoSet])

  useEffect(() => {
    setIsAutoSet(false)
  }, [inputName, setIsAutoSet])

  useEffect(() => {
    if (!openRegistration) setValue('internalRegistration', false)
  }, [setValue, openRegistration])

  const defaultRoleActions = [RoleAction.ROLE_ACTION_STORE_EVENT_EDIT]
  const specificRoleActions = [
    RoleAction.ROLE_ACTION_STORE_EVENT_EDIT,
    RoleAction.ROLE_ACTION_STORE_EVENT_CREATE,
    RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_ACTION,
    RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER
  ]

  return (
    <CardLayout>
      <Title2>{t('page.events.create.formTitle')}</Title2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <FormBlock>
            <CanAccess
              permissions={defaultRoleActions}
              deniedExtraCondition={editMode}
              allowedComponent={
                <Select
                  id="store"
                  control={control}
                  options={myStoresOptions.filter((option) => option.value !== WAREHOUSE_STORE_ID)}
                  label={t('common.label.mandatory.localisation')}
                />
              }
              deniedComponent={
                <FieldDisplay label={t('common.label.mandatory.localisation')} value={storeEvent?.store?.shortName} />
              }
            />

            <FieldsSection>
              <CanAccess
                permissions={defaultRoleActions}
                allowedComponent={
                  <div>
                    <Select
                      control={control}
                      id="eventRange"
                      label={t('page.events.create.labels.mandatory.eventRange')}
                      options={rangeOptions}
                      placeholder={t('common.select.defaultOptions.familly')}
                      className={errors.eventRange ? 'is-invalid' : ''}
                    />
                    <BasicFieldValidationError
                      message={t('page.events.create.formErrors.required.eventRange')}
                      error={errors.eventRange}
                    />
                  </div>
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('page.events.create.labels.mandatory.eventRange')}
                    value={storeEvent?.eventRange?.name}
                  />
                }
              />

              <CanAccess
                permissions={defaultRoleActions}
                allowedComponent={
                  <Select
                    control={control}
                    id="format"
                    label={t('page.events.create.labels.eventFormat')}
                    options={formatOptions}
                    disabled={!selectedRange}
                    placeholder={t('common.select.defaultOptions.format')}
                    className={errors.format ? 'is-invalid' : ''}
                    enableUnselect={true}
                  />
                }
                deniedComponent={
                  <FieldDisplay label={t('page.events.create.labels.eventFormat')} value={storeEvent?.format?.name} />
                }
              />

              <CanAccess
                permissions={defaultRoleActions}
                allowedComponent={
                  <InputFormattedNumber
                    id="price"
                    suffix=" €"
                    decimalScale={2}
                    control={control}
                    label={t('page.events.create.labels.price')}
                  />
                }
                deniedComponent={
                  <TextNumberFieldDisplay
                    label={t('page.events.create.labels.price')}
                    suffix=" €"
                    decimalScale={2}
                    value={storeEvent?.price}
                  />
                }
              />

              <CanAccess
                permissions={defaultRoleActions}
                allowedComponent={
                  <div>
                    <InputFormattedNumber
                      id="raisedPrice"
                      suffix=" €"
                      decimalScale={2}
                      control={control}
                      label={t('page.events.create.labels.raisedPrice')}
                      tooltip={
                        <TextTooltip id="eventRaisedPriceTooltip" text={t('page.events.create.tooltips.raisedPrice')} />
                      }
                      className={errors.raisedPrice ? 'is-invalid' : ''}
                      disabled={!watch('price')}
                    />
                    <BasicFieldValidationError
                      error={errors.raisedPrice}
                      message={t('page.events.create.formErrors.invalid.raisedPrice')}
                    />
                  </div>
                }
                deniedComponent={
                  <TextNumberFieldDisplay
                    suffix=" €"
                    decimalScale={2}
                    label={t('page.events.create.labels.raisedPrice')}
                    tooltip={
                      <TextTooltip id="eventRaisedPriceTooltip" text={t('page.events.create.tooltips.raisedPrice')} />
                    }
                    value={storeEvent?.priceWithoutDiscount}
                  />
                }
              />
            </FieldsSection>
          </FormBlock>

          <FormBlock>
            <FieldsSection>
              <CanAccess
                permissions={defaultRoleActions}
                allowedComponent={
                  <div>
                    <DatePicker
                      id="occursAt"
                      setValue={setValue}
                      register={register}
                      trigger={trigger}
                      label={t('common.label.mandatory.date')}
                      defaultValue={storeEvent?.occursAt ?? ''}
                      className={errors.occursAt ? 'is-invalid' : ''}
                      disabled={!isTodayOrAfterValidation(storeEvent?.occursAt)}
                    />
                    <BasicFieldValidationError
                      error={errors.occursAt}
                      messages={{
                        isPast: t('page.events.create.formErrors.invalid.occursAt'),
                        required: t('page.events.create.formErrors.required.occursAt')
                      }}
                    />
                  </div>
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('common.label.mandatory.date')}
                    value={formatCalendarDate(storeEvent?.occursAt)}
                  />
                }
              />

              <TimeFieldsSection>
                <CanAccess
                  permissions={specificRoleActions}
                  allowedComponent={
                    <div>
                      <TimePicker
                        id="startsAt"
                        control={control}
                        label={t('common.label.mandatory.startHour')}
                        className={errors.startsAt ? 'is-invalid' : ''}
                      />
                      <BasicFieldValidationError
                        messages={{
                          required: t('page.events.create.formErrors.required.hour'),
                          isBeforeNow: t('page.events.create.formErrors.invalid.startAt')
                        }}
                        error={errors.startsAt}
                      />
                    </div>
                  }
                  deniedComponent={
                    <FieldDisplay
                      label={t('common.label.mandatory.startHour')}
                      value={hourFormat(storeEvent?.startsAt)}
                    />
                  }
                />

                <CanAccess
                  permissions={specificRoleActions}
                  allowedComponent={
                    <div>
                      <TimePicker
                        id="endsAt"
                        control={control}
                        label={t('common.label.mandatory.endHour')}
                        className={errors.endsAt ? 'is-invalid' : ''}
                      />
                      <BasicFieldValidationError
                        messages={{
                          isBeforeStart: t('page.events.create.formErrors.invalid.hour'),
                          required: t('page.events.create.formErrors.required.hour')
                        }}
                        error={errors.endsAt}
                      />
                    </div>
                  }
                  deniedComponent={
                    <FieldDisplay label={t('common.label.mandatory.endHour')} value={hourFormat(storeEvent?.endsAt)} />
                  }
                />
              </TimeFieldsSection>
            </FieldsSection>
            <FieldsSection>
              <CanAccess
                permissions={defaultRoleActions}
                allowedComponent={
                  <InputRadio
                    control={control}
                    id="openRegistration"
                    label={t('page.events.create.labels.openRegistration')}
                  />
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('page.events.create.labels.openRegistration')}
                    value={storeEvent?.openRegistration ? t('common.radioButton.yes') : t('common.radioButton.no')}
                  />
                }
              />

              <CanAccess
                permissions={defaultRoleActions}
                allowedComponent={
                  <InputRadio
                    control={control}
                    id="internalRegistration"
                    label={t('page.events.create.labels.internalRegistration')}
                    disabled={!openRegistration}
                  />
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('page.events.create.labels.internalRegistration')}
                    value={storeEvent?.internalRegistration ? t('common.radioButton.yes') : t('common.radioButton.no')}
                  />
                }
              />

              <CanAccess
                permissions={specificRoleActions}
                allowedComponent={
                  <InputRadio
                    control={control}
                    id="deliversVouchers"
                    label={t('page.events.create.labels.deliversVouchers')}
                  />
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('page.events.create.labels.deliversVouchers')}
                    value={storeEvent?.deliversVouchers ? t('common.radioButton.yes') : t('common.radioButton.no')}
                  />
                }
              />

              <CanAccess
                permissions={defaultRoleActions}
                allowedComponent={
                  <InputRadio control={control} id="highlighted" label={t('page.events.create.labels.highlighted')} />
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('page.events.create.labels.highlighted')}
                    value={storeEvent?.highlighted ? t('common.radioButton.yes') : t('common.radioButton.no')}
                  />
                }
              />
            </FieldsSection>
          </FormBlock>
        </FormSection>

        <FormSection>
          <DescriptionWrapper>
            <CanAccess
              permissions={defaultRoleActions}
              allowedComponent={
                <Input
                  register={register}
                  id="name"
                  label={t('page.events.create.labels.name')}
                  className={errors.name ? 'is-invalid' : ''}
                  tooltip={
                    <Tooltip id="nameStoreEvent">
                      <Trans i18nKey="page.events.create.tooltips.nameStoreEvent"/>
                    </Tooltip>
                  }
                />
              }
              deniedComponent={<FieldDisplay label={t('page.events.create.labels.name')} value={storeEvent?.name} />}
            />

            <CanAccess
              permissions={defaultRoleActions}
              allowedComponent={
                <Input
                  register={register}
                  id="subtitle"
                  label={t('page.events.create.labels.subtitle')}
                />
              }
              deniedComponent={
                <FieldDisplay value={storeEvent?.subtitle} label={t('page.events.create.labels.subtitle')} />
              }
            />

            <CanAccess
              permissions={defaultRoleActions}
              allowedComponent={
                <RichTextEditor
                  control={control}
                  id="description"
                  label={t('page.events.create.labels.description')}
                  tooltip={<EventDescriptionTooltip />}
                />
              }
              deniedComponent={
                <RichTextDisplay
                  label={t('page.events.create.labels.description')}
                  value={storeEvent?.description}
                  tooltip={<EventDescriptionTooltip />}
                />
              }
            />
          </DescriptionWrapper>
          <DescriptionWrapper>
            <CanAccess
              permissions={defaultRoleActions}
              allowedComponent={<Input register={register} id="nameEn" label={t('page.events.create.labels.nameEn')} />}
              deniedComponent={
                <FieldDisplay value={storeEvent?.nameEn} label={t('page.events.create.labels.nameEn')} />
              }
            />

            <CanAccess
              permissions={defaultRoleActions}
              allowedComponent={
                <Input
                  register={register}
                  id="subtitleEn"
                  label={t('page.events.create.labels.subtitleEn')}
                />
              }
              deniedComponent={
                <FieldDisplay value={storeEvent?.subtitleEn} label={t('page.events.create.labels.subtitleEn')} />
              }
            />

            <CanAccess
              permissions={defaultRoleActions}
              allowedComponent={
                <RichTextEditor
                  control={control}
                  id="descriptionEn"
                  label={t('page.events.create.labels.descriptionEn')}
                />
              }
              deniedComponent={
                <RichTextDisplay
                  label={t('page.events.create.labels.descriptionEn')}
                  value={storeEvent?.descriptionEn}
                />
              }
            />
          </DescriptionWrapper>
        </FormSection>

        <FormSection>
          <CanAccess
            permissions={defaultRoleActions}
            allowedComponent={
              <Input
                register={register}
                id="requiredDataName"
                label={t('page.events.create.labels.requiredDataName')}
                tooltip={
                  <Tooltip id="eventRequiredDataNameTooltip">
                    <Trans i18nKey="page.events.create.tooltips.requiredDataName" components={{ br: <br /> }} />
                  </Tooltip>
                }
              />
            }
            deniedComponent={
              <FieldDisplay
                value={storeEvent?.requiredDataName}
                label={t('page.events.create.labels.requiredDataName')}
                tooltip={
                  <Tooltip id="eventRequiredDataNameTooltip">
                    <Trans i18nKey="page.events.create.tooltips.requiredDataName" components={{ br: <br /> }} />
                  </Tooltip>
                }
              />
            }
          />

          <CanAccess
            permissions={defaultRoleActions}
            allowedComponent={
              <Input
                register={register}
                id="requiredDataNameEn"
                label={t('page.events.create.labels.requiredDataNameEn')}
              />
            }
            deniedComponent={
              <FieldDisplay
                value={storeEvent?.requiredDataNameEn}
                label={t('page.events.create.labels.requiredDataNameEn')}
              />
            }
          />

          <FieldsSection>
            <CanAccess
              permissions={specificRoleActions}
              allowedComponent={
                <InputNumber
                  register={register}
                  id="maxCapacity"
                  label={t('page.events.create.labels.maxCapacity')}
                  setValue={setValue}
                  trigger={trigger}
                  step={1}
                />
              }
              deniedComponent={
                <FieldDisplay label={t('page.events.create.labels.maxCapacity')} value={`${storeEvent?.maxCapacity}`} />
              }
            />
          </FieldsSection>
        </FormSection>

        <SubmitWrapper>
          <BasicFieldValidationError
            error={errors.name || errors.format}
            message={t('page.events.create.formErrors.required.nameOrFormat')}
          />
          <ShouldDisable permissions={editMode ? specificRoleActions : [RoleAction.ROLE_ACTION_STORE_EVENT_CREATE]}>
            <Button
              icon={editMode ? SaveIcon : CreateIcon}
              buttonType="submit"
              isLoading={isLoading}
              disabled={!canEdit}
            >
              {editMode ? t('common.button.save') : t('common.button.create')}
            </Button>
          </ShouldDisable>
        </SubmitWrapper>
      </Form>
    </CardLayout>
  )
}
