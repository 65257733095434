import playInApi, { useDownloadFile } from 'api/basePlayInApi'
import { orderByIdQueryKey } from 'api/orders'
import { useOrderContext } from 'app/OrderDetails/helper'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { BarcodeIcon, ExportIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useApiNotifications } from 'hooks/useApiNotifications'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { RoleAction, ShippingLabelTypeName } from 'types/playInApiInterfaces'
import { PrintLabelButton, SpaceBetweenRowContainer } from '../ShippingCard.style'

type Props = {
  isShipped: boolean
  order: Order
}

//@ts-ignore Missing role
const permissions = [RoleAction.ROLE_ACTION_ORDER_EDIT_SEND]

export default function ChronopostLikeShippingForm({ isShipped, order }: Props) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [shipSaturday, setShipSaturday] = useState(false)
  const { downloadFile, isLoading } = useDownloadFile()

  const [isPrinting, setIsPrinting] = useState(false)

  const { inMyStoresOrder } = useOrderContext()

  const { emitErrorNotification } = useApiNotifications()
  return (
    <>
      {order.shippingMode?.labelType === ShippingLabelTypeName.CHRONOPOST && (
        <BlockLayout>
          <CanAccess
            permissions={permissions}
            allowedComponent={
              <InputLayout className="no-border" layout="row-reverse" label={t('page.order.detail.deliverSaturday')}>
                <Checkbox
                  className={classnames({ checked: shipSaturday })}
                  onClick={() => setShipSaturday((value) => !value)}
                />
              </InputLayout>
            }
            deniedComponent={
              <InputLayout className="no-border" label={t('page.order.detail.deliverSaturday')}>
                <TextFieldDisplay text={shipSaturday ? t('common.radioButton.yes') : t('common.radioButton.no')} />
              </InputLayout>
            }
            deniedExtraCondition={!inMyStoresOrder}
          />
        </BlockLayout>
      )}

      <ShouldDisable permissions={permissions} deniedExtraCondition={!inMyStoresOrder}>
        <SpaceBetweenRowContainer>
          <PrintLabelButton
            icon={BarcodeIcon}
            variant="warning"
            disabled={isShipped || isPrinting}
            onClick={async () => {
              setIsPrinting(true)
              await playInApi
                .get(`/exports/generate-label/${order.id}/${shipSaturday ? '1' : '0'}?zpl=1`)
                .then((response) => {
                  //Appel au serveur local pour lancer l'impression direct de l'étiquette
                  fetch(
                    'http://localhost:8000/imprimerEtiquetteThermique?port=USB&protocole=DATAMAX&adresseIp=&etiquette=' +
                      response.data['base64String']
                  )
                })
                .catch((error) => {
                  if (error?.status && error?.data) emitErrorNotification(error.status, error.data)
                })
                .finally(() => setIsPrinting(false))
              queryClient.refetchQueries(orderByIdQueryKey(order?.id!))
              document.getElementById('sidebarSearchOrder')?.focus()
            }}
            isLoading={isPrinting}
          >
            {t('page.order.detail.printShippingLabel')}
          </PrintLabelButton>
          <TooltipHover id="download-label" content={t('page.order.detail.downloadShippingLabelTooltip')}>
            <Button
              shape="circle"
              icon={ExportIcon}
              disabled={isShipped}
              onClick={async () => {
                await downloadFile(`/exports/generate-label/${order.id}/${shipSaturday ? '1' : '0'}`)
                queryClient.refetchQueries(orderByIdQueryKey(order?.id!))
                document.getElementById('sidebarSearchOrder')?.focus()
              }}
              isLoading={isLoading}
            />
          </TooltipHover>
        </SpaceBetweenRowContainer>
      </ShouldDisable>
    </>
  )
}
