import classnames from 'classnames'
import { AngleUpIcon } from 'constants/icons'
import { ReactFragment, useState } from 'react'
import { ArrowIcon, ContentList, SubTitle } from './SideBar.styles'
interface Props {
  label: string
  children: ReactFragment
}

function SideBarItemFold({ label, children }: Props) {
  const [collapsed, setCollapsed] = useState(false)

  const toggleContent = () => {
    setCollapsed(!collapsed)
  }

  return (
    <>
      <SubTitle onClick={toggleContent}>
        {label}
        <ArrowIcon svg={AngleUpIcon} className={classnames({ 'arrow-down': collapsed })} />
      </SubTitle>

      <ContentList className={collapsed ? 'close' : 'open'}>{children}</ContentList>
    </>
  )
}

export default SideBarItemFold
