import { yupResolver } from '@hookform/resolvers/yup'
import { AddFidelityPointBody, addFidelityPoints } from 'api/fidelityPoints'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputNumber from 'components/InputNumber/InputNumber'
import Select from 'components/Select/Select'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEmit } from 'hooks/useEventEmitter'
import { useFidelityCategoriesOptions, useMyStoresOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Customer } from 'types/entities'
import { EventType } from 'types/events'
import { FidelityPointCategory } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { AddPointForm } from './CustomerForm.style'

type FormData = {
  value: number
  category: FidelityPointCategory
  store: string
  comment?: string
}
interface Props {
  customer: Customer
}

const schema = yup.object().shape({
  value: yup
    .number()
    .required()
    .transform((value) => (isNaN(value) ? undefined : value)),
  category: yup.string().trim().required(),
  store: yup.string().trim().required()
})

export default function AddFidelityPointForm({ customer }: Props) {
  const { fidelityCategoriesOptions } = useFidelityCategoriesOptions()
  const { myStoresOptions } = useMyStoresOptions()
  const [defaultStore] = useAtom(defaultStoreAtom)

  const emit = useEmit()

  const {
    register,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormData>({
    defaultValues: {
      store: defaultStore?.['@id'],
      comment: ''
    },
    resolver: yupResolver(schema)
  })

  const { t } = useTranslation()

  const { mutate: addMutation, isLoading } = useMutation((body: AddFidelityPointBody) => addFidelityPoints(body), {
    onSuccess: () => {
      //trigger refetch on fidelity points list
      emit(EventType.AddFidelityPoint, null)
      reset()
    }
  })

  const onSubmit = (formData: FormData) => {
    addMutation({
      customer: customer['@id'],
      ...formData
    })
  }

  return (
    <>
      <AddPointForm onSubmit={handleSubmit(onSubmit)}>
        <InputNumber
          register={register}
          id="value"
          setValue={setValue}
          trigger={trigger}
          label={t('page.customer.details.fidelity.form.numberPoint')}
          className={classNames({
            'is-invalid': errors.value
          })}
        />
        <Select
          options={fidelityCategoriesOptions}
          control={control}
          id="category"
          label={t('common.label.mandatory.category')}
          placeholder={t('common.select.defaultOptions.categories')}
          className={classNames({
            'is-invalid': errors.category
          })}
        />
        <Select
          options={myStoresOptions}
          control={control}
          id="store"
          label={t('common.label.mandatory.originLocalisation')}
          className={classNames({
            'is-invalid': errors.store
          })}
        />
        <Input register={register} id="comment" label={t('common.label.comment')} />
        <Button icon={SaveIcon} buttonType="submit" shape="circle" isLoading={isLoading} />
      </AddPointForm>
      <div>
        <BasicFieldValidationError
          message={t('page.customer.details.fidelity.form.errors.mandatoryNumber')}
          error={errors.value}
        />
        <BasicFieldValidationError
          message={t('page.customer.details.fidelity.form.errors.mandatoryCategory')}
          error={errors.category}
        />
      </div>
    </>
  )
}
