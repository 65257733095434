import { yupResolver } from '@hookform/resolvers/yup'
import { getSealedProductsUri } from 'api/sealedProducts'
import { stockByIdQueryKey } from 'api/stock'
import { postStockEntry, PostStockEntryBody } from 'api/stockEntry'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { Option } from 'components/InputAutoComplete/InputAutoComplete.styles'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputNumber from 'components/InputNumber/InputNumber'
import Select from 'components/Select/Select'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { MoreIcon, ProductIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { SealedProduct, Stock } from 'types/entities'
import { SortingDirection } from 'types/sorting'
import * as yup from 'yup'
import { AddProductLayout, Form, SmallInputWrapper } from './AddStockEntryForm.styles'

const schema = yup.object().shape({
  product: yup.string().trim().required(),
  quantity: yup.number().positive().moreThan(0).required(),
  priceCalculation: yup.string().trim().required(),
  priceTaxFree: yup
    .number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .min(0)
})

export interface AddProductFormData {
  product: string
  quantity: number
  priceCalculation: string
  priceTaxFree: number
}

interface Props {
  stockRef: string
  stockId: number
}

const AddProductForm = ({ stockRef, stockId }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    register,
    trigger,
    formState: { errors, touchedFields },
    watch
  } = useForm<AddProductFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      quantity: 1,
      priceCalculation: 'unitPrice'
    },
    reValidateMode: 'onSubmit'
  })

  const [watchPriceCalculation] = watch(['priceCalculation'])

  const [product, setProduct] = useState<SealedProduct | null>(null)

  const tooltip = (
    <Tooltip id="addProductToStockEntryTax">
      <Trans i18nKey="tooltips.stocks.taxPrice.text" components={{ br: <br /> }} />
    </Tooltip>
  )

  const { mutate: postStockEntryMutation, isLoading: isMutationLoading } = useMutation(
    (data: PostStockEntryBody) => postStockEntry(data),
    {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData<Stock>(stockByIdQueryKey(stockId))

        queryClient.setQueryData(stockByIdQueryKey(stockId), {
          ...prev,
          entries: [{ ...data }, ...(prev?.entries || [])]
        })

        reset({
          // @ts-ignore priceTaxFree is required but need an empty string to be reset that will cause error
          priceTaxFree: '',
          priceCalculation: watchPriceCalculation,
          quantity: 1
        })
      }
    }
  )

  const onSubmit = (formData: AddProductFormData) => {
    const buyPriceVatExcluded =
      formData.priceCalculation === 'unitPrice'
        ? formData.priceTaxFree
        : (formData.priceTaxFree ?? 0) / formData.quantity

    postStockEntryMutation({
      product: formData.product,
      quantity: formData.quantity as number,
      buyPriceVatExcluded,
      stock: stockRef,
      tax: product!.tax?.['@id']
    })
  }

  return (
    <AddProductLayout>
      <Svg svg={ProductIcon} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputAutoComplete
          className={classNames('productField', { 'is-invalid': errors.product })}
          getUrlFromSearch={(search) =>
            getSealedProductsUri({
              search: search,
              'order[old]': SortingDirection.Asc,
              'order[secondHand]': SortingDirection.Asc,
              'order[name]': SortingDirection.Asc,
              pack: false
            })
          }
          id="product"
          placeholder={t('common.autocomplete.addProduct')}
          labelKey="name"
          valueKey="@id"
          onEntitySelected={setProduct}
          control={control}
          isTouched={touchedFields.product}
          formatOption={(product: SealedProduct, onClick) => (
            <Option onClick={onClick}>
              <Text fontStyle={product.old ? 'italic' : 'normal'}>
                {product.old ? t('page.stock.detail.products.oldOption', { name: product.name }) : product.name}
              </Text>
            </Option>
          )}
        />
        <SmallInputWrapper>
          <InputNumber
            className={classNames('quantityField', { 'is-invalid': errors.quantity })}
            id="quantity"
            register={register}
            setValue={setValue}
            trigger={trigger}
            min={1}
          />
        </SmallInputWrapper>

        <Select
          className={classNames('priceCalculationField', { 'is-invalid': errors.priceCalculation })}
          id="priceCalculation"
          control={control}
          options={[
            {
              label: t('common.select.unitPrice'),
              value: 'unitPrice'
            },
            {
              label: t('common.select.totalPrice'),
              value: 'totalPrice'
            }
          ]}
        />
        <div className="priceField">
          <InputFormattedNumber
            id="priceTaxFree"
            control={control}
            tooltip={tooltip}
            label=" "
            layout="row"
            suffix=" €"
            placeholder="0.0000 €"
            decimalScale={4}
            className={classNames({ 'is-invalid': errors.priceTaxFree })}
          />
        </div>
        <Button buttonType="submit" shape="circle" icon={MoreIcon} iconColor="white" isLoading={isMutationLoading} />
      </Form>
      <div></div>
      <div></div>
    </AddProductLayout>
  )
}

export default AddProductForm
