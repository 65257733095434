import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled, { css } from 'styled-components'

export const InformationsLayout = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  column-gap: 2rem;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }
`

const GridLayout = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  gap: 2rem 3.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const Form = styled.form`
  ${GridLayout}
`

export const AccountDetail = styled.div`
  ${GridLayout}
`

export const LargeInputLayout = styled(InputLayout)`
  grid-column: span 2;
`

export const GiftForm = styled(Form)`
  margin-top: 3.5rem;
  padding-top: 3.5rem;
  border-top: ${(props) => props.theme.borderColors.secondary} 1px solid;
  row-gap: 0.5rem;
`

export const CommentWrapper = styled.div`
  grid-column: span 2;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-column: auto;
  }
`

export const CommentLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`

export const PasswordText = styled(Text)`
  margin-bottom: 2rem;
`
export const CollaboratorText = styled(Text)`
  margin-bottom: 2rem;
  .link {
    display: inline;
  }
`
export const OrderSummaryLayout = styled(CardLayout)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`
export const OrderSummaryTitle = styled(Title2)`
  margin-bottom: 1rem;
`

export const TotalText = styled(Text)`
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 1rem;
`
export const CountLine = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  line-height: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) and (${(props) =>
      props.theme.screenWidth.minLaptopScreen}) {
    display: grid;
    grid-template-columns: 1fr;
    line-height: 1.2rem;
    margin-bottom: 1rem;
  }
`

export const ResetPasswordButton = styled(Button)`
  width: 25rem;
  max-width: 100%;
`
