import { useFetchStores } from 'api/stores'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import { useProductInfoHelpers } from '../productDetailsUtils'
import {
  Container,
  Content,
  Dot,
  PriceGrid,
  QuantityBlock,
  StoreContainer,
  StoreContent
} from './ProductStockModal.styles'

type Props = Omit<ModalProps, 'children'> & {
  product: SealedProduct
}

const ProductStockModal = ({ open, onClose, product }: Props) => {
  const { t } = useTranslation()

  const { getModalStockInfo, getStockInfoColor, getPreorderDate, getProductStore } = useProductInfoHelpers()
  const { data: stores } = useFetchStores()

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <Text size="1.6rem" fontWeight="bold">
          {t('page.product.stockModal.title')}
        </Text>
        <Content>
          {stores
            ?.filter((store) => store?.['@id'] !== WAREHOUSE_STORE_ID && store.active)
            .map((store) => {
              const productStore = getProductStore(product.productStores, store['@id'])

              return (
                <StoreContainer key={store['@id']}>
                  <Text size="1.6rem" fontWeight="bold">
                    {store?.name}
                  </Text>
                  <QuantityBlock>
                    <Dot color={getStockInfoColor(product, productStore)} />
                    <Text>{getModalStockInfo(product, productStore)}</Text>
                  </QuantityBlock>
                  <StoreContent>
                    {!!productStore?.sellPrice && (
                      <>
                        <PriceGrid>
                          <Text fontWeight="light">{t('page.product.stockModal.localPrice')}</Text>
                          <TextNumber
                            value={productStore.sellPrice}
                            suffix=" €"
                            decimalScale={2}
                            ContainerComponent={Text}
                          />
                        </PriceGrid>
                        {!!productStore.discount && (
                          <PriceGrid>
                            <Text fontWeight="light">{t('page.product.stockModal.localDiscount')}</Text>
                            <TextNumber
                              value={productStore.discount}
                              suffix=" €"
                              prefix="- "
                              decimalScale={2}
                              ContainerComponent={Text}
                            />
                          </PriceGrid>
                        )}
                      </>
                    )}
                    <Text color="warning">{getPreorderDate(product, productStore)}</Text>
                  </StoreContent>
                </StoreContainer>
              )
            })}
        </Content>
      </Container>
    </Modal>
  )
}

export default ProductStockModal
