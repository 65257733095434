import Button, { Props as ButtonProps } from 'components/Button/Button'
import { Text } from 'components/Text/Text.styles'
import { CheckIcon } from 'constants/icons'
import { isWithinInterval, sub } from 'date-fns'
import ConfirmModal from 'features/Modals/ConfirmModal'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import useAuth from 'hooks/useAuth'
import _ from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stock } from 'types/entities'
import { RoleAction, StockEntryJsonldStockReadItemStockRead, StockStatus } from 'types/playInApiInterfaces'
import { useStockActionsNotifications } from '../helpers'

interface Props {
  stock?: Stock
}

const ValidateStockButton = ({ ...props }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button icon={CheckIcon} {...props}>
      {t('page.stock.detail.action.stock.label')}
    </Button>
  )
}

const CancelValidationButton = ({ ...props }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button variant="danger" {...props}>
      {t('page.stock.detail.action.stock.cancel')}
    </Button>
  )
}

export default function StockValidateButton({ stock }: Props) {
  const { t } = useTranslation()
  const { me } = useAuth()
  const { checkMatch } = useCheckMatch()

  const now = new Date()
  const canBeValidated = stock?.status === StockStatus.Created && stock.entries.length
  const canBeCanceled = stock?.status === StockStatus.Validated && stock?.cancelable
  const isValidatedByMe =
    stock?.validatedBy?.['@id'] === me?.['@id'] &&
    stock?.validatedAt &&
    isWithinInterval(new Date(stock.validatedAt), {
      start: sub(now, { days: 3 }),
      end: now
    })

  const { validationMutation, cancelValidationMutation } = useStockActionsNotifications()
  const [confirmModal, toggleConfirmModal] = useState(false)

  const getButton = () => {
    if (canBeValidated) {
      return (
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_ACTION]}>
          <ValidateStockButton
            onClick={() => toggleConfirmModal(true)}
            iconColor={checkMatch([RoleAction.ROLE_ACTION_STOCK_EDIT_ACTION]) ? 'white' : 'black'}
          />
        </ShouldDisable>
      )
    }

    if (canBeCanceled) {
      if (isValidatedByMe) {
        return (
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_ACTION]}
            allowedComponent={<CancelValidationButton onClick={() => cancelValidationMutation.mutate(stock?.id!)} />}
            deniedComponent={<ValidateStockButton disabled iconColor="black" />}
          />
        )
      }

      return (
        <CanAccess
          permissions={[RoleAction.ROLE_ACTION_STOCK_CANCEL_ACTION]}
          allowedComponent={<CancelValidationButton onClick={() => cancelValidationMutation.mutate(stock?.id!)} />}
          deniedComponent={<ValidateStockButton disabled iconColor="black" />}
        />
      )
    }

    return <ValidateStockButton disabled iconColor="black" />
  }

  return (
    <>
      {getButton()}
      <ConfirmModal
        title={t('page.stock.detail.action.stock.confirm', { id: stock?.id })}
        onClose={() => toggleConfirmModal(false)}
        open={confirmModal}
        callback={() => stock && validationMutation.mutate(stock.id as number)}
        confirmLabel={t('page.stock.detail.action.stock.label')}
        confirmBtnProps={{
          icon: CheckIcon,
          iconColor: 'white',
          variant: 'default'
        }}
      >
        <>
          {_.some<StockEntryJsonldStockReadItemStockRead>(stock?.entries, (entry) => entry.product?.price === 0) && (
            <Text>{t('page.stock.detail.action.stock.productWithNoPrice')}</Text>
          )}
        </>
      </ConfirmModal>
    </>
  )
}
