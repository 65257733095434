import { eventByIdQueryKey, postStoreEventCustomer, PostStoreEventCustomerBody } from 'api/storeEvents'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Title2 } from 'components/Title/Title.styles'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Customer, StoreEvent } from 'types/entities'
import AddCustomerForm, { AddCustomerInputs } from './AddCustomerForm'
import SearchCustomerForm from './SearchCustomerForm'

interface Props extends ModalProps {
  event: StoreEvent
}
export default function AddCustomerModal({ event, onClose, ...modalProps }: Props) {
  const { t } = useTranslation()
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>()

  const queryClient = useQueryClient()
  const { mutate: addCustomer, isLoading } = useMutation(
    (body: PostStoreEventCustomerBody) => postStoreEventCustomer(body),
    {
      onSuccess: (addedCustomer) => {
        const prevEvent = queryClient.getQueryData(eventByIdQueryKey(event.id!)) as StoreEvent

        queryClient.setQueryData(eventByIdQueryKey(event.id!), {
          ...prevEvent,
          turnover: (prevEvent.turnover ?? 0) + (addedCustomer?.paidPrice ?? 0),
          customers: [...(prevEvent?.customers ?? []), addedCustomer]
        })

        onClose()
        setSelectedCustomer(undefined)
      }
    }
  )

  const onSubmit = (formData: AddCustomerInputs) => {
    addCustomer({
      ...formData,
      paymentMode: formData.paymentMode || undefined, //cant be null
      event: event['@id'],
      customer: selectedCustomer?.['@id']
    })
  }

  return (
    <Modal onClose={onClose} {...modalProps}>
      <Title2>{t('page.events.details.modals.addCustomer.title')}</Title2>
      <SearchCustomerForm setSelectedCustomer={setSelectedCustomer} selectedCustomer={selectedCustomer} event={event} />
      <AddCustomerForm
        customer={selectedCustomer}
        event={event}
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitDisabled={!selectedCustomer}
      />
    </Modal>
  )
}
