import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const CreateCard = styled(CardLayout)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const StyledTitle2 = styled(Title2)`
  margin-bottom: 0;
`
export const FamilyIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
