import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import TextNumber from 'components/TextNumber/TextNumber'
import CanAccess from 'features/Permissions/CanAccess'
import useAuth from 'hooks/useAuth'
import { useAtom } from 'jotai'
import { RouteName } from 'permissions/permissions'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { SealedProduct } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import { getIdFromIri } from 'utils/queryParams'
import DisplayMainStoreProduct from './forms/DisplayMainStoreProduct'
import EditProductForm from './forms/EditProductForm'
import EditStoreProductForm from './forms/EditStoreProductForm/EditStoreProductForm'
import {
  Container,
  PackProductContainer,
  PackProductImg,
  PackProductSection,
  PackTitle,
  PrintStoreLabelLink,
  ProductDetailsSection,
  ProductFormGrid
} from './ProductDetailsTab.styles'

type Props = {
  productId: string
}

const ProductDetailsTab = ({ productId }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const product = queryClient.getQueryData<SealedProduct>(['sealedProductById', productId])
  const { me } = useAuth()

  const [atomLocation] = useAtom(defaultStoreAtom)
  const productStore = useMemo(
    () => product?.productStores?.find((productLocation) => productLocation.store?.['@id'] === atomLocation?.['@id']),
    [atomLocation, product?.productStores]
  )

  return (
    <Container>
      <div>
        <ProductFormGrid>
          <ProductDetailsSection>
            <InputLayout className="no-border" label={t('page.product.detail.tabs.detail.family')}>
              <TextFieldDisplay text={product?.family?.name} />
            </InputLayout>
            <InputLayout className="no-border" label={t('page.product.detail.tabs.detail.primaryCategory')}>
              <TextFieldDisplay text={product?.category?.name} />
            </InputLayout>
          </ProductDetailsSection>

          <ProductDetailsSection>
            <InputLayout
              className="no-border"
              label={t('page.product.detail.tabs.detail.localPosition', { store: atomLocation?.shortName })}
            >
              <TextFieldDisplay text={productStore?.localPosition} />
            </InputLayout>
            <InputLayout
              className="no-border"
              label={t('page.product.detail.tabs.detail.sizing', { store: atomLocation?.shortName })}
            >
              <TextFieldDisplay
                text={t('page.product.detail.tabs.detail.sizingDetails', {
                  height: product?.heightInMillimeters ? `${product?.heightInMillimeters} mm` : '-',
                  width: product?.widthInMillimeters ? `${product?.widthInMillimeters} mm` : '-',
                  length: product?.lengthInMillimeters ? `${product?.lengthInMillimeters} mm` : '-'
                })}
              />
            </InputLayout>
          </ProductDetailsSection>

          <ProductDetailsSection>
            <InputLayout className="no-border" label={t('common.label.distributor')}>
              <TextFieldDisplay text={product?.distributor?.lastname} />
            </InputLayout>
            <InputLayout className="no-border" label={t('page.product.detail.tabs.detail.editor')}>
              <TextFieldDisplay text={product?.editor?.name} />
            </InputLayout>
          </ProductDetailsSection>

          <ProductDetailsSection>
            <InputLayout
              className="no-border"
              label={t('page.product.detail.tabs.detail.site.title', {
                site: process.env.REACT_APP_BO_V2_HOSTNAME?.split('://')[1]
              })}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_BO_V2_HOSTNAME}/produit/${product?.id}-`}
              >
                <Text color="darkPurple" fontWeight="bold">
                  {t('page.product.detail.tabs.detail.site.link', {
                    site: process.env.REACT_APP_BO_V2_HOSTNAME?.split('://')[1]
                  })}
                </Text>
              </a>
            </InputLayout>

            <InputLayout className="no-border" label={t('page.product.detail.tabs.detail.storeLabel.description')}>
              <PrintStoreLabelLink route={RouteName.StoreLabels} state={{ product: product }}>
                {t('page.product.detail.tabs.detail.storeLabel.link')}
              </PrintStoreLabelLink>
            </InputLayout>
          </ProductDetailsSection>
        </ProductFormGrid>

        {product?.pack && (
          <>
            <PackTitle color="secondary" fontWeight="light">
              {t('page.product.detail.tabs.detail.packComposition')}
            </PackTitle>
            <PackProductSection>
              {product?.productsInPack?.map((packItem) => (
                <PackProductContainer
                  key={packItem['@id']}
                  route={RouteName.ProductDetails}
                  value={getIdFromIri(packItem.product!)}
                >
                  <PackProductImg src={packItem.productImageLink} />
                  <Text>{packItem.quantity}</Text>
                  <Text color="secondary" fontWeight="light">
                    x
                  </Text>
                  <Text>{packItem.productName}</Text>
                  <TextNumber
                    value={packItem.quantity! * packItem.unitPrice!}
                    decimalScale={2}
                    prefix="("
                    suffix=" €)"
                    color="secondary"
                  />
                </PackProductContainer>
              ))}
            </PackProductSection>
          </>
        )}
      </div>

      <CanAccess
        permissions={[RoleAction.ROLE_ACTION_PRODUCT_EDIT_CORE]}
        allowedComponent={
          me?.stores?.find((store) => store.warehouse) ? (
            <EditProductForm productId={productId} />
          ) : (
            <DisplayMainStoreProduct productId={productId} />
          )
        }
        deniedComponent={<DisplayMainStoreProduct productId={productId} />}
      />

      <EditStoreProductForm productId={productId} />
    </Container>
  )
}

export default ProductDetailsTab
