import { FormData as PointsToVoucherFormData } from 'app/CustomerDetails/forms/PointsToVoucherForm'
import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { FidelityPoint } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type FetchFidelityPointParams = Api.GetFidelityPoints.RequestQuery
type FetchFidelityPointResponse = Api.GetFidelityPoints.ResponseBody
export const getFidelityPointUri = (params: FetchFidelityPointParams): string => {
  let qs = stringify(params)
  return [API_ROUTES.fidelityPoints.root, qs].join('?')
}

export const usePaginatedFidelityPoints = (
  queryKey: string,
  query: string,
  options?: PaginatedHookOptions<FetchFidelityPointResponse['hydra:member'][0]>
) => usePaginatedQuery<FetchFidelityPointResponse['hydra:member'][0]>(queryKey, query, options)

export const fetchFidelityPoints = async (query: string) => {
  let res = await playInApi.get<FetchFidelityPointResponse>(query)
  return res.data['hydra:member']
}

export type AddFidelityPointBody = Api.PostFidelityPoints.RequestBody
type AddFidelityPointResponse = Api.PostFidelityPoints.ResponseBody
export const addFidelityPoints = async (body: AddFidelityPointBody): Promise<FidelityPoint> => {
  let res = await playInApi.post<AddFidelityPointBody, AxiosResponse<AddFidelityPointResponse>>(
    API_ROUTES.fidelityPoints.root,
    body
  )
  return res.data
}

export const deleteFidelityPoint = async (id: number) => {
  return await playInApi.delete(`${API_ROUTES.fidelityPoints.root}/${id}`)
}

export type FetchFidelityPointVoucherResponse = Api.GetFidelityPointVouchers.ResponseBody
export const fetchFidelityPointVouchers = async () => {
  let res = await playInApi.get<FetchFidelityPointVoucherResponse>(API_ROUTES.fidelityPoints.vouchers)
  return res.data['hydra:member']
}

export type PatchFidelityPointBody = Api.PatchFidelityPointsId.RequestBody
export const patchFidelityPoints = async (body: PatchFidelityPointBody, id: number) => {
  let res = await playInApi.patch<PatchFidelityPointBody, AxiosResponse<FidelityPoint>>(
    `${API_ROUTES.fidelityPoints.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

type PointsToVoucherBody = Api.PostVouchersFromFidelityCustomerFidelityAmount.RequestBody
type PointsToVoucherResponse = Api.PostVouchersFromFidelityCustomerFidelityAmount.ResponseBody
export const transformPointsToVoucher = async (customer: number, body: PointsToVoucherFormData) => {
  const { fidelityAmout, ...restBody } = body
  let res = await playInApi.post<PointsToVoucherBody, AxiosResponse<PointsToVoucherResponse>>(
    `${API_ROUTES.vouchers.fromFidelity}/${customer}/${fidelityAmout}`,
    restBody
  )
  return res.data
}
