import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { useTranslation } from 'react-i18next'
import { getIdFromIri } from 'utils/queryParams'
import PrintList from '../../components/OrderPrintList'
import PreorderPrintForm from '../../components/PreorderPrintForm/PreorderPrintForm'

interface Props {
  active: boolean
}

const warehouseStoreId = getIdFromIri(WAREHOUSE_STORE_ID)

function CustomPrintTab({ active }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <CardLayout>
        <Title2>{t('page.order.print.customPrint.printOrders')}</Title2>

        <PreorderPrintForm storeId={warehouseStoreId} active={active} />
      </CardLayout>

      <CardLayout>
        <Title2>{t('page.order.print.details.list')}</Title2>

        <PrintList active={active} storeId={warehouseStoreId} />
      </CardLayout>
    </>
  )
}

export default CustomPrintTab
