import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { SearchIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { hasOneOfFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import { FormSearch, Line, SearchFormHeading } from './CustomerForms.style'

export type CustomerForm = {
  email?: string
  firstname?: string
  lastname?: string
  zipCode?: string
}

const schema = yup
  .object()
  .shape({
    firstname: yup.string().trim(),
    lastname: yup.string().trim(),
    email: yup.string().trim(),
    zipCode: yup.string().trim()
  })
  .test(hasOneOfFieldsMethod())

const CustomerSearchForm = ({ onSearch, isSubmitting }) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<CustomerForm>({
    resolver: yupResolver(schema)
  })

  return (
    <CardLayout>
      <SearchFormHeading>
        <Title2>{t('page.customer.manage.search')}</Title2>
        <Tooltip id="search-helper">{t('page.customer.manage.searchHelper')}</Tooltip>
      </SearchFormHeading>

      <FormSearch onSubmit={handleSubmit(onSearch)}>
        <Input label={t('common.label.lastName')} id="lastname" register={register} />
        <Input label={t('common.label.firstName')} id="firstname" register={register} />
        <Line>
          <Input
            type="text"
            label={t('common.label.email')}
            id="email"
            register={register}
            tooltip={
              <Tooltip id="customerEmailTooltip">
                <Trans i18nKey="page.customer.manage.emailHelper" />
              </Tooltip>
            }
          />
        </Line>
        <Input label={t('common.label.zipCode')} id="zipCode" register={register} />
        <Button buttonType="submit" isLoading={isSubmitting} icon={SearchIcon} shape="circle" />
      </FormSearch>
      <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')} />
    </CardLayout>
  )
}

export default CustomerSearchForm
