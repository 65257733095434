import Button from 'components/Button/Button'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const StyledCell = styled(Cell)`
  &.unsuscribed {
    background-color: ${(props) => props.theme.backgroundColors.secondary};
  }
`
export const StyledRowCell = styled(StyledCell)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
export const StyledClickableCell = styled(ClickableCell)`
  &.unsuscribed {
    background-color: ${(props) => props.theme.backgroundColors.secondary};
  }
`

export const ListActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
  gap: 2rem;
`

export const MiscActionsWrapper = styled.div`
  display: flex;
  gap: 3.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`
export const ActionButton = styled(Button)`
  width: 20rem;
  max-width: 100%;
`

export const NoResultText = styled(Text)`
  padding-top: 2rem;
`
