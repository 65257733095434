import { yupResolver } from '@hookform/resolvers/yup'
import { postVoucher, PostVoucherBody } from 'api/vouchers'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker/DatePicker'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputNumber from 'components/InputNumber/InputNumber'
import Select from 'components/Select/Select'
import { MoreIcon } from 'constants/icons'
import { add, endOfDay } from 'date-fns'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEmit } from 'hooks/useEventEmitter'
import { useMyStoresOptions, useVoucherCategoriesOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Customer } from 'types/entities'
import { EventType } from 'types/events'
import { VoucherCategoryName } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { AddVoucherFormLayout } from './CustomerForm.style'

interface Props {
  customer: Customer
}

type FormData = {
  value: number
  category: VoucherCategoryName
  store: string
  comment?: string
  expiresAt: Date | null
}

const schema = yup.object().shape({
  value: yup
    .number()
    .required()
    .max(99999.99)
    .transform((value) => (isNaN(value) ? undefined : value)),
  category: yup.string().trim().required(),
  store: yup.string().trim().required(),
  comment: yup.string().trim(),
  expiresAt: yup.date().nullable().required()
})

export default function AddVoucherForm({ customer }: Props) {
  const { t } = useTranslation()
  const [defaultStore] = useAtom(defaultStoreAtom)
  const {
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      store: defaultStore?.['@id'],
      expiresAt: null,
      comment: ''
    }
  })

  const { myStoresOptions } = useMyStoresOptions()
  const { voucherCategoriesOptions, getVoucherDaysExpired } = useVoucherCategoriesOptions()
  const emit = useEmit()
  const { mutate, isLoading } = useMutation((body: PostVoucherBody) => postVoucher(body), {
    onSuccess: () => {
      emit(EventType.AddCustomerVoucher, null)
      reset()
    }
  })

  const onSubmit = (formData: FormData) => {
    const { expiresAt, ...restData } = formData
    mutate({
      customer: customer['@id'],
      ...restData,
      expiresAt: expiresAt && endOfDay(expiresAt)?.toLocaleDateString('en-EN')
    })
  }

  const watchCategory = watch('category')
  const watchExpiration = watch('expiresAt')

  useEffect(() => {
    if (watchCategory) {
      const daysExpired = getVoucherDaysExpired(watchCategory)
      const today = new Date()
      const expirationDate = endOfDay(add(today, { days: daysExpired }))

      setValue('expiresAt', expirationDate)
    }
  }, [watchCategory, setValue, getVoucherDaysExpired])

  return (
    <>
      <AddVoucherFormLayout onSubmit={handleSubmit(onSubmit)}>
        <InputNumber
          register={register}
          id="value"
          setValue={setValue}
          trigger={trigger}
          label={t('common.label.mandatory.priceValue')}
          className={classNames({
            'is-invalid': errors.value
          })}
          step={0.01}
          showArrows={false}
        />
        <Select
          options={voucherCategoriesOptions}
          control={control}
          id="category"
          label={t('common.label.mandatory.category')}
          placeholder={t('common.select.defaultOptions.categories')}
          className={classNames({
            'is-invalid': errors.category
          })}
        />
        <DatePicker
          id="expiresAt"
          register={register}
          setValue={setValue}
          trigger={trigger}
          defaultValue={watchExpiration}
          label={t('page.customer.details.vouchers.expirationDate')}
          className={classNames({
            'is-invalid': errors.expiresAt
          })}
        />
        <Select
          options={myStoresOptions}
          control={control}
          id="store"
          label={t('common.label.mandatory.originLocalisation')}
          className={classNames({
            'is-invalid': errors.store
          })}
        />
        <Input register={register} id="comment" label={t('common.label.comment')} />
        <Button icon={MoreIcon} shape="circle" buttonType="submit" isLoading={isLoading} iconColor="white" />
      </AddVoucherFormLayout>
      <BasicFieldValidationError
        messages={{
          required: t('form.validation.required.priceValue'),
          max: t('page.customer.details.vouchers.errors.maxValue')
        }}
        error={errors.value}
      />
      <BasicFieldValidationError message={t('form.validation.required.category')} error={errors.category} />
      <BasicFieldValidationError message={t('form.validation.required.expirationDate')} error={errors.expiresAt} />
    </>
  )
}
