import { yupResolver } from '@hookform/resolvers/yup'
import { GetOrderByIdResponse, PostCreditNoteBody, postCreditNote } from 'api/orders'
import Button from 'components/Button/Button'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputRadio from 'components/InputRadio/InputRadio'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { ModalButtonWrapper } from 'components/Modal/Modal.styles'
import Select from 'components/Select/Select'
import TextNumber from 'components/TextNumber/TextNumber'
import { RefundIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import { useMyStoresOptions, usePaymentModeFilters } from 'hooks/useSelectOptions'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { OrderEntry } from 'types/entities'
import * as yup from 'yup'
import { object, string } from 'yup'
import { GetCellPropertiesFn } from '../forms/orderEntriesTable/OrderEntriesTable'
import { StyledCell } from '../forms/orderEntriesTable/OrderEntriesTable.styles'
import { CreditNoteFormContainer, DescriptionText, ModalListingWrapper, RefundModalTitle } from './BaseModal.style'
import { TwoColGridRow } from './CustomerEditModal/CustomerEditModal.style'
import EntryQuantity from './components/EntryQuantity'

interface Props extends ModalProps {
  orderEntries: OrderEntry[]
  columns: Columns<OrderEntry>
  getCellProperties: GetCellPropertiesFn
  callback: () => void
  order?: GetOrderByIdResponse
}

type FormData = {
  paymentFees: number
  shippingFees: number
  rentalGesture: number
  commercialGesture: number
  couponDiscount: number
  paymentMode: string
  refundStore: string
  stockError: boolean
}
const schema = object().shape({
  paymentMode: string().required(),
  refundStore: string().required(),
  paymentFees: yup.number().transform((value) => (isNaN(value) ? 0 : value)),
  shippingFees: yup.number().transform((value) => (isNaN(value) ? 0 : value)),
  rentalGesture: yup.number().transform((value) => (isNaN(value) ? 0 : value)),
  commercialGesture: yup.number().transform((value) => (isNaN(value) ? 0 : value)),
  couponDiscount: yup.number().transform((value) => (isNaN(value) ? 0 : value))
})

type EntryQty = { id: string; quantity: number }
export default function CreditStoreModal({
  open,
  onClose,
  orderEntries,
  columns,
  getCellProperties,
  callback,
  order
}: Props) {
  const { t } = useTranslation()
  const [entriesQty, setEntriesQty] = useState<EntryQty[]>([])

  const getQuantity = (id: string): number => {
    const quantityByEntriesQty = entriesQty.find((entry) => entry.id === id)?.quantity
    if (quantityByEntriesQty !== undefined) return quantityByEntriesQty ?? 0
    const quantityByOrderEntries = orderEntries.find((entry) => entry['@id'] === id)?.quantity
    if (quantityByOrderEntries !== undefined) return quantityByOrderEntries ?? 0
    return 0
  }

  const handleQuantity = useCallback((entry: OrderEntry, quantity: number) => {
    setEntriesQty((prev) => {
      let entries = [...prev]
      let index = entries.findIndex((prevEntry) => prevEntry.id === entry['@id'])
      if (index >= 0) {
        entries[index] = { ...prev[index], quantity }
      } else {
        entries.push({ id: entry['@id']!, quantity })
      }
      return entries
    })
  }, [])

  const queryClient = useQueryClient()

  const { mutateAsync: creditNoteMutate, isLoading } = useMutation((body: PostCreditNoteBody) => postCreditNote(body), {
    onSuccess: () => {
      onClose()
      callback()
    }
  })

  const onSubmit = async (formData: FormData) => {
    let body: PostCreditNoteBody = {
      splitEntries: [],
      shippingFees: formData.shippingFees,
      paymentFees: formData.paymentFees,
      commercialGesture: formData.commercialGesture,
      couponDiscount: formData.couponDiscount,
      rentalGesture: formData.rentalGesture,
      paymentMode: formData.paymentMode,
      stockError: formData.stockError,
      refundStore: formData.refundStore,
      order: order?.['@id']
    }

    for (let entry of orderEntries) {
      body.splitEntries?.push({
        orderEntry: entry['@id'],
        quantityToSplit: getQuantity(entry['@id']!)
      })
    }

    await creditNoteMutate(body)
    queryClient.refetchQueries(['orderEntries', order?.id])
  }
  
  const { me } = useAuth()
  
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      refundStore: me?.mainStore?.['@id']
    }
  })
  
  useEffect(() => {
    reset({
      shippingFees: order?.shippingFees ? order?.shippingFees : undefined,
      paymentFees: order?.paymentFees ? order?.paymentFees : undefined,
      rentalGesture: order?.rentalGesture ? order?.rentalGesture : undefined,
      commercialGesture: order?.commercialGesture ? order?.commercialGesture : undefined,
      couponDiscount: order?.couponDiscount ? order?.couponDiscount : undefined
    })
  }, [order, reset])
  
  columns = [
    {
      key: 'quantity',
      name: t('common.label.quantity'),
      decorator: (entry) => {
        return (
          <StyledCell className={getCellProperties(entry).classname}>
            <EntryQuantity entry={entry} handleQuantity={handleQuantity} />
          </StyledCell>
        )
      }
    },
    ...columns?.filter((e) => ['cardOrProduct', 'lang'].includes(e.key)),

    {
      key: 'price',
      name: t('common.label.totalUnitPrice'),
      decorator: (entry) => (
        <StyledCell className={getCellProperties(entry).classname}>
          <TextNumber value={entry.paidPrice! * getQuantity(entry['@id']!)} suffix=" €" decimalScale={2} />
        </StyledCell>
      )
    }
  ]

  const totalPrice = orderEntries.reduce((prev, entry) => prev + getQuantity(entry['@id']!) * entry.paidPrice!, 0)

  const { myStoresOptions } = useMyStoresOptions()
  const { paymentModeOptions } = usePaymentModeFilters()

  return (
    <Modal open={open} onClose={onClose}>
      <ModalListingWrapper>
        <RefundModalTitle>
          <Trans
            i18nKey={'page.order.detail.modals.creditNote.title'}
            components={{ amount: <TextNumber value={totalPrice} suffix="€" fontWeight="bold" decimalScale={2} /> }}
          />
        </RefundModalTitle>
        <DescriptionText color="secondary">{t('page.order.detail.modals.creditNote.description')}</DescriptionText>
        <Listing data={orderEntries} columns={columns} />
      </ModalListingWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CreditNoteFormContainer>
          <TwoColGridRow>
            {!!order?.paymentFees && (
              <InputFormattedNumber
                label={t('page.order.detail.modals.creditNote.paymentFees')}
                placeholder={t('common.label.priceValue', { currency: '€' })}
                suffix=" €"
                decimalScale={2}
                control={control}
                id="paymentFees"
              />
            )}

            {!!order?.shippingFees && ( 
              <InputFormattedNumber
                label={t('page.order.detail.modals.creditNote.shippingFees')}
                placeholder={t('common.label.priceValue', { currency: '€' })}
                suffix=" €"
                decimalScale={2}
                control={control}
                id="shippingFees"
              />
            )}

            {!!order?.commercialGesture && ( 
              <InputFormattedNumber
                label={t('page.order.detail.modals.creditNote.commercialGesture')}
                placeholder={t('common.label.priceValue', { currency: '€' })}
                suffix=" €"
                decimalScale={2}
                control={control}
                id="commercialGesture"
              />
            )}
              
            {!!order?.rentalGesture && ( 
              <InputFormattedNumber
                label={t('page.order.detail.modals.creditNote.rentalGesture')}
                placeholder={t('common.label.priceValue', { currency: '€' })}
                suffix=" €"
                decimalScale={2}
                control={control}
                id="rentalGesture"
              />
            )}

            {!!order?.couponDiscount && ( 
              <InputFormattedNumber
                label={t('page.order.detail.modals.creditNote.couponDiscount')}
                placeholder={t('common.label.priceValue', { currency: '€' })}
                suffix=" €"
                decimalScale={2}
                control={control}
                id="couponDiscount"
              />
            )}
            
            <Select
              options={myStoresOptions}
              control={control}
              label={t('page.order.detail.modals.creditNote.label.localisation')}
              id="refundStore"
              className={errors.refundStore ? 'is-invalid' : ''}
            />
            <Select
              options={paymentModeOptions}
              control={control}
              label={t('page.order.detail.modals.creditNote.label.paymentMode')}
              id="paymentMode"
              className={errors.paymentMode ? 'is-invalid' : ''}
              placeholder={t('page.order.detail.modals.creditNote.placeholder.paymentMode')}
            />
          </TwoColGridRow>
          <InputRadio
            id="stockError"
            label={t('page.order.detail.modals.creditNote.stockError')}
            display="checkbox"
            layout="row-reverse"
            control={control}
          />
          <ModalButtonWrapper>
            <Button icon={RefundIcon} isLoading={isLoading} buttonType="submit">
              {t('page.order.detail.modals.creditNote.button')}
            </Button>
            <Button variant="white" onClick={() => onClose()}>
              {t('common.label.cancel')}
            </Button>
          </ModalButtonWrapper>
        </CreditNoteFormContainer>
      </form>
    </Modal>
  )
}
