import {
  fetchCollaborators,
  FetchCollaboratorsQueryParams,
  patchCollaborator,
  PatchCollaboratorBody
} from 'api/collaborators'
import { usePaginatedRoles } from 'api/role'
import { usePaginatedRoleActions } from 'api/roleAction'
import { Option } from 'components/Select/Select'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { Collaborator, Role, RoleAction, RoleAction as RoleActionEntity } from 'types/entities'
import { CollaboratorRoleCollaboratorRoleRead, CollaboratorRoleJsonldUserReadUserReadItem } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'

type DeletableRow = {
  isDeletable: boolean
  message?: ReactNode
}

const sortByLocalLabels = (options: Option[]): Option[] => {
  return options.sort((a, b) => {
    if (typeof a.label === 'string' && typeof b.label === 'string') return a.label.localeCompare(b.label)
    return 0
  })
}
export const usePermissionsByCollaborator = (collaborator: Collaborator) => {
  const { me } = useAuth()
  const { t } = useTranslation()

  const franchisedOnly = !_.some(collaborator?.stores, (store) => store.franchised === false)
  //We can add current user roles to collaborator if the role is not already owned and availables in collaborator store
  const availableRolesOptions: Option[] = _.differenceBy(
    me?.reachableRolesList,
    collaborator.collaboratorRoles ?? [],
    'roleName'
  )
    .filter((role) => (franchisedOnly ? !!role.franchised : true))
    .map((e) => ({
      value: e['@id'],
      label: e.name ?? ''
    }))

  //User can only delete role he own
  const canDeleteRole = (role: CollaboratorRoleCollaboratorRoleRead): DeletableRow => {
    if (!role.roleName) return { isDeletable: false }
    if (!me?.reachableRoles?.includes(role.roleName))
      return { isDeletable: false, message: t('page.collaborator.details.roles.tooltips.cantDelete') }
    return { isDeletable: true }
  }

  const deletableRoles: CollaboratorRoleCollaboratorRoleRead[] = _.filter(
    collaborator.collaboratorRoles,
    (collaboratorRole) => canDeleteRole(collaboratorRole).isDeletable
  )

  const availableRoleActionsOptions: Option[] = _.differenceBy(
    me?.reachableActionsList,
    collaborator.additionalActions ?? [],
    'name'
  )
    .filter((roleAction) => (franchisedOnly ? !!roleAction.franchised : true))
    .map((e) => ({
      value: e['@id'],
      label: e.displayName ?? ''
    }))

  //User can only delete actions he own
  const canDeleteRoleAction = (roleAction: RoleActionEntity): DeletableRow => {
    if (!roleAction.name) return { isDeletable: false }
    if (!me?.reachableActions?.includes(roleAction.name))
      return { isDeletable: false, message: t('page.collaborator.details.roleActions.tooltips.cantDelete') }
    return { isDeletable: true }
  }
  const deletableRoleActions: RoleActionEntity[] = _.filter(
    collaborator.additionalActions,
    (additionalAction) => canDeleteRoleAction(additionalAction).isDeletable
  )

  return {
    availableRolesOptions: sortByLocalLabels(availableRolesOptions),
    deletableRoles,
    availableRoleActionsOptions: sortByLocalLabels(availableRoleActionsOptions),
    deletableRoleActions,
    canDeleteRole,
    canDeleteRoleAction
  }
}

//Retrieve role & actions for franchised or not franchised location + pagination props
export const usePermissionsByFranchised = () => {
  const { me } = useAuth()
  const franchisedOnly = !_.some(me?.stores, (store) => store.franchised === false)
  const {
    data: roleActions,
    isFetchingNextPage: roleActionIsFetchingNextPage,
    hasNextPage: roleActionHasNextPage,
    isLoading: roleActionIsLoading,
    fetchNextPage: roleActionFetchNextPage
  } = usePaginatedRoleActions(['permissions-by-franchised', franchisedOnly], {
    'collaboratorRoles.franchised': franchisedOnly || undefined,
    'order[displayName]': SortingDirection.Asc
  })

  const {
    data: roles,
    isFetchingNextPage: roleIsFetchingNextPage,
    hasNextPage: roleHasNextPage,
    fetchNextPage: roleFetchNextPage,
    isLoading: roleIsLoading
  } = usePaginatedRoles(['role', franchisedOnly], {
    franchised: franchisedOnly || undefined,
    'order[name]': SortingDirection.Asc
  })

  const roleActionsOptions: Option[] = roleActions.map((roleAction) => ({
    label: roleAction.displayName ?? '',
    value: roleAction.id
  }))

  const roleOptions: Option[] = roles.map((role) => ({
    label: role.name ?? '',
    value: role.id
  }))

  return {
    roleOptions,
    getRoleByOptionValue: (id?: number) => roles.find((e) => e.id === id),
    rolesPaginationProps: {
      isFetchingNextPage: roleIsFetchingNextPage,
      hasNextPage: roleHasNextPage,
      fetchNextPage: roleFetchNextPage,
      isLoading: roleIsLoading
    },
    roleActionsOptions,
    getRoleActionByOptionValue: (id?: number) => roleActions.find((e) => e.id === id),
    roleActionsOptionsPaginationProps: {
      isFetchingNextPage: roleActionIsFetchingNextPage,
      hasNextPage: roleActionHasNextPage,
      fetchNextPage: roleActionFetchNextPage,
      isLoading: roleActionIsLoading
    }
  }
}

export const useRoleActionByRole = (role: Role) => {
  const { me } = useAuth()

  //Actions owned by user which are not directly availables for selected role
  const availableRolesOptions: Option[] = _.differenceBy(
    me?.reachableActionsList,
    role.collaboratorActions ?? [],
    'name'
  ).map((action) => ({
    label: action.displayName ?? '',
    value: action['@id']
  }))

  return {
    availableRoleActionsOptions: sortByLocalLabels(availableRolesOptions),
    getRoleActionByValue: (id: string) => me?.reachableActionsList?.find((e) => e['@id'] === id)
  }
}

export const useRoleByRoleAction = (roleAction: RoleActionEntity) => {
  const { me } = useAuth()
  const { t } = useTranslation()

  //Roles owned by user which are not directly availables for selected action
  const availableRolesOptions: Option[] = _.differenceBy(
    me?.reachableRolesList,
    roleAction.collaboratorRoles ?? [],
    'roleName'
  ).map((action) => ({
    label: action.name ?? '',
    value: action['@id']
  }))

  //Roles owned by user and directly linked to selected action
  const canDeleteRoleRow = (role: CollaboratorRoleJsonldUserReadUserReadItem): DeletableRow => {
    if (!me?.reachableRoles?.includes(role.roleName!))
      return {
        isDeletable: false,
        message: t('page.permissions.manage.roleAction.tooltips.cantDeleteRole.dontOwnRole')
      }
    return { isDeletable: true }
  }

  const deletableRoles: CollaboratorRoleJsonldUserReadUserReadItem[] = _.filter(
    roleAction.collaboratorRoles,
    (e) => canDeleteRoleRow(e)?.isDeletable
  )
  return {
    availableRolesOptions: sortByLocalLabels(availableRolesOptions),
    deletableRoles,
    getRoleByValue: (id: string) => me?.reachableRolesList?.find((e) => e['@id'] === id),
    canDeleteRoleRow
  }
}

export const useCollaboratorByRole = (role: Role) => {
  const { me } = useAuth()
  //If selected role is not available for franchised store, remove franchised store from query
  const hideFranchised = role.franchised === false
  const availableStores = me?.stores?.filter((store) => !hideFranchised || !store.franchised)?.map((e) => `${e.id}`)
  //Fetch collaborators
  const qp: FetchCollaboratorsQueryParams = {
    'stores[]': availableStores,
    pagination: false,
    'order[lastname]': SortingDirection.Asc,
    'order[firstname]': SortingDirection.Asc
  }
  const queryKey = ['collaborators-by-stores', qp]
  const { data: collaborators, isLoading } = useQuery(queryKey, () => fetchCollaborators(qp))

  //Retrieve collaborators with selected role
  const withRoleCollaborators =
    collaborators?.filter((collaborator) => !!collaborator.collaboratorRoles?.find((e) => e['@id'] === role['@id'])) ??
    []

  //Retrieve collaborators from my store who don't own selected roles
  //Current role can be add to them
  const availableCollaborators = collaborators?.filter(
    (collaborator) => !collaborator.collaboratorRoles?.find((e) => e['@id'] === role['@id'])
  )
  const availableCollaboratorsOptions: Option[] =
    availableCollaborators?.map((collaborator) => ({
      label: `${collaborator.lastname} ${collaborator.firstname}`,
      value: collaborator['@id']
    })) ?? []

  const queryClient = useQueryClient()

  //Mutation
  //No 'isLoading' here to avoid misleading between patch which remove roles and thos which add roles
  //Loading state are handled at component level
  const { mutateAsync: patchCollaboratorMutation } = useMutation(
    ({ id, body }: { id: string; body: PatchCollaboratorBody }) => patchCollaborator({ id, body }),
    {
      emitDefaultSuccessNotification: false,
      onSuccess: (updatedCollaborator) => {
        queryClient.setQueryData<Collaborator[]>(queryKey, _.unionBy([updatedCollaborator], collaborators, 'id'))
      }
    }
  )

  //No need to set deleteableCollaborators as displayed collaborators will always be from the user's localisation
  //Therefore they all can be deleted
  return {
    withRoleCollaborators,
    availableCollaboratorsOptions,
    availableCollaborators,
    isLoading,
    patchCollaboratorMutation
  }
}

export const useCollaboratorByRoleAction = (roleAction: RoleAction) => {
  const { me } = useAuth()
  const { t } = useTranslation()
  //If selected role is not available for franchised store, remove franchised store from query
  const hideFranchised = !_.some(roleAction.collaboratorRoles, (role) => !!role.franchised)
  const availableStores = me?.stores?.filter((store) => !hideFranchised || !store.franchised)?.map((e) => `${e.id}`)
  //Fetch collaborators
  const qp: FetchCollaboratorsQueryParams = {
    'stores[]': availableStores,
    pagination: false,
    withRoles: true,
    'order[lastname]': SortingDirection.Asc,
    'order[firstname]': SortingDirection.Asc
  }
  const queryKey = ['collaborators-by-stores', qp]
  const { data: collaborators, isLoading } = useQuery(queryKey, () => fetchCollaborators(qp))

  //Retrieve collaborators with selected action (in additionalAction or in direct collaboratorRoles)
  const withActionCollaborators =
    collaborators?.filter((collaborator) => !!collaborator.reachableActions?.includes(roleAction.name!)) ?? []

  //Retrieve collaborators from my stores who don't own selected action in additionalAction
  //Current role can be add to them
  const availableCollaborators = _.filter(
    collaborators,
    (e) => !e.additionalActions?.find((additionalAction) => additionalAction['@id'] === roleAction['@id'])
  )

  const canDeleteCollaboratorRow = (collaborator: Collaborator): DeletableRow => {
    if (!collaborator.additionalActions?.find((additionalAction) => additionalAction['@id'] === roleAction['@id']))
      return {
        isDeletable: false,
        message: t('page.permissions.manage.roleAction.tooltips.cantDeleteCollaborator.givenByPermission')
      }
    return {
      isDeletable: true
    }
  }

  //Collaborator who have selected action in their additionalActions can be deleted
  //We can't remove an action which is only inherited from a collaboratorRole
  const deletableCollaboratorsIds = withActionCollaborators
    .filter((collaborator) => canDeleteCollaboratorRow(collaborator)?.isDeletable)
    .map((e) => e['@id']!)

  const availableCollaboratorsOptions: Option[] =
    availableCollaborators?.map((collaborator) => ({
      label: `${collaborator.lastname} ${collaborator.firstname}`,
      value: collaborator['@id']
    })) ?? []

  const queryClient = useQueryClient()

  //Mutation
  //No 'isLoading' here to avoid misleading between patch which remove actions and thos which add actions
  //Loading state are handled at component level
  const { mutateAsync: patchCollaboratorMutation } = useMutation(
    ({ id, body }: { id: string; body: PatchCollaboratorBody }) => patchCollaborator({ id, body }),
    {
      emitDefaultSuccessNotification: false,
      onSuccess: (updatedCollaborator) => {
        queryClient.setQueryData<Collaborator[]>(queryKey, _.unionBy([updatedCollaborator], collaborators, 'id'))
      }
    }
  )

  return {
    withActionCollaborators,
    deletableCollaboratorsIds,
    canDeleteCollaboratorRow,
    availableCollaboratorsOptions,
    availableCollaborators,
    patchCollaboratorMutation,
    isLoading
  }
}
