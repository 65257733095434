import { EventType } from 'types/events'
import { Modals } from 'types/modals'
import { useEmit } from './useEventEmitter'

export const useModals = () => {
  const emit = useEmit()
  const openModal = (modalType: Modals) => {
    emit(EventType.OpenModal, modalType)
  }

  return {
    openModal
  }
}
