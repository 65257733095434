import { yupResolver } from '@hookform/resolvers/yup'
import { GetOrderByIdResponse } from 'api/orders'
import { VoucherCategory } from 'api/vouchers'
import { useOrderContext } from 'app/OrderDetails/helper'
import Button from 'components/Button/Button'
import { Filter } from 'components/FiltersList/FiltersList'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import Select from 'components/Select/Select'
import TextNumberFieldDisplay from 'components/Text/TextNumberFieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import { SaveIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import ShouldHide from 'features/Permissions/ShouldHide'
import _ from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OrderCoupon } from 'types/entities'
import { UnmodifiableOrderStatuses } from 'types/enums'
import { RoleAction, VoucherCategoryName } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { Disclaimer } from '../BillSection/BillSection.style'
import { CouponCardFormContainer, CouponsList } from './CouponCard.style'
import { useCouponVoucherFilters, useCouponVouchers } from './CouponOptions'
import { useRentCouponsOptions } from './RentCouponOptions'

interface CouponCardProps {
  order?: GetOrderByIdResponse
}

interface CouponCardFormInputs {
  coupon?: string
  paymentFees: number
  rentalGesture: number
  shippingFees: number
  commercialGesture: number
}

const schema = yup.object().shape({
  commercialGesture: yup.number(),
  paymentFees: yup.number().transform((value) => (isNaN(value) ? 0 : value)),
  shippingFees: yup.number().transform((value) => (isNaN(value) ? 0 : value))
})

export default function CouponCard({ order }: CouponCardProps) {
  const { checkMatch } = useCheckMatch()
  const { t } = useTranslation()
  const { rentCouponOptions } = useRentCouponsOptions()
  const {
    addOrderVoucher,
    addOrderCoupon,
    patchOrderCoupon,
    updateOrder,
    couponOptions,
    couponOptionsPagination,
    removeCouponOrVoucher,
    orderLoadings,
    couponLoadings,
    voucherLoadings
  } = useCouponVouchers(order)
  const { couponVoucherFilters } = useCouponVoucherFilters(order!)
  const { handleSubmit, control, reset } = useForm<CouponCardFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: CouponCardFormInputs) => {
    if (data.rentalGesture) {
      addOrderVoucher({ value: data.rentalGesture, category: VoucherCategory.RentalGesture })
    }
    if (data.commercialGesture) {
      addOrderVoucher({ value: data.commercialGesture, category: VoucherCategory.CommercialGesture })
    }

    if (data.coupon) {
      // Overwrite order coupon, if any
      if (order?.coupons?.length) {
        // For now, we consider overwriting an order coupon
        // as patching the currently applied one, instead of deleting it then submitting a new one.
        // https://trello.com/c/1DAHMsXw/1542-fixme-ajouter-un-code-promo
        // Subject to change, if multiple coupons are eventually supported.
        order.coupons.map((coupon) =>
          patchOrderCoupon({
            '@id': coupon['@id']!,
            coupon: data.coupon
          })
        )
      } else {
        addOrderCoupon({
          linkedOrder: order?.['@id'],
          coupon: data.coupon
        })
      }
    }

    if (order?.shippingFees !== data.shippingFees || order?.paymentFees !== data.paymentFees) {
      updateOrder({ shippingFees: data.shippingFees, paymentFees: data.paymentFees })
    }
  }

  useEffect(() => {
    reset({
      shippingFees: order?.shippingFees ?? undefined,
      paymentFees: order?.paymentFees ?? undefined
    })
  }, [order, reset])

  const formatCouponDescription = (orderCoupon: OrderCoupon, index: number) => {
    const asteriskCode = _.repeat('*', index + 1) // Sets '*', '**' etc. in case of multiple coupons
    const description = orderCoupon.coupon?.description || t('page.order.detail.tab.payment.couponEmptyDescription')
    return `${asteriskCode}\u00a0${description}`
  }

  const { inMyStoresOrder } = useOrderContext()

  const getInactiveFilters = (filters: Filter[]) => {
    const exclusionList: string[] = []

    if (!checkMatch([RoleAction.ROLE_ACTION_ORDER_EDIT_DISCOUNTS]) || !inMyStoresOrder) {
      exclusionList.push('coupon', VoucherCategoryName.RentalDiscount)
    }

    if (!checkMatch([RoleAction.ROLE_ACTION_ORDER_EDIT_COMMERCIALS_GESTURE]) || !inMyStoresOrder) {
      exclusionList.push(VoucherCategoryName.OrderCommercialGesture)
    }

    return filters
      .filter((filter) => typeof filter.filterId === 'string' && exclusionList.includes(filter.filterId))
      .map((filter) => filter.id)
  }

  return (
    <>
      <Title2>{t('page.order.detail.tab.payment.couponFeesTitle')}</Title2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CouponCardFormContainer>
          <ShouldDisable
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_DISCOUNTS]}
            deniedExtraCondition={!inMyStoresOrder}
          >
            <Select
              label={t('page.order.detail.billing.couponDiscount')}
              placeholder={t('page.order.detail.tab.payment.couponPlaceholder')}
              control={control}
              id="coupon"
              options={couponOptions}
              enableUnselect
              {...couponOptionsPagination}
            />
          </ShouldDisable>

          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_FEES]}
            deniedExtraCondition={!inMyStoresOrder}
            allowedComponent={
              <InputFormattedNumber
                label={t('page.order.detail.tab.payment.paymentFees')}
                placeholder={t('common.label.priceValue', { currency: '€' })}
                suffix=" €"
                decimalScale={2}
                control={control}
                id="paymentFees"
              />
            }
            deniedComponent={
              <TextNumberFieldDisplay
                label={t('page.order.detail.tab.payment.paymentFees')}
                value={order?.paymentFees}
                suffix=" €"
                decimalScale={2}
              />
            }
          />

          <ShouldDisable
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_DISCOUNTS]}
            deniedExtraCondition={!inMyStoresOrder}
          >
            <Select
              label={t('page.order.detail.tab.payment.rentCoupon')}
              placeholder={t('page.order.detail.tab.payment.rentCouponPlaceholder')}
              control={control}
              id="rentalGesture"
              options={rentCouponOptions}
              enableUnselect
            />
          </ShouldDisable>

          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_FEES]}
            deniedExtraCondition={!inMyStoresOrder}
            allowedComponent={
              <InputFormattedNumber
                label={t('page.order.detail.tab.payment.shippingFees')}
                placeholder={t('common.label.priceValue', { currency: '€' })}
                suffix=" €"
                decimalScale={2}
                control={control}
                id="shippingFees"
              />
            }
            deniedComponent={
              <TextNumberFieldDisplay
                label={t('page.order.detail.tab.payment.shippingFees')}
                value={order?.shippingFees}
                suffix=" €"
                decimalScale={2}
              />
            }
          />

          <ShouldDisable
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_COMMERCIALS_GESTURE]}
            deniedExtraCondition={!inMyStoresOrder}
          >
            <InputFormattedNumber
              label={t('page.order.detail.tab.payment.commercialGesture')}
              placeholder={t('common.label.priceValue', { currency: '€' })}
              suffix=" €"
              decimalScale={2}
              control={control}
              id="commercialGesture"
            />
          </ShouldDisable>

          <ShouldHide
            permissions={[
              RoleAction.ROLE_ACTION_ORDER_EDIT_DISCOUNTS,
              RoleAction.ROLE_ACTION_ORDER_EDIT_COMMERCIALS_GESTURE,
              RoleAction.ROLE_ACTION_ORDER_EDIT_FEES
            ]}
            deniedExtraCondition={!inMyStoresOrder}
          >
            <Button
              buttonType="submit"
              shape="circle"
              icon={SaveIcon}
              disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
              isLoading={orderLoadings.update || voucherLoadings.add || couponLoadings.add || couponLoadings.update}
            />
          </ShouldHide>
        </CouponCardFormContainer>
      </form>
      <CouponsList
        showClearAll={false}
        filters={couponVoucherFilters}
        onRemoveFilter={removeCouponOrVoucher}
        disableClick={UnmodifiableOrderStatuses.includes(order?.status!)}
        clearExclusion={getInactiveFilters(couponVoucherFilters)}
      />
      {!!order?.coupons?.length &&
        order.coupons.map((orderCoupon, index) => (
          <Disclaimer color="info" key={orderCoupon['@id']} size="1.2rem">
            {formatCouponDescription(orderCoupon, index)}
          </Disclaimer>
        ))}
    </>
  )
}
