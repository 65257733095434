import { useDownloadFile } from 'api/basePlayInApi'
import { countPurchaseOrders } from 'api/orders'
import { PreviewCounterText } from 'app/OrderPrint/utils.style'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import { Title2 } from 'components/Title/Title.styles'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { TooltipColumnContainer } from 'components/TooltipContents/TootipContents.styles'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon } from 'constants/icons'
import { useEmit } from 'hooks/useEventEmitter'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Store } from 'types/entities'
import { EventType } from 'types/events'
import { stringify } from 'utils/queryParams'
import PrintList from '../../components/OrderPrintList'
import { PreviewButtonContainer } from '../../utils.style'
import CardsPreorderPrintForm from './CardsPreorderPrintForm'
import PrintCardPickup from './components/PrintCardPickup'

interface Props {
  store: Store
  active: boolean
}

const cardOrdersPayload = {
  storeId: 0,
  params: {
    name: 'Envoi cartes',
    preorder: false,
    withCards: true,
    'notClickAndCollect[]': [1, 3]
  }
}

function CardsProductsTab({ store, active }: Props) {
  const { t } = useTranslation()
  const { downloadFile, isLoading } = useDownloadFile()
  const [loadingButton, setLoadingButton] = useState<string>('')
  const emit = useEmit()

  const { data: cardOrders, isFetching: cardOrdersLoading } = useQuery(
    ['countOrderPrint', cardOrdersPayload],
    () => countPurchaseOrders(cardOrdersPayload.storeId, cardOrdersPayload.params),
    {
      enabled: active
    }
  )

  return (
    <>
      <CardLayout>
        <Title2Tooltip
          title={t('page.order.print.details.shipping')}
          text={t('page.order.print.details.tooltips.shipping')}
        />
        <PreviewButtonContainer>
          <Button
            onClick={async () => {
              setLoadingButton('classic')
              await downloadFile(
                `${API_ROUTES.downloads.multipleOrders}/${cardOrdersPayload.storeId}?${stringify(
                  cardOrdersPayload.params
                )}`
              )
              emit(EventType.PrintOrder, null)
            }}
            shape="default"
            icon={PrintIcon}
            size="long"
            isLoading={isLoading && loadingButton === 'classic'}
          >
            {t('page.order.print.actions.printShipping')}
          </Button>
          {cardOrdersLoading || !active ? (
            <Loader />
          ) : (
            <PreviewCounterText>
              {t('page.order.print.details.pendingOrdersCount', { count: cardOrders.totalItems })}
            </PreviewCounterText>
          )}
        </PreviewButtonContainer>
      </CardLayout>
      <PrintCardPickup active={active} />
      <CardLayout>
        <Title2Tooltip
          title={t('page.order.print.details.preorder.title')}
          text={
            <TooltipColumnContainer>
              <div>{t('page.order.print.details.tooltips.preorder.line1')}</div>
              <div>{t('page.order.print.details.tooltips.preorder.line2')}</div>
              <div>{t('page.order.print.details.tooltips.preorder.line3')}</div>
            </TooltipColumnContainer>
          }
        />
        <CardsPreorderPrintForm store={store} active={active} />
      </CardLayout>
      <CardLayout>
        <Title2>{t('page.order.print.details.list')}</Title2>
        <PrintList active={active} storeId={store.id} hasCards={true} />
      </CardLayout>
    </>
  )
}

export default CardsProductsTab
