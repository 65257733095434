import { GetOrderByIdResponse } from 'api/orders'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { InputRow } from '../ShippingCard.style'
import ChronopostLikeShippingForm from './ChronopostLikeShippingForm'

type Props = {
  order?: GetOrderByIdResponse
}

export default function ShippingCardDisplay({ order }: Props) {
  const { t } = useTranslation()

  const getRequiredLabel = (label: string) => {
    return `${label}\u00a0*`
  }

  // TODO: Not available yet
  // const canBeShipped =
  //   !!order?.status &&
  //   ![OrderStatus.Ready, OrderStatus.Treated, OrderStatus.Canceled, OrderStatus.CanceledRequested].includes(
  //     order.status
  //   )

  return (
    <>
      <Title2>{t('page.order.detail.startShippingTitle')}</Title2>
      {order?.shippingMode?.labelGenerated && (
        // TODO: Not available yet
        <ChronopostLikeShippingForm order={order} isShipped={!!order.sentAt} />
      )}
      <InputRow>
        <InputLayout className="no-border" label={getRequiredLabel(t('common.label.trackingNumber'))}>
          <TextFieldDisplay text={order?.trackingNumber} />
        </InputLayout>
        <InputLayout className="no-border" label={t('page.order.detail.weight')}>
          <TextFieldDisplay text={order?.weight?.toString()} />
        </InputLayout>
      </InputRow>
    </>
  )
}
