import { FiltersContainer } from 'components/FiltersList/FiltersList.style'
import styled from 'styled-components'

const largeGap = '10rem'
const defaultGap = '3.5rem'

export const CategoryFormWrapper = styled.form`
  display: grid;
  gap: ${defaultGap};
  padding-bottom: ${defaultGap};
`

export const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem ${largeGap};
  align-items: flex-start;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }
`

export const CategoriesFieldsWrapper = styled(FieldsWrapper)`
  align-items: flex-end;
`

export const SmallGapWrapper = styled.div`
  display: grid;
  gap: 2rem ${defaultGap};
  grid-template-columns: 1fr 1fr calc(50% + (${defaultGap} / 2));
  align-items: flex-end;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const TwoColsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${defaultGap};
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`

export const Separator = styled.div`
  height: 0px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.borderColors.secondary};
  margin-top: ${defaultGap};
  margin-bottom: ${defaultGap};
`

export const StyledFiltersContainer = styled(FiltersContainer)`
  padding-top: 0;
  margin-top: 0;
  border-top: none;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) and (${(props) =>
      props.theme.screenWidth.minTabletScreen}) {
    grid-column: span 2;
  }
`

export const RankWrapper = styled.div`
  display: grid;
  grid-template-columns: calc((100% - ${largeGap}) / 2) auto;
  gap: ${defaultGap};
  align-items: flex-end;
`

export const AddProductFormWrapper = styled.div`
  padding-bottom: 3.5rem;
`

export const ProductList = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 2rem;
  flex-wrap: wrap;
`
