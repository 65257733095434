import { getStocksQueryParams, getStocksUri, useStocksPaginated } from 'api/stock'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { isAfter, startOfToday } from 'date-fns'
import { defaultStockSideBarQp } from 'features/forms/InputSearchForms/StockSearchForm'
import Listing from 'features/Listing/Listing'
import { useStockApiStatus } from 'hooks/useStockStatus'
import { getPath, RouteName } from 'permissions/permissions'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { StockCollectionItem as Stock } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatCalendarDate } from 'utils/dates'
import { mergeFormQueryParams } from 'utils/queryParams'

const defaultSort: OrderParam<getStocksQueryParams> = {
  sortedBy: 'createdAt',
  direction: SortingDirection.Desc,
  queryParams: { 'order[createdAt]': SortingDirection.Desc }
}

interface Props {
  watchFetch?: (boolean) => void
  queryParams: getStocksQueryParams
  store?: string
  isSearched: boolean
}

export default function PurchasesList({ watchFetch, queryParams, store, isSearched }: Props) {
  const { t } = useTranslation()

  const [orderQueryParam, setOrderQueryParam] = useState<OrderParam<getStocksQueryParams>>(defaultSort)
  const navigate = useNavigate()

  const {
    hasNextPage,
    fetchNextPage,
    isFetching,
    totalItems,
    isFetchingNextPage,
    data: stocks
  } = useStocksPaginated(
    queryParams.search
      ? ['stockSidebar', queryParams.search, defaultStockSideBarQp]
      : ['stocks', store, queryParams, orderQueryParam.queryParams],
    getStocksUri({
      ...mergeFormQueryParams<getStocksQueryParams>({ queryParams, order: orderQueryParam.queryParams }),
      store,
      'status[]': queryParams['status[]'],
      transfer: false
    }),
    {
      staleTime: 1000,
      onSuccess: (purchases) => {
        //isSearched check avoid redirect on default search, which can cause confusion for user
        if (isSearched && purchases?.length === 1)
          navigate(getPath(RouteName.StockDetails).replace(':id', `${purchases[0]?.id}`))
      }
    }
  )

  const { getStatusTraduction } = useStockApiStatus()
  const purchaseColumns: Array<Column<Stock, getStocksQueryParams>> = useMemo(
    () => [
      {
        name: t('common.label.date'),
        key: 'createdAt',
        sortable: true,
        orderQueryParam: 'order[createdAt]',
        decorator: (stock: Stock) => (
          <ClickableCell route={RouteName.StockDetails} value={stock.id}>
            <Text>{formatCalendarDate(stock.createdAt)}</Text>
          </ClickableCell>
        )
      },
      {
        name: t('common.label.stockId'),
        key: 'id',
        decorator: (stock: Stock) => (
          <ClickableCell route={RouteName.StockDetails} value={stock.id}>
            <Text color="secondary" fontWeight="light">
              {stock.id}
            </Text>
          </ClickableCell>
        )
      },
      {
        name: t('common.label.retailer'),
        key: 'retailer',
        decorator: (stock: Stock) => (
          <ClickableCell route={RouteName.StockDetails} value={stock.id}>
            <Text>{stock.retailer?.fullName}</Text>
          </ClickableCell>
        )
      },
      {
        name: t('common.label.estimatedReception'),
        key: 'restockDate',
        sortable: true,
        orderQueryParam: 'order[restock]',
        decorator: (stock: Stock) => (
          <ClickableCell route={RouteName.StockDetails} value={stock.id}>
            {stock.productReceived ? (
              <Text color="secondary" fontWeight="light" fontStyle="italic">
                Produits reçus
              </Text>
            ) : stock.restockedAt ? (
              <Text fontWeight="bold">
                {(stock.restockedAt &&
                  isAfter(new Date(stock.restockedAt), startOfToday()) &&
                  stock.approximateRestockDate) ||
                  formatCalendarDate(stock.restockedAt)}
              </Text>
            ) : (
              <Text color="secondary" fontWeight="light">
                -
              </Text>
            )}
          </ClickableCell>
        )
      },
      {
        name: t('common.label.status'),
        key: 'status',
        decorator: (stock: Stock) => (
          <ClickableCell route={RouteName.StockDetails} value={stock.id}>
            <Text>{stock.status ? getStatusTraduction(stock.status) : '-'}</Text>
          </ClickableCell>
        )
      }
    ],
    [t, getStatusTraduction]
  )
  useEffect(() => {
    if (watchFetch) watchFetch(isFetching)
  }, [isFetching, watchFetch])

  return (
    <>
      <CardLayout>
        <Listing
          columns={purchaseColumns}
          data={stocks}
          totalCount={totalItems}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          title={
            isSearched
              ? `${t('page.stock.management.buyCountSearched', { count: totalItems ?? 0 })} :`
              : `${t('page.stock.management.buyCount', { count: totalItems ?? 0 })} :`
          }
          countText={t('page.stock.management.searchBuysCount', {
            current: stocks?.length,
            total: totalItems ?? ''
          })}
          emptyText={isSearched ? t('page.stock.management.emptyBuySearched') : t('page.stock.management.emptyBuy')}
          onOrderChanged={setOrderQueryParam}
          defaultSort={defaultSort}
        />
      </CardLayout>
    </>
  )
}
