import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const Title2TooltipContainer = styled.div`
  display: flex;
`

export const StyledTitle2 = styled(Title2)`
  margin-right: 1rem;
`
