import styled from 'styled-components'

export const FloatingContainer = styled.div`
  position: absolute;
  display: none;

  z-index: 5000;

  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.borderColors.secondary};

  &.switch {
    transform: translateX(-100%);
  }

  &.show {
    display: block;
  }
`
