import { useDownloadFile } from 'api/basePlayInApi'
import { GetRestockPrintsQueryResponse, getRestockPrintsUri, useRestockPrintsPaginated } from 'api/restockPrint'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import { SmallCell } from 'components/SortedTable/SortedTable.styles'
import { Text } from 'components/Text/Text.styles'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'types/entities'
import { formatDateAndHour } from 'utils/dates'
import { PrintSection } from './RestockStorePrint.style'

interface Props {
  store: Store
}

function PrintList({ store }: Props) {
  const { t } = useTranslation()
  const { downloadFile, isLoading } = useDownloadFile()
  const { data, hasNextPage, fetchNextPage, isFetching, isFetched, totalItems, isFetchingNextPage, refetch } =
    useRestockPrintsPaginated(['stocks', store.id], getRestockPrintsUri({ store: `${store.id}` }))

  const restockPrintColumns: Array<Column<GetRestockPrintsQueryResponse>> = useMemo(
    () => [
      {
        name: t('common.label.date'),
        key: 'printedAt',
        decorator: (print) => (
          <Cell>
            <Text>{formatDateAndHour(print.printedAt)}</Text>
          </Cell>
        )
      },
      {
        name: t('common.label.restockNumber'),
        key: 'id',
        decorator: (print) => (
          <Cell>
            <Text>{print.id}</Text>
          </Cell>
        )
      },
      {
        name: t('common.label.collaborator'),
        key: 'printedBy',
        decorator: (print) => (
          <Cell>
            <Text>{[print.printedBy?.firstname, print.printedBy?.lastname].join(' ')}</Text>
          </Cell>
        )
      },
      {
        name: t('common.label.reprint'),
        key: '@id',
        decorator: (print) => (
          <SmallCell>
            <a href={`${process.env.REACT_APP_API_URL}${print.downloadLink}`} target="_blank" rel="noopener noreferrer">
              <Button disabled={!print.store || !print.downloadLink} shape="circle" icon={PrintIcon} />
            </a>
          </SmallCell>
        )
      }
    ],
    [t]
  )

  const onDownload = async () => {
    await downloadFile(`${API_ROUTES.exports.restock}/${store.id}`)
    await refetch()
  }

  return (
    <>
      <PrintSection>
        <Title2Tooltip title={`${t('common.label.restockStore')}`} text={t('tooltips.restock.print.text')} />
        <Button onClick={onDownload} shape="default" icon={PrintIcon} isLoading={isLoading}>
          {t('common.label.print')}
        </Button>
      </PrintSection>
      {isFetched && (
        <CardLayout>
          <Listing
            columns={restockPrintColumns}
            data={data}
            totalCount={totalItems}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            title={`${t('page.restock.management.title')}`}
            countText={t('page.restock.management.currentCount', {
              current: data?.length,
              total: totalItems ?? ''
            })}
            emptyText={t('page.restock.management.emptyPrint')}
          />
        </CardLayout>
      )}
    </>
  )
}

export default PrintList
