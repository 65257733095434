import { yupResolver } from '@hookform/resolvers/yup'
import { closeCashbox, CloseCashboxBody } from 'api/cashbox'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import Textarea from 'components/Textarea/Textarea'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { ArchiveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Cashbox } from 'types/entities'
import { formatCalendarDate } from 'utils/dates'
import { clearCashboxDetails, getCashboxFundedDetails } from 'utils/localStorage'
import * as yup from 'yup'
import CashboxDetailForm from '../form/CashboxDetailForm'
import { ValidationButton } from '../form/CashboxDetailsForm.style'
import { ActiveStepLayout, CloseCashboxCard, CommentForm, TotalText } from './CashboxStep.style'

interface Props {
  isActive: boolean
  cashbox: Cashbox
}

const schema = yup.object().shape({
  comment: yup.string().trim()
})

export default function CashboxCloseStep({ isActive, cashbox }: Props) {
  const { t } = useTranslation()
  const cashboxDeposit = cashbox.totalCashCalculated! - cashbox.cashFundEndCalculated!

  const { handleSubmit, register } = useForm<{ comment?: string }>({
    resolver: yupResolver(schema)
  })
  const queryClient = useQueryClient()

  const { mutate: updateCashbox, isLoading } = useMutation(
    (body: CloseCashboxBody) => closeCashbox(cashbox.id!, body),
    {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData(['cashbox', `${data.id}`])

        queryClient.setQueryData(['cashbox', `${data.id}`], { ...(prev as any), ...data })
        clearCashboxDetails()
      }
    }
  )

  const onSubmit = (formData: { comment?: string }) => {
    const { comment } = formData
    updateCashbox({
      comment
    })
  }

  const cahsboxFundedDetails = getCashboxFundedDetails()
  return (
    <>
      {cashbox?.fundedAt &&
        (isActive ? (
          <>
            <ActiveStepLayout>
              <CardLayout>
                <Title2>{t('page.cashbox.closedStep.formTitle')}</Title2>
                {cahsboxFundedDetails ? (
                  <CashboxDetailForm disabledFields={true} defaultValues={cahsboxFundedDetails} />
                ) : (
                  <Text color="danger">{t('page.cashbox.closedStep.noDetails')}</Text>
                )}
              </CardLayout>
              <CardLayout>
                <Title2>{t('page.cashbox.closedStep.countTitle')}</Title2>
                <TextNumber
                  value={cashboxDeposit > 0 ? cashboxDeposit : 0}
                  ContainerComponent={TotalText}
                  suffix="€"
                  decimalScale={2}
                />
              </CardLayout>
            </ActiveStepLayout>

            <CloseCashboxCard>
              <Title2 style={{ marginBottom: 0 }}>{t('page.cashbox.closedStep.title')}</Title2>
              <Text color="secondary">{t('page.cashbox.closedStep.information')}</Text>
              <CommentForm onSubmit={handleSubmit(onSubmit)}>
                <Textarea
                  rows={3}
                  register={register}
                  id="comment"
                  placeholder={t('page.cashbox.closedStep.commentPlaceholder')}
                />
                <ValidationButton buttonType="submit" variant="warning" icon={ArchiveIcon} isLoading={isLoading}>
                  {t('page.cashbox.closedStep.close')}
                </ValidationButton>
              </CommentForm>
            </CloseCashboxCard>
          </>
        ) : (
          <CardLayout>
            <Title2>{t('page.cashbox.closeStep.date', { date: formatCalendarDate(cashbox?.closedAt) })}</Title2>

            <TextNumber value={cashboxDeposit} fontWeight="light" size="2.8rem" suffix="€" decimalScale={2} />
          </CardLayout>
        ))}
    </>
  )
}
