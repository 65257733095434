import { isAfter } from 'date-fns'
import { Trans } from 'react-i18next'
import slugify from 'slugify'
import { TextColors } from 'theme/theme'
import { formatDateAndHour } from 'utils/dates'

export const getFamilyUrlByName = (name?: string): string =>
  `/${slugify(name ?? '', {
    lower: true,
    locale: 'fr',
    replacement: '-',
    remove: /[*+~.()'"!:@,]/g
  })}`

export const combineDate = (date?: string, hours?: string): Date => {
  if (!date) return new Date()
  if (hours) date += ` ${hours}`
  return new Date(date)
}

export const getDateText = (startAt?: string, endAt?: string) => {
  let dateText: JSX.Element | undefined
  let dateColor: keyof TextColors | undefined

  if (endAt) {
    if (startAt && isAfter(new Date(startAt), new Date())) {
      dateColor = 'danger'
      dateText = (
        <Trans
          values={{
            startAt: formatDateAndHour(startAt),
            endAt: formatDateAndHour(endAt)
          }}
          i18nKey="page.banner.management.dates.inComing"
          components={{ b: <b /> }}
        />
      )
    } else {
      dateColor = 'success'
      dateText = (
        <Trans
          values={{ endAt: formatDateAndHour(endAt) }}
          i18nKey="page.banner.management.dates.onGoing"
          components={{ b: <b /> }}
        />
      )
    }
  } else {
    if (startAt && isAfter(new Date(startAt), new Date())) {
      dateColor = 'danger'
      dateText = (
        <Trans
          values={{ startAt: formatDateAndHour(startAt) }}
          i18nKey="page.banner.management.dates.inCommingStart"
          components={{ b: <b /> }}
        />
      )
    }
  }

  return {
    dateColor,
    dateText
  }
}
