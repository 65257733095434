import { yupResolver } from '@hookform/resolvers/yup'
import Select from 'components/Select/Select'
import useAuth from 'hooks/useAuth'
import { MyStoresOptions, useMyStoresOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextColors } from 'theme/theme'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { HeadingLayout, MainTitleStyle, Subtitle } from './Title.styles'

interface Props {
  title: string
  subtitle?: string
  subTitleColor?: keyof TextColors
  storeOptions?: MyStoresOptions
  showStoreSelect?: boolean
  disabled?: boolean
  tabTitle?: string
}

type LocationForm = {
  store: string | number
}

const schema = yup.object().shape({
  store: yup.string().trim().required()
})

const MainTitle = ({
  title,
  subtitle,
  showStoreSelect = false,
  subTitleColor = 'black',
  storeOptions,
  disabled,
  tabTitle
}: Props) => {
  const { me } = useAuth()
  const { t } = useTranslation()
  const { myStoresOptions } = useMyStoresOptions(storeOptions)
  const [atomLocation, setAtomLocation] = useAtom(defaultStoreAtom)

  const { watch, control } = useForm<LocationForm>({
    resolver: yupResolver(schema),
    defaultValues: { store: atomLocation?.['@id']! }
  })

  const watchLocation = watch('store')

  useEffect(() => {
    if (!!watchLocation) {
      let location = me?.stores?.find((e) => e['@id'] === watchLocation)
      if (location) {
        setAtomLocation(location)
      }
    }
  }, [watchLocation, me?.stores, setAtomLocation])

  return (
    <>
      <Helmet titleTemplate={'%s - Playin Admin'} defer={false}>
        <title>{tabTitle ?? title}</title>
      </Helmet>
      {showStoreSelect ? (
        <HeadingLayout>
          <MainTitle title={title} subtitle={!_.isNil(subtitle) ? subtitle : atomLocation?.shortName} />
          <form>
            <Select
              id="store"
              label={t('common.label.locationChange')}
              options={myStoresOptions}
              layout="row"
              control={control}
              disabled={disabled}
            />
          </form>
        </HeadingLayout>
      ) : (
        <MainTitleStyle>
          <span>{title}</span>
          {subtitle && (
            <Subtitle size="1.8rem" fontWeight="medium" color={subTitleColor} as="span">{`: ${subtitle}`}</Subtitle>
          )}
        </MainTitleStyle>
      )}
    </>
  )
}

export default MainTitle
