import Select, { Option } from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CountWithPerPage, PerPageSelect } from './Listing.style'

interface Props {
  onChange: (perPage: number) => void
  value: number
  perPageOptions?: Option[]
}

const defaultOptions: Option[] = [
  {
    value: 30,
    label: '30'
  },
  {
    value: 60,
    label: '60'
  },
  {
    value: 100,
    label: '100'
  },
  {
    value: 250,
    label: '250'
  },
  {
    value: 500,
    label: '500'
  }
]

interface PerPageInputs {
  perPage: number
}
export default function CountWithPerPageSelect({ onChange, value, perPageOptions }: Props) {
  const { control, watch } = useForm<PerPageInputs>({
    defaultValues: {
      perPage: value
    }
  })

  const perPage = watch('perPage')
  const { t } = useTranslation()

  useEffect(() => {
    if (onChange) onChange(perPage)
  }, [perPage, onChange])

  return (
    <CountWithPerPage>
      <Text color="secondary" fontWeight="light">
        {t('common.listing.perPage')}
      </Text>
      <PerPageSelect>
        <Select id="perPage" control={control} options={perPageOptions ?? defaultOptions} />
      </PerPageSelect>
    </CountWithPerPage>
  )
}
