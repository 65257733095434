import { yupResolver } from '@hookform/resolvers/yup'
import { duplicateStoreEvent, DuplicateStoreEventBody } from 'api/storeEvents'
import { FieldsSection, TimeFieldsSection } from 'app/StoreEventCreate/form/StoreEventForm.style'
import { getEventName } from 'app/StoreEventDetails/utils'
import DatePicker from 'components/DatePicker/DatePicker'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { ConfirmModalWrapper, DeleteModalButton, ModalButtonWrapper } from 'components/Modal/Modal.styles'
import TimePicker from 'components/TimePicker/TimePicker'
import { Title2 } from 'components/Title/Title.styles'
import { SyncIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { getPath, RouteName } from 'permissions/permissions'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { StoreEvent } from 'types/entities'
import { hourFormat } from 'utils/dates'
import { isHourAfterValidation } from 'utils/formHelper'
import * as yup from 'yup'

interface Props extends ModalProps {
  event: StoreEvent
}

const schema = yup.object({
  occursAt: yup.string().trim().required(),
  startsAt: yup.string().trim().required(),
  endsAt: yup
    .string()
    .required()
    .test('isAfter', 'Invalid date', (value, context) => isHourAfterValidation(value, context.parent.startsAt))
})
export interface DuplicateEventInputs extends yup.InferType<typeof schema> {}

export default function DuplicateEventModal({ open, onClose, event }: Props) {
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    register,
    control
  } = useForm<DuplicateEventInputs>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      startsAt: hourFormat(event.startsAt),
      endsAt: hourFormat(event.endsAt)
    }
  })

  const navigate = useNavigate()

  const { mutate: duplicateEventMutation, isLoading } = useMutation(
    (body: DuplicateStoreEventBody) => duplicateStoreEvent(event.id!, body),
    {
      onSuccess: (createdEvent) => navigate(getPath(RouteName.StoreEventDetails).replace(':id', `${createdEvent.id}`))
    }
  )
  const onSubmit = (data) => {
    duplicateEventMutation(data)
  }
  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConfirmModalWrapper>
          <Title2>{t('page.events.details.modals.duplicateEvent.title', { name: getEventName(event) })}</Title2>
          <FieldsSection>
            <div>
              <DatePicker
                id="occursAt"
                setValue={setValue}
                register={register}
                trigger={trigger}
                label={t('common.label.mandatory.date')}
                defaultValue=""
                className={errors.occursAt ? 'is-invalid' : ''}
                autofocus={open}
              />
              <BasicFieldValidationError
                message={t('page.events.create.formErrors.required.occursAt')}
                error={errors.occursAt}
              />
            </div>

            <TimeFieldsSection>
              <div>
                <TimePicker
                  id="startsAt"
                  control={control}
                  label={t('common.label.mandatory.startHour')}
                  className={errors.startsAt ? 'is-invalid' : ''}
                />
                <BasicFieldValidationError
                  messages={{ required: t('page.events.create.formErrors.required.hour') }}
                  error={errors.startsAt}
                />
              </div>

              <div>
                <TimePicker
                  id="endsAt"
                  control={control}
                  label={t('common.label.mandatory.endHour')}
                  className={errors.endsAt ? 'is-invalid' : ''}
                />
                <BasicFieldValidationError
                  messages={{
                    isAfter: t('page.events.create.formErrors.invalid.hour'),
                    required: t('page.events.create.formErrors.required.hour')
                  }}
                  error={errors.endsAt}
                />
              </div>
            </TimeFieldsSection>
          </FieldsSection>

          <ModalButtonWrapper>
            <DeleteModalButton icon={SyncIcon} buttonType="submit" isLoading={isLoading}>
              {t('page.events.details.buttons.duplicateEvent')}
            </DeleteModalButton>
            <DeleteModalButton variant="white" onClick={() => onClose()}>
              {t('common.label.cancel')}
            </DeleteModalButton>
          </ModalButtonWrapper>
        </ConfirmModalWrapper>
      </form>
    </Modal>
  )
}
