import { yupResolver } from '@hookform/resolvers/yup'
import { PatchStoreBody } from 'api/stores'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'
import Button, { Props as ButtonProps } from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Textarea from 'components/Textarea/Textarea'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { UseMutateFunction } from 'react-query'
import { StoreDetails } from 'types/entities'
import { useFormSibblingFields } from 'utils/formHelper'
import * as yup from 'yup'
import { FormSection } from '../StoreSeoTab'
import { FieldsWrapper, FormCard } from '../StoreSeoTab.style'

const schema = yup.object().shape(
  {
    shortDescription: yup
      .string()
      .trim()
      .nullable()
      .when('shortDescriptionEn', { is: (value) => !!value, then: (schema) => schema.required() }),
    shortDescriptionEn: yup
      .string()
      .trim()
      .nullable()
      .when('shortDescription', { is: (value) => !!value, then: (schema) => schema.required() })
  },
  [['shortDescription', 'shortDescriptionEn']]
)

interface FormSectionInputs {
  shortDescription?: string | null
  shortDescriptionEn?: string | null
}

interface Props {
  getSubmitButtonProps: (id: FormSection) => ButtonProps
  mutation: UseMutateFunction<StoreDetails, AxiosResponse<never>, PatchStoreBody>
  store: StoreDetails
}

const getDefaultValues = (store: StoreDetails): FormSectionInputs => ({
  shortDescription: store?.shortDescription,
  shortDescriptionEn: store?.shortDescriptionEn
})

export default function StoreFormSection({ mutation, getSubmitButtonProps, store }: Props) {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger
  } = useForm<FormSectionInputs>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(store)
  })

  const onSubmit = (formData: FormSectionInputs) => {
    mutation(formData)
  }

  useFormSibblingFields([['shortDescription', 'shortDescriptionEn']], { watch, trigger })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormCard>
        <Title2>{t('page.stores.details.storeSeo.sections.store')}</Title2>
        <FieldsWrapper>
          <div>
            <Textarea
              register={register}
              id="shortDescription"
              label={t('page.stores.details.storeSeo.labels.shortDescription', { store: store.name })}
              className={classNames({ 'is-invalid': errors.shortDescription })}
              tooltip={
                <Tooltip id="shortDescriptionTooltip">
                  <Trans i18nKey="page.stores.details.storeSeo.tooltips.shortDescription" />
                </Tooltip>
              }
            />
            <BasicFieldValidationError
              error={errors.shortDescription}
              message={t('form.validation.required.genericFr')}
            />
          </div>

          <div>
            <Textarea
              register={register}
              id="shortDescriptionEn"
              label={t('page.stores.details.storeSeo.labels.shortDescriptionEn', { store: store.name })}
              className={classNames({ 'is-invalid': errors.shortDescriptionEn })}
            />
            <BasicFieldValidationError
              error={errors.shortDescriptionEn}
              message={t('form.validation.required.genericEn')}
            />
          </div>
        </FieldsWrapper>
        <Button {...getSubmitButtonProps('shortDescription')} />
      </FormCard>
    </form>
  )
}
