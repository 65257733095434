import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

/* -------------------------------------------------------------------------- */
/*                                    FETCH                                   */
/* -------------------------------------------------------------------------- */

type FetchCollectionCategoriesParams = Api.GetProductCollectionCategories.RequestQuery
type FetchCollectionCategoriesResponse = Api.GetProductCollectionCategories.ResponseBody['hydra:member'][0]
const getCollectionCategoriesUri = (params?: FetchCollectionCategoriesParams) =>
  [API_ROUTES.collectionCategories.root, stringify(params ?? {})].join('?')

export const usePaginatedCollectionCategories = (
  queryKey: QueryKey,
  params?: FetchCollectionCategoriesParams,
  options?: PaginatedHookOptions<FetchCollectionCategoriesResponse>
) => usePaginatedQuery<FetchCollectionCategoriesResponse>(queryKey, getCollectionCategoriesUri(params), options)

/* -------------------------------------------------------------------------- */
/*                                    POST                                    */
/* -------------------------------------------------------------------------- */

export type PostCollectionCategoryBody = Api.PostProductCollectionCategories.RequestBody
type PostCollectionCategoryResponse = Api.PostProductCollectionCategories.ResponseBody
export const postCollectionCategory = async (body: PostCollectionCategoryBody) => {
  const res = await playInApi.post<PostCollectionCategoryBody, AxiosResponse<PostCollectionCategoryResponse>>(
    API_ROUTES.collectionCategories.root,
    body
  )
  return res.data
}

/* -------------------------------------------------------------------------- */
/*                                    PATCH                                   */
/* -------------------------------------------------------------------------- */

export type PatchCollectionCategoryBody = Api.PatchProductCollectionCategoriesId.RequestBody
type PatchCollectionCategoryResponses = Api.PatchProductCollectionCategoriesId.ResponseBody

export const patchCollectionCategory = async (body: PatchCollectionCategoryBody, id: string) => {
  const res = await playInApi.patch<PatchCollectionCategoryBody, AxiosResponse<PatchCollectionCategoryResponses>>(
    `${API_ROUTES.collectionCategories.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

/* -------------------------------------------------------------------------- */
/*                                   DELETE                                   */
/* -------------------------------------------------------------------------- */

export const deleteCollectionCategory = async (id: string) =>
  await playInApi.delete(`${API_ROUTES.collectionCategories.root}/${id}`)
