import Svg from 'components/Svg/Svg'
import { InfoIcon } from 'constants/icons'
import { Colors } from 'theme/theme'
import { ToggleIconContainer } from './Tooltip.styles'

interface Props {
  color?: keyof Colors
  onClick?: (e: React.MouseEvent) => void
}
export default function ToggleIcon({ color, onClick }: Props) {
  const handleClick = (event) => {
    if (onClick) onClick(event)
  }
  return (
    <ToggleIconContainer color={color} onClick={handleClick}>
      <Svg svg={InfoIcon} color={color} size="1.1rem" allowPropagation={true} />
    </ToggleIconContainer>
  )
}
