import styled from 'styled-components'

const CashboxFormBase = styled.div`
  gap: 2rem;
  align-items: flex-end;
  display: grid;
`

export const DefaultCashboxFormLayout = styled.form`
  padding-bottom: 2rem;
`

export const DefaultCashboxFormWrapper = styled(CashboxFormBase)`
  grid-template-columns: 15rem auto;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr auto;
  }
`

export const AddCashboxFormLayout = styled.form`
  padding-bottom: 2rem;
`

export const AddCashboxFormWrapper = styled(CashboxFormBase)`
  grid-template-columns: 25rem minmax(20rem, 40rem) auto;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr auto;
  }
`

export const ListActions = styled.div`
  padding-left: 1rem;
  padding-bottom: 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
`

export const AddStoreRangeFormWrapper = styled.form`
  display: grid;
  padding-bottom: 2rem;
  align-items: center;
  gap: 0 2rem;
  grid-template-columns: 1fr auto;
`

export const StoreManageLayout = styled.div`
  display: grid;
  gap: 0 2rem;
  grid-template-columns: 1fr 1fr;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr;
  }
`
