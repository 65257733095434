import { yupResolver } from '@hookform/resolvers/yup'
import { createCollaborator, CreateCollaboratorsBody } from 'api/collaborators'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import EmailValidationErrors from 'components/FormValidationErrors/EmailValidationErrors'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import NotAllowedText from 'components/Text/NotAllowedText'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { CreateIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import { useMutation } from 'hooks/useAxiosMutation'
import { useMyStoresOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoleAction } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { FormRegister, Line, RegisterFormActions } from './CollaboratorForms.styles'

type CollaboratorForm = {
  email: string
  firstname: string
  lastname: string
  mainStore: string
}

const schema = yup.object().shape({
  firstname: yup.string().trim().required(),
  lastname: yup.string().trim().required(),
  email: yup.string().email().required(),
  mainStore: yup.string().trim().required()
})

type Props = {
  defaultEmail?: string
}

const CollaboratorRegisterForm = ({ defaultEmail }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [defaultStore] = useAtom(defaultStoreAtom)

  const { handleSubmit, formState, register, setValue, control } = useForm<CollaboratorForm>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      mainStore: defaultStore?.['@id']
    }
  })

  useEffect(() => {
    setValue('email', defaultEmail ?? '')
  }, [defaultEmail, setValue])

  const { errors } = formState

  const { myStoresOptions } = useMyStoresOptions()

  const { mutate: createCollaboratorMutation, isLoading } = useMutation(
    (body: CreateCollaboratorsBody) => createCollaborator(body),
    { onSuccess: (collaborator) => navigate(`/collaborator-details/${collaborator.id}`) }
  )
  const onRegister = (formData: CollaboratorForm) => {
    createCollaboratorMutation(formData)
  }
  const tooltip = useMemo(
    () => <TextTooltip id="register-collaborator-tooltip" text={t('tooltips.collaborator.register.text')} />,
    [t]
  )

  return (
    <CardLayout>
      <Title2>{t('page.collaborator.management.create')}</Title2>
      <CanAccess
        permissions={[RoleAction.ROLE_ACTION_COLLABORATOR_CREATE]}
        allowedComponent={
          <>
            <FormRegister onSubmit={handleSubmit(onRegister)}>
              <div>
                <Input
                  className={`${errors.lastname ? 'is-invalid' : ''}`}
                  label={t('common.label.mandatory.lastName')}
                  id="lastname"
                  register={register}
                />

                <BasicFieldValidationError error={errors.lastname} message={t('form.validation.required.lastname')} />
              </div>

              <div>
                <Input
                  className={`${errors.firstname ? 'is-invalid' : ''}`}
                  label={t('common.label.mandatory.firstName')}
                  id="firstname"
                  register={register}
                />
                <BasicFieldValidationError error={errors.firstname} message={t('form.validation.required.firstname')} />
              </div>

              <Line>
                <Input
                  className={`${errors.email ? 'is-invalid' : ''}`}
                  label={t('common.label.mandatory.email')}
                  type="email"
                  id="email"
                  register={register}
                />
                <EmailValidationErrors error={errors.email} />
              </Line>

              <Line>
                <Text fontWeight="medium" color="info">
                  {t('common.password.autogenerated')}
                </Text>
              </Line>

              <div>
                <Select
                  className={`${errors.mainStore ? 'is-invalid' : ''}`}
                  id="mainStore"
                  control={control}
                  label={t('common.label.primaryLocation')}
                  options={myStoresOptions}
                  tooltip={tooltip}
                />
                <BasicFieldValidationError error={errors.mainStore} message={t('form.validation.required.mainStore')} />
              </div>

              <RegisterFormActions>
                <Button shape="circle" buttonType="submit" isLoading={isLoading} icon={CreateIcon} />
              </RegisterFormActions>
            </FormRegister>
          </>
        }
        deniedComponent={<NotAllowedText />}
      />
    </CardLayout>
  )
}

export default CollaboratorRegisterForm
