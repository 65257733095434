import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import styled from 'styled-components'

export const StyledRowContainer = styled(RowContainer)`
  gap: 5rem;
  align-items: stretch;
`

export const StoreLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid ${(props) => props.theme.borderColors.secondary};
  overflow-x: auto;
`

export const PrintContainer = styled.div`
  width: fit-content;
  height: fit-content;
`

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`

export const StoreRectangleLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 32rem);

  &.printViewMode {
    grid-template-rows: repeat(15, 14.4rem);
  }
`

export const StoreSquareLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 19.6rem);

  &.printViewMode {
    grid-template-rows: repeat(10, 19.6rem);
  }
`

export const OneLineText = styled(Text)`
  text-align: center;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Montserrat;
`

export const TwoLinesText = styled(Text)`
  text-align: center;
  line-height: 2.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  margin: 0 auto;
  font-family: Montserrat;

  &.blue {
    color: #1b1437;
  }

  &.red {
    color: #d7002a;
  }

  &.clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`

export const LabelTitle = styled(TwoLinesText)`
  text-transform: uppercase;
`

export const ArrowIcon = styled(Svg)`
  line-height: 1.8rem;
  margin: 0 0.2rem;
  &.arrow-up {
    transform: rotate(180deg);
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PriceText = styled(Text)`
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  line-height: 4.2rem;
  white-space: nowrap;
  font-family: Montserrat;
`

export const DiscountPriceText = styled(Text)`
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  line-height: 2.8rem;
  color: ${(props) => props.theme.textColors.white};
  font-family: Montserrat;
`

export const DiscountPercentText = styled(Text)`
  position: absolute;
  font-family: Montserrat;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  gap: 3.5rem;
`

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
`

export const PrintActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const StrokeTextNumber = styled(TextNumber)`
  text-decoration-line: line-through;
`
