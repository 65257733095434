import { Navigate, useLocation } from 'react-router-dom'
import { RoleAction } from 'types/playInApiInterfaces'
import CanAccess from './CanAccess'

type Props = {
  permissions?: RoleAction[]
}

export const RestrictedRoute: React.FunctionComponent<Props> = ({ children, permissions = [] }) => {
  const location = useLocation()

  return (
    <CanAccess
      permissions={permissions}
      allowedComponent={children}
      deniedComponent={<Navigate to="/403" state={{ from: location }} replace />}
    />
  )
}

export default RestrictedRoute
