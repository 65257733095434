import { CardPropertiesQueryParams, usePaginatedCards } from 'api/cards'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import Listing, { Columns } from 'features/Listing/Listing'
import { useProductFamilies } from 'hooks/entityHooks/familyHooks'
import { RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation, useNavigate } from 'react-router'
import { Card } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { mergeFormQueryParams } from 'utils/queryParams'
import SearchCardForm, { SearchCardInputs } from './SearchCardForm'

const defaultSort: OrderParam<CardPropertiesQueryParams> = {
  sortedBy: 'name',
  direction: SortingDirection.Asc,
  queryParams: { 'order[name]': SortingDirection.Asc }
}
export default function CardManagement() {
  const { t } = useTranslation()
  const [searchQueryParams, setSearchQueryParams] = useState<CardPropertiesQueryParams>()

  const [searchOrderParams, setSearchOrderParams] = useState<OrderParam<CardPropertiesQueryParams>>(defaultSort)

  const queryParams = mergeFormQueryParams({ searchQueryParams, orderParams: searchOrderParams?.queryParams })

  const {
    data: cards,
    isLoading,
    isIdle,
    totalItems,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = usePaginatedCards(
    searchQueryParams?.search && defaultSort.queryParams === searchOrderParams.queryParams
      ? ['cards:sideSearch', searchQueryParams.search]
      : [queryParams],
    queryParams,
    {
      staleTime: 1000,
      enabled: !!searchQueryParams
    }
  )

  const onSubmit = (formData: SearchCardInputs) => {
    setSearchQueryParams({
      'variations.edition': formData.edition,
      family: formData.family,
      'variations.rarity[]': formData.rarity,
      'cardTypes.id': formData.type,
      rulesText: formData.text,
      'pricingTag.id': formData.pricingTag
    })
  }

  const { getFamilyIcon, getFamilyName } = useProductFamilies()

  const location = useLocation() as Location & { state: null | { search?: string } }
  const navigate = useNavigate()

  const columns: Columns<Card, CardPropertiesQueryParams> = [
    {
      key: 'name',
      name: t('common.label.nameFr'),
      sortable: true,
      orderQueryParam: 'order[name]',
      decorator: (card) => (
        <ClickableCell route={RouteName.CardDetails} value={card.id}>
          {card.name}
        </ClickableCell>
      )
    },
    {
      key: 'nameEn',
      name: t('common.label.nameEn'),
      sortable: true,
      orderQueryParam: 'order[englishName]',
      decorator: (card) => (
        <ClickableCell route={RouteName.CardDetails} value={card.id}>
          <Text fontWeight="light" fontStyle="italic">
            {card.englishName}
          </Text>
        </ClickableCell>
      )
    },
    {
      key: 'range',
      name: t('common.label.range'),
      sortable: true,
      orderQueryParam: 'order[family.name]',
      decorator: (card) => {
        const icon = getFamilyIcon(card.family?.codeName)
        const name = getFamilyName(card.family?.codeName)
        return (
          <ClickableCell route={RouteName.CardDetails} value={card.id}>
            {icon && <Svg svg={icon} size="2rem" title={name} />}
          </ClickableCell>
        )
      }
    },
    {
      key: 'type',
      name: t('common.label.type'),
      decorator: (card) => (
        <ClickableCell route={RouteName.CardDetails} value={card.id}>
          <Text fontStyle="italic" fontWeight="light" color="secondary">
            {card.cardTypes?.map((e) => e.name).join(', ')}
          </Text>
        </ClickableCell>
      )
    }
  ]

  useEffect(() => {
    // Listen search made from order sidebar search
    // if it change, update queryKey to match the request made by the search bar
    // and display results directly
    if (location.state) {
      setSearchQueryParams(location.state)

      // as we consumed current state, we reset it for futur reload
      window.history.replaceState({}, document.title)
    }
  }, [location.state, navigate])

  return (
    <Section>
      <MainTitle title={t('page.card.manage.title')} />
      <SearchCardForm onSubmit={onSubmit} isLoading={isLoading} sidebarSearch={searchQueryParams?.search} />
      {!isIdle && (
        <CardLayout>
          <Listing
            columns={columns}
            data={cards}
            totalCount={totalItems}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            emptyText={t('page.card.manage.listing.emptyText')}
            title={t('page.card.manage.listing.title', { count: totalItems ?? 0 })}
            countText={t('page.card.manage.listing.countText', { count: cards?.length ?? 0, total: totalItems ?? 0 })}
            defaultSort={defaultSort}
            onOrderChanged={setSearchOrderParams}
          />
        </CardLayout>
      )}
    </Section>
  )
}
