import styled from 'styled-components'
import theme, { BorderColors, FontWeight, TextColors } from 'theme/theme'

type Variant = 'default' | 'white' | 'disabled' | 'success' | 'danger' | 'warning' | 'chips'
type Shape = 'default' | 'circle' | 'rectangle'
type Size = 'default' | 'small' | 'long' | 'full' | 'app'
export interface StyledButtonProps {
  variant?: Variant
  shape?: Shape
  size?: Size
  textColor?: keyof TextColors
  fontWeight?: keyof FontWeight
  borderColor?: keyof BorderColors
  iconPosition?: 'left' | 'right'
  isLoading?: boolean
  iconColor?: keyof TextColors
}

const buttonSystem = {
  heights: {
    default: '3.5rem',
    small: '2.5rem',
    circle: '4rem'
  },

  fontSizes: {
    default: '1.4rem',
    small: '1.3rem'
  },

  minWidth: {
    default: '15rem',
    small: '10rem',
    long: '22.5rem',
    full: '100%',
    app: '20rem'
  },

  borderRadius: {
    default: '10rem',
    circle: '50%',
    rectangle: '0.4rem'
  }
}

const defaultButtonStyle = {
  fontWeight: theme.fontWeight.medium,
  borderColor: theme.borderColors.secondary3,
  fontSize: buttonSystem.fontSizes.default,
  minWidth: buttonSystem.minWidth.default,
  height: buttonSystem.heights.default,
  borderRadius: buttonSystem.borderRadius.default,
  color: theme.textColors.white,
  boxShadow: `2px 2px 4px ${theme.borderColors.secondary}`,
  borderWidth: '2px'
}

const buttonTheme = {
  default: {
    ...defaultButtonStyle,
    background: theme.buttonBackgroundColors.default,
    backgroundHover: theme.buttonBackgroundHoverColors.default
  },
  white: {
    ...defaultButtonStyle,
    background: theme.buttonBackgroundColors.white,
    backgroundHover: theme.buttonBackgroundHoverColors.white,
    color: theme.textColors.black
  },
  disabled: {
    ...defaultButtonStyle,
    background: theme.buttonBackgroundColors.disabled,
    backgroundHover: theme.buttonBackgroundHoverColors.disabled,
    color: theme.textColors.black
  },
  success: {
    ...defaultButtonStyle,
    background: theme.buttonBackgroundColors.success,
    backgroundHover: theme.buttonBackgroundHoverColors.success
  },
  danger: {
    ...defaultButtonStyle,
    background: theme.buttonBackgroundColors.danger,
    backgroundHover: theme.buttonBackgroundHoverColors.danger
  },
  warning: {
    ...defaultButtonStyle,
    background: theme.buttonBackgroundColors.warning,
    backgroundHover: theme.buttonBackgroundHoverColors.warning,
    color: theme.textColors.black
  },
  chips: {
    ...defaultButtonStyle,
    background: theme.colors.grey1,
    backgroundHover: theme.colors.grey2,
    color: theme.textColors.black,
    minWidth: 'unset',
    borderWidth: '1px',
    borderColor: theme.colors.grey4,
    iconColor: theme.colors.grey4,
    borderRadius: buttonSystem.borderRadius.rectangle,
    fontWeight: theme.fontWeight.light,
    boxShadow: 'none'
  }
}

const getMinWidth = (props) =>
  props.shape === 'circle'
    ? 'var(--height)'
    : props.size
    ? buttonSystem.minWidth[props.size]
    : buttonTheme[props.variant!].minWidth

const getHeight = (props) =>
  props.size
    ? buttonSystem.heights[props.size]
    : props.shape === 'circle'
    ? buttonSystem.heights.circle
    : buttonTheme[props.variant!].height

export const StyledButton = styled.button<StyledButtonProps>`
  --height: ${(props) => getHeight(props)};
  --fontsize: ${(props) => (props.size ? buttonSystem.fontSizes[props.size] : buttonTheme[props.variant!].fontSize)};
  --textcolor: ${(props) => (props.textColor ? theme.textColors[props.textColor] : buttonTheme[props.variant!].color)};
  --borderWidth: ${(props) => buttonTheme[props.variant!].borderWidth};

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 1rem;
  flex-direction: ${(props) => (props.iconPosition === 'right' ? 'row-reverse' : 'row')};

  min-height: var(--height);
  height: ${(props) => (props.shape === 'circle' ? 'var(--height)' : 'auto')};
  min-width: ${(props) => getMinWidth(props)};

  width: fit-content;
  padding: ${(props) => (props.shape === 'circle' ? 0 : 'calc(0.95rem - var(--borderWidth)) 1rem')};

  border-radius: ${(props) =>
    props.shape ? buttonSystem.borderRadius[props.shape] : buttonTheme[props.variant!].borderRadius};
  border: var(--borderWidth) solid;
  border-color: ${(props) =>
    props.borderColor ? theme.borderColors[props.borderColor] : buttonTheme[props.variant!].borderColor};

  background: ${(props) => buttonTheme[props.variant!].background};
  color: var(--textcolor);
  box-shadow: ${(props) => buttonTheme[props.variant!].boxShadow};

  font-weight: ${(props) =>
    props.fontWeight ? theme.fontWeight[props.fontWeight] : buttonTheme[props.variant!].fontWeight};
  font-size: var(--fontsize);
  line-height: var(--fontsize);
  text-decoration: none;

  pointer-events: ${(props) => (props.isLoading ? 'none' : 'auto')};
  svg {
    display: block;
    ${(props) =>
      props.iconColor || buttonTheme[props.variant!]['iconColor']
        ? `
      path {
        fill: ${props.iconColor || buttonTheme[props.variant!]['iconColor']}!important;
      }    
    `
        : ``}
  }
  &:hover {
    background: ${(props) => buttonTheme[props.variant!].backgroundHover};
    text-decoration: none;
  }
  &:disabled,
  &.disabled {
    --textcolor: ${buttonTheme.disabled.color};
    cursor: default;
    pointer-events: none;
    border-color: ${buttonTheme.disabled.borderColor};
    background: ${buttonTheme.disabled.background};
  }
`

StyledButton.defaultProps = {
  variant: 'default'
}
