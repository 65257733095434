import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import Select from 'components/Select/Select'
import Textarea from 'components/Textarea/Textarea'
import TextNumber from 'components/TextNumber/TextNumber'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import { SaveIcon } from 'constants/icons'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import * as yup from 'yup'
import { ArrowLabelEnum, getDiscountPercentage, LabelFormatEnum, ProductLabel } from '../../storeLabelUtils'
import { Form } from './EditLabelForm.styles'

const schema = yup.object().shape({
  title: yup.string().trim().required(),
  format: yup.string().trim().oneOf([LabelFormatEnum.rectangle, LabelFormatEnum.square]),
  arrow: yup.string().trim().oneOf([ArrowLabelEnum.none, ArrowLabelEnum.up, ArrowLabelEnum.down]),
  price: yup.number().required(),
  discountPrice: yup.number().nullable(),
  discountPercent: yup.number().nullable()
})

type Props = {
  addNewLabel: (data: ProductLabel) => void
  activeLabel?: ProductLabel
  onEdit: () => void
}

const EditLabelForm = ({ addNewLabel, activeLabel, onEdit }: Props) => {
  const { t } = useTranslation()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm<ProductLabel>({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  })

  const [price, discountPrice] = watch(['price', 'discountPrice'])

  useEffect(() => {
    reset({
      title: activeLabel?.title || activeLabel?.product,
      arrow: activeLabel?.arrow || ArrowLabelEnum.none,
      format: activeLabel?.format || LabelFormatEnum.rectangle,
      price: activeLabel?.price,
      discountPercent: activeLabel?.discountPercent,
      discountPrice: activeLabel?.discountPrice
    })
  }, [activeLabel, reset])

  const onSubmit = (data: ProductLabel) => {
    addNewLabel({
      ...data,
      id: activeLabel?.id ?? uuid(),
      discountPercent:
        data.price && data.discountPrice ? getDiscountPercentage(data.price, data.discountPrice) : undefined
    })
    onEdit()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Textarea
        className={classnames('title', { 'is-invalid': errors.title })}
        id="title"
        register={register}
        label={t('page.store.labels.print.modal.form.title')}
        rows={2}
      />
      <Select
        className={classnames({ 'is-invalid': errors.format })}
        id="format"
        control={control}
        options={[
          {
            label: t('page.store.labels.print.modal.form.format.rectangle'),
            value: LabelFormatEnum.rectangle
          },
          {
            label: t('page.store.labels.print.modal.form.format.square'),
            value: LabelFormatEnum.square
          }
        ]}
        label={t('page.store.labels.print.modal.form.format.title')}
      />
      <Select
        className={classnames({ 'is-invalid': errors.arrow })}
        id="arrow"
        control={control}
        options={[
          { label: t('page.store.labels.print.modal.form.arrow.none'), value: ArrowLabelEnum.none },
          { label: t('page.store.labels.print.modal.form.arrow.up'), value: ArrowLabelEnum.up },
          { label: t('page.store.labels.print.modal.form.arrow.down'), value: ArrowLabelEnum.down }
        ]}
        label={t('page.store.labels.print.modal.form.arrow.title')}
      />
      <InputFormattedNumber
        className={classnames({ 'is-invalid': errors.price })}
        id="price"
        control={control}
        decimalScale={2}
        suffix=" €"
        label={t('page.store.labels.print.modal.form.price')}
      />
      <InputFormattedNumber
        className={classnames({ 'is-invalid': errors.discountPrice })}
        id="discountPrice"
        control={control}
        decimalScale={2}
        suffix=" €"
        label={t('page.store.labels.print.modal.form.discountPrice')}
        tooltip={
          <MultiLineTooltip
            id="printLabelDiscountPrice"
            lines={[
              t('page.store.labels.print.modal.form.tooltips.discountPrice.line1'),
              t('page.store.labels.print.modal.form.tooltips.discountPrice.line2')
            ]}
          />
        }
      />

      <InputLayout className="no-border" label={t('page.store.labels.print.modal.form.discountPercent')}>
        <TextNumber
          value={price && discountPrice ? getDiscountPercentage(price, discountPrice) : undefined}
          decimalScale={0}
          suffix="%"
        />
      </InputLayout>

      <Button shape="circle" icon={SaveIcon} buttonType="submit" />
    </Form>
  )
}

export default EditLabelForm
