import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const AppVersion = styled(Text)`
  margin-left: auto;
  align-self: flex-end;
`

export const StyledSection = styled(Section)`
  display: flex;
`
