import { GetStoreEventFormatsResponse, GetStoreEventRangesResponse } from 'api/storeEvents'
import { Filter } from 'components/FiltersList/FiltersList'
import { useEventStatusOptions } from 'hooks/useSelectOptions'
import _, { isNil } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { CustomStoreEventStatus } from 'types/enums'
import { formatCalendarDate } from 'utils/dates'
import { SearchEventInputs } from '../StoreEventManage'

const removeEmptyFields = (formData: SearchEventInputs) => {
  return _.omitBy(formData, (value) => isNil(value) || value === '')
}

interface Props {
  ranges?: GetStoreEventRangesResponse['hydra:member']
  formats?: GetStoreEventFormatsResponse['hydra:member']
}
export default function useStoreEventFilter({ ranges, formats }: Props) {
  const { getEventStatusLabel } = useEventStatusOptions()
  const defaultFilters = [
    {
      id: 'status',
      filterId: `status-${CustomStoreEventStatus.Current}`,
      label: getEventStatusLabel(CustomStoreEventStatus.Current)
    }
  ]

  const [filters, setFilters] = useState<Filter[]>(defaultFilters)

  const filtersFn = useMemo(
    () => ({
      date: (value) => ({ id: 'date', label: formatCalendarDate(value) }),
      status: (value) => ({ id: 'status', label: getEventStatusLabel(value) }),
      range: (value) => ({ id: 'range', label: ranges?.find((e) => e['@id'] === value)?.name }),
      format: (value) => ({ id: 'format', label: formats?.find((e) => e['@id'] === value)?.name })
    }),
    [getEventStatusLabel, ranges, formats]
  )

  const handleFilters = useCallback(
    (formData: SearchEventInputs) => {
      setFilters(
        _.map(removeEmptyFields(formData), (value, key) => {
          return filtersFn[key](value)
        })
      )
    },
    [filtersFn]
  )

  return { filters, handleFilters }
}
