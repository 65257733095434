import _ from 'lodash'
import { Languages, OrderType, PreoderOptions } from './types'

export const getPrintName = ({ preorder, orderType, withProducts, withCards }: PreoderOptions) => {
  const parts: string[] = []
  if (preorder) parts.push('Préco')

  switch (orderType) {
    case OrderType.Classic:
      parts.push('Envoi classique')
      break
    case OrderType.Sellermania:
      parts.push('Sellermania')
      break
    case OrderType.ClickAndCollectBnf:
      parts.push('Click & Collect Paris BNF')
      break
    case OrderType.ClickAndCollectRivoli:
      parts.push('Click & Collect Paris Rivoli')
      break
    case OrderType.ClickAndCollectAnnecy:
      parts.push('Click & Collect Annecy')
      break
  }

  const opts: string[] = []

  if (_.isBoolean(withCards)) opts.push(withCards ? 'Avec Cartes' : 'Sans Carte')
  if (_.isBoolean(withProducts)) opts.push(withProducts ? 'Avec Produits' : 'Sans Produit')

  if (opts.length) parts.push(opts.join(' / '))

  return parts.join(' - ')
}

export const getOrderTypeParams = (orderType?: OrderType) => {
  switch (orderType) {
    case OrderType.Classic:
      return { 'notClickAndCollect[]': [1, 3] }
    case OrderType.Sellermania:
      return { sellermania: true }
    case OrderType.ClickAndCollectBnf:
      return { clickAndCollect: 1 }
    case OrderType.ClickAndCollectRivoli:
      return { clickAndCollect: 3 }
    case OrderType.ClickAndCollectAnnecy:
      return { clickAndCollect: 2 }
    default:
      return {}
  }
}

export const getLanguageParams = (cardLanguage?: Languages) => {
  switch (cardLanguage) {
    case Languages.Fr:
      return { 'cardLang[]': [Languages.Fr] }
    case Languages.En:
      return { 'cardLang[]': [Languages.En] }
    case Languages.FrEn:
      return { 'cardLang[]': [Languages.Fr, Languages.En] }
    default:
      return {}
  }
}
