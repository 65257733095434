import styled from 'styled-components'
export const FieldsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5rem;
  margin-bottom: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
export const Column = styled.div`
  display: grid;
  gap: 2rem;
`
export const SeeMoreContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const TextFieldColumn = styled(Column)`
  grid-column: span 2;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-column: span 1;
    grid-template-columns: 1fr;
  }

  & > div {
    padding-left: 3rem;
  }
`

export const FieldSection = styled.div`
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  gap: 0.5rem;
`

export const FreeInputContainer = styled.div`
  grid-column: span 2;
`
