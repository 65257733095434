import { API_ROUTES } from 'constants/configs'
import { useQuery } from 'react-query'
import { Lang } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type FetchLangsResponse = Api.GetLangsId.ResponseBody
const fetchLangs = async (): Promise<Lang[]> => {
  const res = await playInApi.get<FetchLangsResponse>(API_ROUTES.langs.root)

  return res.data['hydra:member']
}

export const useGetLangs = () => {
  return useQuery(['langs'], () => fetchLangs())
}
