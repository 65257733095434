import { LinkProps } from 'features/Permissions/Link/Link'
import { LinkCell } from './SortedTable.styles'

const ClickableCell: React.FC<LinkProps> = ({
  className,
  children,
  route,
  placeholder,
  value,
  onMouseEnter,
  onMouseLeave,
  state
}) => {
  return (
    <LinkCell
      className={className}
      route={route}
      placeholder={placeholder}
      value={value}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      state={state}
    >
      {children}
    </LinkCell>
  )
}
export default ClickableCell
