import { useTranslation } from 'react-i18next'
import { Text } from './Text.styles'

export default function NotAllowedText() {
  const { t } = useTranslation()
  return (
    <Text fontStyle="italic" fontWeight="light" color="secondary" size="1.3rem">
      {t('common.notAllowed')}
    </Text>
  )
}
