import { patchCustomer, PatchCustomerBody } from 'api/customers'
import {
  eventByIdQueryKey,
  patchStoreEventCustomer,
  PatchStoreEventCustomerBody,
  patchStoreEventOrder,
  PatchStoreEventOrderBody
} from 'api/storeEvents'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { FieldNamesMarkedBoolean } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Customer, StoreEvent } from 'types/entities'
import { StoreEventCustomerJsonldEventReadEventReadItem } from 'types/playInApiInterfaces'
import AddCustomerForm, { AddCustomerInputs } from '../AddCustomerModal/AddCustomerForm'
import SearchCustomerForm from '../AddCustomerModal/SearchCustomerForm'
import { UpdateText } from './UpdateCustomerModal.style'
type EventCustomerItem = StoreEventCustomerJsonldEventReadEventReadItem

interface Props extends ModalProps {
  selectedCustomer: EventCustomerItem
  event: StoreEvent
}

export default function UpdateCustomerModal({ onClose, selectedCustomer, event, ...modalProps }: Props) {
  const { t } = useTranslation()
  const [newCustomer, setNewCustomer] = useState<Customer>()
  const [touchedFields, setTouchedFields] = useState<FieldNamesMarkedBoolean<AddCustomerInputs>>({})

  const queryClient = useQueryClient()

  const { mutateAsync: updateStoreEventCustomer, isLoading: isStoreEventCustomerUpdating } = useMutation(
    (body: PatchStoreEventCustomerBody) => patchStoreEventCustomer(body, selectedCustomer.id!)
  )

  const { mutateAsync: updateStoreEventOrder, isLoading: isStoreEventOrderUpdating } = useMutation(
    (body: PatchStoreEventOrderBody) => patchStoreEventOrder(body, selectedCustomer.linkedOrder?.id!),
    {
      onSuccess: () => {
        setNewCustomer(undefined)
      }
    }
  )

  const { mutateAsync: updateCustomer, isLoading: isCustomerUpdating } = useMutation((body: PatchCustomerBody) =>
    patchCustomer(newCustomer?.id!, body)
  )

  const onSubmit = (formData: AddCustomerInputs) => {
    const {
      firstname: touchedFirstname,
      lastname: touchedLastname,
      additionalData: touchedAdditionalData,
      price: touchedPrice,
      paymentMode: touchedPaymentMode,
      paymentReceivedBy: touchedPaymentReceivedBy
    } = touchedFields

    const { additionalData, price, paymentMode, lastname, firstname, paymentReceivedBy } = formData
    const promises: any[] = []

    // Current api version does'nt allow us to make only one request, this is subject to change in future

    // If firstname or lastname are updated -> PATCH api/customers
    if (touchedFirstname || touchedLastname)
      promises.push(
        updateCustomer({
          firstname: touchedFirstname ? firstname : undefined,
          lastname: touchedLastname ? lastname : undefined
        })
      )

    // If additionalData / price / paymentLocalisation are updated -> PATCH api/store-event-customers
    if (touchedAdditionalData || touchedPrice || touchedPaymentReceivedBy)
      promises.push(
        updateStoreEventCustomer({
          additionalData: touchedAdditionalData ? additionalData : undefined,
          paidPrice: touchedPrice ? price : undefined,
          paymentReceivedBy: touchedPaymentReceivedBy ? paymentReceivedBy : undefined
        })
      )

    // If paymentMode is updated or if we set a new customer -> PATCH api/store-event-orders
    if (touchedPaymentMode || (newCustomer && newCustomer.id !== selectedCustomer.id)) {
      setNewCustomer(undefined)
      promises.push(
        updateStoreEventOrder({
          customer: newCustomer ? newCustomer?.['@id'] : undefined,
          paymentMode: touchedPaymentMode ? paymentMode : undefined
        })
      )
    }

    Promise.all(promises).then(() => {
      onClose()
      queryClient.refetchQueries(eventByIdQueryKey(event.id!))
    })
  }

  return (
    <Modal
      onClose={() => {
        onClose()
        setNewCustomer(undefined)
      }}
      {...modalProps}
    >
      <Title2>{t('page.events.details.modals.updateCustomer.title')}</Title2>
      <SearchCustomerForm selectedCustomer={newCustomer} setSelectedCustomer={setNewCustomer} event={event} />
      <UpdateText>
        {!!newCustomer ? (
          <>
            <Text fontStyle="italic" color="secondary">
              {t('page.events.details.modals.updateCustomer.infos.line1', {
                lastname: selectedCustomer?.linkedOrder?.customer?.lastname,
                firstname: selectedCustomer?.linkedOrder?.customer?.firstname,
                newName: newCustomer.fullName || newCustomer.email
              })}
            </Text>
            <Text fontStyle="italic" color="secondary">
              {t('page.events.details.modals.updateCustomer.infos.line2', {
                name: newCustomer.fullName || newCustomer.email
              })}
            </Text>
          </>
        ) : (
          <Text fontStyle="italic" color="secondary">
            {t('page.events.details.modals.updateCustomer.chooseCustomer')}
          </Text>
        )}
      </UpdateText>
      {selectedCustomer && (
        <AddCustomerForm
          onSubmit={onSubmit}
          onClose={() => {
            onClose()
            setNewCustomer(undefined)
          }}
          isLoading={isStoreEventOrderUpdating || isStoreEventCustomerUpdating || isCustomerUpdating}
          customer={newCustomer ?? selectedCustomer.linkedOrder?.customer}
          event={event}
          onTouchedFields={setTouchedFields}
          isEdit={true}
          defaultValues={{
            lastname: selectedCustomer.linkedOrder?.customer?.lastname,
            firstname: selectedCustomer.linkedOrder?.customer?.firstname,
            email: selectedCustomer.linkedOrder?.customer?.email,
            price: selectedCustomer.paidPrice,
            paymentMode: selectedCustomer.linkedOrder?.paymentMode?.['@id'],
            additionalData: selectedCustomer.additionalData,
            paymentReceivedBy: selectedCustomer.linkedOrder?.paymentReceivedStore?.['@id']
          }}
        />
      )}
    </Modal>
  )
}
