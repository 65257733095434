import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import { setJWT } from 'utils/localStorage'
import playInApi from './basePlayInApi'

type LoginBody = Api.PostAuthenticate.RequestBody
type LoginResponse = Api.PostAuthenticate.ResponseBody
export const authenticate = async (body: LoginBody) => {
  const { data } = await playInApi.post<LoginBody, AxiosResponse<LoginResponse>>(API_ROUTES.authenticate.root, body, {
    baseURL: process.env.REACT_APP_API_URL
  })
  if (!data.token || !data.me) {
    throw new Error('No authentication data')
  }

  setJWT(data.token)
  return data
}
