import { StyledCell } from 'app/OrderDetails/forms/orderEntriesTable/OrderEntriesTable.styles'
import { Column } from 'components/SortedTable/SortedTable'
import { Container, HeaderTitle, Table, Tbody, Td, Th, Thead, Tr } from 'components/SortedTable/SortedTable.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { OrderEntry } from 'types/entities'
import { ChildrenPackRow } from '../BaseModal.style'
import { QtyEntry } from '../SplitOrderModal'
import EntryQuantity from './EntryQuantity'

export interface Props {
  columns: Array<Column<OrderEntry>>
  data: Array<OrderEntry>
  isLoading: boolean
  handleQuantity: (entry: OrderEntry, qty: number) => void
  entryQuantities: Map<string, QtyEntry>
  getCellProperties: (entry: OrderEntry) => any
}

export default function SplitOrderListing({
  columns,
  data,
  isLoading = false,
  handleQuantity,
  entryQuantities,
  getCellProperties
}: Props) {
  return (
    <Container>
      <Table>
        <Thead>
          <Tr>
            {columns.map((col) => (
              <Th sortable={col.sortable} key={`${col.key}`}>
                <HeaderTitle>
                  {typeof col.name === 'string' ? <span>{col.name}</span> : col.name}
                  {col.tooltip ? <Tooltip id={col.key}>{col.tooltip}</Tooltip> : null}
                </HeaderTitle>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading &&
            data.map((row, index) => (
              <>
                <Tr key={index}>
                  {columns.map((col, i) => (
                    <Td key={`${row[col.key]}-${i}`}>{col.decorator(row)}</Td>
                  ))}
                </Tr>
                {!!row.childPack?.length &&
                  // If entry has childPack, add children rows
                  row.childPack?.map((child) => (
                    <Tr>
                      <Td>
                        <StyledCell className={getCellProperties(child)?.classname}></StyledCell>
                      </Td>
                      <Td colSpan={3}>
                        <ChildrenPackRow>
                          <StyledCell className={getCellProperties(child)?.classname}>
                            <EntryQuantity
                              entry={child}
                              handleQuantity={handleQuantity}
                              disabled={
                                typeof child.parentPack === 'string' &&
                                !!entryQuantities.get(child.parentPack)?.isUpdated
                              }
                            />
                          </StyledCell>

                          {columns?.find((col) => col.key === 'cardOrProduct')?.decorator(child)}
                        </ChildrenPackRow>
                      </Td>
                    </Tr>
                  ))}
              </>
            ))}
        </Tbody>
      </Table>
    </Container>
  )
}
