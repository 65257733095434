import { GetOrderByIdResponse } from 'api/orders'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { PaymentModeCardFormContainer } from './PaymentStatusCard.style'

interface PaymentModeCardProps {
  order?: GetOrderByIdResponse
}

export default function PaymentStatusDisplay({ order }: PaymentModeCardProps) {
  const { t } = useTranslation()

  return (
    <>
      <Title2>{t('page.order.detail.tab.payment.paymentStatusTitle')}</Title2>
      <PaymentModeCardFormContainer>
        <InputLayout className="no-border" label={t('page.order.detail.tab.payment.paymentStatus')}>
          <Text fontWeight="medium">
            {order?.paymentReceived
              ? t('common.select.paymentStatus.received')
              : t('common.select.paymentStatus.notReceived')}
          </Text>
        </InputLayout>
        <InputLayout className="no-border" label={t('page.order.detail.tab.payment.paymentReceivedAtLabel')}>
          <TextFieldDisplay text={order?.paymentReceivedStore?.shortName} />
        </InputLayout>
      </PaymentModeCardFormContainer>
    </>
  )
}
