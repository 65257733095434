import { yupResolver } from '@hookform/resolvers/yup'
import {
  eventByIdQueryKey,
  postCancelStoreEventCustomer,
  PostCancelStoreEventCustomerBody,
  PostCancelStoreEventCustomerParams
} from 'api/storeEvents'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { ConfirmModalWrapper } from 'components/Modal/Modal.styles'
import Select from 'components/Select/Select'
import Cell from 'components/SortedTable/Cell'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { DeleteIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import { useMutation } from 'hooks/useAxiosMutation'
import { useMyStoresOptions, usePaymentModeFilters } from 'hooks/useSelectOptions'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { StoreEvent } from 'types/entities'
import { StoreEventCustomerJsonldEventReadEventReadItem } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { AddCustomerButtonsWrapper } from '../AddCustomerModal/AddCustomerModal.style'
import { RefundFormWrapper, RefundWrapper } from './DeleteCustomerModal.style'

type EventCustomerItem = StoreEventCustomerJsonldEventReadEventReadItem

interface Props extends ModalProps {
  selectedCustomers: EventCustomerItem[]
  event: StoreEvent
}

type RefundInputs = {
  store?: string
  paymentMode?: string
}

const schema = yup.object().shape(
  {
    store: yup.string().when('paymentMode', {
      is: (value) => !!value,
      then: (schema) => schema.required()
    }),
    paymentMode: yup.string().when('store', {
      is: (value) => !!value,
      then: (schema) => schema.required()
    })
  },
  [['store', 'paymentMode']]
)
export default function DeleteCustomerModal({ onClose, open, selectedCustomers, event }: Props) {
  const { t } = useTranslation()

  const columns: Columns<EventCustomerItem> = [
    {
      key: 'name',
      name: t('common.label.name'),
      decorator: (customer) => {
        const { firstname, lastname } = customer?.linkedOrder?.customer ?? {}
        return <Cell>{`${firstname} ${lastname}`}</Cell>
      }
    },
    {
      key: 'email',
      name: t('common.label.email'),
      decorator: (customer) => <Cell>{customer?.linkedOrder?.customer?.email}</Cell>
    },
    {
      key: 'price',
      name: t('common.label.price'),
      decorator: (customer) => (
        <Cell>
          <TextNumber placeholder="-" suffix={`\u00a0€`} value={customer.paidPrice} />
        </Cell>
      )
    },
    {
      key: 'paymentMode',
      name: t('common.label.paymentMethod'),
      decorator: (customer) => <Cell>{customer.linkedOrder?.paymentMode?.name}</Cell>
    }
  ]

  const queryClient = useQueryClient()
  const { mutateAsync: cancelStoreEventCustomer, isLoading } = useMutation(
    ({
      body,
      id,
      params
    }: {
      id: number
      body: PostCancelStoreEventCustomerBody
      params: PostCancelStoreEventCustomerParams
    }) => postCancelStoreEventCustomer({ body, id, params }),
    {
      onSuccess: (updatedCustomer, { id }) => {
        const prev = queryClient.getQueryData<StoreEvent>(eventByIdQueryKey(event.id!))
        queryClient.setQueryData(eventByIdQueryKey(event.id!), {
          ...prev,
          turnover: updatedCustomer.refunded
            ? (prev?.turnover ?? 0) - (updatedCustomer.paidPrice ?? 0)
            : prev?.turnover,
          customers: prev?.customers?.map((oldCustomer) => (oldCustomer.id === id ? updatedCustomer : oldCustomer))
        })
      }
    }
  )

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<RefundInputs>({
    resolver: yupResolver(schema)
  })

  const handleClose = () => {
    onClose()
    reset()
  }

  const onSubmit = async (formData: RefundInputs) => {
    const { store, paymentMode } = formData
    for (let customer of selectedCustomers) {
      const body: PostCancelStoreEventCustomerBody = { paymentModes: [] }

      if (customer.paidPrice && store && paymentMode)
        body.paymentModes = [
          {
            refundedAmount: customer.paidPrice,
            paymentMode,
            store
          }
        ]

      await cancelStoreEventCustomer({
        id: customer.id!,
        body,
        params: {
          refund: !!paymentMode && !!store
        }
      })
    }
    handleClose()
  }

  const { myStoresOptions } = useMyStoresOptions()
  const { paymentModeOptions } = usePaymentModeFilters()

  const showRefundSection = _.some(selectedCustomers, (customer) => customer.paidPrice && customer.paidPrice > 0)
  return (
    <Modal open={open} onClose={handleClose}>
      <ConfirmModalWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title2>{t('page.events.details.modals.deleteCustomer.title', { count: selectedCustomers.length })}</Title2>
          <Listing data={selectedCustomers} columns={columns} />
          {showRefundSection && (
            <RefundWrapper>
              <Text fontWeight="light" color="secondary">
                <Trans i18nKey="page.events.details.modals.deleteCustomer.refundText" />
              </Text>

              <RefundFormWrapper>
                <div>
                  <Select
                    options={paymentModeOptions}
                    id="paymentMode"
                    control={control}
                    label={t('page.events.details.modals.deleteCustomer.labels.refundPaymentMode')}
                    enableUnselect
                    placeholder={t('common.select.defaultOptions.paymentMode')}
                    className={classNames({
                      'is-invalid': errors.paymentMode
                    })}
                  />
                  <BasicFieldValidationError
                    message={t('form.validation.required.generic')}
                    error={errors.paymentMode}
                  />
                </div>
                <div>
                  <Select
                    options={myStoresOptions}
                    id="store"
                    control={control}
                    enableUnselect
                    placeholder={t('common.select.defaultOptions.localisation')}
                    label={t('page.events.details.modals.deleteCustomer.labels.refundOrigin')}
                    className={classNames({
                      'is-invalid': errors.store
                    })}
                  />
                  <BasicFieldValidationError message={t('form.validation.required.generic')} error={errors.store} />
                </div>
              </RefundFormWrapper>
            </RefundWrapper>
          )}

          <AddCustomerButtonsWrapper withPadding={!showRefundSection}>
            <Button buttonType="submit" icon={DeleteIcon} isLoading={isLoading}>
              {t('page.events.details.modals.deleteCustomer.removeBtn', { count: selectedCustomers.length })}
            </Button>
            <Button variant="white" onClick={handleClose}>
              {t('common.button.cancel')}
            </Button>
          </AddCustomerButtonsWrapper>
        </form>
      </ConfirmModalWrapper>
    </Modal>
  )
}
