import { StockError } from 'app/StockDetails/helpers'
import { Modals } from './modals'
import { NotificationEvent } from './notifications'

export enum EventType {
  Notification = 'notification',
  Logout = 'logout',
  AddFidelityPoint = 'addFidelityPoint',
  TransformFidelityPoint = 'transformFidelityPoint',
  AddCustomerVoucher = 'addCustomerVoucher',
  OpenModal = 'openModal',
  PatchStockError = 'patchStockError',
  PrintOrder = 'printOrder'
}

export interface Events {
  [EventType.Notification]: NotificationEvent
  [EventType.Logout]: null
  [EventType.AddFidelityPoint]: null
  [EventType.TransformFidelityPoint]: null
  [EventType.AddCustomerVoucher]: null
  [EventType.OpenModal]: Modals
  [EventType.PatchStockError]: StockError[]
  [EventType.PrintOrder]: null
}
