import { yupResolver } from '@hookform/resolvers/yup'
import { useMutateCollaborator } from 'api/collaborators'
import { getCustomersUri } from 'api/customers'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { CrossIcon, SaveIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import useAuth from 'hooks/useAuth'
import { RouteName } from 'permissions/permissions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Collaborator } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { getRequiredLabel } from 'utils/formHelper'
import * as yup from 'yup'
import { CustomerLink, CustomerLinkWrapper, FormWrapper, TwoColsRow } from '../CollaboratorDetails.style'
import { isInMyStores } from '../utils'
import CollaboratorDetailsFormDisplay from './CollaboratorDetailsFormDisplay'

interface Props {
  collaborator: Collaborator
}

const schema = yup.object({
  firstname: yup.string().trim().required(),
  lastname: yup.string().trim().required(),
  email: yup.string().trim().required(),
  customerAccount: yup.string()
})

type CollaboratorInformationsInputs = yup.InferType<typeof schema>

export const getDefaultValues = (collaborator: Collaborator) => ({
  firstname: collaborator.firstname,
  lastname: collaborator.lastname,
  email: collaborator.email,
  customerAccount: collaborator.customerAccount?.['@id']
})

export default function CollaboratorDetailsForm({ collaborator }: Props) {
  const { t } = useTranslation()
  const { customerAccount } = collaborator
  const { me } = useAuth()

  const { handleSubmit, register, control, reset } = useForm<CollaboratorInformationsInputs>({
    resolver: yupResolver(schema)
  })

  const { mutate: patchCollaboratorMutation, isLoading } = useMutateCollaborator(collaborator.id!)

  const deleteCustommerAccount = () => {
    patchCollaboratorMutation({
      customerAccount: null
    })
  }

  const onSubmit = (formData: CollaboratorInformationsInputs) => {
    patchCollaboratorMutation(formData)
  }

  useEffect(() => {
    reset(getDefaultValues(collaborator))
  }, [collaborator, reset])

  const enabledCustomerAccount = false

  return (
    <CardLayout>
      <Title2>{t('page.collaborator.details.informationsTitle')}</Title2>
      <CanAccess
        permissions={[RoleAction.ROLE_ACTION_COLLABORATOR_EDIT]}
        deniedExtraCondition={!collaborator.active || !isInMyStores(collaborator, me?.stores)}
        allowedComponent={
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormWrapper>
              <TwoColsRow>
                <Input register={register} id="lastname" label={getRequiredLabel(t('common.label.lastName'))} />
                <Input register={register} id="firstname" label={getRequiredLabel(t('common.label.firstName'))} />
              </TwoColsRow>
              <Input register={register} id="email" label={getRequiredLabel(t('common.label.email'))} />
              {enabledCustomerAccount && (
                <>
                  <InputAutoComplete
                    control={control}
                    id="customerAccount"
                    getUrlFromSearch={(search) => getCustomersUri({ search })}
                    valueKey="@id"
                    labelKey="fullName"
                    label={t('page.collaborator.details.labels.customerAccount')}
                    tooltip={
                      <TextTooltip
                        id="mainStoreTooltip"
                        text={t('page.collaborator.details.tooltips.customerAccount')}
                      />
                    }
                  />
                  {customerAccount && (
                    <CustomerLinkWrapper>
                      <CustomerLink route={RouteName.CustomerDetails} value={customerAccount.id}>
                        {`${customerAccount.firstname} ${customerAccount.lastname}`}
                      </CustomerLink>
                      <TooltipHover
                        id="deleteCustomerAccount"
                        content={t('page.collaborator.details.tooltips.deleteCustomerAccount')}
                      >
                        <Button shape="circle" icon={CrossIcon} variant="white" onClick={deleteCustommerAccount} />
                      </TooltipHover>
                    </CustomerLinkWrapper>
                  )}
                </>
              )}
            </FormWrapper>
            <Button shape="circle" icon={SaveIcon} buttonType="submit" isLoading={isLoading} />
          </form>
        }
        deniedComponent={<CollaboratorDetailsFormDisplay collaborator={collaborator} />}
      />
    </CardLayout>
  )
}
