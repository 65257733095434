import classnames from 'classnames'
import { ButtonContent, Props as ButtonProps } from 'components/Button/Button'
import { StyledButton } from 'components/Button/Button.style'
import useAuth from 'hooks/useAuth'
import { RouteName, routesTreeConfig, useCheckMatch } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'

export type LinkButtonProps = Omit<ComponentProps, 'to'> & {
  route: RouteName
  placeholder?: string // Placeholder to replace from react router route path
  value?: string | number // Value to replace placeholder,
  state?: any
}

type ComponentProps = ButtonProps & LinkProps

const LinkButtonComponent = ({
  variant = 'default',
  size,
  shape,
  to,
  disabled,
  className,
  iconSize,
  ...buttonAttr
}: ComponentProps) => {
  return (
    <StyledButton
      as={ReactRouterLink}
      variant={variant}
      size={size}
      shape={shape}
      to={to}
      className={classnames(className, { disabled })}
      {...buttonAttr}
    >
      <ButtonContent
        isLoading={buttonAttr.isLoading}
        icon={buttonAttr.icon}
        iconSize={iconSize}
        shape={shape}
        variant={variant}
      >
        {buttonAttr.children}
      </ButtonContent>
    </StyledButton>
  )
}

const LinkButton: React.FC<LinkButtonProps> = ({
  className,
  children,
  route,
  value,
  state,
  placeholder = ':id',
  ...buttonAttr
}) => {
  const { me, accessControl } = useAuth()
  const { checkMatch } = useCheckMatch()
  const [match, setMatch] = useState(false)
  const routeNode = routesTreeConfig.find((node) => node.name === route)

  // if logged user data or accessControl changed, recheck permissions
  useEffect(() => {
    setMatch(checkMatch(routeNode?.permissions))
  }, [me, accessControl, checkMatch, routeNode?.permissions])

  if (!routeNode) {
    return <div className={className}>{children}</div>
  }

  return (
    <LinkButtonComponent
      className={classnames(className, { disabled: !match })}
      to={value ? routeNode.path.replace(placeholder, value.toString()) : routeNode.path}
      state={state}
      {...buttonAttr}
    >
      {children}
    </LinkButtonComponent>
  )
}

export default LinkButton
