import { fetchRoleById, roleByIdQueryKey } from 'api/role'
import { fetchRoleActionById, roleActionByIdQueryKey } from 'api/roleAction'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import Select from 'components/Select/Select'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { SearchIcon } from 'constants/icons'
import { usePermissionsByFranchised } from 'hooks/entityHooks/permissionsHooks'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Location, useLocation } from 'react-router'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import RoleActionManage from './components/RoleActionManage'
import RoleManage from './components/RoleManage'
import { PermissionsFormLayout, PermissionsInputsWrapper } from './PermissionManage.style'
interface SearchRoleOrActionInputs {
  role?: number
  action?: number
}
type SectionType = 'role' | 'roleAction'
export default function PermissionsManage() {
  const [store] = useAtom(defaultStoreAtom)
  const [activeSection, setActiveSection] = useState<SectionType>()
  const location = useLocation() as Location & { state: null | { role?: number; roleAction?: number } }
  const [roleId, setRoleId] = useState<number>()
  const [roleActionId, setRoleActionId] = useState<number>()
  const { t } = useTranslation()

  const { handleSubmit, control, watch, reset, setValue } = useForm<SearchRoleOrActionInputs>({
    defaultValues: {
      role: undefined,
      action: undefined
    }
  })

  const { data: fetchedRoleAction, isLoading: isFetchingRoleAction } = useQuery(
    roleActionByIdQueryKey(roleActionId),
    () => fetchRoleActionById(roleActionId!),
    {
      enabled: !!roleActionId,
      onSuccess: () => {
        //When role is loaded, show actions ection and clear prev role id

        setActiveSection('roleAction')
        setRoleId(undefined)

        reset()
      }
    }
  )

  const { data: fetchedRole, isLoading: isFetchingRole } = useQuery(
    roleByIdQueryKey(roleId),
    () => fetchRoleById(roleId!),
    {
      enabled: !!roleId,
      onSuccess: () => {
        //When role is loaded, show role Section and clear prev action id
        setActiveSection('role')
        setRoleActionId(undefined)

        reset()
      }
    }
  )
  const { roleActionsOptions, roleActionsOptionsPaginationProps, roleOptions, rolesPaginationProps } =
    usePermissionsByFranchised()
  const [watchAction, watchRole] = watch(['action', 'role'])
  const onSubmit = (formData: SearchRoleOrActionInputs) => {
    const { role, action } = formData

    //Enable action or role query
    if (action) {
      setRoleActionId(action)
    }
    if (role) {
      setRoleId(role)
    }
  }

  useEffect(() => {
    //Clear page on store change
    setActiveSection(undefined)
    setRoleActionId(undefined)
    setRoleId(undefined)
    reset()
  }, [store, reset])

  useEffect(() => {
    // Listen search made from order sidebar search
    // and display results directly
    if (location.state) {
      // as we consumed current state, we reset it for futur reload
      const { role, roleAction } = location.state
      if (role) {
        setRoleId(role)
      }
      if (roleAction) {
        setRoleActionId(roleAction)
      }
      window.history.replaceState({}, document.title)
    }
  }, [location.state, setValue])

  return (
    <Section>
      <MainTitle title={t('page.permissions.manage.title')} />
      <CardLayout>
        <Title2>{t('page.permissions.manage.formTitle')}</Title2>
        <PermissionsFormLayout onSubmit={handleSubmit(onSubmit)}>
          <PermissionsInputsWrapper>
            <Select
              options={roleOptions}
              control={control}
              id="role"
              placeholder={t('page.permissions.manage.labels.placeholder.role')}
              disabled={!!watchAction}
              label={t('common.label.occupation')}
              enableUnselect={true}
              {...rolesPaginationProps}
            />
            <Select
              id="action"
              options={roleActionsOptions}
              control={control}
              placeholder={t('page.permissions.manage.labels.placeholder.action')}
              label={t('common.label.roleAction')}
              disabled={!!watchRole}
              enableUnselect={true}
              {...roleActionsOptionsPaginationProps}
            />
          </PermissionsInputsWrapper>
          <Button
            shape="circle"
            icon={SearchIcon}
            buttonType="submit"
            isLoading={isFetchingRoleAction || isFetchingRole}
            disabled={!watchAction && !watchRole}
          />
        </PermissionsFormLayout>
      </CardLayout>
      {isFetchingRole || isFetchingRoleAction ? (
        <Loader />
      ) : (
        <>
          {activeSection === 'role' && <RoleManage role={fetchedRole} />}
          {activeSection === 'roleAction' && <RoleActionManage roleAction={fetchedRoleAction} />}
        </>
      )}
    </Section>
  )
}
