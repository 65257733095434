import { useMutateCollaborator } from 'api/collaborators'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import { usePermissionsByCollaborator } from 'hooks/entityHooks/permissionsHooks'
import useAuth from 'hooks/useAuth'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collaborator } from 'types/entities'
import { CollaboratorRoleCollaboratorRoleRead, RoleAction } from 'types/playInApiInterfaces'
import { ListActions } from '../CollaboratorDetails.style'
import AddRoleForm from '../forms/AddRoleForm'
import { isInMyStores } from '../utils'

interface Props {
  collaborator: Collaborator
}

export default function RolesManage({ collaborator }: Props) {
  const { t } = useTranslation()
  const { me } = useAuth()
  const [selectedRoles, setSelectedRoles] = useState<string[]>([])
  const [openDeleteModal, toggleDeleteModal] = useState(false)
  const { deletableRoles, canDeleteRole } = usePermissionsByCollaborator(collaborator)

  const { checkMatch } = useCheckMatch()

  const columns = _.filter<Column<CollaboratorRoleCollaboratorRoleRead>>(
    [
      {
        key: 'checkbox',
        decorator: (role) => (
          <Cell>
            <TooltipHover
              id={`selectRole-${role.id}`}
              content={canDeleteRole(role).message}
              container="tooltipContainer"
            >
              <Checkbox
                className={classNames({
                  checked: selectedRoles.includes(role['@id']!),
                  disabled: !canDeleteRole(role).isDeletable
                })}
                onClick={() => setSelectedRoles((prev) => _.xor(prev, [role['@id']!]))}
              />
            </TooltipHover>
          </Cell>
        )
      },
      {
        key: 'name',
        name: t('page.collaborator.details.roles.labels.role'),
        decorator: (role) => (
          <ClickableCell route={RouteName.PermissionManage} state={{ role: role.id }}>
            {role.name}
          </ClickableCell>
        )
      },
      {
        key: 'details',
        name: t('page.collaborator.details.roles.labels.roleDetails'),
        decorator: (role) => (
          <ClickableCell route={RouteName.PermissionManage} state={{ role: role.id }}>
            <Text fontWeight="light" color="secondary">
              {role.description}
            </Text>
          </ClickableCell>
        )
      }
    ],
    (column) => {
      if (column.key === 'checkbox')
        return (
          checkMatch([RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]) &&
          !!collaborator.active &&
          isInMyStores(collaborator, me?.stores)
        )
      return true
    }
  )

  const { mutate, isLoading } = useMutateCollaborator(collaborator.id!, () => {
    toggleDeleteModal(false)
    setSelectedRoles([])
  })

  const handleDelete = () => {
    mutate({
      collaboratorRoles:
        collaborator.collaboratorRoles?.filter((e) => !selectedRoles.includes(e['@id']!)).map((e) => e['@id']!) ?? []
    })
  }
  return (
    <>
      <ShouldHide
        permissions={[RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]}
        deniedExtraCondition={!collaborator.active || !isInMyStores(collaborator, me?.stores)}
      >
        <>
          <AddRoleForm collaborator={collaborator} />
          {!!collaborator?.collaboratorRoles?.length && (
            <ListActions>
              <Checkbox
                onClick={() =>
                  setSelectedRoles((prev) =>
                    prev.length === deletableRoles?.length ? [] : deletableRoles?.map((e) => e['@id']!) ?? []
                  )
                }
                className={classNames({
                  checked: !!deletableRoles?.length && selectedRoles.length === deletableRoles?.length,
                  disabled: !deletableRoles.length || !collaborator.active
                })}
              />
              <TooltipHover
                id="deleteStoreTooltip"
                content={t('page.collaborator.details.roles.tooltips.deleteRole')}
                container="tooltipContainer"
              >
                <Button
                  shape="circle"
                  icon={DeleteIcon}
                  variant="white"
                  onClick={() => toggleDeleteModal(true)}
                  disabled={!collaborator.active || !selectedRoles.length}
                />
              </TooltipHover>
            </ListActions>
          )}
          <ConfirmModal
            open={openDeleteModal}
            onClose={() => toggleDeleteModal(false)}
            title={t('page.collaborator.details.roles.deleteTitle')}
            callback={handleDelete}
            closeOnCallback={false}
            confirmBtnProps={{
              isLoading,
              icon: DeleteIcon,
              disabled: !collaborator.active
            }}
          >
            <Listing
              columns={columns.filter((col) => col.key !== 'checkbox')}
              data={collaborator.collaboratorRoles?.filter((store) => selectedRoles.includes(store['@id']!)) ?? []}
            />
          </ConfirmModal>
        </>
      </ShouldHide>

      <Listing
        columns={columns}
        data={_.sortBy(collaborator.collaboratorRoles, 'name')}
        totalCount={collaborator.collaboratorRoles?.length}
        emptyText={t('page.collaborator.details.roles.emptyText')}
      />
    </>
  )
}
