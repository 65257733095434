import { StockTransfersDetail } from 'api/stockEntry'
import Modal, { ModalProps } from 'components/Modal/Modal'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import Listing from 'features/Listing/Listing'
import { useTranslation } from 'react-i18next'
import { ProductJsonldStockReadItemStockRead } from 'types/playInApiInterfaces'
import { formatDateAndHour } from 'utils/dates'
import { getIdFromIri } from 'utils/queryParams'
import { TextContainer } from './QuantityDetailModal.styles'

interface Props extends ModalProps {
  stockTransfersDetail: StockTransfersDetail[]
  stockTransferProduct?: ProductJsonldStockReadItemStockRead | null
}

const BOV2TransfersRedirection: React.FunctionComponent<{ transferStockIri: string }> = ({
  children,
  transferStockIri
}) => {
  return (
    <a
      href={`${
        process.env.REACT_APP_BO_V2_HOSTNAME
      }/lagestiondugenie/index.php?page=stock_saisie&stock_id=${getIdFromIri(transferStockIri)}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

export default function QuantityDetailModal({ open, onClose, stockTransfersDetail, stockTransferProduct }: Props) {
  const { t } = useTranslation()

  const columns: Array<Column<StockTransfersDetail>> = [
    {
      key: 'date',
      name: t('common.label.date'),
      decorator: (transfer) => (
        <BOV2TransfersRedirection transferStockIri={transfer?.stock?.['@id']!}>
          <Cell>
            <Text>{formatDateAndHour(transfer?.stock?.createdAt)}</Text>
          </Cell>
        </BOV2TransfersRedirection>
      )
    },
    {
      key: 'transferId',
      name: t('common.label.transfertNumber'),
      decorator: (transfer) => (
        <BOV2TransfersRedirection transferStockIri={transfer?.stock?.['@id']!}>
          <Cell>
            <Text fontWeight="light">{transfer?.stock?.id}</Text>
          </Cell>
        </BOV2TransfersRedirection>
      )
    },
    {
      key: 'recipient',
      name: t('common.label.recipient'),
      decorator: (transfer) => (
        <BOV2TransfersRedirection transferStockIri={transfer?.stock?.['@id']!}>
          <Cell>
            <Text fontWeight="light">{transfer?.stock?.store?.name}</Text>
          </Cell>
        </BOV2TransfersRedirection>
      )
    },
    {
      key: 'quantity',
      name: t('common.label.quantity'),
      decorator: (transfer) => (
        <BOV2TransfersRedirection transferStockIri={transfer?.stock?.['@id']!}>
          <Cell>
            <Text>{transfer?.quantity}</Text>
          </Cell>
        </BOV2TransfersRedirection>
      )
    }
  ]

  return (
    <Modal open={open} onClose={onClose}>
      <Text fontWeight="bolder" size="2rem">
        {t('page.stock.detail.modal.quantity.title')}
      </Text>
      {stockTransferProduct?.name && (
        <TextContainer>
          <Text fontWeight="light">
            {t('page.stock.detail.modal.quantity.subtitle', { productName: stockTransferProduct?.name })}
          </Text>
          <Text fontWeight="light">{t('page.stock.detail.modal.quantity.disclaimer')}</Text>
        </TextContainer>
      )}
      <Listing columns={columns} data={stockTransfersDetail} />
    </Modal>
  )
}
