import { yupResolver } from '@hookform/resolvers/yup'
import { editOrder, orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { TextareaLayout } from 'components/Textarea/Textarea.style'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import * as yup from 'yup'
import { CommentBoxContainer, StyledItalicTextarea } from './CommentBox.style'

interface CommentBoxInputs {
  comment: string
  orderId: number
}

type CommentBoxProps = CommentBoxInputs & {
  toggleComment: Function
}

const schema = yup.object().shape({
  comment: yup.string().trim()
})

function CommentBox({ comment, orderId, toggleComment }: CommentBoxProps) {
  const { t } = useTranslation()
  const { mutate, isLoading } = useMutation((body: CommentBoxInputs) => editOrder(body, orderId))
  const { handleSubmit, register, setValue } = useForm<CommentBoxInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { comment }
  })

  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${orderId}`)

  const onSubmit = (formData: CommentBoxInputs) => {
    formData = { ...formData, comment: formData.comment.trim() }
    mutate(formData, {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, { ...prev, ...data })
        setValue('comment', formData.comment)
        if (formData.comment.length === 0) {
          toggleComment(false)
        }
      }
    })
  }

  useEffect(() => {
    // Resets textarea default value upon prop change
    setValue('comment', comment)
  }, [comment, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BlockLayout>
      {t('page.order.detail.titleCommentBox')}
        <CommentBoxContainer>
          <TextareaLayout>
            <StyledItalicTextarea rows={1} {...register('comment')} />
          </TextareaLayout>
          <Button shape="circle" icon={SaveIcon} buttonType="submit" isLoading={isLoading} />
        </CommentBoxContainer>
      </BlockLayout>
    </form>
  )
}

export default CommentBox
