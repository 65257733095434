import styled from 'styled-components'

export const BlockLayout = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;

  .no-margin {
    margin-top: 0;
  }
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`
