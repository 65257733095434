import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { RoleAction } from 'types/entities'
import { RoleLayout } from '../PermissionManage.style'
import CollaboratorsByRoleAction from './CollaboratorsByRoleAction'
import RoleByAction from './RoleByAction'
interface Props {
  roleAction?: RoleAction
}
export default function RoleActionManage({ roleAction }: Props) {
  const { t } = useTranslation()

  return (
    <CardLayout>
      {roleAction && (
        <>
          <Title2>{t('page.permissions.manage.roleAction.title', { name: roleAction.displayName })}</Title2>
          <Text color="secondary" fontWeight="light">
            {roleAction.description}
          </Text>
          <RoleLayout>
            <RoleByAction roleAction={roleAction} />
            <CollaboratorsByRoleAction roleAction={roleAction} />
          </RoleLayout>
        </>
      )}
    </CardLayout>
  )
}
