import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import InputLayout, { LayoutProps } from '../Layouts/InputLayout/InputLayout'
import { Text, TextProps } from '../Text/Text.styles'
import { DefaultContainer, StyledNumberFormat } from './InputFormattedNumber.styles'

type ReactHookFormProps<T extends FieldValues> = {
  control: Control<T>
}

export interface InputFormattedNumberProps<T>
  extends LayoutProps,
    TextProps,
    Omit<FormattedNumberComponentProps, 'value' | 'label'> {
  id: Path<T>
}

interface Props<T extends FieldValues> extends ReactHookFormProps<T>, InputFormattedNumberProps<T> {}

export type FormattedNumberComponentProps = TextProps & {
  className?: string
  value?: number | null
  prefix?: string
  suffix?: string
  decimalScale?: number
  placeholder?: string
  ContainerComponent?: React.ComponentType
  onChange?: (value?: number | null) => void
  forwardRef?: any
  displayType?: 'input' | 'text'
  disabled?: boolean
  label?: string
  onBlur?: () => void
  allowNegative?: boolean
}

export const FormattedNumberComponent = ({
  className,
  value,
  color,
  fontWeight,
  prefix,
  suffix,
  decimalScale,
  placeholder,
  ContainerComponent = DefaultContainer,
  displayType = 'text',
  onChange,
  forwardRef,
  disabled,
  label,
  size,
  fontStyle,
  onBlur,
  allowNegative
}: FormattedNumberComponentProps) => {
  return (
    <StyledNumberFormat
      onBlur={onBlur}
      className={className}
      disabled={disabled}
      ref={forwardRef}
      thousandsGroupStyle="thousand"
      value={value ?? ''} // if value is undefined; pass empty string to reset input
      decimalSeparator=","
      displayType={displayType}
      type="text"
      thousandSeparator=" "
      suffix={suffix}
      prefix={prefix}
      decimalScale={decimalScale}
      fixedDecimalScale={!!decimalScale && !!value}
      placeholder={placeholder}
      allowNegative={allowNegative}
      onValueChange={(target) => {
        if (onChange) {
          onChange(target.floatValue ?? null)
        }
      }}
      renderText={(value) => (
        <ContainerComponent>
          <Text className={className} color={color} fontWeight={fontWeight} size={size} fontStyle={fontStyle}>
            {label}
            {value !== '' ? value : placeholder}
          </Text>
        </ContainerComponent>
      )}
    />
  )
}

function InputFormattedNumber<T extends FieldValues>({
  id,
  control,
  className,
  label,
  layout,
  tooltip,
  color,
  fontWeight,
  prefix,
  suffix,
  decimalScale,
  placeholder,
  ContainerComponent,
  disabled
}: Props<T>) {
  return (
    <Controller
      name={id}
      control={control}
      render={({ field }) => {
        const { value, ref, ...props } = field

        return (
          <InputLayout className={className} label={label} layout={layout} tooltip={tooltip}>
            <FormattedNumberComponent
              {...props}
              disabled={disabled}
              forwardRef={ref}
              displayType="input"
              value={value as number}
              color={color}
              fontWeight={fontWeight}
              prefix={prefix}
              suffix={suffix}
              decimalScale={decimalScale}
              placeholder={placeholder}
              ContainerComponent={ContainerComponent}
              allowNegative={false}
            />
          </InputLayout>
        )
      }}
    />
  )
}

export default InputFormattedNumber
