import { fetchStoreProductStats } from 'api/productStats'
import Loader from 'components/Loader/Loader'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Store } from 'types/entities'
import { DataAlignEnd, FormGrid, ProductStatsGrid } from '../../ProductDetailsTab.styles'

const LoadingData: FunctionComponent<{ isLoading: boolean }> = ({ isLoading = false, children }) => {
  return <DataAlignEnd>{isLoading ? <Loader /> : <>{children}</>}</DataAlignEnd>
}

type Props = {
  productId: string
  store: Store
}

const StoreProductStats = ({ productId, store }: Props) => {
  const { t } = useTranslation()
  const { data: productStats, isFetching } = useQuery([`productStoreStats_${productId}_${store.id}`], () =>
    fetchStoreProductStats(productId, store.id!.toString())
  )
  return (
    <ProductStatsGrid>
      <FormGrid>
        <Text fontWeight="light">{t('page.product.detail.tabs.productStore.buyCount')}</Text>
        <LoadingData isLoading={isFetching}>
          <TextNumber value={productStats?.buyCount} fontWeight="medium" placeholder="-" />
        </LoadingData>
        <Text fontWeight="light">{t('page.product.detail.tabs.productStore.stockCount')}</Text>
        <LoadingData isLoading={isFetching}>
          <TextNumber
            //@ts-ignore
            value={productStats?.inventoryQuantity}
            fontWeight="medium"
            placeholder="-"
          />
        </LoadingData>
      </FormGrid>
      <FormGrid>
        <Text fontWeight="light">{t('page.product.detail.tabs.productStore.averageBuyPrice')}</Text>
        <LoadingData isLoading={isFetching}>
          <TextNumber
            value={productStats?.averageBuyPrice}
            suffix=" €"
            decimalScale={2}
            fontWeight="medium"
            placeholder="-"
          />
        </LoadingData>

        <Text fontWeight="light">{t('page.product.detail.tabs.productStore.salesCount')}</Text>
        <LoadingData isLoading={isFetching}>
          <TextNumber value={productStats?.allTimeSells} fontWeight="medium" placeholder="-" />
        </LoadingData>
      </FormGrid>

      <FormGrid>
        <Text fontWeight="light">{t('page.product.detail.tabs.productStore.marginPercentage')}</Text>
        <LoadingData isLoading={isFetching}>
          <TextNumber
            value={productStats?.marginPercentage}
            suffix=" %"
            decimalScale={2}
            fontWeight="medium"
            placeholder="-"
          />
        </LoadingData>
        <Text fontWeight="light">{t('page.product.detail.tabs.productStore.discountSales')}</Text>
        <LoadingData isLoading={isFetching}>
          <TextNumber value={productStats?.discountSales} fontWeight="medium" placeholder="-" />
        </LoadingData>
      </FormGrid>
    </ProductStatsGrid>
  )
}

export default StoreProductStats
