import { yupResolver } from '@hookform/resolvers/yup'
import { editOrder, EditOrderBody, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Select from 'components/Select/Select'
import { Title2 } from 'components/Title/Title.styles'
import { SaveIcon } from 'constants/icons'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import { useMyStoresOptions, usePaymentStatusFilters } from 'hooks/useSelectOptions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { UnmodifiableOrderStatuses } from 'types/enums'
import * as yup from 'yup'
import { LabelWrapSelect, PaymentModeCardFormContainer } from './PaymentStatusCard.style'

interface PaymentModeCardProps {
  order?: GetOrderByIdResponse
}

interface PaymentModeFormInputs {
  paymentReceived: boolean
  paymentReceivedStore: string
}

const schema = yup.object().shape({
  paymentReceived: yup.boolean().required(),
  paymentReceivedStore: yup.string().trim().when('paymentReceived', {
    is: true,
    then: yup.string().trim().required()
  })
})

export default function PaymentStatusCard({ order }: PaymentModeCardProps) {
  const { t } = useTranslation()

  const { paymentStatusOptions } = usePaymentStatusFilters()
  const { myStoresOptions } = useMyStoresOptions()

  const { me } = useAuth()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<PaymentModeFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      paymentReceivedStore: me?.stores?.length === 1 ? me.stores[0]['@id'] : ''
    }
  })

  useEffect(() => {
    reset({
      paymentReceived: !!order?.paymentReceived,
      paymentReceivedStore: order?.paymentReceivedStore?.['@id']
    })
  }, [order, reset])

  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)

  const { mutate: updateOrder, isLoading } = useMutation((body: EditOrderBody) => editOrder(body, order?.id!), {
    onSuccess: (updatedOrder) => {
      const prev = queryClient.getQueryData<Order>(key)
      queryClient.setQueryData(key, { ...prev, ...updatedOrder, paidAt: updatedOrder.paidAt ?? null })
    },
    onError: () => {
      reset({
        paymentReceived: !!order?.paymentReceived,
        paymentReceivedStore: order?.paymentReceivedStore?.['@id']
      })
    }
  })

  const onSubmit = ({ paymentReceived, paymentReceivedStore }: PaymentModeFormInputs) => {
    updateOrder({ paymentReceivedStore, paymentReceived })
  }

  return (
    <>
      <Title2>{t('page.order.detail.tab.payment.paymentStatusTitle')}</Title2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PaymentModeCardFormContainer>
          <Select
            options={paymentStatusOptions}
            label={t('page.order.detail.tab.payment.paymentStatus')}
            id="paymentReceived"
            control={control}
          />
          <div>
            <LabelWrapSelect
              options={myStoresOptions}
              label={t('page.order.detail.tab.payment.paymentReceivedAtLabel')}
              placeholder={t('common.select.defaultOptions.localisation')}
              id="paymentReceivedStore"
              control={control}
              className={classnames({ 'is-invalid': errors.paymentReceivedStore })}
            />
            <BasicFieldValidationError
              error={errors['paymentReceivedStore']}
              message={t('page.order.detail.tab.payment.requiredPaymentReceivedAt')}
            />
          </div>
          <Button
            buttonType="submit"
            icon={SaveIcon}
            shape="circle"
            disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
            isLoading={isLoading}
          />
        </PaymentModeCardFormContainer>
      </form>
    </>
  )
}
