import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { Customer } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export type FetchCustomersQueryParams = Api.GetCustomers.RequestQuery
export const getCustomersUri = (params: FetchCustomersQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.customers.root, queryString].join('?')
}

type QuickSearchCustomerQueryParams = Api.GetCustomersQuickSearchId.RequestQuery
export const getCustomersQuickSearchUri = (search: string, params: QuickSearchCustomerQueryParams): string => {
  const queryString = stringify(params)
  return [`${API_ROUTES.customers.quickSearch}/${search}`, queryString].join('?')
}

type FetchCustomersResponse = Api.GetCustomers.ResponseBody
export const fetchCustomers = async (query: string) => {
  let res = await playInApi.get<FetchCustomersResponse>(query)
  return res.data['hydra:member']
}

export const usePaginatedCustomers = (
  queryKey,
  query: string,
  options?: PaginatedHookOptions<FetchCustomersResponse['hydra:member'][0]>
) => usePaginatedQuery<FetchCustomersResponse['hydra:member'][0]>(queryKey, query, options)

type CreateCustomerBody = Api.PostCustomers.RequestBody
type CreateCustomerResponse = Api.PostCustomers.ResponseBody
export const createCustomer = async (body: CreateCustomerBody): Promise<Customer> => {
  let res = await playInApi.post<CreateCustomerBody, AxiosResponse<CreateCustomerResponse>>(
    API_ROUTES.customers.root,
    body
  )
  return res.data
}

export const customerByIdQueryKey = (id: string | number) => ['customerById', `${id}`]

type FetchCustomerByIdResponse = Api.GetCustomersId.ResponseBody
const getCustomerById = async (id: string | number): Promise<Customer> => {
  let res = await playInApi.get<FetchCustomerByIdResponse>(`${API_ROUTES.customers.root}/${id}`)
  return res.data
}

export const useCustomerById = (id: string | number) => {
  const navigate = useNavigate()
  return useQuery<FetchCustomerByIdResponse, AxiosResponse>(customerByIdQueryKey(id), () => getCustomerById(id), {
    onError: (error) => {
      // replace: true, we prevent going back to the page that respond 40X
      navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
    }
  })
}

type ResetCustomerResponse = Api.PostCustomersResetId.ResponseBody
export const resetCustomerPassword = async (id: string | number): Promise<Customer> => {
  let res = await playInApi.post<{}, AxiosResponse<ResetCustomerResponse>>(
    `${API_ROUTES.customers.reset}/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return res.data
}

export type PatchCustomerBody = Api.PatchCustomersId.RequestBody
type PatchCustomerResponse = Api.PatchCustomersId.ResponseBody
export const patchCustomer = async (id: string | number, body: PatchCustomerBody): Promise<Customer> => {
  let res = await playInApi.patch<PatchCustomerBody, AxiosResponse<PatchCustomerResponse>>(
    `${API_ROUTES.customers.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export const updateProCustomer = async (id: number, pro?: boolean) => {
  let url = pro ? API_ROUTES.customers.toPro : API_ROUTES.retailers.toCustomer
  let res = await playInApi.post(`${url}/${id}`, {})
  return res.data
}

export const anonymiseCustomer = async (id: number) => {
  let res = await playInApi.post(`${API_ROUTES.customers.delete}/${id}`, {})
  return res.data
}
