import {
  getShippingLabelUri,
  patchShippingLabel,
  PatchShippingLabelLogRequest,
  usePaginatedShippingLabels
} from 'api/shippingLabels'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { CHRONOPOST_TRACKING_URL_END, CHRONOPOST_TRACKING_URL_START, COLISHIP_TRACKING_URL } from 'constants/configs'
import { DeleteIcon } from 'constants/icons'
import { format } from 'date-fns'
import { add } from 'date-fns/esm'
import Listing from 'features/Listing/Listing'
import DeleteModal from 'features/Modals/DeleteModal'
import { useMutation } from 'hooks/useAxiosMutation'
import _, { forEach } from 'lodash'
import { RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShippingLabelsLog } from 'types/entities'
import { shortDateAndHourFormat } from 'utils/dates'
import { getIdFromIri } from 'utils/queryParams'
import ShippinLabelLogSearchForm, { ShippingLabelLogSearchData } from './Forms/ShippingLabelLogSearchForm'
import { ActionContainer, ExternalLink, RowContainer } from './ShippingLabelLog.styles'

type DeleteLabel = {
  id: number
  body: PatchShippingLabelLogRequest
}

function ShippingLabelLog() {
  const { t } = useTranslation()
  const [search, setSearch] = useState(
    getShippingLabelUri({
      'printedAt[after]': format(new Date(), 'yyyy-MM-dd')
    })
  )
  const {
    data: shippingLabels,
    totalItems,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    hasBeenCalled,
    refetch
  } = usePaginatedShippingLabels(search, search, {
    enabled: !!search
  })

  const handleSearch = (searchData: ShippingLabelLogSearchData) => {
    const { relatedOrder, printedBy, printDateStart, printDateEnd, ...restData } = searchData

    setSearch(
      getShippingLabelUri({
        ...restData,
        'relatedOrder.billNumber': `${relatedOrder}`,
        'printedBy.id': printedBy ? parseInt(getIdFromIri(printedBy)) : undefined,
        'printedAt[after]': printDateStart ? new Date(printDateStart).toLocaleDateString('en-EN') : '',
        'printedAt[before]': printDateEnd ? add(new Date(printDateEnd), { days: 1 }).toLocaleDateString('en-EN') : ''
      })
    )
  }

  // Deletion is handled by a boolean value in the db
  const [deleteList, setDeleteList] = useState<Array<number>>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  let isDeletingAll = deleteList.length === shippingLabels.length
  const handleToggleAll = () =>
    setDeleteList(isDeletingAll ? [] : shippingLabels.map((shippingLabel) => shippingLabel.id!))

  const { mutate: deleteLabel, isLoading: isDeleting } = useMutation(
    (data: DeleteLabel) => patchShippingLabel(data.id, data.body),
    {
      onSuccess: () => {
        refetch()
      }
    }
  )

  const handleDelete = () => {
    forEach(deleteList, (toDelete) => deleteLabel({ body: { deleted: true }, id: toDelete }))
  }

  const columns: Column<ShippingLabelsLog>[] = [
    {
      name: '',
      key: 'deleted',
      decorator: (shippingLabel) => (
        <Cell>
          <Checkbox
            className={deleteList.includes(shippingLabel.id!) ? 'checked' : ''}
            onClick={() => {
              setDeleteList((prev) => _.xor(prev, [shippingLabel.id!]))
            }}
          />
        </Cell>
      )
    },
    {
      name: t('common.label.print_date'),
      key: 'printedAt',
      decorator: (shippingLabel) => {
        const printDate = shortDateAndHourFormat(new Date(shippingLabel.printedAt!))
        return (
          <Cell>
            <Text>{printDate}</Text>
          </Cell>
        )
      }
    },
    {
      name: t('common.label.orderId_abbreviated'),
      key: 'relatedOrder',
      decorator: (shippingLabel) => {
        const idOrder = shippingLabel.relatedOrder?.id
        return (
          <ClickableCell route={RouteName.OrderDetails} value={idOrder}>
            <Text color="secondary">{shippingLabel.relatedOrder?.referenceNumber}</Text>
          </ClickableCell>
        )
      }
    },
    {
      name: t('common.label.customer'),
      key: 'customer',
      decorator: (shippingLabel) => <Cell>{shippingLabel.relatedOrder?.address?.recipientName}</Cell>
    },
    {
      name: t('common.label.type'),
      key: 'type',
      decorator: (shippingLabel) => (
        <Cell>
          <Text fontWeight="medium">{shippingLabel.type}</Text>
        </Cell>
      )
    },
    {
      name: t('common.label.trackingNumber'),
      key: 'shippingNumber',
      decorator: (shippingLabel) => (
        <Cell>
          <ExternalLink
            href={
              shippingLabel.type === 'colissimo' || 'relais colis'
                ? `${COLISHIP_TRACKING_URL}shippingLabel.shippingNumber`
                : `${CHRONOPOST_TRACKING_URL_START}shippingLabel.shippingNumber${CHRONOPOST_TRACKING_URL_END}`
            }
            target={'_blank'}
          >
            {shippingLabel.shippingNumber}
          </ExternalLink>
        </Cell>
      )
    },
    {
      name: t('common.label.destination'),
      key: 'shippingCountry',
      decorator: (shippingLabel) => (
        <Cell>
          <Text fontWeight="medium" color={shippingLabel.shippingCountry?.alpha2 === 'FR' ? 'info' : 'danger'}>
            {shippingLabel.shippingCountry?.alpha2}
          </Text>
        </Cell>
      )
    },
    {
      name: t('common.label.collaborator'),
      key: 'printedBy',
      decorator: (shippingLabel) => (
        <Cell>
          <Text fontWeight="light">{`${shippingLabel.printedBy?.firstname} ${shippingLabel.printedBy?.lastname}`}</Text>
        </Cell>
      )
    }
  ]

  return (
    <>
      <Section>
        <MainTitle title={t('page.shippingLabelLog.title')} />
        <ShippinLabelLogSearchForm onSearch={handleSearch} isSubmitting={isFetching && !isFetchingNextPage} />
        {hasBeenCalled && (
          <CardLayout>
            <Title2>
              {isFetching && !isFetchingNextPage
                ? t('common.label.loading')
                : t('page.shippingLabelLog.searchCount', { count: totalItems })}
            </Title2>
            {shippingLabels.length >= 1 && (
              <ActionContainer>
                <RowContainer>
                  <Checkbox className={isDeletingAll ? 'checked' : ''} onClick={handleToggleAll} />
                  <Button
                    shape="circle"
                    variant="white"
                    icon={DeleteIcon}
                    onClick={() => setDeleteModalOpen(true)}
                    disabled={!(deleteList.length > 0)}
                    isLoading={isDeleting}
                  />
                </RowContainer>
              </ActionContainer>
            )}
            <Listing
              data={shippingLabels}
              columns={columns}
              isFetchingNextPage={isFetchingNextPage}
              isFetching={isFetching}
              fetchNextPage={fetchNextPage}
              emptyText={t('page.shippingLabelLog.empty')}
              countText={t('page.shippingLabelLog.labelCount', {
                current: shippingLabels?.length ?? 0,
                total: totalItems ?? 0
              })}
              hasNextPage={hasNextPage}
              totalCount={totalItems}
            />
          </CardLayout>
        )}
      </Section>
      <DeleteModal
        callback={handleDelete}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title={t('page.shippingLabel.confirmDelete')}
      />
    </>
  )
}

export default ShippingLabelLog
