import { useGetLitigationsByCustomer } from 'api/litigations'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import Listing from 'features/Listing/Listing'
import { RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { Customer, Litigation } from 'types/entities'
import { formatCalendarDate } from 'utils/dates'
interface Props {
  customer: Customer
}

const getFullname = (collaborator?: { firstname?: string; lastname?: string } | null) => {
  if (collaborator) {
    const { firstname, lastname } = collaborator
    return firstname && lastname ? `${firstname} ${lastname}` : '-'
  }
  return '-'
}
export default function LitigationList({ customer }: Props) {
  const { data: litigations, isFetching } = useGetLitigationsByCustomer(customer.id!)
  const { t } = useTranslation()
  const columns: Column<Litigation>[] = [
    {
      key: 'date',
      name: t('common.label.date'),
      decorator: (litigation: Litigation) => (
        <ClickableCell route={RouteName.OrderDetails} value={litigation?.relatedOrder?.id}>
          {formatCalendarDate(litigation?.issuedAt)}
        </ClickableCell>
      )
    },
    {
      key: 'orderId',
      name: t('common.label.orderId'),
      decorator: (litigation: Litigation) => (
        <ClickableCell route={RouteName.OrderDetails} value={litigation?.relatedOrder?.id}>
          <Text color="secondary">{litigation?.relatedOrder?.referenceNumber}</Text>
        </ClickableCell>
      )
    },
    {
      key: 'type',
      name: t('common.label.type'),
      decorator: (litigation: Litigation) => (
        <ClickableCell route={RouteName.OrderDetails} value={litigation?.relatedOrder?.id}>
          {litigation.category?.name}
        </ClickableCell>
      )
    },
    {
      key: 'cardPreparator',
      name: t('page.order.detail.tab.prepare.cardPreparatorLabel'),
      decorator: (litigation: Litigation) => (
        <ClickableCell route={RouteName.OrderDetails} value={litigation?.relatedOrder?.id}>
          {getFullname(litigation.cardPreparer)}
        </ClickableCell>
      )
    },
    {
      key: 'cardChecker',
      name: t('page.order.detail.tab.prepare.cardCheckerLabel'),
      decorator: (litigation: Litigation) => (
        <ClickableCell route={RouteName.OrderDetails} value={litigation?.relatedOrder?.id}>
          {getFullname(litigation.cardPacker)}
        </ClickableCell>
      )
    },
    {
      key: 'productPreparator',
      name: t('page.order.detail.tab.prepare.productPreparatorLabel'),
      decorator: (litigation: Litigation) => (
        <ClickableCell route={RouteName.OrderDetails} value={litigation?.relatedOrder?.id}>
          {getFullname(litigation.productPreparer)}
        </ClickableCell>
      )
    },
    {
      key: 'productChecker',
      name: t('page.order.detail.tab.prepare.productCheckerLabel'),
      decorator: (litigation: Litigation) => (
        <ClickableCell route={RouteName.OrderDetails} value={litigation?.relatedOrder?.id}>
          {getFullname(litigation.productPacker)}
        </ClickableCell>
      )
    }
  ]
  return (
    <CardLayout>
      <Title2>{t('page.customer.details.litigations.title')}</Title2>
      <Listing
        data={litigations ?? []}
        isFetching={isFetching}
        columns={columns}
        emptyText={<Text color="secondary">{t('page.customer.details.litigations.emptyText')}</Text>}
        totalCount={litigations?.length ?? 0}
      />
    </CardLayout>
  )
}
