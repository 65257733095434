import { yupResolver } from '@hookform/resolvers/yup'
import { getSealedProductsUri } from 'api/sealedProducts'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import * as yup from 'yup'

const schema = yup.object().shape({
  searchProduct: yup.string().trim().nullable(true)
})

interface SearchProductInputs {
  searchProduct?: string | null
}

interface Props {
  onSelect: (searchFrom: 'releaseDate' | 'productSearch' | 'edition', searchedProducts?: SealedProduct[]) => void
  storeId: string
  disabled?: boolean
  resetTrigger?: string | number
}

export default function SearchProductForm({ onSelect, storeId, disabled = false, resetTrigger }: Props) {
  const { t } = useTranslation()
  const {
    formState: { touchedFields },
    control,
    setValue,
    reset
  } = useForm<SearchProductInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  useEffect(() => reset(), [reset, storeId, resetTrigger])

  return (
    <InputAutoComplete
      control={control}
      labelKey="name"
      valueKey="@id"
      id="searchProduct"
      isTouched={touchedFields.searchProduct}
      disabled={disabled}
      getUrlFromSearch={(search) => getSealedProductsUri({ 'exists[secondHandOf]': false, search })}
      label={t('common.label.product')}
      placeholder={t('page.order.print.details.placeholders.product')}
      onEntitySelected={(product) => {
        if (product) {
          setValue('searchProduct', null)
          onSelect('productSearch', [product])
        }
      }}
    />
  )
}
