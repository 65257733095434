import Button from 'components/Button/Button'
import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Select from 'components/Select/Select'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  align-self: flex-end;
  min-width: 10rem;
`

export const StyledSelect = styled(Select)`
  max-width: 40.5rem;
` as typeof Select

export const SmallActionButton = styled(Button)`
  min-width: 2.6rem;
  width: 2.6rem;
  min-height: 2.6rem;
  height: 2.6rem;
`

export const ActionButtonContainer = styled(RowContainer)`
  margin-bottom: 2rem;
  gap: 2rem;
`
