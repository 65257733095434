import classnames from 'classnames'
import Button from 'components/Button/Button'
import InputLayout, { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiddenInput, InputContainer } from './InputFile.styles'

type Props = LayoutProps & {
  handleFile: (file: File) => void
  accept?: string
}

const InputFile = ({ className, label, layout, tooltip, handleFile, accept }: Props) => {
  const { t } = useTranslation()
  const [fileName, setFileName] = useState<string | undefined>(undefined)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFileUpload = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target.files?.[0]
    if (file) {
      setFileName(file?.name)
      handleFile(file)
    }

    // else notification Fichier non supporté
  }

  const onClick = () => {
    inputRef?.current?.click()
  }

  return (
    <InputLayout className={classnames(className, 'no-border')} label={label} layout={layout} tooltip={tooltip}>
      <InputContainer onClick={onClick}>
        <Button variant="white">{t('common.fileInpute.button')}</Button>
        <Text>{fileName ?? t('common.fileInpute.noFile')}</Text>
        <HiddenInput type="file" accept={accept} onChange={handleFileUpload} ref={inputRef} />
      </InputContainer>
    </InputLayout>
  )
}

export default InputFile
