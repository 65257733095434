import classNames from 'classnames'
import { ReactNode, useEffect, useState } from 'react'
import { StyledImg } from './Image.style'

interface Props extends React.ImgHTMLAttributes<{}> {
  skeleton?: ReactNode
}
export default function Image({ src, alt, skeleton, ...restProps }: Props) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    // When src change we need to update loading state
    if (src && isLoaded) setIsLoaded(false)

    // We don't want to trigger useEffect when isLoaded change, as it will always set isLoaded to false
    // eslint-disable-next-line
  }, [src])

  return (
    <>
      {src && (
        <>
          {!isLoaded && !!skeleton && skeleton}
          <StyledImg
            src={src}
            alt={alt ?? 'Image'}
            onLoad={() => {
              setIsLoaded(true)
            }}
            className={classNames({
              'not-loaded': !isLoaded && !!skeleton
            })}
            {...restProps}
          />
        </>
      )}
    </>
  )
}
