import Button from 'components/Button/Button'
import styled from 'styled-components'
import theme from 'theme/theme'

export const StockManagementFormLayout = styled.form`
  display: grid;
  grid-template-columns: repeat(3, minmax(15rem, 18.5rem)) repeat(2, minmax(18rem, 25rem)) 1fr;
  gap: 3.5rem;

  button {
    align-self: flex-end;
  }

  @media screen and (${theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const SubmitButton = styled(Button)`
  @media screen and (${theme.screenWidth.maxDesktopScreen}) {
    justify-self: flex-end;
  }
`
