import { fetchStoreHours, hoursByStoreQueryKey } from 'api/stores'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import CanAccess from 'features/Permissions/CanAccess'
import { useQuery } from 'react-query'
import { StoreDetails } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { useStoreContext } from '../helper'
import StoreInformationsDisplay from './StoreInformationsDisplay'
import StoreInformationsForm from './StoreInformationsForm'

interface Props {
  store: StoreDetails
}
export default function StoreInformationsTab({ store }: Props) {
  const { data: hours } = useQuery(hoursByStoreQueryKey(store.id), () => fetchStoreHours({ store: `${store.id}` }))
  const { isMyStore } = useStoreContext()
  return (
    <CardLayout>
      {hours ? (
        <CanAccess
          permissions={[RoleAction.ROLE_ACTION_STORE_EDIT, RoleAction.ROLE_ACTION_STORE_EDIT_CORE]}
          allowedComponent={<StoreInformationsForm store={store} hours={hours} />}
          deniedComponent={<StoreInformationsDisplay store={store} hours={hours} />}
          deniedExtraCondition={!isMyStore}
        />
      ) : (
        <Loader />
      )}
    </CardLayout>
  )
}
