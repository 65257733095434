import logoutSvg from 'assets/svg/icon/logout.svg'
import Link from 'features/Permissions/Link/Link'
import { StyledText } from 'features/TopBar/TopBar.styles'
import useAuth from 'hooks/useAuth'
import { RouteName } from 'permissions/permissions'
import { LogoutIcon } from './AccountNav.styles'

export default function AccoutNav() {
  const { logout, me } = useAuth()
  return (
    <>
      <Link route={RouteName.CollaboratorAccountOptions} value={me?.id}>
        <StyledText color="mysteryBlue" fontWeight="medium">
          {`${me?.firstname} ${me?.lastname}`}
        </StyledText>
      </Link>

      <LogoutIcon src={logoutSvg} alt="logout" onClick={() => logout()} />
    </>
  )
}
