import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;

  padding: 1px;
  background-color: ${(props) => props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.borderColors.secondary};
  border-radius: 0.4rem;
  box-sizing: border-box;

  font-size: 1.4rem;

  &:focus,
  &:focus-within {
    border: 2px solid #000000;
  }

  &.is-invalid {
    border: 2px solid ${(props) => props.theme.borderColors.danger};
  }

  &.no-border {
    border: none;
    padding: 0;

    &:focus,
    &:focus-within,
    &.is-invalid {
      border: none;
    }
  }

  &:focus,
  &.is-invalid,
  &:focus-within {
    padding: 0;
  }

  &.row-reverse-layout {
    width: fit-content;
  }
  &.disabled {
    background-color: ${(props) => props.theme.backgroundColors.secondary};
  }
`

export const LabelStyled = styled.div`
  display: flex;
  flex-direction: row;

  font-weight: ${(props) => props.theme.fontWeight.light};
  white-space: nowrap;

  gap: 0.5rem;
  min-height: 2rem;
  align-items: center;
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 0.5rem;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  gap: 0.5rem;
  ${LabelStyled} {
    min-height: unset;
  }
`

export const RowReverseContainer = styled(RowContainer)`
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
`

export const LineBreakLabel = styled(LabelStyled)`
  white-space: normal;
`
