import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
`

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`

export const ExternalLink = styled.a`
  font-weight: 'regular';
  color: 'black';
  font-size: 'inherit';
  font-style: 'normal';
`
