import styled from 'styled-components'

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-bottom: 2rem;
  overflow-x: auto;

  & > *:not(:last-child) {
    margin-right: 0.1rem;
  }
`
