import styled from 'styled-components'

export const BackToTopContainer = styled.div`
  position: fixed;
  right: 2rem;
  bottom: 5rem;

  button:hover {
    box-shadow: 2px 2px 4px ${(props) => props.theme.colors.grey6};
  }
`
