import { yupResolver } from '@hookform/resolvers/yup'
import { useDownloadFile } from 'api/basePlayInApi'
import { editOrder, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import { useOrderContext } from 'app/OrderDetails/helper'
import { DuoSectionGrid } from 'app/OrderDetails/OrderDetails.styles'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import Select, { Option } from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { PrintIcon, SaveIcon } from 'constants/icons'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useMutation } from 'hooks/useAxiosMutation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { CanceledOrderStatuses, UnmodifiableOrderStatuses } from 'types/enums'
import { RoleAction } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { BillCouponPrintActionContainer } from './BillCouponPrintCard.style'

const permissions = [RoleAction.ROLE_ACTION_ORDER_PRINT]

interface BillPrintCardInputs {
  invoiceLink: string
  purchaseOrder: string
}

const schema = yup.object().shape({
  invoiceLink: yup.string().trim(),
  purchaseOrder: yup.string().trim()
})

type Props = {
  order?: GetOrderByIdResponse
}

function BillCouponPrintCard({ order }: Props) {
  const { t } = useTranslation()
  const { downloadFile, isLoading } = useDownloadFile()

  const { mutate, isLoading: isMutationLoading } = useMutation(
    (body: { purchaseOrder: string }) => editOrder(body, order?.id!),
    {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, { ...prev, ...data })
      }
    }
  )

  // Allows i18next-parser to support nested translations.
  // See: https://github.com/i18next/i18next-parser#caveats
  //
  // t('common.languages.french')
  // t('common.languages.english')
  const billOptions: Array<Option> = [
    {
      label: t('page.order.detail.tab.misc.print.documentCategoryBill'),
      value: '',
      disabled: true
    },
    {
      label: t('page.order.detail.tab.misc.print.billIncludingTaxes', {
        language: t('common.languages.french')
      }),
      value: `/exports/invoice/${order?.id}/1/fr`
    },
    {
      label: t('page.order.detail.tab.misc.print.billIncludingTaxes', {
        language: t('common.languages.english')
      }),
      value: `/exports/invoice/${order?.id}/1/en`
    },
    {
      label: t('page.order.detail.tab.misc.print.billExcludingTaxes', {
        language: t('common.languages.french')
      }),
      value: `/exports/invoice/${order?.id}/0/fr`
    },
    {
      label: t('page.order.detail.tab.misc.print.billExcludingTaxes', {
        language: t('common.languages.english')
      }),
      value: `/exports/invoice/${order?.id}/0/en`
    },
    {
      label: t('page.order.detail.tab.misc.print.documentCategoryQuote'),
      value: '',
      disabled: true
    },
    {
      label: t('page.order.detail.tab.misc.print.quote', {
        language: t('common.languages.french')
      }),
      value: `/exports/quote/${order?.id}/1/fr`
    },
    {
      label: t('page.order.detail.tab.misc.print.quote', {
        language: t('common.languages.english')
      }),
      value: `/exports/quote/${order?.id}/1/en`
    }
  ]

  const { register, handleSubmit, control, watch } = useForm<BillPrintCardInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      purchaseOrder: order?.purchaseOrder,
      invoiceLink: `/exports/invoice/${order?.id}/1/fr`
    }
  })

  const invoiceLink = watch('invoiceLink')

  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)

  const onSubmit = async ({ purchaseOrder }: BillPrintCardInputs) => {
    mutate({ purchaseOrder })
  }

  const { inMyStoresOrder } = useOrderContext()
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title2>{t('page.order.detail.tab.misc.print.billCouponTitle')}</Title2>
      <DuoSectionGrid>
        <BillCouponPrintActionContainer>
          <ShouldDisable permissions={permissions} deniedExtraCondition={!inMyStoresOrder}>
            <Select
              label={t('page.order.detail.tab.misc.print.billCouponLabel')}
              options={billOptions}
              control={control}
              enableUnselect={false}
              id="invoiceLink"
            />
            <TooltipHover
              id="printBillCouponTitleTooltip"
              content={<Text>{t('page.order.detail.tab.misc.print.billCouponTooltip')}</Text>}
            >
              <ShouldDisable permissions={permissions} deniedExtraCondition={!inMyStoresOrder}>
                <Button
                  variant="warning"
                  shape="circle"
                  icon={PrintIcon}
                  disabled={CanceledOrderStatuses.includes(order?.status!)}
                  isLoading={isLoading}
                  onClick={() => downloadFile(invoiceLink)}
                />
              </ShouldDisable>
            </TooltipHover>
          </ShouldDisable>
        </BillCouponPrintActionContainer>
        <BillCouponPrintActionContainer>
          <Input label={t('page.order.detail.tab.misc.print.billCoupon')} register={register} id="purchaseOrder" />

          <Button
            shape="circle"
            variant="default"
            icon={SaveIcon}
            buttonType="submit"
            disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
            isLoading={isMutationLoading}
          />
        </BillCouponPrintActionContainer>
      </DuoSectionGrid>
    </form>
  )
}

export default BillCouponPrintCard
