import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { BNF_STORE_ID, PLAYIN_SUPPORT_EMAIL, WAREHOUSE_STORE_ID } from 'constants/configs'
import { useAtom } from 'jotai'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import PrintList from './PrintList'

function RestockStorePrint() {
  const { t } = useTranslation()
  const [store] = useAtom(defaultStoreAtom)

  const PrintListByStore = useMemo(() => {
    switch (store?.['@id']) {
      case WAREHOUSE_STORE_ID:
        return (
          <CardLayout>
            <Text color="danger">{t('page.restock.management.unavailable', { storeName: store.shortName })}</Text>
          </CardLayout>
        )
      case BNF_STORE_ID:
        return (
          <CardLayout>
            <Text color="danger">
              {
                <Trans
                  i18nKey="page.restock.management.unavailableBNF"
                  components={{ bold: <b /> }}
                  values={{ email: PLAYIN_SUPPORT_EMAIL }}
                />
              }
            </Text>
          </CardLayout>
        )

      default:
        return <PrintList store={store!} />
    }
  }, [store, t])

  return (
    <Section>
      <MainTitle title={t('common.label.restockStorePrint')} showStoreSelect={true} />
      {PrintListByStore}
    </Section>
  )
}

export default RestockStorePrint
