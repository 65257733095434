import { API_ROUTES } from 'constants/configs'
import { PaymentMode } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type GetPaymentModesResponse = Api.GetPaymentModes.ResponseBody
export type GetPaymentModesParams = Api.GetPaymentModes.RequestQuery
export const getPaymentModes = async (params?: GetPaymentModesParams): Promise<PaymentMode[]> => {
  const response = await playInApi.get<GetPaymentModesResponse>(`${API_ROUTES.paymentMode.root}`, {
    params
  })

  return response.data['hydra:member']
}
