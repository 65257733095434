import styled from 'styled-components'
import { DiscountPercentText, DiscountPriceText, PriceText, TextContainer, TwoLinesText } from '../StoreLabels.styles'

export const CercleDiscount = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 50% 50% 50% 50%;
  border: 2px solid #1b1437;
  background-color: #d7002a;
  position: absolute;
  top: 155px; left: 132px;
`

export const HalfMoonSquareDiscount = styled.div`
  width: 100%;
  height: 16rem;
  margin-top: -7rem;
  border-radius: 0% 0% 50% 50%;;
  background-color: #d7002a;
  border: 2px solid #1b1437;
`

export const SquareDiscountContainer = styled.div`
  position: relative;
  width: 19.6rem;
  height: 19.6rem;
  overflow: hidden;

  cursor: pointer;
`

export const Subtitle = styled(TwoLinesText)`
  height: 4rem;
  margin: 0;
`
export const StyledPriceText = styled(PriceText)`
  top: 0.2rem;
`

export const StyledDiscountPrice = styled(DiscountPriceText)`
  top: 4rem;
`

export const StyledDiscountPercentText = styled(DiscountPercentText)`
  bottom: 0.5rem;
  right: 50%;
  transform: translateX(calc(50% + 7rem));
`

export const StyledTextContainer = styled(TextContainer)`
  top: 9.2rem;
`