import Button from 'components/Button/Button'
import styled from 'styled-components'

export const FormContainer = styled.form`
  display: grid;
  grid-template-columns: repeat(4, 2fr) 1fr;
  gap: 2rem 3.5rem;

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const ButtonUpdate = styled(Button)`
  height: 2rem;
  margin-top: 2.4rem;
`
