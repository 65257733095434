import { yupResolver } from '@hookform/resolvers/yup'
import { fetchCustomers, getCustomersUri } from 'api/customers'
import { createOrder } from 'api/orders'
import { fetchShippingModes, getShippingModesUri } from 'api/shippingModes'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Select, { Option } from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { CreateIcon } from 'constants/icons'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import { useMyStocksOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { CustomerInternalType, ShippingTypeName } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { FormLayout, SubmitButton } from './OrderCreateForm.style'
type FormData = {
  stock: string
  shippingMode?: string
  customer: string
}

const schema = yup.object().shape({
  stock: yup.string().trim().required(),
  shippingMode: yup.string().trim(),
  customer: yup.string().trim().required()
})

type Props = {
  closeModal: () => void
}

export default function OrderCreateForm({ closeModal }: Props) {
  const { t } = useTranslation()
  const { me } = useAuth()
  const [atomLocation] = useAtom(defaultStoreAtom)

  const navigate = useNavigate()
  const { myStockOptions } = useMyStocksOptions()
  const [shippingModeOptions, setShippingModeOptions] = useState<Option[]>([])
  const [customerOptions, setCustomerOptions] = useState<Option[]>([])

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      stock: WAREHOUSE_STORE_ID
    }
  })
  const [watchShippingMode, watchStock] = watch(['shippingMode', 'stock'])

  const { isLoading: mutationLoading, mutate: createOrderMutation } = useMutation(
    (body: FormData) => createOrder(body),
    {
      emitDefaultSuccessNotification: false,
      onSuccess: (order) => {
        closeModal()
        navigate(`/order-details/${order.id}`)
      }
    }
  )

  const onSubmit = (formData: FormData) => {
    const { shippingMode, ...restData } = formData
    createOrderMutation({
      ...restData,
      shippingMode: shippingMode || undefined
    })
  }

  const myStocks = _.filter(me!.stores, (e) => e['@id'] !== WAREHOUSE_STORE_ID).map((e) => e['@id']!)

  const { isLoading: isShippingLoading, data: shippingModes } = useQuery(
    ['shipping-modes', { store: watchStock }],
    () =>
      fetchShippingModes(
        getShippingModesUri({
          'order[name]': SortingDirection.Asc,
          ...(watchStock !== WAREHOUSE_STORE_ID ? { store: watchStock } : { 'store[]': myStocks })
        })
      ),
    {
      enabled: !_.isNil(me?.stores),
      onSuccess: (shippingModes) => {
        let options: Option[] = []
        if (shippingModes.length) {
          shippingModes.forEach((shippingMode) => {
            options.push({ label: shippingMode.name as string, value: shippingMode['@id']! })
          })

          const myStoreShippingMode = shippingModes.find((mode) => mode.store === atomLocation?.['@id'])
          setValue('shippingMode', myStoreShippingMode?.['@id'] || shippingModes[0]['@id'])
        }

        if (watchStock === WAREHOUSE_STORE_ID && _.find(me?.stores, { '@id': WAREHOUSE_STORE_ID })) {
          options.push({
            label: <Text fontStyle="italic">{t('page.order.create.homeShipping')}</Text>,
            value: '',
            disabled: watchStock !== WAREHOUSE_STORE_ID
          })
        }
        setShippingModeOptions(options)
      }
    }
  )

  const targetLocationId = useMemo(() => {
    if (watchShippingMode === '') return WAREHOUSE_STORE_ID
    return shippingModes?.find((e) => e['@id'] === watchShippingMode)?.store ?? undefined
  }, [shippingModes, watchShippingMode])

  const { isLoading: isCustomerLoading } = useQuery(
    ['customer', targetLocationId],
    () =>
      fetchCustomers(
        getCustomersUri({
          playinStore: targetLocationId,
          'order[lastname]': SortingDirection.Asc,
          'order[firstname]': SortingDirection.Asc
        })
      ),
    {
      enabled: !_.isNil(targetLocationId),
      onSuccess: (customers) => {
        if (customers) {
          setCustomerOptions(
            customers.map((customer) => ({
              value: customer['@id']!,
              label: [customer.firstname, customer.lastname].join(' ')
            }))
          )
          if (customers.length === 0) setError('customer', { type: 'notAvailable' })
          else {
            const defaultAccount = customers.find((customer) => {
              if (watchShippingMode === '') {
                return customer.playinAccountType?.codeName === CustomerInternalType.InventoryError
              }
              const selectedShipping = _.find(shippingModes, (mode) => mode['@id'] === watchShippingMode)
              if (selectedShipping?.shippingType === ShippingTypeName.SHOP) {
                return customer.playinAccountType?.codeName === CustomerInternalType.Shop
              }

              return false
            })

            setValue('customer', defaultAccount?.['@id'] || customers[0]['@id']!)
            clearErrors('customer')
          }
        }
      }
    }
  )

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Select
          id="stock"
          control={control}
          options={myStockOptions}
          label={t('common.label.originStock')}
          enableUnselect={false}
          tooltip={
            <Tooltip id="stock-tooltip">
              <Trans i18nKey={'page.order.create.tooltips.stock'} components={{ br: <br /> }} />
            </Tooltip>
          }
        />
        <BasicFieldValidationError error={errors.stock} message={t('form.validation.required.originStock')} />
      </div>

      <div>
        <Select
          id="shippingMode"
          control={control}
          options={shippingModeOptions}
          label={t('common.label.targetLocation')}
          tooltip={<TextTooltip id="stock-tooltip" text={t('page.order.create.tooltips.shippingMode')} />}
          enableUnselect={false}
        />
        <BasicFieldValidationError error={errors.shippingMode} message={t('form.validation.required.targetLocation')} />
      </div>

      <div>
        <Select
          id="customer"
          control={control}
          options={customerOptions}
          label={t('common.label.recipient')}
          tooltip={<TextTooltip id="stock-tooltip" text={t('page.order.create.tooltips.customer')} />}
          enableUnselect={false}
        />

        <BasicFieldValidationError error={errors.customer} message={t('page.order.create.noUserAvailable')} />
      </div>

      <SubmitButton
        buttonType="submit"
        icon={CreateIcon}
        disabled={isShippingLoading || isCustomerLoading || customerOptions.length === 0}
        isLoading={mutationLoading}
      >
        {t('page.order.create.create')}
      </SubmitButton>
    </FormLayout>
  )
}
