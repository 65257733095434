import { FetchOrdersQueryParams } from 'api/orders'
import { Filter } from 'components/FiltersList/FiltersList'
import { endOfDay, formatISO } from 'date-fns'
import {
  useClientProfileOptions,
  useMyStocksOptions,
  useOrderSecondaryStatusOptions,
  useOrderStatusOptions,
  usePaymentModeFilters,
  usePaymentStatusFilters,
  useShippingStatusOptions,
  useShippingTypeOptions,
  useStockStateOptions
} from 'hooks/useSelectOptions'
import _, { isNil } from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderProgress } from 'types/enums'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatCalendarDate } from 'utils/dates'

export type FormData = {
  status?: string[]
  secondaryStatus?: OrderProgress
  location?: string
  shippingMode?: string
  paymentMode?: string
  stockState?: string
  shippingStatus?: string
  paymentStatus?: string
  clientProfile?: string
  startDate?: string
  endDate?: string
}

export const defaultOrder: OrderParam<FetchOrdersQueryParams> = {
  sortedBy: 'date',
  direction: SortingDirection.Desc,
  queryParams: { 'order[createdAt]': SortingDirection.Desc }
}

type HookOptions = {
  defaultFilters?: Filter[]
  defaultQueryParams?: any
}
export function useOrderFilters(options: HookOptions = {}) {
  const { t } = useTranslation()
  const { defaultFilters = [], defaultQueryParams } = options
  const { myStocks, getStockFilters, getStockQueryParams, myStockOptions } = useMyStocksOptions()

  const { paymentModeOptions, getPaymentModeFilter, getPaymentModeQueryParam } = usePaymentModeFilters()
  const { paymentStatusOptions, getPaymentStatusFilter, getPaymentStatusQueryParams } = usePaymentStatusFilters()
  const { clientProfileOptions, getMultipleClientProfileQueryParams, getMultipleClientProfileFilter } =
    useClientProfileOptions()
  const { stockStateOptions, getStockStateFilter, getStockStateQueryParams } = useStockStateOptions()
  const { shippingStatusOptions, getShippingStatusFilter, getShippingStatusQueryParams } = useShippingStatusOptions()
  const { orderSecondaryStatusOptions, getOrderSecondaryStatusFilter, getSecondaryStatusQueryParams } =
    useOrderSecondaryStatusOptions()
  const { orderStatusOptions, getOrderStatusFilter, getOrderStatusesQueryParams } = useOrderStatusOptions()
  const { shippingTypeOptions, getShippingTypeFilter } = useShippingTypeOptions()

  const filtersFunctions = useMemo(
    () => ({
      shippingMode: getShippingTypeFilter,
      paymentMode: getPaymentModeFilter,
      paymentStatus: getPaymentStatusFilter,
      location: getStockFilters,
      clientProfile: getMultipleClientProfileFilter,
      stockState: getStockStateFilter,
      shippingStatus: getShippingStatusFilter,
      secondaryStatus: getOrderSecondaryStatusFilter,
      status: getOrderStatusFilter,
      startDate: (id, value?: string) => ({
        id: 'startDate',
        label: t('page.product.detail.tabs.sales.fromDate', { date: formatCalendarDate(value) })
      }),
      endDate: (id, value?: string) => ({
        id: 'endDate',
        label: t('page.product.detail.tabs.sales.toDate', { date: formatCalendarDate(value) })
      })
    }),
    [
      getShippingTypeFilter,
      getPaymentModeFilter,
      getPaymentStatusFilter,
      getStockFilters,
      getMultipleClientProfileFilter,
      getStockStateFilter,
      getShippingStatusFilter,
      getOrderSecondaryStatusFilter,
      getOrderStatusFilter,
      t
    ]
  )

  const queryParamsFunctions = useMemo(
    () => ({
      paymentMode: getPaymentModeQueryParam,
      paymentStatus: getPaymentStatusQueryParams,
      location: getStockQueryParams,
      stockState: getStockStateQueryParams,
      shippingStatus: getShippingStatusQueryParams,
      status: getOrderStatusesQueryParams,
      clientProfile: getMultipleClientProfileQueryParams,
      shippingMode: (value) => ({ 'shippingMode.shippingType[]': value }),
      startDate: (value?: Date) => ({ 'createdAt[after]': value && formatISO(new Date(value)) }),
      endDate: (value?: Date) => ({
        'createdAt[before]': value && formatISO(endOfDay(new Date(value)))
      }),
      secondaryStatus: getSecondaryStatusQueryParams,
      stock: (value) => ({ stock: value })
    }),
    [
      getPaymentModeQueryParam,
      getPaymentStatusQueryParams,
      getStockQueryParams,
      getStockStateQueryParams,
      getShippingStatusQueryParams,
      getOrderStatusesQueryParams,
      getSecondaryStatusQueryParams,
      getMultipleClientProfileQueryParams
    ]
  )

  const [searchFilters, setSearchFilter] = useState<Filter[]>([...defaultFilters])
  const [queryParams, setQueryParams] = useState<any>({
    location: {
      'stock[]': myStocks.map((opt) => opt['@id'])
    },
    order: defaultOrder.queryParams,
    ...defaultQueryParams
  })
  const [isManuallyFiltered, setIsManuallyFiltered] = useState(false)

  const onSubmit = (formData: FormData) => {
    setIsManuallyFiltered(true)

    let toFilters = _.pickBy(formData, (value, name) => value !== '' && !isNil(value) && filtersFunctions[name])
    let toQuery = _.pickBy(formData, (value, name) => value !== '' && !isNil(value) && queryParamsFunctions[name])
    setQueryParams({
      ..._.mapValues(toQuery, (value, key) => queryParamsFunctions[key](value)),

      // If a specific location is set, search with this location (stock)
      // Else search with current user stocks (stock[])
      ...(!toQuery['location'] && {
        location: {
          'stock[]': myStocks.map((opt) => opt['@id'])
        }
      })
    })

    setSearchFilter(_.flattenDeep(_.map(toFilters, (value, key) => filtersFunctions[key](key, value))))
  }

  return {
    searchFilters,
    setSearchFilter,
    setQueryParams,
    queryParams,
    shippingTypeOptions,
    paymentModeOptions,
    orderStatusOptions,
    paymentStatusOptions,
    clientProfileOptions,
    stockStateOptions,
    shippingStatusOptions,
    orderSecondaryStatusOptions,
    isFiltered: isManuallyFiltered,
    myStocks,
    myStockOptions,
    onSubmit
  }
}
