import { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import { TextareaLayout } from 'components/Textarea/Textarea.style'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { EditorProps as StyledEditorProps, StyledEditor } from './styles'

interface Props<T extends FieldValues> extends LayoutProps, StyledEditorProps {
  control: Control<T>
  id: FieldPath<T>
  textLimit?: number
}

export default function RichTextEditor<T extends FieldValues>({
  control,
  id,
  textLimit,
  size,
  ...layoutProps
}: Props<T>) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [charCount, setCharCount] = useState(0)

  return (
    <TextareaLayout
      {...layoutProps}
      secondaryText={
        !_.isNil(textLimit) && (
          <Text color={charCount > textLimit ? 'danger' : 'black'} fontStyle="italic" fontWeight="light">
            {`${charCount} / ${textLimit}`}
          </Text>
        )
      }
    >
      <Controller
        name={id}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <WYSIWYGEditor
              value={value}
              editorState={editorState}
              onChange={onChange}
              setEditorState={setEditorState}
              size={size}
              onCountText={textLimit ? setCharCount : () => {}}
            />
          )
        }}
      />
    </TextareaLayout>
  )
}

interface EditorProps extends StyledEditorProps {
  value: string
  editorState: EditorState
  setEditorState: Dispatch<SetStateAction<EditorState>>
  onChange: (...event: any[]) => void
  onCountText?: (charCount: number) => void
}

const WYSIWYGEditor = ({ value = '', editorState, onChange, setEditorState, size, onCountText }: EditorProps) => {
  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value || '').contentBlocks)))
  }, [value, setEditorState])

  const editorRef = useRef<Editor>(null)

  return (
    <StyledEditor size={size}>
      <Editor
        ref={editorRef}
        editorState={editorState}
        handlePastedText={() => false}
        onEditorStateChange={(state: EditorState) => {
          setEditorState(state)
          if (onCountText) onCountText(state.getCurrentContent().getPlainText('\u0001')?.length)
        }}
        onBlur={() => {
          //Check if content is only empty <p>
          if (
            convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
            convertToRaw(editorState.getCurrentContent()).blocks[0].text === ''
          ) {
            onChange('')
          } else {
            const data = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/[\r\n|\n|\r]/gm, '')
            onChange(data)
          }
        }}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'list',
            'textAlign',
            'link',
            'embedded',
            'emoji',
            'image',
            'remove',
            'history'
          ],
          blockType: {
            options: ['Normal', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
          },
          link: {
            linkCallback: (props) => {
              // when creating a link, editor loose focus,
              // as we update react-hook-form state on editor blur
              // we need to re focus the editor to force a new blur when clicking elsewhere
              editorRef?.current?.focusEditor()
              return props
            }
          }
        }}
      />
    </StyledEditor>
  )
}
