import classNames from 'classnames'
import { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import { ContentState, EditorState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useTranslation } from 'react-i18next'
import { AutoHeightInputLayout, DisplayTextAreaLayout, EditorProps, StyledEditor } from './styles'

type Props = EditorProps &
  LayoutProps & {
    value?: string | null
  }

const RichTextDisplay = ({ value, size, className, ...layoutProps }: Props) => {
  const { t } = useTranslation()
  const [editorState] = useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value || '').contentBlocks))
  )

  return (
    <AutoHeightInputLayout className={classNames(className, 'no-border')} {...layoutProps}>
      <DisplayTextAreaLayout
        className={classNames({
          'no-border': !value
        })}
      >
        {!!value ? (
          <StyledEditor size={size}>
            <Editor editorState={editorState} toolbarOnFocus={false} />
          </StyledEditor>
        ) : (
          <Text color="secondary" fontWeight="light" fontStyle="italic">
            {t('common.label.notAvailable')}
          </Text>
        )}
      </DisplayTextAreaLayout>
    </AutoHeightInputLayout>
  )
}

export default RichTextDisplay
