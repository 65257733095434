import FieldDisplay from 'components/Text/FieldDisplay'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import _ from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { StoreDetails, StoreHours } from 'types/entities'
import { Days } from 'types/schedule'
import StoreScheduleDisplay from './components/StoreScheduleDisplay'
import {
  CityFieldsRow,
  FieldsContainer,
  FormDescriptionSection,
  FormEndSection,
  FormGeneralSection,
  FormLayout,
  TwoFieldsRow
} from './StoreInformationForm.style'

interface Props {
  store: StoreDetails
  hours: StoreHours[]
}

export default function StoreInformationsForm({ store, hours }: Props) {
  const { t } = useTranslation()

  const getSchedule = () => {
    const days = _.groupBy(hours, 'dayOfWeek')
    const fields: Days[] = []

    //For each 7 days of a week
    for (let i = 1; i <= 7; i++) {
      const day = days[i]
      if (day) {
        const daySplits = _.sortBy(day, 'opensAt')

        const daySplitField: Days = {
          morningOpensAt: daySplits[0]?.opensAt,
          morningClosesAt: daySplits[0]?.closesAt,
          morningId: daySplits[0]?.['@id'] ?? null,
          afternoonOpensAt: daySplits[1]?.opensAt,
          afternoonClosesAt: daySplits[1]?.closesAt,
          afternoonId: daySplits[1]?.['@id'] ?? null
        }

        fields.push(daySplitField)
      } else {
        fields.push({
          morningOpensAt: undefined,
          morningClosesAt: undefined,
          morningId: null,
          afternoonOpensAt: undefined,
          afternoonClosesAt: undefined,
          afternoonId: null
        })
      }
    }
    return fields
  }

  return (
    <FormLayout>
      <FormGeneralSection className="read">
        <FieldsContainer>
          <FieldDisplay label={t('page.stores.details.labels.storeName')} value={store.shortName} />
          <TwoFieldsRow>
            <FieldDisplay label={t('page.stores.details.labels.contactEmail')} value={store.contactEmail} />
            <FieldDisplay
              label={t('common.label.phone')}
              value={store.phoneNumber}
              tooltip={
                <Tooltip id="phoneNumberTooltip">
                  <Trans i18nKey="page.stores.details.tooltips.phoneNumber" />
                </Tooltip>
              }
            />
          </TwoFieldsRow>
          <FieldDisplay label={t('page.stores.details.labels.facebookPageUrl')} value={store.facebookPageUrl} />
        </FieldsContainer>

        <FieldsContainer>
          <FieldDisplay label={t('common.label.address')} value={store.address} />
          <CityFieldsRow>
            <FieldDisplay label={t('common.label.zipCode')} value={store.zipCode} />
            <FieldDisplay label={t('common.label.city')} value={store.city} />
          </CityFieldsRow>
          <FieldDisplay label={t('common.label.state')} value={store.state} />
        </FieldsContainer>
      </FormGeneralSection>

      <FormDescriptionSection>
        <FieldDisplay label={t('page.stores.details.labels.howToGo')} value={store.howToGo} />
        <FieldDisplay label={t('page.stores.details.labels.howToGoEn')} value={store.howToGoEn} />
        <FieldDisplay
          label={t('page.stores.details.labels.openingHoursDescription')}
          value={store.openingHoursDescription}
        />
        <FieldDisplay
          label={t('page.stores.details.labels.openingHoursDescriptionEn')}
          value={store.openingHoursDescriptionEn}
        />
        <FieldDisplay
          label={t('page.stores.details.labels.exceptionalOpeningDescription')}
          value={store.exceptionalOpeningDescription}
          tooltip={
            <Tooltip id="exceptionalOpeningDescriptionTooltip">
              <Trans i18nKey="page.stores.details.tooltips.exceptionalOpeningDescription" />
            </Tooltip>
          }
        />
        <FieldDisplay
          label={t('page.stores.details.labels.exceptionalOpeningDescriptionEn')}
          value={store.exceptionalOpeningDescriptionEn}
        />
      </FormDescriptionSection>

      <StoreScheduleDisplay days={getSchedule()} />

      <FormEndSection>
        <FieldDisplay
          label={t('page.stores.details.labels.activeStore')}
          value={store.active ? t('common.radioButton.yes') : t('common.radioButton.no')}
          tooltip={<TextTooltip id="activeStoreTooltip" text={t('page.stores.details.tooltips.activeStore')} />}
        />
        <FieldDisplay
          label={t('page.stores.details.labels.franchise')}
          value={store.franchised ? t('common.radioButton.yes') : t('common.radioButton.no')}
        />
        <FieldDisplay
          label={t('page.stores.details.labels.cardSeller')}
          value={store.cardSeller ? t('common.radioButton.yes') : t('common.radioButton.no')}
          tooltip={<TextTooltip id="activeStoreTooltip" text={t('page.stores.details.tooltips.cardSeller')} />}
        />
        <FieldDisplay
          label={t('page.stores.details.labels.deliveryTime')}
          value={t('page.stores.details.labels.deliveryDaysCount', { count: store.warehouseDeliveryTime })}
        />
      </FormEndSection>
    </FormLayout>
  )
}
