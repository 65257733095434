import { PickByType } from './utils'

export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type OrderQueryParams<T> = PickByType<T, 'asc' | 'desc'>
export type OrderParam<T> = {
  sortedBy: string
  direction: SortingDirection
  //TODO : typer les order[] de l'api en enum SortingDirection
  queryParams?: OrderQueryParams<T>
}
