import { yupResolver } from '@hookform/resolvers/yup'
import { getCardsUri } from 'api/cards'
import Button from 'components/Button/Button'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { Option } from 'components/InputAutoComplete/InputAutoComplete.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { MoreIcon } from 'constants/icons'
import { usePricingTagsOptions } from 'hooks/entityHooks/cardHooks'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CardProduct } from 'types/entities'
import * as yup from 'yup'
import { FormContainer } from '../../StoreEventManage/StoreEventManage.styles'
import { AddPricingTagInputs } from '../PricingTag'

interface Props {
  onSubmit: (formData: AddPricingTagInputs) => void
  isLoading?: boolean
}

const schema = yup
  .object()
  .shape({
    cardName: yup.string().trim(),
    pricingTag: yup.string().trim().required()
  })

export default function AddPricingTagForm({ onSubmit, isLoading }: Props) {
  const { t } = useTranslation()
  const { pricingTagsOptions } = usePricingTagsOptions()

  const {
    control,
    handleSubmit,
    formState: { touchedFields: cardTouchedFields, errors },
  } = useForm<AddPricingTagInputs>({
    resolver: yupResolver(schema)
  })

  const submitHandler = (formData: AddPricingTagInputs) => {
    onSubmit(formData)
  }

  const formatCardNameOption = (card: CardProduct, onClick: () => void) => (
    <Option onClick={onClick}>
      <Text>{card.name}</Text>
      <Text fontStyle="italic">{`(${card.englishName})`}</Text>
    </Option>
  )


  return (
    <CardLayout>
      <Title2>{t('page.pricingTag.addPricingCard')}</Title2>
      <FormContainer onSubmit={handleSubmit(submitHandler)}>
        <InputAutoComplete
            id="cardIri"
            className={errors.cardIri ? 'is-invalid' : ''}
            placeholder={t('page.order.detail.orderEntries.table.placeholders.cardName')}
            labelKey="name"
            valueKey="@id"
            control={control}
            isTouched={cardTouchedFields.cardIri}
            getUrlFromSearch={(search) => getCardsUri({ search: search })}
            formatOption={formatCardNameOption}
            label={t('common.label.cardName')}
          />
        <Select
          id="pricingTag"
          label={t('common.label.tag')}
          options={pricingTagsOptions}
          control={control}
          placeholder={t('common.select.defaultOptions.tag')}
          enableUnselect={true}
        />
        <Button icon={MoreIcon} shape="circle" iconColor="white" buttonType="submit" isLoading={isLoading} />
      </FormContainer>
    </CardLayout>
  )
}
