import Popover from '@mui/material/Popover'
import styled from 'styled-components'

export const ToggleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;

  color: ${(props) => props.theme.colors[props.color ?? 'black']};

  border: 0.1rem solid ${(props) => props.theme.borderColors[props.color ?? 'secondary']};
  border-radius: 1rem;

  background-color: ${(props) => props.theme.colors.white};
  cursor: pointer;
`

export const TooltipContainer = styled.div<{ width?: string }>`
  width: ${(props) => props.width ?? '25rem'};

  border: 0.1rem solid ${(props) => props.theme.borderColors.secondary};
  border-radius: 0.2rem;
  box-shadow: 0.1rem 0.1rem 0.4rem ${(props) => props.theme.colors.grey3};
  padding: 1rem;

  font-size: 1.3rem;
`

export const TooltipAutoContainer = styled(TooltipContainer)`
  width: unset;
  padding: 0.5rem;
`

export const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    margin-top: 0.5rem;
  }
`
export const TooltipCol = styled.div`
  display: grid;
  gap: 1rem;
`
