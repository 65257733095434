import { usePatchRole } from 'api/role'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import Cell from 'components/SortedTable/Cell'
import { ListActions } from 'components/SortedTable/SortedTable.styles'
import { Text } from 'components/Text/Text.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useApiNotifications } from 'hooks/useApiNotifications'
import _ from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Role as RoleEntity, RoleAction as RoleActionEntity } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import AddRoleActionForm from '../form/AddRoleActionForm'
import { DeleteModalDescription } from '../PermissionManage.style'
interface Props {
  role: RoleEntity
}
export default function ActionsByRole({ role }: Props) {
  const { t } = useTranslation()
  const [selectedRoleActions, setSelectedRoleActions] = useState<string[]>([])
  const [deleteModalOpen, toggleDeleteModal] = useState(false)
  const { checkMatch, hasRole } = useCheckMatch()

  const columns: Columns<RoleActionEntity> = [
    {
      key: 'checkbox',
      decorator: (action) => (
        <Cell>
          <Checkbox
            className={classNames({
              checked: selectedRoleActions.includes(action['@id'])
            })}
            onClick={() => setSelectedRoleActions((prev) => _.xor(prev, [action['@id']!]))}
          />
        </Cell>
      )
    },
    {
      key: 'name',
      name: t('page.permissions.manage.labels.associedAction'),
      decorator: (action) => <Cell>{action.displayName}</Cell>
    },
    {
      key: 'details',
      name: t('page.permissions.manage.labels.actionDetails'),
      decorator: (action) => (
        <Cell>
          <Text fontWeight="light" color="secondary">
            {action.description}
          </Text>
        </Cell>
      )
    }
  ].filter((col) => {
    if (col.key === 'checkbox')
      return checkMatch([RoleAction.ROLE_ACTION_PERMISSIONS_MANAGE_EDIT]) && hasRole([role.roleName!])
    return true
  })

  const { mutateAsync, isLoading } = usePatchRole(role.id!, { emitDefaultSuccessNotification: false })
  const { emitSuccessNotification } = useApiNotifications()
  const handleDelete = async () => {
    await mutateAsync({
      collaboratorActions: _.difference(
        role.collaboratorActions?.map((e) => e['@id']!),
        selectedRoleActions
      )
    })
    toggleDeleteModal(false)
    emitSuccessNotification()
    setSelectedRoleActions([])
  }

  return (
    <div>
      <ShouldHide
        permissions={[RoleAction.ROLE_ACTION_PERMISSIONS_MANAGE_EDIT]}
        deniedExtraCondition={!hasRole([role.roleName!])}
      >
        <>
          <AddRoleActionForm role={role} />
          {!!role.collaboratorActions?.length && (
            <ListActions>
              <Checkbox
                className={classNames({
                  checked: selectedRoleActions.length === role.collaboratorActions?.length
                })}
                onClick={() =>
                  setSelectedRoleActions((prev) =>
                    prev.length === role.collaboratorActions?.length
                      ? []
                      : role.collaboratorActions?.map((action) => action['@id']!) ?? []
                  )
                }
              />
              <TooltipHover
                id="deleteRoleActionTooltip"
                content={t('page.permissions.manage.role.tooltips.deleteAction', { roleName: role.name })}
                container="tooltipContainer"
              >
                <Button
                  variant="white"
                  shape="circle"
                  icon={DeleteIcon}
                  disabled={!selectedRoleActions.length}
                  onClick={() => toggleDeleteModal(true)}
                />
              </TooltipHover>
            </ListActions>
          )}
          <ConfirmModal
            open={deleteModalOpen}
            onClose={() => toggleDeleteModal(false)}
            callback={handleDelete}
            closeOnCallback={false}
            title={t('page.permissions.manage.role.deleteModal.title', { count: selectedRoleActions.length })}
            confirmBtnProps={{
              isLoading
            }}
            confirmLabel={t('common.button.delete')}
          >
            <DeleteModalDescription fontWeight="light" color="secondary">
              {t('page.permissions.manage.role.deleteModal.decription', {
                count: selectedRoleActions.length,
                roleName: role.name
              })}
            </DeleteModalDescription>
            <Listing
              columns={columns.filter((col) => col.key !== 'checkbox')}
              data={_.sortBy(
                role.collaboratorActions?.filter((action) => selectedRoleActions.includes(action['@id']!)),
                'displayName'
              )}
            />
          </ConfirmModal>
        </>
      </ShouldHide>
      <Listing
        columns={columns}
        data={_.sortBy(role.collaboratorActions, 'displayName') ?? []}
        totalCount={role.collaboratorActions?.length}
        emptyText={t('page.permissions.manage.role.listing.actionsEmpty')}
      />
    </div>
  )
}
