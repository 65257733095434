import { useTranslation } from 'react-i18next'
import { StyledText } from './FormValidationErrors.styles'

interface Props {
  error?: {
    type: string
    message?: string
  }
}

const EmailValidationErrors = ({ error }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {error && (
        <StyledText color="danger">
          {{
            email: t('form.validation.format.email'),
            required: t('form.validation.required.email')
          }[error.type] || error.message}
        </StyledText>
      )}
    </>
  )
}

export default EmailValidationErrors
