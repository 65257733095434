import { yupResolver } from '@hookform/resolvers/yup'
import { fetchFidelityPoints, getFidelityPointUri } from 'api/fidelityPoints'
import { GetOrderByIdResponse, cancelOrderSend, orderByIdQueryKey, sendOrder } from 'api/orders'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputNumber from 'components/InputNumber/InputNumber'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Loader from 'components/Loader/Loader'
import { Title2 } from 'components/Title/Title.styles'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import { SaveIcon } from 'constants/icons'
import ConfirmModal from 'features/Modals/ConfirmModal'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { UnmodifiableOrderStatuses } from 'types/enums'
import { OrderStatus } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { ModalAlertText } from '../../ActionButtons/ActionButtons.style'
import { GappedRowContainer, InputRow, SpaceBetweenRowContainer } from '../ShippingCard.style'
import ChronopostLikeShippingForm from './ChronopostLikeShippingForm'

type Props = {
  order?: GetOrderByIdResponse
}

interface ShippingFormInputs {
  trackingNumber: string
  weight: number | null
}

const schema = yup.object().shape({
  trackingNumber: yup.string().trim().required(),
  weight: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
})

export default function ShippingForm({ order }: Props) {
  const { t } = useTranslation()
  const [cancelShippingModalOpen, toggleCancelShippingModal] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors }
  } = useForm<ShippingFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      trackingNumber: order?.trackingNumber,
      weight: order?.weight
    }
  })

  useEffect(() => {
    reset({
      trackingNumber: order?.trackingNumber,
      weight: order?.weight ?? null
    })
  }, [order, reset])

  const getRequiredLabel = (label: string) => {
    return `${label}\u00a0*`
  }

  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)
  const { mutate, isLoading } = useMutation(
    (body: ShippingFormInputs) => sendOrder({ trackingNumber: body.trackingNumber, weight: body.weight! }, order?.id!),
    {
      onSuccess: (updatedOrder) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, { ...prev, ...updatedOrder })
        document.getElementById('sidebarSearchOrder')?.focus()
      }
    }
  )

  const { mutate: cancelSendMutation, isLoading: isCancelLoading } = useMutation(() => cancelOrderSend(order?.id!), {
    onSuccess: (data) => {
      queryClient.setQueryData(key, data)
    }
  })

  // TODO: Not available yet
  // const canBeShipped =
  //   !!order?.status &&
  //   ![OrderStatus.Ready, OrderStatus.Treated, OrderStatus.Canceled, OrderStatus.CanceledRequested].includes(
  //     order.status
  //   )

  const onSubmit = (body: ShippingFormInputs) => mutate(body)
  const hasErrors = !!Object.keys(errors).length

  const fidelityPointQuery = getFidelityPointUri({
    customer: order?.customer?.['@id'],
    pagination: false,
    activeOrWaiting: true
  })

  const orderPoint = order?.fidelityPoint?.value ?? 0

  const { data: points, isFetching } = useQuery(fidelityPointQuery, () => fetchFidelityPoints(fidelityPointQuery), {
    enabled: cancelShippingModalOpen && orderPoint > 0
  })

  const fidelityPointCount =
    points?.filter((point) => point.active).reduce((prev, point) => prev + (point.value! - point.consumed!), 0) ?? 0

  return (
    <>
      <Title2>{t('page.order.detail.startShippingTitle')}</Title2>
      {order?.shippingMode?.labelGenerated && (
        // TODO: Not available yet
        <ChronopostLikeShippingForm order={order} isShipped={!!order.sentAt} />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputRow>
          <Input
            id="trackingNumber"
            register={register}
            label={getRequiredLabel(t('common.label.trackingNumber'))}
            layout="column"
            className={classnames({ 'is-invalid': errors.trackingNumber })}
            inputProps={{ id: 'orderTrackingNumberInput' }}
          />
          <InputNumber
            id="weight"
            register={register}
            label={t('page.order.detail.weight')}
            layout="column"
            setValue={setValue}
            trigger={trigger}
            showArrows={false}
          />
        </InputRow>
        {hasErrors && (
          <BlockLayout>
            <BasicFieldValidationError
              error={errors.trackingNumber}
              message={t('page.order.detail.trackingNumberRequired')}
            />
          </BlockLayout>
        )}
        <SpaceBetweenRowContainer>
          <GappedRowContainer>
            <Button
              shape="circle"
              icon={SaveIcon}
              buttonType="submit"
              disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
              isLoading={isLoading}
            />
            <MultiLineTooltip id={'cancel-order-tooltip'} lines={[t('page.order.detail.startShippingTooltip')]} />
          </GappedRowContainer>
          <Button
            variant="white"
            onClick={() => toggleCancelShippingModal(true)}
            disabled={order?.status !== OrderStatus.Send}
            isLoading={isCancelLoading}
          >
            {t('page.order.detail.modals.cancelShipping')}
          </Button>
        </SpaceBetweenRowContainer>
      </form>
      <ConfirmModal
        open={cancelShippingModalOpen}
        onClose={() => {
          toggleCancelShippingModal(false)
        }}
        title={t('page.order.detail.modals.cancelShippingTitle', { referenceNumber: order?.referenceNumber })}
        callback={() => cancelSendMutation({})}
        confirmLabel={t('page.order.detail.modals.cancelShipping')}
      >
        <>
          {orderPoint > 0 &&
            (isFetching ? (
              <Loader />
            ) : (
              <ModalAlertText>
                {fidelityPointCount >= orderPoint ? (
                  t('page.order.detail.modals.body.cancelTreatOrder.body1', { orderPoint: orderPoint })
                ) : (
                  <Trans
                    i18nKey="page.order.detail.modals.body.cancelTreatOrder.body2"
                    components={{ error: <span /> }}
                    values={{ orderPoint: orderPoint, customerPoints: fidelityPointCount }}
                  />
                )}
              </ModalAlertText>
            ))}
        </>
      </ConfirmModal>
    </>
  )
}
