import { CardmarketIcon, CardtraderIcon, PlayinPIcon, SellerManiaIcon } from 'constants/icons'
import { ComponentType } from 'react'
import { CustomerType } from 'types/playInApiInterfaces'

export function getCustomerIcon(
  playinAccountType?: string | null,
  sourceName?: string | null
): ComponentType | undefined {
  if (playinAccountType) return PlayinPIcon
  else if (sourceName === 'Card Market') return CardmarketIcon
  else if (sourceName === 'Sellermania') return SellerManiaIcon
  else if (sourceName === 'CardTrader') return CardtraderIcon
  return undefined
}

export function getCustomerColorLabel(
  playinAccountType?: string | null,
  sourceName?: string | null
): string | undefined {
  if (playinAccountType) return 'playin'
  else if (sourceName === 'Card Market') return 'cardmarket'
  else if (sourceName === 'Sellermania') return 'sellermania'
  else if (sourceName === 'CardTrader') return 'cardtrader'
  return undefined
}

export function getAccountTypeIcon(type?: CustomerType): ComponentType | undefined {
  if (type === CustomerType.CARDMARKET) return CardmarketIcon
  else if (type === CustomerType.CARDTRADER) return CardtraderIcon
  else if (type === CustomerType.INTERNAL) return PlayinPIcon
  else if (type === CustomerType.SELLERMANIA) return SellerManiaIcon
  return undefined
}
