import Tooltip from '../Tooltip/Tooltip'
import { StyledTitle2, Title2TooltipContainer } from './Title2Tooltip.style'

interface Props {
  id?: string
  text: string | JSX.Element
  title: string
}

export const Title2Tooltip = ({ id, text, title }: Props) => {
  const tooltipId = id || Symbol().toString()
  return (
    <Title2TooltipContainer>
      <StyledTitle2>{title}</StyledTitle2>
      <Tooltip id={tooltipId}>{text}</Tooltip>
    </Title2TooltipContainer>
  )
}

export default Title2Tooltip
