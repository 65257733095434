import { getOrdersUri, useOrdersPaginatedQuery } from 'api/orders'
import Listing, { Columns } from 'features/Listing/Listing'
import { useTranslation } from 'react-i18next'
import { Customer, Order } from 'types/entities'
import { OrderStatus } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'

interface Props {
  customer: Customer
  columns: Columns<Order>
  handleCount: (count?: number) => void
}
export default function CustomerCurrentOrders({ customer, columns, handleCount }: Props) {
  const uri = getOrdersUri({
    customer: customer['@id'],
    'status[]': [
      OrderStatus.InPreparation,
      OrderStatus.Paid,
      OrderStatus.Ready,
      OrderStatus.Validated,
      OrderStatus.CanceledRequested
    ],
    'order[createdAt]': SortingDirection.Desc
  })

  const { t } = useTranslation()
  const {
    data: orders,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItems
  } = useOrdersPaginatedQuery(uri, uri, {
    enabled: uri !== '',
    onSuccess: (data, total) => {
      handleCount(total)
    }
  })

  return (
    <Listing
      columns={columns}
      data={orders}
      emptyText={t('page.order.manage.listing.emptyText')}
      totalCount={totalItems}
      countText={t('page.order.manage.listing.countText', { current: orders.length, total: totalItems ?? 0 })}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
    />
  )
}
