import { yupResolver } from '@hookform/resolvers/yup'
import { postStore, PostStoreBody } from 'api/stores'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputRadio from 'components/InputRadio/InputRadio'
import Modal, { ModalProps } from 'components/Modal/Modal'
import MainTitle from 'components/Title/MainTitle'
import Tooltip from 'components/Tooltip/Tooltip'
import { CreateIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { getPath, RouteName } from 'permissions/permissions'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as yup from 'yup'
import { FormWrapper } from './StoreCreateModal.style'

const schema = yup.object({
  shortName: yup
    .string()
    // Store name can't have "playin" in his name, as it's auto added
    .test('playin', 'Invalid name', (value) => (!!value ? !new RegExp(/\playin/, 'i').test(value) : true))
    .trim()
    .required(),
  franchised: yup.boolean().required(),
  cardSeller: yup.boolean().required()
})

type CreateStoreInputs = yup.InferType<typeof schema>

interface Props extends ModalProps {}
export default function StoreCreateModal({ onClose, open }: Props) {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset
  } = useForm<CreateStoreInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      franchised: true,
      cardSeller: false
    }
  })

  const navigate = useNavigate()

  const { mutate: createStore, isLoading } = useMutation((body: PostStoreBody) => postStore(body), {
    onSuccess: (store) => {
      reset()
      navigate(getPath(RouteName.StoreDetails).replace(':id', `${store.id}`))
      onClose()
    }
  })

  const onSubmit = (formData: CreateStoreInputs) => {
    createStore(formData)
  }
  return (
    <Modal open={open} onClose={onClose}>
      <MainTitle title={t('page.stores.create.title')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <div>
            <Input
              label={t('page.stores.details.labels.storeName')}
              id="shortName"
              register={register}
              className={classNames({
                'is-invalid': errors.shortName
              })}
              tooltip={
                <Tooltip id="storeNameTooltip">
                  <Trans i18nKey={'page.stores.create.tooltips.storeName'} />
                </Tooltip>
              }
            />
            <BasicFieldValidationError
              messages={{
                required: t('form.validation.required.generic'),
                playin: t('page.stores.create.errors.name')
              }}
              error={errors.shortName}
            />
          </div>

          <div>
            <InputRadio
              control={control}
              id="franchised"
              label={t('page.stores.details.labels.franchise')}
              className={classNames({
                'is-invalid': errors.franchised
              })}
            />
            <BasicFieldValidationError message={t('form.validation.required.generic')} error={errors.franchised} />
          </div>

          <div>
            <InputRadio
              control={control}
              id="cardSeller"
              label={t('page.stores.details.labels.cardSeller')}
              className={classNames({
                'is-invalid': errors.cardSeller
              })}
            />
            <BasicFieldValidationError message={t('form.validation.required.generic')} error={errors.cardSeller} />
          </div>

          <Button buttonType="submit" icon={CreateIcon} size="full" isLoading={isLoading}>
            {t('page.stores.create.button')}
          </Button>
        </FormWrapper>
      </form>
    </Modal>
  )
}
