import { FetchOrdersQueryParams } from 'api/orders'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker/DatePicker'
import FiltersList, { Filter } from 'components/FiltersList/FiltersList'
import Input from 'components/Input/Input'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import MultipleSelect from 'components/Select/MultipleSelect'
import Select from 'components/Select/Select'
import Svg from 'components/Svg/Svg'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import {
  DeliveryIcon,
  InventoryIcon,
  MoreIcon,
  PlanningIcon,
  PrintIcon,
  ProductIcon,
  RefundIcon,
  SearchIcon,
  StockIcon
} from 'constants/icons'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useEmit } from 'hooks/useEventEmitter'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Store } from 'types/entities'
import { EventType } from 'types/events'
import { NotificationId, NotificationType } from 'types/notifications'
import { OrderStatus, RoleAction } from 'types/playInApiInterfaces'
import { isObjectEmpty } from 'utils/queryParams'
import useOrderFilter, { FormData } from '../useOrderFilter'
import {
  Column,
  FieldSection,
  FieldsLayout,
  FreeInputContainer,
  SeeMoreContainer,
  TextFieldColumn
} from './OrderManageForm.style'

interface Props {
  onSubmit: (queryParams: { [key in string]: FetchOrdersQueryParams }) => void
  isLoading: boolean
  sidebarSearch?: string
  defaultStatus: OrderStatus[]
  store: Store | undefined
  isPristine: boolean
}

export default function OrderManageForm({
  onSubmit,
  isLoading,
  sidebarSearch,
  defaultStatus,
  store,
  isPristine
}: Props) {
  const { t } = useTranslation()
  const emitNotification = useEmit()

  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    register
  } = useForm<FormData>({
    defaultValues: {
      status: defaultStatus
    }
  })

  const [moreFilters, seeMoreFilters] = useState<boolean>(false)
  const [searchFilters, setSearchFilters] = useState<Filter[]>([])

  const {
    orderStatusOptions,
    orderSecondaryStatusOptions,
    stockStateOptions,
    shippingTypeOptions,
    shippingStatusOptions,
    paymentModeOptions,
    paymentStatusOptions,
    orderPrintStatusOptions,
    familyOptions,
    clientProfileOptions,
    handleForm,
    getSearchFilter,
    getOrderStatusFilter,
    myStockOptions
  } = useOrderFilter(store, setSearchFilters)

  const defaultFilters = useMemo(
    () => (sidebarSearch ? [getSearchFilter('search', sidebarSearch)] : getOrderStatusFilter('status', defaultStatus)),
    [getSearchFilter, getOrderStatusFilter, defaultStatus, sidebarSearch]
  )

  useEffect(() => {
    if (isPristine) setValue('status', defaultStatus)
  }, [setValue, defaultStatus, isPristine])

  useEffect(() => {
    if (isPristine) setSearchFilters(defaultFilters)
  }, [sidebarSearch, defaultFilters, isPristine])

  useEffect(() => {
    // on store change, set inputRadio to false and remove filter label
    setValue('sentOrReceivedByAllStores', false)
    setSearchFilters((prev) => prev.filter((filter) => filter.id !== 'sentOrReceivedByAllStores'))
  }, [store, setValue])

  return (
    <CardLayout>
      <Title2>{t('page.order.manage.formTitle')}</Title2>
      {!sidebarSearch && (
        <form
          onSubmit={handleSubmit((data: FormData) => {
            // If formData are empty: only empty string, null, undified, empty array values
            // Or all statuses are selected without any other params
            // Push warning notification + don't refetch query
            if (
              (_.isEqual(data.status, []) || data.status?.length === orderStatusOptions.length) &&
              isObjectEmpty(_.omit(data, 'status'))
            ) {
              emitNotification(EventType.Notification, {
                id: NotificationId.Warning,
                type: NotificationType.Warning,
                title: t('page.order.manage.notifications.search.title'),
                text: t('page.order.manage.notifications.search.text')
              })
              return
            }

            onSubmit(handleForm(data))
          })}
        >
          <FieldsLayout>
            <FieldSection>
              <Svg svg={InventoryIcon} size="2.5rem" />
              <Column>
                <MultipleSelect
                  id="status"
                  options={orderStatusOptions}
                  control={control}
                  placeholder={t('common.select.defaultOptions.status')}
                  label={t('common.label.mainStatus')}
                  tooltip={<TextTooltip id="productDetailOrdersStatus" text={t('tooltips.product.orderStatus')} />}
                />
                <Select
                  id="secondaryStatus"
                  label={t('common.label.secondaryStatus')}
                  placeholder={t('common.select.defaultOptions.secondaryStatus')}
                  options={orderSecondaryStatusOptions}
                  control={control}
                  enableUnselect={true}
                  tooltip={
                    <TextTooltip
                      id="productDetailOrdersSecondaryStatus"
                      text={t('tooltips.product.orderSecondaryStatus')}
                    />
                  }
                />
              </Column>
            </FieldSection>

            <FieldSection>
              <Svg svg={StockIcon} size="2.5rem" />

              <Column>
                <Select
                  id="stock"
                  label={t('common.label.stock')}
                  placeholder={t('common.select.defaultOptions.stock')}
                  options={myStockOptions}
                  control={control}
                  tooltip={<TextTooltip id="productDetailOrdersLocation" text={t('tooltips.product.orderLocation')} />}
                  enableUnselect={true}
                />
                <Select
                  id="stockStatus"
                  label={t('common.label.stockState')}
                  placeholder={t('common.select.defaultOptions.stockState')}
                  options={stockStateOptions}
                  control={control}
                  enableUnselect={true}
                />
              </Column>
            </FieldSection>

            <FieldSection>
              <Svg svg={DeliveryIcon} size="2.5rem" />
              <Column>
                <MultipleSelect
                  id="shippingMode"
                  label={t('common.label.shippingType')}
                  placeholder={t('common.select.defaultOptions.shippingType')}
                  options={shippingTypeOptions}
                  control={control}
                />
                <Select
                  id="shippingStatus"
                  label={t('common.label.shippingStatus')}
                  placeholder={t('common.select.defaultOptions.shippingStatus')}
                  options={shippingStatusOptions}
                  control={control}
                  enableUnselect={true}
                />
              </Column>
            </FieldSection>

            <FieldSection>
              <Svg svg={RefundIcon} size="2.5rem" />
              <Column>
                <Select
                  id="paymentMode"
                  label={t('common.label.paymentMethod')}
                  placeholder={t('common.select.defaultOptions.paymentMode')}
                  options={paymentModeOptions}
                  control={control}
                  enableUnselect={true}
                  tooltip={
                    <Tooltip id="totalPaidTooltip">
                      <Trans i18nKey="page.order.manage.tooltips.workInProgress" />
                    </Tooltip>
                  }
                />
                <Select
                  id="paymentStatus"
                  label={t('common.label.paymentStatus')}
                  placeholder={t('common.select.defaultOptions.paymentStatus')}
                  options={paymentStatusOptions}
                  control={control}
                  enableUnselect={true}
                />
              </Column>
            </FieldSection>

            {moreFilters && (
              <>
                <FieldSection>
                  <Svg svg={PlanningIcon} size="2.5rem" />
                  <Column>
                    <DatePicker
                      id="createdAfter"
                      register={register}
                      setValue={setValue}
                      trigger={trigger}
                      defaultValue=""
                      label={t('common.label.startDate')}
                    />
                    <DatePicker
                      id="createdBefore"
                      register={register}
                      setValue={setValue}
                      trigger={trigger}
                      defaultValue=""
                      label={t('common.label.endDate')}
                    />
                  </Column>
                </FieldSection>

                <FieldSection>
                  <Svg svg={PrintIcon} size="2.5rem" />
                  <Column>
                    <DatePicker
                      id="printedAt"
                      register={register}
                      setValue={setValue}
                      trigger={trigger}
                      defaultValue=""
                      label={t('common.label.printDate')}
                    />
                    <Select
                      id="printStatus"
                      options={orderPrintStatusOptions}
                      control={control}
                      label={t('common.label.printStatus')}
                      placeholder={t('common.select.defaultOptions.printStatus')}
                      enableUnselect={true}
                    />
                  </Column>
                </FieldSection>

                <FieldSection>
                  <Svg svg={ProductIcon} size="2.5rem" />
                  <Column>
                    <Select
                      id="family"
                      options={familyOptions}
                      control={control}
                      label={t('common.label.family')}
                      placeholder={t('common.select.defaultOptions.familly')}
                      enableUnselect={true}
                    />
                    <InputFormattedNumber
                      control={control}
                      id="totalPaid"
                      label={t('common.label.totalPaid')}
                      suffix=" €"
                      decimalScale={2}
                      placeholder="0,00 €"
                      tooltip={
                        <Tooltip id="totalPaidTooltip">
                          <Trans i18nKey="page.order.manage.tooltips.totalPaid" />
                        </Tooltip>
                      }
                      disabled
                    />
                  </Column>
                </FieldSection>

                <FieldSection>
                  <div />
                  <Column>
                    <TextFieldColumn>
                      <FreeInputContainer>
                        <InputRadio
                          control={control}
                          id="orderContainsCard"
                          label={t('page.order.manage.orderContent')}
                        />
                      </FreeInputContainer>
                    </TextFieldColumn>
                  </Column>
                </FieldSection>

                <TextFieldColumn>
                  <MultipleSelect
                    id="clientProfile"
                    label={t('common.label.clientProfile')}
                    placeholder={t('common.select.defaultOptions.account')}
                    options={clientProfileOptions}
                    control={control}
                  />
                  <Input register={register} id="billNumber" label={t('common.label.invoiceId')} />
                </TextFieldColumn>
                <TextFieldColumn>
                  <Input register={register} id="trackingNumber" label={t('common.label.trackingNumber')} />

                  <Input
                    register={register}
                    id="sourceReference"
                    label={
                      store?.warehouse ? t('common.label.marketPlaceReference') : t('common.label.hiboutikReference')
                    }
                    tooltip={
                      store?.warehouse && (
                        <Tooltip id="marketplaceTooltip">
                          <Trans i18nKey="page.order.manage.tooltips.marketplace" />
                        </Tooltip>
                      )
                    }
                  />
                </TextFieldColumn>
                <ShouldHide permissions={[RoleAction.ROLE_ACTION_ORDERS_READ_ALL_STOCK]}>
                  <TextFieldColumn>
                    <FreeInputContainer>
                      <InputRadio
                        id="sentOrReceivedByAllStores"
                        control={control}
                        label={t('common.label.sentOrReceivedByAllStores')}
                      />
                    </FreeInputContainer>
                  </TextFieldColumn>
                </ShouldHide>
              </>
            )}
          </FieldsLayout>

          {!moreFilters && (
            <SeeMoreContainer>
              <Button variant="white" icon={MoreIcon} onClick={() => seeMoreFilters(true)}>
                {t('common.button.seeMoreFilters')}
              </Button>
            </SeeMoreContainer>
          )}
          <Button shape="circle" icon={SearchIcon} buttonType="submit" isLoading={isLoading} />
        </form>
      )}

      <FiltersList
        filters={searchFilters}
        onRemoveFilter={_.noop() as any} // clicks are disabled
        disableClick={true}
        showClearAll={false}
        className="no-padding"
      />
    </CardLayout>
  )
}
