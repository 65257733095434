import { FetchOrdersQueryParams, getOrdersUri, useOrdersPaginatedQuery } from 'api/orders'
import DatePicker from 'components/DatePicker/DatePicker'
import FiltersList from 'components/FiltersList/FiltersList'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import MultipleSelect from 'components/Select/MultipleSelect'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { SearchIcon } from 'constants/icons'
import CountWithPerPageSelect from 'features/Listing/CountWithPerPageSelect'
import Listing from 'features/Listing/Listing'
import { useOrderStatusOptions } from 'hooks/useSelectOptions'
import { useCheckMatch } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OngoingStatuses } from 'types/enums'
import { RoleAction } from 'types/playInApiInterfaces'
import { OrderParam, SortingDirection } from 'types/sorting'
import { mergeFormQueryParams } from 'utils/queryParams'
import { defaultOrder, FormData, useOrderFilters } from './OrderFilters'
import { FormattedOrder, useOrdersTable } from './OrdersTable'
import { DateContainer, FieldCol, FilterGrid, SearchButton } from './ProductSalesTab.styles'

type Props = {
  productId: string
  active: boolean
}

const addDefaultQueryParams = (queryParams: FetchOrdersQueryParams) => {
  if (!queryParams['order[createdAt]']) {
    return { ...queryParams, 'order[createdAt]': SortingDirection.Desc }
  }
  return queryParams
}

const ProductSalesTab = ({ productId, active }: Props) => {
  const { t } = useTranslation()

  const [perPage, setPerPage] = useState(30)

  const { register, setValue, trigger, reset, getValues, control, handleSubmit } = useForm<FormData>({
    defaultValues: {
      clientProfile: '',
      location: '',
      paymentMode: '',
      paymentStatus: '',
      shippingMode: '',
      shippingStatus: '',
      status: OngoingStatuses,
      stockState: '',
      endDate: '',
      startDate: ''
    }
  })

  const { columns, formatOrderEntry } = useOrdersTable(productId)
  const { getOrderStatusFilter, getOrderStatusesQueryParams } = useOrderStatusOptions()
  const {
    queryParams,
    searchFilters,
    setQueryParams,
    setSearchFilter,
    paymentModeOptions,
    shippingTypeOptions,
    orderStatusOptions,
    paymentStatusOptions,
    clientProfileOptions,
    stockStateOptions,
    shippingStatusOptions,
    orderSecondaryStatusOptions,
    isFiltered,
    myStocks,
    myStockOptions,
    onSubmit
  } = useOrderFilters({
    defaultFilters: getOrderStatusFilter('status', OngoingStatuses),
    defaultQueryParams: { status: getOrderStatusesQueryParams(OngoingStatuses) }
  })

  const [formattedOrders, setFormattedOrders] = useState<Array<FormattedOrder>>([])
  const [orderParams, setOrderParams] = useState<OrderParam<FetchOrdersQueryParams>>()
  //We can't use isSubmitted from useForm because we're not really submitting the form

  useEffect(() => {
    if (orderParams?.queryParams) {
      setQueryParams((prev) => ({ ...prev, order: orderParams?.queryParams }))
    }
  }, [orderParams, setQueryParams])

  const productOrdersUri = getOrdersUri(
    addDefaultQueryParams({
      'entries.product': productId,
      ...mergeFormQueryParams<FetchOrdersQueryParams>(queryParams),
      itemsPerPage: perPage
    })
  )

  const { hasNextPage, isFetching, fetchNextPage, totalItems, isFetchingNextPage } = useOrdersPaginatedQuery(
    ['productOrders', productOrdersUri],
    productOrdersUri,
    {
      enabled: active,
      onSuccess: (orders) => {
        setFormattedOrders(orders.map(formatOrderEntry))
      }
    }
  )

  const onClearAllFilters = () => {
    //Override defaultValues on clear
    reset({ status: [], endDate: '', startDate: '' })
    setSearchFilter([])
    setQueryParams({ location: { 'stock[]': myStocks.map((opt) => opt['@id']) }, order: orderParams })
  }

  const { checkMatch } = useCheckMatch()
  return (
    <div>
      <CardLayout>
        <Text size="1.6rem" fontWeight="bold">
          {t('page.product.detail.tabs.sales.filters')}
        </Text>
        <FilterGrid onSubmit={handleSubmit(onSubmit)}>
          <FieldCol>
            <MultipleSelect
              id="status"
              label={t('common.label.mainStatus')}
              options={orderStatusOptions}
              placeholder={t('common.select.defaultOptions.status')}
              control={control}
              tooltip={<TextTooltip id="productDetailOrdersStatus" text={t('tooltips.product.orderStatus')} />}
            />
            <Select
              id="secondaryStatus"
              label={t('common.label.secondaryStatus')}
              placeholder={t('common.select.defaultOptions.secondaryStatus')}
              options={orderSecondaryStatusOptions}
              control={control}
              tooltip={
                <TextTooltip
                  id="productDetailOrdersSecondaryStatus"
                  text={t('tooltips.product.orderSecondaryStatus')}
                />
              }
              enableUnselect={true}
            />
          </FieldCol>

          <FieldCol>
            <Select
              id="location"
              label={t('common.label.stock')}
              placeholder={t('common.select.defaultOptions.stock')}
              options={myStockOptions}
              control={control}
              tooltip={<TextTooltip id="productDetailOrdersLocation" text={t('tooltips.product.orderLocation')} />}
              enableUnselect={true}
            />
            <Select
              id="stockState"
              label={t('common.label.stockState')}
              placeholder={t('common.select.defaultOptions.stockState')}
              options={stockStateOptions}
              control={control}
              enableUnselect={true}
            />
          </FieldCol>

          <FieldCol>
            <MultipleSelect
              id="shippingMode"
              label={t('common.label.shippingType')}
              placeholder={t('common.select.defaultOptions.shippingType')}
              options={shippingTypeOptions}
              control={control}
            />
            <Select
              id="shippingStatus"
              label={t('common.label.shippingStatus')}
              placeholder={t('common.select.defaultOptions.shippingStatus')}
              options={shippingStatusOptions}
              control={control}
              enableUnselect={true}
            />
          </FieldCol>
          <FieldCol>
            <Select
              id="paymentMode"
              label={t('common.label.paymentMethod')}
              placeholder={t('common.select.defaultOptions.paymentMode')}
              options={paymentModeOptions}
              control={control}
              enableUnselect={true}
            />

            <Select
              id="paymentStatus"
              label={t('common.label.paymentStatus')}
              placeholder={t('common.select.defaultOptions.paymentStatus')}
              options={paymentStatusOptions}
              control={control}
              enableUnselect={true}
            />
          </FieldCol>

          <MultipleSelect
            id="clientProfile"
            label={t('common.label.clientProfile')}
            placeholder={t('common.select.defaultOptions.account')}
            options={clientProfileOptions}
            control={control}
          />

          <DateContainer>
            <DatePicker
              id="startDate"
              label={t('common.label.startDate')}
              register={register}
              setValue={setValue}
              trigger={trigger}
              defaultValue={getValues('startDate')}
            />
            <DatePicker
              id="endDate"
              label={t('common.label.endDate')}
              register={register}
              setValue={setValue}
              trigger={trigger}
              defaultValue={getValues('endDate')}
            />
          </DateContainer>

          <SearchButton shape="circle" icon={SearchIcon} buttonType="submit" isLoading={isFetching} />
        </FilterGrid>
        <FiltersList filters={searchFilters} onClear={onClearAllFilters} disableClick={true} />
      </CardLayout>

      <CardLayout>
        <Listing
          columns={columns}
          data={formattedOrders}
          totalCount={totalItems}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          title={
            isFiltered
              ? t('page.product.detail.tabs.sales.productQuantity', {
                  count: totalItems ?? 0
                })
              : t('page.product.detail.tabs.sales.currentProductQuantity', {
                  count: totalItems ?? 0
                })
          }
          emptyText={t('page.product.detail.tabs.sales.empty')}
          countText={t('page.product.detail.tabs.sales.currentCount', {
            total: totalItems ?? 0,
            current: formattedOrders?.length ?? 0
          })}
          onOrderChanged={setOrderParams}
          defaultSort={defaultOrder}
          bottomComponent={
            checkMatch([RoleAction.ROLE_ACTION_ORDERS_READ_ALL_STOCK]) && (
              <CountWithPerPageSelect
                onChange={(perPage) => setPerPage(perPage)}
                value={perPage}
                perPageOptions={[
                  { value: 30, label: '30' },
                  { value: 100, label: '100' }
                ]}
              />
            )
          }
        />
      </CardLayout>
    </div>
  )
}

export default ProductSalesTab
