import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { Role } from 'types/entities'
import { RoleLayout } from '../PermissionManage.style'
import ActionsByRole from './ActionsByRole'
import CollaboratorsByRole from './CollaboratorsByRole'
interface Props {
  role?: Role
}
export default function RoleManage({ role }: Props) {
  const { t } = useTranslation()

  return (
    <CardLayout>
      {role && (
        <>
          <Title2>{t('page.permissions.manage.role.title', { name: role.name })}</Title2>
          <Text color="secondary" fontWeight="light">
            {role.description}
          </Text>
          <RoleLayout>
            <ActionsByRole role={role} />
            <CollaboratorsByRole role={role} />
          </RoleLayout>
        </>
      )}
    </CardLayout>
  )
}
