import { FormattedNumberComponentProps } from 'components/InputFormattedNumber/InputFormattedNumber'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import TextNumber from 'components/TextNumber/TextNumber'
import _ from 'lodash'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from './Text.styles'

type Props = Omit<FormattedNumberComponentProps, 'onChange'> & {
  label: string
  tooltip?: ReactNode
}

const TextNumberFieldDisplay = ({ value, label, tooltip, ...restProps }: Props) => {
  const { t } = useTranslation()

  return (
    <InputLayout label={label} className="no-border" tooltip={tooltip}>
      {!_.isNil(value) ? (
        <TextNumber value={value} fontWeight="medium" {...restProps} />
      ) : (
        <Text color="secondary" fontWeight="light" fontStyle="italic">
          {t('common.label.notAvailable')}
        </Text>
      )}
    </InputLayout>
  )
}

export default TextNumberFieldDisplay
