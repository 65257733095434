import { SectionCentered } from 'app/ProductManagement/ProductManagement.style'
import Button from 'components/Button/Button'
import SortedTable, { Props as SortedTableProps } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { MoreIcon } from 'constants/icons'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export type Columns<T, U = {}> = SortedTableProps<T, U>['columns']
export type OnOrderChanged<T, U = {}> = SortedTableProps<T, U>['onOrderChanged']

interface Props<T, U = {}> extends Omit<SortedTableProps<T, U>, 'isLoading'> {
  emptyText?: string | ReactNode

  className?: string
  totalCount?: number
  title?: string | ReactNode
  countText?: string
  fetchNextPage?: Function
  hasNextPage?: boolean
  isFetching?: boolean
  isFetchingNextPage?: boolean
  topComponent?: ReactNode
  bottomComponent?: ReactNode
}

function Listing<T, U = {}>({
  className,
  columns,
  data,
  totalCount,
  emptyText,
  hasNextPage,
  fetchNextPage,
  countText,
  title,
  defaultSort,
  onOrderChanged,
  isFetching = false,
  isFetchingNextPage = false,
  topComponent,
  bottomComponent
}: Props<T, U>) {
  const { t } = useTranslation()

  const noResultText = emptyText ?? t('common.noResult')

  return (
    <div className={className}>
      {title && <Title2>{isFetching && !isFetchingNextPage ? t('common.label.loading') : title}</Title2>}
      {totalCount === 0 ? (
        typeof noResultText === 'string' ? (
          <Text color="danger">{noResultText}</Text>
        ) : (
          noResultText ?? ''
        )
      ) : (
        <>
          {topComponent}
          <SortedTable
            data={data}
            columns={columns}
            defaultSort={defaultSort}
            onOrderChanged={onOrderChanged}
            isLoading={isFetching && !isFetchingNextPage}
          />
          {!!fetchNextPage && hasNextPage && (
            <SectionCentered>
              {countText && (
                <Text color="secondary" fontWeight="light">
                  {countText}
                </Text>
              )}
              <Button
                variant="white"
                onClick={() => fetchNextPage()}
                isLoading={isFetchingNextPage}
                disabled={!hasNextPage}
                icon={MoreIcon}
              >
                {t('common.button.nextPage')}
              </Button>
            </SectionCentered>
          )}
          {bottomComponent}
        </>
      )}
    </div>
  )
}

export default Listing
