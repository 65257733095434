import { GetOrderByIdResponse } from 'api/orders'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { InputRow } from '../ShippingCard.style'

type Props = {
  order?: GetOrderByIdResponse
}

export default function CancelReadyStatusForm({ order }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Title2>{t('page.order.detail.notifyReadyStatusTitle')}</Title2>
      <InputRow>
        <InputLayout className="no-border" label={t('page.order.detail.storePlace')}>
          <TextFieldDisplay text={order?.storePlace} />
        </InputLayout>

        <div></div>
      </InputRow>
    </>
  )
}
