import { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import { Path, UseFormRegister } from 'react-hook-form'
import { StyledTextarea, TextareaLayout } from './Textarea.style'

export interface TextareaProps<T> extends LayoutProps {
  id: Path<T>
  rows?: number
  cols?: number
  disabled?: boolean
  placeholder?: string
  resize?: string
}

interface ReactHookFormProps<T> {
  register: UseFormRegister<T>
}

interface Props<T> extends TextareaProps<T>, ReactHookFormProps<T> {}

function Textarea<T>({
  className,
  label,
  id,
  register,
  layout = 'column',
  tooltip,
  rows = 5,
  cols = 30,
  placeholder,
  disabled,
  labelComponent,
  resize
}: Props<T>) {
  return (
    <TextareaLayout
      className={className}
      label={label}
      layout={layout}
      tooltip={tooltip}
      labelComponent={labelComponent}
    >
      <StyledTextarea
        rows={rows}
        cols={cols}
        disabled={disabled}
        placeholder={placeholder}
        resize={resize}
        {...register(id)}
      />
    </TextareaLayout>
  )
}

export default Textarea
