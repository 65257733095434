import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 5rem;
  width: 100%;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  background-color: ${(props) => props.theme.backgroundColors.secondary2};
  color: ${(props) => props.theme.textColors.mysteryBlue};
`
export const LeftDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 22.5rem;
  padding: 0 1.5rem;

  @media screen and (max-width: ${(props) => props.theme.screenWidth.tabletScreen}) {
    width: 100%;
  }
`

export const RightDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;

  @media screen and (max-width: ${(props) => props.theme.screenWidth.tabletScreen}) {
    display: none;
  }
`
export const StyledText = styled(Text)`
  margin-right: 2rem;
`

export const StyledMenuIcon = styled(Svg)`
  cursor: pointer;
`
