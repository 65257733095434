import styled from 'styled-components'
import { FontStyle, FontWeight, TextColors } from 'theme/theme'

export interface TextProps {
  fontWeight?: keyof FontWeight
  color?: keyof TextColors
  fontStyle?: keyof FontStyle
  size?: string
  whiteSpace?: 'pre-wrap' | 'unset'
  textAlign?: 'center' | 'left' | 'right'
}

interface TextWithIconProps extends TextProps {
  iconPosition: 'left' | 'right'
}
export const Text = styled.div<TextProps>`
  font-weight: ${(props) => props.theme.fontWeight[props.fontWeight || 'regular']};
  color: ${(props) => props.theme.textColors[props.color || 'black']};
  font-size: ${(props) => props.size || 'inherit'};
  font-style: ${(props) => props.fontStyle || 'normal'};
  white-space: ${(props) => props.whiteSpace || 'normal'};
  text-align: ${(props) => props.textAlign || 'inherit'};
`

export const UpperCaseText = styled(Text)`
  text-transform: uppercase;
`

export const TextWithIcon = styled(Text)<TextWithIconProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    order: ${(props) => (props.iconPosition === 'left' ? '0' : '2')};
    width: 1.6rem;
  }
`

export const TextEllipsis = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
