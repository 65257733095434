import styled from 'styled-components'

export const CreateFormLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem 5rem;
  padding-bottom: 2rem;
  align-items: stretch;
  @media screen and (max-width: 1435px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }
`
export const FieldsCol = styled.div`
  gap: 2rem;
  display: grid;
`
