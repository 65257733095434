import { cancelStoreEvent, closeStoreEvent, eventByIdQueryKey, PostStoreEventCustomerResponse } from 'api/storeEvents'
import { ArchiveIcon, DeleteIcon, MoreIcon, SyncIcon } from 'constants/icons'
import { isAfter } from 'date-fns'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { StoreEvent } from 'types/entities'
import { UnmodifiableStoreEventStatus } from 'types/enums'
import { RoleAction, StoreEventStatus } from 'types/playInApiInterfaces'
import AddCustomerModal from '../modals/AddCustomerModal/AddCustomerModal'
import DuplicateEventModal from '../modals/DuplicateEventModal/DuplicateEventModal'
import { ActionButton, MiscActionsWrapper } from '../StoreEventDetails.style'
import { getEventName } from '../utils'

interface Props {
  event: StoreEvent
}

const isAfterToday = (date?: string) => {
  if (!date) return false
  return isAfter(new Date(date), new Date())
}
export default function MiscActions({ event }: Props) {
  const { t } = useTranslation()

  const [customerModalOpen, toggleCustomerModal] = useState(false)
  const [treatModalOpen, toggleTreatModal] = useState(false)
  const [cancelModalOpen, toggleCancelModal] = useState(false)
  const [duplicateModalOpen, toggleDuplicateModal] = useState(false)

  const queryClient = useQueryClient()
  const updateStoreEvent = (updatedEvent: PostStoreEventCustomerResponse) => {
    const prev = queryClient.getQueryData(eventByIdQueryKey(event.id!)) as StoreEvent
    queryClient.setQueryData(eventByIdQueryKey(event.id!), { ...prev, ...updatedEvent })
  }

  const { mutate: closeStoreEventMutation, isLoading: isCloseLoading } = useMutation(
    (id: number) => closeStoreEvent(id),
    {
      onSuccess: (updatedStoreEvent) => {
        updateStoreEvent(updatedStoreEvent as PostStoreEventCustomerResponse)
        toggleTreatModal(false)
      }
    }
  )

  const { mutate: cancelStoreEventMutation, isLoading: isCancelLoading } = useMutation(
    (id: number) => cancelStoreEvent(id),
    {
      onSuccess: (updatedEvent) => {
        updateStoreEvent(updatedEvent)
        toggleCancelModal(false)
      }
    }
  )

  const canBeClosed = !(
    UnmodifiableStoreEventStatus.includes(event.status!) ||
    isAfterToday(event.startsAt) ||
    (event.deliversVouchers && event.totalDistributedVouchers === 0)
  )

  const canBeCanceled = !(
    UnmodifiableStoreEventStatus.includes(event.status!) || !!event.customers?.filter((e) => e.validated).length
  )

  return (
    <>
      <MiscActionsWrapper>
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER]}>
          <ActionButton
            icon={MoreIcon}
            variant="white"
            onClick={() => toggleCustomerModal(true)}
            disabled={event.status !== StoreEventStatus.Created || !event.openRegistration}
          >
            {t('page.events.details.buttons.addCustomer')}
          </ActionButton>
        </ShouldDisable>

        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_ACTION]}>
          <ActionButton
            icon={ArchiveIcon}
            variant="warning"
            onClick={() => toggleTreatModal(true)}
            disabled={!canBeClosed}
          >
            {t('page.events.details.buttons.treatEvent')}
          </ActionButton>
        </ShouldDisable>

        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_ACTION]}>
          <ActionButton
            icon={DeleteIcon}
            variant="danger"
            onClick={() => toggleCancelModal(true)}
            disabled={!canBeCanceled}
          >
            {t('page.events.details.buttons.cancelEvent')}
          </ActionButton>
        </ShouldDisable>

        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_ACTION]}>
          <ActionButton icon={SyncIcon} onClick={() => toggleDuplicateModal(true)}>
            {t('page.events.details.buttons.duplicateEvent')}
          </ActionButton>
        </ShouldDisable>
      </MiscActionsWrapper>

      <AddCustomerModal open={customerModalOpen} onClose={() => toggleCustomerModal(false)} event={event} />

      <ConfirmModal
        open={treatModalOpen}
        onClose={() => toggleTreatModal(false)}
        title={t('page.events.details.modals.treatEvent.title', { name: getEventName(event) })}
        callback={() => closeStoreEventMutation(event.id!)}
        confirmBtnProps={{
          variant: 'warning',
          icon: ArchiveIcon,
          isLoading: isCloseLoading
        }}
        confirmLabel={t('page.events.details.buttons.treatEvent')}
        closeOnCallback={false}
      />

      <ConfirmModal
        open={cancelModalOpen}
        onClose={() => toggleCancelModal(false)}
        title={t('page.events.details.modals.cancelEvent.title', { name: getEventName(event) })}
        callback={() => cancelStoreEventMutation(event.id!)}
        confirmBtnProps={{
          icon: DeleteIcon,
          isLoading: isCancelLoading
        }}
        confirmLabel={t('page.events.details.buttons.cancelEvent')}
        closeOnCallback={false}
      />
      <DuplicateEventModal open={duplicateModalOpen} onClose={() => toggleDuplicateModal(false)} event={event} />
    </>
  )
}
