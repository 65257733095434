import { getCollaboratorsUri, usePaginatedCollaborators } from 'api/collaborators'
import { Option } from 'components/Select/Select'
import { getFilterLabel } from 'hooks/useSelectOptions'
import { useState } from 'react'
import { RoleAction } from 'types/playInApiInterfaces'

export const useCollaboratorsOptions = () => {
  const [collaboratorOptions, setCollaboratorOptions] = useState<Option[]>([])

  const collaboratorQuery = getCollaboratorsUri({
    'collaboratorRoles.collaboratorActions.name': RoleAction.ROLE_ACTION_CARD_EDIT_PRICE
  })

  usePaginatedCollaborators(collaboratorQuery, collaboratorQuery, {
    onSuccess: (collaborators) =>
      setCollaboratorOptions(
        collaborators.map((collaborator) => ({
          value: collaborator.id!,
          label: `${collaborator.lastname} ${collaborator.firstname}`
        }))
      ),
  })


  return {
    collaboratorOptions,
    getCollaboratorFilter: (id, value) => ({ id, label: getFilterLabel(collaboratorOptions, value) })
  }
}
