import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Order, OrderItem } from 'types/entities'
import { Api, OrderStatus } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

/* -------------------------------------------------------------------------- */
/*                                    FETCH                                   */
/* -------------------------------------------------------------------------- */
// Make query params mor restrictive, api docs says string, we overide to OrderStatus enum
export type FetchOrdersQueryParams = Api.GetOrders.RequestQuery & {
  status?: OrderStatus
  'status[]'?: OrderStatus[]
}
export const getOrdersUri = (params: FetchOrdersQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.orders.root, queryString].join('?')
}

type FetchOrdersResponse = Api.GetOrders.ResponseBody['hydra:member'][0]

export const orderByIdQueryKey = (id: string | number) => ['orderById', `${id}`]

export type GetOrderByIdResponse = Api.GetOrdersId.ResponseBody
const getOrderById = async (id: string) => {
  const response = await playInApi.get<GetOrderByIdResponse>(`${API_ROUTES.orders.root}/${id}`)
  return response.data
}

type QuickSearchOrderQueryParams = Api.GetOrdersQuickSearchId.RequestQuery
export const getOrderQuickSearchUri = (search: string, params: QuickSearchOrderQueryParams = {}): string => {
  const queryString = stringify(params)
  return [`${API_ROUTES.orders.quickSearch}/${search}`, queryString].join('?')
}

export const useOrdersPaginatedQuery = (
  queryKey: QueryKey,
  query: string,
  options?: PaginatedHookOptions<FetchOrdersResponse>
) => usePaginatedQuery<FetchOrdersResponse>(queryKey, query, options)

export const useGetOrderById = (id: string, { onSuccess }: { onSuccess: (order: GetOrderByIdResponse) => void }) => {
  const navigate = useNavigate()
  return useQuery<GetOrderByIdResponse, AxiosResponse>(orderByIdQueryKey(id), () => getOrderById(id), {
    onError: (error) => {
      // replace: true, we prevent going back to the page that respond 40X
      navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
    },
    onSuccess
  })
}

type FetchExcludedOrders = Api.GetOrdersExcludedFromPrintsId.ResponseBody
export const fetchExcludedFromPrintOrders = async (storeId: string | number) => {
  const res = await playInApi.get<FetchExcludedOrders>(`${API_ROUTES.orders.excludedFromPrint}/${storeId}`)
  return res.data['hydra:member']
}

// type CountPurchaseOrdersBody = Api.ReturnTotalOrdersPrint.RequestBody
export const countPurchaseOrders = async (storeId: string | number, params: any) => {
  const queryString = stringify(params)
  const res = await playInApi.get<any>([`${API_ROUTES.orders.totalOrdersPrint}/${storeId}`, queryString].join('?'))

  return res.data
}

/* -------------------------------------------------------------------------- */
/*                                    POST                                    */
/* -------------------------------------------------------------------------- */

type CreateOrderBody = Api.PostOrders.RequestBody
type CreateOrderResponse = Api.PostOrders.ResponseBody
export const createOrder = async (body: CreateOrderBody) => {
  const res = await playInApi.post<CreateOrderBody, AxiosResponse<CreateOrderResponse>>(API_ROUTES.orders.root, body)
  return res.data
}

export type CancelOrderBody = Api.PostOrdersCancelWithRefundId.RequestBody
export const cancelOrder = async (id: string | number, body: CancelOrderBody) => {
  const res = await playInApi.post<CancelOrderBody, AxiosResponse<Order>>(`${API_ROUTES.orders.cancel}/${id}`, body)
  return res.data
}

export const cancelOrderSend = async (id: string | number) => {
  const res = await playInApi.post<{}, AxiosResponse<Order>>(`${API_ROUTES.orders.cancelSend}/${id}`, {})
  return res.data
}

export const cancelOrderReady = async (id: string | number) => {
  const res = await playInApi.post<{}, AxiosResponse<Order>>(`${API_ROUTES.orders.cancelReady}/${id}`, {})
  return res.data
}

export const cancelOrderTreated = async (id: string | number) => {
  const res = await playInApi.post<{}, AxiosResponse<Order>>(`${API_ROUTES.orders.cancelTreat}/${id}`, {})
  return res.data
}

export const reorder = async (id: string | number) => {
  const res = await playInApi.post<{}, AxiosResponse<OrderItem>>(`${API_ROUTES.orders.reorder}/${id}`, {})
  return res.data
}

type RemoveOrderEntriesBody = Api.PostOrdersDeleteEntriesId.RequestBody
export const removeOrderEntries = async (orderId: string | number, body: RemoveOrderEntriesBody) => {
  const res = await playInApi.post<RemoveOrderEntriesBody, AxiosResponse<OrderItem>>(
    `${API_ROUTES.orders.deleteEntries}/${orderId}`,
    body
  )
  return res.data
}

export type DeleteOrderVoucherBody = Api.PostOrdersDeleteVouchersId.RequestBody
export const deleteOrderVoucher = async (orderId: string | number, body: DeleteOrderVoucherBody) => {
  const res = await playInApi.post<DeleteOrderVoucherBody, AxiosResponse<OrderItem>>(
    `${API_ROUTES.orders.deleteVouchers}/${orderId}`,
    body
  )
  return res.data
}

export const deleteOrderCoupon = async (orderId: string | number) => {
  const res = await playInApi.post<{}, AxiosResponse<OrderItem>>(`${API_ROUTES.orders.deleteCoupon}/${orderId}`, {})
  return res.data
}

export type SplitOrderBody = Api.PostOrdersSplitId.RequestBody
export const splitOrder = async (body: SplitOrderBody, id: number) => {
  let res = await playInApi.post<SplitOrderBody, AxiosResponse<OrderItem>>(`${API_ROUTES.orders.split}/${id}`, body)
  return res.data
}

export type PostCreditNoteBody = Api.PostOrdersCreditNoteCreate.RequestBody
export const postCreditNote = async (body: PostCreditNoteBody) => {
  let res = await playInApi.post<PostCreditNoteBody, AxiosResponse<OrderItem>>(API_ROUTES.orders.creditNoteCreate, body)
  return res.data
}

/* -------------------------------------------------------------------------- */
/*                                    PATCH                                   */
/* -------------------------------------------------------------------------- */
export type EditOrderBody = Api.PatchOrdersId.RequestBody
type EditOrderResponse = Api.PatchOrdersId.ResponseBody
export const editOrder = async (body: EditOrderBody, id: number): Promise<EditOrderResponse> => {
  const res = await playInApi.patch<EditOrderBody, AxiosResponse<EditOrderResponse>>(
    `${API_ROUTES.orders.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

type SendOrderBody = Api.PatchOrdersSendId.RequestBody
type SendOrderResponse = Api.PatchOrdersSendId.ResponseBody
export const sendOrder = async (body: SendOrderBody, id: number): Promise<SendOrderResponse> => {
  const res = await playInApi.patch<SendOrderBody, AxiosResponse<SendOrderResponse>>(
    `${API_ROUTES.orders.root}/send/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

type NotifyReadyOrderBody = Api.PatchOrdersNotifyReadyId.RequestBody
type NotifyReadyOrderResponse = Api.PatchOrdersNotifyReadyId.ResponseBody
export const notifyOrderReady = async (body: NotifyReadyOrderBody, id: number): Promise<NotifyReadyOrderResponse> => {
  const res = await playInApi.patch<NotifyReadyOrderBody, AxiosResponse<NotifyReadyOrderResponse>>(
    `${API_ROUTES.orders.root}/notify-ready/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export type AddOrderPaymentQueryParams = Api.PostOrdersAddPaymentIdPaymentModeValue.RequestParams
export const addOrderPayment = async ({ id, paymentMode, value }: AddOrderPaymentQueryParams) => {
  const res = await playInApi.post<{}, AxiosResponse<Order>>(
    `${API_ROUTES.orders.root}/add-payment/${id}/${paymentMode}/${value}`,
    {}
  )
  return res.data
}

export type RemoveOrderPaymentQueryParams = Api.PostOrdersRemovePaymentIdPaymentModeValue.RequestParams
export const removeOrderPayment = async ({ id, paymentMode, value }: RemoveOrderPaymentQueryParams) => {
  const res = await playInApi.post<{}, AxiosResponse<Order>>(
    `${API_ROUTES.orders.root}/remove-payment/${id}/${paymentMode}/${value}`,
    {}
  )
  return res.data
}

export const patchCardSecondaryStatus = async (status: string) =>
  await playInApi.patch(
    `${API_ROUTES.orders.cardSecondaryStatus}/${status}`,
    {},
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

export const assignBillNumber = async (orderId: string | number) => {
  const res = await playInApi.patch<{}, AxiosResponse<OrderItem>>(
    `${API_ROUTES.orders.assignBillNumber}/${orderId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}
