import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import Link from 'features/Permissions/Link/Link'
import styled from 'styled-components'

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 5rem;
  padding-bottom: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }
`
export const TwoColsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const CustomerLinkWrapper = styled.div`
  @media screen and (${(props) => props.theme.screenWidth.minLaptopScreen}) {
    padding-top: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`

export const CustomerLink = styled(Link)`
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  color: ${(props) => props.theme.textColors.darkPurple};
` as typeof Link

export const PasswordCard = styled(CardLayout)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.5rem;
  align-items: flex-start;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const PasswordCol = styled.div`
  display: grid;
  gap: 2rem;
`
export const PasswordRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
export const CardsCol = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }
`

export const StoreFormLayout = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
  gap: 2rem;
`

export const ListActions = styled.div`
  display: flex;
  padding-left: 1rem;
  gap: 2rem;
  align-items: center;
  padding-bottom: 2rem;
`
export const AddRoleFormWrapper = styled.form`
  margin-bottom: 2rem;
  display: flex;
  max-width: 46rem;
  width: 100%;
  gap: 2rem;
  align-items: flex-end;
`
export const RoleActionsManageWrapper = styled.div`
  margin-top: 3.5rem;
  padding-top: 3.5rem;
  border-top: 1px solid ${(props) => props.theme.borderColors.secondary};
`
export const OriginText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 1rem;
`
