import {
  activateCollaborator,
  collaboratorByIdQueryKey,
  deactivateCollaborator,
  resetCollaboratorPassword
} from 'api/collaborators'
import Button from 'components/Button/Button'
import { Text } from 'components/Text/Text.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { DeleteIcon, LockIcon } from 'constants/icons'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Collaborator } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { formatCalendarDate } from 'utils/dates'
import { PasswordCard, PasswordCol, PasswordRow } from '../CollaboratorDetails.style'
import { isInMyStores } from '../utils'

interface Props {
  collaborator: Collaborator
}
export default function PasswordManage({ collaborator }: Props) {
  const { t } = useTranslation()
  const { me } = useAuth()

  const queryClient = useQueryClient()

  const { mutate: deactivateCollaboratorMutation, isLoading: deactivateCollaboratorLoading } = useMutation(
    (id: number) => deactivateCollaborator(id),
    {
      onSuccess: (updatedCollaborator) => {
        queryClient.setQueryData(collaboratorByIdQueryKey(collaborator.id), updatedCollaborator)
      }
    }
  )

  const { mutate: activateCollaboratorMutation, isLoading: activateCollaboratorLoading } = useMutation(
    (id: number) => activateCollaborator(id),
    {
      onSuccess: (updatedCollaborator) => {
        queryClient.setQueryData(collaboratorByIdQueryKey(collaborator.id), updatedCollaborator)
      }
    }
  )

  const { mutate: resetPasswordMutation, isLoading: resetPasswordLoading } = useMutation((id: string) =>
    resetCollaboratorPassword(id)
  )

  //Avoid considering a previous disabled collaborator as inactive
  //At the same time, avoid considering a freshly created collaborator as disabled
  const isCollaboratorDisabled = collaborator.disabledAt && !collaborator.active

  return (
    <PasswordCard>
      <PasswordCol>
        <PasswordRow>
          <Text fontWeight="light" color="secondary">
            {t('page.collaborator.details.newPassword.label')}
          </Text>
          <Tooltip id="passwordTooltip">{t('page.collaborator.details.newPassword.tooltip')}</Tooltip>
        </PasswordRow>
        <ShouldDisable
          byPassRoleValidation={collaborator['@id'] === me?.['@id']}
          permissions={[RoleAction.ROLE_ACTION_COLLABORATOR_EDIT]}
          deniedExtraCondition={!isInMyStores(collaborator, me?.stores) || !collaborator.active}
        >
          <Button
            icon={LockIcon}
            variant="warning"
            size="long"
            onClick={() => resetPasswordMutation(`${collaborator.id}`)}
            isLoading={resetPasswordLoading}
          >
            {t('page.collaborator.details.newPassword.button')}
          </Button>
        </ShouldDisable>
      </PasswordCol>
      <PasswordCol>
        <div>
          {isCollaboratorDisabled ? (
            <Text color="danger">
              <Trans
                i18nKey="page.collaborator.details.accountDeleted"
                values={{
                  date: formatCalendarDate(collaborator.disabledAt) ?? '-',
                  name: collaborator.disabledBy
                    ? `${collaborator.disabledBy?.firstname} ${collaborator.disabledBy?.lastname}`
                    : '-'
                }}
                components={{ b: <strong /> }}
              />
            </Text>
          ) : (
            <Text>
              <Trans
                i18nKey="page.collaborator.details.accountCreated"
                values={{
                  date: formatCalendarDate(collaborator.createdAt) ?? '-',
                  name: collaborator.createdBy
                    ? `${collaborator.createdBy?.firstname} ${collaborator.createdBy?.lastname}`
                    : '-'
                }}
                components={{ b: <strong /> }}
              />
            </Text>
          )}
        </div>

        <ShouldDisable
          permissions={[RoleAction.ROLE_ACTION_COLLABORATOR_CREATE]}
          deniedExtraCondition={!isInMyStores(collaborator, me?.stores)}
        >
          {collaborator.active ? (
            <Button
              size="long"
              variant="danger"
              icon={DeleteIcon}
              onClick={() => deactivateCollaboratorMutation(collaborator.id!)}
              isLoading={deactivateCollaboratorLoading}
            >
              {t('page.collaborator.details.deleteAccount')}
            </Button>
          ) : (
            <Button
              size="long"
              isLoading={activateCollaboratorLoading}
              onClick={() => activateCollaboratorMutation(collaborator.id!)}
            >
              {t('page.collaborator.details.enableAccount')}
            </Button>
          )}
        </ShouldDisable>
      </PasswordCol>
    </PasswordCard>
  )
}
