import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import { Title2 } from 'components/Title/Title.styles'
import { SearchIcon } from 'constants/icons'
import { useMyStoresOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { FormSearch, Line } from './CollaboratorForms.styles'

export type CollaboratorForm = {
  email: string
  firstname: string
  lastname: string
  stores: string
}

const schema = yup.object().shape({
  firstname: yup.string().trim(),
  lastname: yup.string().trim(),
  email: yup.string().trim(),
  stores: yup.string().trim()
})

const CollaboratorSearchForm = ({ onSearch, isSubmitting }) => {
  const { t } = useTranslation()
  const [atomLocation] = useAtom(defaultStoreAtom)
  const { handleSubmit, register, control } = useForm<CollaboratorForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      stores: atomLocation?.['@id']
    }
  })

  const { myStoresOptions } = useMyStoresOptions()

  return (
    <CardLayout>
      <Title2>{t('page.collaborator.management.search')}</Title2>
      <FormSearch onSubmit={handleSubmit(onSearch)}>
        <Input label={t('common.label.lastName')} id="lastname" register={register} />
        <Input label={t('common.label.firstName')} id="firstname" register={register} />
        <Line>
          <Input type="text" label={t('common.label.email')} id="email" register={register} />
        </Line>
        <Select
          id="stores"
          control={control}
          label={t('common.label.location')}
          options={[
            {
              label: t('common.select.defaultOptions.localisations'),
              value: ''
            },
            ...myStoresOptions
          ]}
        />
        <Button buttonType="submit" isLoading={isSubmitting} icon={SearchIcon} shape="circle" />
      </FormSearch>
    </CardLayout>
  )
}

export default CollaboratorSearchForm
