import { Modal } from '@mui/material'
import Button from 'components/Button/Button'
import Svg from 'components/Svg/Svg'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const Container = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  margin: 10rem auto;
  padding: 5rem;
  border: 1px solid ${(props) => props.theme.borderColors.secondary2};
  outline: none;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    max-width: 90%;
  }
`

export const CloseIcon = styled(Svg)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
`

export const StyledModal = styled(Modal)`
  overflow-y: auto;
`
export const DeleteModalButton = styled(Button)`
  min-width: 20rem;
`
export const ModalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem 5rem;
  padding-top: 2rem;
  flex-wrap: wrap;
`
export const DeleteModalTitle = styled(Title2)`
  text-align: center;
`

export const ConfirmModalWrapper = styled.div`
  max-width: 70rem;
`
