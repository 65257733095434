import { yupResolver } from '@hookform/resolvers/yup'
import { FamilyStatus, usePaginatedRanges } from 'api/ranges'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import InputRadio from 'components/InputRadio/InputRadio'
import { ColumnContainer } from 'components/Layouts/InputLayout/InputLayout.styles'
import Select, { Option } from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { SearchIcon } from 'constants/icons'
import { formatISO } from 'date-fns'
import LinkButton from 'features/Permissions/LinkButton'
import { RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Ranges } from 'types/entities'
import * as yup from 'yup'
import {
  BannerCardLayout,
  CarouselContainer,
  HeaderCard,
  HeaderContainer,
  HiddenOverflowSection,
  ImageCardContainer,
  SearchFormLayout,
  TitleContainer
} from './BannerManagement.styles'
import { getFamilyUrlByName } from './bannerUtils'
import BannerSelector from './components/BannerSelector'
import ImageCard from './ImageCard'

export type Banner = {
  id: string
  src: string
  startDate?: string
  endDate?: string
}

const schema = yup.object({
  page: yup.string().required(),
  lang: yup.string()
})

interface SearchPageInputs extends yup.InferType<typeof schema> {}

const BannerManagement = () => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<SearchPageInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      lang: 'fr'
    }
  })

  const {
    data: families,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = usePaginatedRanges(['family', { 'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage] }], { 'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage] })

  const pageOptions: Option[] =
    families.map((family) => ({
      label: getFamilyUrlByName(family.name),
      value: family['@id']
    })) ?? []

  const [banners, setBanners] = useState<Banner[]>([])
  const [selectedFamily, setSelectedFamily] = useState<Ranges>()

  const onDragEnd = (result: DropResult) => {
    // If item is dropped outside of dropZone, we do nothing
    if (!!result.destination) {
      const newList = [...banners]
      const item = newList.splice(result.source.index, 1)[0]
      newList.splice(result.destination.index, 0, item)
      setBanners(newList)
    }
  }

  const handleDate = (index: number, data: { endDate?: string; startDate?: string }) => {
    setBanners((prev) =>
      prev.map((banner, i) => {
        if (index !== i) return banner
        return { ...banner, ...data }
      })
    )
  }

  const onSubmit = (formData: SearchPageInputs) => {
    setSelectedFamily(families.find((e) => e['@id'] === formData.page))
    //Placeholder datas
    if (formData.page === '/api/families/1') {
      setBanners([
        {
          id: 'banner1',
          src: 'banner/banner.png',
          startDate: formatISO(new Date('2022-11-28 14:00')),
          endDate: formatISO(new Date('2022-12-28 19:00'))
        }
      ])
    }
  }

  return (
    <HiddenOverflowSection>
      <MainTitle title={t('page.banner.management.title')} />
      <HeaderContainer>
        <HeaderCard>
          <TitleContainer>
            <Title2>{t('page.banner.management.search.title')}</Title2>
            <Tooltip id="bannerSearchTooltip">{t('page.banner.management.search.tooltip')}</Tooltip>
          </TitleContainer>

          <SearchFormLayout onSubmit={handleSubmit(onSubmit)}>
            <Select
              control={control}
              id="page"
              options={pageOptions}
              label={t('common.label.page')}
              className={classNames({
                'is-invalid': errors.page
              })}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              placeholder={t('page.banner.management.search.pagesPlaceholder')}
            />
            <InputRadio
              id="lang"
              control={control}
              label={t('common.label.lang')}
              labelAccept={t('common.languages.french')}
              acceptValue="fr"
              labelDecline={t('common.languages.english')}
              declineValue="en"
            />
            <Button icon={SearchIcon} shape="circle" buttonType="submit" />
          </SearchFormLayout>
          <BasicFieldValidationError error={errors.page} message={t('page.banner.management.search.errors.page')} />
        </HeaderCard>

        <HeaderCard>
          <Title2>{t('page.banner.management.create.title')}</Title2>
          <LinkButton route={RouteName.BannerCreate}>{t('page.banner.management.create.button')}</LinkButton>
        </HeaderCard>
      </HeaderContainer>

      {selectedFamily && (
        <>
          <BannerCardLayout>
            <TitleContainer>
              <Title2>
                {t('page.banner.management.carousel.title', {
                  url: getFamilyUrlByName(selectedFamily.name),
                  count: banners.length ?? 0
                })}
              </Title2>
              <Tooltip id="bannerCarouselTooltip">{t('page.banner.management.carousel.tooltip')}</Tooltip>
            </TitleContainer>
            <CarouselContainer>
              <ColumnContainer>
                <Text fontWeight="light">{t('page.banner.management.carousel.description')}</Text>
                <Text fontWeight="light">{t('page.banner.management.carousel.disclaimer')}</Text>
              </ColumnContainer>
              <ColumnContainer>
                <TitleContainer>
                  <Text fontWeight="light">{t('page.banner.management.publish.description')}</Text>
                  <Tooltip id="bannerPublishTooltip">{t('page.banner.management.publish.tooltip')}</Tooltip>
                </TitleContainer>
                <Button>{t('page.banner.management.publish.action')}</Button>
              </ColumnContainer>
            </CarouselContainer>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="banners" direction="horizontal">
                {(provided) => (
                  <ImageCardContainer ref={provided.innerRef} {...provided.droppableProps}>
                    {banners.map((banner, index) => (
                      <ImageCard
                        selectedFamily={selectedFamily}
                        key={index}
                        banner={banner}
                        index={index}
                        handleDelete={(index) => setBanners((prev) => prev.filter((e, i) => i !== index))}
                        handleDate={handleDate}
                      />
                    ))}
                    {provided.placeholder}
                  </ImageCardContainer>
                )}
              </Droppable>
            </DragDropContext>
          </BannerCardLayout>
          <BannerSelector handleAdd={(banner) => setBanners((prev) => [...prev, banner])} />
        </>
      )}
    </HiddenOverflowSection>
  )
}

export default BannerManagement
