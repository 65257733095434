import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Link from 'features/Permissions/Link/Link'
import { RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { getIdFromIri } from 'utils/queryParams'
import { LinkedOrderSubTitleText } from './OrderInfoCard.style'

type LinkedOrderSubTitleProps = {
  splitOrders?: string[]
}

function LinkedOrderSubTitle({ splitOrders }: LinkedOrderSubTitleProps) {
  const { t } = useTranslation()
  return (
    <RowContainer>
      {`${t('page.order.detail.linkedOrders', { count: splitOrders?.length })}`}
      <LinkedOrderSubTitleText fontWeight="bold" color="darkPurple">
        {splitOrders?.map((linkedOrder, index) => (
          <Link route={RouteName.OrderDetails} value={getIdFromIri(linkedOrder)} key={`linkedOrder-${index}`}>
            {t('page.order.detail.linkedOrder', { index: index + 1 })}
          </Link>
        ))}
      </LinkedOrderSubTitleText>
    </RowContainer>
  )
}

export default LinkedOrderSubTitle
