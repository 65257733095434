import { getFidelityPointUri, usePaginatedFidelityPoints } from 'api/fidelityPoints'
import Listing, { Columns } from 'features/Listing/Listing'
import { useEventEmitter } from 'hooks/useEventEmitter'
import { useTranslation } from 'react-i18next'
import { Customer, FidelityPoint } from 'types/entities'
import { EventType } from 'types/events'

interface Props {
  customer: Customer
  columns: Columns<FidelityPoint>
}
export default function UsedPoints({ customer, columns }: Props) {
  const { t } = useTranslation()
  const { useListener } = useEventEmitter()
  let query = getFidelityPointUri({
    customer: customer['@id'],
    active: false,
    waiting: false
  })
  const { data, totalItems, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching, refetch } =
    usePaginatedFidelityPoints(query, query, {})

  useListener(EventType.TransformFidelityPoint, () => {
    refetch()
  })

  return (
    <Listing
      emptyText={t('page.customer.details.fidelity.used.empty')}
      countText={t('page.customer.details.fidelity.used.countText', {
        current: data.length ?? 0,
        total: totalItems ?? 0
      })}
      data={data}
      columns={columns}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      totalCount={totalItems}
    />
  )
}
