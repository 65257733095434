import Button from 'components/Button/Button'
import styled from 'styled-components'

export const AddStockFormLayout = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
`

export const SubmitButton = styled(Button)`
  width: 100%;
`
