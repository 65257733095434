import { useDownloadFile } from 'api/basePlayInApi'
import { countPurchaseOrders } from 'api/orders'
import { PreviewCounterText } from 'app/OrderPrint/utils.style'
import Button from 'components/Button/Button'
import Loader from 'components/Loader/Loader'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon } from 'constants/icons'
import { useEmit } from 'hooks/useEventEmitter'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Store } from 'types/entities'
import { EventType } from 'types/events'
import { stringify } from 'utils/queryParams'
import { PreviewButtonContainer } from '../../../utils.style'
import { ExportButtonsCardLayout } from '../otherProductsTab.style'

interface Props {
  store: Store
  active: boolean
}

const rivoliClickCollectPayload = {
  name: 'Click & Collect Paris Rivoli',
  preorder: false,
  withProducts: true,
  withCards: false,
  'clickAndCollect[]': [3]
}

export default function PrintPreorder({ store, active }: Props) {
  const [loadingButton, setLoadingButton] = useState<string>()
  const { downloadFile, isLoading } = useDownloadFile()
  const { t } = useTranslation()
  const emit = useEmit()

  const mainClickCollectPayload = useMemo(() => {
    if (store.warehouse) {
      return {
        name: 'Click & Collect Paris BNF',
        preorder: false,
        withProducts: true,
        'clickAndCollect[]': [1]
      }
    }
    return {
      name: 'Click & Collect',
      preorder: false,
      withCards: false,
      send: false,
      'clickAndCollect[]': [store.id]
    }
  }, [store.id, store.warehouse])

  const { data: mainClickCollectOrders, isFetching: mainClickCollectOrdersLoading } = useQuery(
    ['countOrderPrint', store.id, mainClickCollectPayload],
    () => countPurchaseOrders(store.id, mainClickCollectPayload),
    {
      enabled: active
    }
  )

  const { data: rivoliClickCollectOrders, isFetching: rivoliClickCollectOrdersLoading } = useQuery(
    ['countOrderPrint', store.id, rivoliClickCollectPayload],
    () => countPurchaseOrders(store.id, rivoliClickCollectPayload),
    {
      enabled: active && store?.warehouse
    }
  )

  return (
    <ExportButtonsCardLayout>
      <div>
        <Title2Tooltip
          title={
            store?.warehouse
              ? t('page.order.print.otherProduct.clickAndCollectWarehouse')
              : t('page.order.print.otherProduct.clickAndCollect')
          }
          text={
            store?.warehouse
              ? t('page.order.print.otherProduct.tooltips.clickAndCollectWarehouse')
              : t('page.order.print.otherProduct.tooltips.clickAndCollect', { store: store.shortName })
          }
        />
        <PreviewButtonContainer>
          <Button
            onClick={async () => {
              setLoadingButton('main')
              await downloadFile(
                `${API_ROUTES.downloads.multipleOrders}/${store.id}?${stringify(mainClickCollectPayload)}`
              )
              emit(EventType.PrintOrder, null)
            }}
            shape="default"
            icon={PrintIcon}
            size="long"
            isLoading={isLoading && loadingButton === 'main'}
          >
            {store.warehouse
              ? t('page.order.print.actions.printClickAndCollectBNF')
              : t('page.order.print.actions.printClickAndCollect')}
          </Button>
          {!active || mainClickCollectOrdersLoading ? (
            <Loader />
          ) : (
            <PreviewCounterText>
              {t('page.order.print.details.pendingOrdersCount', { count: mainClickCollectOrders.totalItems })}
            </PreviewCounterText>
          )}
        </PreviewButtonContainer>
      </div>
      {store.warehouse && (
        <PreviewButtonContainer>
          <Button
            onClick={async () => {
              setLoadingButton('secondary')
              await downloadFile(
                `${API_ROUTES.downloads.multipleOrders}/${store.id}?${stringify(rivoliClickCollectPayload)}`
              )
              emit(EventType.PrintOrder, null)
            }}
            shape="default"
            icon={PrintIcon}
            size="long"
            isLoading={isLoading && loadingButton === 'secondary'}
          >
            {t('page.order.print.actions.printClickAndCollectRivoli')}
          </Button>
          {!active || rivoliClickCollectOrdersLoading ? (
            <Loader />
          ) : (
            <PreviewCounterText>
              {t('page.order.print.details.pendingOrdersCount', { count: rivoliClickCollectOrders.totalItems })}
            </PreviewCounterText>
          )}
        </PreviewButtonContainer>
      )}
    </ExportButtonsCardLayout>
  )
}
