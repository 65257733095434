import Modal, { ModalProps } from 'components/Modal/Modal'
import { DeleteModalButton, DeleteModalTitle, ModalButtonWrapper } from 'components/Modal/Modal.styles'
import { useTranslation } from 'react-i18next'

interface DeleteModalProps extends ModalProps {
  title: string
  callback: () => void
  confirmationText?: string | JSX.Element
  body?: JSX.Element
}
export default function DeleteModal({ title, callback, onClose, open, confirmationText, body }: DeleteModalProps) {
  const { t } = useTranslation()
  const buttonLabel = confirmationText ?? t('common.label.validate')

  const handleConfirm = () => {
    onClose()
    callback()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <DeleteModalTitle>{title}</DeleteModalTitle>
      {body}

      <ModalButtonWrapper>
        <DeleteModalButton variant="danger" onClick={handleConfirm}>
          {buttonLabel}
        </DeleteModalButton>
        <DeleteModalButton variant="white" onClick={() => onClose()}>
          {t('common.label.cancel')}
        </DeleteModalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}
