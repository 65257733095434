import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'
export const AddFormLayout = styled.form`
  display: flex;
  gap: 2rem;
  padding-bottom: 2rem;
  align-items: flex-end;
`
export const PermissionsFormLayout = styled.form`
  display: flex;
  gap: 2rem 5rem;
  align-items: flex-end;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    display: grid;
  }
`
export const PermissionsInputsWrapper = styled.div`
  display: grid;
  gap: 2rem 10rem;
  position: relative;
  align-items: flex-end;
  grid-template-columns: repeat(2, minmax(10rem, 35rem));
  &::after {
    content: '';
    height: 100%;
    display: block;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    width: 0px;
    position: absolute;
    left: 50%;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
    &::after {
      display: none;
    }
  }
`
export const RoleLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 5rem;
  margin-top: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
export const DeleteModalDescription = styled(Text)`
  margin-bottom: 2rem;
`
