import Select from 'components/Select/Select'
import styled from 'styled-components'

export const StyledSelect = styled(Select)`
  max-width: 25rem;
` as typeof Select

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.5rem;
  align-items: flex-end;
`
