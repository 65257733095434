import { customerByIdQueryKey, patchCustomer, PatchCustomerBody } from 'api/customers'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import Select from 'components/Select/Select'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import Textarea from 'components/Textarea/Textarea'
import { Title2 } from 'components/Title/Title.styles'
import { SaveIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import { useMutation } from 'hooks/useAxiosMutation'
import { useCountryOptions } from 'hooks/useSelectOptions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { Customer } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { AccountDetail, Form } from '../CustomerDetails.style'

interface Props {
  customer: Customer
}

type FormData = {
  firstname: string
  lastname: string
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  country: string | null
  phone?: string | null
  homePhone?: string | null
}

export default function CustomerForm({ customer }: Props) {
  const { t } = useTranslation()
  const { countryOptions, getCountryCode, getCountryId } = useCountryOptions()
  const { id } = useParams()

  const defaultCountryCode = getCountryCode(customer.country)
  const { register, control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      firstname: customer.firstname,
      lastname: customer.lastname,
      address: customer.address,
      extraAddress: customer.extraAddress,
      zipCode: customer.zipCode,
      city: customer.city,
      state: customer.state,
      country: defaultCountryCode,
      phone: customer.phone,
      homePhone: customer.homePhone
    }
  })
  const queryClient = useQueryClient()
  const { mutate: updateCustomer, isLoading } = useMutation((body: PatchCustomerBody) => patchCustomer(id!, body), {
    onSuccess: (data) => {
      let queryKey = customerByIdQueryKey(customer.id!)
      let prev = queryClient.getQueriesData(queryKey)
      queryClient.setQueryData(queryKey, { ...prev, ...data })
    }
  })
  const onSubmit = (formData: FormData) => {
    const { country, ...restData } = formData
    updateCustomer({
      ...restData,
      country: getCountryId(country)
    })
  }

  useEffect(() => {
    if (defaultCountryCode) setValue('country', defaultCountryCode)
  }, [defaultCountryCode, setValue])

  const FormComponent = (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input register={register} id="lastname" label={t('page.customer.details.form.lastname')} />
      <Input register={register} id="firstname" label={t('page.customer.details.form.firstname')} />
      <Textarea register={register} id="address" label={t('common.label.address')} rows={2} />
      <Textarea register={register} id="extraAddress" label={t('common.label.extraAddress')} rows={2} />
      <Input register={register} id="zipCode" label={t('common.label.zipCode')} />
      <Input register={register} id="city" label={t('common.label.city')} />
      <Input register={register} id="state" label={t('common.label.state')} />
      <Select options={countryOptions} id="country" control={control} label={t('common.label.country')} />
      <Input register={register} id="phone" label={t('common.label.mobilePhone')} />
      <Input register={register} id="homePhone" label={t('common.label.homePhone')} />
      <Button shape="circle" icon={SaveIcon} buttonType="submit" isLoading={isLoading} />
    </Form>
  )

  const DetailTextComponent = (
    <AccountDetail>
      <InputLayout className="no-border" label={t('page.customer.details.form.lastname')}>
        <TextFieldDisplay text={customer.lastname} />
      </InputLayout>
      <InputLayout className="no-border" label={t('page.customer.details.form.firstname')}>
        <TextFieldDisplay text={customer.firstname} />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.address')}>
        <TextFieldDisplay text={customer.address} />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.extraAddress')}>
        <TextFieldDisplay text={customer.extraAddress} />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.zipCode')}>
        <TextFieldDisplay text={customer.zipCode} />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.city')}>
        <TextFieldDisplay text={customer.city} />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.state')}>
        <TextFieldDisplay text={customer.state} />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.country')}>
        <TextFieldDisplay text={getCountryCode(customer.country)} />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.mobilePhone')}>
        <TextFieldDisplay text={customer.phone} />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.homePhone')}>
        <TextFieldDisplay text={customer.homePhone} />
      </InputLayout>
    </AccountDetail>
  )

  return (
    <CardLayout>
      <Title2>{t('page.customer.details.informationsTitle')}</Title2>
      <CanAccess
        allowedComponent={FormComponent}
        deniedComponent={DetailTextComponent}
        permissions={[RoleAction.ROLE_ACTION_CUSTOMER_EDIT]}
      ></CanAccess>
    </CardLayout>
  )
}
