import Button from 'components/Button/Button'
import Cell from 'components/SortedTable/Cell'
import { Td } from 'components/SortedTable/SortedTable.styles'
import Listing from 'features/Listing/Listing'
import styled from 'styled-components'

export const MarginButton = styled(Button)`
  margin: 0.5rem 0 0.5rem 2rem;
`

export const StyledCell = styled(Cell)`
  height: auto;
`

export const OrderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 4.5em);
  width: 100%;
`

export const StyledListing = styled(Listing)`
  ${Td} {
    vertical-align: top;
  }
` as typeof Listing
