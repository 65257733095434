import { fetchCollaborators } from 'api/collaborators'
import { DateContainer } from 'app/CashboxManagement/form/CashboxManagementForm.style'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker/DatePicker'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import { Title2 } from 'components/Title/Title.styles'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { SearchIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { FormSearch, InputsContainer } from './ShippingLabelLogSearchForm.style'

export type ShippingLabelLogSearchData = {
  relatedOrder?: number
  shippingNumber?: string
  printedBy?: string
  printDateStart?: string
  printDateEnd?: string
}

const ShippinLabelLogSearchForm = ({ onSearch, isSubmitting }) => {
  const { t } = useTranslation()
  const { handleSubmit, register, setValue, trigger, control } = useForm<ShippingLabelLogSearchData>()

  const { data: collaborators } = useQuery(['warehouse-collaborators'], () =>
    fetchCollaborators({ stores: WAREHOUSE_STORE_ID, 'order[lastname]': 'asc', active: true })
  )

  return (
    <CardLayout>
      <Title2>{t('page.shippingLabelLog.searchTitle')}</Title2>
      <FormSearch onSubmit={handleSubmit(onSearch)}>
        <InputsContainer>
          <Input label={t('common.label.orderId')} id="relatedOrder" register={register} />
          <Input label={t('common.label.trackingNumber')} id="shippingNumber" register={register} />
          <DateContainer>
            <DatePicker
              id="printDateStart"
              defaultValue=""
              register={register}
              setValue={setValue}
              trigger={trigger}
              label={t('common.label.startDate')}
            />
            <DatePicker
              id="printDateEnd"
              defaultValue=""
              register={register}
              setValue={setValue}
              trigger={trigger}
              label={t('common.label.endDate')}
            />
          </DateContainer>
          <Select
            label={t('common.label.collaborator')}
            control={control}
            id="printedBy"
            options={
              collaborators
                ? collaborators.map((collaborators) => ({
                    value: collaborators['@id']!,
                    label: [collaborators.firstname, collaborators.lastname].join(' ')
                  }))
                : []
            }
            placeholder={t('common.select.defaultOptions.collaborators')}
            enableUnselect={true}
          />
        </InputsContainer>
        <Button buttonType="submit" isLoading={isSubmitting} icon={SearchIcon} shape="circle" />
      </FormSearch>
    </CardLayout>
  )
}

export default ShippinLabelLogSearchForm
