import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { Option } from 'components/Select/Select'
import { StatusTypes } from 'components/StatusBlock/StatusBlock'
import TextNumber from 'components/TextNumber/TextNumber'
import { isAfter } from 'date-fns'
import { getFilterLabel, useOrderSecondaryStatusOptions } from 'hooks/useSelectOptions'
import _, { isNil } from 'lodash'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Order, OrderItem } from 'types/entities'
import { OrderProgress } from 'types/enums'
import { OrderStatus } from 'types/playInApiInterfaces'
import { formatCalendarDate, formatDateAndHour } from 'utils/dates'
import { OrderContext } from './OrderDetails'
import {
  useCardAssemblingOptions,
  useCardCheckingOptions,
  useProductAssemblingOptions,
  useProductCheckingOptions
} from './components/PreparationCard/PreparationOptions'

const CenterRowContainer = styled(RowContainer)`
  justify-content: center;
`

type Step = {
  name?: string | null
  date?: string | null
  status: StatusTypes
  title: string
  secondaryStatus?: string | JSX.Element | boolean | null
}

type OrderSteps = {
  validatedStep: Step
  paidStep: Step
  pendingStep: Step
  sendStep: Step
  readyStep: Step
  treatedStep: Step
  canceledStep?: Step
}

export const useOrderSteps = (order?: OrderItem): OrderSteps => {
  const { t } = useTranslation()
  const { getStatusTraduction } = useOrderSecondaryStatusOptions()
  const { options: cardPrepOpts } = useCardAssemblingOptions()
  const { options: cardCheckOpts } = useCardCheckingOptions()
  const { options: productPrepOpts } = useProductAssemblingOptions()
  const { options: productCheckOpts } = useProductCheckingOptions()

  const getSecondaryStatusLabel = (options: Option[], status: string, inTransitText: string, preparedAt) => {
    if (!!inTransitText) {
      return !!preparedAt 
        ? `${getFilterLabel(options, status)} - ${inTransitText} : ${preparedAt}` 
        : `${getFilterLabel(options, status)} - ${inTransitText}`
    } else {
      return !!preparedAt 
        ? `${getFilterLabel(options, status)} : ${preparedAt}`
        : `${getFilterLabel(options, status)}`
    }
  }

  const getInPreparationSecondaryStatus = (order?: OrderItem) => {
    const {
      productCheckedSecondaryStatus,
      productPreparedSecondaryStatus,
      cardCheckedSecondaryStatus,
      cardPreparedSecondaryStatus,
      productInTransit,
      cardInTransit,
      productPreparationAt,
      cardPreparationAt
    } = order || {}

    const statuses: string[] = []

    const productInTransitText = productInTransit ? t('common.select.order.orderSecondaryStatus.productInTransit') : ''

    const cardInTransitText = cardInTransit ? t('common.select.order.orderSecondaryStatus.cardInTransit') : ''

    const productPreparationAtText = productPreparationAt ? formatDateAndHour(productPreparationAt) : ''

    const cardPreparationAtText = cardPreparationAt ? formatDateAndHour(cardPreparationAt) : ''

    if (productCheckedSecondaryStatus) {
      statuses.push(getSecondaryStatusLabel(productCheckOpts, productCheckedSecondaryStatus, productInTransitText, productPreparationAtText))
    } else if (productPreparedSecondaryStatus) {
      statuses.push(getSecondaryStatusLabel(productPrepOpts, productPreparedSecondaryStatus, productInTransitText, productPreparationAtText))
    } else if (productInTransit) {
      statuses.push(productPreparationAtText ? `${productInTransitText} : ${productPreparationAtText}` : productInTransitText)
    }

    if (cardCheckedSecondaryStatus) {
      statuses.push(getSecondaryStatusLabel(cardCheckOpts, cardCheckedSecondaryStatus, cardInTransitText, cardPreparationAtText))
    } else if (cardPreparedSecondaryStatus) {
      statuses.push(getSecondaryStatusLabel(cardPrepOpts, cardPreparedSecondaryStatus, cardInTransitText, cardPreparationAtText))
    } else if (cardInTransit) {
      statuses.push(cardPreparationAtText ? `${cardInTransitText} : ${cardPreparationAtText}` : cardInTransitText)
    }

    return statuses.length > 0 ? (
      <>
        {statuses.map((status, index) => (
          <div key={`${status}-${index}`}>{status}</div>
        ))}
      </>
    ) : undefined
  }

  type PreorderOrRestockDateType = 'preorder' | 'restock'
  const getPreorderOrRestockDateTraduction = (type: PreorderOrRestockDateType, order?: OrderItem) => {
    if (type === 'preorder')
      return (
        <div>
          {t('page.order.detail.secondaryStatus.preorderAt', {
            date: order?.approximatePreorderDate || formatCalendarDate(order?.preorderAt)
          })}
        </div>
      )
    if (type === 'restock')
      return (
        <div>
          {t('page.order.detail.secondaryStatus.restockedAt', {
            date: order?.approximateRestockDate || formatCalendarDate(order?.restockedAt)
          })}
        </div>
      )
  }

  const getPreorderOrRestockStatus = (order?: OrderItem) => {
    const restockedAt = order?.restockedAt && new Date(order.restockedAt)
    const preorderAt = order?.preorderAt && new Date(order.preorderAt)
    const now = new Date()

    // if restockedAt or preorderAt are bigger than now
    // we need to chose the right one to diplay, otherwise display nothing
    if ((restockedAt && isAfter(restockedAt, now)) || (preorderAt && isAfter(preorderAt, now))) {
      if (restockedAt && preorderAt) {
        const mostRecent: PreorderOrRestockDateType = isAfter(restockedAt, preorderAt) ? 'restock' : 'preorder'

        return getPreorderOrRestockDateTraduction(mostRecent, order)
      }
      if (restockedAt) return getPreorderOrRestockDateTraduction('restock', order)
      if (preorderAt) return getPreorderOrRestockDateTraduction('preorder', order)
    }
  }

  const getPaidStepSecondaryStatus = (order?: OrderItem) => {
    return (
      (order?.excludedFromPrints || !!order?.leftToPay || !!getPreorderOrRestockStatus(order)) && (
        <>
          {getPreorderOrRestockStatus(order)}
          {order?.excludedFromPrints && <div>{getStatusTraduction(OrderProgress.ExcludedPrint)}</div>}
          {!!order?.leftToPay && (
            <CenterRowContainer>
              {t('page.order.detail.secondaryStatus.leftToPay')}
              <TextNumber color="white" value={order?.leftToPay} suffix=" €" decimalScale={2} />
            </CenterRowContainer>
          )}
        </>
      )
    )
  }

  const getValidatedStepSecondaryStatus = (order?: OrderItem) => {
    const restockedAt = order?.restockedAt && new Date(order.restockedAt)
    const preorderAt = order?.preorderAt && new Date(order.preorderAt)
    const now = new Date()

    return (
      (order?.excludedFromPrints ||
        (restockedAt && isAfter(restockedAt, now)) ||
        (preorderAt && isAfter(preorderAt, now))) && (
        <>
          {getPreorderOrRestockStatus(order)}
          {order?.excludedFromPrints && <div>{getStatusTraduction(OrderProgress.ExcludedPrint)}</div>}
        </>
      )
    )
  }

  const getStepStatus = (
    orderStatus: OrderStatus,
    key: keyof OrderItem,
    nextStep?: Array<keyof OrderItem>
  ): StatusTypes => {
    if (order?.status === orderStatus) return StatusTypes.active
    if (order && order[key]) return StatusTypes.history
    //Next step are not supposed to be active if current one is not in history, however it can happen on old order so we need a specific status
    if (order && nextStep && _.some(nextStep, (step) => !isNil(order[step]))) return StatusTypes.old
    return StatusTypes.pending
  }

  const getPaidStepStatus = () => {
    let stepStatus = getStepStatus(OrderStatus.Paid, 'paidAt', ['printedAt', 'sentAt', 'treatedAt', 'notifiedReadyAt'])
    if (stepStatus === StatusTypes.old && !!order?.totalToPay)
      return StatusTypes.canceled
    return stepStatus
  }
  const getPaidStepTitle = () => {
    let stepStatus = getStepStatus(OrderStatus.Paid, 'paidAt', ['printedAt', 'sentAt', 'treatedAt', 'notifiedReadyAt'])
    if (stepStatus === StatusTypes.old && !!order?.totalToPay)
      return t('common.select.order.orderStatus.toPay')
    return t('common.select.order.orderStatus.paid')
  }

  const validatedStep: Step = {
    status: getStepStatus(OrderStatus.Validated, 'createdAt'),
    date: order?.createdAt,
    name: order?.createdBy && `${order?.createdBy.firstname} ${order?.createdBy.lastname}`,
    title: t('common.select.order.orderStatus.validated'),
    secondaryStatus: getValidatedStepSecondaryStatus(order)
  }

  const paidStep: Step = {
    status: getPaidStepStatus(),
    date: order?.paidAt,
    name: order?.paymentReceivedBy && `${order.paymentReceivedBy.firstname} ${order.paymentReceivedBy.lastname}`,
    title: getPaidStepTitle(),
    secondaryStatus: getPaidStepSecondaryStatus(order)
  }

  const pendingStep: Step = {
    status: getStepStatus(OrderStatus.InPreparation, 'printedAt', ['sentAt', 'treatedAt', 'notifiedReadyAt']),
    date: order?.printedAt,
    name: order?.printedBy && `${order.printedBy.firstname} ${order.printedBy.lastname}`,
    title: t('common.select.order.orderStatus.inPreparation'),
    secondaryStatus: getInPreparationSecondaryStatus(order)
  }

  const readyStep: Step = {
    status: getStepStatus(OrderStatus.Ready, 'notifiedReadyAt', ['treatedAt']),
    date: order?.notifiedReadyAt,
    name: order?.notifiedReadyBy && `${order.notifiedReadyBy.firstname} ${order.notifiedReadyBy.lastname}`,
    title: t('common.select.order.orderStatus.ready'),
    secondaryStatus: order?.storePlace
      ? `${t('page.order.detail.secondaryStatus.storePlace', { storePlace: order.storePlace })}`
      : undefined
  }

  const sendStep: Step = {
    status: getStepStatus(OrderStatus.Send, 'sentAt', ['notifiedReadyAt', 'treatedAt']),
    date: order?.sentAt,
    name: order?.sentBy && `${order.sentBy.firstname} ${order.sentBy.lastname}`,
    title: t('common.select.order.orderStatus.send'),
    secondaryStatus: order?.trackingNumber
      ? `${t('page.order.detail.secondaryStatus.trackingNumber', { trackingNumber: order.trackingNumber })}`
      : undefined
  }

  const treatedStep: Step = {
    status: getStepStatus(OrderStatus.Treated, 'treatedAt'),
    date: order?.treatedAt,
    name: order?.treatedBy && `${order.treatedBy.firstname} ${order.treatedBy.lastname}`,
    title: t('common.select.order.orderStatus.treated')
  }

  const getCancelStep = (): { canceledStep: Step } | undefined => {
    if (order?.canceled || order?.status === OrderStatus.Canceled)
      return {
        canceledStep: {
          status: StatusTypes.canceled,
          title: t('common.select.order.orderStatus.canceled'),
          date: order?.canceledAt,
          name: order?.canceledBy && `${order.canceledBy.firstname} ${order.canceledBy.lastname}`
        }
      }
    if (order?.cancelRequested || order?.status === OrderStatus.CanceledRequested)
      return {
        canceledStep: {
          status: StatusTypes.canceled,
          title: t('common.select.order.orderStatus.canceledRequest'),
          date: order?.cancelRequestedAt
        }
      }

    return
  }

  return {
    validatedStep,
    paidStep,
    pendingStep,
    sendStep,
    readyStep,
    treatedStep,
    ...getCancelStep()
  }
}

export const useOrderContext = () => {
  const { inMyStoresOrder } = useContext(OrderContext)
  return {
    inMyStoresOrder
  }
}

export const useOrderTab = (order?: Order) => {
  const orderDefaultTab = useMemo((): number => {
    if (order?.status === OrderStatus.Validated || order?.status === OrderStatus.CanceledRequested) return 1
    if (order?.status === OrderStatus.Paid) return 2
    if (order?.status === OrderStatus.InPreparation) return 3
    return 0
    // We need to only execute this on id change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id])

  return {
    orderDefaultTab
  }
}

export const getRecipientNameFromOrder = (order?: Order) => 
  order?.address?.recipientName ||
  order?.address?.billingName ||
  order?.customer?.fullName ||
  order?.customer?.email ||
  ''

export const getTotalRefunds = (refundsArray) => {
  return parseFloat(
    _.reduce(refundsArray, (prev, current) => prev + (current?.refundedAmount ?? 0), 0)
    .toFixed(2)
  )
}
