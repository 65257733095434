import { Text } from 'components/Text/Text.styles'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import { useTranslation } from 'react-i18next'
import { Litigation } from 'types/entities'
import { formatDateAndHour } from 'utils/dates'
import { RelayRecipientTitle2, ShippingTitleContainer, UnspacedTitle2 } from './OrderInfoCard.style'

interface AddressCardProps {
  title?: string
  relayClickCollectRecipient?: string
  address?: string | null
  extraInformations?: string | null
  postalCode?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
  customerLitigations?: Array<Litigation>
}

function AddressPanel({
  title,
  relayClickCollectRecipient,
  address,
  extraInformations,
  postalCode,
  city,
  country,
  customerLitigations
}: AddressCardProps) {
  const { t } = useTranslation()
  return (
    <>
      {title && (
        <ShippingTitleContainer>
          {customerLitigations && customerLitigations.length > 0 && (
            <MultiLineTooltip
              color="danger"
              id={'customerLitigationsTooltip'}
              lines={customerLitigations.map(
                (litigation) =>
                  `${litigation.category?.name}  ${
                    litigation.issuedAt && `(${formatDateAndHour(litigation.issuedAt)})`
                  }`
              )}
            />
          )}
          <UnspacedTitle2>
            <span>{title}</span>
            {relayClickCollectRecipient && (
              <RelayRecipientTitle2>
                {t('page.order.detail.relayClickCollectRecipientTitle', {
                  recipient: relayClickCollectRecipient
                })}
              </RelayRecipientTitle2>
            )}
          </UnspacedTitle2>
        </ShippingTitleContainer>
      )}
      {address && (
        <Text>
          {address}
          {extraInformations && `, ${extraInformations}`}
        </Text>
      )}
      {(postalCode || city) && (
        <Text fontWeight="bold">
          {[postalCode, city].join(' ')}
          {country && `, ${country}`}
        </Text>
      )}
    </>
  )
}

export default AddressPanel
