import Snackbar from '@mui/material/Snackbar'
import Alert from 'components/Alert/Alert'
import { useEventEmitter } from 'hooks/useEventEmitter'
import find from 'lodash/find'
import { useCallback, useEffect, useState } from 'react'
import { EventType } from 'types/events'
import { NotificationEvent, NotificationId } from 'types/notifications'

interface NotificationState {
  type: 'success' | 'warning' | 'error'
  id: NotificationId
  title: string
  text?: string | string[]
  subtext?: string
  key: number
}

const SnackbarManager = () => {
  const [open, setOpen] = useState(false)
  const { useListener } = useEventEmitter()
  const [notification, setNotification] = useState<NotificationState | null>(null)
  const [notifificationStack, setNotificationStack] = useState<NotificationState[]>([])

  useEffect(() => {
    if (notifificationStack.length > 0 && !notification) {
      // Set a new notification when we don't have an active one
      setNotification({ ...notifificationStack[0] })
      // Remove it from stack
      setNotificationStack((prev) => prev.slice(1))
      setOpen(true)
    }
  }, [notifificationStack, notification, open])

  // useCallback prevent the function to reset every rerender,
  // so useListener won't reset on rerender
  const notificationHandler = useCallback(
    (payload: NotificationEvent) => {
      setNotificationStack((prev) => {
        // if the incoming notification already exists in stack or is the active notification
        // we just ignore it
        const isNotificationInStack = !!find(prev, { id: payload.id })
        const isNotificationActive = notification?.id === payload.id

        if (!isNotificationActive && !isNotificationInStack) {
          return [...prev, { ...payload, key: new Date().getTime() }]
        }

        return prev
      })
    },
    [notification]
  )

  useListener(EventType.Notification, notificationHandler)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  // When a snackbar disapear, reset the current notification to trigger useEffect
  // And grad the next snackbar
  const handleExited = () => {
    setNotification(null)
  }

  return (
    <>
      {notification && (
        <Snackbar
          key={notification.key}
          sx={{
            width: '100%'
          }}
          open={open}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          autoHideDuration={5000}
        >
          <Alert
            onClose={handleClose}
            severity={notification?.type}
            title={notification.title}
            text={notification.text}
            subtext={notification.subtext}
          />
        </Snackbar>
      )}
    </>
  )
}

export default SnackbarManager
