import { ChipsButton } from 'components/Button/Button'
import styled from 'styled-components'
import theme from 'theme/theme'

export const FiltersContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
  flex-wrap: wrap;
  border-top: 1px solid ${theme.borderColors.secondary};
  &.no-padding {
    border: none;
    padding-top: 0;
  }
`

export const StyledChipButton = styled(ChipsButton)`
  &.no-clear {
    pointer-events: none;
    cursor: auto;
  }
`
