import styled from 'styled-components'

export const RefundWrapper = styled.div`
  display: grid;
  gap: 2rem;
  padding: 3.5rem 0;
`

export const RefundFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 3.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
