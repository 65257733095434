import { useCollaboratorById } from 'api/collaborators'
import BottomMessage from 'components/BottomMessage/BottomMesssage'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { PageLoader } from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { CardsCol } from './CollaboratorDetails.style'
import PasswordManage from './components/PasswordManage'
import RoleActionsManage from './components/RoleActionManage'
import RolesManage from './components/RolesManage'
import StoresManage from './components/StoresManage'
import CollaboratorDetailsForm from './forms/CollaboratorDetailsForm'

function CollaboratorDetails() {
  const { id } = useParams()
  const { t } = useTranslation()
  const { data: collaborator, isLoading } = useCollaboratorById(id!)
  return (
    <Section>
      {isLoading && <PageLoader />}
      {collaborator && (
        <>
          <MainTitle title={`${collaborator.firstname} ${collaborator.lastname}`} />
          <PasswordManage collaborator={collaborator} />
          <CardsCol>
            <CardLayout>
              <Title2>{t('page.collaborator.details.stores.permissions.title')}</Title2>
              <RolesManage collaborator={collaborator} />
              <RoleActionsManage collaborator={collaborator} />
            </CardLayout>

            <StoresManage collaborator={collaborator} />
          </CardsCol>
          <CollaboratorDetailsForm collaborator={collaborator} />
          <BottomMessage text={t('common.requiredLabel')} />
        </>
      )}
    </Section>
  )
}

export default CollaboratorDetails
