import { yupResolver } from '@hookform/resolvers/yup'
import { getSealedProductsUri } from 'api/sealedProducts'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SealedProduct, Store } from 'types/entities'
import * as yup from 'yup'
import { FieldsCol } from '../otherProductsTab.style'

const schema = yup.object().shape({
  searchProduct: yup.string().trim().nullable(true)
})

interface SearchProductInputs {
  searchProduct?: string | null
}

interface Props {
  onSelect: (searchFrom: 'releaseDate' | 'productSearch' | 'edition', searchedProducts?: SealedProduct[]) => void
  store: Store
}
export default function SearchProductForm({ onSelect, store }: Props) {
  const { t } = useTranslation()
  const {
    formState: { touchedFields },
    control,
    setValue,
    reset
  } = useForm<SearchProductInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  useEffect(() => reset(), [reset, store])

  return (
    <FieldsCol>
      <InputAutoComplete
        control={control}
        labelKey="name"
        valueKey="@id"
        id="searchProduct"
        isTouched={touchedFields.searchProduct}
        getUrlFromSearch={(search) => getSealedProductsUri({ 'exists[secondHandOf]': false, search })}
        label={t('common.label.product')}
        placeholder={t('page.order.print.details.placeholders.product')}
        onEntitySelected={(product) => {
          if (product) {
            setValue('searchProduct', null)
            onSelect('productSearch', [product])
          }
        }}
      />
    </FieldsCol>
  )
}
