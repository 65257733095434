import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import styled from 'styled-components'

export const PreparationTabCardContainer = styled.div`
  max-width: 100%;
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }

  @media screen and (${(props) => props.theme.screenWidth.minLaptopScreen}) and (${(props) =>
      props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
    & > :last-child {
      grid-column: span 2;
    }
  }

  @media screen and (${(props) => props.theme.screenWidth.minDesktopScreen}) {
    grid-template-columns: 3.5fr 3.5fr 3fr;
  }

  @media screen and (${(props) => props.theme.screenWidth.minLargeScreen}) {
    grid-template-columns: 4fr 4fr 2fr;
  }
`

export const PreparationTabCard = styled(CardLayout)`
  margin-bottom: 0;
`
