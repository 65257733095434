import styled from 'styled-components'
import { OneLineText, PriceText, TextContainer } from '../StoreLabels.styles'

export const HalfMoonRectangle = styled.div`
  width: 100%;
  height: 16.4rem;
  background-color: #1b1437;

  &.down {
    margin-top: 5.2rem;
    border-radius: 50% 50% 0% 0%;
  }

  &.up {
    margin-top: -7.2rem;
    border-radius: 0% 0% 50% 50%;
  }
`

export const Subtitle = styled(OneLineText)`
  height: 1.8rem;
  top: 4.8rem;
`

export const RectangleContainer = styled.div`
  position: relative;
  width: 32rem;
  height: 14.4rem;
  overflow: hidden;

  cursor: pointer;
`
export const StyledPriceTextDown = styled(PriceText)`
  bottom: 2rem;

  font-size: 3.4rem;
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`

export const StyledPriceTextUp = styled(PriceText)`
  top: 1.9rem;

  font-size: 3.4rem;
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`

export const StyledTextContainer = styled(TextContainer)`
  &.down {
    top: 0.8rem;
  }

  &.up {
    bottom: 0.8rem;
  }
`
