import FieldDisplay from 'components/Text/FieldDisplay'
import styled from 'styled-components'
export const FormLayout = styled.form`
  display: flex;
  gap: 3.5rem;
  align-items: flex-end;

  @media screen and (${(props) => props.theme.screenWidth.minDesktopScreen}) {
    .field {
      max-width: 25rem;
    }
    .small-field {
      max-width: 15rem;
    }
  }

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 100%;
  }
`

export const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) and (${(props) =>
      props.theme.screenWidth.minTabletScreen}) {
    grid-column: span 2;
  }
`

export const DisplayName = styled(FieldDisplay)`
  max-width: 25rem;
`
