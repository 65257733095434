import { InputContainer } from 'components/Select/Select.styles'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'
import theme from 'theme/theme'

export const MainTitleStyle = styled.h1`
  margin-top: 0;
  font-size: 2.8rem;
  font-weight: ${(props) => props.theme.fontWeight.bolder};
  margin-bottom: 3.3rem;
  height: fit-content;
  line-height: 2.5rem;

  @media screen and (max-width: ${(props) => props.theme.screenWidth.tabletScreen}) {
    font-size: 2.2rem;
  }
`

export const Title2 = styled.h2`
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`

export const Title3 = styled.h3`
  margin: 0;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`

export const HeadingLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
  h1 {
    margin-bottom: 0;
  }
  @media screen and (${theme.screenWidth.maxDesktopScreen}) {
    display: grid;
    row-gap: 1rem;
    form {
      justify-self: flex-start;
    }
  }
  @media screen and (${theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: repeat(1, 100%);
    form {
      width: 100%;

      ${InputContainer} {
        width: 100%;
      }
    }
  }

  ${InputContainer} {
    gap: 0.5rem;
    width: 20rem;
  }
`

export const Subtitle = styled(Text)`
  margin-left: 0.5rem;
`
