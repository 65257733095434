import { patchCollectionCategory, PatchCollectionCategoryBody } from 'api/collectionCategories'
import {
  deleteCollectionEntry,
  patchCollectionEntry,
  PatchCollectionEntryBody,
  postCollectionEntry,
  PostCollectionEntryBody
} from 'api/collectionEntries'
import { getCollectionCategoriesKey, useCollectionCategories } from 'app/CollectionDetails/utils'
import { useApiNotifications } from 'hooks/useApiNotifications'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEffect, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useQueryClient } from 'react-query'
import { ProductCollection, ProductCollectionCategory } from 'types/entities'
import { getIdFromIri } from 'utils/queryParams'
import { ProductCollectionEntry } from '../form/AddProductsForm'
import { CollectionCategoryFormDatas } from '../form/CategoryForm'
import CategoryItem from './CategoryItem'
interface Props {
  isActive: boolean
  productCollection: ProductCollection
}
export default function EditCategory({ isActive, productCollection }: Props) {
  const [orderedCategories, setOrderedCategories] = useState<ProductCollectionCategory[]>([])
  const [isUpdatingItem, setIsUpdatingItem] = useState(false)

  const { collectionCategories } = useCollectionCategories({
    isActive
  })

  const { emitSuccessNotification } = useApiNotifications()
  const queryClient = useQueryClient()

  //Patch collection mutation entry
  const { mutateAsync: mutateCategory } = useMutation(
    ({ body, id }: { body: PatchCollectionCategoryBody; id: string }) => patchCollectionCategory(body, id),
    {
      emitDefaultSuccessNotification: false
    }
  )

  const { mutateAsync: createEntry } = useMutation((body: PostCollectionEntryBody) => postCollectionEntry(body), {
    emitDefaultSuccessNotification: false
  })
  const { mutateAsync: patchEntry } = useMutation(
    ({ body, id }: { body: PatchCollectionEntryBody; id: string }) => patchCollectionEntry(id, body),
    {
      emitDefaultSuccessNotification: false
    }
  )
  const { mutateAsync: removeEntry } = useMutation((id: string) => deleteCollectionEntry(id), {
    emitDefaultSuccessNotification: false
  })

  const onDragEnd = (result: DropResult) => {
    // If item is dropped outside of dropZone, we do nothing
    if (!!result.destination && result.source.index !== result.destination.index) {
      const newList = [...orderedCategories]
      const item = newList.splice(result.source.index, 1)[0]
      newList.splice(result.destination.index, 0, item)
      setOrderedCategories(newList)
      mutateCategory({
        //@ts-ignore TODO Fields should not be required
        body: {
          position: result.destination.index
        },
        id: getIdFromIri(result.draggableId)
      })
    }
  }

  useEffect(() => {
    setOrderedCategories(collectionCategories)
  }, [collectionCategories])

  const onSubmit = (formData: CollectionCategoryFormDatas, category: ProductCollectionCategory): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const { products, ...restData } = formData

      const toDeleteEntries: string[] = []
      const toAddEntries: Map<string, ProductCollectionEntry> = new Map()
      const toPatchEntries: Map<string, ProductCollectionEntry> = new Map()

      // If an entry is present in current category & no longer present in products coming from form
      // Then this entry need to be deleted
      for (let currentEntry of category?.entries ?? []) {
        if (!products?.find((e) => e.product['@id'] === currentEntry.product?.['@id'])) {
          toDeleteEntries.push(getIdFromIri(currentEntry['@id']!))
        }
      }

      for (let formEntry of products) {
        const currentEntry = category.entries?.find((entry) => entry.product?.['@id'] === formEntry.product['@id'])
        // If an entry is present in current category & in products from form
        // Then this entry need to be updated
        if (currentEntry) {
          // Patch the entry only if his ranking has changed
          if (!(formEntry.rank && currentEntry?.product?.[formEntry.rank])) {
            toPatchEntries.set(getIdFromIri(currentEntry['@id']!), formEntry)
          }
        } else {
          //Otherwise, the entry is new and need to be added
          toAddEntries.set(formEntry.product['@id']!, formEntry)
        }
      }

      try {
        setIsUpdatingItem(true)
        // First, update category datas
        await mutateCategory({ body: restData, id: getIdFromIri(category['@id']!) })

        // Then we delete entries which need to be
        for (let toDeleteEntry of toDeleteEntries) {
          await removeEntry(toDeleteEntry)
        }

        // Then we add new entries
        for (let toAddEntry of toAddEntries.values()) {
          await createEntry({
            product: toAddEntry.product['@id']!,
            category: category['@id']!,
            baseBox: toAddEntry.rank === 'baseBox',
            expansionBox: toAddEntry.rank === 'expansionBox'
          })
        }

        // Then patch updated entries
        for (let toPatchEntry of toPatchEntries.entries()) {
          const [id, value] = toPatchEntry

          await patchEntry({
            id,
            body: {
              product: {
                baseBox: value.rank === 'baseBox',
                expansionBox: value.rank === 'expansionBox'
              }
            }
          })
        }

        resolve()
        emitSuccessNotification()
      } catch {
        reject()
      } finally {
        setIsUpdatingItem(false)

        // Finaly, refetch collection in background, to get up to date data
        await queryClient.refetchQueries(getCollectionCategoriesKey(`${productCollection.id}`))
      }
    })
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories" direction="vertical">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {orderedCategories?.map((e, index) => (
                <CategoryItem
                  index={index}
                  category={e}
                  key={`category-${e['@id']}`}
                  isLoading={isUpdatingItem}
                  onSubmit={(formData) => onSubmit(formData, e)}
                  productCollection={productCollection}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
