import Button from 'components/Button/Button'
import { AngleUpIcon } from 'constants/icons'
import useScrollPosition from 'hooks/useScrollPosition'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { BackToTopContainer } from './BackToTop.style'

function BackToTop() {
  const scrollPosition = useScrollPosition()
  const location = useLocation()

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(scrollToTop, [location])

  return (
    <>
      {
        // Le composant doit s'afficher à partir d'un scroll de 70rem
        scrollPosition >= 700 && (
          <BackToTopContainer>
            <Button icon={AngleUpIcon} variant="white" shape="circle" borderColor="secondary" onClick={scrollToTop} />
          </BackToTopContainer>
        )
      }
    </>
  )
}

export default BackToTop
