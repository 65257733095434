import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PostalRelay } from 'types/entities'
import {Api} from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

export type PostRelayBody = Api.PostPostalRelaysWithExternalId.RequestBody
type PostRelayResponse = Api.PostPostalRelaysWithExternalId.ResponseBody

export const postRelay = async (relayId: string, labelType: string | undefined): Promise<PostalRelay> => {
  let res = await playInApi.post<PostRelayBody, AxiosResponse<PostRelayResponse>>(API_ROUTES.postalRelays.root, {
    relayId,
    labelType
  })
  return res.data
}
