import { authenticate } from 'api/authenticate'
import { fetchCollaboratorById } from 'api/collaborators'
import { PLAYIN_SUPPORT_EMAIL } from 'constants/configs'
import { AuthContext } from 'contexts/auth'
import { useAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { EventType } from 'types/events'
import { NotificationId, NotificationType } from 'types/notifications'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import { getIdFromIri } from 'utils/queryParams'
import { useEventEmitter } from './useEventEmitter'

export default function useAuth() {
  const queryClient = useQueryClient()
  const { setIsAuth, isAuth, me, handleMyData, accessControl, setAccessControl } = useContext(AuthContext)
  const navigate = useNavigate()
  const { emit } = useEventEmitter()
  const { t } = useTranslation()
  const [, setAtomLocation] = useAtom(defaultStoreAtom)

  const signIn = async (email: string, password: string) => {
    try {
      const { me } = await authenticate({ email, password })

      return me
    } catch (error) {
      emit(EventType.Notification, {
        id: NotificationId.BadCredentials,
        type: NotificationType.Error,
        title: t('notification.badCredentials.title'),
        subtext: t('notification.badCredentials.subtext')
      })
      throw new Error()
    }
  }

  const fetchUserData = async (meIri: string) => {
    try {
      const me = await fetchCollaboratorById(getIdFromIri(meIri))

      if (!me) {
        emit(EventType.Notification, {
          id: NotificationId.BadCredentials,
          type: NotificationType.Error,
          title: t('notification.badCredentials.title'),
          subtext: t('notification.badCredentials.subtext')
        })
        throw new Error('user not found')
      }

      handleMyData(me)
      setIsAuth(true)
    } catch (error: any) {
      if (error?.status === 401) {
        emit(EventType.Notification, {
          id: NotificationId.InactiveUser,
          title: t('notification.inactiveUser.title'),
          subtext: t('notification.inactiveUser.subtext'),
          text: PLAYIN_SUPPORT_EMAIL,
          type: NotificationType.Error
        })
      }
      throw new Error()
    }
  }

  const login = async (email: string, password: string) => {
    try {
      const meIri = await signIn(email, password)
      if (!meIri) {
        throw new Error('No user iri')
      }
      await fetchUserData(meIri)
    } catch (error) {
      logout()
    }
  }

  const logout = () => {
    setIsAuth(false)
    // Clear location localStorage atom
    setAtomLocation(RESET)
    localStorage.clear()
    queryClient.clear()

    navigate('/login')
  }

  return {
    me,
    isAuth,
    login,
    logout,
    accessControl,
    setAccessControl
  }
}
