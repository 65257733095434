import BottomMessage from 'components/BottomMessage/BottomMesssage'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { useTranslation } from 'react-i18next'
import BannerForm from './BannerForm'

const BannerCreate = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <MainTitle title={t('page.banner.create.title', { name: 'Nom de la banière' })} />

      <BannerForm
        defaultValues={{ lang: 'fr', img: undefined, imgLarge: undefined, title: '', urlLink: '' }}
        onSubmit={(formData) => {}}
        mode="create"
      />

      <BottomMessage text={t('common.requiredLabel')} />
    </Section>
  )
}

export default BannerCreate
