import { useAtom } from 'jotai'
import _ from 'lodash'
import noop from 'lodash/noop'
import { createContext, useState } from 'react'
import { Collaborator } from 'types/entities'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import { getJWT, getUserData, setUserData } from 'utils/localStorage'

const useAccessControl = process.env.REACT_APP_BYPASS_PERRMISSIONS === 'true' ? false : true

interface AuthContextInterface {
  isAuth: boolean
  me: Collaborator | undefined
  setIsAuth: any
  handleMyData: any
  accessControl: boolean
  setAccessControl: any
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuth: false,
  me: undefined,
  setIsAuth: noop,
  handleMyData: noop,
  accessControl: useAccessControl,
  setAccessControl: noop
})

export default function Auth(props) {
  const jwt = getJWT()
  const localStorageMe = getUserData()
  const [isAuth, setIsAuth] = useState(!!jwt && !!localStorageMe)
  const [me, setMe] = useState<Collaborator | undefined>(localStorageMe)
  const [accessControl, setAccessControl] = useState(useAccessControl)
  const [, setAtomLocation] = useAtom(defaultStoreAtom)

  const handleMyData = (myData: Collaborator) => {
    const myStoreSorted = _.sortBy(myData.stores, 'shortName')
    setAtomLocation(myData.mainStore)

    const myDataSorted = {
      ...myData,
      stores: myStoreSorted
    }

    setUserData(myDataSorted)
    setMe(myDataSorted)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        me,
        setIsAuth,
        handleMyData,
        accessControl,
        setAccessControl
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
