import { AxiosResponse } from 'axios'
import { MutationFunction, useMutation as useReactQueryMutation, UseMutationOptions } from 'react-query'
import { useApiNotifications } from './useApiNotifications'

type MutationOptions<TData, TVariables, TContext> = UseMutationOptions<TData, AxiosResponse, TVariables, TContext> & {
  emitDefaultSuccessNotification?: boolean
  emitDefaultErrorNotification?: boolean
}

const defaultOptions = {
  emitDefaultSuccessNotification: true,
  emitDefaultErrorNotification: true
}

export function useMutation<TData = unknown, TVariables = unknown, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options: MutationOptions<TData, TVariables, TContext> = {}
) {
  const { emitDefaultSuccessNotification, emitDefaultErrorNotification, ...opts } = { ...defaultOptions, ...options }
  const { emitErrorNotification, emitSuccessNotification } = useApiNotifications()

  return useReactQueryMutation(mutationFn, {
    ...opts,
    onError: (error, variables, context) => {
      if (emitDefaultErrorNotification) {
        emitErrorNotification(error.status, error.data)
      }

      if (options?.onError) {
        options.onError(error, variables, context)
      }
    },
    onSuccess: (data, variables, context) => {
      if (emitDefaultSuccessNotification) {
        emitSuccessNotification()
      }

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context)
      }
    }
  })
}
