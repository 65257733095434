import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import Listing from 'features/Listing/Listing'
import { RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { FutureStoreEventOrder } from 'types/entities'
import { CustomerJsonldCustomerReadCustomerReadItem } from 'types/playInApiInterfaces'
import { fullDateFormat } from 'utils/dates'

interface Props {
  customer: CustomerJsonldCustomerReadCustomerReadItem
}

export default function FutureEventList({ customer }: Props) {
  const { t } = useTranslation()
  const columns: Column<FutureStoreEventOrder>[] = [
    {
      key: 'date',
      name: t('common.label.date'),
      decorator: (eventOrder) => {
        const date = fullDateFormat(eventOrder?.storeEventCustomers?.[0].event?.startsAt)
        const capitalizedDate = date && date.charAt(0).toUpperCase() + date.slice(1)
        return (
          <ClickableCell route={RouteName.StoreEventDetails} value={eventOrder?.storeEventCustomers?.[0].event?.id}>
            <Text>{capitalizedDate}</Text>
          </ClickableCell>
        )
      }
    },
    {
      key: 'name',
      name: t('common.label.name'),
      decorator: (eventOrder) => (
        <ClickableCell route={RouteName.StoreEventDetails} value={eventOrder?.storeEventCustomers?.[0].event?.id}>
          {eventOrder?.storeEventCustomers?.[0].event?.name}
        </ClickableCell>
      )
    },
    {
      key: 'format',
      name: t('common.label.format'),
      decorator: (eventOrder) => (
        <ClickableCell route={RouteName.StoreEventDetails} value={eventOrder?.storeEventCustomers?.[0].event?.id}>
          {eventOrder?.storeEventCustomers?.[0].event?.format?.name}
        </ClickableCell>
      )
    },
    {
      key: 'store',
      name: t('common.label.location'),
      decorator: (eventOrder) => (
        <ClickableCell route={RouteName.StoreEventDetails} value={eventOrder?.storeEventCustomers?.[0].event?.id}>
          {eventOrder?.storeEventCustomers?.[0].event?.store?.name}
        </ClickableCell>
      )
    }
  ]
  return (
    <CardLayout>
      <Title2>{t('page.customer.details.furureEvent.title')}</Title2>
      <Listing
        data={customer.futureEventOrders ?? []}
        columns={columns}
        emptyText={<Text color="secondary">{t('page.customer.details.furureEvent.emptyText')}</Text>}
        totalCount={customer.futureEventOrders?.length ?? 0}
      />
    </CardLayout>
  )
}
