import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { ShippingLabelsLog } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type FetchShippingLabelsLogResponse = Api.GetShippingLabelLogs.ResponseBody['hydra:member'][0]
export const usePaginatedShippingLabels = (
  queryKey: string,
  query: string,
  options?: PaginatedHookOptions<FetchShippingLabelsLogResponse>
) => usePaginatedQuery<FetchShippingLabelsLogResponse>(queryKey, query, options)

type FetchShippinglabelLogQueryParams = Api.GetShippingLabelLogs.RequestQuery
export const getShippingLabelUri = (params: FetchShippinglabelLogQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.shippingLabelsLog.root, queryString].join('?')
}

export type PatchShippingLabelLogRequest = Api.PatchShippingLabelLogsId.RequestBody
type PatchShippingLabelLogResponse = Api.PatchShippingLabelLogsId.ResponseBody
export const patchShippingLabel = async (
  id: string | number,
  body: PatchShippingLabelLogRequest
): Promise<ShippingLabelsLog> => {
  let res = await playInApi.patch<PatchShippingLabelLogRequest, AxiosResponse<PatchShippingLabelLogResponse>>(
    `${API_ROUTES.shippingLabelsLog.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}
