import Button from 'components/Button/Button'
import { Text } from 'components/Text/Text.styles'
import Link from 'features/Permissions/Link/Link'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);

  & > *:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.borderColors.secondary};
    margin-bottom: 4rem;
    padding-bottom: 4rem;
  }
`

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(10rem, auto);
  grid-column-gap: 1rem;
  align-items: center;
  grid-row-gap: 2rem;
`

export const ProductStoreWrapper = styled.div`
  display: grid;
  row-gap: 3rem;
`

export const ProductFormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5rem;
  grid-row-gap: 2rem;
  align-items: flex-start;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${(props) => props.theme.screenWidth.laptopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const ProductStatsGrid = styled(ProductFormGrid)`
  grid-template-columns: repeat(4, 1fr);

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr;
  }
`
export const UnavailableProductStoreFormGrid = styled(ProductFormGrid)`
  align-items: flex-end;
`

export const FormSection = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  grid-auto-rows: auto;
`

export const ProductSecondFormSection = styled(FormSection)`
  grid-auto-rows: 1fr;
`

export const ProductDetailsSection = styled(FormSection)`
  grid-row-gap: 3rem;
`

export const DataAlignEnd = styled.div`
  justify-self: end;
`

export const PrintStoreLabelLink = styled(Link)`
  color: ${(props) => props.theme.textColors.darkPurple};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`

export const SaveButton = styled(Button)`
  margin-top: 2rem;
`

export const TextFormField = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-rows: auto 1fr;
  height: 100%;
  & > :nth-child(2) {
    align-self: flex-start;
  }
`

export const PackProductSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  column-gap: 3.5rem;
`

export const PackProductImg = styled.img`
  max-width: 7.5rem;
  max-height: 7.5rem;
`

export const PackProductContainer = styled(Link)`
  display: flex;
  gap: 1rem;
  align-items: center;

  &::before {
    content: '';
    background-color: black;
    border-radius: 50%;
    margin: 0.4rem;
    padding: 0.3rem;
  }
`

export const PackTitle = styled(Text)`
  margin: 3rem 0 1rem 0;
`

export const DiscountWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: flex-start;
`
