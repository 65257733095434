import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { defaultStoreAtom } from 'utils/jotaiAtom'

export default function NotAvailable() {
  const [store] = useAtom(defaultStoreAtom)
  const { t } = useTranslation()
  return (
    <Section>
      <MainTitle title={t('page.events.management.title')} showStoreSelect={true} />
      <CardLayout>
        <Text color="danger">{t('page.events.details.notAvailable', { name: store?.shortName })}</Text>
      </CardLayout>
    </Section>
  )
}
