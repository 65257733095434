import { GetOrderByIdResponse } from 'api/orders'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { TwoColGridRow } from './PreparationCardSection.style'
import { useProductAssemblingOptions, useProductCheckingOptions } from './PreparationOptions'

interface PreparationProductSectionProps {
  order?: GetOrderByIdResponse
}

export default function PreparationProductDisplay({ order }: PreparationProductSectionProps) {
  const { t } = useTranslation()

  const { options: productAssemblingOptions } = useProductAssemblingOptions()
  const { options: productCheckingOptions } = useProductCheckingOptions()

  return (
    <>
      <Title2>{t('page.order.detail.tab.prepare.productSectionTitle')}</Title2>
      <TwoColGridRow>
        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.productAssemblingLabel')}>
          <TextFieldDisplay
            text={productAssemblingOptions.find((opt) => opt.value === order?.productPreparedSecondaryStatus)?.label}
          />
        </InputLayout>

        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.productCheckingLabel')}>
          <TextFieldDisplay
            text={productCheckingOptions.find((opt) => opt.value === order?.productCheckedSecondaryStatus)?.label}
          />
        </InputLayout>

        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.productPreparatorLabel')}>
          <TextFieldDisplay
            text={
              order?.productPreparer &&
              [`${order.productPreparer.id} -`, order.productPreparer.lastname, order.productPreparer.firstname].join(
                ' '
              )
            }
          />
        </InputLayout>

        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.productCheckerLabel')}>
          <TextFieldDisplay
            text={
              order?.productChecker &&
              [`${order.productChecker.id} -`, order.productChecker.lastname, order.productChecker.firstname].join(' ')
            }
          />
        </InputLayout>

        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.transitLabel')}>
          <TextFieldDisplay text={order?.productInTransit ? t('common.radioButton.yes') : t('common.radioButton.no')} />
        </InputLayout>
      </TwoColGridRow>
    </>
  )
}
