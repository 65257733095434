import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { SearchIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { SearchFormFields, SearchFormWrapper } from './CollectionManagement.style'

const schema = yup.object({
  name: yup.string(),
  onFamilyHome: yup.boolean(),
  onCollectionHome: yup.boolean(),
  active: yup.boolean()
})

export interface SearchCollectionInputs extends yup.InferType<typeof schema> {}

interface Props {
  onSubmit: (formData: SearchCollectionInputs) => void
  isFormLoading?: boolean
}
export default function SearchCollectionForm({ onSubmit, isFormLoading }: Props) {
  const { t } = useTranslation()
  const { handleSubmit, control, register } = useForm<SearchCollectionInputs>({
    resolver: yupResolver(schema)
  })

  return (
    <CardLayout>
      <Title2>{t('page.collection.manage.formTitle')}</Title2>
      <SearchFormWrapper onSubmit={handleSubmit(onSubmit)}>
        <SearchFormFields>
          <Input
            register={register}
            id="name"
            label={t('common.label.name')}
            placeholder={t('page.collection.manage.placeholder.name')}
          />
          <InputRadio
            control={control}
            id="onFamilyHome"
            label={t('page.collection.manage.labels.higlighted')}
            tooltip={
              <Tooltip
                id="higlightedTooltip"
                children={
                  <Trans
                    i18nKey="page.collection.manage.tooltips.highlighted"
                    components={{
                      a: (
                        <a
                          href={`https://www.play-in.com/jeux-de-societe`}
                          target="_blank"
                          rel="noreferrer"
                          children=""
                        />
                      )
                    }}
                  />
                }
              />
            }
            display="checkbox"
            enableUnselect={true}
          />
          <InputRadio
            control={control}
            id="onCollectionHome"
            label={t('page.collection.manage.labels.collectionPage')}
            enableUnselect={true}
            display="checkbox"
            tooltip={
              <Tooltip
                id="collectionPageTooltip"
                children={
                  <Trans
                    i18nKey="page.collection.manage.tooltips.collectionPage"
                    components={{
                      a: (
                        <a
                          href={`https://www.play-in.com/series-de-jeux-de-societe`}
                          target="_blank"
                          rel="noreferrer"
                          children=""
                        />
                      )
                    }}
                  />
                }
              />
            }
          />
          <InputRadio
            control={control}
            id="active"
            label={t('page.collection.manage.labels.state')}
            enableUnselect={true}
            display="checkbox"
            labelAccept={t('page.collection.manage.labels.active')}
            labelDecline={t('page.collection.manage.labels.inactive')}
          />
        </SearchFormFields>
        <Button buttonType="submit" icon={SearchIcon} shape="circle" isLoading={isFormLoading} />
      </SearchFormWrapper>
    </CardLayout>
  )
}
