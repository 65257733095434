import { API_ROUTES } from 'constants/configs'
import { CommercialOffer } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type FetchCommercialOffersQueryParams = Api.GetCommercialOffers.RequestQuery
const getCommercialOffersUri = (params: FetchCommercialOffersQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.commercialOffers.root, queryString].join('?')
}

type FetchCommercialOffersResponse = Api.GetCommercialOffers.ResponseBody
export const fetchCommercialOffers = async (params: FetchCommercialOffersQueryParams): Promise<CommercialOffer[]> => {
  const res = await playInApi.get<FetchCommercialOffersResponse>(getCommercialOffersUri(params))

  return res.data['hydra:member']
}
