import Svg from 'components/Svg/Svg'
import { StyledSvgProps } from 'components/Svg/Svg.style'
import styled from 'styled-components'

interface SpinningSvgProps extends StyledSvgProps {
  animationDuration?: string
}

export const SpinningSvg = styled(Svg)<SpinningSvgProps>`
  -moz-animation: spin linear infinite;
  -webkit-animation: spin linear infinite;
  animation: spin linear infinite;
  animation-duration: ${(props) => props.animationDuration ?? '1s'};

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const PageContainerWrapper = styled.div`
  height: 4rem;
`
