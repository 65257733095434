import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Tabs from 'components/Tabs/Tabs'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'

export default function SellList() {
  const { t } = useTranslation()
  return (
    <CardLayout>
      <Title2>{t('page.customer.details.sells.title')}</Title2>
      <Tabs
        idle={true}
        tabs={[
          {
            tabLabel: t('page.customer.details.sells.inProgess'),
            panelComponent: (
              <Text color="secondary" fontStyle="italic">
                Section en cours de développement
              </Text>
            )
          },
          {
            tabLabel: t('page.customer.details.sells.closed'),
            panelComponent: (
              <Text color="secondary" fontStyle="italic">
                Section en cours de développement
              </Text>
            )
          }
        ]}
      />
    </CardLayout>
  )
}
