import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'
import theme from 'theme/theme'

export const CreateSection = styled(CardLayout)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${Title2} {
    margin-bottom: 0;
  }

  @media screen and (${theme.screenWidth.maxLaptopScreen}) {
    ${Title2} {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
`

export const CreateSectionButton = styled(Button)`
  min-width: 20rem;
`
