import { yupResolver } from '@hookform/resolvers/yup'
import { getRetailersUri } from 'api/retailers'
import { getSealedProductsUri } from 'api/sealedProducts'
import FiltersList, { Filter } from 'components/FiltersList/FiltersList'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import MultipleSelect from 'components/Select/MultipleSelect'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { SearchIcon } from 'constants/icons'
import { useStockApiStatus } from 'hooks/useStockStatus'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import { StockStatus } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { hasOneOfFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import { StockManagementFormLayout, SubmitButton } from './StockManagement.style'

export type FormData = {
  billNumber?: string
  retailer?: string
  status?: string[]
  id?: number
  product?: string
}

const schema = yup
  .object()
  .shape({
    billNumber: yup.string().trim(),
    retailer: yup.string().trim(),
    product: yup.string().trim(),
    id: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
    status: yup.array()
  })
  .test(hasOneOfFieldsMethod())

interface Props {
  onSubmit: SubmitHandler<FormData>
  isLoading: boolean
  searchParam?: string
}

export default function StockManagementForm({ onSubmit, isLoading, searchParam }: Props) {
  const { t } = useTranslation()
  const { options: statusOptions, getMultipleStatusFilters } = useStockApiStatus()
  const [product, setProduct] = useState<SealedProduct>()

  const [selectedRetailer, setSelectedRetailer] = useState<string | undefined>()
  const [filters, setFilters] = useState<Filter[]>(
    getMultipleStatusFilters([StockStatus.Created, StockStatus.Validated], 'status')
  )

  const {
    handleSubmit,
    formState: { touchedFields, errors },
    control,
    register
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      status: [StockStatus.Created, StockStatus.Validated]
    }
  })

  const handleForm = (formData: FormData) => {
    const { billNumber, status, retailer } = formData
    setFilters([
      ...(status ? getMultipleStatusFilters(status, 'status') : []),
      ...(billNumber ? [{ id: 'billNumber' as keyof FormData, label: billNumber }] : []),
      ...(retailer ? [{ id: 'retailer' as keyof FormData, label: selectedRetailer || '' }] : []),
      ...(product ? [{ id: 'retailer' as keyof FormData, label: product.name! }] : [])
    ])

    onSubmit(formData)
  }

  const getSearchFilter = useCallback(
    (value: string) => ({ id: 'search', label: t('page.order.manage.filters.search', { value }) }),
    [t]
  )

  useEffect(() => {
    if (searchParam) setFilters([getSearchFilter(searchParam)])
  }, [getSearchFilter, searchParam])

  return (
    <CardLayout>
      <Title2>{t('page.stock.management.filter_title')}</Title2>
      <StockManagementFormLayout onSubmit={handleSubmit(handleForm)}>
        <MultipleSelect
          label={t('common.label.status')}
          options={statusOptions}
          control={control}
          id="status"
          placeholder={t('common.placeholder.choose_status')}
          className="select-status"
        />
        <Input id="id" register={register} label={t('common.label.stockId')} />
        <Input id="billNumber" register={register} label={t('common.label.invoiceId')} />
        <InputAutoComplete
          label={t('common.label.retailer')}
          control={control}
          labelKey="fullName"
          valueKey="@id"
          id="retailer"
          getUrlFromSearch={(search) => getRetailersUri({ search })}
          isTouched={touchedFields.retailer}
          placeholder={t('common.placeholder.search_retailer')}
          onEntitySelected={(entity) => entity && setSelectedRetailer(entity['fullName'])}
        />
        <InputAutoComplete
          label={t('common.label.product')}
          getUrlFromSearch={(search) =>
            getSealedProductsUri({
              search,
              'order[name]': SortingDirection.Asc
            })
          }
          labelKey="name"
          valueKey="id"
          control={control}
          id="product"
          placeholder={t('common.placeholder.product')}
          isTouched={touchedFields.product}
          onEntitySelected={setProduct}
          tooltip={
            <Tooltip id="masterProductTooltip">
              <Text>{t('page.stock.management.tooltips.product')}</Text>
            </Tooltip>
          }
        />
        <SubmitButton shape="circle" icon={SearchIcon} buttonType="submit" isLoading={isLoading} />
      </StockManagementFormLayout>
      <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')} />
      <FiltersList filters={filters} disableClick={true} />
    </CardLayout>
  )
}
