import { yupResolver } from '@hookform/resolvers/yup'
import { getCardEditionsUri } from 'api/cardEditions'
import { FetchCardProducts, useGetCardProducts } from 'api/cardProducts'
import { getCardsUri } from 'api/cards'
import { postOrderEntry } from 'api/orderEntries'
import { GetOrderByIdResponse } from 'api/orders'
import Button from 'components/Button/Button'
import { TextSeparator } from 'components/Separator/Separator.style'
import Svg from 'components/Svg/Svg'
import { Text, TextEllipsis } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { CardIcon, MoreIcon } from 'constants/icons'
import { useCardDeclinations } from 'hooks/entityHooks/cardHooks'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { CardProduct, OrderEntry } from 'types/entities'
import * as yup from 'yup'
import {
  CardOption,
  CardOptions,
  CardRowContainer,
  EditionSelect,
  FieldsContainer,
  FlagIcon,
  Form,
  IconContainer,
  RowContainer,
  SmallAutoComplete,
  SmallInputNumber
} from './OrderEntriesTable.styles'

type CardFormData = {
  cardName?: string
  cardEdition?: string
  card?: string
  quantity: number
}

const schema = yup.object().shape({
  card: yup.string().trim().required(),
  cardName: yup.string().trim().required(),
  cardEdition: yup.string().trim(),
  quantity: yup.number().moreThan(0).required()
})

const getCardQuantity = (cardProduct?: FetchCardProducts) => {
  return cardProduct?.productStores?.find((productStore) => productStore?.store?.['@id'] === WAREHOUSE_STORE_ID)
    ?.quantity
}

type Props = {
  order: GetOrderByIdResponse
}

const OrderEntryCardForm = ({ order }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const getCardDeclinationWording = useCardDeclinations()

  const getCardDeclinations = ({ foil, inked, signed, firstEdition, scanId }) => {
    const labels = getCardDeclinationWording({ foil, inked, signed, firstEdition, scanId })

    return (
      <TextSeparator separator="-">
        {labels.map((label, index) => (
          <Text key={[label, index].join('-')} fontStyle="italic" fontWeight="light">
            {label}
          </Text>
        ))}
      </TextSeparator>
    )
  }

  const getCardOption = (cardProduct: FetchCardProducts) => {
    const cardQuantity = getCardQuantity(cardProduct)
    const flagCode = _.capitalize(cardProduct.cardData?.declination?.lang?.code)

    return {
      element: (
        <CardOption>
          <TextEllipsis>{cardProduct.cardData?.variation?.edition?.name}</TextEllipsis>
          <Text fontWeight="light" fontStyle="italic">
            {cardProduct.cardData?.variation?.name}
          </Text>
          <FlagIcon
            code={cardProduct.cardData?.declination?.lang?.isoCode || undefined}
            title={flagCode}
            fallback={<Text>{flagCode}</Text>}
          />
          <Text>{cardProduct.cardData?.declination?.grading?.name}</Text>
          <TextSeparator separator="-">
            {getCardDeclinations({
              firstEdition: cardProduct.cardData?.declination?.firstEdition,
              foil: cardProduct.cardData?.declination?.foil,
              inked: cardProduct.cardData?.declination?.inked,
              scanId: cardProduct.cardData?.declination?.scan?.id,
              signed: cardProduct.cardData?.declination?.signed
            })}
          </TextSeparator>
          <TextNumber
            value={cardProduct.price}
            fontWeight="semiBold"
            decimalScale={2}
            suffix={`\u00A0€\u00A0(${cardQuantity})`}
          />
        </CardOption>
      ),
      isDisabled: cardQuantity === 0 || cardProduct.price === 0
    }
  }

  const {
    handleSubmit,
    control,
    setValue,
    register,
    trigger,
    watch,
    formState: { touchedFields: cardTouchedFields, errors },
    reset
  } = useForm<CardFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      quantity: 1
    }
  })

  const [cardEdition, cardName, cardProduct] = watch(['cardEdition', 'cardName', 'card'])

  const {
    data: cardProducts,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetched: isCardProductFetched
  } = useGetCardProducts(
    {
      'cardData.variation.edition': cardEdition,
      'cardData.variation.cardProperties': cardName,
      'onlineQuantity[gt]': '0',
      'price[gt]': '0',
      'order[cardData.variation.edition.releasedAt]': 'desc'
    },
    { enabled: !!cardName }
  )

  const selectedCardProduct = cardProducts.find((card) => card['@id'] === cardProduct)

  const { mutate: addProduct } = useMutation(
    (body: CardFormData) =>
      postOrderEntry({
        order: order['@id']!,
        product: body.card,
        quantity: body.quantity
      }),
    {
      onSuccess: (newEntry) => {
        queryClient.setQueriesData<OrderEntry[]>(['orderEntries', order.id], (prevEntries) => [
          ...(prevEntries || []),
          newEntry
        ])
      }
    }
  )

  const onSubmit = (formData: CardFormData) => {
    addProduct(formData)
    reset()
  }

  const formatCardNameOption = (card: CardProduct, onClick: () => void) => (
    <CardOptions onClick={onClick}>
      <Text>{card.name}</Text>
      <Text fontStyle="italic">{`(${card.englishName})`}</Text>
    </CardOptions>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FieldsContainer>
        <RowContainer>
          <IconContainer>
            <Svg svg={CardIcon} size="2em" />
          </IconContainer>
          <SmallAutoComplete
            id="cardName"
            className={errors.cardName ? 'is-invalid' : ''}
            placeholder={t('page.order.detail.orderEntries.table.placeholders.cardName')}
            labelKey="name"
            valueKey="@id"
            control={control}
            isTouched={cardTouchedFields.cardName}
            getUrlFromSearch={(search) => getCardsUri({ search: search, 'variations.edition': cardEdition })}
            formatOption={formatCardNameOption}
          />
          <SmallAutoComplete
            id="cardEdition"
            placeholder={t('page.order.detail.orderEntries.table.placeholders.cardEdition')}
            labelKey="name"
            valueKey="@id"
            control={control}
            isTouched={cardTouchedFields.cardEdition}
            getUrlFromSearch={(search) => getCardEditionsUri({ search, 'order[releasedAt]': 'desc' })}
          />
        </RowContainer>
        {isCardProductFetched && (
          <CardRowContainer>
            <EditionSelect
              id="card"
              className={errors.card ? 'is-invalid' : ''}
              placeholder={t('page.order.detail.orderEntries.table.placeholders.cardProduct')}
              emptyOptionLabel={t('page.order.detail.orderEntries.table.emptyCardProduct')}
              enableUnselect={false}
              autoOpen={true}
              options={
                !cardName
                  ? []
                  : cardProducts.map((cardProduct) => {
                      const cardOption = getCardOption(cardProduct)
                      return {
                        label: cardOption.element,
                        value: cardProduct['@id']!,
                        disabled: cardOption.isDisabled
                      }
                    })
              }
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              control={control}
            />
            <SmallInputNumber
              id="quantity"
              className={errors.quantity ? 'is-invalid' : ''}
              max={getCardQuantity(selectedCardProduct) ?? 1}
              register={register}
              setValue={setValue}
              trigger={trigger}
            />
            <Button shape="circle" icon={MoreIcon} buttonType="submit" iconColor="white" />
          </CardRowContainer>
        )}
      </FieldsContainer>
    </Form>
  )
}

export default OrderEntryCardForm
