import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button/Button'
import FiltersList from 'components/FiltersList/FiltersList'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { SearchIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProductCreationRequestStatus } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { SearchFormLayout, SearchInputWrapper, SearchSelectWrapper } from '../ProductRequestsManage.style'
import useRequestFilters from '../utils/useRequestFilters'

const schema = yup.object({
  name: yup.string().trim(),
  createdBy: yup.string(),
  status: yup.mixed<ProductCreationRequestStatus | ''>().oneOf([...Object.values(ProductCreationRequestStatus), ''])
})

export interface SearchSheetsInputs extends yup.InferType<typeof schema> {}

interface Props {
  onSubmit: (formData: SearchSheetsInputs) => void
  isLoading?: boolean
}
export default function SearchSheetsForm({ onSubmit, isLoading }: Props) {
  const { t } = useTranslation()
  const { searchFilters, handleFilters, sheetStatusOptions, collaboratorOptions, collaboratorOptionsPagination } =
    useRequestFilters()

  const { handleSubmit, control, register } = useForm<SearchSheetsInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      status: ProductCreationRequestStatus.Created
    }
  })

  return (
    <CardLayout>
      <SearchFormLayout
        onSubmit={handleSubmit((formData: SearchSheetsInputs) => {
          handleFilters(formData)
          onSubmit(formData)
        })}
      >
        <SearchInputWrapper>
          <Input
            register={register}
            id="name"
            label={t('common.label.productName')}
            placeholder={t('common.placeholder.product')}
          />
        </SearchInputWrapper>

        <SearchSelectWrapper>
          <Select
            options={collaboratorOptions}
            control={control}
            label={t('common.label.collaborator')}
            id="createdBy"
            placeholder={t('common.select.defaultOptions.collaboratorsChoose')}
            enableUnselect
            tooltip={
              <TextTooltip
                id="selectCollaboratorTooltip"
                text={t('page.productRequest.manage.tooltips.collaborator')}
              />
            }
            {...collaboratorOptionsPagination}
          />
        </SearchSelectWrapper>

        <SearchSelectWrapper>
          <Select
            label={t('page.productRequest.manage.labels.state')}
            options={sheetStatusOptions}
            control={control}
            placeholder={t('common.select.defaultOptions.status')}
            id="status"
            enableUnselect
          />
        </SearchSelectWrapper>

        <Button buttonType="submit" shape="circle" icon={SearchIcon} isLoading={isLoading} />
      </SearchFormLayout>
      <FiltersList filters={searchFilters} disableClick={true} />
    </CardLayout>
  )
}
