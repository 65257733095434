import { useGetStoreById } from 'api/stores'
import { PageLoader } from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import Tabs from 'components/Tabs/Tabs'
import MainTitle from 'components/Title/MainTitle'
import CanAccess from 'features/Permissions/CanAccess'
import useAuth from 'hooks/useAuth'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { RoleAction } from 'types/playInApiInterfaces'
import StoreInformationsTab from './StoreInformationsTab/StoreInformationsTab'
import StoreManageTab from './StoreManageTab/StoreManageTab'
import StoreSeoTab from './StoreSeoTab/StoreSeoTab'
import StoreSeoTabDisplay from './StoreSeoTab/StoreSeoTabDisplay'
interface StoreContextProps {
  isMyStore: boolean
}
export const StoreContext = createContext<StoreContextProps>({
  isMyStore: false
})
export default function StoreDetails() {
  const { id: storeId } = useParams()
  const { me } = useAuth()
  const { t } = useTranslation()

  const { data: store, isLoading } = useGetStoreById(storeId!)

  const isMyStore = !!me?.stores?.find((e) => e['@id'] === store?.['@id'])

  return (
    <StoreContext.Provider value={{ isMyStore }}>
      <Section>
        {isLoading ? (
          <>
            <PageLoader />
          </>
        ) : (
          store && (
            <>
              <MainTitle title={store.name} />
              <Tabs
                tabs={[
                  {
                    tabLabel: t('page.stores.details.storeManage.title'),
                    panelComponent: <StoreManageTab store={store} />,
                    noStyle: true
                  },
                  {
                    tabLabel: t('page.stores.details.infos.title'),
                    panelComponent: <StoreInformationsTab store={store} />,
                    noStyle: true
                  },
                  {
                    tabLabel: t('page.stores.details.seo.title'),
                    panelComponent: (
                      <CanAccess
                        permissions={[RoleAction.ROLE_ACTION_STORE_EDIT]}
                        allowedComponent={<StoreSeoTab store={store} />}
                        deniedComponent={<StoreSeoTabDisplay store={store} />}
                        deniedExtraCondition={!isMyStore}
                      />
                    ),
                    noStyle: true
                  }
                ]}
              />
            </>
          )
        )}
      </Section>
    </StoreContext.Provider>
  )
}
