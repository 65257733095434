import SingleInputForm from 'hooks/useSingleInputForm'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderEntry } from 'types/entities'
import { number } from 'yup'

interface Props {
  entry: OrderEntry
  handleQuantity: (entry: OrderEntry, quantity: number) => void
  disabled?: boolean
}
export default function EntryQuantity({ entry, handleQuantity, disabled }: Props) {
  const { t } = useTranslation()

  //Child pack (entry with parentPack) can be set to 0
  const min = entry?.parentPack ? 0 : 1
  const onSubmit = useCallback(
    async ({ inputValue }) => {
      handleQuantity(entry, parseInt(inputValue))
    },
    [handleQuantity, entry]
  )
  const fieldSchema = number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .max(entry?.quantity!, t('page.order.detail.modals.splitOrder.validation.max', { quantity: entry.quantity }))
    .min(min, t('page.order.detail.modals.splitOrder.validation.min', { quantity: min }))
    .required(t('page.order.detail.modals.splitOrder.validation.required'))

  return (
    <SingleInputForm
      type="number"
      onSubmit={onSubmit}
      defaultValue={disabled ? undefined : entry.quantity}
      disabled={disabled || (!entry.parentPack && entry.quantity === 1)}
      mode="onChange"
      min={min}
      max={entry.quantity}
      fieldSchema={fieldSchema}
    />
  )
}
