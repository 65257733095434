import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { MoreIcon } from 'constants/icons'
import { useTranslation } from 'react-i18next'
import { Banner } from '../BannerManagement'
import { CatalogCard, CatalogGrid, CenteredButton } from '../BannerManagement.styles'
import CatalogItem from './CatalogItem'

interface Props {
  handleAdd: (banner: Banner) => void
}
export default function BannerSelector({ handleAdd }: Props) {
  const { t } = useTranslation()

  const availableBanners: Banner[] = [
    {
      id: 'banner1',
      src: 'banner/banner.png'
    },
    {
      id: 'banner2',
      src: 'banner/banner2.jpg'
    },
    {
      id: 'banner3',
      src: 'banner/banner3.jpg'
    },
    {
      id: 'bannerFormat',
      src: 'banner/test.jpg'
    }
  ]

  return (
    <CatalogCard>
      <Title2>{t('page.banner.management.catalog.title')}</Title2>
      <div>
        <Text fontWeight="light">{t('page.banner.management.catalog.description')}</Text>
        <Text fontWeight="light">{t('page.banner.management.catalog.disclaimer')}</Text>
      </div>

      <CatalogGrid>
        {availableBanners.map((banner, index) => (
          <CatalogItem key={index} banner={banner} handleAdd={handleAdd} />
        ))}
      </CatalogGrid>

      <CenteredButton icon={MoreIcon} variant="white">
        {t('common.button.nextPage')}
      </CenteredButton>
    </CatalogCard>
  )
}
