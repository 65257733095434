import { yupResolver } from '@hookform/resolvers/yup'
import { editOrder, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import { useOrderShippingModes } from 'api/shippingModes'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import { FormattedNumberComponent } from 'components/InputFormattedNumber/InputFormattedNumber'
import { DefaultContainer } from 'components/InputFormattedNumber/InputFormattedNumber.styles'
import InputRadio from 'components/InputRadio/InputRadio'
import { BlockLayout, RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { LabelStyled } from 'components/Layouts/TextLayout/TextLayout.style'
import Loader from 'components/Loader/Loader'
import { Text, TextEllipsis } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { PlayinPIcon, SaveIcon, WarehouseIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { ShippingModeOption, useShippingModeFilters } from 'hooks/useSelectOptions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { OrderStatus, ShippingTypeName } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import ShippingModeSelect from './ShippingModeSelect'
import {
  DangerLabelInputRadioContainer,
  DiscountPriceSpan,
  LabelOption,
  ShippingPrice,
  StockBlock,
  StockLabel
} from './ShippingSection.style'

interface ShippingSectionProps {
  order?: GetOrderByIdResponse
}

interface ShippingSectionFormInputs {
  shippingMode: string | null
  prioritySend: boolean
}

const schema = yup.object().shape({
  shippingMode: yup.string().trim(),
  prioritySend: yup.boolean().required()
})

export function getCountryFrom(order?: GetOrderByIdResponse) {
  if (order?.address?.relatedRelay) {
    return order?.address?.relatedRelay.country?.id
  }
  return order?.address?.recipientCountry?.id
}

function ShippingSection({ order }: ShippingSectionProps) {
  const { t } = useTranslation()
  const { mutate, isLoading: isEditLoading } = useMutation((body: ShippingSectionFormInputs) =>
    editOrder(body, order?.id!)
  )
  const hasEntries = !!order?.entries?.length

  const { query: shippingModeQuery } = useOrderShippingModes(order)

  const { isFetching, shippingModeOptions, getShippingModeTypeFrom } = useShippingModeFilters(shippingModeQuery, {
    enabled: !!order && !!shippingModeQuery
  })

  const isFastShipping = order?.shippingMode?.shippingType === ShippingTypeName.FAST
  const isUnknownShipping = (value?: string) => !!value && !shippingModeOptions.map((el) => el.value).includes(value)

  const { handleSubmit, control, watch, reset } = useForm<ShippingSectionFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      shippingMode: order?.shippingMode?.['@id'],
      prioritySend: !!order?.prioritySend
    }
  })

  useEffect(() => {
    reset({
      shippingMode: order?.shippingMode?.['@id'] || null,
      prioritySend: !!order?.prioritySend
    })
  }, [reset, order?.id, order?.shippingMode, order?.prioritySend])

  const hasShippingDiscount = (option: ShippingModeOption): boolean =>
    order?.shippingMode?.['@id'] === option.value && option.price !== order?.shippingFees

  const formattedShippingModeOptions = [
    ...shippingModeOptions,
    // For old shippingModes, current value is not included in shippingModeOptions
    // We must add a new option for this deprecated shippingMode
    // If shipping mode is updated, this deprecateds option will disapear
    ...(isUnknownShipping(order?.shippingMode?.['@id'])
      ? [
          {
            label: order?.shippingMode?.name ?? '',
            value: order?.shippingMode?.['@id'],
            price: order?.shippingFees ?? 0
          }
        ]
      : [])
  ].map((option) => ({
    ...option,
    label: (
      <LabelOption>
        <TextEllipsis fontWeight="bolder">{option.label}</TextEllipsis>

        <ShippingPrice>
          <FormattedNumberComponent
            value={option.price}
            decimalScale={2}
            prefix=""
            suffix={'\u00a0€'}
            ContainerComponent={hasShippingDiscount(option) ? DiscountPriceSpan : DefaultContainer}
            color={hasShippingDiscount(option) ? 'secondary' : 'black'}
            fontWeight={hasShippingDiscount(option) ? 'light' : 'regular'}
          />
          {hasShippingDiscount(option) && (
            <FormattedNumberComponent value={order?.shippingFees} decimalScale={2} prefix="" suffix={'\u00a0€'} />
          )}
        </ShippingPrice>
      </LabelOption>
    )
  }))

  const queryClient = useQueryClient()
  const prioritySend = watch('prioritySend')
  const shippingMode = watch('shippingMode')

  const onSubmit = (data: ShippingSectionFormInputs) => {
    mutate(
      {
        prioritySend: data.prioritySend,
        shippingMode: data.shippingMode || null // unselect set shippingMode to empty string => transform to null to remove it
      },
      {
        onSuccess: (updatedOrder) => {
          const key = orderByIdQueryKey(`${order?.id}`)
          queryClient.setQueryData(key, updatedOrder)
        },
        onError: () => {
          // Reset form to previous state
          reset({
            shippingMode: order?.shippingMode?.['@id'],
            prioritySend: !!order?.prioritySend
          })
        }
      }
    )
  }

  const isTreated = [
    OrderStatus.Canceled,
    OrderStatus.CanceledRequested,
    OrderStatus.Send,
    OrderStatus.Treated
  ].includes(order?.status!)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title2>{t('page.order.detail.shippingSection.title')}</Title2>
      <BlockLayout>
        <ShippingModeSelect
          placeholder={isFetching ? <Loader /> : t('common.select.defaultOptions.shippingMode')}
          label={t('common.label.shippingMode')}
          control={control}
          id="shippingMode"
          options={formattedShippingModeOptions}
          disabled={isFetching || !hasEntries}
          tooltipEnabled={!hasEntries}
          className={classnames({
            'is-invalid': hasEntries && !shippingMode
          })}
        />
      </BlockLayout>
      <BlockLayout>
        <DangerLabelInputRadioContainer
          prioritySend={prioritySend || getShippingModeTypeFrom(shippingMode) === ShippingTypeName.FAST}
        >
          <InputRadio
            className={classnames({ checked: getShippingModeTypeFrom(shippingMode) === ShippingTypeName.FAST })}
            display="checkbox"
            control={control}
            id="prioritySend"
            disabled={isFastShipping}
          />
          {prioritySend || getShippingModeTypeFrom(shippingMode) === ShippingTypeName.FAST ? (
            <Text color="danger" fontWeight="bold">
              {t('page.order.detail.prioritySend')}
            </Text>
          ) : (
            <LabelStyled>{t('page.order.detail.prioritySendUnselected')}</LabelStyled>
          )}
        </DangerLabelInputRadioContainer>
      </BlockLayout>
      <RowContainer>
        <StockBlock>
          <StockLabel>{t('common.label.stockOrigin')}</StockLabel>
          <RowContainer>
            {order?.stock?.warehouse ? (
              <WarehouseIcon width="25" height="25" />
            ) : (
              <PlayinPIcon width="25" height="25" />
            )}
            <Text size="1.8rem" fontWeight="bolder">{`${t('common.label.stock')} ${order?.stock?.name}`}</Text>
          </RowContainer>
        </StockBlock>
        <Button
          buttonType="submit"
          shape="circle"
          icon={SaveIcon}
          disabled={!!order?.trackingNumber || isTreated}
          isLoading={isEditLoading}
        />
      </RowContainer>
    </form>
  )
}

export default ShippingSection
