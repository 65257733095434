import { Filter } from 'components/FiltersList/FiltersList'
import { Option } from 'components/Select/Select'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { StockStatus } from 'types/playInApiInterfaces'

export const useStockApiStatus = () => {
  const { t } = useTranslation()

  const getStatusTraduction = useCallback(
    (status: string) => {
      const traductions = {
        [StockStatus.Validated]: t('common.select.stock.stockStatus.validated'),
        [StockStatus.Created]: t('common.select.stock.stockStatus.created'),
        [StockStatus.Canceled]: t('common.select.stock.stockStatus.canceled'),
        [StockStatus.Treated]: t('common.select.stock.stockStatus.treated')
      }

      return traductions[status]
    },
    [t]
  )

  const getMultipleStatusFilters = (statusList: string[], id: string): Filter[] => {
    return statusList.map((status) => ({
      id,
      label: getStatusTraduction(status),
      value: status,
      filterId: `status-${status}`
    }))
  }

  const options: Option[] = Object.values(StockStatus).map((value) => ({
    value: value,
    label: getStatusTraduction(value)
  }))

  return { options, getStatusTraduction, getMultipleStatusFilters }
}
