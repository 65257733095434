import { splitOrder, SplitOrderBody } from 'api/orders'
import Button from 'components/Button/Button'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { ModalButtonWrapper } from 'components/Modal/Modal.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { SplitIcon } from 'constants/icons'
import { Columns } from 'features/Listing/Listing'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { getPath, RouteName } from 'permissions/permissions'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { OrderEntry } from 'types/entities'
import { StyledCell } from '../forms/orderEntriesTable/OrderEntriesTable.styles'
import { DescriptionText } from './BaseModal.style'
import EntryQuantity from './components/EntryQuantity'
import SplitOrderListing from './components/SplitOrderListing'

interface Props extends ModalProps {
  orderId: number
  orderEntries: OrderEntry[]
  columns: Columns<OrderEntry>
  getCellProperties: (entry: OrderEntry) => any
}

export type QtyEntry = {
  quantity: number
  isUpdated?: boolean
  parent?: string
}

const hasUpdatedChildren = (entry: OrderEntry, qtyEntries: Map<string, QtyEntry>): boolean => {
  for (let value of qtyEntries.values()) {
    if (value.parent === entry['@id'] && value.isUpdated) return true
  }
  return false
}
export default function SplitOrderModal({ open, onClose, orderId, orderEntries, columns, getCellProperties }: Props) {
  const { t } = useTranslation()
  const [entriesQty, setEntriesQty] = useState<Map<string, QtyEntry>>(new Map())
  const navigate = useNavigate()

  const handleQuantity = useCallback((entry: OrderEntry, quantity: number) => {
    setEntriesQty(
      (prev) =>
        new Map(
          prev.set(entry['@id']!, {
            quantity,
            parent: typeof entry?.parentPack === 'string' ? entry?.parentPack : undefined,
            isUpdated: entry.quantity !== quantity
          })
        )
    )
  }, [])

  const { mutate: splitOrderMutate, isLoading } = useMutation((body: SplitOrderBody) => splitOrder(body, orderId), {
    onSuccess: (order) => navigate(getPath(RouteName.OrderDetails).replace(':id', `${order.id}`))
  })

  const getQuantity = (id: string): number =>
    entriesQty.get(id)?.quantity ?? orderEntries.find((entry) => entry['@id'] === id)?.quantity ?? 0

  const handleSplit = () => {
    let splitEntries: SplitOrderBody['splitEntries'] = []
    for (let splitEntry of orderEntries) {
      //If entry has updated childrenPack, send them instead of parent entry
      if (hasUpdatedChildren(splitEntry, entriesQty) && !!splitEntry.childPack?.length) {
        splitEntries = [
          ...splitEntries,
          ...splitEntry.childPack.map((childEntry) => ({
            orderEntry: childEntry['@id']!,
            quantityToSplit: entriesQty.get(childEntry['@id']!)?.quantity
          }))
        ]
      } else {
        //Otherwise, send parent entry
        splitEntries.push({
          orderEntry: splitEntry['@id'],
          quantityToSplit: entriesQty.get(splitEntry['@id']!)?.quantity
        })
      }
    }

    splitOrderMutate({ splitEntries: _.filter(splitEntries, (entry) => entry.quantityToSplit !== 0) })
  }

  columns = [
    {
      key: 'quantity',
      name: t('common.label.quantity'),
      decorator: (entry) => {
        return (
          <StyledCell className={getCellProperties(entry).classname}>
            <EntryQuantity
              entry={entry}
              handleQuantity={handleQuantity}
              disabled={hasUpdatedChildren(entry, entriesQty)}
            />
          </StyledCell>
        )
      }
    },
    ...columns?.filter((e) => ['cardOrProduct', 'lang'].includes(e.key)),

    {
      key: 'price',
      name: t('common.label.totalUnitPrice'),
      decorator: (entry) => (
        <StyledCell className={getCellProperties(entry).classname}>
          <TextNumber value={entry.paidPrice! * getQuantity(entry['@id']!)} suffix=" €" decimalScale={2} />
        </StyledCell>
      )
    }
  ]
  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <Title2>{t('page.order.detail.modals.splitOrder.title')}</Title2>
        <DescriptionText color="secondary">{t('page.order.detail.modals.splitOrder.description')}</DescriptionText>
        <SplitOrderListing
          data={orderEntries}
          columns={columns}
          isLoading={false}
          handleQuantity={handleQuantity}
          entryQuantities={entriesQty}
          getCellProperties={getCellProperties}
        />
      </div>
      <ModalButtonWrapper>
        <Button icon={SplitIcon} onClick={handleSplit} isLoading={isLoading} iconColor="white">
          {t('page.order.detail.modals.splitOrder.button')}
        </Button>
        <Button variant="white" onClick={onClose}>
          {t('common.label.cancel')}
        </Button>
      </ModalButtonWrapper>
    </Modal>
  )
}
