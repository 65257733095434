import { LabelStyled } from 'components/Layouts/InputLayout/InputLayout.styles'
import FieldDisplay from 'components/Text/FieldDisplay'
import { Text } from 'components/Text/Text.styles'
import { useTranslation } from 'react-i18next'
import { Days } from 'types/schedule'
import useSchedule from '../hooks/useSchedule'
import {
  AfternoonSchedule,
  FormScheduleSection,
  MorningSchedule,
  ScheduleRow,
  ScheduleWrapper
} from '../StoreInformationForm.style'

interface Props {
  days: Days[]
}

export default function StoreScheduleDisplay({ days }: Props) {
  const { t } = useTranslation()
  const { getDayByIndex } = useSchedule()

  return (
    <FormScheduleSection className="read">
      <ScheduleWrapper>
        <LabelStyled>{t('page.stores.details.labels.schedule')}</LabelStyled>

        {days.map((day, index) => (
          <ScheduleRow key={`day-${index}`}>
            <Text>{getDayByIndex(index)}</Text>
            <MorningSchedule>
              <FieldDisplay value={day.morningOpensAt} placeholder={<>-</>} />
              <FieldDisplay value={day.morningClosesAt} placeholder={<>-</>} />
            </MorningSchedule>
            <AfternoonSchedule>
              <FieldDisplay value={day.afternoonOpensAt} placeholder={<>-</>} />
              <FieldDisplay value={day.afternoonClosesAt} placeholder={<>-</>} />
            </AfternoonSchedule>
          </ScheduleRow>
        ))}
      </ScheduleWrapper>
    </FormScheduleSection>
  )
}
