import { Collapse } from '@mui/material'
import { productCollectionByIdQueryKey } from 'api/collection'
import { deleteCollectionCategory } from 'api/collectionCategories'
import { getCollectionCategoriesKey } from 'app/CollectionDetails/utils'
import Button from 'components/Button/Button'
import Loader from 'components/Loader/Loader'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import {
  DoodleFiveIcon,
  DoodleFourIcon,
  DoodleOneIcon,
  DoodleSevenIcon,
  DoodleSixIcon,
  DoodleThreeIcon,
  DoodleTwoIcon
} from 'constants/doodleIcons'
import { DeleteIcon, EditIcon } from 'constants/icons'
import ConfirmModal from 'features/Modals/ConfirmModal'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { ProductCollection, ProductCollectionCategory } from 'types/entities'
import { SealedProductJsonldProductReadItemProductRead } from 'types/playInApiInterfaces'
import { getIdFromIri } from 'utils/queryParams'
import ProductItem from '../components/ProductItem'
import { ProductCollectionEntry } from '../form/AddProductsForm'
import CategoryForm, { CollectionCategoryFormDatas } from '../form/CategoryForm'
import {
  CategoryItemActions,
  CategoryItemCard,
  CategoryItemProductsWrapper,
  CategoryItemTitle,
  CategoryItemWrapper,
  EditCategoryFormWrapper,
  GridWrapper
} from './EditCategory.style'

interface Props {
  productCollection: ProductCollection
  category: ProductCollectionCategory
  index: number
  isLoading: boolean
  onSubmit: (data: CollectionCategoryFormDatas) => Promise<void>
}

const numberIcons = [
  DoodleOneIcon,
  DoodleTwoIcon,
  DoodleThreeIcon,
  DoodleFourIcon,
  DoodleFiveIcon,
  DoodleSixIcon,
  DoodleSevenIcon
]

const getRank = (product: SealedProductJsonldProductReadItemProductRead) => {
  if (product.baseBox) return 'baseBox'
  if (product.expansionBox) return 'expansionBox'
}

export default function CategoryItem({ category, index, isLoading, onSubmit, productCollection }: Props) {
  const [openForm, toggleForm] = useState(false)
  const [openModal, toggleModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const queryClient = useQueryClient()
  const { id: collectionId } = useParams()
  const { t } = useTranslation()

  const { mutateAsync: deleteAsync } = useMutation(() => deleteCollectionCategory(getIdFromIri(`${category['@id']}`)), {
    onSuccess: () => {
      queryClient.refetchQueries(getCollectionCategoriesKey(collectionId!))
    }
  })

  const handleDelete = async () => {
    setIsDeleting(true)
    await deleteAsync(undefined)
    await queryClient.refetchQueries(productCollectionByIdQueryKey(`${productCollection.id}`))
    setIsDeleting(false)
    toggleModal(false)
  }

  const productList: ProductCollectionEntry[] = _.orderBy(
    category.entries
      ?.filter((entry) => !!entry.product)
      ?.map((entry) => ({
        product: entry.product!,
        rank: getRank(entry.product!)
      })) ?? [],
    // Sort by baseBox & expansionBox Fist, then by product releaseDate, then by name
    [(e) => e.rank === 'baseBox', (e) => e.rank === 'expansionBox', (e) => e.product.releasedAt, (e) => e.product.name],
    ['desc', 'desc', 'asc']
  )

  const handleSubmit = async (data: CollectionCategoryFormDatas) => {
    try {
      await onSubmit(data)
      toggleForm(false)
    } catch {}
  }

  const formRef = useRef<HTMLDivElement>(null)

  const handleEdit = () => {
    const newOpenStatus = !openForm
    toggleForm(newOpenStatus)
    if (newOpenStatus && !!formRef) {
      window.scrollTo({ top: formRef.current?.offsetTop, behavior: 'smooth' })
    }
  }
  return (
    <>
      <Draggable draggableId={category['@id']!} index={index} isDragDisabled={openForm}>
        {(provided) => (
          <CategoryItemWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div>{isLoading ? <Loader size="1.5rem" /> : <Svg size="1.5rem" svg={numberIcons[index]} />}</div>
            <CategoryItemCard>
              <GridWrapper>
                <div>
                  <CategoryItemTitle>{category.name}</CategoryItemTitle>
                  <Text>{category.description}</Text>
                  <CategoryItemProductsWrapper>
                    {productList?.map((entry) => (
                      <ProductItem product={entry.product} rank={entry.rank} key={`item-${entry.product.id}`} />
                    ))}
                  </CategoryItemProductsWrapper>
                </div>
                <CategoryItemActions>
                  <Button variant="white" icon={EditIcon} shape="circle" onClick={handleEdit} />
                  <Button variant="white" icon={DeleteIcon} shape="circle" onClick={() => toggleModal(true)} />
                </CategoryItemActions>
              </GridWrapper>
              <Collapse in={openForm} ref={formRef}>
                <EditCategoryFormWrapper>
                  <CategoryForm
                    onSubmit={handleSubmit}
                    isActive={openForm}
                    isEdit={true}
                    id={`${index}`}
                    defaultValues={{
                      name: category.name,
                      nameEn: category.nameEn ?? '',
                      description: category.description ?? '',
                      descriptionEn: category.descriptionEn ?? '',
                      ownProductsShown: category.ownProductsShown,
                      linkedCategories: category.linkedCategories
                    }}
                    productsList={productList}
                    isFormLoading={isLoading}
                  />
                </EditCategoryFormWrapper>
              </Collapse>
            </CategoryItemCard>
          </CategoryItemWrapper>
        )}
      </Draggable>
      <ConfirmModal
        open={openModal}
        title={t('page.collection.details.editCategory.modal.deleteTitle', { name: category.name })}
        onClose={() => toggleModal(false)}
        callback={handleDelete}
        closeOnCallback={false}
        confirmBtnProps={{
          isLoading: isDeleting
        }}
      />
    </>
  )
}
