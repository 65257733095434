import classNames from 'classnames'
import { MutableRefObject, ReactNode, useEffect, useRef } from 'react'
import theme from 'theme/theme'
import { Tab as StyledTab } from './Tabs.styles'

interface Props {
  tabsContainerRef: MutableRefObject<null>
  onTabChange: (index: number) => void
  isActive: boolean
  index: number
  label?: ReactNode | string
  disabled?: boolean
  className?: string
}

export default function Tab({ tabsContainerRef, onTabChange, isActive, label, index, disabled, className }: Props) {
  const tabRef = useRef(null)

  useEffect(() => {
    if (isActive) {
      let component = tabRef?.current as any
      if (tabsContainerRef && component && window && window.innerWidth <= theme.breakPoints.laptopScreen) {
        let tabsContainer = tabsContainerRef.current as any

        let blockRelativeOffset = component.offsetLeft - tabsContainer.offsetLeft
        let marginLeft = (tabsContainer.offsetWidth - component.offsetWidth) / 2

        tabsContainer.scroll({ left: blockRelativeOffset - marginLeft, top: 0, behavior: 'smooth' })
      }
    }
  }, [isActive, tabRef, tabsContainerRef])
  return (
    <StyledTab
      ref={tabRef}
      label={label}
      onClick={() => onTabChange(index)}
      className={classNames(
        {
          selected: isActive,
          disabled: disabled
        },
        className
      )}
    />
  )
}
