import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import { Title2, Title3 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const ActiveStepLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(70rem, 85rem) minmax(30rem, 35rem);
  gap: 2rem;
  width: 100%;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`

export const CashboxContainer = styled.div`
  max-width: 117rem;
  width: 100%;
`

export const TotalText = styled(Text)`
  font-size: 2.8rem;
  text-align: center;
  padding: 2rem;
`

export const DifferenceText = styled(Text)`
  font-style: italic;
`

interface Props {
  border: boolean
}
export const SectionBottom = styled.div<Props>`
  ${(props) => props.border && `border-top : 1px solid ${(props) => props.theme.colors.grey4};`}
  margin-top: 2rem;
  display: grid;
  gap: 2rem;
  ${(props) => props.border && `padding-top:2rem;`}
`

export const AccountResumeSection = styled.div`
  & > div {
    display: flex;
    justify-content: space-between;
  }
`

export const FundCashboxModalBody = styled.div`
  ul {
    padding-left: 1.375rem;
    li {
      line-height: 1.8;
    }
  }
`

export const CommentForm = styled.form`
  display: grid;
  gap: 2rem;
`

export const CloseCashboxCard = styled(CardLayout)`
  display: grid;
  gap: 2rem;
`

export const CashboxesList = styled.div`
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-top: 2rem;
`

export const ModalTitle2 = styled(Title2)`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 1.6rem;
  margin-bottom: 3rem;
`
export const ModalTitle3 = styled(Title3)`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 1.4rem;
  margin: 0;
`

export const JustCreatedCard = styled(CardLayout)`
  display: grid;
  gap: 2rem;
`
