import { API_ROUTES } from 'constants/configs'
import { EmailType } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type FetchEmailTypesResponse = Api.GetEmailTypes.ResponseBody
export type FetchEmailTypesQueryParams = Api.GetEmailTypes.RequestParams
export type FetchEmailTypesItem = FetchEmailTypesResponse['hydra:member'][number]

export const fetchEmailTypes = async (params?: FetchEmailTypesQueryParams): Promise<EmailType[]> => {
  const res = await playInApi.get<FetchEmailTypesResponse>(API_ROUTES.emailTypes.root, { ...params })
  return res.data['hydra:member']
}
