import classnames from 'classnames'
import TextNumber from 'components/TextNumber/TextNumber'
import { LabelTitle, TitleContainer } from '../StoreLabels.styles'
import { ArrowLabelEnum, getTitleFontSize } from '../storeLabelUtils'
import { HalfMoonRectangle, RectangleContainer, StyledPriceTextDown, StyledPriceTextUp, StyledTextContainer, Subtitle } from './RectangleLabel.styles'

type Props = {
  title: string
  subtitle?: string
  price: number
  arrow?: ArrowLabelEnum
  onClick: () => void
}

const RectangleLabel = ({ title, subtitle, price, arrow, onClick }: Props) => {
  return (
    <RectangleContainer onClick={onClick}>
      <HalfMoonRectangle className={classnames(arrow && arrow === ArrowLabelEnum.down ? 'down' : 'up')}/>
      <TextNumber
        value={price}
        color="white"
        fontWeight="semiBold"
        size="4.4rem"
        decimalScale={2}
        suffix={`\u00a0€`}
        ContainerComponent={arrow && arrow === ArrowLabelEnum.down ? StyledPriceTextDown : StyledPriceTextUp}
      />

      <StyledTextContainer className={classnames(arrow && arrow === ArrowLabelEnum.down ? 'down' : 'up')}>
        <TitleContainer>
          <LabelTitle
            className={classnames(subtitle ? 'clamp-2' : 'clamp-3', 'blue')}
            fontWeight="medium"
            size={getTitleFontSize(title.trim(), subtitle)}
          >
            {title.trim()}
          </LabelTitle>
        </TitleContainer>
        {subtitle && <Subtitle size="1.6rem">{subtitle.trim()}</Subtitle>}
      </StyledTextContainer>
    </RectangleContainer>
  )
}

export default RectangleLabel
