import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InputLayout, { LayoutProps } from '../Layouts/InputLayout/InputLayout'
import { Text } from '../Text/Text.styles'
import { Checkbox, CheckboxContainer, CheckboxItem, ChoiceContainer, Dot, Radio } from './InputRadio.styles'

interface RadioButtonsProps extends LayoutProps {
  onChange: (val: string | boolean | undefined) => void
  value?: any
  myRef: any
  isInvalid?: boolean
  labelAccept?: string
  labelDecline?: string
  display: 'radio' | 'checkbox'
  disabled?: boolean
  acceptValue?: string | boolean
  declineValue?: string | boolean
  enableUnselect?: boolean
  swapOpts?: boolean
}

function RadioButtons({
  label,
  tooltip,
  layout,
  className,
  onChange,
  value,
  myRef,
  labelAccept,
  labelDecline,
  disabled = false,
  display,
  labelComponent,
  acceptValue = true,
  declineValue = false,
  enableUnselect = false,
  swapOpts
}: RadioButtonsProps) {
  const { t } = useTranslation()
  const [radioState, setRadioState] = useState(value)

  useEffect(() => {
    setRadioState(value)
  }, [value])

  const yes = useMemo(
    () => ({ name: labelAccept ?? t('common.radioButton.yes'), value: acceptValue }),
    [t, labelAccept, acceptValue]
  )
  const no = useMemo(
    () => ({ name: labelDecline ?? t('common.radioButton.no'), value: declineValue }),
    [t, labelDecline, declineValue]
  )

  const radioButtons = (swapOpts ? [no, yes] : [yes, no]).map((option) => (
    <ChoiceContainer
      key={option.name}
      className={disabled ? 'disabled' : ''}
      onClick={() => {
        setRadioState(option.value)
        onChange(option.value)
      }}
    >
      <Radio className={className}>
        <Dot className={classNames({ checked: option.value === radioState }, className)} />
      </Radio>
      <Text color={disabled ? 'secondary2' : 'black'}>{option.name}</Text>
    </ChoiceContainer>
  ))

  const checkboxButton = enableUnselect ? (
    (swapOpts ? [no, yes] : [yes, no]).map((option) => (
      <CheckboxItem key={option.name}>
        <Checkbox
          className={classNames({ checked: option.value === radioState, disabled: disabled }, className)}
          onClick={() => {
            const newVal = option.value === radioState ? undefined : option.value
            setRadioState(newVal)
            onChange(newVal)
          }}
        />
        <span>{option.name}</span>
      </CheckboxItem>
    ))
  ) : (
    <Checkbox
      className={classNames({ checked: radioState, disabled: disabled }, className)}
      onClick={() => {
        const newVal = !radioState
        setRadioState(newVal)
        onChange(newVal)
      }}
    />
  )

  return (
    <InputLayout
      className="no-border"
      inputRef={myRef}
      label={label}
      tooltip={tooltip}
      layout={layout}
      labelComponent={labelComponent}
    >
      <CheckboxContainer>{display === 'radio' ? radioButtons : checkboxButton}</CheckboxContainer>
    </InputLayout>
  )
}

interface ReactHookFormProps<T extends FieldValues> {
  control: Control<T>
}

export interface InputRadioProps<T> extends LayoutProps {
  id: Path<T>
  isInvalid?: boolean
  labelAccept?: string
  labelDecline?: string
  acceptValue?: string | boolean
  declineValue?: string | boolean
  disabled?: boolean
  display?: 'radio' | 'checkbox'
  enableUnselect?: boolean
  swapOpts?: boolean
}

interface Props<T extends FieldValues> extends ReactHookFormProps<T>, InputRadioProps<T> {}

function InputRadio<T extends FieldValues>({
  id,
  className,
  control,
  label,
  layout,
  tooltip,
  labelAccept,
  labelDecline,
  disabled,
  display = 'radio',
  acceptValue,
  declineValue,
  labelComponent,
  enableUnselect,
  swapOpts = false
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => {
        const { ref, ...props } = field
        return (
          <RadioButtons
            display={display}
            className={className}
            label={label}
            layout={layout}
            tooltip={tooltip}
            myRef={ref}
            labelAccept={labelAccept}
            labelDecline={labelDecline}
            disabled={disabled}
            labelComponent={labelComponent}
            acceptValue={acceptValue}
            declineValue={declineValue}
            enableUnselect={enableUnselect}
            swapOpts={swapOpts}
            {...props}
          />
        )
      }}
    />
  )
}

export default InputRadio
