import { API_ROUTES, API_ROUTES_BASES } from 'constants/configs'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

export type FetchPricingTagsParams = Api.GetPricingTags.RequestQuery
const getPricingTagsUri = () => {
  return API_ROUTES.pricingTags.root
}

type FetchPricingTagsResponse = Api.GetPricingTags.ResponseBody['hydra:member'][0]
export const usePaginatedPricingTags = (
  queryKey: QueryKey,
) => usePaginatedQuery<FetchPricingTagsResponse>(queryKey, getPricingTagsUri())

export type GetPricingTagsResponse = Api.GetPricingTags.ResponseBody
type GetPricingTagsQueryParams = Api.GetPricingTags.RequestQuery
export const getPricingTag = async (params: GetPricingTagsQueryParams) => {
  const response = await playInApi.get<GetPricingTagsResponse>(API_ROUTES_BASES.pricingTags, {
    params
  })

  return response.data['hydra:member']
}
