import { yupResolver } from '@hookform/resolvers/yup'
import { getSealedProductsUri } from 'api/sealedProducts'
import Button from 'components/Button/Button'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { Option } from 'components/InputAutoComplete/InputAutoComplete.styles'
import { Text } from 'components/Text/Text.styles'
import { MoreIcon } from 'constants/icons'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import { ProductStoreJsonldProductReadItemProductRead } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { v4 as uuid } from 'uuid'
import * as yup from 'yup'
import { ArrowLabelEnum, LabelFormatEnum, ProductLabel } from '../../storeLabelUtils'
import { Container, Form, NoWrapText, OldProductOptionContainer } from './AddLabelForm.styles'

const schema = yup.object().shape({
  product: yup.string().trim().required()
})

type FormData = {
  product: string
}

type Props = {
  storeId: string
  setActiveLabel: React.Dispatch<React.SetStateAction<ProductLabel | undefined>>
}

export const getDiscountPrice = (productStore?: ProductStoreJsonldProductReadItemProductRead): number | undefined => {
  if (productStore?.discount && productStore.customerSellPrice)
    return productStore.customerSellPrice - productStore.discount
  else if (productStore?.customerDiscount && productStore.customerSellPrice)
    return productStore.customerSellPrice - productStore.customerDiscount
  return undefined
}

const AddLabelForm = ({ storeId, setActiveLabel }: Props) => {
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  })

  const [selectedProduct, setSelectedProduct] = useState<SealedProduct | undefined>()

  const onSubmit = () => {
    const productStore = selectedProduct?.productStores?.find((productStore) => productStore.store?.['@id'] === storeId)
    setActiveLabel({
      id: uuid(),
      product: selectedProduct?.storeLabelTitle! || selectedProduct?.name!,
      price: productStore?.customerSellPrice!,
      discountPrice: getDiscountPrice(productStore),
      format: LabelFormatEnum.rectangle,
      arrow: ArrowLabelEnum.none
    })
  }

  const getProductOptionFormat = (product: SealedProduct, onClick: () => void) => {
    return product?.old ? (
      <Option onClick={onClick}>
        <OldProductOptionContainer>
          <Text> {product.name}</Text>
          <NoWrapText color="secondary" fontStyle="italic" size="1rem">
            {t('common.label.oldProduct')}
          </NoWrapText>
        </OldProductOptionContainer>
      </Option>
    ) : (
      <Option onClick={onClick}>
        <Text> {product.name}</Text>
      </Option>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <InputAutoComplete
          control={control}
          id="product"
          getUrlFromSearch={(search) =>
            getSealedProductsUri({
              'productStores.store': storeId,
              search,
              'exists[secondHandOf]': false,
              'order[old]': SortingDirection.Asc,
              'order[name]': SortingDirection.Asc
            })
          }
          labelKey="name"
          valueKey="@id"
          label={t('common.label.product')}
          onEntitySelected={setSelectedProduct}
          placeholder={t('page.store.labels.print.modal.add.placeholder')}
          formatOption={getProductOptionFormat}
          autofocus
        />
        <Button icon={MoreIcon} shape="circle" buttonType="submit" iconColor="white" />
      </Container>
    </Form>
  )
}

export default AddLabelForm
