import { GetOrderByIdResponse } from 'api/orders'
import CouponCard from 'app/OrderDetails/components/CouponCard/CouponCard'
import PaymentModeCard from 'app/OrderDetails/components/PaymentModeCard/PaymentModeCard'
import PaymentModeDisplay from 'app/OrderDetails/components/PaymentModeCard/PaymentModeDisplay'
import PaymentStatusCard from 'app/OrderDetails/components/PaymentStatusCard/PaymentStatusCard'
import PaymentStatusDisplay from 'app/OrderDetails/components/PaymentStatusCard/PaymentStatusDisplay'
import { useOrderContext } from 'app/OrderDetails/helper'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import CanAccess from 'features/Permissions/CanAccess'
import { RoleAction } from 'types/playInApiInterfaces'
import { PaymentTabCardContainer } from './PaymentTab.style'

interface PaymentTabProps {
  order?: GetOrderByIdResponse
}

export const PaymentTab = ({ order }: PaymentTabProps) => {
  const { inMyStoresOrder } = useOrderContext()

  return (
    <PaymentTabCardContainer>
      <CardLayout>
        <CouponCard order={order} />
      </CardLayout>
      <CanAccess
        permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_PAYMENT_MODE]}
        allowedComponent={
          <>
            <CardLayout>
              <PaymentModeCard order={order} />
            </CardLayout>
            <CardLayout>
              <PaymentStatusCard order={order} />
            </CardLayout>
          </>
        }
        deniedComponent={
          <>
            <CardLayout>
              <PaymentModeDisplay order={order} />
            </CardLayout>
            <CardLayout>
              <PaymentStatusDisplay order={order} />
            </CardLayout>
          </>
        }
        deniedExtraCondition={!inMyStoresOrder}
      />
    </PaymentTabCardContainer>
  )
}

export default PaymentTab
