import { postProductCreationRequest, PostProductCreationRequestBody } from 'api/productCreationRequests'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { useMutation } from 'hooks/useAxiosMutation'
import { getPath, RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import ProductSheetCreateForm from './ProductSheetRequestForm'

export default function ProductSheetRequest() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(
    (body: PostProductCreationRequestBody) => postProductCreationRequest(body),
    {
      onSuccess: () => navigate(getPath(RouteName.ProductRequestManagement))
    }
  )

  return (
    <Section>
      <MainTitle title={t('page.productRequest.create.title')} />
      <ProductSheetCreateForm onSubmit={mutate} isLoading={isLoading} />
    </Section>
  )
}
