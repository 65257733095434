import { HorizontalSeparator } from 'app/OrderDetails/components/OrderInfoCard/OrderInfoCard.style'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import { useTranslation } from 'react-i18next'
import { Litigation } from 'types/entities'
import { OrderJsonldOrderReadOrderReadItem } from 'types/playInApiInterfaces'
import { DeletableLitigationItem } from './DeletableLitigationItem'
import OrderLitigationForm from './OrderLitigationForm'
import { LitigationModalBlock, LitigationModalContainer, OrderLitigationModalTitle } from './OrderLitigationModal.style'

interface Props extends Omit<ModalProps, 'children'> {
  order: OrderJsonldOrderReadOrderReadItem
}

export default function OrderLitigationModal({ open, onClose, order }: Props) {
  const { t } = useTranslation()
  const litigations: Array<Litigation> = order?.litigations ?? []
  return (
    <Modal open={open} onClose={onClose}>
      <LitigationModalContainer>
        <LitigationModalBlock>
          <OrderLitigationModalTitle>{t('page.order.detail.tab.misc.litigation.title')}</OrderLitigationModalTitle>
          {open && <OrderLitigationForm order={order} />}
        </LitigationModalBlock>
        <HorizontalSeparator />
        <LitigationModalBlock>
          <OrderLitigationModalTitle>{t('page.order.detail.tab.misc.litigation.list')}</OrderLitigationModalTitle>
          {litigations.map((litigation) => (
            <DeletableLitigationItem key={`deletable_${litigation['@id']}`} litigation={litigation} order={order} />
          ))}
          {litigations.length < 1 && (
            <Text fontWeight="light" color="secondary">
              {t('page.order.detail.tab.misc.litigation.emptyText')}
            </Text>
          )}
        </LitigationModalBlock>
      </LitigationModalContainer>
    </Modal>
  )
}
