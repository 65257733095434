import { yupResolver } from '@hookform/resolvers/yup'
import { getRetailersUri } from 'api/retailers'
import { createStock } from 'api/stock'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import Select from 'components/Select/Select'
import { CreateIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useMyStoresOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { AddStockFormLayout, SubmitButton } from './AddStockForm.style'
interface AddStockFormData {
  retailer: string
  store: string
}

const schema = yup.object().shape({
  retailer: yup.string().trim().required().nullable(),
  store: yup.string().trim().required()
})

type Props = {
  closeModal: () => void
}

const AddStockForm = ({ closeModal }: Props) => {
  const [atomLocation] = useAtom(defaultStoreAtom)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    handleSubmit,
    formState: { errors, touchedFields },
    control
  } = useForm<AddStockFormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      store: atomLocation?.['@id']
    }
  })

  const { myStoresOptions } = useMyStoresOptions()

  const { mutate: createStockMutation, isLoading } = useMutation(
    (variables: AddStockFormData) => createStock(variables),
    {
      emitDefaultSuccessNotification: false,
      onSuccess: (res) => {
        closeModal()
        navigate(`/stock-details/${res?.id}`)
      }
    }
  )

  return (
    <AddStockFormLayout onSubmit={handleSubmit((formData) => createStockMutation(formData))}>
      <div>
        <Select
          className={errors.store ? 'is-invalid' : ''}
          label={t('common.label.buyer')}
          id="store"
          control={control}
          options={myStoresOptions}
        />
        <BasicFieldValidationError error={errors.store} message={t('form.validation.required.shipper')} />
      </div>
      <div>
        <InputAutoComplete
          className={errors.retailer ? 'is-invalid' : ''}
          label={t('common.label.retailer')}
          labelKey="fullName"
          valueKey="@id"
          getUrlFromSearch={(search) => getRetailersUri({ search: search })}
          id="retailer"
          control={control}
          isTouched={touchedFields.retailer}
          placeholder={t('common.placeholder.search_retailer')}
        />
        <BasicFieldValidationError error={errors.retailer} message={t('form.validation.required.retailer')} />
      </div>

      <SubmitButton icon={CreateIcon} buttonType="submit" isLoading={isLoading}>
        {t('common.button.create')}
      </SubmitButton>
    </AddStockFormLayout>
  )
}

export default AddStockForm
