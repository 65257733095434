import axios, { AxiosError } from 'axios'
import { eventEmitter } from 'contexts/eventEmitter'
import trimDeep from 'deep-trim'
import { useApiNotifications } from 'hooks/useApiNotifications'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { EventType } from 'types/events'
import { CollectionResults } from 'types/playinApi'
import { getJWT } from 'utils/localStorage'

const playInApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

playInApi.interceptors.request.use((config) => {
  const token = getJWT()

  return {
    ...config,
    headers: {
      ...config.headers,
      ...(token && { Authorization: `Bearer ${token}` })
    }
  }
})

playInApi.interceptors.request.use((request) => {
  return { ...request, data: trimDeep(request.data) }
})

playInApi.interceptors.response.use(
  (response) => {
    return response
  },
  (error: AxiosError) => {
    if (401 === error?.response?.status) {
      eventEmitter.emit(EventType.Logout, null)
    }

    return Promise.reject(error?.response)
  }
)

export const fetchCollection = async <T>(uri: string): Promise<CollectionResults<T>> => {
  const res = await playInApi.get(uri)
  return {
    data: res.data['hydra:member'],
    totalItems: res.data['hydra:totalItems'],
    pagination: res.data['hydra:view']
  }
}

export const useDownloadFile = () => {
  const { emitErrorNotification } = useApiNotifications()
  const [isLoading, setIsLoading] = useState(false)

  const downloadFile = useCallback(
    async (uri: string, type: 'post' | 'get' = 'get', data?: any): Promise<void> => {
      setIsLoading(true)

      const apiFn =
        type === 'get'
          ? playInApi.get(uri, {
              responseType: 'blob'
            })
          : playInApi.post(uri, data, {
              responseType: 'blob'
            })

      const res = await apiFn.catch(async (error) => {
        const errorPayload = JSON.parse(await error.data.text())
        emitErrorNotification(error.status, errorPayload)
        setIsLoading(false)
      })

      setIsLoading(false)

      if (res && res.data) {
        const contentDispositionFilename =
          res.headers['content-disposition'] && res.headers['content-disposition'].split('filename=')[1]

        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('target', '_blank')
        link.setAttribute('rel', 'nofollow noreferrer')

        if (contentDispositionFilename) {
          link.download = contentDispositionFilename
        }

        // Append to html link element page
        document.body.appendChild(link)
        // Start download
        link.click()
        // Clean up and remove the link
        link?.parentNode?.removeChild(link)
      }
    },
    [emitErrorNotification]
  )

  const downloadFileDebounce = useMemo(
    () => _.debounce(downloadFile, 1000, { leading: true, trailing: false }),
    [downloadFile]
  )

  return {
    downloadFile: downloadFileDebounce,
    isLoading
  }
}

export default playInApi
