import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const EmailSendModalTitle2 = styled(Title2)`
  font-size: 1.9rem;
`

export const EmailSendFormRowContainer = styled(RowContainer)`
  gap: 2rem;
`

export const ModalColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85rem;
  gap: 1rem;
  max-width: 100%;
  position: relative;
`
