import { resetCustomerPassword } from 'api/customers'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { LockIcon } from 'constants/icons'
import Link from 'features/Permissions/Link/Link'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useMutation } from 'hooks/useAxiosMutation'
import { RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { Customer } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { CollaboratorText, PasswordText, ResetPasswordButton } from '../CustomerDetails.style'

export default function ResetPassword({ customer }: { customer: Customer }) {
  const { t } = useTranslation()
  const { mutate: resetPasswordMutation, isLoading } = useMutation(() => resetCustomerPassword(customer.id!))
  return (
    <CardLayout>
      <Title2>{customer.email}</Title2>
      {customer?.collaboratorAccount && (
        <CollaboratorText>
          {t('page.customer.details.passwordReset.collaboratorAccount')}
          <Text color="darkPurple" fontWeight="bold" className="link">
            <Link route={RouteName.CollaboratorDetails} value={customer.collaboratorAccount.id}>
              {t('page.customer.details.passwordReset.collaboratorAccountLink', {
                firstname: customer.collaboratorAccount.firstname,
                lastname: customer.collaboratorAccount.lastname
              })}
            </Link>
          </Text>
        </CollaboratorText>
      )}
      <PasswordText fontWeight="light" color="secondary">
        {t('page.customer.details.passwordReset.description')}
      </PasswordText>
      <ShouldDisable permissions={[RoleAction.ROLE_ACTION_CUSTOMER_EDIT]}>
        <ResetPasswordButton variant="warning" icon={LockIcon} onClick={resetPasswordMutation} isLoading={isLoading}>
          {t('page.customer.details.passwordReset.button')}
        </ResetPasswordButton>
      </ShouldDisable>
    </CardLayout>
  )
}
