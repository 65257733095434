import styled from 'styled-components'
import { FontWeight } from 'theme/theme'
import InputLayout from '../Layouts/InputLayout/InputLayout'
export const TextareaLayout = styled(InputLayout)`
  height: auto;
`

interface StyledTextAreaProps {
  fontWeight?: keyof FontWeight
  resize?: string
}
export const StyledTextarea = styled.textarea<StyledTextAreaProps>`
  resize: ${(props) => props.resize || 'none'};
  width: 100%;
  border: none;
  padding: 1rem;
  font-size: 1.4rem;
  font-family: inherit;

  font-weight: ${(props) =>
    props?.fontWeight ? props.theme.fontWeight[props.fontWeight] : props.theme.fontWeight.regular};

  &:focus,
  &.is-invalid {
    outline: none;
  }
  &::placeholder {
    font-style: italic;
  }
`
