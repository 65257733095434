import { PatchFidelityPointBody, patchFidelityPoints } from 'api/fidelityPoints'
import ApiResponseIcon from 'components/Icon/ApiResponseIcon'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import Cell from 'components/SortedTable/Cell'
import Tabs from 'components/Tabs/Tabs'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { formatISO } from 'date-fns'
import { Columns } from 'features/Listing/Listing'
import CanAccess from 'features/Permissions/CanAccess'
import { useMutation } from 'hooks/useAxiosMutation'
import { useFidelityCategoriesOptions } from 'hooks/useSelectOptions'
import SingleInputForm from 'hooks/useSingleInputForm'
import _ from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Customer, FidelityPoint } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { formatCalendarDate } from 'utils/dates'
import AddFidelityPointForm from '../forms/AddFidelityPointForm'
import PointsToVoucherForm from '../forms/PointsToVoucherForm'
import { RowCell, TabNameContainer } from './CustomerList.style'
import ActivePoints from './fidelityPoints/ActivePoints'
import UsedPoints from './fidelityPoints/UsedPoints'

export default function FidelityList({ customer }: { customer: Customer }) {
  const { checkMatch } = useCheckMatch()
  const permissions = [RoleAction.ROLE_ACTION_CUSTOMER_POINTS]

  const { t } = useTranslation()

  const [activeCount, setActiveCount] = useState<number | undefined>(undefined)
  const [isActiveTab, setIsActiveTab] = useState<boolean>(false)
  const [updatePointIcon, setUpdatePointIcon] = useState('')

  const { getCategoryTraduction } = useFidelityCategoriesOptions()

  const {
    mutate: updateFidelityPoint,
    isLoading,
    isSuccess,
    isError
  } = useMutation(({ body, id }: { body: PatchFidelityPointBody; id: number }) => patchFidelityPoints(body, id))

  const columns: Columns<FidelityPoint> = [
    {
      name: t('common.label.number'),
      key: 'id',
      decorator: (point: FidelityPoint) => (
        <Cell className={point.waiting ? 'disabled' : ''}>
          <Text color="secondary" fontWeight="light">
            {point.id}
          </Text>
        </Cell>
      )
    },

    {
      name: t('page.customer.details.fidelity.label.remainingPoints'),
      key: 'usedPoints',
      decorator: (point: FidelityPoint) => (
        <Cell className={point.waiting ? 'disabled' : ''}>{point.value! - point.consumed!}</Cell>
      )
    },
    {
      name: t('page.customer.details.fidelity.label.totalPoints'),
      key: 'totalPoint',
      decorator: (point: FidelityPoint) => (
        <Cell className={point.waiting ? 'disabled' : ''}>
          <Text fontStyle="italic">{`(${point.value})`}</Text>
        </Cell>
      )
    },
    {
      name: t('common.label.issue'),
      key: 'createdAt',
      decorator: (point: FidelityPoint) => (
        <Cell className={point.waiting ? 'disabled' : ''}>
          {point.waiting ? t('page.customer.details.fidelity.label.waiting') : formatCalendarDate(point.createdAt)}
        </Cell>
      )
    },
    {
      name: t('common.label.expiration'),
      key: 'expiresAt',
      decorator: (point: FidelityPoint) => (
        <RowCell className={point.waiting ? 'disabled' : ''}>
          {point.waiting ? (
            '-'
          ) : point.active ? (
            <CanAccess
              permissions={permissions}
              allowedComponent={
                <>
                  <SingleInputForm
                    defaultValue={point.expiresAt}
                    type="date"
                    onSubmit={async ({ inputValue }) => {
                      setUpdatePointIcon(point['@id']!)
                      updateFidelityPoint({
                        body: { expiresAt: formatISO(new Date(inputValue)) },
                        id: point.id!
                      })
                    }}
                  />
                  <ApiResponseIcon
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    isError={isError}
                    showIcon={updatePointIcon === point['@id']}
                  />
                </>
              }
              deniedComponent={<div>{formatCalendarDate(point.expiresAt)}</div>}
            />
          ) : (
            formatCalendarDate(point.expiresAt)
          )}
        </RowCell>
      )
    },
    {
      name: t('common.label.comment'),
      key: 'comment',
      decorator: (point: FidelityPoint) => <Cell className={point.waiting ? 'disabled' : ''}>{point.comment}</Cell>
    },
    {
      name: t('common.label.type'),
      key: 'category',
      decorator: (point: FidelityPoint) => (
        <Cell className={point.waiting ? 'disabled' : ''}>{getCategoryTraduction(point.category!)}</Cell>
      )
    },
    {
      name: t('common.label.location'),
      key: 'store',
      decorator: (point: FidelityPoint) => <Cell className={point.waiting ? 'disabled' : ''}>{point?.store?.name}</Cell>
    }
  ]
  return (
    <CardLayout>
      <Title2>{t('page.customer.details.fidelity.title')}</Title2>
      <Tabs
        idle={!checkMatch(permissions)}
        tabs={[
          {
            tabLabel: t('page.customer.details.fidelity.add'),
            panelComponent: <AddFidelityPointForm customer={customer} />,
            disabled: !checkMatch(permissions)
          },
          {
            tabLabel: _.isNumber(activeCount) ? (
              t('page.customer.details.fidelity.current.title', { count: activeCount })
            ) : (
              <TabNameContainer>
                {t('page.customer.details.fidelity.current.title_zero')}
                <Loader />
              </TabNameContainer>
            ),
            panelComponent: (
              <ActivePoints
                customer={customer}
                columns={columns}
                setCount={setActiveCount}
                setIsActive={setIsActiveTab}
              />
            ),
            disabled: !isActiveTab
          },
          {
            tabLabel: t('page.customer.details.fidelity.used.title'),
            panelComponent: <UsedPoints customer={customer} columns={columns} />
          },
          {
            tabLabel: t('page.customer.details.fidelity.transform.title'),
            panelComponent: <PointsToVoucherForm customer={customer} fidelityPoints={activeCount} />,
            disabled: !activeCount || !checkMatch(permissions)
          }
        ]}
      />
    </CardLayout>
  )
}
