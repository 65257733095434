import { API_ROUTES } from 'constants/configs'
import { useQuery, UseQueryOptions } from 'react-query'
import { Tax } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type FetchTaxesResponse = Api.GetTaxes.ResponseBody
const fetchTaxes = async (): Promise<Tax[]> => {
  const res = await playInApi.get<FetchTaxesResponse>(API_ROUTES.taxes.root)

  return res.data['hydra:member']
}

export const useGetTaxes = (
  options?: Omit<UseQueryOptions<FetchTaxesResponse['hydra:member']>, 'queryKey' | 'queryFn'>
) => {
  return useQuery(['taxes'], () => fetchTaxes(), options)
}
