import { CrossIcon } from 'constants/icons'
import { isEmpty, isNil } from 'lodash'
import { ComponentType, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FiltersContainer, StyledChipButton } from './FiltersList.style'

export type AllowedFilterLabel = string | number | ReactElement | undefined | JSX.Element

export type Filter = {
  id: string
  label: AllowedFilterLabel
  filterId?: string | number
  value?: string | number
}

interface Props {
  filters: Filter[]
  showClearAll?: boolean
  onClear?: () => void
  onRemoveFilter?: (filter: Filter) => void
  className?: string
  clearExclusion?: string[]
  disableClick?: boolean
  filterComponent?: ComponentType
}

export default function FiltersList({
  filters = [],
  showClearAll = true,
  onClear,
  onRemoveFilter,
  className,
  clearExclusion,
  disableClick,
  filterComponent
}: Props) {
  const { t } = useTranslation()
  const filtersList = filters.filter((e) => e.label !== '' && !isNil(e.label))

  const clearFilters = () => {
    if (onClear) onClear()
  }
  const FiltersContainerComponent = filterComponent ?? FiltersContainer

  return (
    <>
      {filtersList && !isEmpty(filtersList) && (
        <FiltersContainerComponent className={className}>
          {filters.length >= 2 && showClearAll && !disableClick && (
            <StyledChipButton onClick={clearFilters}>{t('common.label.all_filters')}</StyledChipButton>
          )}
          {filtersList.map((filter) => {
            let filterId = filter?.filterId || String(filter?.id)
            return (
              <StyledChipButton
                onClick={() => (onRemoveFilter ? onRemoveFilter(filter) : {})}
                key={`filter-${filterId}`}
                className={disableClick || clearExclusion?.includes(filter.id as string) ? 'no-clear' : ''}
                icon={disableClick || clearExclusion?.includes(filter.id as string) ? null : CrossIcon}
                buttonType="button"
              >
                {filter.label}
              </StyledChipButton>
            )
          })}
        </FiltersContainerComponent>
      )}
    </>
  )
}
