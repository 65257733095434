import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export type FetchProductCreationRequestsParams = Api.GetProductCreationRequests.RequestQuery
const getProductCreationRequestsUri = (params?: FetchProductCreationRequestsParams) => {
  const qs = stringify(params ?? {})
  return [API_ROUTES.productCreationRequests.root, qs].join('?')
}

type FetchProductCreationRequestsResponse = Api.GetProductCreationRequests.ResponseBody
export const usePaginatedProductCreationRequests = (
  queryKey: QueryKey,
  params?: FetchProductCreationRequestsParams,
  options?: PaginatedHookOptions<FetchProductCreationRequestsResponse['hydra:member'][0]>
) =>
  usePaginatedQuery<FetchProductCreationRequestsResponse['hydra:member'][0]>(
    queryKey,
    getProductCreationRequestsUri(params),
    options
  )

/* -------------------------------------------------------------------------- */
/*                                    POST                                    */
/* -------------------------------------------------------------------------- */

export type PostProductCreationRequestBody = Api.PostProductCreationRequests.RequestBody
type PostProductCreationRequestResponse = Api.PostProductCreationRequests.ResponseBody

export const postProductCreationRequest = async (body: PostProductCreationRequestBody) => {
  const res = await playInApi.post<PostProductCreationRequestBody, AxiosResponse<PostProductCreationRequestResponse>>(
    API_ROUTES.productCreationRequests.root,
    body
  )
  return res.data
}

/* -------------------------------------------------------------------------- */
/*                                    PATCH                                   */
/* -------------------------------------------------------------------------- */

export const validateProductCreationRequest = async (id: number) =>
  await playInApi.patch(
    `${API_ROUTES.productCreationRequests.validate}/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

export const rejectProductCreationRequest = async (id: number) =>
  await playInApi.patch(
    `${API_ROUTES.productCreationRequests.reject}/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
