import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { getDifferenceColor } from '../utils/cashHelper'
import { AccountResumeSection } from './CashboxStep.style'

interface Props {
  resume: Array<{ label: string; total?: number; expectedDifference: number }>
}

export default function AccountResume({ resume }: Props) {
  const { t } = useTranslation()
  return (
    <>
      {resume.map((section, index) => (
        <AccountResumeSection key={`account-section-${index}`}>
          {!_.isNil(section.total) && (
            <div>
              <Text>{section.label}</Text>
              <TextNumber fontWeight="bold" suffix="€" value={section.total} decimalScale={2} />
            </div>
          )}
          <div style={{ fontStyle: 'italic' }}>
            <Text
              fontWeight={section.expectedDifference === 0 ? 'light' : 'regular'}
              color={getDifferenceColor(section.expectedDifference)}
            >
              {t('page.cashbox.accountStep.expectedDifference')}
            </Text>

            <TextNumber
              value={section.expectedDifference}
              prefix={section.expectedDifference > 0 ? '+' : ''}
              fontWeight={section.expectedDifference === 0 ? 'light' : 'regular'}
              color={getDifferenceColor(section.expectedDifference)}
              suffix="€"
              decimalScale={2}
            />
          </div>
        </AccountResumeSection>
      ))}
    </>
  )
}
