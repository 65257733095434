import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useQuery } from 'react-query'
import { Voucher } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

// TODO: If stable values, consider moving it in enums.ts with all remaining values
export enum VoucherCategory {
  RentalGesture = 6,
  CommercialGesture = 9
}

export function getVoucherCategoryUri(category: number) {
  return `${API_ROUTES.voucherCategories.root}/${category}`
}

export type PostVoucherBody = Api.PostVouchers.RequestBody
export const postVoucher = async (body: PostVoucherBody) => {
  const res = await playInApi.post<PostVoucherBody, AxiosResponse<Voucher>>(API_ROUTES.vouchers.root, body)
  return res.data
}

export type PatchVoucherBody = Api.PatchVouchersId.RequestBody
export const patchVoucher = async (body: PostVoucherBody, id: number) => {
  return await playInApi.patch<PostVoucherBody, AxiosResponse<Voucher>>(`${API_ROUTES.vouchers.root}/${id}`, body, {
    headers: {
      'Content-Type': 'application/merge-patch+json'
    }
  })
}

type FetchVouchersQueryParams = Api.GetVouchers.RequestQuery
type FetchVouchersResponse = Api.GetVouchers.ResponseBody
const getVouchersUri = (params: FetchVouchersQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.vouchers.root, queryString].join('?')
}

export const fetchCustomerVouchers = async (customerId: string) => {
  const res = await playInApi.get<FetchVouchersResponse>(getVouchersUri({ customer: customerId, active: true }))
  return res.data['hydra:member']
}

export const usePaginatedVouchers = (
  params: FetchVouchersQueryParams,
  options?: PaginatedHookOptions<FetchVouchersResponse['hydra:member'][0]>
) => usePaginatedQuery<FetchVouchersResponse['hydra:member'][0]>(['vouchers', params], getVouchersUri(params), options)

export const useCustomerVouchers = (customerId?: string | number) => {
  return useQuery(['customer-vouchers', customerId], () => fetchCustomerVouchers(`${customerId!}`), {
    enabled: !!customerId
  })
}

type FetchVoucherCategoriesResponse = Api.GetVoucherCategories.ResponseBody
type FetchVoucherCategoriesQueryParams = Api.GetVoucherCategories.RequestQuery
export const fetchVoucherCategories = async (params: FetchVoucherCategoriesQueryParams) => {
  const res = await playInApi.get<FetchVoucherCategoriesResponse>(API_ROUTES.voucherCategories.root, { params })
  return res.data['hydra:member']
}

export const deleteVoucher = async (id: number) => {
  let res = await playInApi.delete(`${API_ROUTES.vouchers.root}/${id}`)
  return res.data
}
