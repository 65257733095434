import { yupResolver } from '@hookform/resolvers/yup'
import { getCustomersUri, usePaginatedCustomers } from 'api/customers'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import { Dot, Radio } from 'components/InputRadio/InputRadio.styles'
import Loader from 'components/Loader/Loader'
import { Text } from 'components/Text/Text.styles'
import { SearchIcon } from 'constants/icons'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Customer, StoreEvent } from 'types/entities'
import { SortingDirection } from 'types/sorting'
import { hasOneOfFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import {
  CustomerResultItem,
  CustomerResults,
  LargeInput,
  SearchCustomerButtonWrapper,
  SearchCustomerFormLayout,
  SearchCustomerWrapper,
  StyledInput
} from './AddCustomerModal.style'

const schema = yup
  .object({
    firstname: yup.string().trim(),
    lastname: yup.string().trim(),
    email: yup.string().trim()
  })
  .test(hasOneOfFieldsMethod())

interface SearchCustomerInputs extends yup.InferType<typeof schema> {}

interface Props {
  selectedCustomer?: Customer
  setSelectedCustomer: (customer: Customer) => void
  event: StoreEvent
}
export default function SearchCustomerForm({ selectedCustomer, setSelectedCustomer, event }: Props) {
  const [search, setSearch] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<SearchCustomerInputs>({
    resolver: yupResolver(schema)
  })

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage, isIdle } = usePaginatedCustomers(
    search,
    search,
    {
      enabled: !!search,
      onSuccess: (customers) => {
        if (customers.length === 1) {
          const filteredDatas = customers.filter(
            (customer) =>
              !event.customers?.find((e) => e.linkedOrder?.customer?.['@id'] === customer['@id'] && !!e.validated)
          )
          if (filteredDatas.length) setSelectedCustomer(customers[0])
        }
      }
    }
  )

  const customers = data.filter(
    (customer) => !event.customers?.find((e) => e.linkedOrder?.customer?.['@id'] === customer['@id'] && !!e.validated)
  )

  const customersRegistered = data.filter(
    (customer) => event.customers?.find((e) => e.linkedOrder?.customer?.['@id'] === customer['@id'] && !!e.validated)
  )

  const onSubmit = ({ email, firstname, lastname }: SearchCustomerInputs) => {
    setSearch(
      getCustomersUri({
        email,
        name: [lastname, firstname].join(' '),
        'order[lastname]': SortingDirection.Asc,
        'order[firstname]': SortingDirection.Asc
      })
    )
    reset()
  }

  const { t } = useTranslation()

  return (
    <SearchCustomerWrapper>
      <SearchCustomerFormLayout onSubmit={handleSubmit(onSubmit)}>
        <StyledInput
          register={register}
          id="lastname"
          label={t('common.label.lastName')}
          className={classNames({
            'is-invalid': errors['oneOf']
          })}
          autofocus={true}
        />
        <StyledInput
          register={register}
          id="firstname"
          label={t('common.label.firstName')}
          className={classNames({
            'is-invalid': errors['oneOf']
          })}
        />

        <LargeInput
          register={register}
          id="email"
          label={t('common.label.email')}
          className={classNames({
            'is-invalid': errors['oneOf']
          })}
        />
        <Button shape="circle" icon={SearchIcon} buttonType="submit" isLoading={isLoading} />
      </SearchCustomerFormLayout>
      <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')} />
      {!isIdle && (
        <>
          <CustomerResults>
            {isLoading ? (
              <Loader />
            ) : !!customers.length || !!customersRegistered.length ? (
              <>
              {customersRegistered.map((customer, index) => (
                <CustomerResultItem key={`customer-${index}`}>
                  <Text color="secondary2" fontStyle="italic">
                    {customer.fullName + ' ' + customer.email}
                  </Text>
                </CustomerResultItem>
              ))}
              
              {customers.map((customer, index) => (
                <CustomerResultItem key={`customer-${index}`}>
                  <Radio
                    onClick={() => setSelectedCustomer(customer)}
                    className={classNames({ checked: selectedCustomer?.['@id'] === customer['@id'] })}
                  >
                    <Dot className={selectedCustomer?.['@id'] === customer['@id'] ? 'checked' : ''} />
                  </Radio>
                  {customer.fullName && <Text>{customer.fullName}</Text>}
                  <Text color="secondary" fontStyle="italic">
                    {customer.email}
                  </Text>
                </CustomerResultItem>
              ))}
            </>) : (
              <Text color="danger">Aucun résultat</Text>
            )}
          </CustomerResults>

          {hasNextPage && (
            <SearchCustomerButtonWrapper>
              <Button variant="white" isLoading={isFetchingNextPage} onClick={() => fetchNextPage()}>
                {t('common.button.nextPage')}
              </Button>
            </SearchCustomerButtonWrapper>
          )}
        </>
      )}
    </SearchCustomerWrapper>
  )
}
