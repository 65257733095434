import { Skeleton } from '@mui/material'
import { Text } from 'components/Text/Text.styles'
import { CrossIcon } from 'constants/icons'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import { DeleteProductButton, ImageContainer, ImageProductItem, ProductItemWrapper, ProductNameWrapper } from './ProductItem.style'

interface Props {
  product: SealedProduct
  rank?: 'baseBox' | 'expansionBox'
  onDelete?: () => void
}
export default function ProductItem({ product, rank, onDelete }: Props) {
  const { t } = useTranslation()

  return (
    <ProductItemWrapper>
      {onDelete && <DeleteProductButton svg={CrossIcon} color="grey4" onClick={onDelete} />}
      {product.mainImageUrl && (
        <ImageContainer>
          <ImageProductItem
            src={product.mainImageUrl}
            skeleton={<Skeleton width={125} height={125} variant="rectangular" />}
          />
        </ImageContainer>
      )}
      <ProductNameWrapper>
        <Text textAlign="center">{product.name}</Text>
        {rank && (
          <Text fontStyle="italic" fontWeight="light" textAlign="center">
            {rank === 'baseBox'
              ? t('page.collection.details.form.label.rank.baseBox')
              : t('page.collection.details.form.label.rank.expansionBox')}
          </Text>
        )}
      </ProductNameWrapper>
    </ProductItemWrapper>
  )
}
