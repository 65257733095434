import classNames from 'classnames'
import Loader from 'components/Loader/Loader'
import Svg from 'components/Svg/Svg'
import { CrossIcon } from 'constants/icons'
import _ from 'lodash'
import { ComponentType, ReactNode, useMemo } from 'react'
import { StyledButton, StyledButtonProps } from './Button.style'

export interface Props extends StyledButtonProps {
  buttonType?: 'submit' | 'reset' | 'button'
  isLoading?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon?: ComponentType | null
  iconSize?: string
  children?: ReactNode
  className?: string
  form?: string
}

export const ButtonContent = ({ variant, isLoading, icon, children, iconSize, onClick = _.noop, shape }) => (
  <>
    {isLoading ? (
      <Loader size={'1.6rem'} color={['default', 'danger', 'success'].includes(variant) ? 'white' : 'black'} />
    ) : (
      <>
        {icon && <Svg svg={icon} size={iconSize || (shape === 'circle' ? '2rem' : '1.6rem')} onClick={onClick} />}
        {children}
      </>
    )}
  </>
)

export default function Button({
  buttonType = 'button',
  variant = 'default',
  shape,
  size,
  isLoading,
  disabled,
  onClick = _.noop,
  textColor,
  icon,
  fontWeight,
  borderColor,
  children,
  className,
  iconSize,
  iconColor,
  form
}: Props) {
  const debouncedOnClick = useMemo(() => _.debounce(onClick, 300, { leading: true, trailing: false }), [onClick])

  return (
    <StyledButton
      className={className}
      variant={variant}
      size={size}
      shape={shape}
      fontWeight={fontWeight}
      textColor={textColor}
      borderColor={borderColor}
      type={buttonType}
      disabled={disabled}
      onClick={debouncedOnClick}
      isLoading={isLoading}
      iconColor={iconColor}
      form={form}
    >
      <ButtonContent
        isLoading={isLoading}
        icon={icon}
        iconSize={iconSize}
        onClick={debouncedOnClick}
        shape={shape}
        variant={variant}
      >
        {children}
      </ButtonContent>
    </StyledButton>
  )
}

type ExternalLinkButtonProps = Props & React.AnchorHTMLAttributes<HTMLAnchorElement>

export const ExternalLinkButton = ({
  variant = 'default',
  size,
  shape,
  href,
  target,
  rel,
  disabled,
  className,
  iconSize,
  onClick = _.noop,
  isLoading,
  ...buttonAttr
}: ExternalLinkButtonProps) => {
  const debouncedOnClick = useMemo(() => _.debounce(onClick, 300, { leading: true, trailing: false }), [onClick])

  return (
    <StyledButton
      as="a"
      className={classNames(className, { disabled })}
      variant={variant}
      size={size}
      shape={shape}
      href={href}
      target={target}
      rel={rel}
      onClick={debouncedOnClick}
      isLoading={isLoading}
      {...buttonAttr}
    >
      <ButtonContent
        isLoading={isLoading}
        icon={buttonAttr.icon}
        iconSize={iconSize}
        onClick={debouncedOnClick}
        shape={shape}
        variant={variant}
      >
        {buttonAttr.children}
      </ButtonContent>
    </StyledButton>
  )
}

export const ChipsButton = ({
  variant = 'chips',
  shape,
  onClick = _.noop,
  icon = CrossIcon,
  iconSize = '1rem',
  iconPosition = 'right',
  ...buttonAttr
}: Props) => {
  const debouncedOnClick = useMemo(() => _.debounce(onClick, 300, { leading: true, trailing: false }), [onClick])
  return (
    <StyledButton
      variant={variant}
      iconPosition={iconPosition}
      onClick={debouncedOnClick}
      type={buttonAttr.buttonType}
      {...buttonAttr}
    >
      <ButtonContent
        isLoading={buttonAttr.isLoading}
        icon={icon}
        iconSize={iconSize}
        onClick={debouncedOnClick}
        shape={shape}
        variant={variant}
      >
        {buttonAttr.children}
      </ButtonContent>
    </StyledButton>
  )
}
