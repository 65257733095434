import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const Form = styled.form`
  padding-top: 1.5rem;
  padding-bottom: 3.5rem;

  border-bottom: 1px solid ${(props) => props.theme.borderColors.secondary};
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  gap: 3.5rem;
`

export const OldProductOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
`

export const NoWrapText = styled(Text)`
  white-space: nowrap;
`
