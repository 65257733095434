import styled from 'styled-components'

export const ToolTipContent = styled.div`
  width: 38rem;
  padding: 1rem;
`

export const TooltipLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  &.head {
    margin-bottom: 0.5rem;
  }

  &.tail {
    border-top: 1px solid black;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  }
`
