import { yupResolver } from '@hookform/resolvers/yup'
import { patchProductStore, PatchProductStoreBody } from 'api/productStores'
import { ProductStoreFromSealedProductCollection as ProductStore } from 'api/sealedProducts'
import { useStoreZonesOptions } from 'app/ProductDetails/productDetailsUtils'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputNumber from 'components/InputNumber/InputNumber'
import InputRadio from 'components/InputRadio/InputRadio'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import Select from 'components/Select/Select'
import FieldDisplay from 'components/Text/FieldDisplay'
import { Text } from 'components/Text/Text.styles'
import TextNumberFieldDisplay from 'components/Text/TextNumberFieldDisplay'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { ANNECY_STORE_ID, BNF_STORE_ID } from 'constants/configs'
import { SaveIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import StoreSelect from 'features/StoreSelect'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import { useAtom } from 'jotai'
import _, { isNumber } from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { SealedProduct, Store } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import {
  DiscountWrapper,
  FormSection,
  ProductFormGrid,
  ProductStoreWrapper,
  UnavailableProductStoreFormGrid
} from '../../ProductDetailsTab.styles'
import StoreProductStats from './StoreProductStats'

interface StoreInputs {
  sellPrice?: number | null
  discount?: number | null
  discountPercent?: number
  specialDiscount?: boolean
  localPosition?: string | null
  storeZone?: string | null
  alertQuantity?: number | null
}

const schema = yup.object().shape({
  sellPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  discount: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  discountPercent: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  specialDiscount: yup.boolean(),
  localPosition: yup.string().trim().nullable(),
  alertQuantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  storeZone: yup.string().trim().nullable()
})
interface Props {
  productId: string
}

function getProductStore(productStores?: ProductStore[], storeId?: string): ProductStore | undefined {
  return _.find(productStores, (productStore) => productStore.store?.['@id'] === storeId)
}

export default function EditStoreProductForm({ productId }: Props) {
  const { t } = useTranslation()
  const { me } = useAuth()
  const [atomLocation] = useAtom(defaultStoreAtom)

  const [store, setStore] = useState<Store>(atomLocation!)
  const [storeProduct, setStoreProduct] = useState<ProductStore | undefined>()

  const queryClient = useQueryClient()
  const product = queryClient.getQueryData<SealedProduct>(['sealedProductById', productId])

  // Get enable all stores that have a productStore
  const availableStores = (me?.stores || []).map((store) => ({
    label: store.shortName,
    value: store['@id']!
  }))

  const { control, register, trigger, setValue, watch, handleSubmit, reset } = useForm<StoreInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      localPosition: storeProduct?.localPosition,
      storeZone: storeProduct?.storeZone
    }
  })

  const isWarehouse = store.warehouse
  const canEditStockAlert = !(store.warehouse || store['@id'] === BNF_STORE_ID)
  const { checkMatch } = useCheckMatch()

  const [watchLocalPrice, watchDiscountPercent, watchDiscount] = watch(['sellPrice', 'discountPercent', 'discount'])

  useEffect(() => {
    if (!isNumber(watchLocalPrice) || watchLocalPrice === 0) {
      setValue('discount', null)
      setValue('discountPercent', 0)
      setValue('specialDiscount', false)
    } else if (isNumber(watchDiscount)) {
      const percentage = (watchDiscount / watchLocalPrice) * 100
      setValue('discountPercent', Math.trunc(percentage))
    } else {
      setValue('discountPercent', 0)
      setValue('specialDiscount', false)
    }
  }, [watchDiscount, setValue, storeProduct, watchLocalPrice])

  useEffect(() => {
    const newStoreProduct = getProductStore(product?.productStores, store['@id'])
    setStoreProduct(newStoreProduct)
    reset({
      discount: newStoreProduct?.discount,
      discountPercent: newStoreProduct?.discountPercentage,
      sellPrice: newStoreProduct?.sellPrice ?? null,
      specialDiscount: !!newStoreProduct?.specialDiscount,
      localPosition: newStoreProduct?.localPosition,
      alertQuantity: (canEditStockAlert && newStoreProduct?.alertQuantity) || null,
      storeZone: newStoreProduct?.storeZone
    })
  }, [store, product, reset, canEditStockAlert])

  const { mutateAsync: updateProductStore, isLoading: isUpdatingProductStore } = useMutation(
    ({ storeProductId, body }: { storeProductId: number; body: PatchProductStoreBody }) =>
      patchProductStore(storeProductId, { ...body, storeZone: body.storeZone || null }),
    {
      onSuccess: (updatedProductStore, { storeProductId }) => {
        const { store, ...productStoreData } = updatedProductStore
        const productStores = product?.productStores?.map((productStore) =>
          productStore.id === storeProductId
            ? {
                ...productStore,
                ...productStoreData,
                discount: productStoreData.discount ?? null,
                sellPrice: productStoreData.sellPrice ?? null,
                specialDiscount: productStoreData.specialDiscount ?? false,
                storeZone: productStoreData.storeZone ?? null
              }
            : productStore
        )
        queryClient.setQueryData(['sealedProductById', productId], {
          ...product,
          productStores
        })
      }
    }
  )

  const onSubmit = async (formData: StoreInputs) => {
    const { alertQuantity, ...restData } = formData
    const payload: PatchProductStoreBody = {
      ...restData,
      defaultAlertQuantity:
        storeProduct?.alertQuantity !== alertQuantity ? storeProduct?.store?.['@id'] === ANNECY_STORE_ID : undefined,
      alertQuantity: alertQuantity || null
    }

    await updateProductStore({
      storeProductId: storeProduct?.id!,
      body: payload
    })
  }

  const { storeZonesOptions, storeZonesOptionsPagination, getStoreZoneLabel } = useStoreZonesOptions({
    enabled: !!storeProduct,
    store: `${store.id}`
  })

  const canSubmit = checkMatch([
    RoleAction.ROLE_ACTION_PRODUCT_EDIT_STOCK_PLACE,
    RoleAction.ROLE_ACTION_PRODUCT_EDIT_LOCAL
  ])

  if (!storeProduct) {
    return (
      <UnavailableProductStoreFormGrid>
        <>
          <InputLayout className="no-border" label={t('common.label.location')}>
            <StoreSelect store={store} onLocationChange={setStore} storeOptions={availableStores} />
          </InputLayout>
        </>
        <Text fontStyle="italic" fontWeight="light">
          {t('page.product.detail.tabs.productStore.notAvailable', { location: store?.shortName })}
        </Text>
      </UnavailableProductStoreFormGrid>
    )
  }

  return (
    <div>
      <Title2>{t('page.product.detail.tabs.productStore.title', { location: store?.shortName })}</Title2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProductStoreWrapper>
          <ProductFormGrid>
            <FormSection>
              <InputLayout className="no-border" label={t('common.label.location')}>
                <StoreSelect store={store} onLocationChange={setStore} storeOptions={availableStores} />
              </InputLayout>

              <CanAccess
                permissions={[RoleAction.ROLE_ACTION_PRODUCT_EDIT_LOCAL]}
                deniedExtraCondition={isWarehouse}
                allowedComponent={
                  <InputFormattedNumber
                    id="sellPrice"
                    suffix=" €"
                    decimalScale={2}
                    control={control}
                    placeholder="0,00 €"
                    label={t('common.label.sellPrice')}
                  />
                }
                deniedComponent={
                  <TextNumberFieldDisplay
                    label={t('common.label.sellPrice')}
                    value={!isWarehouse ? storeProduct.sellPrice : undefined}
                    suffix=" €"
                    decimalScale={2}
                    fontWeight="medium"
                  />
                }
              />
            </FormSection>

            <FormSection>
              <DiscountWrapper>
                <CanAccess
                  permissions={[RoleAction.ROLE_ACTION_PRODUCT_EDIT_LOCAL]}
                  deniedExtraCondition={isWarehouse}
                  allowedComponent={
                    <>
                      <InputFormattedNumber
                        id="discount"
                        suffix=" €"
                        decimalScale={2}
                        control={control}
                        placeholder="0,00 €"
                        disabled={!watchLocalPrice}
                        label={t('common.label.discount')}
                      />
                      <InputLayout className="no-border" label={t('common.label.discountPercent')}>
                        <TextNumber value={watchDiscountPercent ?? 0} suffix=" %" fontWeight="medium" />
                      </InputLayout>
                    </>
                  }
                  deniedComponent={
                    <>
                      <TextNumberFieldDisplay
                        label={t('common.label.discount')}
                        suffix=" €"
                        decimalScale={2}
                        fontWeight="medium"
                        value={!isWarehouse ? storeProduct.discount : undefined}
                      />
                      <TextNumberFieldDisplay
                        label={t('common.label.discountPercent')}
                        value={(!isWarehouse && storeProduct.discountPercentage) || undefined}
                        suffix=" %"
                        fontWeight="medium"
                      />
                    </>
                  }
                />
              </DiscountWrapper>

              <CanAccess
                permissions={[RoleAction.ROLE_ACTION_PRODUCT_EDIT_LOCAL]}
                deniedExtraCondition={isWarehouse}
                allowedComponent={
                  <InputRadio
                    id="specialDiscount"
                    control={control}
                    disabled={!watchDiscount}
                    label={t('common.label.specialDiscount')}
                  />
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('common.label.specialDiscount')}
                    value={
                      !isWarehouse
                        ? storeProduct?.specialDiscount
                          ? t('common.radioButton.yes')
                          : t('common.radioButton.no')
                        : undefined
                    }
                  />
                }
              />
            </FormSection>
            <FormSection>
              <CanAccess
                permissions={[RoleAction.ROLE_ACTION_PRODUCT_EDIT_STOCK_PLACE]}
                allowedComponent={
                  <Select
                    options={storeZonesOptions}
                    label={t('common.label.storeZone')}
                    placeholder={t('common.select.defaultOptions.storeZone')}
                    control={control}
                    id="storeZone"
                    enableUnselect
                    {...storeZonesOptionsPagination}
                  />
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('common.label.storeZone')}
                    value={storeProduct.storeZone ? getStoreZoneLabel(storeProduct.storeZone) : undefined}
                  />
                }
              />

              {
                <CanAccess
                  permissions={[RoleAction.ROLE_ACTION_PRODUCT_EDIT_STOCK_PLACE]}
                  allowedComponent={
                    <Input id="localPosition" register={register} label={t('common.label.localPosition')} />
                  }
                  deniedComponent={
                    <FieldDisplay label={t('common.label.localPosition')} value={storeProduct.localPosition} />
                  }
                />
              }
            </FormSection>
            <FormSection>
              <CanAccess
                permissions={[
                  RoleAction.ROLE_ACTION_PRODUCT_EDIT_STOCK_PLACE,
                  RoleAction.ROLE_ACTION_PRODUCT_EDIT_LOCAL
                ]}
                allowedComponent={
                  <InputNumber
                    step={1}
                    id="alertQuantity"
                    register={register}
                    setValue={setValue}
                    trigger={trigger}
                    label={t('common.label.stockQuantityAlert')}
                    disabled={!canEditStockAlert}
                  />
                }
                deniedComponent={
                  <FieldDisplay
                    label={t('common.label.stockQuantityAlert')}
                    value={storeProduct?.alertQuantity?.toString()}
                  />
                }
              />
            </FormSection>
          </ProductFormGrid>
          {canSubmit && (
            <Button buttonType="submit" shape="circle" icon={SaveIcon} isLoading={isUpdatingProductStore} />
          )}
          <StoreProductStats productId={productId} store={store} />
        </ProductStoreWrapper>
      </form>
    </div>
  )
}
