import { yupResolver } from '@hookform/resolvers/yup'
import { getStoreEventFormats, getStoreEventRanges } from 'api/storeEvents'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker/DatePicker'
import FiltersList from 'components/FiltersList/FiltersList'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import { Title2 } from 'components/Title/Title.styles'
import { SearchIcon } from 'constants/icons'
import { useEventStatusOptions } from 'hooks/useSelectOptions'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { CustomStoreEventStatus } from 'types/enums'
import { hasOneOfFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import useStoreEventFilter from '../hook/useStoreEventFilters'
import { SearchEventInputs } from '../StoreEventManage'
import { FormContainer } from '../StoreEventManage.styles'

interface Props {
  onSubmit: (formData: SearchEventInputs) => void
  isLoading?: boolean
}

const schema = yup
  .object()
  .shape({
    date: yup.string().trim(),
    status: yup.string().trim(),
    range: yup.string().trim(),
    format: yup.string().trim()
  })
  .test(hasOneOfFieldsMethod())

export default function StoreEventSearchForm({ onSubmit, isLoading }: Props) {
  const { t } = useTranslation()
  const { eventStatusOptions } = useEventStatusOptions()

  const {
    control,
    register,
    setValue,
    trigger,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<SearchEventInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      status: CustomStoreEventStatus.Current
    }
  })
  const selectedRange = watch('range')
  const { data: ranges } = useQuery(['store-event-ranges'], () => getStoreEventRanges({ 'order[name]': 'asc' }))
  const { data: formats } = useQuery(
    ['store-event-formats', selectedRange],
    () => getStoreEventFormats({ range: selectedRange, 'order[name]': 'asc' }),
    {
      enabled: !!selectedRange
    }
  )
  const { filters, handleFilters } = useStoreEventFilter({ ranges, formats })

  const submitHandler = (formData: SearchEventInputs) => {
    handleFilters(formData)
    onSubmit(formData)
  }

  return (
    <CardLayout>
      <Title2>{t('page.events.management.search.title')}</Title2>
      <FormContainer onSubmit={handleSubmit(submitHandler)}>
        <DatePicker
          id="date"
          label={t('common.label.date')}
          register={register}
          setValue={setValue}
          trigger={trigger}
          defaultValue=""
        />
        <Select
          id="status"
          label={t('common.label.status')}
          options={eventStatusOptions}
          control={control}
          placeholder={t('common.select.defaultOptions.status')}
          enableUnselect={true}
        />
        <Select
          id="range"
          label={t('common.label.category')}
          options={(ranges || []).map((range) => ({
            label: range.name,
            value: range['@id']!
          }))}
          control={control}
          placeholder={t('common.select.defaultOptions.categories')}
          enableUnselect={true}
        />
        <Select
          id="format"
          label={t('common.label.format')}
          options={(formats || []).map((format) => ({
            label: format.name!,
            value: format['@id']!
          }))}
          disabled={!selectedRange}
          control={control}
          placeholder={t('common.select.defaultOptions.format')}
          enableUnselect={true}
        />
        <Button buttonType="submit" shape="circle" icon={SearchIcon} isLoading={isLoading} />
      </FormContainer>
      <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')}></BasicFieldValidationError>

      <FiltersList disableClick={true} filters={filters} />
    </CardLayout>
  )
}
