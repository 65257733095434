import { useProductCollectionById } from 'api/collection'
import { PageLoader } from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import Tabs from 'components/Tabs/Tabs'
import MainTitle from 'components/Title/MainTitle'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import CreateCategory from './tabs/CreateCategory/CreateCategory'
import EditCategory from './tabs/EditCategory/EditCategory'
import EditInformations from './tabs/EditInformation/EditInformations'

export default function CollectionDetails() {
  const { t } = useTranslation()
  const { id: collectionId } = useParams()
  const { data: productCollection } = useProductCollectionById(collectionId!)
  const [selectedTab, setSelectedTab] = useState<number | null>()

  if (!productCollection)
    return (
      <Section>
        <PageLoader />
      </Section>
    )

  return (
    <Section>
      <MainTitle
        title={t('page.collection.details.title', { name: productCollection?.name })}
      />
      <Tabs
        defaultIndex={0}
        onTabChange={setSelectedTab}
        tabs={[
          {
            tabLabel: t('page.collection.details.informations.title'),
            panelComponent: <EditInformations productCollection={productCollection} />
          },
          {
            tabLabel: t('page.collection.details.createCategory.title'),
            panelComponent: <CreateCategory isActive={selectedTab === 1} productCollection={productCollection} />
          },
          {
            tabLabel: t('page.collection.details.manageCategory.title'),
            panelComponent: <EditCategory isActive={selectedTab === 2} productCollection={productCollection} />,
            noStyle: true,
            disabled: !productCollection?.categories?.length
          }
        ]}
      />
    </Section>
  )
}
