import { usePatchRoleAction } from 'api/roleAction'
import Button from 'components/Button/Button'
import MultipleSelect from 'components/Select/MultipleSelect'
import { MoreIcon } from 'constants/icons'
import { useRoleByRoleAction } from 'hooks/entityHooks/permissionsHooks'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RoleAction } from 'types/entities'
import { AddFormLayout } from '../PermissionManage.style'
interface AddRoleInputs {
  roles: string[]
}
interface Props {
  roleAction: RoleAction
}
export default function AddRoleForm({ roleAction }: Props) {
  const { reset, control, handleSubmit, watch } = useForm<AddRoleInputs>()
  const { t } = useTranslation()
  const { availableRolesOptions } = useRoleByRoleAction(roleAction)

  const { mutateAsync: patchRoleAction, isLoading } = usePatchRoleAction(roleAction.id!, {
    callback: () => {
      reset()
    }
  })

  const onSubmit = (formData: AddRoleInputs) => {
    const { roles } = formData
    patchRoleAction({
      collaboratorRoles: _.map(roleAction.collaboratorRoles, (role) => role['@id']!).concat(roles)
    })
  }

  return (
    <AddFormLayout onSubmit={handleSubmit(onSubmit)}>
      <MultipleSelect
        options={availableRolesOptions}
        control={control}
        id="roles"
        label={t('page.permissions.manage.labels.addRole')}
        placeholder={t('page.permissions.manage.labels.placeholder.role')}
        disabled={!availableRolesOptions?.length}
      />
      <Button
        shape="circle"
        icon={MoreIcon}
        buttonType="submit"
        iconColor="white"
        disabled={!watch('roles')?.length}
        isLoading={isLoading}
      />
    </AddFormLayout>
  )
}
