import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import useAuth from 'hooks/useAuth'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { Cashbox } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type CashboxByIdResponse = Api.GetCashboxControlsId.ResponseBody
const getCashboxById = async (id?: string): Promise<Cashbox> => {
  let res = await playInApi.get<CashboxByIdResponse>([API_ROUTES.cashbox.root, id].join('/'))
  return res['data']
}

export type CashboxCollectionBody = Api.GetCashboxControls.RequestQuery
export const getCashboxCollectionUri = (body: CashboxCollectionBody): string => {
  return [API_ROUTES.cashbox.root, stringify(body)].join('?')
}

type CashboxCollectionResponse = Api.GetCashboxControls.ResponseBody
export const useGetMyCashboxes = () => {
  const { me } = useAuth()
  const myStores = me!.stores?.map((store) => `${store.id}`)
  return useQuery(['my-cashbox', { createdBy: me!.id, 'store[]': me!.stores, 'exists[closedAt]': false }], async () => {
    let res = await playInApi.get<CashboxCollectionResponse>(
      getCashboxCollectionUri({
        createdBy: me!['@id'],
        'store[]': myStores,
        'exists[closedAt]': false
      })
    )
    return res.data['hydra:member']
  })
}

export const useGetCashboxById = (id?: string) => {
  const navigate = useNavigate()
  return useQuery<CashboxByIdResponse, AxiosResponse>(['cashbox', `${id}`], () => getCashboxById(id), {
    enabled: id !== undefined,
    onError: (error) => {
      // replace: true, we prevent going back to the page that respond 40X
      navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
    }
  })
}

export const usePaginatedCashboxes = (
  querykey: string,
  uri: string,
  options?: PaginatedHookOptions<CashboxCollectionResponse['hydra:member'][0]>
) => usePaginatedQuery(querykey, uri, options)

type CreateCashboxBody = Api.PostCashboxControls.RequestBody
type CreateCashboxResponse = Api.PostCashboxControls.ResponseBody
export const createCashbox = async (body: CreateCashboxBody): Promise<Cashbox> => {
  let res = await playInApi.post<CreateCashboxBody, AxiosResponse<CreateCashboxResponse>>(API_ROUTES.cashbox.root, body)
  return res.data
}

export type ValidateCashboxBody = Api.PatchCashboxControlsValidateId.RequestBody
type ValidateCashboxResponse = Api.PatchCashboxControlsValidateId.ResponseBody
export const validateCashbox = async (id: string | number, body: ValidateCashboxBody): Promise<Cashbox> => {
  let res = await playInApi.patch<ValidateCashboxBody, AxiosResponse<ValidateCashboxResponse>>(
    `${API_ROUTES.cashbox.validate}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export type FundCashboxBody = Api.PatchCashboxControlsFundId.RequestBody
type FundCashboxResponse = Api.PatchCashboxControlsFundId.ResponseBody
export const fundCashbox = async (id: number | string, body: FundCashboxBody): Promise<Cashbox> => {
  let res = await playInApi.patch<FundCashboxBody, AxiosResponse<FundCashboxResponse>>(
    `${API_ROUTES.cashbox.fund}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export type CloseCashboxBody = Api.PatchCashboxControlsCloseId.RequestBody
type CloseCashboxResponse = Api.PatchCashboxControlsCloseId.ResponseBody
export const closeCashbox = async (id: string | number, body: CloseCashboxBody): Promise<Cashbox> => {
  let res = await playInApi.patch<CloseCashboxBody, AxiosResponse<CloseCashboxResponse>>(
    `${API_ROUTES.cashbox.close}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}
