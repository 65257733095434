import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import ImageUploader from 'components/InputFile/ImageUploader'
import InputRadio from 'components/InputRadio/InputRadio'
import { LineBreakLabel } from 'components/Layouts/InputLayout/InputLayout.styles'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import { Text } from 'components/Text/Text.styles'
import Textarea from 'components/Textarea/Textarea'
import Tooltip from 'components/Tooltip/Tooltip'
import { SaveIcon } from 'constants/icons'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { ProductCollection, SealedProduct } from 'types/entities'
import { getRequiredLabel } from 'utils/formHelper'
import * as yup from 'yup'
import ProductSelector from '../component/ProductSelector'
import { AdditionalSection, FieldsWrapper, Image, SavedImageInfo, SavedImageText, SubmitButton, TwoColsRow } from './CollectionCreateForm.style'



const schema = yup.object({
  name: yup.string().trim().required(),
  englishName: yup.string().trim(),
  slogan: yup.string().trim().nullable().when('onCollectionHome', {
    is: true,
    then: yup.string().trim().required().nullable()
  }),
  sloganEn: yup.string().trim(),
  license: yup.boolean(),
  onFamilyHome: yup.boolean(),
  onCollectionHome: yup.boolean(),
  dedicatedPage: yup.boolean(),
  image: yup
    .mixed()
    .nullable()
    .when('dedicatedPage', { is: true, then: (schema) => schema.required() }),
  masterProduct: yup.string().trim().nullable(),
  shortDescription: yup
    .string()
    .trim()
    .when('dedicatedPage', { is: true, then: (schema) => schema.required() }),
  shortDescriptionEn: yup.string().trim(),
  titleDescription: yup.string().trim(),
  titleDescriptionEn: yup.string().trim(),
  description: yup.string().trim(),
  descriptionEn: yup.string().trim(),
  active: yup.boolean()
})

export interface CreateCollectionInputs extends yup.InferType<typeof schema> {}
interface Props {
  onSubmit: (formData: CreateCollectionInputs) => void
  isFormLoading?: boolean
  currentCollection?: ProductCollection
  isEdit?: boolean
}
const catchPhraseTextLimit = 70

export default function CollectionCreateForm({ onSubmit, isFormLoading, isEdit = false, currentCollection }: Props) {
  const { t } = useTranslation()

  const initialValues: Partial<CreateCollectionInputs> = currentCollection
    ? {
        name: currentCollection.name,
        englishName: currentCollection.englishName ?? undefined,
        slogan: currentCollection.slogan ?? undefined,
        sloganEn: currentCollection.sloganEn ?? undefined,
        onFamilyHome: currentCollection.onFamilyHome,
        onCollectionHome: currentCollection.onCollectionHome,
        dedicatedPage: currentCollection.dedicatedPage,
        shortDescription: currentCollection.shortDescription ?? undefined,
        shortDescriptionEn: currentCollection.shortDescriptionEn ?? undefined,
        description: currentCollection.description ?? undefined,
        descriptionEn: currentCollection.descriptionEn ?? undefined,
        active: currentCollection.active,
        image: `${currentCollection?.imageUrl}`,
        masterProduct: currentCollection.masterProduct?.['@id'],
        titleDescription: currentCollection?.titleDescription ?? undefined,
        titleDescriptionEn: currentCollection?.titleDescriptionEn ?? undefined
      }
    : {}
  const {
    handleSubmit,
    watch,
    control,
    register,
    setValue,
    formState: { errors }
  } = useForm<CreateCollectionInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      license: false,
      onFamilyHome: false,
      onCollectionHome: false,
      dedicatedPage: false,
      ...initialValues
    }
  })

  const [
    isLicense,
    isHomepage,
    hasDedicated,
    watchCatchPhrase,
    watchCatchPhraseEn,
    onCollectionHome
  ] = watch([
    'license',
    'onFamilyHome',
    'dedicatedPage',
    'slogan',
    'sloganEn',
    'onCollectionHome'
  ])

  const [masterProduct, setMasterProduct] = useState<SealedProduct | null>(null)

  useEffect(() => {
    if (isLicense) {
      setValue('onFamilyHome', false)
      setValue('onCollectionHome', false)
      setValue('dedicatedPage', true)
    } else if (isHomepage) {
      setValue('onCollectionHome', true)
      setValue('dedicatedPage', true)
    }

    if (onCollectionHome) setValue('dedicatedPage', true)
  }, [setValue, isHomepage, isLicense, onCollectionHome])

  const handleMasterProduct = (product: SealedProduct | null) => {
    setMasterProduct(product)
    setValue('masterProduct', product?.['@id'] ?? null)
  }

  useEffect(() => {
    if (currentCollection?.masterProduct) setMasterProduct(currentCollection?.masterProduct)
  }, [currentCollection?.masterProduct])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldsWrapper>
        <div>
          <Input
            register={register}
            id="name"
            label={getRequiredLabel(t('common.label.name'))}
            className={classNames({ 'is-invalid': errors.name })}
          />
          <BasicFieldValidationError error={errors.name} message={t('form.validation.required.name')} />
        </div>

        <Input register={register} id="englishName" label={t('common.label.nameEn')} />
        <div>
          <Input
            disabled={!onCollectionHome}
            register={register}
            id="slogan"
            label={getRequiredLabel(t('page.collection.create.labels.catchPhrase'))}
            tooltip={
              <Tooltip
                id="catchPhraseTooltip"
                children={<Trans i18nKey="page.collection.create.tooltips.catchPhrase" components={{ br: <br /> }} />}
              />
            }
            secondaryText={
              <Text
                fontStyle="italic"
                fontWeight="light"
                color={(watchCatchPhrase?.length ?? 0) > catchPhraseTextLimit ? 'danger' : 'black'}
              >{`${watchCatchPhrase?.length ?? 0} / ${catchPhraseTextLimit}`}</Text>
            }
            className={classNames({ 'is-invalid': errors.slogan })}
          />
          <BasicFieldValidationError error={errors.slogan} message={t('page.collection.create.required.slogan')} />
        </div>

        <Input
          disabled={!onCollectionHome}
          register={register}
          id="sloganEn"
          label={t('page.collection.create.labels.catchPhraseEn')}
          secondaryText={
            <Text
              fontStyle="italic"
              fontWeight="light"
              color={(watchCatchPhraseEn?.length ?? 0) > catchPhraseTextLimit ? 'danger' : 'black'}
            >{`${watchCatchPhraseEn?.length ?? 0} / ${catchPhraseTextLimit}`}</Text>
          }
        />
        <TwoColsRow>
          <InputRadio
            control={control}
            id="onFamilyHome"
            label={t('page.collection.create.labels.higlighted')}
            disabled={isLicense}
            tooltip={
              <Tooltip
                id="higlightedTooltip"
                children={<Trans i18nKey="page.collection.create.tooltips.higlighted" components={{ br: <br /> }} />}
              />
            }
          />
          <InputRadio
            control={control}
            id="onCollectionHome"
            label={t('page.collection.create.labels.collectionPage')}
            disabled={isLicense || isHomepage}
            tooltip={
              <Tooltip
                id="collectionPageTooltip"
                children={
                  <Trans i18nKey="page.collection.create.tooltips.collectionPage" components={{ br: <br /> }} />
                }
              />
            }
          />
        </TwoColsRow>
        <TwoColsRow>
          <InputRadio
            control={control}
            id="dedicatedPage"
            label={t('page.collection.create.labels.dedicatedPage')}
            disabled={isLicense || isHomepage || onCollectionHome}
            tooltip={
              <Tooltip
                id="dedicatedPageTooltip"
                children={<Trans i18nKey="page.collection.create.tooltips.dedicatedPage" components={{ br: <br /> }} />}
              />
            }
          />
          {isEdit && (
            <InputRadio
              control={control}
              id="active"
              label={t('page.collection.create.labels.active')}
              tooltip={
                <Tooltip
                  id="activeTooltip"
                  children={<Trans i18nKey="page.collection.create.tooltips.active" components={{ br: <br /> }} />}
                />
              }
            />
          )}
        </TwoColsRow>
      </FieldsWrapper>
      {hasDedicated && (
        <AdditionalSection>
          <FieldsWrapper>
            <div>
              <ImageUploader
                display={{ height: 200 }}
                control={control}
                id="image"
                setValue={setValue}
                label={t('page.collection.create.labels.image')}
                hasError={errors.image}
                labelComponent={LineBreakLabel}
              />
              {initialValues.image !== 'null' && (
                <SavedImageInfo>
                  <SavedImageText>
                    {t('page.collection.create.savedImage')}
                  </SavedImageText>
                  <Tooltip
                    id="savedImageInfoTooltip"
                    children={
                      <Image src={initialValues.image} />
                    }
                  />
                </SavedImageInfo>
              )}
              <BasicFieldValidationError error={errors.image} message={t('page.collection.create.required.image')} />
            </div>

            <ProductSelector masterProduct={masterProduct} handleProduct={handleMasterProduct} />

            <div>
              <Textarea
                register={register}
                id="shortDescription"
                label={getRequiredLabel(t('common.label.shortDescription'))}
                className={classNames({ 'is-invalid': errors.shortDescription })}
                tooltip={
                  <Tooltip
                    id="descriptionTooltip"
                    children={
                      <Trans i18nKey="page.collection.create.tooltips.description" components={{ br: <br /> }} />
                    }
                  />
                }
              />
              <BasicFieldValidationError
                error={errors.shortDescription}
                message={t('form.validation.required.description')}
              />
            </div>

            <Textarea
              register={register}
              id="shortDescriptionEn"
              label={t('common.label.shortDescriptionEn')}
            />

            <div>
              <RichTextEditor
                control={control}
                id="description"
                label={t('page.collection.create.labels.longDescription')}
                className={classNames({ 'is-invalid': errors.description })}
                tooltip={
                  <Tooltip
                    id="longDescriptionTooltip"
                    children={<Trans i18nKey="page.collection.create.tooltips.longDescription" />}
                  />
                }
              />
              <BasicFieldValidationError
                error={errors.description}
                message={t('form.validation.required.longDescription')}
              />
            </div>

            <RichTextEditor
              control={control}
              id="descriptionEn"
              label={t('page.collection.create.labels.longDescriptionEn')}
            />
          </FieldsWrapper>
        </AdditionalSection>
      )}

      <SubmitButton icon={SaveIcon} buttonType="submit" isLoading={isFormLoading}>
        {isEdit ? t('common.button.save') : t('page.collection.create.btn')}
      </SubmitButton>
    </form>
  )
}
