import styled from 'styled-components'
import theme from 'theme/theme'

export const ModalLayout = styled.div`
  display: grid;
  grid-template-columns: 450px 450px;
  align-items: flex-start;

  > div {
    display: grid;
    form {
      width: 250px;
      justify-self: center;
    }
  }

  > div:first-of-type {
    border-right: 1px solid ${theme.colors.grey4};
  }
  > div:last-of-type {
    padding-left: 5rem;
  }
  @media screen and (${theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
    row-gap: 3.5rem;
    > div:first-of-type {
      border-right: none;
    }
    > div:last-of-type {
      padding-left: 0;
    }
  }
`
