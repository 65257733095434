import { yupResolver } from '@hookform/resolvers/yup'
import { useGetEditions } from 'api/cardEditions'
import { GetPrintExclusionsQueryResponse, getPrintExclusionsUri } from 'api/printExclusions'
import { fetchSealedProducts, getSealedProductsUri } from 'api/sealedProducts'
import Select from 'components/Select/Select'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { SealedProduct, Store } from 'types/entities'
import { isObjectEmpty } from 'utils/queryParams'
import * as yup from 'yup'
import useOtherProductsSearchFilter, { PreorderSearchFormInputs } from '../hooks/useOtherProductsSearchFilter'
import { SearchQuery } from '../OtherProductPreorderForm'
import { FieldsCol } from '../otherProductsTab.style'

const schema = yup.object().shape({
  edition: yup.string().trim().nullable(true),
  language: yup.string().trim().nullable(true)
})

interface Props {
  handleSearchStatus: (search: SearchQuery) => void
  handleProducts: (searchFrom: 'releaseDate' | 'productSearch' | 'edition', searchedProducts?: SealedProduct[]) => void
  store: Store
  active: boolean
}
export default function SearchByEditionForm({ handleSearchStatus, handleProducts, store, active }: Props) {
  const { t } = useTranslation()
  const { control, watch, setValue, reset } = useForm<PreorderSearchFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      edition: null,
      language: null
    }
  })
  const [watchEdition] = watch(['edition'])
  const { queryParams, langOptions } = useOtherProductsSearchFilter(watch)
  const { data: editions } = useGetEditions(
    { 'releasedAt[after]': 'now', withProducts: true },
    {
      enabled: active
    }
  )

  const { data: printExclusions, isLoading: isExclusionLoading } = usePaginatedQuery<GetPrintExclusionsQueryResponse>(
    ['excluded-from-print-editions', store.id],
    getPrintExclusionsUri({
      store: `${store.id}`,
      pagination: false
    }),
    {
      enabled: active
    }
  )

  const { isLoading, isFetched } = useQuery(
    [queryParams, store],
    () =>
      fetchSealedProducts(
        getSealedProductsUri({
          'exists[secondHandOf]': false,
          itemsPerPage: 70,
          ...queryParams
        })
      ),
    {
      enabled: active && !isObjectEmpty(queryParams),
      onSuccess: (data) => {
        handleProducts('edition', data)
      }
    }
  )

  useEffect(() => reset(), [store, reset])

  useEffect(() => {
    handleSearchStatus({ isLoading, isFetched })
  }, [isLoading, isFetched, handleSearchStatus])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!value.edition && name === 'edition') {
        setValue('language', '')
        handleProducts('edition', [])
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue, handleProducts])

  return (
    <FieldsCol>
      <Select
        options={(editions || []).map((option) => ({
          label: option.name,
          value: option.id,
          disabled: (printExclusions ?? [])?.map((e) => e.edition?.['@id']).includes(option['@id'])
        }))}
        control={control}
        id="edition"
        enableUnselect={true}
        placeholder={t('common.select.defaultOptions.cardEdition')}
        isLoading={isExclusionLoading || isLoading}
        label={t('common.label.cardEdition')}
      />
      <Select
        id="language"
        options={langOptions}
        label={t('common.label.lang')}
        control={control}
        enableUnselect={true}
        placeholder={t('common.select.defaultOptions.lang')}
        disabled={!watchEdition}
      />
    </FieldsCol>
  )
}
