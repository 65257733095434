import Button from 'components/Button/Button'
import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import styled from 'styled-components'

export const SpaceBetweenRowContainer = styled(RowContainer)`
  display: inline-flex;
  align-content: space-between;
`

export const GappedRowContainer = styled(RowContainer)`
  gap: 2rem;
`

export const Separator = styled.hr`
  margin: 2rem 0;
  border: 1px solid ${(props) => props.theme.borderColors.secondary};
`

export const InputRow = styled(RowContainer)`
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;

  & > :last-child {
    min-width: 9rem;
    max-width: 9rem;
  }
`

export const StorePlaceRow = styled(RowContainer)`
  margin-bottom: 2rem;
`

export const PrintLabelButton = styled(Button)`
  width: 100%;
`

export const PaddingLessButton = styled(Button)`
  padding: calc(0.95rem - var(--borderWidth)) 0.5rem;
`
