import { useDownloadFile } from 'api/basePlayInApi'
import { OldIcon } from 'app/ProductManagement/ProductManagement.style'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { API_ROUTES } from 'constants/configs'
import { CheckIcon, CrossIcon, PrintIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ShouldHide from 'features/Permissions/ShouldHide'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { StoreEvent } from 'types/entities'
import { RoleAction, StoreEventCustomerJsonldEventReadEventReadItem } from 'types/playInApiInterfaces'
import {
  ListActionsWrapper,
  NoResultText,
  StyledCell,
  StyledClickableCell,
  StyledRowCell
} from '../StoreEventDetails.style'
import CustomerListActions from './CustomerListActions'
import MiscActions from './MiscActions'

interface Props {
  event: StoreEvent
}

type StoreEventItem = StoreEventCustomerJsonldEventReadEventReadItem

export default function CustomerList({ event }: Props) {
  const { t } = useTranslation()
  const { checkMatch } = useCheckMatch()

  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([])

  const handleSelectCustomer = (id: string) => setSelectedCustomers((prev) => _.xor(prev, [id]))

  const activeCustomers = event?.customers?.filter((customer) => customer.validated)
  const handleSelectAll = () =>
    setSelectedCustomers((prev) =>
      prev.length === activeCustomers?.length ? [] : activeCustomers?.map((e) => e['@id']!) ?? []
    )

  const { downloadFile, isLoading: downloadLoading } = useDownloadFile()

  const columns = _.filter<Column<StoreEventItem>>(
    [
      {
        key: 'checkbox',
        decorator: (customer) => (
          <StyledCell
            className={classNames({
              unsuscribed: !customer.validated
            })}
          >
            {!!customer.validated && (
              <Checkbox
                onClick={() => handleSelectCustomer(customer['@id']!)}
                className={classNames({ checked: !!selectedCustomers.includes(customer['@id']!) })}
              />
            )}
          </StyledCell>
        )
      },
      {
        key: 'software',
        name: t('page.events.details.labels.software'),
        decorator: (customer) => {
          const icon = customer.software ? CheckIcon : CrossIcon
          const color = customer.software ? 'success' : 'danger'
          return (
            <StyledCell
              className={classNames({
                unsuscribed: !customer.validated
              })}
            >
              <OldIcon svg={icon} color={color} />
            </StyledCell>
          )
        }
      },
      {
        key: 'name',
        name: t('common.label.name'),
        decorator: (customer) => {
          const { firstname, lastname } = customer.linkedOrder?.customer ?? {}
          return (
            <StyledClickableCell
              route={RouteName.CustomerDetails}
              value={customer.linkedOrder?.customer?.id}
              className={classNames({
                unsuscribed: !customer.validated
              })}
            >
              {`${lastname} ${firstname}`}
            </StyledClickableCell>
          )
        }
      },
      {
        key: 'email',
        name: t('common.label.email'),
        decorator: (customer) => (
          <StyledClickableCell
            route={RouteName.CustomerDetails}
            value={customer.linkedOrder?.customer?.id}
            className={classNames({
              unsuscribed: !customer.validated
            })}
          >
            <Text fontWeight="light">{customer.linkedOrder?.customer?.email}</Text>
          </StyledClickableCell>
        )
      },
      {
        key: 'additionalData',
        name: <Text fontStyle="italic">{event.requiredDataName}</Text>,
        decorator: (customer) => (
          <StyledClickableCell
            route={RouteName.StoreEventDetails}
            value={customer.id}
            className={classNames({
              unsuscribed: !customer.validated
            })}
          >
            {customer.additionalData ?? '-'}
          </StyledClickableCell>
        )
      },
      {
        key: 'price',
        name: t('page.events.details.labels.price'),
        decorator: (customer) => (
          <StyledRowCell
            className={classNames({
              unsuscribed: !customer.validated
            })}
          >
            {customer.validated ? (
              <TextNumber value={customer.paidPrice} suffix={`\u00a0€`} decimalScale={2} />
            ) : (
              <Text fontStyle="italic">
                {customer.refunded
                  ? t('page.events.details.labels.refunded.yes')
                  : t('page.events.details.labels.refunded.no')}
              </Text>
            )}

            <Tooltip id="refundTooltip">
              <Trans
                i18nKey="page.events.details.tooltips.refund"
                components={{ strong: <strong /> }}
                values={{ refundId: `t${customer?.linkedOrder?.id}` }}
              />
            </Tooltip>
          </StyledRowCell>
        )
      },
      {
        key: 'paymentMode',
        name: t('common.label.paymentMethod'),
        decorator: (customer) => (
          <StyledCell
            className={classNames({
              unsuscribed: !customer.validated
            })}
          >
            {customer.linkedOrder?.paymentMode?.name || '-'}
          </StyledCell>
        )
      },
      {
        key: 'voucher',
        name: t('page.events.details.labels.voucher'),
        decorator: (customer) => (
          <StyledCell
            className={classNames({
              unsuscribed: !customer.validated
            })}
          >
            <TextNumber value={customer.voucher?.originalValue} suffix={`\u00a0€`} decimalScale={2} placeholder="-" />
          </StyledCell>
        )
      },
      {
        key: 'invoice',
        name: t('page.events.details.labels.invoice'),
        decorator: (customer) => (
          <StyledCell
            className={classNames({
              unsuscribed: !customer.validated
            })}
          >
            <TooltipHover
              id="printBillCouponTitleTooltip"
              content={<Text>{t('page.events.details.buttons.invoiceTooltip')}</Text>}
            >
              <Button
                variant="default"
                shape="circle"
                icon={PrintIcon}
                disabled={!customer.linkedOrder?.billNumber}
                isLoading={downloadLoading}
                onClick={() => downloadFile(`${API_ROUTES.downloads.orderEvent}/${customer.linkedOrder?.id}`)}
              />
            </TooltipHover>
          </StyledCell>
        )
      }
    ],
    (column) => {
      if (column.key === 'checkbox') {
        return checkMatch([
          RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER,
          RoleAction.ROLE_ACTION_STORE_EVENT_VOUCHER_CUSTOMER
        ])
      }

      if (column.key === 'additionalData') {
        return !!event.requiredDataName
      }

      return true
    }
  )

  const sortCustomers = (customers: StoreEventItem[]) => {
    const groupedCustomers = _.groupBy(customers, 'validated')

    const sortedCustomers = _.mapValues(groupedCustomers, (group) => {
      return _.sortBy(group, 'linkedOrder.customer.lastname')
    })

    return _.concat(sortedCustomers['true'] ?? [], sortedCustomers['false'] ?? [])
  }

  const softwareCount = t('page.events.details.listTitleSoftwareCount', {
    count: _.filter(event.customers, (e) => !!e.software)?.length
  })
  return (
    <CardLayout>
      <Title2>
        {t('page.events.details.listTitle', {
          count: activeCustomers?.length,
          total: event.maxCapacity ?? '-',
          softwareCount
        })}
      </Title2>
      <MiscActions event={event} />
      {event?.customers && !!event.customers.length ? (
        <>
          <ListActionsWrapper>
            <ShouldHide
              permissions={[
                RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER,
                RoleAction.ROLE_ACTION_STORE_EVENT_VOUCHER_CUSTOMER
              ]}
            >
              <Checkbox
                onClick={handleSelectAll}
                className={classNames({
                  checked: selectedCustomers.length === activeCustomers?.length && !!activeCustomers?.length,
                  disabled: !activeCustomers?.length
                })}
              />
            </ShouldHide>
            <CustomerListActions
              selectedCustomers={sortCustomers(event.customers.filter((e) => selectedCustomers.includes(e['@id']!)))}
              event={event}
              setSelectedCustomers={setSelectedCustomers}
            />
          </ListActionsWrapper>

          <Listing columns={columns} data={sortCustomers(event.customers)} />
        </>
      ) : (
        <NoResultText color="danger">{t('page.events.details.noCustomers')} </NoResultText>
      )}
    </CardLayout>
  )
}
