import { useGetCashboxById } from 'api/cashbox'
import Button from 'components/Button/Button'
import { PageLoader } from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { HeadingLayout } from 'components/Title/Title.styles'
import StoreSelect from 'features/StoreSelect'
import { getPath, RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation, useNavigate, useParams } from 'react-router'
import { Store } from 'types/entities'
import { CashboxStatus } from 'types/playInApiInterfaces'
import CashboxCloseStep from './steps/CashboxCloseStep'
import CashboxCreateStep from './steps/CashboxCreateStep'
import CashboxFundStep from './steps/CashboxFundStep'
import { CashboxContainer, JustCreatedCard } from './steps/CashboxStep.style'
import CashboxValidateStep from './steps/CashboxValidateStep'

function CashboxDetails() {
  //already existing cashbox if id is defined
  const { t } = useTranslation()
  const { id: cashboxId } = useParams()

  const location = useLocation() as Location & { state: null | { fromCreate: boolean } }

  //If cashbox was created just before, we want to use a special display in order to not mislead collaborator
  //So we need to handle this information
  const [fromCreate, setFromCreate] = useState(location?.state ?? false)

  //If id is provided, we juste need to get the cashbox by id
  const { data: cashbox, isLoading } = useGetCashboxById(cashboxId)

  const navigate = useNavigate()
  useEffect(() => {
    // Listen for previous cashbox creation
    if (location.state) {
      // as we consumed current state, we reset it for futur reload
      setFromCreate(location.state.fromCreate)
      navigate(getPath(RouteName.CashboxDetails).replace(':id', `${cashboxId}`), {
        replace: true,
        state: null
      })
    }
  }, [location.state, navigate, cashboxId])

  return (
    <Section>
      {!isLoading ? (
        cashbox && (
          <>
            <HeadingLayout>
              <MainTitle
                title={t('page.cashbox.title', {
                  fullName: `${cashbox?.createdBy?.firstname} ${cashbox?.createdBy?.lastname}`
                })}
              />
              <div>
                <StoreSelect store={cashbox.store as Store} onLocationChange={() => {}} disabled={true} />
              </div>
            </HeadingLayout>

            <CashboxContainer>
              <CashboxCreateStep isActive={false} cashbox={cashbox} />
              {fromCreate ? (
                <JustCreatedCard>
                  <Text fontStyle="italic">{t('page.cashbox.justCreated.text')}</Text>
                  <Button variant="white" onClick={() => setFromCreate(false)}>
                    {t('page.cashbox.justCreated.btn')}
                  </Button>
                </JustCreatedCard>
              ) : (
                <>
                  <CashboxValidateStep isActive={cashbox?.status === CashboxStatus.Created} cashbox={cashbox} />
                  <CashboxFundStep isActive={cashbox?.status === CashboxStatus.Validated} cashbox={cashbox} />
                  <CashboxCloseStep isActive={cashbox?.status === CashboxStatus.Funded} cashbox={cashbox} />
                </>
              )}
            </CashboxContainer>
          </>
        )
      ) : (
        <PageLoader />
      )}
    </Section>
  )
}

export default CashboxDetails
