import { Text } from 'components/Text/Text.styles'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { SealedProduct } from 'types/entities'
import { ProductVisibility } from 'types/playInApiInterfaces'
import { formatCalendarDate } from 'utils/dates'
import { ProductFormGrid, TextFormField } from '../ProductDetailsTab.styles'

type Props = {
  productId: string
}

const DisplayMainStoreProduct = ({ productId }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const product = queryClient.getQueryData<SealedProduct>(['sealedProductById', productId])

  const getVisibilityLabel = (visibility: string) => {
    return {
      [ProductVisibility.AlwaysHidden]: t('common.select.productVisibility.never'),
      [ProductVisibility.AlwaysShown]: t('common.select.productVisibility.always'),
      [ProductVisibility.Default]: t('common.select.productVisibility.inStock')
    }[visibility]
  }

  return (
    <div>
      <Title2>{t('page.product.detail.tabs.detail.commonInfo')}</Title2>
      <ProductFormGrid>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.barcode')}</Text>
          <TextFieldDisplay text={product?.barCode} fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.visibilityOnSite')}</Text>
          <TextFieldDisplay text={getVisibilityLabel(product?.visibility!)} fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.mainPrice')}</Text>
          <TextNumber value={product?.price} suffix=" €" decimalScale={2} fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.tva')}</Text>
          <TextNumber value={product?.tax?.rate} suffix=" %" fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.releaseDate')}</Text>
          <TextFieldDisplay text={formatCalendarDate(product?.releasedAt)} fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.oldProduct')}</Text>
          <Text fontWeight="medium">{product?.old ? t('common.radioButton.yes') : t('common.radioButton.no')}</Text>
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.advisedPrice')}</Text>
          <TextNumber value={product?.advisedPrice ?? undefined} suffix=" €" decimalScale={2} fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.discount')}</Text>
          <TextNumber value={product?.discount ?? 0} suffix=" €" decimalScale={2} fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.lang')}</Text>
          <TextFieldDisplay text={product?.lang?.name} fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.redirectTo')}</Text>
          <TextFieldDisplay text={product?.redirectTo} fontWeight="medium" />
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.pricingPolicy')}</Text>
          <Text fontWeight="medium">
            {product?.pricingPolicy ? t('common.radioButton.yes') : t('common.radioButton.no')}
          </Text>
        </TextFormField>
        <TextFormField>
          <Text fontWeight="light">{t('common.label.discountPercent')}</Text>
          <TextNumber value={product?.discountPercentage ?? 0} suffix=" %" fontWeight="medium" />
        </TextFormField>
        <div></div>
        <div></div>
        <div></div>
        <Text fontWeight="medium">
          {product?.isSpecialDiscount
            ? t('page.product.detail.tabs.detail.specialDiscount.actif')
            : t('page.product.detail.tabs.detail.specialDiscount.inactif')}
        </Text>
      </ProductFormGrid>
    </div>
  )
}

export default DisplayMainStoreProduct
