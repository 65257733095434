import { ReactNode, useState } from 'react'
import { Colors } from 'theme/theme'
import ToggleIcon from './ToggleIcon'
import { StyledPopover, TooltipContainer } from './Tooltip.styles'

interface Props {
  className?: string
  id: string
  children: ReactNode
  color?: keyof Colors
  width?: string
}
const Tooltip = ({ className, id, children, color, width }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const tooltipId = open ? id : undefined
  return (
    <>
      <ToggleIcon onClick={handleClick} color={color} />
      <StyledPopover
        className={className}
        id={tooltipId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <TooltipContainer width={width}>{children}</TooltipContainer>
      </StyledPopover>
    </>
  )
}

export default Tooltip
