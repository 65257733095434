import { API_ROUTES } from 'constants/configs'
import { useQuery, UseQueryOptions } from 'react-query'
import { Store } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type FetchCardEditionsResponse = Api.GetCardEditions.ResponseBody
type FetchCardEditionsQueryParams = Api.GetCardEditions.RequestQuery
const fetchCardEditions = async (params?: FetchCardEditionsQueryParams) => {
  const res = await playInApi.get<FetchCardEditionsResponse>(API_ROUTES.cardEditions.root, { params })
  return res.data['hydra:member']
}

export const useGetEditions = (
  params?: FetchCardEditionsQueryParams,
  options?: Omit<UseQueryOptions<FetchCardEditionsResponse['hydra:member']>, 'queryKey' | 'queryFn'>
) => {
  return useQuery(['card-editions', params], () => fetchCardEditions(params), options)
}

export const useGetIncomingCardEditions = (
  store: Store,
  options?: Omit<UseQueryOptions<FetchCardEditionsResponse['hydra:member']>, 'queryKey' | 'queryFn'>
) => {
  return useQuery(
    ['incoming-card-editions', store['@id']],
    () =>
      fetchCardEditions({
        'releasedAt[after]': 'now',
        withProducts: true,
        withoutExcludedAt: store['@id']!
      }),
    options
  )
}

export const getCardEditionsUri = (params: FetchCardEditionsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.cardEditions.root, queryString].join('?')
}
