import { GetOrderByIdResponse } from 'api/orders'
import { useCustomerVouchers } from 'api/vouchers'
import { ColumnContainer, RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { Text } from 'components/Text/Text.styles'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { useGetPaymentModeLabel } from 'hooks/entityHooks/paymentModeHooks'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { OverviewTextRow, PaymentModeCardFormContainer, StyledInputLayout } from './PaymentModeCard.style'

interface PaymentModeCardProps {
  order?: GetOrderByIdResponse
}
const PaymentModeDisplay = ({ order }: PaymentModeCardProps) => {
  const { t } = useTranslation()
  const { getPaymentModeLabel } = useGetPaymentModeLabel()

  const { data: customerVouchers } = useCustomerVouchers(`${order?.customer?.id}`)
  const voucherTotal = _.sum(customerVouchers?.map((el) => Number(el.value)))

  return (
    <div>
      <Title2>{t('page.order.detail.tab.payment.paymentModesTitle')}</Title2>
      <PaymentModeCardFormContainer>
        <StyledInputLayout className="no-border" label={t('common.label.paymentMethod')}>
          <ColumnContainer>
            {order?.paymentModes?.payments?.length ? (
              order.paymentModes.payments?.map((paymentMode) => (
                <RowContainer key={paymentMode['@id']}>
                  <TextNumber value={paymentMode.value} suffix=" €" decimalScale={2} fontWeight="medium" />
                  {'-'}
                  <Text fontWeight="medium">{getPaymentModeLabel(paymentMode.name)}</Text>
                </RowContainer>
              ))
            ) : (
              <TextFieldDisplay />
            )}
          </ColumnContainer>
        </StyledInputLayout>
        <OverviewTextRow>
          <Text
            fontWeight="light"
            fontStyle="italic"
            color={!!order?.paymentModes?.payments?.length && order?.leftToPay !== 0 ? 'danger' : 'secondary'}
          >
            {t('common.label.leftToPay')}
          </Text>
          <TextNumber
            fontWeight="light"
            fontStyle="italic"
            color={!!order?.paymentModes?.payments?.length && order?.leftToPay !== 0 ? 'danger' : 'secondary'}
            value={order?.leftToPay}
            suffix=" €"
            decimalScale={2}
          />
        </OverviewTextRow>
        <OverviewTextRow>
          <Text fontWeight="light" fontStyle="italic" color="secondary">
            {t('page.order.detail.tab.payment.totalVouchers')}
          </Text>
          <TextNumber
            fontWeight="light"
            fontStyle="italic"
            color="secondary"
            value={voucherTotal}
            suffix=" €"
            decimalScale={2}
          />
        </OverviewTextRow>
      </PaymentModeCardFormContainer>
    </div>
  )
}

export default PaymentModeDisplay
