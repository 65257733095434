import classNames from 'classnames'
import Button from 'components/Button/Button'
import InputLayout, { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import { useEmit } from 'hooks/useEventEmitter'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EventType } from 'types/events'
import { NotificationId, NotificationType } from 'types/notifications'
import { HiddenInput, InputContainer } from './InputFile.styles'

interface ComponentProps extends LayoutProps {
  handleFile?: (file: File) => void
  accept?: string
  value?: File
  onChange: (file: File) => void
  validationFn: (file: File) => Promise<FileError[]>
  hasError?: boolean
}

interface Props<T extends FieldValues> extends Omit<ComponentProps, 'onChange' | 'forwardRef'> {
  id: Path<T>
  control: Control<T>
  hasError?: boolean
}

export type FileError = {
  key: string
  message: string
}

const InputFileComponent = ({
  className,
  handleFile,
  accept,
  value,
  onChange,
  validationFn,
  hasError,
  ...layoutProps
}: ComponentProps) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<File | null>(value ?? null)
  const emitNotification = useEmit()

  const handleFileUpload = async (event?: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target.files?.[0]

    if (file) {
      const errors = await validationFn(file)
      if (!!errors.length) {
        emitNotification(EventType.Notification, {
          id: NotificationId.Warning,
          type: NotificationType.Warning,
          title: t('common.file.errors.notification'),
          text: errors.map((e) => e.message)
        })
      } else {
        onChange(file)
        setInputValue(file)
        if (handleFile) handleFile(file)
      }
    }
  }
  const inputRef = useRef<HTMLInputElement>(null)

  const onClick = () => {
    inputRef?.current?.click()
  }

  const handlePathAsFileUpload = useCallback(
    async (path: string) => {
      const response = await fetch(path)
      // here image is url/location of image
      const blob = await response.blob()
      const file = new File([blob], 'image.jpg', { type: blob.type })
      if (handleFile) handleFile(file)
    },
    [handleFile]
  )

  useEffect(() => {
    //If we get path (from defaultValues)
    if (typeof value === 'string') {
      handlePathAsFileUpload(value) //Specific file upload which doesn't trigger onChange
    }
    if (value === null) setInputValue(null)
  }, [value, handlePathAsFileUpload])

  return (
    <InputLayout className={classNames(className, 'no-border')} {...layoutProps}>
      <InputContainer onClick={onClick}>
        <Button variant="white">{t('common.fileInpute.button')}</Button>
        <Text fontStyle="italic" color={hasError ? 'danger' : 'black'}>
          {inputValue?.name ?? t('common.fileInpute.noFile')}
        </Text>
        <HiddenInput type="file" accept={accept} ref={inputRef} onChange={handleFileUpload} />
      </InputContainer>
    </InputLayout>
  )
}
export default function ControlledInputFile<T extends FieldValues>({
  id,
  control,
  accept,
  className,
  handleFile,
  validationFn,
  hasError,
  ...layoutProps
}: Props<T>) {
  return (
    <Controller
      name={id}
      control={control}
      render={({ field }) => {
        const { value, onChange } = field
        return (
          <InputFileComponent
            value={value}
            accept={accept}
            className={className}
            handleFile={handleFile}
            onChange={onChange}
            validationFn={validationFn}
            hasError={hasError}
            {...layoutProps}
          />
        )
      }}
    />
  )
}
