import { yupResolver } from '@hookform/resolvers/yup'
import {
  PatchStoreBody,
  PatchStoreHourBody,
  PostStoreHourBody,
  deleteStoreHour,
  hoursByStoreQueryKey,
  patchStore,
  patchStoreHour,
  postStoreHour,
  storeByIdQueryKey
} from 'api/stores'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputRadio from 'components/InputRadio/InputRadio'
import Select, { Option } from 'components/Select/Select'
import FieldDisplay from 'components/Text/FieldDisplay'
import Textarea from 'components/Textarea/Textarea'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { API_ROUTES } from 'constants/configs'
import CanAccess from 'features/Permissions/CanAccess'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { useEffect } from 'react'
import { FieldArray, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { StoreDetails, StoreHours } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { Days } from 'types/schedule'
import { SibblingFieldsKeys, isHourAfterValidation, useFormSibblingFields } from 'utils/formHelper'
import * as yup from 'yup'
import { useStoreContext } from '../helper'
import {
  CityFieldsRow,
  FieldsContainer,
  FormDescriptionSection,
  FormEndSection,
  FormGeneralSection,
  FormLayout,
  TwoFieldsRow
} from './StoreInformationForm.style'
import StoreSchedule from './components/StoreSchedule'

interface Props {
  store: StoreDetails
  hours: StoreHours[]
}

const daySchema = yup.object().shape(
  {
    morningOpensAt: yup
      .string()
      .trim()
      .when('morningClosesAt', { is: (val) => !!val, then: (schema) => schema.required() }),
    morningClosesAt: yup
      .string()
      .when('morningOpensAt', { is: (val) => !!val, then: (schema) => schema.required() })
      .test('isBeforeStart', 'End hour need to take place after start hour', (value, context) =>
        isHourAfterValidation(value, context.parent?.morningOpensAt)
      ),
    morningId: yup.string().nullable(),
    afternoonOpensAt: yup
      .string()
      .when('afternoonClosesAt', { is: (val) => !!val, then: (schema) => schema.required() })
      .test('isBeforeStart', 'End hour need to take place after start hour', (value, context) => {
        return isHourAfterValidation(value, context.parent.morningClosesAt)
      }),
    afternoonClosesAt: yup
      .string()
      .when('afternoonOpensAt', { is: (val) => !!val, then: (schema) => schema.required() })
      .test('isBeforeStart', 'End hour need to take place before after hour', (value, context) =>
        isHourAfterValidation(value, context.parent?.afternoonOpensAt)
      ),
    afternoonId: yup.string().nullable()
  },
  [
    ['morningOpensAt', 'morningClosesAt'],
    ['afternoonOpensAt', 'afternoonClosesAt']
  ]
)

const baseSchema = {
  days: yup.array().of(daySchema),
  shortName: yup.string().trim().required(),
  contactEmail: yup.string().trim().nullable(),
  phoneNumber: yup.string().trim().nullable(),
  facebookPageUrl: yup.string().trim().nullable(),
  address: yup.string().trim().nullable(),
  zipCode: yup.string().trim().nullable(),
  city: yup.string().trim().nullable(),
  state: yup.string().trim().nullable(),
  country: yup.string().trim().nullable(),
  description: yup
    .object()
    // Shape is needed to avoid cyclic dependency, however, shape the entire schema is causing troubleshooting with InferType
    // so we need to only shape the needed part
    .shape(
      {
        howToGo: yup
          .string()
          .trim()
          .when('howToGoEn', { is: (val) => !!val, then: (schema) => schema.required() }),
        howToGoEn: yup
          .string()
          .trim()
          .when('howToGo', { is: (val) => !!val, then: (schema) => schema.required() }),

        openingHoursDescription: yup
          .string()
          .trim()
          .when('openingHoursDescriptionEn', { is: (val) => !!val, then: (schema) => schema.required() }),
        openingHoursDescriptionEn: yup
          .string()
          .trim()
          .when('openingHoursDescription', { is: (val) => !!val, then: (schema) => schema.required() }),
        exceptionalOpeningDescription: yup
          .string()
          .trim()
          .when('exceptionalOpeningDescriptionEn', { is: (val) => !!val, then: (schema) => schema.required() }),
        exceptionalOpeningDescriptionEn: yup
          .string()
          .trim()
          .when('exceptionalOpeningDescription', { is: (val) => !!val, then: (schema) => schema.required() })
      },
      [
        ['howToGo', 'howToGoEn'],
        ['openingHoursDescriptionEn', 'openingHoursDescription'],
        ['exceptionalOpeningDescriptionEn', 'exceptionalOpeningDescription']
      ]
    )
    .required(),

  active: yup.boolean(),
  franchised: yup.boolean(),
  cardSeller: yup.boolean(),
  warehouseDeliveryTime: yup.number(),
  exeptionalHours: yup
    .object()
    .shape(
      {
        openingClosing: yup.boolean(),
        exceptionalDate: yup.string(),
        exeptionalMorningOpensAt: yup.string(),
        exeptionalMorningClosesAt: yup.string(),
        exeptionalAfternoonOpensAt: yup.string(),
        exeptionalAfternoonClosesAt: yup.string()
      }
    )
}

type DaySplit = {
  id?: string | null
  opensAt?: string
  closesAt?: string
}
export interface StoreInformationsInputs extends Omit<yup.InferType<typeof schema>, 'days'> {
  days: Days[],
}

const schema = yup.object(baseSchema)

// Split day in 2 parts (morning & afternoon), later data treatment will be more simple this way
const daysAsDaysSplit = (days: Days[]): DaySplit[][] => {
  return days.map((day) => [
    { id: day.morningId, closesAt: day.morningClosesAt, opensAt: day.morningOpensAt },
    { id: day.afternoonId, closesAt: day.afternoonClosesAt, opensAt: day.afternoonOpensAt }
  ])
}

// Check if daySplit need to be updated to avoid useless POST or PATCH
const needHourUpdate = (field: DaySplit, currentHours: StoreHours[]) => {
  if (!_.some(field, (e) => !!e)) return false
  if (field.id) {
    const prevHour = currentHours.find((hour) => hour['@id'] === field.id)

    return !(prevHour?.opensAt === field.opensAt && prevHour?.closesAt === field.closesAt)
  }

  return true
}

export default function StoreInformationsForm({ store, hours }: Props) {
  const { t } = useTranslation()

  const getStoreDefaultValues = (store: StoreDetails): Partial<StoreInformationsInputs> => {
    return {
      shortName: store.shortName,
      contactEmail: store.contactEmail,
      phoneNumber: store.phoneNumber,
      facebookPageUrl: store.facebookPageUrl,
      address: store.address,
      zipCode: store.zipCode,
      city: store.city,
      state: store.state,
      description: {
        howToGo: store.howToGo,
        howToGoEn: store.howToGoEn,
        openingHoursDescription: store.openingHoursDescription,
        openingHoursDescriptionEn: store.openingHoursDescriptionEn,
        exceptionalOpeningDescription: store.exceptionalOpeningDescription,
        exceptionalOpeningDescriptionEn: store.exceptionalOpeningDescriptionEn
      },
      active: store.active ?? false,
      franchised: store.franchised ?? true,
      cardSeller: store.cardSeller ?? false,
      warehouseDeliveryTime: store.warehouseDeliveryTime
    }
  }

  const formProps = useForm<StoreInformationsInputs>({
    resolver: yupResolver(schema),
    defaultValues: getStoreDefaultValues(store)
  })

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    trigger
  } = formProps

  const { fields: timeFields, replace } = useFieldArray({
    control,
    name: 'days'
  })

  useEffect(() => {
    const days = _.groupBy(hours, 'dayOfWeek')
    const fields: FieldArray<StoreInformationsInputs, 'days'>[] = []

    //For each 7 days of a week
    for (let i = 1; i <= 7; i++) {
      const day = days[i]
      if (day) {
        const daySplits = _.sortBy(day, 'opensAt')

        const daySplitField: FieldArray<StoreInformationsInputs, 'days'> = {
          morningOpensAt: daySplits[0]?.opensAt,
          morningClosesAt: daySplits[0]?.closesAt,
          morningId: daySplits[0]?.['@id'] ?? null,
          afternoonOpensAt: daySplits[1]?.opensAt,
          afternoonClosesAt: daySplits[1]?.closesAt,
          afternoonId: daySplits[1]?.['@id'] ?? null
        }

        fields.push(daySplitField)
      } else {
        fields.push({
          morningOpensAt: undefined,
          morningClosesAt: undefined,
          morningId: null,
          afternoonOpensAt: undefined,
          afternoonClosesAt: undefined,
          afternoonId: null
        })
      }
    }
    replace(fields)
  }, [replace, hours])

  const queryClient = useQueryClient()

  const { mutate: mutateStore, isLoading: mutateStoreLoading } = useMutation(
    (body: PatchStoreBody) => patchStore(store.id!, body),
    {
      onSuccess: (updatedStore) => {
        const prev = queryClient.getQueryData<StoreDetails>(storeByIdQueryKey(store.id!))
        queryClient.setQueryData(storeByIdQueryKey(store.id!), { ...prev, ...updatedStore })
      }
    }
  )

  const { mutateAsync: postHourMutation, isLoading: postHourMutationLoading } = useMutation(
    (body: PostStoreHourBody) => postStoreHour(body),
    {
      onSuccess: (createdStoreHour) => {
        const prev = queryClient.getQueryData<StoreHours[]>(hoursByStoreQueryKey(store.id))
        queryClient.setQueryData(hoursByStoreQueryKey(store.id), [...(prev ?? []), createdStoreHour])
      }
    }
  )

  
  const { mutateAsync: patchStoreHourMutation, isLoading: patchHourMutationLoading } = useMutation(
    ({ body, id }: { body: PatchStoreHourBody; id: number }) => patchStoreHour(id, body),
    {
      onSuccess: (updatedHour) => {
        const prev = queryClient.getQueryData<StoreHours[]>(hoursByStoreQueryKey(store.id))
        queryClient.setQueryData(
          hoursByStoreQueryKey(store.id),
          prev?.map((hour) => (hour['@id'] === updatedHour['@id'] ? updatedHour : hour))
        )
      }
    }
    )

  const { mutateAsync: deleteStoreHourMutation, isLoading: deleteHourMutationLoading } = useMutation(
    (id: string) => deleteStoreHour(parseInt(id.replace(`/${API_ROUTES.storeHours.root}/`, ''))),
    {
      onSuccess: (_, id) => {
        const prev = queryClient.getQueryData<StoreHours[]>(hoursByStoreQueryKey(store.id))
        queryClient.setQueryData(
          hoursByStoreQueryKey(store.id),
          prev?.filter((e) => e['@id'] !== id)
        )
      }
    }
  )

  const onSubmit = (formData: StoreInformationsInputs) => {
    const { days, description, exeptionalHours, ...restData } = formData
    mutateStore({ ...restData, ...description })

    _.forEach(daysAsDaysSplit(days), async (day, index) => {
      for (let daySplit of day) {
        if (needHourUpdate(daySplit, hours)) {
          if (daySplit.id) {
            if (!!daySplit.opensAt && !!daySplit.closesAt) {
              await patchStoreHourMutation({
                id: parseInt(daySplit.id.replace(`/${API_ROUTES.storeHours.root}/`, '')),
                body: {
                  activity: 'Boutique',
                  dayOfWeek: index + 1,
                  opensAt: daySplit.opensAt,
                  closesAt: daySplit.closesAt,
                  store: store['@id']
                }
              })
            } else {
              deleteStoreHourMutation(daySplit.id)
            }
          } else {
            await postHourMutation({
              activity: 'Boutique',
              dayOfWeek: index + 1,
              opensAt: daySplit.opensAt,
              closesAt: daySplit.closesAt,
              store: store['@id']
            })
          }
        }
      }
    })

    console.log(exeptionalHours);
  }

  const deliveryDaysOptions: Option[] = _.range(10).map((_, index) => ({
    value: index + 1,
    label: t('page.stores.details.labels.deliveryDaysCount', { count: index + 1 })
  }))

  useFormSibblingFields<StoreInformationsInputs>(
    [
      ['description.howToGo', 'description.howToGoEn'],
      ['description.openingHoursDescription', 'description.openingHoursDescriptionEn'],
      ['description.exceptionalOpeningDescription', 'description.exceptionalOpeningDescriptionEn'],
      ..._.flatten(
        timeFields.map((timeField, index): SibblingFieldsKeys<StoreInformationsInputs>[] => [
          [`days.${index}.morningOpensAt`, `days.${index}.morningClosesAt`],
          [`days.${index}.afternoonOpensAt`, `days.${index}.afternoonClosesAt`]
        ])
      )
    ],
    { watch, trigger }
  )

  const { isMyStore } = useStoreContext()
  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)}>
      <FormGeneralSection>
        <FieldsContainer>
          <div>
            <Input
              register={register}
              id="shortName"
              label={t('page.stores.details.labels.storeName')}
              className={classNames({ 'is-invalid': errors.shortName })}
              disabled={true}
            />
            <BasicFieldValidationError
              error={errors.shortName}
              message={t('page.stores.details.infos.errors.required.storeName')}
            />
          </div>
          <TwoFieldsRow>
            <Input register={register} id="contactEmail" label={t('page.stores.details.labels.contactEmail')} />
            <Input
              register={register}
              id="phoneNumber"
              label={t('common.label.phone')}
              tooltip={
                <Tooltip id="phoneNumberTooltip">
                  <Trans i18nKey="page.stores.details.tooltips.phoneNumber" />
                </Tooltip>
              }
            />
          </TwoFieldsRow>
          <Input register={register} id="facebookPageUrl" label={t('page.stores.details.labels.facebookPageUrl')} />
        </FieldsContainer>

        <FieldsContainer>
          <Input register={register} id="address" label={t('common.label.address')} />
          <CityFieldsRow>
            <Input register={register} id="zipCode" label={t('common.label.zipCode')} />
            <Input register={register} id="city" label={t('common.label.city')} />
          </CityFieldsRow>
          <Input register={register} id="state" label={t('common.label.state')} />
        </FieldsContainer>
      </FormGeneralSection>

      <FormDescriptionSection>
        <div>
          <Textarea
            register={register}
            id="description.howToGo"
            label={t('page.stores.details.labels.howToGo')}
            className={classNames({ 'is-invalid': errors.description?.howToGo })}
          />
          <BasicFieldValidationError
            error={errors.description?.howToGo}
            message={t('form.validation.required.genericFr')}
          />
        </div>

        <div>
          <Textarea
            register={register}
            id="description.howToGoEn"
            label={t('page.stores.details.labels.howToGoEn')}
            className={classNames({ 'is-invalid': errors.description?.howToGoEn })}
          />
          <BasicFieldValidationError
            error={errors.description?.howToGoEn}
            message={t('form.validation.required.genericEn')}
          />
        </div>

        <div>
          <Input
            register={register}
            id="description.openingHoursDescription"
            label={t('page.stores.details.labels.openingHoursDescription')}
            className={classNames({ 'is-invalid': errors.description?.openingHoursDescription })}
            tooltip={
              <Tooltip id="openingHoursDescriptionTooltip">
                <Trans i18nKey="page.stores.details.tooltips.openingHoursDescription" />
              </Tooltip>
            }
          />
          <BasicFieldValidationError
            error={errors.description?.openingHoursDescription}
            message={t('form.validation.required.genericFr')}
          />
        </div>

        <div>
          <Input
            register={register}
            id="description.openingHoursDescriptionEn"
            label={t('page.stores.details.labels.openingHoursDescriptionEn')}
            className={classNames({ 'is-invalid': errors.description?.openingHoursDescriptionEn })}
          />
          <BasicFieldValidationError
            error={errors.description?.openingHoursDescriptionEn}
            message={t('form.validation.required.genericEn')}
          />
        </div>

        <div>
          <Input
            register={register}
            id="description.exceptionalOpeningDescription"
            label={t('page.stores.details.labels.exceptionalOpeningDescription')}
            className={classNames({ 'is-invalid': errors.description?.exceptionalOpeningDescription })}
            tooltip={
              <Tooltip id="exceptionalOpeningDescriptionTooltip">
                <Trans i18nKey="page.stores.details.tooltips.exceptionalOpeningDescription" />
              </Tooltip>
            }
          />
          <BasicFieldValidationError
            error={errors.description?.exceptionalOpeningDescription}
            message={t('form.validation.required.genericFr')}
          />
        </div>

        <div>
          <Input
            register={register}
            id="description.exceptionalOpeningDescriptionEn"
            label={t('page.stores.details.labels.exceptionalOpeningDescriptionEn')}
            className={classNames({ 'is-invalid': errors.description?.exceptionalOpeningDescriptionEn })}
          />
          <BasicFieldValidationError
            error={errors.description?.exceptionalOpeningDescriptionEn}
            message={t('form.validation.required.genericEn')}
          />
        </div>
      </FormDescriptionSection>
      <FormProvider {...formProps}>
        <StoreSchedule timeFields={timeFields} store={store}/>
      </FormProvider>

      <CanAccess
        permissions={[RoleAction.ROLE_ACTION_STORE_EDIT_CORE]}
        deniedExtraCondition={!isMyStore}
        allowedComponent={
          <FormEndSection>
            <InputRadio
              control={control}
              id="active"
              label={t('page.stores.details.labels.activeStore')}
              tooltip={<TextTooltip id="activeStoreTooltip" text={t('page.stores.details.tooltips.activeStore')} />}
            />
            <InputRadio control={control} id="franchised" label={t('page.stores.details.labels.franchise')} />
            <InputRadio
              control={control}
              id="cardSeller"
              label={t('page.stores.details.labels.cardSeller')}
              tooltip={<TextTooltip id="activeStoreTooltip" text={t('page.stores.details.tooltips.cardSeller')} />}
            />
            <Select
              options={deliveryDaysOptions}
              control={control}
              id="warehouseDeliveryTime"
              label={t('page.stores.details.labels.deliveryTime')}
            />
          </FormEndSection>
        }
        deniedComponent={
          <FormEndSection>
            <FieldDisplay
              label={t('page.stores.details.labels.activeStore')}
              value={store.active ? t('common.radioButton.yes') : t('common.radioButton.no')}
              tooltip={<TextTooltip id="activeStoreTooltip" text={t('page.stores.details.tooltips.activeStore')} />}
            />
            <FieldDisplay
              label={t('page.stores.details.labels.franchise')}
              value={store.franchised ? t('common.radioButton.yes') : t('common.radioButton.no')}
            />
            <FieldDisplay
              label={t('page.stores.details.labels.cardSeller')}
              value={store.cardSeller ? t('common.radioButton.yes') : t('common.radioButton.no')}
              tooltip={<TextTooltip id="activeStoreTooltip" text={t('page.stores.details.tooltips.cardSeller')} />}
            />
            <FieldDisplay
              label={t('page.stores.details.labels.deliveryTime')}
              value={t('page.stores.details.labels.deliveryDaysCount', { count: store.warehouseDeliveryTime })}
            />
          </FormEndSection>
        }
      />

      <Button
        buttonType="submit"
        isLoading={
          mutateStoreLoading || patchHourMutationLoading || postHourMutationLoading || deleteHourMutationLoading
        }
      >
        {t('common.button.save')}
      </Button>
    </FormLayout>
  )
}
