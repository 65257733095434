import { EventEmitterContext } from 'contexts/eventEmitter'
import { DependencyList, useCallback, useContext, useEffect } from 'react'
import { Events } from 'types/events'

export function useEmit() {
  const em = useContext(EventEmitterContext)
  return useCallback(
    <E extends keyof Events>(type: E, args: Events[E]) => {
      em.emit(type, args)
    },
    [em]
  )
}

export function useEventEmitter() {
  const emit = useEmit()
  return {
    useListener: <E extends keyof Events>(type: E, listener: (args: Events[E]) => void, deps: DependencyList = []) => {
      const em = useContext(EventEmitterContext)
      useEffect(() => {
        em.add(type, listener)
        return () => {
          em.remove(type, listener)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [listener, type, ...deps])
    },
    emit
  }
}
