import { useEventEmitter } from 'hooks/useEventEmitter'
import { useState } from 'react'
import { EventType } from 'types/events'
import { Modals } from 'types/modals'
import OrderCreateModal from './OrderCreate/OrderCreateModal'
import StockCreateModal from './StockCreate/StockCreateModal'
import StoreCreateModal from './StoreCreate/StoreCreateModal'

const ModalsContainer = () => {
  const [openModal, setOpenModal] = useState<Modals | undefined>(undefined)
  const { useListener } = useEventEmitter()

  useListener(EventType.OpenModal, (modalType: Modals) => {
    setOpenModal(modalType)
  })

  return (
    <>
      <OrderCreateModal open={openModal === Modals.OrderCreate} onClose={() => setOpenModal(undefined)} />
      <StockCreateModal open={openModal === Modals.StockCreate} onClose={() => setOpenModal(undefined)} />
      <StoreCreateModal open={openModal === Modals.StoreCreate} onClose={() => setOpenModal(undefined)} />
    </>
  )
}

export default ModalsContainer
