import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { useTranslation } from 'react-i18next'
import {
  CountLine as StyledCountLine,
  OrderSummaryLayout,
  OrderSummaryTitle,
  TotalText
} from '../CustomerDetails.style'

const CountLine = ({ label, value }: { label: string; value?: number }) => {
  return (
    <StyledCountLine>
      <Text color="secondary">{label}</Text>
      <TextNumber value={value} suffix="€" placeholder="-" />
    </StyledCountLine>
  )
}

export default function OrderSummary() {
  const { t } = useTranslation()

  return (
    <OrderSummaryLayout>
      <div>
        <OrderSummaryTitle>{t('page.customer.details.summary.margin')}</OrderSummaryTitle>
        <TextNumber
          ContainerComponent={TotalText}
          suffix="€"
          decimalScale={2}
          color="secondary2"
          placeholder={t('common.label.notAvailable')}
        />
        <TextNumber decimalScale={2} suffix="%" />
      </div>
      <div>
        <OrderSummaryTitle>{t('page.customer.details.summary.order')}</OrderSummaryTitle>

        <TextNumber
          ContainerComponent={TotalText}
          suffix="€"
          decimalScale={2}
          color="secondary2"
          placeholder={t('common.label.notAvailable')}
        />
        <CountLine label={t('page.customer.details.summary.averageCart')} />
        <CountLine label={t('page.customer.details.summary.monthlyCart')} />
      </div>
      <div>
        <OrderSummaryTitle>{t('page.customer.details.summary.buyout')}</OrderSummaryTitle>
        <TextNumber
          ContainerComponent={TotalText}
          suffix="€"
          decimalScale={2}
          placeholder={t('common.label.notAvailable')}
          color="secondary2"
        />
        <CountLine label={t('page.customer.details.summary.cash')} />
        <CountLine label={t('page.customer.details.summary.coupon')} />
      </div>
    </OrderSummaryLayout>
  )
}
