import Button from 'components/Button/Button'
import { StyledText } from 'components/FormValidationErrors/FormValidationErrors.styles'
import { FitInputLayout } from 'components/InputNumber/InputNumber.styles'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.5rem 5rem;

  .fullwidth-input {
    width: 100%;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }

  ${FitInputLayout} {
    min-width: 150px;
    max-width: 354px;
    width: 100%;
  }
`
export const SplitFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxLargeScreen}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr 1fr;
  }

  ${FitInputLayout} {
    min-width: 50px;
    max-width: 100px;
    width: unset;
  }
`

export const FormActions = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  align-items: center;
  ${StyledText} {
    margin-top: 0;
  }
`

export const FundStepInformation = styled.div`
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const SectionTitle = styled(Text)`
  margin-bottom: 1rem;
`

export const ValidationButton = styled(Button)`
  width: 20rem;
`
