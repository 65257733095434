import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import styled from 'styled-components'

export const StyledSection = styled(Section)`
  display: flex;
  justify-content: center;
  padding-top: 6.5rem;
`

export const StyledCard = styled(CardLayout)`
  width: 55rem;
`

export const LoginButton = styled(Button)`
  width: 100%;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
`

export const Form = styled.form`
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  grid-row-gap: 2rem;

  padding: 5rem 10rem;
  padding-top: 3rem;

  @media screen and (max-width: ${(props) => props.theme.screenWidth.tabletScreen}) {
    padding: 5rem;
    padding-top: 2rem;
  }

  @media screen and (max-width: ${(props) => props.theme.screenWidth.mobileScreen}) {
    padding: 0;
    padding-bottom: 2rem;
  }
`
export const Header = styled.div``

export const PlayinLogo = styled.img`
  margin-top: 1rem;
  margin-left: 1.5rem;
`
