import styled from 'styled-components'
import theme from 'theme/theme'

export const StyledVerticalSeparator = styled.hr`
  border: none;
  border-left: 1px solid ${theme.textColors.secondary2};
  width: 1px;
  min-height: 4px;
  flex-grow: 1;
`

export const TextSeparator = styled.div<{ separator: string }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  & > *:not(:last-child) {
    &::after {
      content: '${(props) => props.separator}';
      margin: 0.5rem;
      font-weight: ${(props) => props.theme.fontWeight.regular};
    }
  }
`
