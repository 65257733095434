import { deleteFidelityPoint, fetchFidelityPoints, getFidelityPointUri } from 'api/fidelityPoints'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import Cell from 'components/SortedTable/Cell'
import { DeleteIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import DeleteModal from 'features/Modals/DeleteModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEventEmitter } from 'hooks/useEventEmitter'
import _, { forEach } from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Customer, FidelityPoint } from 'types/entities'
import { EventType } from 'types/events'
import { RoleAction } from 'types/playInApiInterfaces'
import { DeleteFidelityWrapper } from '../CustomerList.style'

interface Props {
  customer: Customer
  columns: Columns<FidelityPoint>
  setCount: (count: number) => void
  setIsActive: (isActive: boolean) => void
}
export default function ActivePoints({ customer, columns, setCount, setIsActive }: Props) {
  const { checkMatch } = useCheckMatch()
  const permissions = [RoleAction.ROLE_ACTION_CUSTOMER_POINTS]

  const { t } = useTranslation()

  let query = getFidelityPointUri({
    customer: customer['@id'],
    pagination: false,
    activeOrWaiting: true
  })

  const [deleteList, setDeleteList] = useState<Array<number | undefined>>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const { data, isFetching, refetch } = useQuery(query, () => fetchFidelityPoints(query), {
    onSuccess: (points) => {
      let pointCount = points
        .filter((point) => point.active)
        .reduce((prev, point) => prev + (point.value! - point.consumed!), 0)

      // It is possible to have 0 active point but still have FIdelityPoints item
      // So we need to differentiate this 2 states
      setCount(pointCount)
      setIsActive(points.length > 0)
    }
  })

  const { useListener } = useEventEmitter()

  useListener(EventType.AddFidelityPoint, () => {
    refetch()
  })

  useListener(EventType.TransformFidelityPoint, () => {
    refetch()
  })

  const { mutate: deletePoint, isLoading: isDeleting } = useMutation((id: number) => deleteFidelityPoint(id), {
    onSuccess: (_, id) => {
      setDeleteList((prev) => prev.filter((e) => e !== id))
      refetch()
    }
  })

  let isDeletingAll = deleteList.length === data?.length

  const handleToggleAll = () => setDeleteList(isDeletingAll ? [] : data ? data?.map((point) => point.id) : [])

  const handleDelete = () => {
    forEach(deleteList, (toDelete) => {
      if (toDelete) deletePoint(toDelete)
    })
    refetch()
  }

  columns = checkMatch(permissions)
    ? [
        {
          key: 'checkbox',
          decorator: (point: FidelityPoint) => (
            <Cell className={point.waiting ? 'disabled' : ''}>
              <Checkbox
                className={classNames({
                  checked: deleteList.includes(point.id),
                  disabled: point.waiting
                })}
                onClick={() => setDeleteList((prev) => _.xor(prev, [point.id]))}
              />
            </Cell>
          )
        },
        ...columns
      ]
    : columns

  return (
    <>
      <ShouldHide permissions={permissions}>
        <DeleteFidelityWrapper>
          <Checkbox className={isDeletingAll ? 'checked' : ''} onClick={handleToggleAll} />
          <Button
            icon={DeleteIcon}
            variant="white"
            shape="circle"
            onClick={() => setDeleteModalOpen(true)}
            disabled={!(deleteList.length > 0)}
            isLoading={isDeleting}
          />
        </DeleteFidelityWrapper>
      </ShouldHide>
      <Listing
        emptyText={t('page.customer.details.fidelity.active.empty')}
        data={data ?? []}
        columns={columns}
        isFetching={isFetching}
      />

      <DeleteModal
        callback={handleDelete}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title={t('page.customer.details.fidelity.active.confirmDelete')}
      />
    </>
  )
}
