import { yupResolver } from '@hookform/resolvers/yup'
import { addLitigation, getCustomerLitigationsQueryKey, PostLitigationBody } from 'api/litigations'
import { GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import { MoreIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useLitigationCategoriesOptions } from 'hooks/useSelectOptions'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Litigation, Order } from 'types/entities'
import * as yup from 'yup'
import { LitigationCategorySelect, LitigationFormContainer } from './OrderLitigationModal.style'

interface Props {
  order: GetOrderByIdResponse
}

interface LitigationFormData {
  category: string
}

const schema = yup.object().shape({
  category: yup.string().trim().required()
})

export default function OrderLitigationForm({ order }: Props) {
  const { t } = useTranslation()
  const { getAvailableOrderLitigationCategories } = useLitigationCategoriesOptions()
  const availableOptions = getAvailableOrderLitigationCategories(order)

  const { control, handleSubmit, reset } = useForm<LitigationFormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  })

  const queryClient = useQueryClient()
  const orderKey = orderByIdQueryKey(order.id!)
  const litigationsKey = getCustomerLitigationsQueryKey(order.customer?.id!)
  const { mutate, isLoading } = useMutation((body: PostLitigationBody) => addLitigation(body))

  const onSubmit = ({ category }: LitigationFormData) => {
    const body: PostLitigationBody = { category, relatedOrder: order['@id'] }
    mutate(body, {
      onSuccess: (data) => {
        // Add the new litigation to the related order.
        const prev = queryClient.getQueryData<Order>(orderKey)
        queryClient.setQueryData<Order>(orderKey, {
          ...prev,
          litigations: [...prev?.litigations!, data]
        })
        const customerLitigations = queryClient.getQueryData<Litigation[]>(litigationsKey)
        queryClient.setQueryData<Litigation[]>(litigationsKey, [...(customerLitigations ?? []), data])
        reset()
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LitigationFormContainer>
        <LitigationCategorySelect
          control={control}
          id="category"
          options={availableOptions}
          label={t('page.order.detail.tab.misc.litigation.label')}
          placeholder={t('page.order.detail.tab.misc.litigation.placeholder')}
        />
        <Button
          shape="circle"
          variant="default"
          icon={MoreIcon}
          buttonType="submit"
          iconColor="white"
          isLoading={isLoading}
        />
      </LitigationFormContainer>
    </form>
  )
}
