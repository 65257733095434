import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const PlayAreaChoiceContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`

export const ChoiceItem = styled.div`
  min-width: 14rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const FormCard = styled(CardLayout)`
  display: grid;
  gap: 2rem;
  ${Title2} {
    margin: 0;
  }
`
