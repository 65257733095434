import { getCountryFrom } from 'app/OrderDetails/components/ShippingSection/ShippingSection'
import { API_ROUTES, DEFAULT_COUNTRY_CODE } from 'constants/configs'
import { useMemo } from 'react'
import { OrderItem, ShippingMode } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'
import { useGetCountry } from './countries'

type FetchShippingModesResponse = Api.GetShippingModes.ResponseBody
type FetchShippingModesParams = Api.GetShippingModes.RequestQuery

export const fetchShippingModes = async (query: string): Promise<ShippingMode[]> => {
  const res = await playInApi.get<FetchShippingModesResponse>(query)

  return res.data['hydra:member']
}

export const getShippingModesUri = (params: FetchShippingModesParams = {}): string => {
  const qs = stringify(params)
  return [API_ROUTES.shippingModes.root, qs].join('?')
}

const getOrderShippingModesUri = (orderId: number, countryId: string | number | null): string => {
  return `${API_ROUTES.shippingModes.availableForOrder}/${orderId}/country/${countryId}`
}

export const useOrderShippingModes = (order?: OrderItem) => {
  const { data: apiCountries } = useGetCountry(DEFAULT_COUNTRY_CODE)
  const DEFAULT_COUNTRY_ID = apiCountries?.[0].id
  let countryId = getCountryFrom(order) ?? DEFAULT_COUNTRY_ID
  const query = countryId ? getOrderShippingModesUri(order?.id!, countryId) : ''

  return {
    query,
    queryKey: useMemo(() => ['shippingModes', query], [query])
  }
}
