import { yupResolver } from '@hookform/resolvers/yup'
import { activateGift } from 'api/giftcard'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import { BarcodeIcon } from 'constants/icons'
import { useApiNotifications } from 'hooks/useApiNotifications'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEmit } from 'hooks/useEventEmitter'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EventType } from 'types/events'
import * as yup from 'yup'
import { GiftManagementFormLayout } from './GiftCardManagementForm.style'

const schema = yup.object().shape({
  ean128: yup.string().trim().required()
})

export type FormData = {
  ean128: string
}

function GiftCardManagementForm({ location }: { location?: string }) {
  const { emitErrorNotification, getApiErrorNotificationContent } = useApiNotifications()
  const emitNotification = useEmit()

  const {
    handleSubmit,
    formState: { errors },
    register,
    resetField
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const { t } = useTranslation()
  const { mutate: activateGiftMutation, isLoading } = useMutation(
    (ean128: string) => activateGift(ean128, { store: location }),
    {
      emitDefaultErrorNotification: false,
      onError: (error, variables) => {
        if (error.status === 404) {
          emitNotification(
            EventType.Notification,
            getApiErrorNotificationContent(t('notification.gifts.notFound', { barCode: variables }), '')
          )
        } else {
          emitErrorNotification(error.status, error.data, '')
        }
      }
    }
  )

  const onSubmit = (formData: FormData) => {
    resetField('ean128')
    activateGiftMutation(formData?.ean128)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GiftManagementFormLayout>
        <Input
          id="ean128"
          label={t('common.label.barcode')}
          register={register}
          className={`${errors.ean128 ? 'is-invalid' : ''}`}
        ></Input>

        <Button buttonType="submit" isLoading={isLoading} icon={BarcodeIcon} shape="circle" />
      </GiftManagementFormLayout>
      <BasicFieldValidationError error={errors.ean128} message={t('form.validation.required.barCode')} />
    </form>
  )
}

export default GiftCardManagementForm
