import { yupResolver } from '@hookform/resolvers/yup'
import { consumeGiftCard } from 'api/giftcard'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import { MoreIcon } from 'constants/icons'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEmit } from 'hooks/useEventEmitter'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Customer } from 'types/entities'
import { EventType } from 'types/events'
import { RoleAction } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { GiftForm } from '../CustomerDetails.style'

const schema = yup.object().shape({
  giftCard: yup.string().trim().required()
})

interface FormData {
  giftCard: string
}

export default function GiftCardForm({ customer }: { customer: Customer }) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  })
  const { t } = useTranslation()

  const emit = useEmit()
  const { mutate: consumeCard, isLoading } = useMutation((ean: string) => consumeGiftCard(customer.id!, ean), {
    // When giftcard is used, we need to refetch voucher (as we do when voucher is added)
    onSuccess: () => emit(EventType.AddCustomerVoucher, null)
  })

  const onSubmit = (formData: FormData) => {
    consumeCard(formData.giftCard)
  }
  return (
    <GiftForm onSubmit={handleSubmit(onSubmit)}>
      <div>
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_CUSTOMER_EDIT]}>
          <Input
            label={t('page.customer.details.form.addGiftCard')}
            register={register}
            id="giftCard"
            className={classNames({
              'is-invalid': errors.giftCard
            })}
          />
        </ShouldDisable>
      </div>

      <ShouldDisable permissions={[RoleAction.ROLE_ACTION_CUSTOMER_EDIT]}>
        <Button icon={MoreIcon} shape="circle" buttonType="submit" isLoading={isLoading} iconColor="white" />
      </ShouldDisable>
      <BasicFieldValidationError error={errors.giftCard} message={t('form.validation.required.giftCard')} />
    </GiftForm>
  )
}
