import { FetchOrdersQueryParams, getOrderQuickSearchUri, getOrdersUri, useOrdersPaginatedQuery } from 'api/orders'
import { getRecipientNameFromOrder } from 'app/OrderDetails/helper'
import { OldIcon } from 'app/ProductManagement/ProductManagement.style'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import Svg from 'components/Svg/Svg'
import { Text, TextWithIcon } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import MainTitle from 'components/Title/MainTitle'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { CheckIcon, CrossIcon, PlayinPIcon, PrintIcon, WarehouseIcon } from 'constants/icons'
import { isToday as isTodayFn } from 'date-fns'
import CountWithPerPageSelect from 'features/Listing/CountWithPerPageSelect'
import Listing from 'features/Listing/Listing'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useProductFamilies } from 'hooks/entityHooks/familyHooks'
import { useModals } from 'hooks/useModals'
import { useOrderStatusOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation } from 'react-router-dom'
import { Order } from 'types/entities'
import { OngoingStatuses } from 'types/enums'
import { Modals } from 'types/modals'
import { OrderStatus, RoleAction, ShippingTypeName } from 'types/playInApiInterfaces'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatCalendarDate, hourFormat } from 'utils/dates'
import { getAccountTypeIcon } from 'utils/externalSellers'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import { mergeFormQueryParams } from 'utils/queryParams'
import { CreateCard, FamilyIconsWrapper, StyledTitle2 } from './OrderManage.style'
import OrderManageForm from './form/OrderManageForm'

const defaultSort: OrderParam<FetchOrdersQueryParams> = {
  sortedBy: 'date',
  direction: SortingDirection.Desc,
  queryParams: { 'order[createdAt]': SortingDirection.Desc }
}

function OrderManage() {
  const location = useLocation() as Location & { state: null | FetchOrdersQueryParams }
  const { t } = useTranslation()
  const [store] = useAtom(defaultStoreAtom)
  const { openModal } = useModals()
  const [orderQueryParam, setOrderQueryParam] = useState<OrderParam<FetchOrdersQueryParams>>(defaultSort)
  const [perPage, setPerPage] = useState(30)

  // Set to false is user trigger a new search by touching the form,
  // Stay at true if store changed
  const [isPristine, setIsPristine] = useState(true)

  const defaultQueryParams = useMemo(
    () => ({
      //If selected store is a warehouse, we want to see all ongoing orders & orders with cancel request
      // Otherwise, we want to see all ongoing orders, orders with cancel request & sent orders
      'status[]': store?.warehouse
        ? [...OngoingStatuses, OrderStatus.CanceledRequested]
        : [...OngoingStatuses, OrderStatus.Send, OrderStatus.CanceledRequested],
      'sentOrReceivedBy[]': [store?.['@id']!]
    }),
    [store]
  )

  const [queryParams, setQueryParams] = useState<FetchOrdersQueryParams>(location?.state ?? defaultQueryParams)

  const {
    data: orders,
    isFetching,
    isFetchingNextPage,
    totalItems,
    fetchNextPage,
    hasNextPage
  } = useOrdersPaginatedQuery(
    queryParams.search
      ? ['orderSidebar', queryParams.search, orderQueryParam.queryParams]
      : ['orders', queryParams, orderQueryParam.queryParams, perPage],
    queryParams.search
      ? getOrderQuickSearchUri(queryParams.search, orderQueryParam.queryParams)
      : getOrdersUri(
          mergeFormQueryParams({
            queryParams,
            orderQueryParam: orderQueryParam.queryParams,
            perPage: { itemsPerPage: perPage }
          })
        ),
    {
      staleTime: 1000
    }
  )

  useEffect(() => {
    // Listen search made from order sidebar search
    // and display results directly
    if (location.state) {
      // as we consumed current state, we reset it for futur reload
      window.history.replaceState({}, document.title)
      setQueryParams(location?.state)
    }
  }, [location.state])

  // When store change, we need to change sourceReference <===> hiboutikId
  useEffect(() => {
    setQueryParams((prevQps) => {
      const newQp = { ...prevQps }

      // store vs warehouse: switch params to match logic
      if (prevQps.hiboutikId || prevQps.sourceReference) {
        store?.warehouse
          ? _.chain(newQp).set('sourceReference', prevQps.hiboutikId).omit('hiboutikId').value()
          : _.chain(newQp).set('hiboutikId', prevQps.sourceReference).omit('sourceReference').value()
      }

      newQp['sentOrReceivedBy[]'] = [store?.['@id']!]

      // If user didn't touch the form, when changing location, change default status params
      if (isPristine) {
        newQp['status[]'] = defaultQueryParams['status[]']
      }

      return newQp
    })
  }, [store, isPristine, defaultQueryParams])

  const handleFilters = (filters: { [key in string]: FetchOrdersQueryParams }) => {
    setQueryParams((prev) => {
      const newParams = mergeFormQueryParams<FetchOrdersQueryParams>(filters)
      // If params has changed, mark form as not pristine
      if (!_.isEqual(newParams, prev)) {
        setIsPristine(false)
      }
      return newParams
    })
  }

  const { getStatusTraduction } = useOrderStatusOptions()
  const { getFamilyIcon, getFamilyName } = useProductFamilies()

  const columns: Column<Order, FetchOrdersQueryParams>[] = [
    {
      key: 'date',
      name: t('common.label.date'),
      sortable: true,
      orderQueryParam: 'order[createdAt]',
      decorator: (order) => {
        const isToday = !!order.createdAt && isTodayFn(new Date(order.createdAt))
        return (
          <ClickableCell route={RouteName.OrderDetails} value={order.id}>
            {isToday ? hourFormat(order.createdAt) : formatCalendarDate(order.createdAt)}
          </ClickableCell>
        )
      }
    },
    {
      key: 'id',
      name: t('common.label.number'),
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text fontWeight="medium">{order.referenceNumber}</Text>
        </ClickableCell>
      )
    },
    {
      key: 'customer',
      name: t('common.label.customer'),
      decorator: (order) => {
        const icon = getAccountTypeIcon(order.customer?.accountType)
        return (
          <ClickableCell route={RouteName.OrderDetails} value={order.id}>
            <TextWithIcon iconPosition="left">
              {icon && <Svg svg={icon} />}
              {getRecipientNameFromOrder(order)}
            </TextWithIcon>
          </ClickableCell>
        )
      }
    },
    {
      key: 'stock',
      name: t('common.label.stock'),
      sortable: true,
      orderQueryParam: 'order[stock.shortName]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <TooltipHover id={`stock-${order.id}`} content={<Text>{order.stock?.shortName}</Text>}>
            {order.stock?.['@id'] === WAREHOUSE_STORE_ID ? (
              <Svg svg={WarehouseIcon} size="2rem" />
            ) : (
              <Svg svg={PlayinPIcon} size="2rem" />
            )}
          </TooltipHover>
        </ClickableCell>
      )
    },

    {
      key: 'shippingType',
      name: t('common.label.shippingMode'),
      sortable: true,
      orderQueryParam: 'order[shippingMode.name]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text color={order.shippingMode?.shippingType === ShippingTypeName.FAST ? 'danger' : 'black'}>
            {order.shippingMode?.name}
          </Text>
        </ClickableCell>
      )
    },
    {
      key: 'status',
      name: t('common.label.status'),
      sortable: true,
      orderQueryParam: 'order[status]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text fontStyle="italic">{order.status ? getStatusTraduction(order.status) : '-'}</Text>
        </ClickableCell>
      )
    },
    {
      key: 'print',
      name: <Svg svg={PrintIcon} />,
      sortable: true,
      orderQueryParam: 'order[printed]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          {order.printed ? (
            <TooltipHover id={`stock-${order.id}`} content={<Text>{t('common.order.printStatus.printed')}</Text>}>
              <OldIcon svg={CheckIcon} color={'success'} size="2rem" />
            </TooltipHover>
          ) : (
            <TooltipHover id={`stock-${order.id}`} content={<Text>{t('common.order.printStatus.notPrinted')}</Text>}>
              <OldIcon svg={CrossIcon} color="danger" size="2rem" />
            </TooltipHover>
          )}
        </ClickableCell>
      )
    },

    {
      key: 'total',
      name: t('common.label.subTotal'),
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <TextNumber value={order.subTotal} decimalScale={2} suffix={'\u00a0€'} />
        </ClickableCell>
      )
    },
    {
      key: 'family',
      name: t('common.label.family'),
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <FamilyIconsWrapper>
            {order.familiesInOrder?.map((family) => {
              //@ts-ignore TODO wrong typed family
              let codeName = family.codeName
              return (
                getFamilyIcon(codeName!) && (
                  <Svg key={codeName} svg={getFamilyIcon(codeName)!} size="2rem" title={getFamilyName(codeName)} />
                )
              )
            })}
          </FamilyIconsWrapper>
        </ClickableCell>
      )
    }
  ]

  const { checkMatch } = useCheckMatch()

  return (
    <Section>
      <MainTitle
        title={t('page.order.manage.title')}
        subtitle={queryParams.search ? '' : undefined}
        showStoreSelect={!queryParams.search}
      />
      <CreateCard>
        {queryParams.search ? (
          <>
            <StyledTitle2>{t('page.order.manage.byLocalisationTitle')}</StyledTitle2>
            <Button onClick={() => setQueryParams(defaultQueryParams)} size="app">
              {t('page.order.manage.byLocalisationBtn')}
            </Button>
          </>
        ) : (
          <>
            <StyledTitle2>{t('page.order.manage.createTitle')}</StyledTitle2>
            <ShouldDisable permissions={[RoleAction.ROLE_ACTION_ORDER_CREATE]}>
              <Button onClick={() => openModal(Modals.OrderCreate)} size="app">
                {t('page.order.manage.createOrder')}
              </Button>
            </ShouldDisable>
          </>
        )}
      </CreateCard>
      <OrderManageForm
        onSubmit={handleFilters}
        isLoading={isFetching}
        sidebarSearch={queryParams.search}
        defaultStatus={defaultQueryParams['status[]']}
        store={store ?? undefined}
        isPristine={isPristine}
      />

      <CardLayout>
        <Listing
          emptyText={t('page.order.manage.listing.emptyText')}
          columns={columns}
          data={orders}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          title={
            _.isEqual(queryParams['status[]'], defaultQueryParams['status[]'])
              ? t('page.order.manage.listing.titleCurrent', {
                  count: totalItems || 0
                })
              : t('page.order.manage.listing.title', {
                  count: totalItems || 0
                })
          }
          fetchNextPage={fetchNextPage}
          countText={t('page.order.manage.listing.countText', {
            current: orders?.length ?? 0,
            total: totalItems ?? 0
          })}
          bottomComponent={
            checkMatch([RoleAction.ROLE_ACTION_ORDERS_READ_ALL_STOCK]) && (
              <CountWithPerPageSelect onChange={(perPage) => setPerPage(perPage)} value={perPage} />
            )
          }
          totalCount={totalItems}
          hasNextPage={hasNextPage}
          defaultSort={defaultSort}
          onOrderChanged={setOrderQueryParam}
        />
      </CardLayout>
    </Section>
  )
}

export default OrderManage
