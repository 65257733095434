import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
  padding: 0.5rem 0px 0.5rem 2rem; ;
`

export const DetailsBody = styled.div`
  width: 49rem;
  max-width: 90%;
`

export const DetailSection = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: ${(props) => props.theme.colors.grey4} solid;
  border-width: 1px;
  display: grid;
  gap: 2rem;

  ${Title2} {
    margin-bottom: 0;
  }

  &:last-of-type {
    border-width: 0;
  }
`

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.8;
`
export const TotalText = styled.div`
  text-align: center;
`
