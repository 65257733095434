import { getSealedProductsUri, useSealedProductsPaginated } from 'api/sealedProducts'
import { SearchForm, useInputSearchForm } from 'components/InputSearchForm/InputSearchForm'
import { ProductIcon } from 'constants/icons'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const SealedProductSearchForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { reset, InputSearchForm } = useInputSearchForm()

  const { isFetching } = useSealedProductsPaginated(
    ['sealedProduct:sideSearch', search],
    getSealedProductsUri({ search: search, 'exists[secondHandOf]': false, 'order[old]': 'asc', 'order[name]': 'asc' }),
    {
      enabled: !!search,
      onSuccess: (data, total) => {
        if (total === 1) {
          navigate(`/product-details/${data[0].id}`)
        } else {
          navigate(`/product-manage`, { state: { search } })
        }
        setSearch('')
        reset()
      }
    }
  )

  const onSearch = async (formData: SearchForm) => {
    if (!formData.value.trim()) {
      navigate(`/product-manage`)
    } else {
      setSearch(formData.value)
    }
  }

  return (
    <InputSearchForm
      svg={ProductIcon}
      text={t('inputSearchForm.tooltip.product')}
      isLoading={isFetching}
      onSearch={onSearch}
      title={t('page.product.management.search_title')}
    />
  )
}

export default memo(SealedProductSearchForm)
