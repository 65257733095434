import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'

type FetchStoreZonesParams = Api.GetStoreZones.RequestQuery
type FetchStoreZonesResponse = Api.GetStoreZones.ResponseBody['hydra:member'][0]
const getStoreZonesUri = (params?: FetchStoreZonesParams): string => {
  const qs = stringify(params ?? {})
  return [API_ROUTES.storeZones.root, qs].join('?')
}

export const usePaginatedStoreZones = (
  queryKey: QueryKey,
  params: FetchStoreZonesParams,
  options?: PaginatedHookOptions<FetchStoreZonesResponse>
) => usePaginatedQuery<FetchStoreZonesResponse>(queryKey, getStoreZonesUri(params), options)
