import { Coupon, getCouponsUri } from 'api/coupons'
import { editOrderCoupon, PatchOrderCouponRequest, postOrderCoupon, PostOrderCouponBody } from 'api/orderCoupons'
import {
  deleteOrderCoupon,
  deleteOrderVoucher,
  DeleteOrderVoucherBody,
  editOrder,
  EditOrderBody,
  GetOrderByIdResponse,
  orderByIdQueryKey
} from 'api/orders'
import { getVoucherCategoryUri, postVoucher, VoucherCategory } from 'api/vouchers'
import { Filter } from 'components/FiltersList/FiltersList'
import { FormattedNumberComponent } from 'components/InputFormattedNumber/InputFormattedNumber'
import { Option } from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { API_ROUTES } from 'constants/configs'
import { useMutation } from 'hooks/useAxiosMutation'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useVoucherCategoriesOptions } from 'hooks/useSelectOptions'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { VoucherCategoryName } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { getIdFromIri } from 'utils/queryParams'
import { CouponFilter } from './CouponCard.style'

const isVoucherCategoryIri = (iri: string) => iri.startsWith(API_ROUTES.voucherCategories.root)

export const useCouponVoucherFilters = (order: GetOrderByIdResponse) => {
  const { t } = useTranslation()
  const { getVoucherCategoryTraduction } = useVoucherCategoriesOptions()
  // Combined filters, intended for FilterList, using coupons and vouchers' IRIs as unique keys.
  const [filters, setFilters] = useState<Filter[]>([])

  useEffect(() => {
    const combinedFilters: Filter[] = []
    // Coupon filters (in practice there should be only one, though <Order>.coupons is array type)
    _.forEach(order?.coupons, (orderCoupon, index) => {
      const asteriskCode = _.repeat('*', index + 1) // Sets '*', '**' etc. in case of multiple coupons
      const code = `${orderCoupon.coupon?.code}\u00a0${asteriskCode}`
      combinedFilters.push({
        id: orderCoupon['@id']!,
        label: (
          <CouponFilter>
            <Text fontWeight="regular">{t('page.order.detail.tab.payment.couponPrefix', { code })}</Text>
          </CouponFilter>
        ),
        filterId: 'coupon'
      })
    })
    // Voucher filters, grouped by category
    const voucherCategories = _.chain(order?.vouchers)
      // Filter voucher to keep on RentralDiscount and orderCommercialGesture
      // Waiting refacto to conciders how to handle "bon d'achat" properly
      .filter((voucher) =>
        [VoucherCategoryName.RentalDiscount, VoucherCategoryName.OrderCommercialGesture].includes(
          voucher.category?.codeName!
        )
      )
      .groupBy('category.@id')
      .map((items, key) => ({
        id: key,
        name: getVoucherCategoryTraduction(items[0].category?.codeName!) || items[0].category?.codeName,
        value: _.chain(items).map('value').sum().value(),
        items: _.map(items, '@id') as string[],
        codeName: items[0].category?.codeName
      }))
      .value()

    _.forEach(voucherCategories, (category) => {
      combinedFilters.push({
        id: category.id,
        label: (
          <CouponFilter>
            <FormattedNumberComponent value={category.value} suffix=" €" decimalScale={2} />
            <Text fontWeight="regular">{category.name}</Text>
          </CouponFilter>
        ),
        filterId: category.codeName
      })
    })
    setFilters(combinedFilters)
  }, [order, t, getVoucherCategoryTraduction])

  return {
    couponVoucherFilters: filters
  }
}

export const useCouponVouchers = (order?: GetOrderByIdResponse) => {
  const [coupons, setCoupons] = useState<Coupon[]>([])
  const [couponOptions, setCouponOptions] = useState<Option[]>([])

  const {
    data: customerCoupons,
    fetchNextPage: fetchNextCoupons,
    hasNextPage: hasMoreCoupons,
    isFetchingNextPage: isFetchingMoreCoupons
  } = usePaginatedQuery<Coupon>(
    ['customer:coupons', order?.customer?.id!],
    getCouponsUri({
      customer: `${order?.customer?.id!}`,
      'expiresAt[after]': 'now',
      valid: true,
      'order[code]': SortingDirection.Asc
    }),
    {
      enabled: !!order?.customer?.id!,
      onSuccess: (coupons) => {
        setCoupons(coupons)
        setCouponOptions(
          coupons
            .filter((coupon) => !order?.coupons?.find((orderCoupon) => orderCoupon.coupon?.['@id'] === coupon['@id']))
            .map((coupon) => ({
              label: coupon.code,
              value: coupon['@id']!
            }))
        )
      }
    }
  )

  const getCouponFromOption = (iri?: string) => customerCoupons?.find((coupon) => coupon['@id'] === iri)
  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)

  const { mutate: updateOrder, isLoading: isUpdatingOrder } = useMutation(
    (body: EditOrderBody) => editOrder(body, order?.id!),
    {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, { ...prev, ...data })
      }
    }
  )

  const { mutate: addOrderVoucher, isLoading: isAddingVoucher } = useMutation(
    ({ value, category }: { value: number; category: VoucherCategory }) =>
      postVoucher({
        customer: order?.customer?.['@id'],
        expiresAt: '+ 6 months',
        value,
        category: getVoucherCategoryUri(category),
        linkedOrder: order?.['@id'],
        store: order?.shippingMode?.store?.['@id']
      }),
    {
      onSuccess: (res) => {
        const { linkedOrder, ...voutcher } = res
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, {
          ...prev,
          ...linkedOrder,
          vouchers: [...prev?.vouchers!, voutcher]
        })
      }
    }
  )

  const { mutate: deleteVoucher } = useMutation(
    (body: DeleteOrderVoucherBody) => deleteOrderVoucher(order?.id!, body),
    {
      onSuccess: (updatedOrder) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, {
          ...prev,
          ...updatedOrder
        })
      }
    }
  )

  const { mutate: addOrderCoupon, isLoading: isAddingCoupon } = useMutation(
    (body: PostOrderCouponBody) => postOrderCoupon(body),
    {
      onSuccess: (response, { coupon: iri }) => {
        const { linkedOrder, ...couponData } = response

        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, {
          ...prev,
          ...linkedOrder,
          // POST /api/order-coupons currently doesn't populate <OrderCoupon>.coupon field
          coupons: [...prev?.coupons!, { ...couponData, coupon: getCouponFromOption(iri) }]
        })
        setCouponOptions(
          coupons
            .filter((coupon) => coupon['@id'] !== iri)
            .map((coupon) => ({
              label: coupon.code,
              value: coupon['@id']!
            }))
        )
      }
    }
  )

  const { mutateAsync: patchOrderCoupon, isLoading: isUpdatingCoupon } = useMutation(
    (body: PatchOrderCouponRequest & { '@id': string }) =>
      editOrderCoupon({ coupon: body.coupon }, getIdFromIri(body['@id']!)),
    {
      onSuccess: (response, body) => {
        const { linkedOrder, ...couponData } = response

        const prev = queryClient.getQueryData<Order>(key)
        const updatedCoupons = (prev?.coupons ?? []).map((el) =>
          // PATCH /api/order-coupons/{id} currently doesn't populate <OrderCoupon>.coupon field
          el['@id'] === body['@id'] ? { ...couponData, coupon: getCouponFromOption(body.coupon) } : el
        )
        setCouponOptions(
          coupons
            .filter((coupon) => coupon['@id'] !== body.coupon)
            .map((coupon) => ({
              label: coupon.code,
              value: coupon['@id']!
            }))
        )
        queryClient.setQueryData(key, {
          ...prev,
          ...linkedOrder,
          // POST /api/order-coupons currently doesn't populate <OrderCoupon>.coupon field
          // Subject to change, when multiple order coupons will be supported.
          coupons: updatedCoupons
        })
      }
    }
  )

  const { mutate: deleteCoupon } = useMutation((orderId: number) => deleteOrderCoupon(orderId), {
    onSuccess: (updatedOrder) => {
      const prev = queryClient.getQueryData<Order>(key)
      queryClient.setQueryData(key, {
        ...prev,
        ...updatedOrder
      })

      setCouponOptions(
        coupons.map((coupon) => ({
          label: coupon.code,
          value: coupon['@id']!
        }))
      )
    }
  })

  const removeCouponOrVoucher = async (filter: Filter) => {
    if (isVoucherCategoryIri(filter.id)) {
      const relatedOrderVouchers = (order?.vouchers || []).filter((voucher) => voucher.category?.['@id'] === filter.id)
      return deleteVoucher({ deletedVouchers: relatedOrderVouchers.map((voucher) => voucher['@id']!) })
    } else {
      // Otherwise, for order coupons, delete the item.
      return deleteCoupon(order?.id!)
    }
  }
  return {
    couponOptions,
    addOrderVoucher,
    addOrderCoupon,
    patchOrderCoupon,
    removeCouponOrVoucher,
    updateOrder,
    couponOptionsPagination: { fetchNextCoupons, hasMoreCoupons, isFetchingMoreCoupons },
    couponLoadings: { add: isAddingCoupon, update: isUpdatingCoupon },
    voucherLoadings: { add: isAddingVoucher },
    orderLoadings: { update: isUpdatingOrder }
  }
}
