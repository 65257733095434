import styled from 'styled-components'
import theme from 'theme/theme'
export const GiftCardManagementLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.5rem;
  align-items: flex-end;
  button {
    align-self: flex-end;
  }

  @media screen and (${theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`
