import BannerCreate from 'app/BannerCreate/BannerCreate'
import BannerEdit from 'app/BannerEdit/BannerEdit'
import BannerManagement from 'app/BannerManagement/BannerManagement'
import CardManagement from 'app/CardManagement/CardManagement'
import CashboxCreate from 'app/CashboxCreate/CashboxCreate'
import CashboxDetails from 'app/CashboxDetails/CashboxDetails'
import CashboxManagement from 'app/CashboxManagement/CashboxManagement'
import CollaboratorAccountOptions from 'app/CollaboratorAccountOptions/CollaboratorAccountOptions'
import CollaboratorDetails from 'app/CollaboratorDetails/CollaboratorDetails'
import CollaboratorManagement from 'app/CollaboratorManagement/CollaboratorManagement'
import CollectionCreate from 'app/CollectionCreate/CollectionCreate'
import CollectionDetails from 'app/CollectionDetails/CollectionDetails'
import CollectionManagemement from 'app/CollectionManagement/CollectionManagement'
import CustomerDetails from 'app/CustomerDetails/CustomerDetails'
import CustomerManagement from 'app/CustomerManagement/CustomerManagement'
import Error403 from 'app/Errors/Error403'
import Error404 from 'app/Errors/Error404'
import GiftCardManagement from 'app/GiftCardManagement/GiftCardManagement'
import Home from 'app/Home/Home'
import Login from 'app/Login/Login'
import OrderDetails from 'app/OrderDetails/OrderDetails'
import OrderManage from 'app/OrderManagement/OrderManage'
import OrderPreparationManage from 'app/OrderPreprationMange/OrderPreparationManage'
import OrderPrint from 'app/OrderPrint/OrderPrint'
import PermissionsManage from 'app/PermissionsManage/PermissionsManage'
import PricingTag from 'app/PricingTag/PricingTag'
import ProductDetails from 'app/ProductDetails/ProductDetails'
import ProductManagement from 'app/ProductManagement/ProductManagement'
import ProductRequestsManage from 'app/ProductRequestsManage/ProductRequestsManage'
import ProductSheetRequest from 'app/ProductSheetRequest/ProductSheetRequest'
import RestockStorePrint from 'app/RestockStorePrint/RestockStorePrint'
import ShippingLabelLog from 'app/ShippingLabelLog/ShippingLabelLog'
import StockDetails from 'app/StockDetails/StockDetails'
import StockManagement from 'app/StockManagement/StockManagement'
import StockQuantityAlert from 'app/StockQuantityAlert/StockQuantityAlert'
import StockRange from 'app/StockRange/StockRange'
import StoreDetails from 'app/StoreDetails/StoreDetails'
import StoreEventCreate from 'app/StoreEventCreate/StoreEventCreate'
import StoreEventDetails from 'app/StoreEventDetails/StoreEventDetails'
import StoreEventManage from 'app/StoreEventManage/StoreEventManage'
import StoreLabels from 'app/StoreLabels/StoreLabels'
import StoreManagement from 'app/StoreManagement/StoreManagement'
import MainLayout from 'components/Layouts/MainLayout/MainLayout'
import RestrictedRoute from 'features/Permissions/RestrictedRoute'
import useAuth from 'hooks/useAuth'
import { RouteName, routesTreeConfig } from 'permissions/permissions'
import { Navigate, Route, Routes } from 'react-router-dom'

type RouteConfig = {
  name: RouteName
  component: JSX.Element
}

export const routes: Array<RouteConfig> = [
  {
    name: RouteName.CardManagement,
    component: <CardManagement />
  },
  {
    name: RouteName.CashboxDetails,
    component: <CashboxDetails />
  },
  {
    name: RouteName.CashboxCreate,
    component: <CashboxCreate />
  },
  {
    name: RouteName.CashboxManagement,
    component: <CashboxManagement />
  },
  {
    name: RouteName.CollaboratorAccountOptions,
    component: <CollaboratorAccountOptions />
  },
  {
    name: RouteName.CollaboratorDetails,
    component: <CollaboratorDetails />
  },
  {
    name: RouteName.CollaboratorManagement,
    component: <CollaboratorManagement />
  },
  {
    name: RouteName.CollectionCreate,
    component: <CollectionCreate />
  },
  {
    name: RouteName.CollectionDetails,
    component: <CollectionDetails />
  },
  {
    name: RouteName.CollectionManagement,
    component: <CollectionManagemement />
  },
  {
    name: RouteName.CustomerDetails,
    component: <CustomerDetails />
  },
  {
    name: RouteName.CustomerManagement,
    component: <CustomerManagement />
  },
  {
    name: RouteName.GiftCardManagement,
    component: <GiftCardManagement />
  },
  {
    name: RouteName.OrderPrint,
    component: <OrderPrint />
  },
  {
    name: RouteName.OrderDetails,
    component: <OrderDetails />
  },
  {
    name: RouteName.OrderManage,
    component: <OrderManage />
  },
  {
    name: RouteName.OrderPreparationManage,
    component: <OrderPreparationManage />
  },
  {
    name: RouteName.PermissionManage,
    component: <PermissionsManage />
  },
  {
    name: RouteName.PricingTag,
    component: <PricingTag />
  },
  {
    name: RouteName.ProductDetails,
    component: <ProductDetails />
  },
  {
    name: RouteName.ProductManagement,
    component: <ProductManagement />
  },
  {
    name: RouteName.ProductSheetRequest,
    component: <ProductSheetRequest />
  },
  {
    name: RouteName.ProductRequestManagement,
    component: <ProductRequestsManage />
  },

  {
    name: RouteName.RestockStorePrint,
    component: <RestockStorePrint />
  },
  {
    name: RouteName.StoreLabels,
    component: <StoreLabels />
  },
  {
    name: RouteName.StockDetails,
    component: <StockDetails />
  },
  {
    name: RouteName.StockRange,
    component: <StockRange />
  },
  {
    name: RouteName.StockQuantityAlert,
    component: <StockQuantityAlert />
  },
  {
    name: RouteName.StockManagement,
    component: <StockManagement />
  },
  {
    name: RouteName.StoreEventManage,
    component: <StoreEventManage />
  },
  {
    name: RouteName.StoreEventCreate,
    component: <StoreEventCreate />
  },
  {
    name: RouteName.StoreEventDetails,
    component: <StoreEventDetails />
  },
  {
    name: RouteName.ShippingLabelLog,
    component: <ShippingLabelLog />
  },
  {
    name: RouteName.StoreManagement,
    component: <StoreManagement />
  },
  {
    name: RouteName.StoreDetails,
    component: <StoreDetails />
  },
  {
    name: RouteName.BannerCreate,
    component: <BannerCreate />
  },
  {
    name: RouteName.BannerEdit,
    component: <BannerEdit />
  },
  {
    name: RouteName.BannerManagement,
    component: <BannerManagement />
  }
]

export default function Router() {
  const { isAuth } = useAuth()

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={isAuth ? <MainLayout /> : <Navigate to="/login" />}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        {routesTreeConfig.map((route, index) => {
          const routeComponent = routes.find((node) => node.name === route.name)
          return (
            <Route
              key={index}
              path={route.path}
              element={<RestrictedRoute permissions={route.permissions}>{routeComponent?.component}</RestrictedRoute>}
            />
          )
        })}
        <Route path="/403" element={<Error403 />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  )
}
