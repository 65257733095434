import { cancelStoreEvent, GetStoreEventsQueryParams, getStoreEventsUri } from 'api/storeEvents'
import { getEventName } from 'app/StoreEventDetails/utils'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { DeleteIcon, WarningRedIcon } from 'constants/icons'
import { add } from 'date-fns'
import CountWithPerPageSelect from 'features/Listing/CountWithPerPageSelect'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import LinkButton from 'features/Permissions/LinkButton'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useMutation } from 'hooks/useAxiosMutation'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useEventStatusOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { StoreEvent } from 'types/entities'
import { CustomStoreEventStatus, UnmodifiableStoreEventStatus } from 'types/enums'
import { RoleAction, StoreEventStatus as EventStatus } from 'types/playInApiInterfaces'
import { OrderParam, SortingDirection } from 'types/sorting'
import { fullDateFormat } from 'utils/dates'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import { mergeFormQueryParams } from 'utils/queryParams'
import StoreEventSearchForm from './form/StoreEventSearchForm'
import NotAvailable from './NotAvailable'
import {
  ActionButtonContainer,
  CreateCardLayout,
  ErrorCellContainer,
  StyledColumnContainer
} from './StoreEventManage.styles'

export type SearchEventInputs = {
  date?: string
  status?: CustomStoreEventStatus
  range?: string
  format?: string
}

const isEventCancelable = (event: StoreEvent) =>
  !(UnmodifiableStoreEventStatus.includes(event.status!) || !!event.customersCount)

const StoreEventManage = () => {
  const { t } = useTranslation()
  const { checkMatch } = useCheckMatch()
  const [store] = useAtom(defaultStoreAtom)
  const [selectedEvents, setSelectedEvents] = useState<StoreEvent[]>([])
  const [perPage, setPerPage] = useState(30)

  const [isSearched, setIsSearched] = useState(false)
  const [orderQueryParam, setOrderQueryParam] = useState<OrderParam<GetStoreEventsQueryParams>>()
  const [queryParam, setQueryParam] = useState<GetStoreEventsQueryParams>({
    status: 'current',
    store: store?.['@id']!
  })

  const [deleteEventsModalOpen, toggleDeleteEventsModal] = useState<boolean>(false)

  const { getEventStatusLabel } = useEventStatusOptions()

  const columns = _.filter<Column<StoreEvent, GetStoreEventsQueryParams>>(
    [
      {
        key: 'checkbox',
        decorator: (event) => (
          <Cell>
            <Checkbox
              className={classnames({
                disabled: !isEventCancelable(event),
                checked: !!_.find(selectedEvents, { '@id': event['@id'] })
              })}
              onClick={() => setSelectedEvents((prev) => _.xor(prev, [event]))}
            />
          </Cell>
        )
      },
      {
        key: 'date',
        name: t('common.label.date'),
        sortable: true,
        orderQueryParam: 'order[occursAt]',
        decorator: (event) => {
          const date = fullDateFormat(event.startsAt)
          const capitalizedDate = date && date.charAt(0).toUpperCase() + date.slice(1)
          return (
            <ClickableCell route={RouteName.StoreEventDetails} value={event.id}>
              <Text>{capitalizedDate}</Text>
            </ClickableCell>
          )
        }
      },
      {
        key: 'name',
        name: t('common.label.name'),
        decorator: (event) => (
          <ClickableCell route={RouteName.StoreEventDetails} value={event.id}>
            <Text>{getEventName(event)}</Text>
          </ClickableCell>
        )
      },
      {
        key: 'range',
        name: t('common.label.category'),
        sortable: true,
        orderQueryParam: 'order[eventRange.name]',
        decorator: (event) => (
          <ClickableCell route={RouteName.StoreEventDetails} value={event.id}>
            <Text fontWeight="light">{event.eventRange?.name}</Text>
          </ClickableCell>
        )
      },
      {
        key: 'status',
        name: t('common.label.status'),
        decorator: (event) => {
          //@ts-ignore
          const statusLabel = getEventStatusLabel(event.status)
          return (
            <ClickableCell route={RouteName.StoreEventDetails} value={event.id}>
              {event.status === EventStatus.WaitingVoucher ? (
                <ErrorCellContainer>
                  <Svg svg={WarningRedIcon} size="2rem" />
                  <Text fontWeight="medium">{statusLabel} </Text>
                </ErrorCellContainer>
              ) : (
                statusLabel
              )}
            </ClickableCell>
          )
        }
      },
      {
        key: 'price',
        name: t('common.label.price'),
        decorator: (event) => (
          <ClickableCell route={RouteName.StoreEventDetails} value={event.id}>
            <StyledColumnContainer>
              <TextNumber value={event.price} suffix=" €" decimalScale={2} />
              {!!(event.priceWithoutDiscount && event.priceWithoutDiscount !== event.price) && (
                <TextNumber
                  value={event.priceWithoutDiscount}
                  prefix="("
                  suffix=" €)"
                  decimalScale={2}
                  fontWeight="light"
                  fontStyle="italic"
                />
              )}
            </StyledColumnContainer>
          </ClickableCell>
        )
      },
      {
        key: 'attendees',
        name: t('common.label.attendees'),
        decorator: (event) => (
          <ClickableCell route={RouteName.StoreEventDetails} value={event.id}>
            <Text>{`${event.customersCount} / ${event.maxCapacity ?? '-'}`}</Text>
          </ClickableCell>
        )
      }
    ],
    (column) => {
      if (column.key === 'checkbox') {
        return checkMatch([RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_ACTION])
      }

      return true
    }
  )

  const queryKey = [
    'store-events',
    mergeFormQueryParams<GetStoreEventsQueryParams>({
      queryParam,
      orderParam: orderQueryParam?.queryParams,
      store: { store: store?.['@id']! },
      perPage: { itemsPerPage: perPage }
    })
  ]

  const {
    data: storeEvents,
    totalItems,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasBeenCalled
  } = usePaginatedQuery<StoreEvent>(
    queryKey,
    getStoreEventsUri(
      mergeFormQueryParams<GetStoreEventsQueryParams>({
        queryParam,
        orderParam: orderQueryParam?.queryParams,
        store: { store: store?.['@id']! },
        perPage: { itemsPerPage: perPage }
      })
    ),
    {
      enabled: store?.['@id'] !== WAREHOUSE_STORE_ID
    }
  )

  useEffect(() => {
    //When selected store is warehouse, listing & form components are unmounted
    //As we don't have persistence for form values yet, we need to reset search
    if (store?.['@id'] === WAREHOUSE_STORE_ID) {
      setQueryParam({
        status: 'current',
        store: store?.['@id']!
      })
    }
  }, [store])

  const onSubmit = (formData: SearchEventInputs) => {
    setIsSearched(true)
    setQueryParam({
      status: formData?.status,
      eventRange: formData?.range,
      format: formData?.format,
      'occursAt[after]': formData.date || undefined,
      'occursAt[strictly_before]': !!formData?.date
        ? add(new Date(formData.date), { days: 1 }).toLocaleDateString('en-EN')
        : undefined,
      'order[occursAt]': [CustomStoreEventStatus.Current].includes(formData.status!)
        ? SortingDirection.Asc
        : SortingDirection.Desc
    })
  }

  const selectAllAvailableEvents = () => {
    setSelectedEvents((prevEvents) => {
      if (prevEvents.length === storeEvents.filter((event) => isEventCancelable(event)).length) {
        return []
      } else {
        return storeEvents.filter(isEventCancelable)
      }
    })
  }

  const queryClient = useQueryClient()
  const { mutateAsync: deleteEvent } = useMutation((id: number) => cancelStoreEvent(id), {
    onSuccess: (updatedEvent, id) => {
      const prev = queryClient.getQueryData(queryKey) as any
      const pages = prev.pages.map((page) => ({
        ...page,
        data: page.data.map((event) => (event.id === id ? { ...event, ...updatedEvent } : event))
      }))

      queryClient.setQueryData(queryKey, { ...prev, pages })
    }
  })

  const handleDelete = async () => {
    for (let event of selectedEvents) {
      await deleteEvent(event.id!)
    }
    setSelectedEvents([])
    toggleDeleteEventsModal(false)
  }

  if (store?.['@id'] === WAREHOUSE_STORE_ID) return <NotAvailable />

  return (
    <>
      <Section>
        <MainTitle title={t('page.events.management.title')} showStoreSelect={true} />
        <CreateCardLayout>
          <Title2>{t('page.events.management.create.title')}</Title2>

          <LinkButton size="long" route={RouteName.StoreEventCreate}>
            {t('page.events.management.create.action')}
          </LinkButton>
        </CreateCardLayout>

        <StoreEventSearchForm onSubmit={onSubmit} isLoading={isFetching && !isFetchingNextPage} />
        <CardLayout>
          {!hasBeenCalled ? (
            <Loader />
          ) : (
            <>
              <Title2>
                {isSearched
                  ? t('page.events.management.list.title', { count: totalItems ?? 0 })
                  : t('page.events.management.list.currentTitle', { count: totalItems ?? 0 })}
              </Title2>
              <ShouldHide
                permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_ACTION]}
                deniedExtraCondition={totalItems === 0}
              >
                <ActionButtonContainer>
                  <Checkbox
                    className={classnames({
                      checked:
                        !!selectedEvents.length &&
                        storeEvents.length &&
                        selectedEvents.length === storeEvents.filter(isEventCancelable).length,
                      disabled: !storeEvents.length
                    })}
                    onClick={selectAllAvailableEvents}
                  />
                  <TooltipHover id="remove-events" content={t('page.events.management.list.delete')}>
                    <Button
                      icon={DeleteIcon}
                      shape="circle"
                      variant={'white'}
                      onClick={() => toggleDeleteEventsModal(true)}
                      disabled={!selectedEvents.length || !storeEvents.length}
                    />
                  </TooltipHover>
                </ActionButtonContainer>
              </ShouldHide>
              <Listing
                columns={columns}
                data={storeEvents}
                totalCount={totalItems}
                hasNextPage={hasNextPage}
                isFetching={isFetching}
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                countText={t('page.events.management.list.countText', {
                  count: storeEvents?.length,
                  total: totalItems ?? ''
                })}
                emptyText={t('page.events.management.list.countText_zero')}
                onOrderChanged={setOrderQueryParam}
                bottomComponent={<CountWithPerPageSelect onChange={(perPage) => setPerPage(perPage)} value={perPage} />}
              />
            </>
          )}
        </CardLayout>
      </Section>
      <ConfirmModal
        open={deleteEventsModalOpen}
        onClose={() => toggleDeleteEventsModal(false)}
        title={t('page.events.management.list.deleteModal.title')}
        callback={handleDelete}
        confirmLabel={t('page.events.management.list.deleteModal.confirmLabel')}
      >
        <Listing columns={columns.filter((e) => e.key !== 'checkbox')} data={selectedEvents} />
      </ConfirmModal>
    </>
  )
}

export default StoreEventManage
