import { yupResolver } from '@hookform/resolvers/yup'
import { patchSealedProduct, PatchSealedProductBody } from 'api/sealedProducts'
import classnames from 'classnames'
import DatePicker from 'components/DatePicker/DatePicker'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputRadio from 'components/InputRadio/InputRadio'
import { LineBreakLabel } from 'components/Layouts/InputLayout/InputLayout.styles'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { SaveIcon } from 'constants/icons'
import { endOfYesterday, isAfter, isFuture } from 'date-fns'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Lang, SealedProduct, Tax } from 'types/entities'
import { ProductVisibility } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import {
  DiscountWrapper,
  FormSection,
  ProductFormGrid,
  ProductSecondFormSection,
  SaveButton,
  TextFormField
} from '../ProductDetailsTab.styles'

type FormData = {
  barCode?: string | null
  lang?: string
  releaseDate?: string
  old: boolean
  visibility?: ProductVisibility
  redirectTo?: string
  tax?: string
  specialDiscount?: boolean
  pricingPolicy?: boolean
  bookLaw?: boolean
  price?: number
  advisedPrice?: number | null
  discount?: number
  discountPercent?: number
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
}

type Props = {
  productId: string
}

const getProductDefaultValue = (product: SealedProduct) => ({
  barCode: product?.barCode,
  releaseDate: product?.releasedAt ?? undefined,
  old: product?.old ?? false,
  lang: product?.lang?.['@id'],
  visibility: product?.visibility ?? ProductVisibility.Default,
  redirectTo: product?.redirectTo ?? undefined,
  pricingPolicy: product?.pricingPolicy ?? false,
  bookLaw: product?.bookLaw ?? false,
  specialDiscount: product?.isSpecialDiscount ?? false,
  price: product?.price,
  advisedPrice: product?.advisedPrice,
  tax: product?.tax?.['@id'],
  discount: product?.discount,
  discountPercent: product?.discountPercentage,
  approximateReleaseDate: product?.approximateReleaseDate ?? undefined,
  approximateReleaseDateEn: product?.approximateReleaseDateEn ?? undefined
})

const EditProductForm = ({ productId }: Props) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const product = queryClient.getQueryData<SealedProduct>(['sealedProductById', productId])
  const langs = queryClient.getQueryData<Lang[]>(['langs']) ?? []
  const taxes = queryClient.getQueryData<Tax[]>(['taxes']) ?? []

  const schema = yup.object().shape(
    {
      barCode: yup.string().trim(),
      releaseDate: product?.releasedAt ? yup.string().trim().required() : yup.string().trim(),
      old: yup.boolean(),
      lang: yup.string().trim(),
      visibility: yup.string().trim(),
      redirectTo: yup.string().trim(),
      pricingPolicy: yup.boolean(),
      bookLaw: yup.boolean(),
      specialDiscount: yup.boolean(),
      price: yup.number().nullable(),
      advisedPrice: yup
        .number()
        .positive()
        .nullable()
        .when('pricingPolicy', {
          is: (pricingPolicy) => !!pricingPolicy,
          then: yup.number().positive().required()
        }),
      tax: yup.string().trim(),
      discount: yup.number().nullable(),
      discountPercent: yup.number().nullable(),
      approximateReleaseDate: yup
        .string()
        .trim()
        .when('approximateReleaseDateEn', {
          is: (val) => !!val && product?.releasedAt && isAfter(new Date(product?.releasedAt), endOfYesterday()),
          then: (schema) => schema.required()
        }),
      approximateReleaseDateEn: yup
        .string()
        .trim()
        .when('approximateReleaseDate', {
          is: (val) => !!val && product?.releasedAt && isAfter(new Date(product?.releasedAt), endOfYesterday()),
          then: (schema) => schema.required()
        })
    },
    [['approximateReleaseDate', 'approximateReleaseDateEn']]
  )

  const {
    register,
    setValue,
    trigger,
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: getProductDefaultValue(product!),
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset(getProductDefaultValue(product!))
  }, [product, reset])

  const [watchDiscount, watchDiscountPercent, watchPrice, watchPricingPolicy, oldProduct, watchReleaseDate, watchBookLaw] = watch([
    'discount',
    'discountPercent',
    'price',
    'pricingPolicy',
    'old',
    'releaseDate',
    'bookLaw'
  ])

  useEffect(() => {
    if (!watchDiscount) {
      setValue('discountPercent', 0)
      setValue('specialDiscount', false)
    } else if (_.isNumber(watchDiscount) && _.isNumber(watchPrice) && watchPrice > 0) {
      const percentage = (watchDiscount / watchPrice) * 100
      setValue('discountPercent', Math.trunc(percentage))
    }
  }, [watchDiscount, setValue, product, watchPrice])

  useEffect(() => {
    if(
        _.isNumber(watchDiscount)
        && watchDiscount > 0
        && watchBookLaw
    ){
      setError('bookLaw', {
        type: 'illegalProduct',
        message: t('form.validation.warning.bookLaw')
      })
    } else {
      clearErrors('bookLaw')
    }
  }, [watchBookLaw, watchDiscount, setError, clearErrors, product, t])

  const { mutate: updateProduct, isLoading: isMutationLoading } = useMutation(
    (body: PatchSealedProductBody) => patchSealedProduct(productId, body),
    {
      onSuccess: (updatedProduct) => {
        const prev = queryClient.getQueryData<SealedProduct>(['sealedProductById', productId])
        queryClient.setQueryData(['sealedProductById', productId], {
          ...prev,
          ...updatedProduct,
          advisedPrice: updatedProduct.advisedPrice ?? null
        })
      }
    }
  )

  function onSubmit(data: FormData) {
    const formData = {
      barCode: data.barCode,
      lang: data.lang,
      releasedAt: data.releaseDate || null,
      visibility: data.visibility,
      old: data.old,
      redirectTo: data.redirectTo,
      tax: data.tax,
      discount: data.discount,
      specialDiscount: data.specialDiscount,
      price: !data?.pricingPolicy ? data.price : undefined,
      advisedPrice: data.advisedPrice,
      pricingPolicy: data.pricingPolicy,
      bookLaw: data.bookLaw,
      approximateReleaseDate: data.approximateReleaseDate,
      approximateReleaseDateEn: data.approximateReleaseDateEn
    }

    const payload: PatchSealedProductBody = _.omitBy(formData, (value, key) => {
      // don't patch those if the didn't change
      if (['releasedAt', 'approximateReleaseDate', 'approximateReleaseDateEn'].includes(key)) {
        return formData[key] === product?.[key]
      }

      return false
    })

    updateProduct(payload)
  }

  const isFutureRelease = !!watchReleaseDate && isFuture(new Date(watchReleaseDate))

  return (
    <div>
      <Title2>{t('page.product.detail.tabs.detail.commonInfo')}</Title2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProductFormGrid>
          <FormSection>
            <Input id="barCode" register={register} label={t('common.label.barcode')} className="is" />
            <div>
              <DatePicker
                className={classnames({ 'is-invalid': errors.releaseDate })}
                id="releaseDate"
                defaultValue={product?.releasedAt || ''}
                register={register}
                setValue={setValue}
                trigger={trigger}
                label={t('common.label.releaseDate')}
              />
              <BasicFieldValidationError
                error={errors.releaseDate}
                message={t('form.validation.required.releasedDate')}
              />
            </div>

            <div>
              <Input
                id="approximateReleaseDate"
                register={register}
                label={t('common.label.approximateReleaseDate')}
                disabled={!isFutureRelease}
                className={classnames({ 'is-invalid': errors.approximateReleaseDate })}
                tooltip={
                  <Tooltip id="tooltipApproximateReleaseDate">
                    <Trans i18nKey="page.product.detail.tabs.detail.tooltips.approximateReleaseDate" />
                  </Tooltip>
                }
              />
              <BasicFieldValidationError
                error={errors.approximateReleaseDate}
                message={t('form.validation.required.generic')}
              />
            </div>
            <div>
              <Input
                id="approximateReleaseDateEn"
                register={register}
                label={t('common.label.approximateReleaseDateEn')}
                disabled={!isFutureRelease}
                className={classnames({ 'is-invalid': errors.approximateReleaseDateEn })}
                tooltip={
                  <TextTooltip
                    id="tooltipApproximateReleaseDateEn"
                    text={t('page.product.detail.tabs.detail.tooltips.approximateReleaseDateEn')}
                  />
                }
              />
              <BasicFieldValidationError
                error={errors.approximateReleaseDateEn}
                message={t('form.validation.required.generic')}
              />
            </div>
          </FormSection>
          <ProductSecondFormSection>
            <Select
              id="lang"
              options={langs
                .filter((lang) => lang.code !== 'random')
                .map((lang) => ({ value: lang['@id']!, label: lang.name! }))}
              control={control}
              label={t('common.label.lang')}
              enableUnselect={true}
              placeholder={t('common.select.defaultOptions.lang')}
            />
            <Select
              id="visibility"
              options={[
                {
                  label: t('common.select.productVisibility.always'),
                  value: ProductVisibility.AlwaysShown
                },
                {
                  label: t('common.select.productVisibility.inStock'),
                  value: ProductVisibility.Default
                },
                {
                  label: t('common.select.productVisibility.never'),
                  value: ProductVisibility.AlwaysHidden
                }
              ]}
              control={control}
              label={t('common.label.visibilityOnSite')}
            />
            <InputRadio
              display="checkbox"
              id="old"
              control={control}
              label={t('common.label.oldProduct')}
              layout="row-reverse"
            />
            <Input id="redirectTo" register={register} label={t('common.label.redirectTo')} disabled={!oldProduct} />
          </ProductSecondFormSection>
          <FormSection>
            <InputFormattedNumber
              id="price"
              suffix=" €"
              decimalScale={2}
              control={control}
              placeholder="0,00 €"
              label={t('common.label.mainPrice')}
              disabled={watchPricingPolicy}
            />
            <div>
              <InputFormattedNumber
                className={classnames({ 'is-invalid': errors.advisedPrice })}
                id="advisedPrice"
                suffix=" €"
                decimalScale={2}
                control={control}
                placeholder="0,00 €"
                label={t('common.label.advisedPrice')}
              />
              <BasicFieldValidationError
                error={errors.advisedPrice}
                message={t('form.validation.required.advisedPrice')}
              />
            </div>
            <div>
              <InputRadio
                  id="pricingPolicy"
                  display="checkbox"
                  control={control}
                  label={t('common.label.pricingPolicy')}
                  layout="row-reverse"
                  tooltip={
                    <Tooltip id="pricingPolicyTooltip">
                      <Trans
                          i18nKey={'page.product.detail.tabs.productStore.tooltips.pricingPolicy'}
                          components={{ br: <br /> }}
                      />
                    </Tooltip>
                  }
              />
              <InputRadio
                  id="bookLaw"
                  display="checkbox"
                  control={control}
                  label={t('common.label.bookLawSubjected')}
                  layout="row-reverse"
                  tooltip={
                    <Tooltip id="bookLawTooltip">
                      <Trans
                          i18nKey={'page.product.detail.tabs.productStore.tooltips.bookLaw'}
                          components={{ br: <br /> }}
                      />
                    </Tooltip>
                  }
              />
              <BasicFieldValidationError
                  message={t('form.validation.warning.bookLaw')}
                  error={errors.bookLaw}
              />
            </div>

          </FormSection>
          <FormSection>
            <Select
              id="tax"
              control={control}
              options={taxes?.map((tax) => ({ label: `${tax.rate} %`, value: tax['@id']! }))}
              label={t('common.label.tva')}
            />

            <DiscountWrapper>
              <InputFormattedNumber
                id="discount"
                suffix=" €"
                decimalScale={2}
                control={control}
                placeholder="0,00 €"
                label={t('common.label.discount')}
                disabled={!watchPrice}
              />

              <TextFormField>
                <Text fontWeight="light">{t('common.label.discountPercent')}</Text>
                <TextNumber value={watchDiscountPercent ?? 0} suffix=" %" fontWeight="medium" />
              </TextFormField>
            </DiscountWrapper>

            <InputRadio
              id="specialDiscount"
              label={t('common.label.specialDiscount')}
              labelComponent={LineBreakLabel}
              control={control}
              disabled={!watchDiscount}
            />
          </FormSection>
        </ProductFormGrid>
        <SaveButton buttonType="submit" shape="circle" icon={SaveIcon} isLoading={isMutationLoading} />
      </form>
    </div>
  )
}
export default EditProductForm
