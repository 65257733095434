import { ReactComponent as AccessorieFamilyIconSvg } from 'assets/svg/range/accessories.svg'
import { ReactComponent as BoardgameFamilyIconSvg } from 'assets/svg/range/boardgame.svg'
import { ReactComponent as DragonballFamilyIconSvg } from 'assets/svg/range/dragonball.svg'
import { ReactComponent as FigurinesFamilyIconSvg } from 'assets/svg/range/figurines.svg'
import { ReactComponent as FleshAndBloodFamilyIconSvg } from 'assets/svg/range/fleshblood.svg'
import { ReactComponent as LegoFamilyIconSvg } from 'assets/svg/range/lego.svg'
import { ReactComponent as MagicFamilyIconSvg } from 'assets/svg/range/mtg.svg'
import { ReactComponent as PaintsFamilyIconSvg } from 'assets/svg/range/paints.svg'
import { ReactComponent as PokemonFamilyIconSvg } from 'assets/svg/range/pkmn.svg'
import { ReactComponent as WarhammerFamilyIconSvg } from 'assets/svg/range/warhammer.svg'
import { ReactComponent as YugihoFamilyIconSvg } from 'assets/svg/range/yugiho.svg'

export const MagicFamilyIcon = MagicFamilyIconSvg
export const PokemonFamilyIcon = PokemonFamilyIconSvg
export const AccessorieFamilyIcon = AccessorieFamilyIconSvg
export const BoardgameFamilyIcon = BoardgameFamilyIconSvg
export const DragonballFamilyIcon = DragonballFamilyIconSvg
export const LegoFamilyIcon = LegoFamilyIconSvg
export const PaintsFamilyIcon = PaintsFamilyIconSvg
export const WarhammerFamilyIcon = WarhammerFamilyIconSvg
export const YugihoFamilyIcon = YugihoFamilyIconSvg
export const FleshAndBloodFamilyIcon = FleshAndBloodFamilyIconSvg
export const FigurinesFamilyIcon = FigurinesFamilyIconSvg
