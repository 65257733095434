import { RoleAction } from 'types/playInApiInterfaces'
import CanAccess from './CanAccess'

type Props = {
  permissions: RoleAction[]
  deniedExtraCondition?: boolean
  byPassRoleValidation?: boolean
}

const ShouldHide: React.FunctionComponent<Props> = ({
  children,
  permissions,
  deniedExtraCondition,
  byPassRoleValidation
}) => {
  return (
    <CanAccess
      permissions={permissions}
      allowedComponent={children}
      deniedComponent={<></>}
      deniedExtraCondition={deniedExtraCondition}
      byPassRoleValidation={byPassRoleValidation}
    />
  )
}

export default ShouldHide
