import { postStoreEvent, PostStoreEventBody } from 'api/storeEvents'
import EventStatusHeader from 'app/StoreEventDetails/components/EventStatusHeader'
import BottomMessage from 'components/BottomMessage/BottomMesssage'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { useMutation } from 'hooks/useAxiosMutation'
import { getPath, RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import StoreEventForm, { CreateEventInputs } from './form/StoreEventForm'

export default function StoreEventCreate() {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { mutate: createEvent, isLoading: createEventLoading } = useMutation(
    (body: PostStoreEventBody) => postStoreEvent(body),
    {
      onSuccess: (data) => {
        navigate(getPath(RouteName.StoreEventDetails).replace(':id', `${data.id}`))
      }
    }
  )

  const onSubmit = (formData: CreateEventInputs) => {
    //TODO Update schema, then name & nameEn will not be required anymore
    const { name, nameEn, occursAt, subtitle, subtitleEn, raisedPrice } = formData
    createEvent({
      ...formData,
      priceWithoutDiscount: raisedPrice,
      subtitle: subtitle ?? undefined,
      subtitleEn: subtitleEn ?? undefined,
      name: name ?? '',
      nameEn: nameEn ?? '',
      occursAt: new Date(occursAt).toLocaleDateString('en-EN')
    })
  }

  return (
    <Section>
      <EventStatusHeader
        statusStep={{
          open: { name: t('page.events.details.status.open') },
          discount: { name: t('page.events.details.status.discount') },
          closed: { name: t('page.events.details.status.closed') }
        }}
      />
      <MainTitle title={t('page.events.create.title')} />
      <StoreEventForm isLoading={createEventLoading} onSubmit={onSubmit} />
      <BottomMessage text={t('common.requiredLabel')} />
    </Section>
  )
}
