import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const CategoryItemWrapper = styled.div`
  display: grid;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;
  grid-template-columns: 1.5rem 1fr;
`
export const CategoryItemCard = styled(CardLayout)`
  margin-bottom: 0;
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`
export const CategoryItemActions = styled.div`
  border-left: 1px solid ${(props) => props.theme.borderColors.secondary};
  padding-left: 5rem;
  display: flex;
  gap: 2rem;
`
export const CategoryItemTitle = styled(Title2)`
  margin-bottom: 0;
`
export const CategoryItemProductsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`
export const EditCategoryFormWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.borderColors.secondary};
  padding-top: 3.5rem;
  margin-top: 3.5rem;
`
