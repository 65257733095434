import { Text } from 'components/Text/Text.styles'

interface Props {
  text: string
}
export default function BottomMessage({ text }: Props) {
  return (
    <div>
      <Text color="secondary" fontWeight="light" size="1rem">
        {text}
      </Text>
    </div>
  )
}
