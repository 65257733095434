import { GetPrintExclusionsQueryResponse } from 'api/printExclusions'
import Modal, { ModalProps } from 'components/Modal/Modal'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Text } from 'components/Text/Text.styles'
import Listing from 'features/Listing/Listing'
import { RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { OrderItem } from 'types/entities'
import { formatCalendarDate } from 'utils/dates'
import { Container } from './ExcludedOrdersModal.styles'

type Props = ModalProps & {
  printExclusion?: GetPrintExclusionsQueryResponse
  orders: OrderItem[]
}

const ExcludedOrdersModal = ({ open, onClose, printExclusion, orders }: Props) => {
  const { t } = useTranslation()

  const columns = [
    {
      name: t('common.label.date'),
      key: 'date',
      decorator: (item: OrderItem) => (
        <ClickableCell route={RouteName.OrderDetails} value={item.id}>
          <Text>{formatCalendarDate(item.createdAt)}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.number_abbreviated'),
      key: 'referenceNumber',
      decorator: (item: OrderItem) => (
        <ClickableCell route={RouteName.OrderDetails} value={item.id}>
          <Text fontWeight="light" color="secondary">
            {item.referenceNumber}
          </Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.customer'),
      key: 'name',
      decorator: (item: OrderItem) => (
        <ClickableCell route={RouteName.OrderDetails} value={item.id}>
          <Text>{item.customer?.fullName}</Text>
        </ClickableCell>
      )
    }
  ]

  return printExclusion ? (
    <Modal open={open} onClose={onClose}>
      <Container>
        <Listing
          columns={columns}
          data={orders}
          totalCount={orders.length}
          emptyText={t('page.order.print.exclustionList.modal.emptyText', { edition: printExclusion.edition?.name })}
          title={t('page.order.print.exclustionList.modal.title', {
            count: orders.length,
            edition: printExclusion.edition?.name
          })}
        />
      </Container>
    </Modal>
  ) : (
    <></>
  )
}

export default ExcludedOrdersModal
