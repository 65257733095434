import { useEffect, useState } from 'react'
import { Control, Controller, FieldValues, Path, RefCallBack, UseFormRegister } from 'react-hook-form'
import { InputTextStyle } from '../Input/Input.styles'
import InputLayout, { LayoutProps } from '../Layouts/InputLayout/InputLayout'

export interface InputProps<T> extends LayoutProps {
  type?: string
  id: Path<T>
  placeholder?: string
  disabled?: boolean
  autofocus?: boolean
  /** Pass DOM attributes to the input element */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

interface ReactHookFormProps<T extends FieldValues> {
  register: UseFormRegister<T>
}

interface Props<T extends FieldValues> extends InputProps<T>, ReactHookFormProps<T> {}

function Input<T extends FieldValues>({
  className,
  label,
  id,
  type = 'text',
  register,
  layout = 'column',
  tooltip,
  placeholder,
  disabled,
  autofocus,
  secondaryText,
  inputProps
}: Props<T>) {
  return (
    <InputLayout
      className={className}
      label={label}
      layout={layout}
      tooltip={tooltip}
      disabled={disabled}
      secondaryText={secondaryText}
    >
      <InputTextStyle
        placeholder={placeholder}
        className={className}
        type={type}
        {...register(id)}
        disabled={disabled}
        autoFocus={autofocus}
        {...inputProps}
      />
    </InputLayout>
  )
}

export default Input

interface EnterInputProps<T extends FieldValues> extends Omit<Props<T>, 'register'> {
  control: Control<T>
}
export function EnterInput<T extends FieldValues>({
  control,
  id,
  className,
  label,
  layout,
  tooltip,
  ...inputProps
}: EnterInputProps<T>) {
  return (
    <InputLayout className={className} label={label} layout={layout} tooltip={tooltip}>
      <Controller
        name={id}
        control={control}
        render={({ field: { onChange, onBlur, value, ref, ...fieldProps }, fieldState: { isTouched } }) => {
          return (
            <EnterInputComponent
              {...inputProps}
              {...fieldProps}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  onChange(event.currentTarget.value)
                  onBlur()
                }
              }}
              isTouched={isTouched}
              forwardRef={ref}
            />
          )
        }}
      />
    </InputLayout>
  )
}

interface EnterInputComponentProps {
  isTouched: boolean
  onKeyDown: (KeyboardEvent) => void
  placeholder?: string
  disabled?: boolean
  forwardRef: RefCallBack
}
function EnterInputComponent({ isTouched, onKeyDown, placeholder, disabled, forwardRef }: EnterInputComponentProps) {
  const [inputValue, setInputValue] = useState('')
  useEffect(() => {
    if (!isTouched) setInputValue('')
  }, [isTouched])

  return (
    <InputTextStyle
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      disabled={disabled}
      value={inputValue}
      onChange={(event) => setInputValue(event.currentTarget.value)}
      ref={forwardRef}
    />
  )
}
