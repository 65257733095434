import Button, { Props as ButtonProps } from 'components/Button/Button'
import { ArchiveIcon } from 'constants/icons'
import { isWithinInterval, sub } from 'date-fns'
import ConfirmModal from 'features/Modals/ConfirmModal'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import useAuth from 'hooks/useAuth'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Stock } from 'types/entities'
import { RoleAction, StockStatus } from 'types/playInApiInterfaces'
import { useStockActionsNotifications } from '../helpers'

interface Props {
  stock?: Stock
}

const TreatStockButton = ({ ...props }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button variant="warning" icon={ArchiveIcon} {...props}>
      {t('page.stock.detail.action.terminated.label')}
    </Button>
  )
}

const UntreateStockButton = ({ ...props }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button variant="danger" {...props}>
      {t('page.stock.detail.action.terminated.cancel')}
    </Button>
  )
}

export default function StockTreatButton({ stock }: Props) {
  const { t } = useTranslation()
  const { me } = useAuth()

  const [confirmModal, toggleConfirmModal] = useState(false)
  const { treatmentMutation, cancelTreatmentMutation } = useStockActionsNotifications()

  const now = new Date()
  const canBeTreated = [StockStatus.Created, StockStatus.Validated].includes(stock?.status!) && stock?.entries?.length
  const canBeCanceled = stock?.status === StockStatus.Treated
  const isTreatedByMe =
    stock?.treatedBy?.['@id'] === me?.['@id'] &&
    stock?.treatedAt &&
    isWithinInterval(new Date(stock.treatedAt), {
      start: sub(now, { days: 3 }),
      end: now
    })

  const getButton = () => {
    if (canBeTreated) {
      return (
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_ACTION]}>
          <TreatStockButton onClick={() => toggleConfirmModal(true)} />
        </ShouldDisable>
      )
    }

    if (canBeCanceled) {
      if (isTreatedByMe) {
        return <UntreateStockButton onClick={() => cancelTreatmentMutation.mutate(stock?.id!)} />
      }

      return (
        <CanAccess
          permissions={[RoleAction.ROLE_ACTION_STOCK_CANCEL_ACTION]}
          allowedComponent={<UntreateStockButton onClick={() => cancelTreatmentMutation.mutate(stock?.id!)} />}
          deniedComponent={<TreatStockButton disabled />}
        />
      )
    }

    return <TreatStockButton disabled />
  }

  return (
    <>
      {getButton()}
      <ConfirmModal
        title={t('page.stock.detail.action.terminated.confirm', { id: stock?.id })}
        onClose={() => toggleConfirmModal(false)}
        open={confirmModal}
        callback={() => stock && treatmentMutation.mutate(stock.id as number)}
        confirmLabel={t('page.stock.detail.action.terminated.label')}
        confirmBtnProps={{
          icon: ArchiveIcon,
          variant: 'warning'
        }}
      >
        <Trans i18nKey="page.stock.detail.action.terminated.text" components={{ br: <br /> }} />
      </ConfirmModal>
    </>
  )
}
