import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import Loader from 'components/Loader/Loader'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { ListActions } from 'components/SortedTable/SortedTable.styles'
import { Text } from 'components/Text/Text.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useCollaboratorByRoleAction } from 'hooks/entityHooks/permissionsHooks'
import { useApiNotifications } from 'hooks/useApiNotifications'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Collaborator, RoleAction as RoleActionEntity } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import AddActionToCollaboratorForm from '../form/AddActionToCollaboratorForm'
import { AddCollaboratorInputs } from '../form/AddRoleToCollaboratorForm'
import { DeleteModalDescription } from '../PermissionManage.style'

interface Props {
  roleAction: RoleActionEntity
}
export default function CollaboratorsByRoleAction({ roleAction }: Props) {
  const { t } = useTranslation()
  const { checkMatch } = useCheckMatch()

  const {
    withActionCollaborators,
    isLoading,
    patchCollaboratorMutation,
    availableCollaborators,
    availableCollaboratorsOptions,
    deletableCollaboratorsIds,
    canDeleteCollaboratorRow
  } = useCollaboratorByRoleAction(roleAction)

  const [selectedCollaborators, setSelectedCollaborators] = useState<string[]>([])
  const [deleteModalOpen, toggleDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const columns = _.filter<Column<Collaborator>>(
    [
      {
        key: 'checkbox',
        decorator: (collaborator) => (
          <Cell>
            <TooltipHover
              id="cantDeleteCollaboratorByActionRow"
              content={canDeleteCollaboratorRow(collaborator)?.message}
              container="tooltipContainer"
            >
              <Checkbox
                className={classNames({
                  checked: selectedCollaborators.includes(collaborator['@id']!),
                  disabled: !deletableCollaboratorsIds.includes(collaborator['@id']!)
                })}
                onClick={() => setSelectedCollaborators((prev) => _.xor(selectedCollaborators, [collaborator['@id']!]))}
              />{' '}
            </TooltipHover>
          </Cell>
        )
      },
      {
        key: 'name',
        name: t('page.permissions.manage.labels.collaboratorWithRoleAction'),
        decorator: (collaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={collaborator.id}>
            {` ${collaborator.lastname} ${collaborator.firstname}`}
          </ClickableCell>
        )
      },
      {
        key: 'store',
        name: t('common.label.location'),
        decorator: (collaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={collaborator.id}>
            {collaborator.stores?.map((e) => e.shortName).join(', ')}
          </ClickableCell>
        )
      },
      {
        key: 'origin',
        name: t('page.collaborator.details.stores.labels.origin'),
        tooltip: <Trans i18nKey="page.permissions.manage.tooltips.origin" />,
        decorator: (collaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={collaborator.id}>
            <Text fontStyle="italic" fontWeight="light">
              {!collaborator.additionalActions?.find((e) => e['@id'] === roleAction['@id'])
                ? t('page.collaborator.details.stores.labels.fromRole')
                : t('page.collaborator.details.stores.labels.fromManually')}
            </Text>
          </ClickableCell>
        )
      }
    ],
    (col) => {
      if (col.key === 'checkbox')
        return checkMatch([RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]) && checkMatch([roleAction.name!])
      return true
    }
  )

  const { emitSuccessNotification } = useApiNotifications()

  const removeRoleActionFromCollaborators = async () => {
    setIsDeleting(true)
    try {
      for (let collaboratorIri of selectedCollaborators) {
        const collaborator = _.find(withActionCollaborators, (e) => e['@id'] === collaboratorIri)
        await patchCollaboratorMutation({
          id: `${collaborator?.id}`,
          body: {
            additionalActions: _.difference(
              collaborator?.additionalActions?.map((roleAction) => roleAction['@id']!),
              [roleAction['@id']!]
            )
          }
        })
      }
      //We only send success notification at the end of ALL patch
      emitSuccessNotification()
      setSelectedCollaborators([])
      toggleDeleteModal(false)
    } finally {
      setIsDeleting(false)
    }
  }

  const addActionToCollaborator = async (formData: AddCollaboratorInputs) =>
    new Promise<void>(async (resolve) => {
      for (let collaboratorIri of formData.collaborators) {
        const collaborator = _.find(availableCollaborators, (e) => e['@id'] === collaboratorIri)
        await patchCollaboratorMutation({
          id: `${collaborator?.id}`,
          body: {
            additionalActions: _.map(collaborator?.additionalActions, (role) => role['@id']!).concat(roleAction['@id']!)
          }
        })
      }
      //We only send success notification at the end of ALL patch
      emitSuccessNotification()
      resolve()
    })
  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <ShouldHide
        permissions={[RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]}
        deniedExtraCondition={!checkMatch([roleAction.name!])}
      >
        <>
          <AddActionToCollaboratorForm
            roleAction={roleAction}
            onSubmit={addActionToCollaborator}
            availableCollaboratorsOptions={availableCollaboratorsOptions}
          />
          {!!withActionCollaborators?.length && (
            <ListActions>
              <Checkbox
                className={classNames({
                  checked:
                    deletableCollaboratorsIds.length &&
                    deletableCollaboratorsIds.length === selectedCollaborators.length,
                  disabled: !deletableCollaboratorsIds.length
                })}
                onClick={() =>
                  setSelectedCollaborators((prev) =>
                    prev.length === deletableCollaboratorsIds.length ? [] : deletableCollaboratorsIds
                  )
                }
              />
              <TooltipHover
                id="removeRoleFromCollaboratorTooltip"
                content={t('page.permissions.manage.roleAction.tooltips.removeCollaborator', {
                  roleName: roleAction.displayName
                })}
                container="tooltipContainer"
              >
                <Button
                  icon={DeleteIcon}
                  variant="white"
                  shape="circle"
                  disabled={!selectedCollaborators.length}
                  onClick={() => toggleDeleteModal(true)}
                />
              </TooltipHover>
            </ListActions>
          )}
          <ConfirmModal
            callback={removeRoleActionFromCollaborators}
            closeOnCallback={false}
            title={t('page.permissions.manage.roleAction.removeCollaboratorModal.title', {
              count: selectedCollaborators.length
            })}
            open={deleteModalOpen}
            onClose={() => toggleDeleteModal(false)}
            confirmLabel={t('common.button.delete')}
            confirmBtnProps={{
              isLoading: isDeleting
            }}
          >
            <DeleteModalDescription fontWeight="light" color="secondary">
              {t('page.permissions.manage.roleAction.removeCollaboratorModal.decription', {
                count: selectedCollaborators.length,
                roleName: roleAction.displayName
              })}
            </DeleteModalDescription>
            <Listing
              columns={columns.filter((col) => col.key !== 'checkbox')}
              data={withActionCollaborators.filter((e) => selectedCollaborators.includes(e['@id']!))}
            />
          </ConfirmModal>
        </>
      </ShouldHide>
      <Listing
        columns={columns}
        data={withActionCollaborators}
        totalCount={withActionCollaborators.length}
        emptyText={t('page.permissions.manage.roleAction.listing.collaboratorsEmpty')}
      />
    </div>
  )
}
