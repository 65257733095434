import { CreateCardLayout } from 'app/StoreEventManage/StoreEventManage.styles'
import styled from 'styled-components'

export const CreateCollectionCard = styled(CreateCardLayout)``

export const SearchFormWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 3.5rem;
  align-items: flex-end;
  max-width: 97.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
`
export const SearchFormFields = styled.div`
  display: grid;
  grid-template-columns: minmax(20rem, 25rem) repeat(3, 1fr);
  gap: 3.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
