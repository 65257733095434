import { transformPointsToVoucher } from 'api/fidelityPoints'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEmit } from 'hooks/useEventEmitter'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Customer } from 'types/entities'
import { EventType } from 'types/events'
import { PointVoucherForm } from './CustomerForm.style'
import { useFidelityVoucherOptions } from './useCustomerOptions'

interface Props {
  customer: Customer
  fidelityPoints?: number
}

export type FormData = {
  fidelityAmout?: number
  comment?: string
}

export default function PointsToVoucherForm({ customer, fidelityPoints }: Props) {
  const { t } = useTranslation()
  const emit = useEmit()

  const { fidelityVoucherOptions, closestValue } = useFidelityVoucherOptions(fidelityPoints)

  const { control, register, setValue, handleSubmit } = useForm<FormData>()

  useEffect(() => {
    setValue('fidelityAmout', closestValue?.fidelityPoints!)
  }, [closestValue, setValue])

  const { mutate: transformToVoucherMutation, isLoading } = useMutation(
    (body: FormData) => transformPointsToVoucher(customer.id!, body),
    {
      onSuccess: () => {
        setValue('comment', '')

        emit(EventType.TransformFidelityPoint, null)
        emit(EventType.AddCustomerVoucher, null)
      }
    }
  )

  const onSubmit = (formData: FormData) => transformToVoucherMutation(formData)

  return (
    <div>
      <PointVoucherForm onSubmit={handleSubmit(onSubmit)}>
        <Select
          options={fidelityVoucherOptions}
          control={control}
          id="fidelityAmout"
          label={t('page.customer.details.fidelity.label.pointsToTransform')}
          disabled={!closestValue}
          placeholder={t('page.customer.details.fidelity.insufficientBalance')}
        />
        <Input register={register} id="comment" label={t('common.label.comment')} />
        <Button buttonType="submit" isLoading={isLoading}>
          {t('common.label.transform')}
        </Button>
      </PointVoucherForm>
      <Text color="secondary" fontWeight="light">
        {t('page.customer.details.fidelity.availablePoints', { count: fidelityPoints ?? 0 })}
      </Text>
    </div>
  )
}
