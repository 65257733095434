import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { OrderEntry } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type FetchOrderEntriesResponse = Api.GetOrderEntries.ResponseBody
type FetchOrderEntriesQueryParams = Api.GetOrderEntries.RequestQuery
export const fetchOrderEntries = async (params: FetchOrderEntriesQueryParams): Promise<OrderEntry[]> => {
  const res = await playInApi.get<FetchOrderEntriesResponse>(API_ROUTES.orderEntries.root, {
    params: {
      hideChild: true,
      ...params
    }
  })

  return res.data['hydra:member']
}

export type UpdateOrderEntryBody = Api.PostOrderEntries.RequestBody
type PostOrderEntriesResponse = Api.PostOrderEntries.ResponseBody
export const postOrderEntry = async (body: UpdateOrderEntryBody) => {
  const res = await playInApi.post<UpdateOrderEntryBody, AxiosResponse<PostOrderEntriesResponse>>(
    API_ROUTES.orderEntries.root,
    body
  )

  return res.data
}

type PatchOrderEntriesResponse = Api.PatchOrderEntriesId.ResponseBody
export const patchOrderEntry = async (orderEntryId: string | number, body: UpdateOrderEntryBody) => {
  const res = await playInApi.patch<UpdateOrderEntryBody, AxiosResponse<PatchOrderEntriesResponse>>(
    `${API_ROUTES.orderEntries.root}/${orderEntryId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

  return res.data
}

type ReportStockErrorBody = Api.PostOrderEntriesReportStockError.RequestBody
type ReportStockErrorQueryParams = Api.PostOrderEntriesReportStockError.RequestQuery
type ReportStockErrorResponse = Api.PostOrderEntriesReportStockError.ResponseBody
export const reportStockError = async (body: ReportStockErrorBody, params: ReportStockErrorQueryParams) => {
  const res = await playInApi.post<ReportStockErrorBody, AxiosResponse<ReportStockErrorResponse>>(
    API_ROUTES.orderEntries.reportStockError,
    body,
    { params }
  )
  return res.data
}
