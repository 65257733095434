import styled from 'styled-components'

export const GiftManagementFormLayout = styled.div`
  display: grid;
  grid-template-columns: 25rem 1fr;
  gap: 3.5rem;
  button {
    align-self: flex-end;
    justify-self: flex-start;
  }
`
