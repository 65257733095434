import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { useClickAway } from '../../hooks/useClickAway'
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery'
import { LayoutProps } from '../Layouts/InputLayout/InputLayout'
import { Text } from '../Text/Text.styles'
import {
  Container,
  InputAutoCompleteInput,
  Option,
  Options,
  StyledInputLayout,
  StyledLoader
} from './InputAutoComplete.styles'

interface ComponentProps<T> {
  id: Path<T>
  labelKey: string
  valueKey: string
  defaultLabel?: string
  placeholder?: string
  getUrlFromSearch: (search?: string) => string
  onEntitySelected?: (entity?: any) => void
  formatOption?: (entity: any, onClick: () => void) => ReactElement
}

export interface InputAutoCompleteProps<T> extends ComponentProps<T>, LayoutProps {
  autofocus?: boolean
}

interface ReactHookFormProps {
  isTouched?: boolean
}

export interface Props<T extends FieldValues> extends InputAutoCompleteProps<T>, ReactHookFormProps {
  control: Control<T>
}

export default function InputAutoComplete<T extends FieldValues>({
  className,
  label,
  layout,
  tooltip,
  control,
  isTouched = false,
  id,
  labelKey,
  valueKey,
  defaultLabel,
  getUrlFromSearch,
  formatOption,
  onEntitySelected,
  placeholder,
  autofocus,
  disabled
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => {
        return (
          <StyledInputLayout className={className} label={label} layout={layout} tooltip={tooltip} disabled={disabled}>
            <InputAutoCompleteCompopent
              onBlur={field.onBlur}
              onChange={field.onChange}
              isTouched={isTouched}
              id={id}
              labelKey={labelKey}
              valueKey={valueKey}
              defaultLabel={defaultLabel}
              placeholder={placeholder}
              getUrlFromSearch={getUrlFromSearch}
              formatOption={formatOption}
              autofocus={autofocus}
              disabled={disabled}
              onEntitySelected={onEntitySelected}
            />
          </StyledInputLayout>
        )
      }}
    />
  )
}

type ControlledComponentProps<T> = InputAutoCompleteProps<T> & {
  onBlur: any
  onChange: any
  isTouched: boolean
}

function InputAutoCompleteCompopent<T>({
  id,
  defaultLabel = '',
  labelKey,
  valueKey,
  getUrlFromSearch,
  formatOption,
  placeholder,
  onBlur,
  onChange,
  isTouched = false,
  autofocus,
  disabled,
  onEntitySelected
}: ControlledComponentProps<T>) {
  const { t } = useTranslation()

  const [search, setSearch] = useState(defaultLabel)
  const [inputValue, setInputValue] = useState(defaultLabel)
  const [displayOptions, setDisplayOptions] = useState(false)
  const [options, setOptions] = useState<Array<any>>([])

  // On ClickAway, we close the select panel
  // And we trigger field validation with onBlur
  const { ref } = useClickAway(() => {
    if (displayOptions) {
      setDisplayOptions(false)
      onBlur()
    }
  })

  const { isFetching, isFetched, totalItems, hasNextPage, fetchNextPage } = usePaginatedQuery<any>(
    [`autoComplete:${id}`, search],
    getUrlFromSearch(search),
    {
      enabled: !!search,
      onSuccess: (data) => {
        // Filter result with no label
        const filteredResults = data.filter((item) => item[labelKey])

        setOptions(filteredResults)

        // If only one item is found, we select it
        if (filteredResults.length === 1) {
          selectOption(filteredResults[0])
        } else {
          setDisplayOptions(true)
        }
      }
    }
  )

  const { ref: infiniteScrollRef, inView } = useInView({
    threshold: 1 // Trigger component is in view when fully visible
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage])

  // the form hook provide use with isTouched to know when the field has been touch
  // isTouched is false by default
  // when isTouched go back to false, it means the forms has been reseted, so we reset the input
  useEffect(() => {
    if (isTouched === false) {
      setDisplayOptions(false)
      setOptions([])
      setInputValue(defaultLabel)
      setSearch(defaultLabel)
      if (onEntitySelected) onEntitySelected(undefined)
    }
  }, [isTouched, defaultLabel, onEntitySelected])

  useEffect(() => {
    if (!inputValue) {
      return
    }

    if (inputValue.toLowerCase().trim() !== search.toLowerCase().trim()) {
      // if no more input within 500ms, we trigger a new query
      const timeout = setTimeout(() => {
        setSearch(inputValue)
      }, 500)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [inputValue, search])

  const selectOption = useCallback(
    (item: any) => {
      setDisplayOptions(false)
      onChange(item[valueKey]) // set the field value
      setInputValue(item[labelKey])
      onBlur() // we call onBlur to trigger a new validation with the selected value

      // Send back the whole object to parent scope

      if (onEntitySelected) onEntitySelected(item)
    },
    [valueKey, labelKey, onChange, onBlur, onEntitySelected]
  )

  useEffect(() => {
    // if we clear the field
    // search is defined is we search something, or if we have a defaultLabel
    // reset the search value and disable the query to avoid unnecessary api calls
    if (inputValue === '' && search) {
      setDisplayOptions(false)
      setOptions([])
      setSearch('')
      onChange(undefined)
      onBlur() // we call onBlur to trigger a new validation with the selected value
      if (onEntitySelected) onEntitySelected(null)
    }
  }, [inputValue, onChange, id, onBlur, search, onEntitySelected])

  return (
    <Container ref={ref}>
      <InputAutoCompleteInput
        value={inputValue}
        placeholder={placeholder}
        autoFocus={autofocus}
        onChange={(e) => {
          setInputValue(e.target.value)
        }}
        onClick={() => {
          setDisplayOptions((displayOptions) => !displayOptions)
        }}
        disabled={disabled}
      />
      {isFetching && <StyledLoader />}
      <Options className={displayOptions && isFetched ? 'open' : 'close'}>
        {isFetched && (
          <Option className="header">
            <Text color="secondary" fontWeight="light">
              {t('common.autocomplete.results', { count: totalItems })}
            </Text>
          </Option>
        )}
        {options.map((item, index) => (
          <div
            key={item[valueKey]}
            tabIndex={0}
            // Tag last item with intersection ref
            ref={options.length === index + 1 ? infiniteScrollRef : undefined}
          >
            {formatOption ? (
              formatOption(item, () => selectOption(item))
            ) : (
              <Option onClick={() => selectOption(item)}>
                <Text>{item[labelKey]}</Text>
              </Option>
            )}
          </div>
        ))}
        <div>{isFetching && <StyledLoader />}</div>
      </Options>
    </Container>
  )
}
