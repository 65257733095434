import Link from 'features/Permissions/Link/Link'
import styled from 'styled-components'
import theme from 'theme/theme'
import { ToggleIconContainer } from '../Tooltip/Tooltip.styles'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  border-bottom: 0.1rem solid black;
  box-shadow: 0.1rem 0.1rem 0.4rem black;
  background-color: ${(props) => props.theme.backgroundColors.secondary};
  color: ${(props) => props.theme.textColors.secondary};
  overflow: auto;
`
type SortableProps = {
  sortable?: boolean
}

export const HeaderTitle = styled.div<SortableProps>`
  cursor: ${(props) => (props.sortable === false ? 'default' : 'pointer')};
  display: flex;
  align-items: center;

  ${ToggleIconContainer} {
    display: inline-flex;
    margin-left: 1rem;
    z-index: 50;
    position: relative;
  }
`

export const Th = styled.th<SortableProps>`
  padding: 0 1rem;
  font-size: 1.3rem;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  text-align: left;
  cursor: ${(props) => (props.sortable === false ? 'default' : 'pointer')};
`

export const CaretIcon = styled.img`
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1rem;

  &.active.desc {
    transform: rotate(0);
  }

  &.active.asc {
    transform: rotate(180deg);
  }
`

export const Tbody = styled.tbody`
  overflow: auto;
  & > *:not(:last-child) {
    border-bottom: 0.1rem solid ${(props) => props.theme.borderColors.secondary};
  }
`

export const Tr = styled.tr`
  height: 4rem;
`

export const Td = styled.td`
  height: 4rem;
  padding: 0;

  &.hide-cell {
    display: none;
  }
`

export const Container = styled.div`
  max-width: 100%;
  width: 100%;
  overflow-x: auto;

  @media screen and (${theme.screenWidth.maxLaptopScreen}) {
    padding-bottom: 1.5rem;
  }
`

export const StyledCell = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    background-color: ${(props) => props.theme.backgroundColors.secondary};
  }
`
export const LinkCell = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }

  &.unavailable {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`

export const LoaderContainer = styled.div`
  margin: 2rem;
  text-align: center;
`
export const SmallCell = styled(StyledCell)`
  padding: 0.25rem 1rem;
`

export const ListActions = styled.div`
  display: flex;
  padding-left: 1rem;
  gap: 2rem;
  align-items: center;
  padding-bottom: 2rem;
`
