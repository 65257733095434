import { yupResolver } from '@hookform/resolvers/yup'
import { editOrder, orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { TextareaLayout } from 'components/Textarea/Textarea.style'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import * as yup from 'yup'
import { CommentBoxContainer, StyledItalicTextarea } from './InstructionBox.style'

interface InstructionBoxInputs {
  instruction: string
  orderId: number
}

type InstructionBoxProps = InstructionBoxInputs & {
  toggleInstruction: Function
}

const schema = yup.object().shape({
  instruction: yup.string().trim()
})

function InstructionBox({ instruction, orderId, toggleInstruction }: InstructionBoxProps) {
  const { t } = useTranslation()
  const { mutate, isLoading } = useMutation((body: InstructionBoxInputs) => editOrder(body, orderId))
  const { handleSubmit, register, setValue } = useForm<InstructionBoxInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { instruction }
  })


  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${orderId}`)

  const onSubmit = (formData: InstructionBoxInputs) => {
    formData = { ...formData, instruction: formData.instruction.trim() }
    mutate(formData, {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, { ...prev, ...data })
        setValue('instruction', formData.instruction)
        if (formData.instruction.length === 0) {
          toggleInstruction(false)
        }
      }
    })
  }

  useEffect(() => {
    // Resets textarea default value upon prop change
    setValue('instruction', instruction)
  }, [instruction, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BlockLayout>
        {t('page.order.detail.titleInstructionBox')}
        <CommentBoxContainer>
          <TextareaLayout>
            <StyledItalicTextarea rows={1} {...register('instruction')} />
          </TextareaLayout>
          <Button shape="circle" icon={SaveIcon} buttonType="submit" isLoading={isLoading} />
        </CommentBoxContainer>
      </BlockLayout>
    </form>
  )
}

export default InstructionBox
