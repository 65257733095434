import styled from 'styled-components'

export const TwoColGridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const PraparationInputRadioContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  font-weight: ${(props) => props.theme.fontWeight.regular};

  & > :first-child {
    max-width: 3rem;
  }

  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
`
