import { Filter } from 'components/FiltersList/FiltersList'
import { useCardFamiliesOptions, useCardRarityOptions, useCardTypeOptions, usePricingTagsOptions } from 'hooks/entityHooks/cardHooks'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { CardEditionJsonldCardEditionRead } from 'types/playInApiInterfaces'
import { TextFilterLabel } from './CardManagement.style'
import { SearchCardInputs } from './SearchCardForm'

interface useCardsFilterOptions {
  family?: string
  selectedEdition?: CardEditionJsonldCardEditionRead
}

function useCardsFilters({ family, selectedEdition }: useCardsFilterOptions) {
  const { cardRarityOptions, cardRarityOptionsPagination, getRaritiesMultipleFilter } = useCardRarityOptions({
    family
  })
  const { getCardFamilyFilter } = useCardFamiliesOptions()
  const { cardTypesOptions, cardTypesOptionsPagination, getCardTypeFilter } = useCardTypeOptions({
    family
  })
  const { pricingTagsOptions, getPricingTagFilter } = usePricingTagsOptions()

  const [searchFilters, setSearchFilters] = useState<Filter[]>([])

  const filterFns = useMemo(
    () => ({
      edition: (id, value) => ({ id, value, label: selectedEdition?.name }),
      rarity: getRaritiesMultipleFilter,
      family: getCardFamilyFilter,
      type: getCardTypeFilter,
      pricingTag: getPricingTagFilter,
      text: (id, value) => ({ id, label: <TextFilterLabel>{value}</TextFilterLabel> })
    }),
    [selectedEdition, getRaritiesMultipleFilter, getCardTypeFilter, getCardFamilyFilter, getPricingTagFilter]
  )

  const handleFilters = (formData: SearchCardInputs) => {
    const filters: Filter[] = []

    _.forEach(formData, (value, name) => {
      if (!_.isNil(value) && value !== '') {
        if (filterFns[name]) {
          filters.push(filterFns[name](name, value))
        }
      }
    })
    
    setSearchFilters(_.flattenDeep(filters))
  }

  return {
    handleFilters,
    filters: searchFilters,
    cardTypesOptions,
    cardRarityOptions,
    cardRarityOptionsPagination,
    cardTypesOptionsPagination,
    pricingTagsOptions
  }
}

export default useCardsFilters
