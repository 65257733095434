import Listing from 'features/Listing/Listing'
import styled from 'styled-components'

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem 5rem;
  margin-top: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const StyledListing = styled(Listing)`
  margin-top: 2rem;
` as typeof Listing
