import { FetchCardRaritiesParams, usePaginatedCardRarities } from 'api/cardRarities'
import { FetchCardTypesParams, usePaginatedCardTypes } from 'api/cardTypes'
import { usePaginatedPricingTags } from 'api/pricingTags'
import { FamilyStatus, fetchRanges } from 'api/ranges'
import { Option } from 'components/Select/Select'
import { getFilterLabel } from 'hooks/useSelectOptions'
import { ReactNode, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { CardProductProductReadProductStats, FamilyJsonldFamilyRead } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'

const LightSpan = styled.span`
  font-weight: ${(props) => props.theme.fontWeight.light};
`
const LightItalicSpan = styled.span`
  font-weight: ${(props) => props.theme.fontWeight.light};
  font-style: ${(props) => props.theme.fontStyle.italic};
`

const BoldSpan = styled.span`
  font-weight: ${(props) => props.theme.fontWeight.bold};
`

const CardName = styled.div`
  span:not(:last-child) {
    margin-right: 0.4rem;
  }
`
export const useCardDeclinations = () => {
  const { t } = useTranslation()

  return ({ foil, inked, signed, firstEdition, scanId }) => {
    return [
      foil ? t('label.cards.declinations.foil') : undefined,
      inked ? t('label.cards.declinations.inked') : undefined,
      signed ? t('label.cards.declinations.signed') : undefined,
      firstEdition ? t('label.cards.declinations.firstEdition') : undefined,
      scanId ? t('label.cards.declinations.scanned', { scanId }) : undefined
    ].filter((str) => !!str)
  }
}

//######### CARDS ##########

interface useCardFamiliesOptionsParams {
  onSuccessCallback?: (families: FamilyJsonldFamilyRead[]) => void
}
export const useCardFamiliesOptions = (options: useCardFamiliesOptionsParams = {}) => {
  const { onSuccessCallback } = options
  const { data: families } = useQuery(
    'cardFamily',
    () => fetchRanges({ cards: true, 'order[name]': SortingDirection.Asc, 'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage] }),
    {
      onSuccess: (cardFamilies) => {
        if (onSuccessCallback) onSuccessCallback(cardFamilies)
      }
    }
  )
  const cardFamiliesOptions: Option[] =
    families?.map((e) => ({
      value: `${e.id}`,
      label: e.name ?? e.codeName
    })) ?? []

  return {
    cardFamiliesOptions,
    getCardFamilyFilter: (id, value) => ({ id, label: getFilterLabel(cardFamiliesOptions, value) })
  }
}

export const useCardRarityOptions = (params?: FetchCardRaritiesParams) => {
  const {
    data: cardRarities,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading
  } = usePaginatedCardRarities(['cardRarities', params], { 'order[name]': SortingDirection.Asc, ...params })

  const cardRarityOptions: Option[] = useMemo(
    () =>
      cardRarities?.map((e) => ({
        value: `${e.id}`,
        label: e.name
      })) ?? [],
    [cardRarities]
  )

  const getRaritiesMultipleFilter = useCallback(
    (id: string, rarityList: string[]) => {
      if (rarityList.length)
        return rarityList.map((rarity) => ({
          id,
          label: getFilterLabel(cardRarityOptions, rarity),
          filterId: `status-${rarity}`
        }))
      else return [{ id: 'cardRarity', label: '' }]
    },
    [cardRarityOptions]
  )

  return {
    getRaritiesMultipleFilter,
    cardRarityOptions,
    cardRarityOptionsPagination: { isFetchingNextPage, hasNextPage, fetchNextPage, isLoading }
  }
}

export const useCardTypeOptions = (params?: FetchCardTypesParams) => {
  const queryParams = { 'order[name]': SortingDirection.Asc, ...params }
  const {
    data: cardTypes,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading
  } = usePaginatedCardTypes(['cardTypes', queryParams], queryParams, {
    enabled: !!params
  })

  const cardTypesOptions: Option[] =
    cardTypes?.map((cardType) => ({
      label: cardType.name ?? '',
      value: cardType.id
    })) ?? []

  return {
    cardTypesOptions,
    cardTypesOptionsPagination: { fetchNextPage, hasNextPage, isFetchingNextPage, isLoading },
    getCardTypeFilter: (id, value) => ({ id, label: getFilterLabel(cardTypesOptions, value) })
  }
}

export const useCardFullName = () => {
  const getCardDeclination = useCardDeclinations()
  const getCardFullName = (cardProduct: CardProductProductReadProductStats): ReactNode => {
    const variation = cardProduct?.cardData?.variation
    const declination = cardProduct?.cardData?.declination
    const nameSplits: ReactNode[] = [
      <LightSpan>{`(${variation?.cardProperties?.id})`}</LightSpan>,
      <BoldSpan>{variation?.collectionNumber}</BoldSpan>,
      <span>{variation?.rarity?.shortName}</span>,
      variation?.cardProperties?.colorCode?.colorLetterCode && (
        <span>{variation?.cardProperties?.colorCode?.colorLetterCode}</span>
      ),
      <>
        <span>{variation?.cardProperties?.name}</span>
        <span> / </span>
        <span>{variation?.cardProperties?.englishName}</span>
      </>,
      variation?.name && <BoldSpan>{variation?.name}</BoldSpan>,
      <span>{variation?.edition?.name}</span>,
      declination?.grading?.name && <LightItalicSpan>{declination?.grading?.name}</LightItalicSpan>,
      ...getCardDeclination({
        firstEdition: declination?.firstEdition,
        foil: declination?.foil,
        inked: declination?.inked,
        scanId: declination?.scan?.id,
        signed: declination?.signed
      }).map((label, index) => <LightItalicSpan key={[label, index].join('-')}>{label}</LightItalicSpan>)
    ].filter((node) => !!node)

    return (
      <CardName>
        {nameSplits.map((el, index) => (
          <>
            {el}
            {index !== nameSplits.length - 1 && <span> - </span>}
          </>
        ))}
      </CardName>
    )
  }

  return {
    getCardFullName
  }
}

export const usePricingTagsOptions = (iri: boolean = true) => {
  const {
    data: pricingTags
  } = usePaginatedPricingTags('pricingTags')
  
  const pricingTagsOptions: Option[] =
    pricingTags?.map((pricingTag) => ({
      label: pricingTag.name ?? '',
      value: iri ? pricingTag['@id'] : pricingTag.id
    })) ?? []

  return {
    pricingTagsOptions,
    getPricingTagFilter: (id, value) => ({ id, label: getFilterLabel(pricingTagsOptions, value) })
  }
}
