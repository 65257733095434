import { cancelOrderReady, GetOrderByIdResponse, notifyOrderReady, orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import Select from 'components/Select/Select'
import { Title2 } from 'components/Title/Title.styles'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import { SaveIcon } from 'constants/icons'
import ConfirmModal from 'features/Modals/ConfirmModal'
import { useMutation } from 'hooks/useAxiosMutation'
import { useStorePlaceOrderOptions } from 'hooks/useSelectOptions'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { UnmodifiableOrderStatuses } from 'types/enums'
import { OrderStatus } from 'types/playInApiInterfaces'
import { GappedRowContainer, PaddingLessButton, SpaceBetweenRowContainer, StorePlaceRow } from '../ShippingCard.style'

type Props = {
  order?: GetOrderByIdResponse
}

interface DeliveryFormInputs {
  storePlace: string | null
}

export default function CancelReadyStatusForm({ order }: Props) {
  const { t } = useTranslation()
  const [cancelReadyStatusModalOpen, toggleCancelReadyStatusModal] = useState(false)
  const { control, handleSubmit, reset } = useForm<DeliveryFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      storePlace: order?.storePlace!
    }
  })

  useEffect(() => {
    reset({
      storePlace: order?.storePlace!
    })
  }, [order, reset])

  const { storePlaceOrderOptions } = useStorePlaceOrderOptions()

  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)
  const { mutate, isLoading: isOrderReadyLoading } = useMutation(
    (body: DeliveryFormInputs) => notifyOrderReady(body, order?.id!),
    {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, { ...prev, ...data })
        document.getElementById('sidebarSearchOrder')?.focus()
      }
    }
  )

  const { mutate: cancelReadyMutate, isLoading } = useMutation((id: string | number) => cancelOrderReady(id), {
    onSuccess: (data) => {
      queryClient.setQueryData(key, data)
    }
  })

  const onSubmit = (body: DeliveryFormInputs) => mutate(body)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title2>{t('page.order.detail.notifyReadyStatusTitle')}</Title2>
        <StorePlaceRow>
          <Select
            id="storePlace"
            control={control}
            label={t('page.order.detail.storePlace')}
            placeholder={t('common.select.defaultOptions.storePlace')}
            layout="column"
            options={storePlaceOrderOptions ?? []}
          />
        </StorePlaceRow>
        <SpaceBetweenRowContainer>
          <GappedRowContainer>
            <Button
              shape="circle"
              icon={SaveIcon}
              buttonType="submit"
              disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
              isLoading={isOrderReadyLoading}
            />
            <MultiLineTooltip id={'cancel-order-tooltip'} lines={[t('page.order.detail.cancelReadyStatusTooltip')]} />
          </GappedRowContainer>
          <PaddingLessButton
            variant="white"
            onClick={() => toggleCancelReadyStatusModal(true)}
            disabled={order?.status !== OrderStatus.Ready}
            isLoading={isLoading}
          >
            {t('page.order.detail.modals.removeReadyStatus')}
          </PaddingLessButton>
        </SpaceBetweenRowContainer>
      </form>
      <ConfirmModal
        open={cancelReadyStatusModalOpen}
        onClose={() => {
          toggleCancelReadyStatusModal(false)
        }}
        confirmLabel={t('page.order.detail.modals.removeReadyStatus')}
        title={t('page.order.detail.modals.cancelReadyStatusTitle', { referenceNumber: order?.referenceNumber })}
        callback={() => cancelReadyMutate(order?.id!)}
      />
    </>
  )
}
