import classnames from 'classnames'
import TextNumber from 'components/TextNumber/TextNumber'
import { LabelTitle, StrokeTextNumber, TitleContainer } from '../StoreLabels.styles'
import { ArrowLabelEnum, getTitleFontSize } from '../storeLabelUtils'
import {
  CercleDiscount,
  HalfMoonRectangleDiscount,
  RectangleDiscountContainer,
  StyledDiscountPercentTextDown,
  StyledDiscountPercentTextUp,
  StyledDiscountPriceTextDown,
  StyledDiscountPriceTextUp,
  StyledPriceTextDown,
  StyledPriceTextUp,
  StyledTextContainer,
  Subtitle
} from './RectangleDiscountLabel.styles'

type Props = {
  title: string
  subtitle?: string
  price: number
  discountPrice: number
  discountPercent: number
  arrow?: ArrowLabelEnum
  onClick: () => void
}

const RectangleDiscountLabel = ({ title, subtitle, price, discountPrice, discountPercent, arrow, onClick }: Props) => {
  return (
    <RectangleDiscountContainer onClick={onClick}>
      <HalfMoonRectangleDiscount className={classnames(arrow && arrow === ArrowLabelEnum.down ? 'down' : 'up')}/>
      <CercleDiscount className={classnames(arrow && arrow === ArrowLabelEnum.down ? 'down' : 'up')}/>
      <TextNumber
        value={discountPrice}
        fontWeight="semiBold"
        size="4.4rem"
        color="white"
        decimalScale={2}
        suffix={`\u00a0€`}
        ContainerComponent={arrow && arrow === ArrowLabelEnum.down ? StyledPriceTextDown : StyledPriceTextUp}
      />
      <StrokeTextNumber
        value={price}
        size="2.9rem"
        fontWeight="light"
        color="white"
        decimalScale={2}
        suffix={`\u00a0€`}
        ContainerComponent={arrow && arrow === ArrowLabelEnum.down ? StyledDiscountPriceTextDown : StyledDiscountPriceTextUp}
      />
      <StyledTextContainer className={classnames(arrow && arrow === ArrowLabelEnum.down ? 'down' : 'up')}>
        <TitleContainer>
          <LabelTitle
            className={classnames(subtitle ? 'clamp-2' : 'clamp-3', 'red')}
            fontWeight="medium"
            size={getTitleFontSize(title.trim(), subtitle)}
          >
            {title.trim()}
          </LabelTitle>
        </TitleContainer>
        {subtitle && <Subtitle size="1.6rem">{subtitle.trim()}</Subtitle>}
      </StyledTextContainer>

      <TextNumber
        value={discountPercent}
        fontWeight="bolder"
        size="2rem"
        decimalScale={0}
        suffix="%"
        ContainerComponent={arrow && arrow === ArrowLabelEnum.down ? StyledDiscountPercentTextDown : StyledDiscountPercentTextUp}
      />
    </RectangleDiscountContainer>
  )
}

export default RectangleDiscountLabel
