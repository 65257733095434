import { useCheckMatch } from 'permissions/permissions'
import { RoleAction } from 'types/playInApiInterfaces'

type Props = {
  permissions?: RoleAction[]
  allowedComponent: React.ReactNode
  deniedComponent: React.ReactNode
  deniedExtraCondition?: boolean
  byPassRoleValidation?: boolean
}

export const CanAccess = ({
  permissions = [],
  allowedComponent,
  deniedComponent,
  deniedExtraCondition = false,
  byPassRoleValidation = false
}: Props) => {
  const { checkMatch } = useCheckMatch()

  if (!!byPassRoleValidation) return <>{allowedComponent}</>

  if (!checkMatch(permissions) || deniedExtraCondition) {
    return <>{deniedComponent}</>
  }
  return <>{allowedComponent}</>
}

export default CanAccess
