import { FetchFidelityPointVoucherResponse, fetchFidelityPointVouchers } from 'api/fidelityPoints'
import { Option } from 'components/Select/Select'
import TextNumber from 'components/TextNumber/TextNumber'
import { forEach } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Customer } from 'types/entities'
import { VoucherOption } from './CustomerForm.style'

export const useAccountOptions = (customer: Customer) => {
  const { t } = useTranslation()

  const accountStateOptions: Option[] = [
    { value: false, label: t('common.select.accountState.activated') },
    { value: true, label: t('common.select.accountState.deleted') }
  ]

  const getAccountStateQueryParam = (deleted: boolean) => {
    if (deleted) return { deleted: true }
    return { active: true }
  }

  return {
    accountStateOptions,
    getAccountStateQueryParam
  }
}

export const useFidelityVoucherOptions = (customerPoint?: number) => {
  const { data: vouchers } = useQuery('fidelityPointToVoucher', fetchFidelityPointVouchers)

  const fidelityVoucherOptions: Option[] =
    vouchers?.map((voucher) => ({
      value: voucher.fidelityPoints,
      label: (
        <VoucherOption>
          <span>{`${voucher.fidelityPoints} Pts`}</span>

          <TextNumber value={voucher.voucherValue} suffix="€" />
        </VoucherOption>
      ),
      disabled: !customerPoint || voucher.fidelityPoints! > customerPoint
    })) ?? []

  const getClosestValue = (): FetchFidelityPointVoucherResponse['hydra:member'][0] => {
    let sortedVouchers = vouchers?.sort((a, b) => a.fidelityPoints! - b.fidelityPoints!) ?? []
    let closestVoucher
    forEach(sortedVouchers, (voucher) => {
      if (!customerPoint || customerPoint >= voucher.fidelityPoints!) closestVoucher = voucher
    })
    return closestVoucher
  }

  return { fidelityVoucherOptions, closestValue: getClosestValue() }
}
