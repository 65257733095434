import { GetOrderByIdResponse } from 'api/orders'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import EmailSendForm from './EmailSendForm'
import { EmailSendModalTitle2 } from './EmailSendModal.style'

interface Props extends Omit<ModalProps, 'children'> {
  order: GetOrderByIdResponse
}

export default function EmailSendModal({ open, onClose, order }: Props) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose} keepMounted={true}>
      <>
        <EmailSendModalTitle2>{t('page.order.detail.tab.misc.sendEmailModal.title')}</EmailSendModalTitle2>
        <EmailSendForm order={order} onClose={onClose} open={open} />
      </>
    </Modal>
  )
}
