import Modal, { ModalProps } from 'components/Modal/Modal'
import NotAllowedText from 'components/Text/NotAllowedText'
import MainTitle from 'components/Title/MainTitle'
import CanAccess from 'features/Permissions/CanAccess'
import { useTranslation } from 'react-i18next'
import { RoleAction } from 'types/playInApiInterfaces'
import OrderCreateForm from './form/OrderCreateForm'
import { ModalBody } from './OrderCreateModal.style'

interface Props extends Omit<ModalProps, 'children'> {}

export default function OrderCreateModal({ open, onClose }: Props) {
  const { t } = useTranslation()
  return (
    <Modal open={open} onClose={onClose}>
      <>
        <MainTitle title={t('page.order.create.title')} />

        <ModalBody>
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_ORDER_CREATE]}
            allowedComponent={<OrderCreateForm closeModal={onClose} />}
            deniedComponent={<NotAllowedText />}
          />
        </ModalBody>
      </>
    </Modal>
  )
}
