import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { useFetchQuery } from 'hooks/useFetchQuery'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useCallback } from 'react'
import { QueryKey } from 'react-query'
import { Api, StockEntryJsonldStockEntryTransferred, StockStatus } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export type PostStockEntryBody = Api.PostStockEntries.RequestBody
type PostStockEntryResponse = Api.PostStockEntries.ResponseBody
export const postStockEntry = async (body: PostStockEntryBody) => {
  const response = await playInApi.post<PostStockEntryBody, AxiosResponse<PostStockEntryResponse>>(
    `${API_ROUTES.stocksEntries.root}`,
    {
      buyPriceVatExcluded: body.buyPriceVatExcluded,
      product: body.product,
      quantity: body.quantity,
      stock: body.stock,
      tax: body.tax
    }
  )

  return response.data
}

export const deleteStockEntry = (id: number) => {
  return playInApi.delete(`${API_ROUTES.stocksEntries.root}/${id}`)
}

export type PatchStockEntryBody = Api.PatchStockEntriesId.RequestBody
type PatchStockEntryResponse = Api.PatchStockEntriesId.ResponseBody
export const patchStockEntry = async (id: number, body: PatchStockEntryBody) => {
  const res = await playInApi.patch<PatchStockEntryBody, AxiosResponse<PatchStockEntryResponse>>(
    `${API_ROUTES.stocksEntries.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

  return res.data
}

export type FetchStockEntriesQueryParams = Api.GetStockEntries.RequestQuery
export const getStockEntriesUri = (params: FetchStockEntriesQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.stocksEntries.root, queryString].join('?')
}

type FetchStockEntriesResponse = Api.GetStockEntries.ResponseBody
export const useOrdersPaginatedQuery = (
  queryKey: QueryKey,
  query: string,
  options?: PaginatedHookOptions<FetchStockEntriesResponse['hydra:member'][0]>
) => usePaginatedQuery<FetchStockEntriesResponse['hydra:member'][0]>(queryKey, query, options)

export const getStockTransfersQueryKey = (stockId: string | number) => `stock-${stockId}-entries-transfered`

type FetchStockEntriesTransferredFromResponse = Api.GetStockEntriesTransferredFromIdEntry.ResponseBody
type FetchStockEntriesTransferredFromQueryParams = Api.GetStockEntriesTransferredFromIdEntry.RequestQuery
export type StockTransfersDetail = StockEntryJsonldStockEntryTransferred
export const getStockEntriesTransfered = async (
  stockId: string | number,
  params: FetchStockEntriesTransferredFromQueryParams
) => {
  const res = await playInApi.get<FetchStockEntriesTransferredFromResponse>(
    `${API_ROUTES.stocksEntries.transferredFrom}/${stockId}`,
    {
      params
    }
  )
  return res.data['hydra:member']
}

export const useGetTransfers = (stockId: string | number, stockStatus?: StockStatus) => {
  const { data: transfers } = useFetchQuery(
    [getStockTransfersQueryKey(stockId)],
    () => getStockEntriesTransfered(stockId, { pagination: false }),
    {
      enabled: stockStatus && [StockStatus.Validated, StockStatus.Treated].includes(stockStatus)
    }
  )

  const getStockEntryTransfersQuantity = useCallback(
    (stockEntryId?: number) => {
      const stockEntryTransfers = (transfers || []).filter((transfer) => transfer?.sourceId === stockEntryId)
      const stockEntryTransfersInProgress = (stockEntryTransfers || []).filter((transfer) => !transfer?.stock?.validated && !transfer?.stock?.treated)
      const stockEntryTransfersDone = (stockEntryTransfers || []).filter((transfer) => transfer?.stock?.validated || transfer?.stock?.treated)

      const quantityInProgress: number = stockEntryTransfersInProgress?.reduce((prev: number, transfer) => prev + transfer?.quantity!, 0)
      const quantityDone: number = stockEntryTransfersDone?.reduce((prev: number, transfer ) => prev + transfer?.quantity!, 0)
      const quantityTotal: number =  stockEntryTransfers?.reduce((prev, transfer) => prev + transfer?.quantity!, 0)

      return { quantityTotal, quantityInProgress, quantityDone }
    },
    [transfers]
  )

  return {
    transfers,
    getStockEntryTransfersQuantity
  }
}
