import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import styled from 'styled-components'
import theme from 'theme/theme'

export const StockRangeForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.5rem;
  align-items: flex-end;

  @media screen and (${theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr auto;
  }
`

export const ExportRangeCard = styled(CardLayout)`
  width: 100%;
  max-width: 70rem;
`
