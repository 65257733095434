import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Svg from 'components/Svg/Svg'
import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  cursor: pointer;
`

export const HiddenInput = styled.input`
  display: none;
`

export const ImageUploaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-self: flex-start;
`
export const ImageCard = styled(CardLayout)`
  width: fit-content;
  max-width: 100%;
  padding-top: 3.5rem;
  position: relative;

  img {
    max-width: 100%;
  }
`

export const DeleteImage = styled(Svg)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
` as typeof Svg
