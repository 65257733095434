import { yupResolver } from '@hookform/resolvers/yup'
import { fetchCustomerVouchers } from 'api/vouchers'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import Select from 'components/Select/Select'
import { MoreIcon, TransfertIcon } from 'constants/icons'
import { isBefore, sub } from 'date-fns'
import { useGetPaymentModeLabel } from 'hooks/entityHooks/paymentModeHooks'
import { useMyStoresOptions, usePaymentModeFilters } from 'hooks/useSelectOptions'
import _ from 'lodash'
import { useEffect } from 'react'
import { FieldNamesMarkedBoolean, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Customer, StoreEvent } from 'types/entities'
import { PaymentModes } from 'types/enums'
import { getRequiredLabel } from 'utils/formHelper'
import * as yup from 'yup'
import { AddCustomerButtonsWrapper, AddCustomerFormLayout, TwoColField } from './AddCustomerModal.style'

const baseSchema = {
  firstname: yup.string().trim().required(),
  lastname: yup.string().trim().required(),
  email: yup.string().trim().required()
}

const defaultSchema = yup.object(baseSchema)

export interface AddCustomerInputs extends yup.InferType<typeof defaultSchema> {
  price?: number
  paymentMode?: string | null
  paymentReceivedBy?: string | null
  additionalData?: string | null
}

interface Props {
  event: StoreEvent
  onSubmit: (open: AddCustomerInputs) => void
  onClose: () => void
  customer?: Customer
  isLoading?: boolean
  defaultValues?: Partial<AddCustomerInputs>
  onTouchedFields?: (fields: FieldNamesMarkedBoolean<AddCustomerInputs>) => void
  submitDisabled?: boolean
  isEdit?: boolean
}

export const getEventDefaultPrice = (event: StoreEvent) => {
  if (!event.startsAt) return event.price

  if (isBefore(new Date(), sub(new Date(event.startsAt), { hours: 14 }))) return event.price
  return event.priceWithoutDiscount || event.price
}

export default function AddCustomerForm({
  customer,
  event,
  onSubmit,
  onClose,
  isLoading,
  onTouchedFields,
  defaultValues,
  submitDisabled = false,
  isEdit = false
}: Props) {
  const isPaymentRequired = event.openRegistration && !!event.price

  const schema = yup.object({
    ...baseSchema,
    price: yup
      .number()
      .max(getEventDefaultPrice(event) ?? 0)
      .transform((value) => (isNaN(value) ? undefined : value))
      .when([], {
        is: () => isPaymentRequired,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema
      }),
    paymentMode: yup
      .string()
      .trim()
      .when([], {
        is: () => isPaymentRequired,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema
      }),
    ...(!!event?.requiredDataName && { additionalData: yup.string().trim().required() }),
    paymentReceivedBy: yup
      .string()
      .trim()
      .when('paymentMode', {
        is: (value) => !!value,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema
      })
  })
  const { t } = useTranslation()

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors, touchedFields },
    watch
  } = useForm<AddCustomerInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      price: getEventDefaultPrice(event),
      paymentReceivedBy: event.store?.['@id'],
      ...defaultValues
    }
  })

  useEffect(() => {
    if (customer) {
      const { firstname, lastname, email } = customer
      setValue('firstname', firstname ?? '')
      setValue('lastname', lastname ?? '')
      setValue('email', email)
    }
  }, [customer, setValue])

  useEffect(() => {
    if (onTouchedFields) onTouchedFields(touchedFields)
  }, [touchedFields, onTouchedFields])

  const [watchPrice, watchPaymentMode] = watch(['price', 'paymentMode'])

  const { data: vouchers } = useQuery(
    ['customer-vouchers', customer?.['@id']],
    () => fetchCustomerVouchers(customer?.['@id']!),
    {
      enabled: !!customer
    }
  )

  const totalVouchers = _.reduce(vouchers, (prev, current) => prev + (current?.value ?? 0), 0)

  const { paymentModes, getPaymentModeByValue } = usePaymentModeFilters({ availableInEvent: true })
  const { getPaymentModeLabel } = useGetPaymentModeLabel()

  const { myStoresOptions } = useMyStoresOptions()

  const getDisabledOption = (paymentMode?: PaymentModes) => {
    switch (paymentMode) {
      case PaymentModes.Voucher:
        if (defaultValues?.paymentMode)
          return !(getPaymentModeByValue(defaultValues?.paymentMode)?.codeName === PaymentModes.Voucher)
        return (
          totalVouchers === 0 || //User don't have any voucher
          totalVouchers < (watchPrice ?? 0)
        )
      default:
        return false
    }
  }

  const paymentModeOptions = _.sortBy(
    paymentModes?.map((paymentMode) => ({
      value: paymentMode['@id']!,
      label: getPaymentModeLabel(paymentMode.codeName),
      disabled: getDisabledOption(paymentMode?.codeName as PaymentModes)
    })),
    (option) => option.label
  )

  // If paymenyMode is set as voucher, and price get higher than customer's voucher, we need to unset the paymentMode
  // In order to validate form, user will then need to choose a new payment mode
  useEffect(() => {
    if (!isEdit) {
      if (
        totalVouchers < (watchPrice ?? 0) &&
        getPaymentModeByValue(watchPaymentMode)?.codeName === PaymentModes.Voucher
      )
        setValue('paymentMode', null)
    }
  }, [setValue, totalVouchers, watchPaymentMode, watchPrice, isEdit, getPaymentModeByValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AddCustomerFormLayout>
        <div>
          <Input
            register={register}
            id="lastname"
            label={getRequiredLabel(t('common.label.lastName'))}
            disabled={!customer || !!customer?.lastname}
            className={classNames({ 'is-invalid': errors.lastname })}
          />
          <BasicFieldValidationError message={t('form.validation.required.lastname')} error={errors.lastname} />
        </div>

        <div>
          <Input
            register={register}
            id="firstname"
            label={getRequiredLabel(t('common.label.firstName'))}
            disabled={!customer || !!customer?.firstname}
            className={classNames({ 'is-invalid': errors.firstname })}
          />
          <BasicFieldValidationError message={t('form.validation.required.firstname')} error={errors.firstname} />
        </div>

        <Input
          register={register}
          id="email"
          label={getRequiredLabel(t('common.label.email'))}
          disabled={true}
          className={classNames({ 'is-invalid': errors.email })}
        />
        <BasicFieldValidationError message={t('form.validation.required.email')} error={errors.email} />

        <div>
          <InputFormattedNumber
            control={control}
            id="price"
            label={isPaymentRequired ? getRequiredLabel(t('common.label.price')) : t('common.label.price')}
            suffix={`\u00a0€`}
            decimalScale={2}
            className={classNames({ 'is-invalid': errors.price })}
            disabled={isEdit && getPaymentModeByValue(defaultValues?.paymentMode)?.codeName === PaymentModes.Voucher}
          />
          <BasicFieldValidationError
            messages={{
              required: t('form.validation.required.priceValue'),
              max: t('page.events.details.modals.addCustomer.errors.max', {
                price: getEventDefaultPrice(event)?.toFixed(2)
              })
            }}
            error={errors.price}
          />
        </div>

        <div>
          <Select
            options={paymentModeOptions}
            control={control}
            id="paymentMode"
            label={
              isPaymentRequired ? getRequiredLabel(t('common.label.paymentMethod')) : t('common.label.paymentMethod')
            }
            placeholder={t('common.select.defaultOptions.paymentMode')}
            className={classNames({ 'is-invalid': errors.paymentMode })}
            enableUnselect={true}
            disabled={isEdit && getPaymentModeByValue(defaultValues?.paymentMode)?.codeName === PaymentModes.Voucher}
          />
          <BasicFieldValidationError message={t('form.validation.required.paymentMode')} error={errors.paymentMode} />
        </div>
        <div>
          <Select
            options={myStoresOptions}
            id="paymentReceivedBy"
            control={control}
            label={t('page.order.detail.tab.payment.paymentReceivedAtLabel')}
            className={classNames({ 'is-invalid': errors.paymentReceivedBy })}
            enableUnselect={true}
          />
          <BasicFieldValidationError message={t('form.validation.required.generic')} error={errors.paymentReceivedBy} />
        </div>

        {!!event.requiredDataName && (
          <TwoColField>
            <Input
              register={register}
              id="additionalData"
              label={getRequiredLabel(event.requiredDataName)}
              className={classNames({ 'is-invalid': errors.additionalData })}
            />
            <BasicFieldValidationError message={t('form.validation.required.generic')} error={errors.additionalData} />
          </TwoColField>
        )}
      </AddCustomerFormLayout>
      <AddCustomerButtonsWrapper>
        <Button
          buttonType="submit"
          icon={isEdit ? TransfertIcon : MoreIcon}
          iconColor={submitDisabled ? 'black' : 'white'}
          disabled={submitDisabled}
          isLoading={isLoading}
        >
          {isEdit ? t('common.button.edit') : t('common.button.add')}
        </Button>
        <Button variant="white" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
      </AddCustomerButtonsWrapper>
    </form>
  )
}
