import { useOrderSteps } from 'app/OrderDetails/helper'
import StatusBlock from 'components/StatusBlock/StatusBlock'
import {
  DoodleFiveIcon,
  DoodleFourIcon,
  DoodleOneIcon,
  DoodleSevenIcon,
  DoodleSixIcon,
  DoodleThreeIcon,
  DoodleTwoIcon
} from 'constants/doodleIcons'
import { useRef } from 'react'
import { OrderItem } from 'types/entities'
import { ShippingTypeName } from 'types/playInApiInterfaces'
import { formatDateAndHour } from 'utils/dates'
import { StatusContainer } from './StatusHeader.styles'

interface Props {
  order?: OrderItem
}

const icons = [
  DoodleOneIcon,
  DoodleTwoIcon,
  DoodleThreeIcon,
  DoodleFourIcon,
  DoodleFiveIcon,
  DoodleSixIcon,
  DoodleSevenIcon
]

const StatusHeader = ({ order }: Props) => {
  const statusHeaderRef = useRef(null)
  const { validatedStep, paidStep, pendingStep, sendStep, readyStep, treatedStep, canceledStep } = useOrderSteps(order)
  const hasSendStep =
    order?.shippingMode?.shippingType !== ShippingTypeName.SHOP ||
    (order.shippingMode.shippingType === ShippingTypeName.SHOP && order?.stock?.id !== order?.shippingMode?.store?.id)
  const hasReadyStep =
    order?.stock?.id !== order?.shippingMode?.store?.id || order?.shippingMode?.shippingType === ShippingTypeName.SHOP

  const allSteps = [
    validatedStep,
    paidStep,
    pendingStep,
    ...(hasSendStep ? [sendStep] : []),
    ...(hasReadyStep ? [readyStep] : []),
    treatedStep,
    ...(canceledStep ? [canceledStep] : [])
  ]

  return (
    <StatusContainer ref={statusHeaderRef}>
      {allSteps.map((step, index) => (
        <StatusBlock
          icon={icons[index]}
          title={step.title}
          type={step.status}
          containerRef={statusHeaderRef}
          boldText={formatDateAndHour(step.date)}
          lightText={step.name}
          key={`status-${index}`}
          secondaryStatus={step.secondaryStatus}
        />
      ))}
    </StatusContainer>
  )
}

export default StatusHeader
