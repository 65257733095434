import { yupResolver } from '@hookform/resolvers/yup'
import { useDownloadFile } from 'api/basePlayInApi'
import { editOrder, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import { useOrderContext } from 'app/OrderDetails/helper'
import { DuoSectionGrid } from 'app/OrderDetails/OrderDetails.styles'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import InputRadio from 'components/InputRadio/InputRadio'
import { ColumnContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import { LabelStyled } from 'components/Layouts/TextLayout/TextLayout.style'
import { Text } from 'components/Text/Text.styles'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon, SaveIcon } from 'constants/icons'
import ConfirmModal from 'features/Modals/ConfirmModal'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { UnmodifiableOrderStatuses } from 'types/enums'
import { RoleAction } from 'types/playInApiInterfaces'
import { hasOneOfChecksMethod, hasOnlyOneOfChecksMethod } from 'utils/formHelper'
import * as yup from 'yup'
import { PrepareRadioContainer, PrepareRadioGroup } from './PreparePrintCard.style'
interface BillPrintCardInputs {
  printed: boolean
  excludedFromPrints: boolean
}

type Props = {
  order?: GetOrderByIdResponse
}

const permissions = [RoleAction.ROLE_ACTION_ORDER_PRINT]

const schema = yup
  .object()
  .shape({
    printed: yup.boolean().default(false),
    excludedFromPrints: yup.boolean().default(false)
  })
  .test(hasOneOfChecksMethod())
  .test(hasOnlyOneOfChecksMethod())

function PreparePrintCard({ order }: Props) {
  const { t } = useTranslation()
  const [confirmPrintModalOpen, toggleConfirmPrintModal] = useState(false)
  const { downloadFile, isLoading: downloadLoading } = useDownloadFile()

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm<BillPrintCardInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      printed: false,
      excludedFromPrints: false
    }
  })
  const { mutate, isLoading } = useMutation((body: any) => editOrder(body, order?.id!), {
    onSuccess: (data) => {
      /*We dont need to use prev data here, besides this is causing trouble when removing print state*/
      queryClient.setQueryData(key, data)
      resetField('printed')
      resetField('excludedFromPrints')
    }
  })

  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)

  const onSubmit = ({ printed, excludedFromPrints }: BillPrintCardInputs) => {
    if (order) {
      mutate({
        excludedFromPrints: order?.excludedFromPrints ? !excludedFromPrints : excludedFromPrints,
        printed: order?.printed ? !printed : printed
      })
    }
  }

  const { inMyStoresOrder } = useOrderContext()

  const printOrder = async () => {
    await downloadFile(`${API_ROUTES.downloads.order}/${order?.id}`)
    queryClient.refetchQueries(orderByIdQueryKey(order?.id!))
    toggleConfirmPrintModal(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title2>{t('page.order.detail.tab.print.orderPrintTitle')}</Title2>
        <DuoSectionGrid>
          <ColumnContainer>
            <LabelStyled>{t('page.order.detail.tab.print.prepareLabel')}</LabelStyled>
            <ShouldDisable permissions={permissions} deniedExtraCondition={!inMyStoresOrder}>
              <Button
                variant="warning"
                icon={PrintIcon}
                disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
                isLoading={downloadLoading}
                onClick={() => (order?.printed ? toggleConfirmPrintModal(true) : printOrder())}
              >
                {t('page.order.detail.tab.print.printAction')}
              </Button>
            </ShouldDisable>
          </ColumnContainer>

          <CanAccess
            permissions={permissions}
            allowedComponent={
              <div>
                <PrepareRadioGroup>
                  <PrepareRadioContainer>
                    <InputRadio
                      display="checkbox"
                      label={
                        order?.printed
                          ? t('page.order.detail.tab.print.removePrintedStatus')
                          : t('page.order.detail.tab.print.addPrintedStatus')
                      }
                      id="printed"
                      control={control}
                      layout="row-reverse"
                    />
                  </PrepareRadioContainer>
                  <PrepareRadioContainer>
                    <InputRadio
                      display="checkbox"
                      label={
                        order?.excludedFromPrints
                          ? t('page.order.detail.tab.print.includeIntoPrints')
                          : t('page.order.detail.tab.print.excludeFromPrints')
                      }
                      id="excludedFromPrints"
                      control={control}
                      layout="row-reverse"
                      disabled={order?.printed}
                      className={classNames({ disabled: order?.printed })}
                    />
                  </PrepareRadioContainer>
                  {errors['oneOfChecks'] && (
                    <BasicFieldValidationError
                      error={errors['oneOfChecks']}
                      message={t('page.order.detail.tab.print.errorNeedOneOption')}
                    />
                  )}
                  {!order?.printed && !errors['oneOfChecks'] && (
                    <BasicFieldValidationError
                      error={errors['onlyOneOfChecks']}
                      message={t('page.order.detail.tab.print.errorNeedOnlyOneOfOptions')}
                    />
                  )}
                </PrepareRadioGroup>
                <Button
                  shape="circle"
                  variant="default"
                  icon={SaveIcon}
                  buttonType="submit"
                  disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
                  isLoading={isLoading}
                />
              </div>
            }
            deniedComponent={
              <InputLayout className="no-border" label={t('page.order.detail.tab.print.excludeFromPrints')}>
                <TextFieldDisplay
                  text={order?.excludedFromPrints ? t('common.radioButton.yes') : t('common.radioButton.no')}
                />
              </InputLayout>
            }
            deniedExtraCondition={!inMyStoresOrder}
          />
        </DuoSectionGrid>
      </form>
      <ConfirmModal
        onClose={() => toggleConfirmPrintModal(false)}
        open={confirmPrintModalOpen}
        callback={printOrder}
        title={t('page.order.detail.tab.print.modal.title')}
        confirmLabel={t('page.order.detail.tab.print.printAction')}
        confirmBtnProps={{
          variant: 'warning',
          icon: PrintIcon,
          isLoading: downloadLoading
        }}
        closeOnCallback={false}
      >
        <Text fontWeight="light">{t('page.order.detail.tab.print.modal.content', { order: order?.referenceNumber })}</Text>
      </ConfirmModal>
    </>
  )
}

export default PreparePrintCard
