import { yupResolver } from '@hookform/resolvers/yup'
import { FamilyStatus } from 'api/ranges'
import { getRetailersUri } from 'api/retailers'
import { useGetTaxes } from 'api/taxes'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import Textarea from 'components/Textarea/Textarea'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { useFamilyFilters, useProductCategoriesOptions } from 'hooks/useSelectOptions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { SortingDirection } from 'types/sorting'
import { getRequiredLabel } from 'utils/formHelper'
import * as yup from 'yup'
import { CreateFormLayout, FieldsCol } from './ProductSheetRequest.style'

const schema = yup.object({
  name: yup.string().trim().required(),
  family: yup.string().trim().required(),
  category: yup.string().trim().required(),
  comment: yup.string().trim(),
  barcode: yup.string().trim(),
  distributor: yup.string().trim(),
  sellPrice: yup.number(),
  advisedPrice: yup.number(),
  tax: yup.string().trim().required(),
  pricingPolicy: yup.boolean(),
  bookLaw: yup.boolean()
})

export interface SheetCreateInputs extends yup.InferType<typeof schema> {}
interface Props {
  onSubmit: (formData: SheetCreateInputs) => void
  isLoading?: boolean
}
export default function ProductSheetRequestForm({ onSubmit, isLoading }: Props) {
  const { t } = useTranslation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue
  } = useForm<SheetCreateInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      pricingPolicy: true,
      bookLaw: false
    }
  })

  const { data: taxes } = useGetTaxes({
    onSuccess(data) {
      setValue('tax', data.find((e) => e.rate === 20)?.['@id'] ?? '')
    }
  })
  const { familyOptions } = useFamilyFilters({ 'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage], 'order[name]': SortingDirection.Asc })

  const [watchFamily, followPricingPolicy] = watch(['family', 'pricingPolicy'])
  const { productCategoriesOptions, productCategoriesOptionsPagination } = useProductCategoriesOptions(
    {
      family: watchFamily,
      'order[name]': SortingDirection.Asc
    },
    '@id',
    { enabled: !!watchFamily }
  )

  useEffect(() => {
    if (followPricingPolicy) {
      setValue('sellPrice', undefined)
    }
  }, [setValue, followPricingPolicy])

  return (
    <CardLayout>
      <Title2>{t('page.productRequest.create.formTitle')}</Title2>
      <form onSubmit={handleSubmit((formData) => onSubmit(formData))}>
        <CreateFormLayout>
          <FieldsCol>
            <div>
              <Input
                register={register}
                id="name"
                label={getRequiredLabel(t('common.label.productName'))}
                className={classNames({
                  'is-invalid': errors.name
                })}
              />
              <BasicFieldValidationError error={errors.name} message={t('form.validation.required.name')} />
            </div>

            <Input register={register} id="barcode" label={t('common.label.barcode')} />

            <div>
              <Select
                control={control}
                id="tax"
                options={taxes?.map((e) => ({ value: e['@id'], label: `${e.rate} %` })) ?? []}
                label={t('common.label.vatRate')}
                placeholder={t('common.select.defaultOptions.vatRate')}
                className={classNames({
                  'is-invalid': errors.tax
                })}
              />
              <BasicFieldValidationError error={errors.tax} message={t('form.validation.required.generic')} />
            </div>
          </FieldsCol>
          <FieldsCol>
            <InputAutoComplete
              label={t('common.label.distributor')}
              labelKey="fullName"
              valueKey="@id"
              getUrlFromSearch={(search) => getRetailersUri({ search })}
              id="distributor"
              control={control}
              placeholder={t('common.select.defaultOptions.distributor')}
            />
            <div>
              <Select
                control={control}
                id="family"
                options={familyOptions}
                label={getRequiredLabel(t('common.label.family'))}
                placeholder={t('common.select.defaultOptions.familly')}
                className={classNames({
                  'is-invalid': errors.family
                })}
              />
              <BasicFieldValidationError error={errors.family} message={t('form.validation.required.range')} />
            </div>
            <div>
              <Select
                control={control}
                id="category"
                options={productCategoriesOptions}
                label={getRequiredLabel(t('common.label.category'))}
                placeholder={t('common.select.defaultOptions.categories')}
                disabled={!watchFamily}
                className={classNames({
                  'is-invalid': errors.category
                })}
                {...productCategoriesOptionsPagination}
              />
              <BasicFieldValidationError error={errors.category} message={t('form.validation.required.category')} />
            </div>
          </FieldsCol>
          <FieldsCol>
            <InputFormattedNumber
              control={control}
              id="sellPrice"
              label={t('common.label.mainPrice')}
              decimalScale={2}
              suffix={`\u00a0€`}
              disabled={followPricingPolicy}
            />
            <InputFormattedNumber
              control={control}
              id="advisedPrice"
              label={t('common.label.advisedPrice')}
              decimalScale={2}
              suffix={`\u00a0€`}
              tooltip={
                <Tooltip id="advisedPriceTooltip">
                  <Trans i18nKey={'page.productRequest.create.tooltips.advisedPrice'} />
                </Tooltip>
              }
            />
            <div>
              <InputRadio
                  id="pricingPolicy"
                  display="checkbox"
                  control={control}
                  label={t('common.label.pricingPolicy')}
                  layout="row-reverse"
                  tooltip={
                    <Tooltip id="pricingPolicyTooltip">
                      <Trans i18nKey={'page.product.detail.tabs.productStore.tooltips.pricingPolicy'} />
                    </Tooltip>
                  }
              />
              <InputRadio
                  id="bookLaw"
                  display="checkbox"
                  control={control}
                  label={t('common.label.bookLawSubjected')}
                  layout="row-reverse"
                  tooltip={
                    <Tooltip id="bookLawTooltip">
                      <Trans i18nKey={'page.product.detail.tabs.productStore.tooltips.bookLaw'} />
                    </Tooltip>
                  }
              />
            </div>
          </FieldsCol>

          <Textarea id="comment" register={register} label={t('common.label.comment')} />
        </CreateFormLayout>
        <Button buttonType="submit" size="app" isLoading={isLoading}>
          {t('page.productRequest.create.createBtn')}
        </Button>
      </form>
    </CardLayout>
  )
}
