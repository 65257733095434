import { API_ROUTES } from 'constants/configs'
import { useQuery } from 'react-query'
import { Country } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type FetchCountriesResponse = Api.GetCountries.ResponseBody
type FetchCountriesQueryParams = Api.GetCountries.RequestQuery

const getCountriesUri = (params: FetchCountriesQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.countries.root, queryString].join('?')
}
const fetchCountries = async (uri: string): Promise<Country[]> => {
  let res = await playInApi.get<FetchCountriesResponse>(uri)
  return res.data['hydra:member']
}

export const useGetCountries = () =>
  useQuery('all-countries', () => fetchCountries(getCountriesUri({ pagination: false })))

export const useGetCountry = (countryCode: string) =>
  useQuery(['country', countryCode], () => fetchCountries(getCountriesUri({ pagination: false, alpha2: countryCode })))
