import styled from 'styled-components'

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem 3.5rem;
  margin-top: 3.5rem;
  align-items: end;

  & > .title,
  & > .subtitle {
    grid-column: span 3;
  }
`
