import { yupResolver } from '@hookform/resolvers/yup'
import playInSvg from 'assets/img/playin.png'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import EmailValidationErrors from 'components/FormValidationErrors/EmailValidationErrors'
import Input from 'components/Input/Input'
import MainTitle from 'components/Title/MainTitle'
import { LockIcon } from 'constants/icons'
import useAuth from 'hooks/useAuth'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Form, Header, LoginButton, PlayinLogo, StyledCard, StyledSection } from './Login.styles'
type LoginForm = {
  email: string
  password: string
}

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().trim().required('form.validation.required.password')
})

const Login = () => {
  const { isAuth } = useAuth()
  const navigate = useNavigate()
  const { login } = useAuth()
  const { handleSubmit, formState, register } = useForm<LoginForm>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const { t } = useTranslation()

  const { errors, isSubmitting } = formState

  const onSubmit = async (data: LoginForm) => {
    await login(data.email, data.password)
    navigate(-1)
  }

  if (isAuth) {
    return <Navigate to="/" replace />
  }

  return (
    <>
      <Header>
        <PlayinLogo src={playInSvg} />
      </Header>
      <StyledSection>
        <StyledCard>
          <MainTitle title={t('page.login.title')} />

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Input
                className={`${errors.email ? 'is-invalid' : ''}`}
                label={t('common.label.email')}
                type="email"
                id="email"
                register={register}
              />
              <EmailValidationErrors error={errors.email} />
            </div>

            <div>
              <Input
                className={`${errors.password ? 'is-invalid' : ''}`}
                label={t('common.label.password')}
                type="password"
                id="password"
                register={register}
              />
              <BasicFieldValidationError error={errors.password} message={t('form.validation.required.password')} />
            </div>

            <LoginButton isLoading={isSubmitting} buttonType="submit" icon={LockIcon}>
              {t('common.button.login')}
            </LoginButton>
          </Form>
        </StyledCard>
      </StyledSection>
    </>
  )
}

export default Login
