import _ from 'lodash'
import { ComponentType } from 'react'
import { Container, StyledSvg, StyledSvgProps } from './Svg.style'

interface Props extends StyledSvgProps {
  svg: ComponentType
  className?: string
  onClick?: (e: React.MouseEvent) => void
  title?: string
  allowPropagation?: boolean
}

export default function Svg({ svg, color, size, className, title, onClick = _.noop, allowPropagation = false }: Props) {
  return (
    <Container
      className={className}
      onClick={(e) => {
        if (!allowPropagation) e.stopPropagation()
        onClick(e)
      }}
      title={title}
      size={size}
    >
      <StyledSvg as={svg} size={size} color={color} />
    </Container>
  )
}
