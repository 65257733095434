import { FetchStockEntriesQueryParams, getStockEntriesUri, useOrdersPaginatedQuery } from 'api/stockEntry'
import classNames from 'classnames'
import FiltersList, { Filter } from 'components/FiltersList/FiltersList'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { SearchIcon } from 'constants/icons'
import CountWithPerPageSelect from 'features/Listing/CountWithPerPageSelect'
import { Columns } from 'features/Listing/Listing'
import { useStockStatusOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { StockEntry } from 'types/entities'
import { StockStatus } from 'types/playInApiInterfaces'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatDateAndHour } from 'utils/dates'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import { mergeFormQueryParams } from 'utils/queryParams'
import { SearchButton } from '../salesTab/ProductSalesTab.styles'
import { Form, StyledListing } from './ProductStocksTab.styles'
import useStockFilter, { FormData } from './useStockFilter'

type Props = {
  productId: string
  active: boolean
}

type FormattedData = {
  stockId?: number
  retailer?: string | null
  quantity?: number
  soldQuantity?: number
  remainingQuantity?: number
  buyPrice?: number
  date?: string
  status?: StockStatus
}

const formatStockEntry = (stockEntry: StockEntry): FormattedData => ({
  buyPrice: stockEntry.buyPrice,
  date: formatDateAndHour(stockEntry?.stock?.createdAt),
  quantity: stockEntry.quantity,
  remainingQuantity: stockEntry.remainingQuantity,
  retailer: stockEntry.stock?.retailer?.fullName,
  soldQuantity: stockEntry.soldQuantity,
  stockId: stockEntry.stock?.id,
  status: stockEntry.stock?.status
})

const defaultOrder: OrderParam<FetchStockEntriesQueryParams> = {
  sortedBy: 'date',
  direction: SortingDirection.Desc,
  queryParams: { 'order[stock.createdAt]': SortingDirection.Desc }
}

const addDefaultQueryParams = (queryParams: FetchStockEntriesQueryParams): FetchStockEntriesQueryParams => {
  const restParams = { transfer: false }
  if (!queryParams['order[stock.createdAt]']) {
    return {
      ...queryParams,
      ...restParams,
      'order[stock.createdAt]': SortingDirection.Desc
    }
  }
  return { ...queryParams, ...restParams }
}

const ProductStocksTab = ({ productId, active }: Props) => {
  const { t } = useTranslation()
  const [atomLocation] = useAtom(defaultStoreAtom)
  const [perPage, setPerPage] = useState(30)
  const { control, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      location: atomLocation?.['@id']
    }
  })

  const { setQueryParams, myStoresOptions, filters, queryParams, setFilters, quantityOptions, onSubmit } =
    useStockFilter()
  const [orderParams, setOrderParams] = useState<OrderParam<FetchStockEntriesQueryParams>>(defaultOrder)

  useEffect(() => {
    if (orderParams?.queryParams) {
      setQueryParams((prev) => ({ ...prev, order: orderParams?.queryParams }))
    }
  }, [orderParams, setQueryParams])

  const { getStatusTraduction } = useStockStatusOptions()

  const [formattedStockEntries, setFormattedStockEntries] = useState<FormattedData[]>([])

  const { fetchNextPage, isFetching, totalItems, isFetchingNextPage, hasNextPage } = useOrdersPaginatedQuery(
    ['productStockEntries', productId, queryParams, perPage],
    getStockEntriesUri(
      addDefaultQueryParams({
        product: productId,
        ...mergeFormQueryParams<FetchStockEntriesQueryParams>({
          ...queryParams,
          status: { 'stock.status[]': [StockStatus.Created, StockStatus.Treated, StockStatus.Validated] },
          itemsPerPage: perPage
        })
      })
    ),
    {
      enabled: active,
      onSuccess: (stockEntries) => setFormattedStockEntries(stockEntries.map(formatStockEntry))
    }
  )

  const removeFilters = (filter: Filter) => {
    setValue(filter.id as keyof FormData, '')
    setFilters(filters.filter((e) => e.id !== filter.id))
    setQueryParams(_.omit(queryParams, filter.id))
  }

  const columns: Columns<FormattedData, FetchStockEntriesQueryParams> = [
    {
      name: t('common.label.date'),
      key: 'date',
      sortable: true,
      orderQueryParam: 'order[stock.createdAt]',
      decorator: (stock) => (
        <ClickableCell
          route={RouteName.StockDetails}
          value={stock.stockId}
          className={classNames({
            unavailable: stock.status === StockStatus.Canceled
          })}
        >
          <Text>{stock.date}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.stockId'),
      key: 'stockId',
      decorator: (stock) => (
        <ClickableCell
          route={RouteName.StockDetails}
          value={stock.stockId}
          className={classNames({
            unavailable: stock.status === StockStatus.Canceled
          })}
        >
          <Text fontWeight="light" color="secondary">
            {stock.stockId}
          </Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.retailer'),
      key: 'retailer',
      decorator: (stock) => (
        <ClickableCell
          route={RouteName.StockDetails}
          value={stock.stockId}
          className={classNames({
            unavailable: stock.status === StockStatus.Canceled
          })}
        >
          <Text>{stock.retailer}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.status'),
      key: 'status',
      decorator: (stock) => (
        <ClickableCell
          route={RouteName.StockDetails}
          value={stock.stockId}
          className={classNames({
            unavailable: stock.status === StockStatus.Canceled
          })}
        >
          <Text fontStyle="italic">{stock.status ? getStatusTraduction(stock.status) : '-'}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('page.product.detail.tabs.buyings.table.quantity'),
      key: 'quantity',
      decorator: (stock) => (
        <ClickableCell
          route={RouteName.StockDetails}
          value={stock.stockId}
          className={classNames({
            unavailable: stock.status === StockStatus.Canceled
          })}
        >
          <TextNumber value={stock.quantity} />
        </ClickableCell>
      )
    },
    {
      name: t('page.product.detail.tabs.buyings.table.soldQuantity'),
      key: 'soldQuantity',
      decorator: (stock) => (
        <ClickableCell
          route={RouteName.StockDetails}
          value={stock.stockId}
          className={classNames({
            unavailable: stock.status === StockStatus.Canceled
          })}
        >
          <TextNumber value={stock.soldQuantity} fontWeight="light" />
        </ClickableCell>
      )
    },
    {
      name: t('page.product.detail.tabs.buyings.table.remainingQuantity'),
      key: 'remainingQuantity',
      sortable: true,
      orderQueryParam: 'remainingQuantity',
      decorator: (stock) => (
        <ClickableCell
          route={RouteName.StockDetails}
          value={stock.stockId}
          className={classNames({
            unavailable: stock.status === StockStatus.Canceled
          })}
        >
          <TextNumber
            value={stock.remainingQuantity}
            color={stock.remainingQuantity ? 'success' : 'danger'}
            fontWeight="medium"
          />
        </ClickableCell>
      )
    },
    {
      name: t('common.label.buyPrice'),
      key: 'buyPrice',
      decorator: (stock) => (
        <ClickableCell
          route={RouteName.StockDetails}
          value={stock.stockId}
          className={classNames({
            unavailable: stock.status === StockStatus.Canceled
          })}
        >
          <TextNumber value={stock.buyPrice} suffix=" €" decimalScale={2} />
        </ClickableCell>
      )
    }
  ]

  return (
    <div>
      <CardLayout>
        <Text size="1.6rem" fontWeight="bold">
          {t('page.product.detail.tabs.buyings.filters')}
        </Text>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Select
            layout="column"
            id="location"
            options={myStoresOptions}
            control={control}
            label={t('common.label.location')}
          />
          <Select
            layout="column"
            id="withQuantity"
            options={quantityOptions}
            control={control}
            label={t('common.label.stockState')}
            enableUnselect={true}
            placeholder={t('page.product.detail.tabs.stocks.filters.placeholder.withQuantity')}
          />
          <SearchButton buttonType="submit" icon={SearchIcon} shape="circle" isLoading={isFetching} />
        </Form>

        <FiltersList
          filters={filters}
          onRemoveFilter={removeFilters}
          showClearAll={false}
          clearExclusion={['location']}
        />
      </CardLayout>
      <CardLayout>
        <Title2>
          <Trans
            i18nKey="page.product.detail.tabs.buyings.results"
            components={{ bold: <strong /> }}
            count={totalItems ?? 0}
          />
        </Title2>
        <StyledListing
          columns={columns}
          data={formattedStockEntries}
          totalCount={totalItems}
          fetchNextPage={fetchNextPage}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          emptyText={t('common.pagination.stock.emptyText')}
          countText={t('common.pagination.stock.countText', {
            current: formattedStockEntries?.length ?? 0,
            total: totalItems ?? 0
          })}
          defaultSort={defaultOrder}
          onOrderChanged={setOrderParams}
          hasNextPage={hasNextPage}
          bottomComponent={
            <CountWithPerPageSelect
              onChange={(perPage) => setPerPage(perPage)}
              value={perPage}
              perPageOptions={[
                { value: 30, label: '30' },
                { value: 100, label: '100' }
              ]}
            />
          }
        />
      </CardLayout>
    </div>
  )
}

export default ProductStocksTab
