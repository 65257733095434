import { yupResolver } from '@hookform/resolvers/yup'
import { PatchStoreBody } from 'api/stores'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'
import Button, { Props as ButtonProps } from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { UseMutateFunction } from 'react-query'
import { StoreDetails } from 'types/entities'
import { useFormSibblingFields } from 'utils/formHelper'
import * as yup from 'yup'
import PlayAreaRadio from '../PlayAreaRadio'
import { FormSection } from '../StoreSeoTab'
import { FieldsWrapper, FormCard } from '../StoreSeoTab.style'

const schema = yup.object().shape(
  {
    playAreaDescription: yup
      .string()
      .trim()
      .nullable()
      .when('playAreaDescriptionEn', { is: (value) => !!value, then: (schema) => schema.required() }),
    playAreaDescriptionEn: yup
      .string()
      .trim()
      .nullable()
      .when('playAreaDescription', { is: (value) => !!value, then: (schema) => schema.required() }),
    catering: yup.boolean(),
    playArea: yup.boolean()
  },
  [['playAreaDescription', 'playAreaDescriptionEn']]
)

interface FormSectionInputs {
  playAreaDescription?: string | null
  playAreaDescriptionEn?: string | null
  catering?: boolean
  playArea?: boolean
}

interface Props {
  getSubmitButtonProps: (id: FormSection) => ButtonProps
  mutation: UseMutateFunction<StoreDetails, AxiosResponse<never>, PatchStoreBody>
  store: StoreDetails
}

const getDefaultValues = (store: StoreDetails): FormSectionInputs => ({
  playAreaDescription: store?.playAreaDescription,
  playAreaDescriptionEn: store?.playAreaDescriptionEn,
  catering: store.catering,
  playArea: store.playArea
})

export default function PlayAreaFormSection({ mutation, getSubmitButtonProps, store }: Props) {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger
  } = useForm<FormSectionInputs>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(store)
  })
  const [watchCatering, watchPlayArea] = watch(['catering', 'playArea'])

  const onSubmit = (formData: FormSectionInputs) => {
    mutation(formData)
  }

  useFormSibblingFields([['playAreaDescription', 'playAreaDescriptionEn']], { watch, trigger })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormCard>
        <Title2>{t('page.stores.details.storeSeo.sections.playArea')}</Title2>
        <PlayAreaRadio
          catering={watchCatering}
          playArea={watchPlayArea}
          setCatering={(value: boolean) => setValue('catering', value)}
          setPlayArea={(value: boolean) => setValue('playArea', value)}
        />
        <FieldsWrapper>
          <div>
            <RichTextEditor
              control={control}
              id="playAreaDescription"
              label={t('page.stores.details.storeSeo.labels.playAreaDescription')}
              className={classNames({ 'is-invalid': errors.playAreaDescription })}
              tooltip={
                <Tooltip id="fieldTooltip">
                  <Trans i18nKey="page.stores.details.storeSeo.tooltips.playAreaDescription" />
                </Tooltip>
              }
            />
            <BasicFieldValidationError
              error={errors.playAreaDescription}
              message={t('form.validation.required.genericFr')}
            />
          </div>

          <div>
            <RichTextEditor
              control={control}
              id="playAreaDescriptionEn"
              label={t('page.stores.details.storeSeo.labels.playAreaDescriptionEn')}
              className={classNames({ 'is-invalid': errors.playAreaDescriptionEn })}
            />
            <BasicFieldValidationError
              error={errors.playAreaDescriptionEn}
              message={t('form.validation.required.genericEn')}
            />
          </div>
        </FieldsWrapper>
        <Button {...getSubmitButtonProps('playArea')} />
      </FormCard>
    </form>
  )
}
