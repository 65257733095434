import Svg from 'components/Svg/Svg'
import { Text, UpperCaseText } from 'components/Text/Text.styles'
import { ComponentType, MutableRefObject, useEffect, useRef } from 'react'
import { TextColors } from 'theme/theme'
import {
  Icon,
  InfoBlock,
  SecondaryStatusBlock,
  SecondaryStatusInfo,
  StatusContainer,
  StatusWrapper,
  TitleBlock
} from './StatusBlock.styles'

export enum StatusTypes {
  active = 'active',
  pending = 'pending',
  history = 'history',
  canceled = 'canceled',
  old = 'old'
}

interface Props {
  type: StatusTypes
  icon: string | ComponentType
  title: string
  boldText?: string | null
  lightText?: string | null
  containerRef: MutableRefObject<null>
  secondaryStatus?: string | JSX.Element | boolean | null
}

type Theme = {
  textColor: keyof TextColors
}

type Themes = {
  active: Theme
  pending: Theme
  history: Theme
  canceled: Theme
  old: Theme
}

const themes: Themes = {
  active: {
    textColor: 'white'
  },
  pending: {
    textColor: 'secondary2'
  },
  history: {
    textColor: 'black'
  },
  canceled: {
    textColor: 'white'
  },

  old: {
    textColor: 'white'
  }
}

const scrollToActive = (statusBlock, statusHeader) => {
  if (statusBlock && statusHeader) {
    let blockRelativeOffset = statusBlock.offsetLeft - statusHeader.offsetLeft
    let marginLeft = (statusHeader.offsetWidth - statusBlock.offsetWidth) / 2

    statusHeader.scroll({ left: blockRelativeOffset - marginLeft, top: 0, behavior: 'smooth' })
  }
}

const StatusBlock = ({ type, icon, title, boldText, lightText, containerRef, secondaryStatus }: Props) => {
  const statusRef = useRef(null)

  useEffect(() => {
    if (containerRef && [StatusTypes.active, StatusTypes.canceled].includes(type) && statusRef) {
      scrollToActive(statusRef.current, containerRef.current)
      window.addEventListener('resize', () => scrollToActive(statusRef.current, containerRef.current))
    }
  }, [containerRef, type, statusRef])

  return (
    <StatusWrapper>
      <StatusContainer className={type} ref={statusRef}>
        {typeof icon === 'string' ? <Icon src={icon} /> : <Icon as={Svg} svg={icon} size="2rem" />}

        <TitleBlock>
          <UpperCaseText
            fontWeight="bold"
            color={themes[type].textColor}
            fontStyle={type === StatusTypes.old ? 'italic' : 'normal'}
          >
            {title}
          </UpperCaseText>
        </TitleBlock>
        {type !== 'pending' && (
          <InfoBlock>
            <Text color={themes[type].textColor}>{boldText}</Text>
            <Text fontWeight="light" color={themes[type].textColor}>
              {lightText}
            </Text>
          </InfoBlock>
        )}
      </StatusContainer>
      {secondaryStatus && type === StatusTypes.active && (
        <SecondaryStatusBlock className={type}>
          <SecondaryStatusInfo>{secondaryStatus}</SecondaryStatusInfo>
        </SecondaryStatusBlock>
      )}
    </StatusWrapper>
  )
}

export default StatusBlock
