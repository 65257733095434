import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { RowContainer } from 'components/Layouts/InputLayout/InputLayout.styles'
import { Section } from 'components/Section/Section.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'
import { Image } from './ImageCard.styles'

export const HiddenOverflowSection = styled(Section)`
  overflow: hidden;
`

export const HeaderContainer = styled(RowContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  margin-bottom: 2rem;
`

export const HeaderCard = styled(CardLayout)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 0;
`

export const BannerCardLayout = styled(CardLayout)`
  padding-bottom: 0.5rem; // Adding extra padding for scrollbar visibility, so we reduce the cardLayout padding
`

export const SearchFormLayout = styled.form`
  display: flex;
  gap: 3.5rem;
  align-items: flex-end;
`

export const TitleContainer = styled(RowContainer)`
  align-items: flex-start;
`

export const CarouselContainer = styled(RowContainer)`
  gap: 9rem;
`

export const ImageCardContainer = styled(RowContainer)`
  gap: 0;
  // atlassian/react-beautiful-dnd don't support wrapped list for now
  overflow-x: auto;
  margin-top: 2rem;
  padding-bottom: 1.5rem;
`

export const CatalogItemLayout = styled.div`
  display: flex;
  width: 25rem;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  ${Image} {
    border: 1px solid #c4c4c4;
  }
`

export const CatalogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(30rem, 1fr));
  gap: 2rem 5rem;
`

export const CatalogCard = styled(CardLayout)`
  display: grid;
  gap: 2rem;
  ${Title2} {
    margin: 0;
  }
`
export const CenteredButton = styled(Button)`
  justify-self: center;
`
