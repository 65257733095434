import checkSvg from 'assets/svg/icon/check.svg'
import styled from 'styled-components'

export const Radio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2rem;
  width: 2rem;

  border: 1px solid #c4c4c4;
  border-radius: 50%;
  cursor: pointer;

  &.is-invalid {
    border: 2px solid ${(props) => props.theme.borderColors.danger};
  }
`

export const Checkbox = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;

  border: 1px solid #c4c4c4;
  border-radius: 0.4rem;
  cursor: pointer;

  &.checked {
    background-image: url(${checkSvg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
  }

  &.is-invalid {
    border: 2px solid ${(props) => props.theme.borderColors.danger};
  }

  &.disabled {
    pointer-events: none;
    background-color: ${(props) => props.theme.backgroundColors.secondary};
  }
`

export const Dot = styled.div`
  visibility: hidden;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.black};

  &.checked {
    visibility: visible;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`

export const ChoiceContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
  &.disabled {
    pointer-events: none;
  }
`

export const CheckboxItem = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`
