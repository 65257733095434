import { usePatchRole } from 'api/role'
import Button from 'components/Button/Button'
import MultipleSelect from 'components/Select/MultipleSelect'
import { MoreIcon } from 'constants/icons'
import { useRoleActionByRole } from 'hooks/entityHooks/permissionsHooks'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Role } from 'types/entities'
import { AddFormLayout } from '../PermissionManage.style'
interface Props {
  role: Role
}
interface AddRoleActionInputs {
  roleActions: string[]
}
export default function AddRoleActionForm({ role }: Props) {
  const { reset, control, handleSubmit, watch } = useForm<AddRoleActionInputs>()
  const { t } = useTranslation()
  const { availableRoleActionsOptions } = useRoleActionByRole(role)
  const { mutate: patchRoleMutation, isLoading } = usePatchRole(role.id!, { callback: () => reset() })
  const onSubmit = (formData: AddRoleActionInputs) => {
    if (formData.roleActions) {
      patchRoleMutation({
        collaboratorActions: _.map(role.collaboratorActions, (e) => e['@id']!).concat(formData.roleActions)
      })
    }
  }

  return (
    <AddFormLayout onSubmit={handleSubmit(onSubmit)}>
      <MultipleSelect
        options={availableRoleActionsOptions}
        control={control}
        id="roleActions"
        label={t('page.permissions.manage.labels.addAction')}
        placeholder={t('page.permissions.manage.labels.placeholder.action')}
        disabled={!availableRoleActionsOptions?.length}
      />
      <Button
        shape="circle"
        icon={MoreIcon}
        buttonType="submit"
        iconColor="white"
        isLoading={isLoading}
        disabled={!watch('roleActions')}
      />
    </AddFormLayout>
  )
}
