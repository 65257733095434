import { fetchCollection } from 'api/basePlayInApi'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import { CollectionResults } from 'types/playinApi'

export type PaginatedHookOptions<T> = Omit<UseInfiniteQueryOptions<CollectionResults<T>>, 'onSuccess' | 'onError'> & {
  onSuccess?: (data: Array<T>, totalItems?: number) => void
  onError?: (error?: unknown | undefined) => void
}

export const usePaginatedQuery = <T>(queryKey: QueryKey, query: string, options?: PaginatedHookOptions<T>) => {
  const { onSuccess, onError, ...hookOptions } = options ?? {}
  const [hasBeenCalled, setHasBeenCalled] = useState(false)
  const [flattenedData, setFlattenedData] = useState<Array<T>>([])
  const [totalItems, setTotalItems] = useState<number | undefined>()

  const useInfiniteQueryHook = useInfiniteQuery<CollectionResults<T>>(
    queryKey,
    ({ pageParam = query }) => fetchCollection<T>(pageParam),
    {
      ...hookOptions,
      onSuccess: (data) => {
        setHasBeenCalled(true)
        const flattenedCollection = _.flatten((data?.pages ?? []).map((page) => page.data))
        if (onSuccess) onSuccess(flattenedCollection, data.pages[0].totalItems)
      },
      onError: (error) => {
        if (onError) onError(error)
      },
      getNextPageParam: (lastPage) => {
        return lastPage.pagination?.['hydra:next']
      },
      getPreviousPageParam: (lastPage) => {
        return lastPage.pagination?.['hydra:previous']
      }
    }
  )

  useEffect(() => {
    setTotalItems(useInfiniteQueryHook.data?.pages[0].totalItems)
    setFlattenedData(_.flatten(useInfiniteQueryHook.data?.pages.map((page) => page.data)))
  }, [useInfiniteQueryHook.data])

  return {
    ...useInfiniteQueryHook,
    data: flattenedData,
    totalItems,
    hasBeenCalled
  }
}
