import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { FontWeight, TextColors } from 'theme/theme'

export enum CashboxCoinsCountEuro {
  Cent1 = '1_cents',
  Cents2 = '2_cents',
  Cents5 = '5_cents',
  Cents10 = '10_cents',
  Cents20 = '20_cents',
  Cents50 = '50_cents',
  Euro1 = '1_euros',
  Euros2 = '2_euros'
}

export enum CashboxBillsCountEuro {
  Euros5 = '5_euros',
  Euros10 = '10_euros',
  Euros20 = '20_euros',
  Euros50 = '50_euros',
  Euros100 = '100_euros',
  Euros200 = '200_euros',
  Euros500 = '500_euros'
}

export enum DepositFields {
  Deposit = 'depositTotal',
  Gift = 'depositGiftTotal'
}

export type AllCashboxFields = CashboxCoinsCountEuro | CashboxBillsCountEuro | DepositFields

type CashboxFields = {
  [key in AllCashboxFields]?: {
    label: string
    required: boolean
  }
}

type FormData = { [key in AllCashboxFields]?: number }

const eurosConversion = {
  [CashboxCoinsCountEuro.Cent1]: 0.01,
  [CashboxCoinsCountEuro.Cents2]: 0.02,
  [CashboxCoinsCountEuro.Cents5]: 0.05,
  [CashboxCoinsCountEuro.Cents10]: 0.1,
  [CashboxCoinsCountEuro.Cents20]: 0.2,
  [CashboxCoinsCountEuro.Cents50]: 0.5,
  [CashboxCoinsCountEuro.Euro1]: 1,
  [CashboxCoinsCountEuro.Euros2]: 2,
  [CashboxBillsCountEuro.Euros5]: 5,
  [CashboxBillsCountEuro.Euros10]: 10,
  [CashboxBillsCountEuro.Euros20]: 20,
  [CashboxBillsCountEuro.Euros50]: 50,
  [CashboxBillsCountEuro.Euros100]: 100,
  [CashboxBillsCountEuro.Euros200]: 200,
  [CashboxBillsCountEuro.Euros500]: 500
}

export const getCashboxCount = (cashbox: FormData) => {
  let inEuros = _.map(cashbox, (value, key) => {
    return parseFloat((value! * eurosConversion[key]).toFixed(2))
  })
  return _.sum(inEuros)
}

export const getDifferenceColor = (difference?: number | null): keyof TextColors => {
  if (!difference) return 'black'
  else if (difference > 0) return 'success'
  else return 'danger'
}

export const getDifferencePrefix = (price?: number | null) => {
  if (!price) return ''
  return price > 0 ? '+\u00a0' : '\u00a0'
}

export const getDifferenceFontWeight = (price?: number | null): keyof FontWeight => {
  return price !== 0 ? 'medium' : 'light'
}

export default function useCashbox() {
  const { t } = useTranslation()

  const coinFields: CashboxFields = {
    [CashboxCoinsCountEuro.Cent1]: { required: false, label: t('common.cash.euro_cents', { value: 1 }) },
    [CashboxCoinsCountEuro.Cents2]: { required: false, label: t('common.cash.euro_cents', { value: 2 }) },
    [CashboxCoinsCountEuro.Cents5]: { required: false, label: t('common.cash.euro_cents', { value: 5 }) },
    [CashboxCoinsCountEuro.Cents10]: { required: false, label: t('common.cash.euro_cents', { value: 10 }) },
    [CashboxCoinsCountEuro.Cents20]: { required: false, label: t('common.cash.euro_cents', { value: 20 }) },
    [CashboxCoinsCountEuro.Cents50]: { required: false, label: t('common.cash.euro_cents', { value: 50 }) },
    [CashboxCoinsCountEuro.Euro1]: { required: false, label: t('common.cash.euros', { value: 1 }) },
    [CashboxCoinsCountEuro.Euros2]: { required: false, label: t('common.cash.euros', { value: 2 }) }
  }

  const billFields: CashboxFields = {
    [CashboxBillsCountEuro.Euros5]: { required: false, label: t('common.cash.euros', { value: 5 }) },
    [CashboxBillsCountEuro.Euros10]: { required: false, label: t('common.cash.euros', { value: 10 }) },
    [CashboxBillsCountEuro.Euros20]: { required: false, label: t('common.cash.euros', { value: 20 }) },
    [CashboxBillsCountEuro.Euros50]: { required: false, label: t('common.cash.euros', { value: 50 }) },
    [CashboxBillsCountEuro.Euros100]: { required: false, label: t('common.cash.euros', { value: 100 }) },
    [CashboxBillsCountEuro.Euros200]: { required: false, label: t('common.cash.euros', { value: 200 }) },
    [CashboxBillsCountEuro.Euros500]: { required: false, label: t('common.cash.euros', { value: 500 }) }
  }

  const depositFields: CashboxFields = {
    [DepositFields.Deposit]: { required: true, label: t('page.cashbox.accountStep.depositTotal') },
    [DepositFields.Gift]: { required: true, label: t('page.cashbox.accountStep.depositGiftTotal') }
  }

  return {
    billFields,
    coinFields,
    depositFields
  }
}
