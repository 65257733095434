import { Option } from 'components/Select/Select'
import useAuth from 'hooks/useAuth'
import _ from 'lodash'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Collaborator, Store } from 'types/entities'

type DeletableRow = {
  isDeletable: boolean
  message?: ReactNode
}
export const useCollaboratorStoresOptions = (collaborator: Collaborator) => {
  const { me } = useAuth()
  const { t } = useTranslation()

  const canDeleteStore = (store: Store): DeletableRow => {
    //Current user own the store
    //Store is not mainStore
    //Store is not only given by role
    if (!me?.stores?.find((e) => e['@id'] === store['@id']))
      return { isDeletable: false, message: t('page.collaborator.details.stores.tooltips.notMyStore') }
    if (store['@id'] === collaborator?.mainStore?.['@id'])
      return { isDeletable: false, message: t('page.collaborator.details.stores.tooltips.cantDeleteMainstore') }
    if (!store.givenManually)
      return { isDeletable: false, message: t('page.collaborator.details.stores.tooltips.cantDeleteFromRole') }
    return {
      isDeletable: true
    }
  }

  const deletableStoresIds =
    collaborator.stores?.filter((store) => canDeleteStore(store)?.isDeletable).map((e) => e['@id']!) ?? []

  //User can add stores he owns, and which are not already manually given to collaborator
  const addableStoresOptions: Option[] =
    me?.stores
      ?.filter((store) => !collaborator.stores?.find((e) => store['@id'] === e['@id'] && e.givenManually))
      ?.map((store) => ({
        label: store.shortName ?? '',
        value: store['@id']
      })) ?? []

  return {
    deletableStoresIds,
    allStores: collaborator.stores,
    addableStoresOptions,
    canDeleteStore
  }
}

export const isInMyStores = (collaborator: Collaborator, myStores?: Store[]) =>
  _.some(collaborator.stores, (collaboratorStore) => myStores?.find((e) => e['@id'] === collaboratorStore['@id']))
