import { getStocksQueryParams } from 'api/stock'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import StockCreateModal from 'features/Modals/StockCreate/StockCreateModal'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation } from 'react-router'
import { RoleAction, StockStatus } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import StockManagementForm, { FormData } from './forms/StockManagementForm'
import PurchasesList from './PurchasesList'
import { CreateSection, CreateSectionButton } from './StockManagement.style'

const defaultParams: getStocksQueryParams = {
  'status[]': [StockStatus.Created, StockStatus.Validated],
  'exists[cardOpening]': false
}

function StockManagement() {
  const { t } = useTranslation()
  const location = useLocation() as Location & { state: null | getStocksQueryParams }
  const [store] = useAtom(defaultStoreAtom)

  const [purchasesParams, setPurchasesParams] = useState<getStocksQueryParams>(location.state || defaultParams)

  const [purchasesLoading, setPurchasesLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const onSubmit = (formData: FormData) => {
    const { billNumber, retailer, status, id, product } = formData
    setPurchasesParams({
      ...defaultParams,
      retailer: retailer || undefined,
      billNumber: billNumber || undefined,
      'status[]': status || undefined,
      id: id || undefined,
      'entries.product': product || undefined
    })
  }

  useEffect(() => {
    // Listen search made from order sidebar search
    // and display results directly
    if (location.state) {
      // as we consumed current state, we reset it for futur reload
      window.history.replaceState({}, document.title)
      setPurchasesParams(location?.state)
    }
  }, [location.state])

  return (
    <>
      <Section>
        <MainTitle title={t('page.stock.management.title')} showStoreSelect={true} />
        <CreateSection>
          <Title2>{t('page.stock.management.create_title')}</Title2>
          <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STOCK_CREATE]}>
            <CreateSectionButton onClick={() => setOpenModal(true)}>
              {t('common.label.create_stock')}
            </CreateSectionButton>
          </ShouldDisable>
        </CreateSection>
        <StockManagementForm onSubmit={onSubmit} isLoading={purchasesLoading} searchParam={purchasesParams.search} />

        <CardLayout>
          <Title2>Transferts en cours :</Title2>
          <Text color="secondary" fontStyle="italic">
            Section en cours de développement
          </Text>
        </CardLayout>

        <PurchasesList
          watchFetch={(isFetching) => setPurchasesLoading(isFetching)}
          queryParams={purchasesParams}
          store={store?.['@id']}
          isSearched={!_.isEmpty(purchasesParams) && !_.isEqual(defaultParams, purchasesParams)}
        />
      </Section>
      <StockCreateModal open={openModal} onClose={() => setOpenModal(!openModal)} />
    </>
  )
}

export default StockManagement
