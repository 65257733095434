import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { StyledTextarea } from 'components/Textarea/Textarea.style'
import styled from 'styled-components'

export const StyledItalicTextarea = styled(StyledTextarea)`
  min-height: 4.5rem;
  padding: 0.5rem 1rem;
  font-style: italic;
  resize: vertical;
`

export const CommentBoxContainer = styled(RowContainer)`
  gap: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
`
