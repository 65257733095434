import React from 'react'
import { RoleAction } from 'types/playInApiInterfaces'
import CanAccess from './CanAccess'

type Props = {
  permissions: RoleAction[]
  deniedExtraCondition?: boolean
  byPassRoleValidation?: boolean
}

const ShouldDisable: React.FunctionComponent<Props> = ({
  children,
  permissions,
  deniedExtraCondition,
  byPassRoleValidation
}) => {
  const disabledChildren = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { disabled: true })
    }
    return child
  })

  // TODO set disabled class or options disabled within children node
  return (
    <CanAccess
      permissions={permissions}
      deniedExtraCondition={deniedExtraCondition}
      allowedComponent={children}
      deniedComponent={<>{disabledChildren}</>}
      byPassRoleValidation={byPassRoleValidation}
    />
  )
}

export default ShouldDisable
