import { getSealedProductsUri, useSealedProductsPaginated } from 'api/sealedProducts'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import Loader from 'components/Loader/Loader'
import MultipleSelect from 'components/Select/MultipleSelect'
import Select, { Option } from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { MoreIcon } from 'constants/icons'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import ProductItem from '../components/ProductItem'
import { AddProductFormWrapper, ProductList, RankWrapper, SmallGapWrapper } from './CategoryForm.style'
interface AddProductsInputs {
  products: string[]
  rank?: 'baseBox' | 'expansionBox' | ''
  productSearch?: string
}

export type ProductCollectionEntry = { product: SealedProduct; rank?: 'expansionBox' | 'baseBox' }

interface AddProductsFormProps {
  handleProducts: (list: ProductCollectionEntry[]) => void
  products: ProductCollectionEntry[]
}

export default function AddProductsForm({ products, handleProducts }: AddProductsFormProps) {
  const [search, setSearch] = useState<string>()
  const [selectedProducts, setSelectedProducts] = useState<ProductCollectionEntry[]>(products)
  const { t } = useTranslation()

  const { control, watch, register, setValue } = useForm<AddProductsInputs>()
  const productSearch = watch('productSearch')
  const watchProducts = watch('products')
  const watchRank = watch('rank')

  const {
    data: sealedProducts,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isIdle,
    totalItems
  } = useSealedProductsPaginated(
    ['sealedProduct:collectionSearch', search],
    getSealedProductsUri({ search: search, 'exists[secondHandOf]': false, 'order[old]': 'asc', 'order[name]': 'asc' }),
    {
      enabled: !!search
    }
  )

  const rankOptions: Option[] = [
    {
      label: t('page.collection.details.form.label.rank.baseBox'),
      value: 'baseBox'
    },
    {
      label: t('page.collection.details.form.label.rank.expansionBox'),
      value: 'expansionBox'
    }
  ]

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearch(productSearch)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [productSearch])

  const addProduct = () => {
    const toAddProducts = sealedProducts
      ?.filter((e) => watchProducts.includes(e['@id']!))
      ?.map((e) => ({ product: e, rank: watchRank || undefined }))

    setSelectedProducts((prev) => _.uniqBy([...toAddProducts, ...prev], (e) => e.product['@id']))
    setValue('rank', '')
    setValue('products', [])
  }

  useEffect(() => handleProducts(selectedProducts), [selectedProducts, handleProducts])
  useEffect(() => setSelectedProducts(products), [products])

  const sortedSelectedProducts = _.orderBy(
    selectedProducts,
    // Sort by baseBoxFirst, then by product releaseDate, then by name
    [(e) => e.rank === 'baseBox', (e) => e.product.releasedAt, (e) => e.product.name],
    ['desc', 'desc', 'asc']
  )

  return (
    <AddProductFormWrapper>
      <SmallGapWrapper>
        <Input
          label={t('page.collection.details.form.label.productSearch')}
          register={register}
          id="productSearch"
          secondaryText={isFetching && <Loader />}
          placeholder={t('page.collection.details.form.placeholder.productSearch')}
        />

        <MultipleSelect
          control={control}
          id="products"
          label={t('page.collection.details.form.label.toAddProduct')}
          options={sealedProducts.map((product) => ({
            label: (
              <>
                {product.old ? (
                  <Text fontStyle="italic">{t('page.stock.detail.products.oldOption', { name: product.name })}</Text>
                ) : (
                  product.name
                )}
              </>
            ),
            value: product['@id']
          }))}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          disabled={isIdle || totalItems === 0}
          placeholder={t('page.collection.details.form.placeholder.toAddProduct')}
        />
        <RankWrapper>
          <Select
            options={rankOptions}
            control={control}
            id="rank"
            label={t('page.collection.details.form.label.productRank')}
            disabled={!watchProducts?.length}
            placeholder={t('page.collection.details.form.placeholder.productRank')}
          />

          <Button icon={MoreIcon} shape="circle" variant="white" onClick={addProduct} disabled={!watchProducts} />
        </RankWrapper>
      </SmallGapWrapper>
      {!!sortedSelectedProducts.length && (
        <ProductList>
          {sortedSelectedProducts?.map((entry) => (
            <ProductItem
              product={entry.product}
              rank={entry.rank}
              key={`item-${entry.product.id}`}
              onDelete={() => setSelectedProducts((prev) => _.differenceBy(prev, [entry], (e) => e.product['@id']))}
            />
          ))}
        </ProductList>
      )}
    </AddProductFormWrapper>
  )
}
