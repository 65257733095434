import { FetchCollaboratorsQueryParams, getCollaboratorsUri, usePaginatedCollaborators } from 'api/collaborators'
import BottomMessage from 'components/BottomMessage/BottomMesssage'
import { Section } from 'components/Section/Section.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import Listing from 'features/Listing/Listing'
import useAuth from 'hooks/useAuth'
import { RouteName } from 'permissions/permissions'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Collaborator } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { mergeFormQueryParams } from 'utils/queryParams'
import CollaboratorRegisterForm from './CollaboratorForms/CollaboratorRegisterForm'
import CollaboratorSearchForm, { CollaboratorForm } from './CollaboratorForms/CollaboratorSearchForm'
import { Grid, StyledCardLayout } from './CollaboratorManagement.styles'

type FormattedCollaborator = {
  id: number
  firstname?: string
  lastname?: string
  email?: string
  locations?: string
}

function parseCollaborators(rowCollaborator: Collaborator): FormattedCollaborator {
  return {
    id: rowCollaborator.id!,
    firstname: rowCollaborator.firstname,
    lastname: rowCollaborator.lastname,
    email: rowCollaborator.email,
    locations: (rowCollaborator.stores ?? []).map((store) => store.shortName).join(', ')
  }
}

const defaultSort: OrderParam<FetchCollaboratorsQueryParams> = {
  sortedBy: 'lastname',
  direction: SortingDirection.Asc,
  queryParams: { 'order[lastname]': SortingDirection.Asc, 'order[firstname]': SortingDirection.Asc }
}

function CollaboratorManagement() {
  const { t } = useTranslation()
  const { me } = useAuth()

  const navigate = useNavigate()
  const [searchedEmail, setSearchedEmail] = useState<string | undefined>(undefined)
  const [parsedCollaborators, setParsedCollaborators] = useState<FormattedCollaborator[]>([])
  const [orderParam, setOrderParam] = useState<OrderParam<FetchCollaboratorsQueryParams>>(defaultSort)
  const [searchParam, setSearchParam] = useState<CollaboratorForm>()

  const allStoresParams = {
    'stores[]': !searchParam?.stores ? me?.stores?.map((store) => store['@id']!) : undefined
  }

  const { totalItems, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage, hasBeenCalled } =
    usePaginatedCollaborators(
      ['collaborators', searchParam, orderParam.queryParams],
      getCollaboratorsUri(
        mergeFormQueryParams<FetchCollaboratorsQueryParams>({
          searchParams: searchParam,
          allStoresParams,
          orderParam: { ...orderParam.queryParams, 'order[firstname]': SortingDirection.Asc }
        })
      ),
      {
        enabled: !!searchParam,
        onSuccess: (data, total) => {
          if (total === 1) {
            return navigate(`/collaborator-details/${data[0].id}`)
          }

          if (total === 0) {
            setSearchedEmail(searchParam?.email)
          }

          setParsedCollaborators(data.map((e) => parseCollaborators(e)))
        }
      }
    )

  const handleSearch = async (formData: CollaboratorForm) => {
    setSearchedEmail(undefined)
    setSearchParam(formData)
  }

  const columns: Array<Column<FormattedCollaborator, FetchCollaboratorsQueryParams>> = useMemo(
    () => [
      {
        name: t('common.label.id'),
        key: 'id',
        decorator: (colaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={colaborator.id}>
            <Text color="secondary" fontWeight="light">
              {colaborator.id}
            </Text>
          </ClickableCell>
        )
      },
      {
        name: t('common.label.lastName'),
        key: 'lastname',
        sortable: true,
        orderQueryParam: 'order[lastname]',
        decorator: (colaborator: FormattedCollaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={colaborator.id}>
            <Text>{colaborator.lastname}</Text>
          </ClickableCell>
        )
      },
      {
        name: t('common.label.firstName'),
        key: 'firstname',
        decorator: (colaborator: FormattedCollaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={colaborator.id}>
            <Text>{colaborator.firstname}</Text>
          </ClickableCell>
        )
      },
      {
        name: t('common.label.email'),
        key: 'email',
        decorator: (colaborator: FormattedCollaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={colaborator.id}>
            <Text>{colaborator.email}</Text>
          </ClickableCell>
        )
      },
      {
        name: t('common.label.locations'),
        key: 'locations',
        decorator: (colaborator: FormattedCollaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={colaborator.id}>
            <Text>{colaborator.locations?.toString()}</Text>
          </ClickableCell>
        )
      }
    ],
    [t]
  )

  return (
    <Section>
      <MainTitle title={t('page.collaborator.management.title')} />
      <Grid>
        <CollaboratorSearchForm onSearch={handleSearch} isSubmitting={isFetching && !isFetchingNextPage} />

        <CollaboratorRegisterForm defaultEmail={searchedEmail} />

        {hasBeenCalled && (
          <StyledCardLayout>
            <Listing
              columns={columns}
              data={parsedCollaborators}
              title={t('page.collaborator.management.count', {
                count: totalItems
              })}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              emptyText={t('page.collaborator.management.empty')}
              hasNextPage={hasNextPage}
              totalCount={totalItems}
              defaultSort={defaultSort}
              onOrderChanged={setOrderParam}
            />
          </StyledCardLayout>
        )}
      </Grid>
      <BottomMessage text={t('common.requiredLabel')} />
    </Section>
  )
}

export default CollaboratorManagement
