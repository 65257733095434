import { Text } from 'components/Text/Text.styles'
import { FunctionComponent } from 'react'
import { TextColors } from 'theme/theme'
import { ChildrenContainer, Container } from './ProductInfo.styles'

type Props = {
  title: string
  info?: string
  infoColor?: keyof TextColors
}

const ProductInfo: FunctionComponent<Props> = ({ title, info, infoColor = 'black', children }) => {
  return (
    <Container>
      <Text fontWeight="bold" size="1.6rem">
        {title}
      </Text>
      <ChildrenContainer>{children}</ChildrenContainer>
      {info && (
        <Text size="1.6rem" color={infoColor}>
          {info}
        </Text>
      )}
    </Container>
  )
}

export default ProductInfo
