import styled from 'styled-components'

export const CountWithPerPage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  padding-top: 2rem;
`
export const PerPageSelect = styled.div`
  width: 7rem;
`
