import { FetchCustomersQueryParams, getCustomersUri, usePaginatedCustomers } from 'api/customers'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Text } from 'components/Text/Text.styles'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import Listing, { Columns } from 'features/Listing/Listing'
import { RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Customer, StoreDetails } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { mergeFormQueryParams } from 'utils/queryParams'

interface Props {
  store: StoreDetails
}

const defaultSort: OrderParam<FetchCustomersQueryParams> = {
  sortedBy: 'name',
  direction: SortingDirection.Asc,
  queryParams: { 'order[lastname]': SortingDirection.Asc }
}

export default function InternalCustomers({ store }: Props) {
  const [orderParam, setOrderParam] = useState<OrderParam<FetchCustomersQueryParams>>(defaultSort)

  const query = getCustomersUri(
    mergeFormQueryParams<FetchCustomersQueryParams>({
      params: { playinStore: `${store.id}` },
      orderParam: orderParam.queryParams
    })
  )
  const {
    data: customers,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    totalItems
  } = usePaginatedCustomers(query, query)
  const { t } = useTranslation()
  const columns: Columns<Customer, FetchCustomersQueryParams> = [
    {
      key: 'id',
      name: t('common.label.number'),
      decorator: (customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          <Text fontWeight="light">{customer.id}</Text>
        </ClickableCell>
      )
    },
    {
      key: 'name',
      name: t('common.label.name'),
      sortable: true,
      orderQueryParam: 'order[lastname]',
      decorator: (customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          {customer.fullName}
        </ClickableCell>
      )
    }
  ]
  return (
    <div>
      <Title2Tooltip
        title={t('page.stores.details.storeManage.internalTitle')}
        text={t('page.stores.details.storeManage.tooltips.internalTitle')}
        id="internalAccountTooltip"
      />
      <Listing
        data={customers}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        columns={columns}
        emptyText={t('page.stores.details.storeManage.listing.customers.empty')}
        totalCount={totalItems}
        defaultSort={defaultSort}
        onOrderChanged={setOrderParam}
      />
    </div>
  )
}
