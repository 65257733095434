import { Text } from '../Text/Text.styles'
import Tooltip from '../Tooltip/Tooltip'
import { TitleText } from './TootipContents.styles'

interface Props {
  id: string
  text: string
  title?: string
}

export const TextTooltip = ({ id, text, title }: Props) => {
  return (
    <Tooltip id={id}>
      {title && <TitleText fontWeight="medium">{title}</TitleText>}
      <Text fontWeight="light">{text}</Text>
    </Tooltip>
  )
}

export default TextTooltip
