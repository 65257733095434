import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { useQuery, UseQueryOptions } from 'react-query'
import { Litigation } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type FetchLitigationsResponse = Api.GetLitigations.ResponseBody
type FetchLitigationsQueryParams = Api.GetLitigations.RequestParams

const getLitigationsUri = (params: FetchLitigationsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.litigations.root, queryString].join('?')
}

const fetchLitigationsByCustomer = async (customerId: number): Promise<Litigation[]> => {
  const res = await playInApi.get<FetchLitigationsResponse>(getLitigationsUri({ customer: customerId }))
  return res.data['hydra:member']
}

export const getCustomerLitigationsQueryKey = (customerId: number) => ['litigations-by-customer', customerId]

export const useGetLitigationsByCustomer = (customerId: number, options?: UseQueryOptions<Litigation[]>) => {
  return useQuery<Litigation[]>(
    getCustomerLitigationsQueryKey(customerId),
    () => fetchLitigationsByCustomer(customerId),
    options ?? {}
  )
}

type FetchLitigationCategoriesResponse = Api.GetLitigationCategories.ResponseBody
type LitigationCategoryItem = FetchLitigationCategoriesResponse['hydra:member'][number]

const fetchLitigationCategories = async (): Promise<LitigationCategoryItem[]> => {
  const res = await playInApi.get<FetchLitigationCategoriesResponse>(API_ROUTES.litigationCategories.root)
  return res.data['hydra:member']
}

type useGetLitigationCategoriesOptions = { enabled?: boolean; onSuccess?: (data: LitigationCategoryItem[]) => void }
export const useGetLitigationCategories = (options?: useGetLitigationCategoriesOptions) => {
  return useQuery([`litigation-categories`], () => fetchLitigationCategories(), {
    enabled: options?.enabled ?? true,
    onSuccess: options?.onSuccess
  })
}

export type PostLitigationBody = Api.PostLitigations.RequestBody
type PostLitigationResponse = Api.PostLitigations.ResponseBody
export const addLitigation = async (body: PostLitigationBody): Promise<PostLitigationResponse> => {
  const res = await playInApi.post<PostLitigationBody, AxiosResponse<PostLitigationResponse>>(
    API_ROUTES.litigations.root,
    body
  )
  return res.data
}

export type DeleteLitigationParams = Api.DeleteLitigationsId.RequestParams
export const deleteLitigation = async ({ id }: DeleteLitigationParams) => {
  return await playInApi.delete(`${API_ROUTES.litigations.root}/${id}`)
}
