import caretSvg from 'assets/svg/icon/caret-down.svg'
import caretFillSvg from 'assets/svg/icon/caret-fill.svg'
import classnames from 'classnames'
import Loader from 'components/Loader/Loader'
import Tooltip from 'components/Tooltip/Tooltip'
import isEqual from 'lodash/isEqual'
import React, { ReactNode, useState } from 'react'
import { OrderParam, OrderQueryParams, SortingDirection } from 'types/sorting'
import {
  CaretIcon,
  Container,
  HeaderTitle,
  LoaderContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from './SortedTable.styles'

export interface Column<Response, Params = {}> {
  name?: string | JSX.Element
  key: string
  tooltip?: ReactNode
  sortable?: boolean
  orderQueryParam?: keyof OrderQueryParams<Params>
  decorator: (value: Response) => JSX.Element
}

export interface Props<Response, Params> {
  className?: string
  columns: Array<Column<Response, Params>>
  data: Array<Response>
  isLoading: boolean
  onOrderChanged?: (orderQueryParam: OrderParam<Params>) => void
  defaultSort?: OrderParam<Params>
}

function changeDirection(dir: SortingDirection): SortingDirection {
  if (dir === SortingDirection.Desc) return SortingDirection.Asc
  return SortingDirection.Desc
}

function SortedTable<T = {}, U = {}>({
  className,
  columns,
  data,
  isLoading = false,
  defaultSort,
  onOrderChanged
}: Props<T, U>) {
  const [sortedBy, setSortedBy] = useState<string | undefined>(defaultSort?.sortedBy)
  const [sortDirection, setSortDirection] = useState<SortingDirection>(defaultSort?.direction ?? SortingDirection.Desc)
  function handleSort(column: Column<T, U>) {
    let directionChanged

    if (sortedBy !== column.key) {
      setSortedBy(column.key)
      directionChanged = SortingDirection.Desc
    } else {
      directionChanged = changeDirection(sortDirection)
    }

    setSortDirection(directionChanged)
    if (onOrderChanged && column.orderQueryParam) {
      onOrderChanged({
        sortedBy: column.key,
        direction: directionChanged,
        queryParams: {
          [column.orderQueryParam]: directionChanged
        } as OrderParam<U>['queryParams']
      })
    }
  }

  return (
    <Container className={className}>
      <Table>
        <Thead>
          <Tr>
            {columns.map((col) => (
              <Th sortable={col.sortable} key={`${col.key}`}>
                <HeaderTitle sortable={col.sortable} onClick={() => handleSort(col)}>
                  {typeof col.name === 'string' ? <span>{col.name}</span> : col.name}
                  {col.tooltip ? <Tooltip id={col.key}>{col.tooltip}</Tooltip> : null}
                  {col.sortable && (
                    <CaretIcon
                      className={classnames(sortDirection, {
                        active: sortedBy === col.key
                      })}
                      src={sortedBy === col.key ? caretFillSvg : caretSvg}
                    />
                  )}
                </HeaderTitle>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading &&
            data.map((row, index) => (
              <Tr key={index}>
                {columns.map((col, i) => (
                  <Td key={`${row[col.key]}-${i}`}>{col.decorator(row)}</Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </Table>
      {isLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </Container>
  )
}

export default React.memo(SortedTable, isEqual) as typeof SortedTable
