import { yupResolver } from '@hookform/resolvers/yup'
import { sendEmail, SendEmailBody } from 'api/emails'
import { FetchEmailTypesItem } from 'api/emailTypes'
import { editOrder, EditOrderBody, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Select from 'components/Select/Select'
import Textarea from 'components/Textarea/Textarea'
import { SendIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEmailTypeFilters } from 'hooks/useSelectOptions'
import _ from 'lodash'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { formatCalendarDate } from 'utils/dates'
import * as yup from 'yup'
import { EmailSendFormRowContainer, ModalColumnBody } from './EmailSendModal.style'

const ORDER_PLACEHOLDER = '#num_comm'
const MISSING_PLACEHOLDER = '#missing'
const REPLACEMENT_PLACEHOLDER = '#replacement'

interface Props {
  order: GetOrderByIdResponse
  onClose: Function
  open: boolean
}

interface EmailFormData {
  type: string
  from: string
  subject: string
  message: string
  missingCardsAndProducts: string
  replacementCardsAndProducts: string
}

const schema = yup.object().shape({
  type: yup.string().trim().required(),
  from: yup.string().trim().required(),
  subject: yup.string().trim().required(),
  message: yup.string().trim().required(),
  missingCardsAndProducts: yup.string().trim(),
  replacementCardsAndProducts: yup.string().trim()
})

export default function EmailSendForm({ order, onClose, open }: Props) {
  const { t } = useTranslation()
  const { emailTypeOptions, emailTypes } = useEmailTypeFilters({}, open)

  const { register, handleSubmit, watch, setValue, control, reset } = useForm<EmailFormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      type: '',
      from: '',
      subject: '',
      message: '',
      missingCardsAndProducts: '',
      replacementCardsAndProducts: ''
    }
  })

  const [selectedEmailType, missingProduct, replacementProduct] = watch([
    'type',
    'missingCardsAndProducts',
    'replacementCardsAndProducts'
  ])

  const hydrateMailTemplate = useCallback(
    (template: string, missing?: string, replacement?: string) => {
      return _.chain(template)
        .replace(MISSING_PLACEHOLDER, missing || MISSING_PLACEHOLDER)
        .replace(REPLACEMENT_PLACEHOLDER, replacement || REPLACEMENT_PLACEHOLDER)
        .replace(ORDER_PLACEHOLDER, order.referenceNumber || ORDER_PLACEHOLDER)
        .value()
    },
    [order.referenceNumber]
  )

  useEffect(() => {
    const emailType = emailTypes.find((el) => el['@id'] === selectedEmailType)
    if (emailType) {
      setValue(
        'subject',
        order.referenceNumber && emailType.subject
          ? emailType.subject.replace(ORDER_PLACEHOLDER, order.referenceNumber)
          : emailType.subject || ''
      )
      setValue('from', emailType.sender!)
      setValue('message', hydrateMailTemplate(emailType.message!, missingProduct, replacementProduct))
    }
  }, [
    selectedEmailType,
    emailTypes,
    setValue,
    missingProduct,
    replacementProduct,
    hydrateMailTemplate,
    order.referenceNumber
  ])

  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order.id}`)

  const { mutate: updateOrderComment } = useMutation((body: EditOrderBody) => editOrder(body, order.id!))

  function generateCommentFrom(missingContent: string, replacementContent: string, emailType: FetchEmailTypesItem) {
    const missingContentMsg = missingContent
      ? t('page.order.detail.tab.misc.sendEmailModal.missingCardsAndProductsMessage', {
          content: missingContent
        })
      : ''
    const replacementContentMsg = replacementContent
      ? t('page.order.detail.tab.misc.sendEmailModal.replacementCardsAndProductsMessage', {
          content: replacementContent
        })
      : ''
    const extraInfo =
      missingContentMsg.length > 0 || replacementContentMsg.length > 0
        ? ` : ${[missingContentMsg, replacementContentMsg].filter(Boolean).join(' // ')}`
        : ''

    return t('page.order.detail.tab.misc.sendEmailModal.commentLog', {
      date: formatCalendarDate(new Date().toString()),
      title: emailType!.title,
      description: extraInfo
    })
  }

  const { mutateAsync: sendEmailMutation, isLoading: isSendingEmail } = useMutation((body: SendEmailBody) =>
    sendEmail(body, order.id!)
  )

  const onSubmit = async ({
    type,
    from,
    subject,
    message,
    missingCardsAndProducts,
    replacementCardsAndProducts
  }: EmailFormData) => {
    const emailType = emailTypes.find((el) => el['@id'] === type)
    await sendEmailMutation({ from, subject, message, to: order!.customer!.email })

    if (emailType) {
      const generatedComment = generateCommentFrom(missingCardsAndProducts, replacementCardsAndProducts, emailType)
      const comment = !!order.comment ? `${order.comment}\n${generatedComment}` : generatedComment
      updateOrderComment(
        { comment },
        {
          onSuccess: (data) => {
            const prev = queryClient.getQueryData<Order>(key)
            queryClient.setQueryData(key, { ...prev, ...data })
          }
        }
      )
    }
    reset()
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalColumnBody>
        <BlockLayout>
          <Select
            control={control}
            options={emailTypeOptions}
            label={t('page.order.detail.tab.misc.sendEmailModal.mailType')}
            placeholder={t('page.order.detail.tab.misc.sendEmailModal.placeholder')}
            id="type"
          />
        </BlockLayout>
        <EmailSendFormRowContainer>
          <Input
            disabled={!selectedEmailType}
            register={register}
            id={'subject'}
            label={t('page.order.detail.tab.misc.sendEmailModal.mailObject')}
          />
          <Input disabled={!selectedEmailType} register={register} id={'from'} label={t('common.label.shipper')} />
        </EmailSendFormRowContainer>
        <BlockLayout>
          <EmailSendFormRowContainer>
            <Textarea
              disabled={!selectedEmailType}
              register={register}
              id={'missingCardsAndProducts'}
              label={t('page.order.detail.tab.misc.sendEmailModal.missingCardsAndProducts', {
                placeholder: MISSING_PLACEHOLDER
              })}
              resize="vertical"
            />
            <Textarea
              disabled={!selectedEmailType}
              register={register}
              id={'replacementCardsAndProducts'}
              label={t('page.order.detail.tab.misc.sendEmailModal.replacementCardsAndProducts', {
                placeholder: REPLACEMENT_PLACEHOLDER
              })}
              resize="vertical"
            />
          </EmailSendFormRowContainer>
        </BlockLayout>
        <BlockLayout>
          <Textarea
            disabled={!selectedEmailType}
            register={register}
            id={'message'}
            label={t('page.order.detail.tab.misc.sendEmailModal.mailContent')}
            rows={19}
          />
        </BlockLayout>
        <Button
          buttonType="submit"
          disabled={!selectedEmailType}
          icon={SendIcon}
          variant={selectedEmailType ? 'default' : 'white'}
          isLoading={isSendingEmail}
        >
          {t('common.label.send')}
        </Button>
      </ModalColumnBody>
    </form>
  )
}
