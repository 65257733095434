import Button from 'components/Button/Button'
import styled from 'styled-components'

export const FormLayout = styled.form`
  display: grid;
  gap: 2rem;
`
export const FormGeneralSection = styled.div`
  --colGap: 10rem;
  display: grid;
  gap: 2rem var(--colGap);
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    --colGap: 6rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }

  @media screen and (${(props) => props.theme.screenWidth.minTabletScreen}) {
    & > div:first-of-type {
      position: relative;
      &::after {
        content: '';
        width: 0px;
        display: block;
        border: 2px solid ${(props) => props.theme.colors.secondary};
        height: 100%;
        position: absolute;
        top: 0;
        right: calc((var(--colGap) / 2) * -1);
        border-bottom: 0;
      }
    }
  }

  &.read {
    border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
  }
`

export const FieldsContainer = styled.div`
  display: grid;
  gap: 2rem;
`

export const TwoFieldsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const CityFieldsRow = styled.div`
  display: grid;
  grid-template-columns: 12.5rem 1fr;
  gap: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const FormDescriptionSection = styled.div`
  display: grid;
  gap: 2rem 5rem;
  grid-template-columns: 1fr 1fr;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }
`

export const FormScheduleSection = styled(FormDescriptionSection)`
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr;
  }
  &.read {
    border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
  }
`

export const FormEndSection = styled(FormDescriptionSection)`
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
export const ScheduleWrapper = styled.div`
  display: grid;
  gap: 2rem;
`

export const ScheduleRow = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 1fr 1fr;
  gap: 2rem 3.5rem;
  align-items: center;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 7.5rem 1fr;
  }
`
export const MorningSchedule = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`

export const AfternoonSchedule = styled(MorningSchedule)`
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-column-start: 2;
  }
`

export const DivStoreExceptionalHours = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`

export const OpeningClosing = styled.div`
  margin-top: 1rem;
`

export const StoreExceptionalHoursRow = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const StoreExceptionalHoursSetter = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 1fr;
  gap: 3.5rem;
`

export const StoreExceptionalHoursAction = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  order: 1;
  margin-top: 3rem;
`

export const AddButton = styled(Button)`
  margin-top: 2rem;
`