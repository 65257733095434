import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import styled from 'styled-components'
import theme from 'theme/theme'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  grid-column-gap: 5rem;
  @media screen and (${theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 100%;
  }
`

export const StyledCardLayout = styled(CardLayout)`
  grid-column-end: span 2;
  @media screen and (${theme.screenWidth.maxLaptopScreen}) {
    grid-column-end: span 1;
  }
`
