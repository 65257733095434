import {
  deleteProductCollection,
  patchProductCollection,
  PatchProductCollectionBody,
  postCollectionImage,
  productCollectionByIdQueryKey
} from 'api/collection'
import CollectionCreateForm, { CreateCollectionInputs } from 'app/CollectionCreate/form/CollectionCreateForm'
import Button from 'components/Button/Button'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { DeleteIcon } from 'constants/icons'
import ConfirmModal from 'features/Modals/ConfirmModal'
import { useApiNotifications } from 'hooks/useApiNotifications'
import { useMutation } from 'hooks/useAxiosMutation'
import { getPath, RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { ProductCollection } from 'types/entities'
import { DeleteCollectionWrapper } from './EditInformation.style'

interface Props {
  productCollection: ProductCollection
}
export default function EditInformations({ productCollection }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [deleteModalOpen, toggleDeleteModal] = useState(false)

  const [isEditLoading, setIsEditLoading] = useState(false)

  const queryClient = useQueryClient()
  const { emitSuccessNotification } = useApiNotifications()
  const { mutateAsync: updateProductCollection } = useMutation(
    ({ id, body }: { id: string | number; body: PatchProductCollectionBody }) => patchProductCollection(id, body),
    {
      emitDefaultSuccessNotification: false
    }
  )
  const { mutateAsync: updateCollectionImage } = useMutation(
    ({ body, id }: { body: FormData; id: string | number }) => postCollectionImage(id, body),
    {
      emitDefaultSuccessNotification: false
    }
  )

  const { mutate: deleteMutation, isLoading: isDeleteLoading } = useMutation(
    () => deleteProductCollection(productCollection.id!),
    {
      onSuccess: () => {
        navigate(getPath(RouteName.CollectionManagement))
      }
    }
  )

  const onSubmit = async (formValues: CreateCollectionInputs) => {
    const { image, ...restData } = formValues
    setIsEditLoading(true)
    try {
      //First, update product collection
      await updateProductCollection({
        id: productCollection.id!,
        body: restData
      })

      // If image is an url, it mean the file didn't get updated
      // Otherwise, we need to send the updated image to the api
      if (image && image instanceof File) {
        let formData = new FormData()
        formData.append('image', image)
        // Older image deletion is handled in server side
        await updateCollectionImage({ body: formData, id: productCollection.id! })
      }
      emitSuccessNotification()
    } finally {
      setIsEditLoading(false)
      queryClient.refetchQueries(productCollectionByIdQueryKey(`${productCollection.id}`))
    }
  }

  return (
    <>
      <CollectionCreateForm
        onSubmit={onSubmit}
        isEdit={true}
        isFormLoading={isEditLoading}
        currentCollection={productCollection}
      />
      <DeleteCollectionWrapper>
        <InputLayout
          className="no-border"
          label={t('page.collection.details.informations.delete.label')}
          tooltip={
            <Tooltip id="deleteCollectionTooltip">
              <Trans i18nKey="page.collection.details.informations.delete.tooltip" />
            </Tooltip>
          }
        >
          <Button
            variant="danger"
            icon={DeleteIcon}
            onClick={() => toggleDeleteModal(true)}
            isLoading={isDeleteLoading}
          >
            {t('common.button.delete')}
          </Button>
        </InputLayout>
      </DeleteCollectionWrapper>
      <ConfirmModal
        title={t('page.collection.details.informations.delete.modalTitle')}
        callback={() => deleteMutation({})}
        open={deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
      >
        <Text>{t('page.collection.details.informations.delete.modalContent')}</Text>
      </ConfirmModal>
    </>
  )
}
