import { useDownloadFile } from 'api/basePlayInApi'
import { eventByIdQueryKey, patchStoreEventCustomer, PatchStoreEventCustomerBody } from 'api/storeEvents'
import Button from 'components/Button/Button'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { API_ROUTES } from 'constants/configs'
import { CheckIcon, DeleteIcon, ExportIcon, RefundIcon, TransfertIcon } from 'constants/icons'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { StoreEvent } from 'types/entities'
import { UnmodifiableStoreEventStatus } from 'types/enums'
import { RoleAction, StoreEventCustomerJsonldEventReadEventReadItem, StoreEventStatus } from 'types/playInApiInterfaces'
import DeleteCustomerModal from '../modals/DeleteCustomerModal/DeleteCustomerModal'
import UpdateCustomerModal from '../modals/UpdateCustomerModal/UpdateCustomerModal'
import VouchersModal from '../modals/VouchersModal/VouchersModal'

type EventCustomerItem = StoreEventCustomerJsonldEventReadEventReadItem

interface Props {
  selectedCustomers: EventCustomerItem[]
  setSelectedCustomers: (customers: string[]) => void
  event: StoreEvent
}

export default function CustomerListActions({ selectedCustomers, event, setSelectedCustomers }: Props) {
  const { t } = useTranslation()

  const [updateCustomerModalOpen, toggleUpdateCustomerModal] = useState(false)
  const [vouchersModalOpen, toggleVouchersModal] = useState(false)
  const [deleteModalOpen, toggleDeleteModal] = useState(false)

  const { downloadFile, isLoading: isExportLoading } = useDownloadFile()

  const { mutateAsync: updateEventCustomer, isLoading } = useMutation(
    ({ body, id }: { body: PatchStoreEventCustomerBody; id: number }) => patchStoreEventCustomer(body, id)
  )

  const queryClient = useQueryClient()
  const notifyRegister = async () => {
    for (let customer of selectedCustomers) {
      await updateEventCustomer(
        { body: { software: !customer.software }, id: customer.id! },
        {
          onSuccess: (customer) => {
            const prevEvent = queryClient.getQueryData<StoreEvent>(eventByIdQueryKey(event.id!))
            const newCustomers = prevEvent?.customers?.map((prevCustomer) =>
              prevCustomer.id === customer.id ? { ...prevCustomer, ...customer } : prevCustomer
            )
            queryClient.setQueryData(eventByIdQueryKey(event.id!), {
              ...prevEvent,
              customers: newCustomers
            })
          }
        }
      )
    }
    setSelectedCustomers([])
  }

  const canExport = event.status !== StoreEventStatus.Canceled && !!event.customers?.length

  return (
    <>
      <TooltipHover id="softwareBtnTooltip" content={t('page.events.details.tooltips.buttons.software')}>
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER]}>
          <Button
            variant="white"
            shape="circle"
            icon={CheckIcon}
            disabled={UnmodifiableStoreEventStatus.includes(event.status!) || !selectedCustomers.length}
            onClick={() => notifyRegister()}
            isLoading={isLoading}
          />
        </ShouldDisable>
      </TooltipHover>
      <TooltipHover
        id="openRegistrationBtnTooltip"
        content={t('page.events.details.tooltips.buttons.openRegistration')}
      >
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER]}>
          <Button
            variant="white"
            shape="circle"
            icon={TransfertIcon}
            onClick={() => toggleUpdateCustomerModal(true)}
            disabled={!event.openRegistration || selectedCustomers.length !== 1}
          />
        </ShouldDisable>
      </TooltipHover>
      <TooltipHover id="voucherBtnTooltip" content={t('page.events.details.tooltips.buttons.voucher')}>
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_VOUCHER_CUSTOMER]}>
          <Button
            variant="white"
            shape="circle"
            icon={RefundIcon}
            disabled={UnmodifiableStoreEventStatus.includes(event.status!) || !selectedCustomers.length}
            onClick={() => toggleVouchersModal(true)}
          />
        </ShouldDisable>
      </TooltipHover>
      <TooltipHover id="exportBtnTooltip" content={t('page.events.details.exportCustomers')}>
        <ShouldDisable
          permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER]}
          deniedExtraCondition={!canExport || !selectedCustomers.length}
        >
          <Button
            variant="white"
            shape="circle"
            icon={ExportIcon}
            isLoading={isExportLoading}
            onClick={() =>
              downloadFile(API_ROUTES.exports.customerList, 'post', {
                customers: selectedCustomers.map((e) => e.linkedOrder?.customer?.['@id'])
              })
            }
          />
        </ShouldDisable>
      </TooltipHover>
      <TooltipHover id="deleteBtnTooltip" content={t('page.events.details.tooltips.buttons.delete')}>
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER]}>
          <Button
            variant="white"
            shape="circle"
            icon={DeleteIcon}
            disabled={event.closed || event.deleted || !selectedCustomers.length}
            onClick={() => toggleDeleteModal(true)}
          />
        </ShouldDisable>
      </TooltipHover>

      <VouchersModal
        event={event}
        selectedCustomers={selectedCustomers}
        open={vouchersModalOpen}
        onClose={() => {
          setSelectedCustomers([])
          toggleVouchersModal(false)
        }}
      />
      <UpdateCustomerModal
        open={updateCustomerModalOpen}
        onClose={() => {
          setSelectedCustomers([])
          toggleUpdateCustomerModal(false)
        }}
        event={event}
        selectedCustomer={selectedCustomers[0]}
      />

      <DeleteCustomerModal
        open={deleteModalOpen}
        onClose={() => {
          setSelectedCustomers([])
          toggleDeleteModal(false)
        }}
        selectedCustomers={selectedCustomers}
        event={event}
      />
    </>
  )
}
