import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'

type CardProductsQueryParams = Api.GetCardProducts.RequestQuery
const getCardProductsUri = (params: CardProductsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.cardProducts.root, queryString].join('?')
}

export type FetchCardProducts = Api.GetCardProducts.ResponseBody['hydra:member'][0]
export const useGetCardProducts = (
  params: CardProductsQueryParams,
  options?: PaginatedHookOptions<FetchCardProducts>
) => usePaginatedQuery<FetchCardProducts>(['cards', params], getCardProductsUri(params), options)
