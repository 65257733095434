import { fetchStores, FetchStoresQueryParams, PaginatedStoreItem } from 'api/stores'
import { CreateCardLayout } from 'app/StoreEventManage/StoreEventManage.styles'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { StoreIcon, WarehouseIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import useAuth from 'hooks/useAuth'
import { useModals } from 'hooks/useModals'
import { RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Modals } from 'types/modals'
import { RoleAction } from 'types/playInApiInterfaces'
import { OrderParam, SortingDirection } from 'types/sorting'

const defaultSort: OrderParam<FetchStoresQueryParams> = {
  sortedBy: 'name',
  direction: SortingDirection.Asc,
  queryParams: { 'order[shortName]': SortingDirection.Asc }
}

export default function StoreManagement() {
  const { t } = useTranslation()

  const [orderQueryParam, setOrderQueryParam] = useState<OrderParam<FetchStoresQueryParams>>(defaultSort)
  const { me } = useAuth()

  const myStoresIdList = me?.stores?.map((e) => e.id)
  const { data: stores, isFetching } = useQuery(['myStores', myStoresIdList, orderQueryParam.queryParams], () =>
    fetchStores({ 'id[]': myStoresIdList, ...orderQueryParam.queryParams })
  )

  const columns: Columns<PaginatedStoreItem, FetchStoresQueryParams> = [
    {
      key: 'name',
      name: t('common.label.name'),
      sortable: true,
      orderQueryParam: 'order[shortName]',
      decorator: (store) => (
        <ClickableCell route={RouteName.StoreDetails} value={store.id}>
          {store.shortName}
        </ClickableCell>
      )
    },
    {
      key: 'address',
      name: t('common.label.address'),
      decorator: (store) => (
        <ClickableCell route={RouteName.StoreDetails} value={store.id}>
          <Text fontWeight="light">{store.address}</Text>
        </ClickableCell>
      )
    },
    {
      key: 'zipcode',
      name: t('common.label.zipCode'),
      decorator: (store) => (
        <ClickableCell route={RouteName.StoreDetails} value={store.id}>
          {store.zipCode}
        </ClickableCell>
      )
    },
    {
      key: 'city',
      name: t('common.label.city'),
      sortable: true,
      orderQueryParam: 'order[city]',
      decorator: (store) => (
        <ClickableCell route={RouteName.StoreDetails} value={store.id}>
          {store.city}
        </ClickableCell>
      )
    },
    {
      key: 'franchised',
      name: t('page.stores.manage.labels.firmName'),
      sortable: true,
      orderQueryParam: 'order[franchised]',
      decorator: (store) => (
        <ClickableCell route={RouteName.StoreDetails} value={store.id}>
          {store.franchised ? t('page.stores.details.labels.franchise') : t('page.stores.details.labels.parentCompany')}
        </ClickableCell>
      )
    },
    {
      key: 'type',
      name: t('common.label.type'),

      decorator: (store) => {
        const tooltipText = store.warehouse
          ? t('page.stores.details.labels.warehouse')
          : t('page.stores.details.labels.shop')

        const icon = store.warehouse ? WarehouseIcon : StoreIcon
        return (
          <ClickableCell route={RouteName.StoreDetails} value={store.id}>
            <TooltipHover id={`storeType-${store.id}`} content={tooltipText}>
              <Svg svg={icon} />
            </TooltipHover>
          </ClickableCell>
        )
      }
    },
    {
      key: 'active',
      name: t('common.label.status'),
      sortable: true,
      orderQueryParam: 'order[active]',
      decorator: (store) => {
        const textContent = store.active ? t('common.label.active') : t('common.label.inactive')
        const textColor = store.active ? 'success' : 'danger'
        const fontWeight = store.active ? 'regular' : 'medium'
        return (
          <ClickableCell route={RouteName.StoreDetails} value={store.id}>
            <Text color={textColor} fontWeight={fontWeight} fontStyle="italic">
              {textContent}
            </Text>
          </ClickableCell>
        )
      }
    }
  ]
  const { openModal } = useModals()

  return (
    <Section>
      <MainTitle title={t('page.stores.manage.title')} />
      <CreateCardLayout>
        <Title2>{t('page.stores.manage.createTitle')}</Title2>
        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STORE_CREATE]}>
          <Button onClick={() => openModal(Modals.StoreCreate)} size="app">
            {t('page.stores.manage.createBtn')}
          </Button>
        </ShouldDisable>
      </CreateCardLayout>
      <CardLayout>
        <Listing
          data={stores ?? []}
          columns={columns}
          totalCount={stores?.length}
          emptyText={t('page.stores.manage.listing.empty')}
          title={t('page.stores.manage.listing.title', { count: stores?.length })}
          defaultSort={defaultSort}
          onOrderChanged={setOrderQueryParam}
          isFetching={isFetching}
        />
      </CardLayout>
    </Section>
  )
}
