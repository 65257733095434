import { yupResolver } from '@hookform/resolvers/yup'
import { FamilyStatus, usePaginatedRanges } from 'api/ranges'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import { Option } from 'components/Select/MultipleSelect'
import Select from 'components/Select/Select'
import { MoreIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Ranges, StoreDetails } from 'types/entities'
import { SortingDirection } from 'types/sorting'
import * as yup from 'yup'
import { AddStoreRangeFormWrapper } from '../StoreManageTab.style'

const schema = yup.object({
  range: yup.string().trim().required()
})

export interface RangeInputs extends yup.InferType<typeof schema> {}

interface Props {
  store: StoreDetails
  currentRanges?: Ranges[]
  onSubmit: (data: RangeInputs) => void
  isLoading?: boolean
}

export default function AddStoreRangeForm({ store, currentRanges, onSubmit, isLoading }: Props) {
  const { t } = useTranslation()
  const rangesQueryParams = { 'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage], 'order[name]': SortingDirection.Asc }
  const {
    data: ranges,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = usePaginatedRanges(['family', rangesQueryParams], rangesQueryParams)

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<RangeInputs>({
    resolver: yupResolver(schema)
  })

  const options: Option[] =
    ranges?.map((range) => ({
      label: range.name ?? range.codeName,
      value: range['@id']!,
      disabled: !!currentRanges?.find((e) => e['@id'] === range['@id']) ?? false
    })) ?? []

  //TODO Wait API

  return (
    <AddStoreRangeFormWrapper onSubmit={handleSubmit(onSubmit)}>
      <Select
        control={control}
        id="range"
        options={options}
        placeholder={t('common.select.defaultOptions.familly')}
        className={classNames({ 'is-invalid': errors.range })}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />

      <Button icon={MoreIcon} shape="circle" iconColor="white" buttonType="submit" isLoading={isLoading} />
      <BasicFieldValidationError error={errors.range} message={t('form.validation.required.range')} />
    </AddStoreRangeFormWrapper>
  )
}
