import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type ActivateGiftBody = Api.PostGiftCardsActivateEan128.RequestBody
type ActivateGiftResponse = Api.PostGiftCardsActivateEan128.ResponseBody
export const activateGift = async (id: string, body: ActivateGiftBody) => {
  const res = await playInApi.post<ActivateGiftBody, AxiosResponse<ActivateGiftResponse>>(
    `${API_ROUTES.giftCard.activate}/${id}`,
    body
  )
  return res.data
}

export const consumeGiftCard = async (id: number, ean: string) => {
  const res = await playInApi.post(`${API_ROUTES.giftCard.consume}/${ean}/${id}`, {})
  return res.data
}
