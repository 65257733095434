import FiltersList from 'components/FiltersList/FiltersList'
import styled from 'styled-components'

export const CouponCardFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem 5rem;
  align-items: end;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const RentCouponOption = styled.div`
  display: flex;
  gap: 0.3rem;
`

export const CouponsList = styled(FiltersList)`
  flex-wrap: wrap;
` as typeof FiltersList

export const CouponFilter = styled.div`
  display: flex;

  & > :not(:first-child):before {
    content: '- ';
    margin-left: 0.2rem;
  }
`
