import playInSvg from 'assets/img/playin.png'
import { MenuClosedIcon, MenuOpenIcon } from 'constants/icons'
import AccoutNav from 'features/TopBar/AccountNav'
import { PermissionActivationToggle } from 'permissions/permissions'
import { Link } from 'react-router-dom'
import { Header, LeftDiv, RightDiv, StyledMenuIcon } from './TopBar.styles'

export default function TopBar({ openSidebar, isSidebarOpen }) {
  return (
    <Header>
      <LeftDiv>
        <StyledMenuIcon onClick={() => openSidebar()} svg={isSidebarOpen ? MenuOpenIcon : MenuClosedIcon} />

        <Link to="/">
          <img src={playInSvg} alt="playin" />
        </Link>
      </LeftDiv>

      <RightDiv>
        {process.env.REACT_APP_BYPASS_PERRMISSIONS === 'true' && <PermissionActivationToggle />}
        <AccoutNav />
      </RightDiv>
    </Header>
  )
}
