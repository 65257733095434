import { FamilyStatus, FetchRangesQueryParams, usePaginatedRanges } from 'api/ranges'
import { PatchStoreBody, patchStore } from 'api/stores'
import { useStoreContext } from 'app/StoreDetails/helper'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { DeleteIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Ranges, StoreDetails } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { OrderParam, SortingDirection } from 'types/sorting'
import { mergeFormQueryParams } from 'utils/queryParams'
import { ListActions } from '../StoreManageTab.style'
import AddStoreRangeForm, { RangeInputs } from '../form/AddStoreRangeForm'

interface Props {
  store: StoreDetails
}

const defaultSort: OrderParam<FetchRangesQueryParams> = {
  sortedBy: 'name',
  direction: SortingDirection.Asc,
  queryParams: { 'order[name]': SortingDirection.Asc }
}
export default function StoreRanges({ store }: Props) {
  const { t } = useTranslation()
  const { checkMatch } = useCheckMatch()

  const [selectedRanges, setSelectedRanges] = useState<string[]>([])
  const [orderParam, setOrderParam] = useState<OrderParam<FetchRangesQueryParams>>(defaultSort)
  const [deleteModalOpen, toggleDeleteModal] = useState(false)

  const queryParams = mergeFormQueryParams<FetchRangesQueryParams>({
    params: { stores: `${store.id}`, 'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage] },
    orderParam: orderParam.queryParams
  })

  const {
    data: ranges,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage,
    totalItems,
    refetch
  } = usePaginatedRanges(['family', queryParams], queryParams)

  const handleSelectAll = () =>
    setSelectedRanges((prev) => (prev.length === ranges?.length ? [] : ranges.map((range) => range['@id']!)))

  const { mutate: patchStoreMutation, isLoading } = useMutation((body: PatchStoreBody) => patchStore(store.id!, body), {
    onSuccess: () => refetch()
  })

  const { isMyStore } = useStoreContext()

  const columns = _.filter<Column<Ranges, FetchRangesQueryParams>>(
    [
      {
        key: 'checkbox',
        decorator: (range) => (
          <Cell>
            <Checkbox
              onClick={() => setSelectedRanges((prev) => _.xor(prev, [range['@id']!]))}
              className={classNames({
                checked: selectedRanges.includes(range['@id']!)
              })}
            />
          </Cell>
        )
      },
      {
        key: 'name',
        name: t('common.label.name'),
        sortable: true,
        orderQueryParam: 'order[name]',
        decorator: (range) => <Cell>{range.name}</Cell>
      }
    ],
    (column) => {
      if (column.key === 'checkbox') {
        return checkMatch([RoleAction.ROLE_ACTION_STORE_EDIT]) && isMyStore
      }

      return true
    }
  )

  const onSubmit = (formData: RangeInputs) => {
    patchStoreMutation({
      families: [...ranges.map((e) => e['@id']!), formData.range]
    })
  }

  const handleDelete = () => {
    patchStoreMutation(
      {
        families: _.xor(
          ranges.map((e) => e['@id']!),
          selectedRanges
        )
      },
      {
        onSuccess: () => {
          setSelectedRanges([])
          toggleDeleteModal(false)
        }
      }
    )
  }

  return (
    <>
      <Title2Tooltip
        title={t('page.stores.details.storeManage.rangesTitle')}
        text={t('page.stores.details.storeManage.tooltips.ranges')}
        id="internalAccountTooltip"
      />
      <ShouldHide permissions={[RoleAction.ROLE_ACTION_STORE_EDIT]} deniedExtraCondition={!isMyStore}>
        <AddStoreRangeForm store={store} currentRanges={ranges} onSubmit={onSubmit} isLoading={isLoading} />
      </ShouldHide>
      <ShouldHide permissions={[RoleAction.ROLE_ACTION_STORE_EDIT]} deniedExtraCondition={!totalItems || !isMyStore}>
        <ListActions>
          <Checkbox
            className={classNames({ checked: !!selectedRanges.length && selectedRanges.length === ranges?.length })}
            onClick={() => handleSelectAll()}
          />
          <TooltipHover id="deleteRangeTooltip" content={t('page.stores.details.storeManage.tooltips.rangesDelete')}>
            <Button
              icon={DeleteIcon}
              shape="circle"
              variant="white"
              disabled={!selectedRanges.length}
              onClick={() => toggleDeleteModal(true)}
            />
          </TooltipHover>
        </ListActions>
      </ShouldHide>
      <Listing
        data={ranges}
        isFetchingNextPage={isFetchingNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        emptyText={t('page.stores.details.storeManage.listing.ranges.empty')}
        columns={columns}
        totalCount={totalItems}
        defaultSort={defaultSort}
        onOrderChanged={setOrderParam}
      />

      <ConfirmModal
        title={t('page.stores.details.storeManage.listing.ranges.delete.title', { storeName: store.name })}
        callback={handleDelete}
        open={deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        confirmBtnProps={{
          isLoading,
          icon: DeleteIcon
        }}
        closeOnCallback={false}
        confirmLabel={t('common.button.remove')}
      >
        <Listing
          data={ranges.filter((range) => selectedRanges.includes(range['@id']!))}
          columns={columns.filter((e) => e.key !== 'checkbox')}
        />
      </ConfirmModal>
    </>
  )
}
