import classNames from 'classnames'
import { Text } from 'components/Text/Text.styles'
import usePrevious from 'hooks/usePrevious'
import _ from 'lodash'
import { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Tab from './Tab'
import { PanelContainer, StyledTabs } from './Tabs.styles'

type PanelContainerProps = {
  isActive: boolean
  noStyle: boolean
}

const NotStyledContainer = styled.div``

export const TabPanel: FunctionComponent<PanelContainerProps> = ({ children, isActive, noStyle }) => {
  const Container = noStyle ? NotStyledContainer : PanelContainer
  return <Container hidden={isActive === false}>{children}</Container>
}

export type TabProps = {
  tabLabel: string | ReactNode
  panelComponent: ReactNode
  noStyle?: boolean
  disabled?: boolean
  hidden?: boolean
}

type Props = {
  tabs: TabProps[]
  idle?: boolean
  onTabChange?: (index?: number | null) => void
  defaultIndex?: number
}

const Tabs = ({ tabs, idle = false, onTabChange, defaultIndex }: Props) => {
  const { t } = useTranslation()
  const fistActiveTab = _.find(tabs, (tab) => {
    return !tab.disabled && !tab.hidden
  })

  const getFirstIndex = useCallback(() => {
    //If default tab is not disabled
    if (defaultIndex && !tabs[defaultIndex]?.disabled) return defaultIndex
    if (fistActiveTab) return tabs.indexOf(fistActiveTab)
    return null
  }, [fistActiveTab, defaultIndex, tabs])

  const [selectedTabIndex, setSelectedTabIndex] = useState<number | null>(getFirstIndex())
  const tabsContainerRef = useRef(null)

  const onChange = useCallback(
    (tabIndex: number | null) => {
      setSelectedTabIndex(tabIndex)

      if (onTabChange) {
        onTabChange(tabIndex)
      }
    },
    [onTabChange]
  )

  const previousTabs = usePrevious(tabs)
  useEffect(() => {
    // if some tabs have changed, and have been set disabled or hidden
    // find the first active tab
    let needUpdate = false
    _.forEach(tabs, (tab, index) => {
      const previousTab = previousTabs?.[index]
      if (tab.disabled && tab.disabled !== previousTab?.disabled) {
        needUpdate = true
      }
      if (tab.hidden && tab.hidden !== previousTab?.hidden) {
        needUpdate = true
      }
    })

    if (needUpdate) {
      const tabIndex = fistActiveTab ? tabs.indexOf(fistActiveTab) : null
      onChange(idle ? null : tabIndex)
    }
  }, [tabs, idle, previousTabs, onChange, fistActiveTab])

  return (
    <>
      <StyledTabs ref={tabsContainerRef}>
        {tabs
          .filter((tab) => !tab.hidden)
          .map((tab, index) => (
            <Tab
              onTabChange={onChange}
              tabsContainerRef={tabsContainerRef}
              key={index}
              index={index}
              label={tab.tabLabel}
              className={classNames({
                solo: tabs.length === 1
              })}
              disabled={tab.disabled}
              isActive={selectedTabIndex === index}
            />
          ))}
      </StyledTabs>
      <TabPanel key="no-active" isActive={selectedTabIndex === null} noStyle={false}>
        <Text color="secondary" fontStyle="italic">
          {t('common.tabs.noDefault')}
        </Text>
      </TabPanel>
      {tabs.map((tab, index) => {
        return (
          <TabPanel key={index} isActive={selectedTabIndex === index} noStyle={!!tab.noStyle}>
            {tab.panelComponent}
          </TabPanel>
        )
      })}
    </>
  )
}

export default Tabs
