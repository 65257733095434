import styled from 'styled-components'

export const RegisterFormActions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`
export const FormSearch = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 3.5rem;
  grid-row-gap: 2rem;
  button {
    align-self: flex-end;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const FormRegister = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-column-gap: 3.5rem;
  grid-row-gap: 2rem;
  ${RegisterFormActions} {
    align-self: flex-end;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const Line = styled.div`
  @media screen and (${(props) => props.theme.screenWidth.minTabletScreen}) {
    grid-column-end: span 2;
  }
`
