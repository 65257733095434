import classnames from 'classnames'
import TextNumber from 'components/TextNumber/TextNumber'
import { LabelTitle, TitleContainer } from '../StoreLabels.styles'
import { ArrowLabelEnum, getTitleFontSize } from '../storeLabelUtils'
import { HalfMoonSquare, SquareContainer, StyledPriceText, StyledTextContainer, Subtitle } from './SquareLabel.styles'

type Props = {
  title: string
  subtitle?: string
  price: number
  arrow?: ArrowLabelEnum
  onClick: () => void
}

const SquareLabel = ({ title, subtitle, price, onClick }: Props) => {
  return (
    <SquareContainer onClick={onClick}>
      <HalfMoonSquare/>
      <TextNumber
        value={price}
        color="white"
        fontWeight="bold"
        size="3.6rem"
        decimalScale={2}
        suffix={`\u00a0€`}
        ContainerComponent={StyledPriceText}
      />
      <StyledTextContainer>
        <TitleContainer>
          <LabelTitle
            className={classnames(subtitle ? 'clamp-2' : 'clamp-3', 'blue')}
            fontWeight="medium"
            size={getTitleFontSize(title.trim(), subtitle)}
          >
            {title.trim()}
          </LabelTitle>
        </TitleContainer>
        {subtitle && (
          <Subtitle className="clamp-2" size="1.6rem">
            {subtitle.trim()}
          </Subtitle>
        )}
      </StyledTextContainer>
    </SquareContainer>
  )
}

export default SquareLabel
