import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Stock } from 'types/entities'
import {
  Api,
  CollaboratorJsonldStockReadItemStockRead,
  StockJsonldStockReadItemStockReadStockStats
} from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export const stockByIdQueryKey = (id: string | number) => ['stockById', `${id}`]

export type StockCollaborator = Partial<CollaboratorJsonldStockReadItemStockRead>
export type StockEntryFromStock = GetStockByIdResponse['entries'][0]

type GetStockByIdResponse = Api.GetStocksId.ResponseBody
const getStockById = async (id: string): Promise<Stock> => {
  const response = await playInApi.get<GetStockByIdResponse>(`${API_ROUTES.stocks.root}/${id}`)

  return response.data
}

export type PatchStockBody = Api.PatchStocksId.RequestBody
export type PatchStockResponse = Api.PatchStocksId.ResponseBody
export const patchStock = async (id: number | string, body: PatchStockBody) => {
  const response = await playInApi.patch<PatchStockBody, AxiosResponse<PatchStockResponse>>(
    `${API_ROUTES.stocks.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

  return response.data
}

type CreateStocksBody = Api.PostStocks.RequestQuery
type CreateStockReponse = Api.PostStocks.ResponseBody

export const createStock = async (body: CreateStocksBody): Promise<CreateStockReponse> => {
  const res = await playInApi.post<CreateStocksBody, AxiosResponse<CreateStockReponse>>(API_ROUTES.stocks.root, body)
  return res.data
}

export const useGetStockById = (id: string) => {
  const navigate = useNavigate()
  return useQuery<Stock, AxiosResponse>(stockByIdQueryKey(id), () => getStockById(id), {
    onError: (error) => {
      // replace: true, we prevent going back to the page that respond 40X
      navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
    }
  })
}

//GET STOCKS
export type getStocksQueryParams = Api.GetStocks.RequestQuery
export const getStocksUri = (params: getStocksQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.stocks.root, queryString].join('?')
}
//Todo typer options
type GetStocksQueryResponse = Api.GetStocks.ResponseBody['hydra:member'][0]
export const useStocksPaginated = (
  queryKey: QueryKey,
  query: string,
  options?: PaginatedHookOptions<GetStocksQueryResponse>
) => usePaginatedQuery<GetStocksQueryResponse>(queryKey, query, options)

export const getStockStats = async (stockId: string | number) => {
  const res = await playInApi.get<StockJsonldStockReadItemStockReadStockStats>(`${API_ROUTES.stocks.stats}/${stockId}`)
  return res.data
}
