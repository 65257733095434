import angleUpSvg from 'assets/svg/icon/angle-up.svg'
import { InputTextStyle } from 'components/Input/Input.styles'
import styled from 'styled-components'
import InputLayout from '../Layouts/InputLayout/InputLayout'

export const StyledInputNumber = styled(InputTextStyle)`
  width: 100%;
  min-width: 6.5rem;

  padding-left: 1rem;

  outline: none;
  border: none;

  //Fix apparence for Chrome
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  //Fix apparence for Firefox
  -moz-appearance: textfield;
  appearance: textfield;
  background: none; //use layout background
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const FitInputLayout = styled(InputLayout)`
  width: fit-content;
`

export const IncrementIcon = styled.span`
  width: 1rem;
  height: 0.6rem;
  background-image: url(${angleUpSvg});
  background-size: 100%;
  cursor: pointer;

  &.invert {
    transform: rotate(180deg);
  }

  &.disabled {
    cursor: default;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-right: 1rem;
  gap: 0.5rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`
