import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentModes } from 'types/enums'

export const useGetPaymentModeLabel = () => {
  const { t } = useTranslation()

  return {
    getPaymentModeLabel: useCallback(
      (mode): string =>
        ({
          [PaymentModes.Cash]: t('common.select.paymentMethod.cash'),
          [PaymentModes.Check]: t('common.select.paymentMethod.check'),
          [PaymentModes.Paypal]: t('common.select.paymentMethod.paypal'),
          [PaymentModes.Transfer]: t('common.select.paymentMethod.transfer'),
          [PaymentModes.BankCard]: t('common.select.paymentMethod.bankCard'),
          [PaymentModes.MandatCash]: t('common.select.paymentMethod.mandatCash'),
          [PaymentModes.Voucher]: t('common.select.paymentMethod.voucher'),
          [PaymentModes.Store]: t('common.select.paymentMethod.store'),
          [PaymentModes.Multiple]: t('common.select.paymentMethod.multiple'),
          [PaymentModes.Lcr]: t('common.select.paymentMethod.lcr'),
          [PaymentModes.GiftCard]: t('common.select.paymentMethod.giftCard'),
          [PaymentModes.Payment3]: t('common.select.paymentMethod.payment3'),
          [PaymentModes.Payment4]: t('common.select.paymentMethod.payment4'),
          [PaymentModes.TicketRestaurant]: t('common.select.paymentMethod.ticketRestaurant'),
          [PaymentModes.BankCardStore]: t('common.select.paymentMethod.bankCardStore'),
          [PaymentModes.Levy]: t('common.select.paymentMethod.levy'),
          [PaymentModes.Credit]: t('common.select.paymentMethod.credit')
        }[mode] ||
        mode ||
        ''),
      [t]
    )
  }
}
