import { StatusTypes } from 'components/StatusBlock/StatusBlock'
import { useEventStatusOptions } from 'hooks/useSelectOptions'
import { StoreEvent } from 'types/entities'
import { CollaboratorEventReadEventReadItem, StoreEventStatus } from 'types/playInApiInterfaces'
import { formatCalendarDate } from 'utils/dates'
import { EventStep } from '../components/EventStatusHeader'

const getFullName = (author?: CollaboratorEventReadEventReadItem | null) => {
  if (!author) return ''
  const { firstname, lastname } = author
  if (!lastname) return firstname
  if (!firstname) return lastname
  return firstname.concat(' ', lastname)
}

export default function useEventStatus(event?: StoreEvent) {
  const { getEventStatusLabel } = useEventStatusOptions()
  const getStepStatus = (eventStatus: StoreEventStatus, nextStep: StoreEventStatus[] = []): StatusTypes => {
    if (event?.status === eventStatus && event?.status === StoreEventStatus.Canceled) return StatusTypes.canceled
    if (event?.status === eventStatus) return StatusTypes.active
    if (event?.status && nextStep.includes(event?.status)) return StatusTypes.history
    return StatusTypes.pending
  }

  const { Created, WaitingVoucher, Canceled, Treated } = StoreEventStatus

  const openStep: EventStep = {
    name: getEventStatusLabel(Created),
    status: getStepStatus(Created, [WaitingVoucher, Canceled, Treated]),
    date: formatCalendarDate(event?.createdAt),
    author: getFullName(event?.createdBy)
  }
  const voucherStep: EventStep = {
    name: getEventStatusLabel(WaitingVoucher),
    status: getStepStatus(WaitingVoucher, [Canceled, Treated]),
    date: formatCalendarDate(event?.voucherAddedAt),
    author: getFullName(event?.voucherAddedBy)
  }
  const closedStep: EventStep = {
    name: getEventStatusLabel(Treated),
    status: getStepStatus(Treated, []),
    date: formatCalendarDate(event?.treatedAt),
    author: getFullName(event?.treatedBy)
  }

  const canceledStep: EventStep = {
    name: getEventStatusLabel(Canceled),
    status: getStepStatus(Canceled, []),
    date: formatCalendarDate(event?.canceledAt),
    author: getFullName(event?.canceledBy)
  }
  return {
    openStep,
    voucherStep,
    closedStep: event?.status === StoreEventStatus.Canceled ? canceledStep : closedStep
  }
}
