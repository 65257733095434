import Input from 'components/Input/Input'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const ModalBody = styled.div`
  width: 85rem;
  max-width: 100%;
`

export const Separator = styled.hr`
  margin: 5rem 0;
  border: 1px solid ${(props) => props.theme.borderColors.secondary};
`

export const SearchCustomerFormContainer = styled.div`
  display: flex;
  gap: 2rem 3.5rem;

  & > :last-child {
    margin-top: auto;
  }

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`

export const RegularInputContainer = styled.div`
  @media screen and (${(props) => props.theme.screenWidth.minLaptopScreen}) {
    min-width: 18.5rem;
  }
`

export const EmailInputContainer = styled.div`
  @media screen and (${(props) => props.theme.screenWidth.minLaptopScreen}) {
    min-width: 23.5rem;
  }
`

export const CustomerBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
    gap: 0.25rem;
  }
`

export const NameBlock = styled(Text)`
  @media screen and (${(props) => props.theme.screenWidth.minTabletScreen}) {
    width: 22.5rem;
  }
  white-space: initial;
`

export const CustomerResultsList = styled.div`
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: auto 1fr;
  row-gap: 2rem;

  & > :first-child {
    margin-right: 1rem;
  }

  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
`

export const ShippingAddressFormContainer = styled.div`
  margin-bottom: 2rem;
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`

export const TwoColGridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 3.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const ZipCodeCityRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem 3.5rem;
  }
`

export const ZipCodeInput = styled(Input)`
  @media screen and (${(props) => props.theme.screenWidth.minTabletScreen}) {
    max-width: 8.5rem;
  }
` as typeof Input

export const CountryPhoneRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem 3.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const ValidationErrorContainer = styled.div`
  margin-bottom: 1rem;
`
