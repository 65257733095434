import styled from 'styled-components'

export const FormSearch = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 3.5rem;
  align-items: end;

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    gap: 2rem;
    grid-template-columns: 1fr;
  }
`

export const InputsContainer = styled.div`
  gap: 2rem;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (${(props) => props.theme.screenWidth.minTabletScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (${(props) => props.theme.screenWidth.minDesktopScreen}) {
    grid-template-columns: repeat(4, 1fr);
    gap: 3.5rem;
  }
`
