import { Colors } from 'theme/theme'
import { Text } from '../Text/Text.styles'
import Tooltip from '../Tooltip/Tooltip'
import { TooltipColumnContainer } from './TootipContents.styles'

interface Props {
  className?: string
  id: string
  title?: string
  lines: Array<string | JSX.Element>
  color?: keyof Colors
}

export const MultiLineTooltip = ({ className, id, lines, title, color }: Props) => {
  return (
    <Tooltip className={className} id={id} color={color}>
      <TooltipColumnContainer>
        {title && <Text fontWeight="medium">{title}</Text>}
        {lines.map((line, index) => (
          <Text key={index} fontWeight="light">
            {line}
          </Text>
        ))}
      </TooltipColumnContainer>
    </Tooltip>
  )
}

export default MultiLineTooltip
