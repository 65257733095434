import { removeOrderEntries } from 'api/orders'
import Button from 'components/Button/Button'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { ModalButtonWrapper } from 'components/Modal/Modal.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { DeleteIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import { useMutation } from 'hooks/useAxiosMutation'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { OrderEntry } from 'types/entities'
import { StyledCell } from '../forms/orderEntriesTable/OrderEntriesTable.styles'
import { DescriptionText } from './BaseModal.style'

interface Props extends ModalProps {
  orderId: number
  orderEntries: OrderEntry[]
  columns: Columns<OrderEntry>
  getCellProperties: (entry: OrderEntry) => any
  callback?: () => any
}

export default function DeleteEntryModal({
  open,
  onClose,
  orderId,
  orderEntries,
  columns,
  getCellProperties,
  callback
}: Props) {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const { mutate: deleteEntries, isLoading } = useMutation(
    (deletedEntries: string[]) => removeOrderEntries(orderId, { deletedEntries }),
    {
      onSuccess: (_, deletedEntries) => {
        queryClient.setQueriesData<OrderEntry[]>(['orderEntries', orderId], (prevEntries) =>
          (prevEntries || []).filter((entry) => !deletedEntries.includes(entry['@id']!))
        )

        if (callback) {
          callback()
        }
        onClose()
      }
    }
  )

  const handleDelete = () => {
    deleteEntries(orderEntries.map((entry) => entry['@id']!))
  }

  columns = [
    {
      key: 'quantity',
      name: t('common.label.quantity'),
      decorator: (entry) => {
        return <StyledCell className={getCellProperties(entry).classname}>{entry.quantity}</StyledCell>
      }
    },
    ...columns?.filter((e) => ['cardOrProduct', 'lang'].includes(e.key)),

    {
      key: 'price',
      name: t('common.label.totalUnitPrice'),
      decorator: (entry) => (
        <StyledCell className={getCellProperties(entry).classname}>
          <TextNumber value={entry.paidPrice! * entry.quantity!} suffix=" €" decimalScale={2} />
        </StyledCell>
      )
    }
  ]
  return (
    <Modal open={open} onClose={onClose}>
      <div className="test">
        <Title2>{t('page.order.detail.modals.deleteEntry.title')}</Title2>
        <DescriptionText color="secondary">{t('page.order.detail.modals.deleteEntry.description')}</DescriptionText>
        <Listing data={orderEntries} columns={columns} />
      </div>
      <ModalButtonWrapper>
        <Button icon={DeleteIcon} onClick={() => handleDelete()} isLoading={isLoading}>
          {t('page.order.detail.modals.deleteEntry.button')}
        </Button>
        <Button variant="white" onClick={() => onClose()}>
          {t('common.label.cancel')}
        </Button>
      </ModalButtonWrapper>
    </Modal>
  )
}
