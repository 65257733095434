import classNames from 'classnames'
import { Dot, Radio } from 'components/InputRadio/InputRadio.styles'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { useTranslation } from 'react-i18next'
import { ChoiceItem, PlayAreaChoiceContainer } from './StoreSeoTab.style'

interface Props {
  catering?: boolean
  playArea?: boolean
  setCatering: (e: boolean) => void
  setPlayArea: (e: boolean) => void
}

export default function PlayAreaRadio({ catering, playArea, setCatering, setPlayArea }: Props) {
  const { t } = useTranslation()

  return (
    <InputLayout
      label={t('page.stores.details.storeSeo.labels.playArea.title')}
      className="no-border"
      tooltip={<TextTooltip id="playAreaTooltip" text={t('page.stores.details.storeSeo.tooltips.playArea')} />}
    >
      <PlayAreaChoiceContainer>
        <ChoiceItem>
          <Radio
            onClick={() => {
              setCatering(true)
              setPlayArea(true)
            }}
          >
            <Dot className={classNames({ checked: catering })} />
          </Radio>
          <Text>{t('page.stores.details.storeSeo.labels.playArea.options.catering')}</Text>
        </ChoiceItem>
        <ChoiceItem>
          <Radio
            onClick={() => {
              setCatering(false)
              setPlayArea(true)
            }}
          >
            <Dot className={classNames({ checked: !catering && playArea })} />
          </Radio>
          <Text>{t('page.stores.details.storeSeo.labels.playArea.options.playArea')}</Text>
        </ChoiceItem>
        <ChoiceItem>
          <Radio
            onClick={() => {
              setCatering(false)
              setPlayArea(false)
            }}
          >
            <Dot className={classNames({ checked: !catering && !playArea })} />
          </Radio>
          <Text>{t('page.stores.details.storeSeo.labels.playArea.options.none')}</Text>
        </ChoiceItem>
      </PlayAreaChoiceContainer>
    </InputLayout>
  )
}
