import { ColumnContainer, RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const FormContainer = styled.form`
  display: grid;
  grid-template-columns: repeat(4, 2fr) 1fr;
  gap: 2rem 3.5rem;
  align-items: end;

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const CreateCardLayout = styled(CardLayout)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  ${Title2} {
    margin: 0;
  }
`

export const StyledColumnContainer = styled(ColumnContainer)`
  gap: 0;
`

export const ActionButtonContainer = styled(RowContainer)`
  margin-bottom: 2rem;
  gap: 2rem;
`

export const ErrorCellContainer = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.danger};
  gap: 0.5rem;
  align-items: center;
`
