import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import { useGetPaymentModeLabel } from 'hooks/entityHooks/paymentModeHooks'
import { useTranslation } from 'react-i18next'
import { StockStockReadItemStockRead } from 'types/playInApiInterfaces'
import { formatCalendarDate } from 'utils/dates'
import { DisplayCardLayout } from '../EditStockForm.styles'
import { DueDateEnTooltip, DueDateFrTooltip, ReceptionDateTooltip } from '../FormTooltips'

interface Props {
  stock: StockStockReadItemStockRead
}
export default function DisplayStock({ stock }: Props) {
  const { t } = useTranslation()

  const { getPaymentModeLabel } = useGetPaymentModeLabel()
  return (
    <DisplayCardLayout>
      <div>
        <Title2>{t('page.stock.detail.buyers')}</Title2>

        <InputLayout className="no-border" label={t('common.label.buyer')} layout="row">
          <TextFieldDisplay text={stock.store.shortName} />
        </InputLayout>
        <InputLayout className="no-border" label={t('common.label.retailer')} layout="row">
          <TextFieldDisplay text={stock.retailer?.fullName} />
        </InputLayout>
      </div>
      <div>
        <Title2>{t('page.stock.detail.reception')}</Title2>

        <InputLayout
          className="no-border"
          label={t('common.label.receptionDate')}
          layout="row"
          tooltip={<ReceptionDateTooltip />}
        >
          <TextFieldDisplay text={formatCalendarDate(stock.restockedAt)} />
        </InputLayout>
        <InputLayout className="no-border" label={t('common.label.productReceived')} layout="row">
          <TextFieldDisplay text={stock.productReceived ? t('common.radioButton.yes') : t('common.radioButton.no')} />
        </InputLayout>
      </div>
      <div>
        <Title2>{t('page.stock.detail.expectedReception')}</Title2>

        <InputLayout
          className="no-border"
          label={t('common.label.dueDateFr')}
          layout="row"
          tooltip={<DueDateFrTooltip />}
        >
          <TextFieldDisplay text={stock.approximateRestockDate} />
        </InputLayout>
        <InputLayout
          className="no-border"
          label={t('common.label.dueDateEn')}
          layout="row"
          tooltip={<DueDateEnTooltip />}
        >
          <TextFieldDisplay text={stock.approximateRestockDateEn} />
        </InputLayout>
      </div>
      <div>
        <Title2>{t('page.stock.detail.payement')}</Title2>

        <InputLayout className="no-border" label={t('common.label.paymentMethod')} layout="row">
          <TextFieldDisplay text={getPaymentModeLabel(stock.paymentMode?.codeName)} />
        </InputLayout>
        <InputLayout className="no-border" label={t('common.label.paymentReceived.label')} layout="row">
          <TextFieldDisplay
            text={stock.paymentReceived ? t('common.label.paymentReceived.yes') : t('common.label.paymentReceived.no')}
          />
        </InputLayout>
      </div>
      <div>
        <Title2>{t('page.stock.detail.invoices')}</Title2>

        <InputLayout className="no-border" label={t('common.label.invoiceId')} layout="row">
          <TextFieldDisplay text={stock.billNumber} />
        </InputLayout>
        <InputLayout className="no-border" label={t('common.label.invoiceDate')} layout="row">
          <TextFieldDisplay text={formatCalendarDate(stock.billDate)} />
        </InputLayout>
      </div>
      <div>
        <Title2>{t('page.stock.detail.comment')}</Title2>

        <TextFieldDisplay text={stock.comment} />
      </div>
    </DisplayCardLayout>
  )
}
