import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import _ from 'lodash'
import { RouteName, routesTreeConfig, useCheckMatch } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { StyledLink } from './Link.styles'

export type LinkProps = {
  className?: string
  route: RouteName
  placeholder?: string // Placeholder to replace from react router route path
  value?: string | number // Value to replace placeholder
  mode?: 'text' | 'hide'
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  disabled?: boolean
  state?: any
}

const Link: React.FC<LinkProps> = ({
  className,
  children,
  route,
  value,
  placeholder = ':id',
  mode = 'text',
  onMouseEnter,
  onMouseLeave,
  disabled,
  state
}) => {
  const { me, accessControl } = useAuth()
  const { checkMatch } = useCheckMatch()
  const [match, setMatch] = useState(false)
  const routeNode = routesTreeConfig.find((node) => node.name === route)

  // if logged user data or accessControl changed, recheck permissions
  useEffect(() => {
    setMatch(checkMatch(routeNode?.permissions))
  }, [me, accessControl, checkMatch, routeNode?.permissions])

  if (!routeNode) {
    return <div className={className}>{children}</div>
  }

  if (mode === 'hide' && !match) {
    return <></>
  }

  return (
    <StyledLink
      className={classnames(className, { disabled: disabled || !match })}
      to={!_.isNil(value) ? routeNode.path.replace(placeholder, value.toString()) : routeNode.path}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      state={state}
    >
      {children}
    </StyledLink>
  )
}

export default Link
