import { useTranslation } from 'react-i18next'

enum WeekDays {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday'
}
export default function useSchedule() {
  const { t } = useTranslation()
  const getDaysOfWeekTraduction = (day: WeekDays) =>
    ({
      [WeekDays.Monday]: t('common.days.monday'),
      [WeekDays.Tuesday]: t('common.days.tuesday'),
      [WeekDays.Wednesday]: t('common.days.wednesday'),
      [WeekDays.Thursday]: t('common.days.thursday'),
      [WeekDays.Friday]: t('common.days.friday'),
      [WeekDays.Saturday]: t('common.days.saturday'),
      [WeekDays.Sunday]: t('common.days.sunday')
    }[day])

  return {
    daysOfWeek: Object.values(WeekDays).map((e) => ({
      value: e,
      label: getDaysOfWeekTraduction(e)
    })),
    getDayByIndex: (index: number) => getDaysOfWeekTraduction(Object.values(WeekDays)[index])
  }
}
