import { usePaginatedVouchers } from 'api/vouchers'
import Listing, { Columns } from 'features/Listing/Listing'
import { useTranslation } from 'react-i18next'
import { Customer, Voucher } from 'types/entities'
import { SortingDirection } from 'types/sorting'

export interface Props {
  customer: Customer
  columns: Columns<Voucher>
}
export default function UsedVouchers({ customer, columns }: Props) {
  const { t } = useTranslation()
  const {
    data: vouchers,
    totalItems,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage
  } = usePaginatedVouchers({ customer: customer['@id'], active: false, 'order[expiresAt]': SortingDirection.Desc })

  return (
    <Listing
      data={vouchers}
      totalCount={totalItems}
      emptyText={t('page.customer.details.vouchers.active.emptyText')}
      countText={t('page.customer.details.vouchers.active.countText', {
        current: vouchers?.length ?? 0,
        total: totalItems ?? 0
      })}
      columns={columns}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
    />
  )
}
