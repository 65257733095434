import { postCollection, PostCollectionBody, postCollectionImage } from 'api/collection'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { useApiNotifications } from 'hooks/useApiNotifications'
import { useMutation } from 'hooks/useAxiosMutation'
import { getPath, RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { ProductCollectionJsonldProductCollectionRead } from 'types/playInApiInterfaces'
import CollectionCreateForm, { CreateCollectionInputs } from './form/CollectionCreateForm'

export default function CollectionCreate() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const { emitSuccessNotification } = useApiNotifications()
  const { mutateAsync: createCollection } = useMutation((body: PostCollectionBody) => postCollection(body), {
    emitDefaultSuccessNotification: false
  })
  const { mutateAsync: createCollectionImage } = useMutation(
    ({ body, id }: { body: FormData; id: string }) => postCollectionImage(id, body),
    {
      emitDefaultSuccessNotification: false
    }
  )

  const onSubmit = async (formValues: CreateCollectionInputs) => {
    //SPLIT common data & conditioned data
    const {
      image,
      description,
      descriptionEn,
      shortDescription,
      shortDescriptionEn,
      titleDescription,
      titleDescriptionEn,
      ...basePayload
    } = formValues

    const payload = {
      ...basePayload,
      ...(basePayload.dedicatedPage
        ? { description, descriptionEn, shortDescription, shortDescriptionEn, titleDescription, titleDescriptionEn }
        : {})
    }

    setIsLoading(true)
    let createdCollection: ProductCollectionJsonldProductCollectionRead | undefined
    try {
      //First, create the new collection
      createdCollection = await createCollection(payload)

      //Then, if an image is picked, post the image with freshly created collection id
      if (image && image instanceof File) {
        let formData = new FormData()
        formData.append('image', image)
        await createCollectionImage({ body: formData, id: `${createdCollection.id}` })
      }
      emitSuccessNotification()
    } finally {
      setIsLoading(false)
      //If collection is created, redirect to the new collection (even if image upload has failed for eg)
      if (createdCollection) navigate(getPath(RouteName.CollectionDetails).replace(':id', `${createdCollection.id}`))
    }
  }
  return (
    <Section>
      <MainTitle title={t('page.collection.create.title')} />
      <CardLayout>
        <CollectionCreateForm onSubmit={onSubmit} isFormLoading={isLoading} />
      </CardLayout>
    </Section>
  )
}
