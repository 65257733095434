import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import GiftCardManagementForm from './forms/GiftCardManagementForm'
import { GiftCardManagementLayout } from './GiftCardManagement.style'

function GiftCardManagement() {
  const { t } = useTranslation()

  const [location] = useAtom(defaultStoreAtom)

  return (
    <Section>
      <MainTitle title={t('page.gift.manage.title')} showStoreSelect={true} />
      <GiftCardManagementLayout>
        <CardLayout>
          <Title2Tooltip
            title={`${t('page.gift.managment.form.title')}`}
            text={t('page.gift.managment.form.barcode_helper')}
            id="GiftTitle"
          />
          <GiftCardManagementForm location={location?.['@id']} />
        </CardLayout>
      </GiftCardManagementLayout>
    </Section>
  )
}

export default GiftCardManagement
