import { patchCollaboratorPassword, PatchCollaboratorsUpdatePasswordBody } from 'api/collaborators'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { useMutation } from 'hooks/useAxiosMutation'
import { useTranslation } from 'react-i18next'
import UpdateCredentialsForm from './form/UpdateCredentialsForm'

export type UpdateCredentialsInputs = {
  oldPassword?: string
  newPassword?: string
  checkPassword?: string
}

export default function CollaboratorAccountOptions() {
  const { t } = useTranslation()
  const { mutate: patchCollaboratorMutation, isLoading } = 
    useMutation((body: PatchCollaboratorsUpdatePasswordBody) => patchCollaboratorPassword({ body }))

  const onSubmit = (formData: UpdateCredentialsInputs) => {
    if(formData.newPassword === formData.checkPassword){
      patchCollaboratorMutation({
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword
      })
    }
  }
  
  return (
    <Section>
      <MainTitle title={t('page.collaborator.accountOptions.title')} />
      <UpdateCredentialsForm onSubmit={onSubmit} isLoading={isLoading}/>
    </Section>
  )
}
