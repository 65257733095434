import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { Text } from 'components/Text/Text.styles'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import { ProductCheckbox, ProductList, ProductWrapper } from '../otherProductsTab.style'

interface Props {
  setSelectedProducts: (products: any) => void
  selectedProducts: number[]
  productsList: SealedProduct[]
}

export default function ProductsSelector({ selectedProducts, setSelectedProducts, productsList }: Props) {
  const { t } = useTranslation()

  const handleCheckbox = (product: SealedProduct) => {
    if (selectedProducts.includes(product.id!)) {
      setSelectedProducts((prev) => prev.filter((e) => e !== product.id))
    } else {
      setSelectedProducts((prev) => [...prev, product.id!])
    }
  }

  const allChecked = selectedProducts.length === productsList.length

  const handleAllCheckbox = () => {
    if (allChecked) {
      setSelectedProducts([])
    } else {
      setSelectedProducts(productsList.map((e) => e.id!))
    }
  }

  return (
    <ProductWrapper>
      {!!productsList?.length ? (
        <>
          {productsList.length > 1 && (
            <ProductCheckbox>
              <Checkbox className={allChecked ? 'checked' : ''} onClick={handleAllCheckbox} />
              <Text>{t('page.order.print.details.preorder.allProducts.label')}</Text>
            </ProductCheckbox>
          )}

          <ProductList>
            {productsList.map((product, index) => (
              <ProductCheckbox key={`checkbox-${product.id}`}>
                <Checkbox
                  className={selectedProducts.includes(product.id!) ? 'checked' : ''}
                  onClick={() => handleCheckbox(product)}
                />
                <Text>{product.name}</Text>
              </ProductCheckbox>
            ))}
          </ProductList>
        </>
      ) : (
        <Text color="secondary" fontStyle="italic">
          {t('page.product.management.empty')}
        </Text>
      )}
    </ProductWrapper>
  )
}
