import Svg from 'components/Svg/Svg'
import styled from 'styled-components'
import { TextColors } from 'theme/theme'

interface IconProps {
  backgroundColor: keyof TextColors
}

export const Icon = styled.div<IconProps>`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border-radius: 100%;
  background-color: ${(props) => props.theme.textColors[props.backgroundColor]};
`

export const IconSvg = styled(Svg)`
  display: flex;
  align-items: center;
  justify-content: center;
` as typeof Svg
