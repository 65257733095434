import { collaboratorByIdQueryKey, patchCollaborator, PatchCollaboratorBody } from 'api/collaborators'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Collaborator, Store } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { FormWrapper, ListActions, OriginText } from '../CollaboratorDetails.style'
import AddStoreForm from '../forms/AddStoreForm'
import MainStoreForm from '../forms/MainStoreForm'
import { isInMyStores, useCollaboratorStoresOptions } from '../utils'

interface Props {
  collaborator: Collaborator
}
const accessRole = [RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]

export default function StoresManage({ collaborator }: Props) {
  const [selectedStores, setSelectedStores] = useState<string[]>([])
  const [openDeleteModal, toggleDeleteModal] = useState(false)

  const { t } = useTranslation()
  const { me } = useAuth()

  const { mainStore } = collaborator
  const { checkMatch } = useCheckMatch()

  const { deletableStoresIds, canDeleteStore } = useCollaboratorStoresOptions(collaborator)

  const deniedExtraCondition = !collaborator.active || !isInMyStores(collaborator, me?.stores)

  const StoreOrigin = useMemo(
    () =>
      ({ store }: { store: Store }) => {
        if (store.givenByPermissions && store.givenManually)
          return (
            <>
              {t('page.collaborator.details.stores.labels.fromManuallyAndRole')}
              <ShouldHide permissions={accessRole} deniedExtraCondition={deniedExtraCondition}>
                <Tooltip id="fromManuallyAndRoleTooltip">
                  <Trans i18nKey="page.collaborator.details.stores.tooltips.fromManuallyAndRole" />
                </Tooltip>
              </ShouldHide>
            </>
          )
        if (store.givenByPermissions) return <>{t('page.collaborator.details.stores.labels.fromRole')}</>
        return <>{t('page.collaborator.details.stores.labels.fromManually')}</>
      },
    [deniedExtraCondition, t]
  )

  const columns = _.filter<Column<Store>>(
    [
      {
        key: 'checkbox',
        decorator: (store) => (
          <Cell>
            <TooltipHover
              id="cantDeleteStoreTooltip"
              content={canDeleteStore(store)?.message}
              container="tooltipContainer"
            >
              <Checkbox
                onClick={() => setSelectedStores((prev) => _.xor(prev, [store['@id']!]))}
                className={classNames({
                  checked: selectedStores.includes(store['@id']!),
                  disabled: !deletableStoresIds.includes(store['@id']!)
                })}
              />
            </TooltipHover>
          </Cell>
        )
      },
      {
        key: 'name',
        name: t('page.collaborator.details.stores.labels.playinLocalisation'),
        decorator: (store) => (
          <ClickableCell route={RouteName.StoreDetails} value={store.id}>
            {store.shortName}
          </ClickableCell>
        )
      },

      {
        key: 'mainStore',
        name: t('page.collaborator.details.stores.labels.mainStore'),
        decorator: (store) => {
          const isMainStore = collaborator.mainStore?.['@id'] === store['@id']
          return (
            <ClickableCell route={RouteName.StoreDetails} value={store.id}>
              <Text color={isMainStore ? 'success' : 'black'} fontWeight={isMainStore ? 'semiBold' : 'light'}>
                {isMainStore ? t('common.radioButton.yes') : t('common.radioButton.no')}
              </Text>
            </ClickableCell>
          )
        }
      },
      {
        key: 'origin',
        name: t('page.collaborator.details.stores.labels.origin'),
        tooltip: <Trans i18nKey="page.collaborator.details.stores.tooltips.origin" />,
        decorator: (store) => (
          <ClickableCell route={RouteName.StoreDetails} value={store.id}>
            <OriginText fontStyle="italic" fontWeight="light">
              <StoreOrigin store={store} />
            </OriginText>
          </ClickableCell>
        )
      }
    ],
    (col) => {
      if (col.key === 'checkbox') return checkMatch(accessRole) && !deniedExtraCondition
      return true
    }
  )

  const queryClient = useQueryClient()
  const { mutate: patchCollaboratorMutation, isLoading } = useMutation(
    (body: PatchCollaboratorBody) => patchCollaborator({ id: `${collaborator.id}`, body }),
    {
      onSuccess: (updatedCollaborator) => {
        const prev = queryClient.getQueryData<Collaborator>(collaboratorByIdQueryKey(collaborator.id!))
        queryClient.setQueryData(collaboratorByIdQueryKey(collaborator.id!), { ...prev, ...updatedCollaborator })
        toggleDeleteModal(false)
        setSelectedStores([])
      }
    }
  )

  const handleDelete = () => {
    //We need to send all deletableStores, without selected stores and with mainStore
    const stores = [..._.difference(deletableStoresIds, selectedStores), mainStore?.['@id']!]
    patchCollaboratorMutation({
      stores
    })
  }

  useEffect(() => {
    //In case new mainStore was selected before mainStore change
    setSelectedStores((prev) => prev.filter((e) => e !== mainStore?.['@id']!))
  }, [mainStore])

  return (
    <>
      <CardLayout>
        <Title2>{t('page.collaborator.details.stores.title')}</Title2>

        <ShouldHide permissions={accessRole} deniedExtraCondition={deniedExtraCondition}>
          <>
            <FormWrapper>
              <AddStoreForm collaborator={collaborator} />
              <MainStoreForm collaborator={collaborator} />
            </FormWrapper>
            {!!collaborator.stores?.length && (
              <ListActions>
                <Checkbox
                  onClick={() =>
                    setSelectedStores((prev) => (prev.length === deletableStoresIds.length ? [] : deletableStoresIds))
                  }
                  className={classNames({
                    checked: deletableStoresIds?.length && selectedStores.length === deletableStoresIds.length,
                    //As mainStore need to be sent in future delete Patch, we need to handle the case out of the hook when needed
                    disabled: !deletableStoresIds?.length
                  })}
                />
                <TooltipHover
                  id="deleteStoreTooltip"
                  content={t('page.collaborator.details.stores.tooltips.deleteStore')}
                  container="tooltipContainer"
                >
                  <Button
                    shape="circle"
                    icon={DeleteIcon}
                    variant="white"
                    onClick={() => toggleDeleteModal(true)}
                    disabled={!selectedStores.length}
                  />
                </TooltipHover>
              </ListActions>
            )}

            <ConfirmModal
              open={openDeleteModal}
              onClose={() => toggleDeleteModal(false)}
              title={t('page.collaborator.details.stores.deleteTitle', { count: selectedStores.length })}
              callback={handleDelete}
              closeOnCallback={false}
              confirmBtnProps={{
                isLoading,
                icon: DeleteIcon
              }}
            >
              <Listing
                columns={columns.filter((col) => col.key !== 'checkbox')}
                data={_.sortBy(
                  collaborator.stores?.filter((store) => selectedStores.includes(store['@id']!)),
                  'shortName'
                )}
              />
            </ConfirmModal>
          </>
        </ShouldHide>

        <Listing columns={columns} data={_.sortBy(collaborator.stores ?? [], 'shortName')} />
      </CardLayout>
    </>
  )
}
