import { deliverVouchers, eventByIdQueryKey, EventDeliverVouchersBody } from 'api/storeEvents'
import { getEventName } from 'app/StoreEventDetails/utils'
import Button from 'components/Button/Button'
import Modal, { ModalProps } from 'components/Modal/Modal'
import Cell from 'components/SortedTable/Cell'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { SaveIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import { useMutation } from 'hooks/useAxiosMutation'
import SingleInputForm from 'hooks/useSingleInputForm'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { StoreEvent } from 'types/entities'
import {
  StoreEventCustomerJsonldEventReadEventReadItem,
  StoreEventDeliverVouchersItemInputJsonldEventWrite
} from 'types/playInApiInterfaces'
import { AddCustomerButtonsWrapper } from '../AddCustomerModal/AddCustomerModal.style'
import { VoucherButtonsContainer, VouchersStatisticsContainer, VoucherStatistic } from './VouchersModal.style'

type StoreEventCustomer = StoreEventCustomerJsonldEventReadEventReadItem

interface Props extends ModalProps {
  event: StoreEvent
  selectedCustomers: StoreEventCustomer[]
}

const getFullname = (customer: StoreEventCustomer) => {
  const { firstname, lastname } = customer?.linkedOrder?.customer ?? {}
  return !!firstname && !!lastname ? `${lastname} ${firstname}` : ''
}

export default function VouchersModal({ open, onClose, event, selectedCustomers }: Props) {
  const { t } = useTranslation()

  const [voucherValues, setVoucherValues] = useState<Map<string, StoreEventDeliverVouchersItemInputJsonldEventWrite>>(
    new Map()
  )

  const totalInputs = _.reduce(
    selectedCustomers,
    (prev, current) => {
      return prev + ((voucherValues.get(current['@id']!)?.voucherValue ?? 0) - (current?.voucher?.originalValue ?? 0))
    },
    0
  )

  const columns: Columns<StoreEventCustomer> = [
    {
      key: 'value',
      name: t('page.events.details.modals.vouchers.labels.voucher'),
      decorator: (customer) => (
        <Cell>
          <SingleInputForm
            mode="onBlur"
            onSubmit={async ({ inputValue }) =>
              setVoucherValues(
                (prev) =>
                  new Map(
                    prev.set(customer['@id']!, {
                      customer: customer['@id'],
                      voucherValue: inputValue ?? 0
                    })
                  )
              )
            }
            type="formattedNumber"
            suffix={`\u00a0€`}
            decimalScale={2}
            submitType="onBlur"
            defaultValue={customer?.voucher?.originalValue}
          />
        </Cell>
      )
    },
    {
      key: 'name',
      name: t('common.label.name'),
      decorator: (customer) => <Cell>{getFullname(customer)}</Cell>
    },
    {
      key: 'email',
      name: t('common.label.email'),
      decorator: (customer) => (
        <Cell>
          <Text fontWeight="light">{customer?.linkedOrder?.customer?.email}</Text>
        </Cell>
      )
    }
  ]

  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation((body: EventDeliverVouchersBody) => deliverVouchers(body, event.id!), {
    onSuccess: (updatedEvent) => {
      const prev = queryClient.getQueryData<StoreEvent>(eventByIdQueryKey(updatedEvent.id!))
      queryClient.setQueryData(eventByIdQueryKey(updatedEvent.id!), { ...prev, ...updatedEvent })

      onClose()
    }
  })

  const onSubmit = () => {
    mutate({
      items: Array.from(voucherValues.values())
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Title2>{t('page.events.details.modals.vouchers.title', { name: getEventName(event) })}</Title2>
      <VouchersStatisticsContainer>
        <VoucherStatistic>
          <Text>{t('page.events.details.modals.vouchers.labels.turnover')}</Text>
          <TextNumber value={event.turnover} suffix={`\u00a0€`} placeholder="-" decimalScale={2} fontWeight="bold" />
        </VoucherStatistic>
        <VoucherStatistic>
          <Text>{t('page.events.details.modals.vouchers.labels.totalDistributedVouchers')}</Text>
          <TextNumber
            value={(event.totalDistributedVouchers ?? 0) + totalInputs}
            suffix={`\u00a0€`}
            placeholder="-"
            decimalScale={2}
            fontWeight="bold"
          />
        </VoucherStatistic>
      </VouchersStatisticsContainer>
      <Listing data={selectedCustomers} columns={columns} />
      <VoucherButtonsContainer>
        <AddCustomerButtonsWrapper>
          <Button icon={SaveIcon} onClick={onSubmit} isLoading={isLoading}>
            {t('common.button.save')}
          </Button>
          <Button variant="white" onClick={onClose}>
            {t('common.button.cancel')}
          </Button>
        </AddCustomerButtonsWrapper>
      </VoucherButtonsContainer>
    </Modal>
  )
}
