import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Cell from 'components/SortedTable/Cell'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const CreateCardLayout = styled(CardLayout)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Title2} {
    margin-bottom: 0;
  }
`

export const SearchFormLayout = styled.form`
  display: flex;
  gap: 2rem 3.5rem;
  align-items: flex-end;

  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const SearchSelectWrapper = styled.div`
  width: 25rem;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    width: 100%;
  }
`
export const SearchInputWrapper = styled.div`
  width: 40rem;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    width: 100%;
    grid-column: span 2;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-column: span 1;
  }
`

export const NameCell = styled(Cell)`
  display: flex;
  gap: 0.5rem;
  min-width: 20rem;
`
