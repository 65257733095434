import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & .MuiButtonBase-root {
    padding: 0;
  }

  & .MuiInputAdornment-root {
    margin: 0;
    margin-right: 1.5rem;
  }

  .MuiPickersDay-root {
    color: red !important;
  }
  .MuiSvgIcon-root {
    font-size: 1.4rem;
  }

  &.disabled {
    pointer-events: none;
  }
`
