import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { MoreIcon, PrintIcon } from 'constants/icons'
import html2canvas from 'html2canvas'
import { useAtom } from 'jotai'
import jsPDF from 'jspdf'
import _ from 'lodash'
import { RouteName, getPath } from 'permissions/permissions'
import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation, useNavigate } from 'react-router'
import { SealedProduct } from 'types/entities'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import { v4 as uuid } from 'uuid'
import CsvFileUploader from './CsvFileUploader/CsvFileUploader'
import { getDiscountPrice } from './LabelEditionModal/Forms/AddLabelForm'
import LabelEditionModal, { EditionMode } from './LabelEditionModal/LabelEditionModal'
import ProductsByFamily from './ProductsByFamily/ProductsByFamily'
import RectangleDiscountLabel from './RectangleDiscountLabel/RectangleDiscountLabel'
import RectangleLabel from './RectangleLabel/RectangleLabel'
import SquareDiscountLabel from './SquareDiscountLabel/SquareDiscountLabel'
import SquareLabel from './SquareLabel/SquareLabel'
import {
  GridContainer,
  LabelsContainer,
  PrintActionContainer,
  PrintContainer,
  StoreLabelsContainer,
  StoreRectangleLabels,
  StoreSquareLabels,
  StyledRowContainer
} from './StoreLabels.styles'
import { ArrowLabelEnum, LabelFormatEnum, ProductLabel, getDiscountPercentage } from './storeLabelUtils'

const nbRectangleLabelsPerPage = 60
const nbSquareLabelsPerPage = 70

const StoreLabels = () => {
  const { t } = useTranslation()
  const [store] = useAtom(defaultStoreAtom)
  const [labels, setLabels] = useState<ProductLabel[]>([])
  const [selectedLabel, setSelectedLabel] = useState<ProductLabel | undefined>(undefined)
  const [modalOpen, setModalOpen] = useState(false)
  const [editionMode, setEditionMode] = useState<EditionMode | undefined>(undefined)
  const [rectangleLabelRefs, setRectangleLabelRefs] = useState<React.RefObject<HTMLDivElement>[]>([])
  const [squareLabelRefs, setSquareLabelRefs] = useState<React.RefObject<HTMLDivElement>[]>([])
  const [generatingPdf, setGeneratingPdf] = useState(false)

  useEffect(() => {
    const rectangleLabels = _.filter(labels, (label) => label.format === LabelFormatEnum.rectangle)
    const squareLabels = _.filter(labels, (label) => label.format === LabelFormatEnum.square)

    setRectangleLabelRefs(
      _.chain(rectangleLabels)
        .chunk(nbRectangleLabelsPerPage)
        .map(() => createRef<HTMLDivElement>())
        .value()
    )
    setSquareLabelRefs(
      _.chain(squareLabels)
        .chunk(nbSquareLabelsPerPage)
        .map(() => createRef<HTMLDivElement>())
        .value()
    )
  }, [labels])

  const onLabelClick = (label: ProductLabel) => {
    setSelectedLabel(label)
    setEditionMode('edit')
    setModalOpen(true)
  }

  const getLabel = (productInfo: ProductLabel, index: number) => {
    if (_.isNumber(productInfo.discountPercent) && _.isNumber(productInfo.discountPrice)) {
      return productInfo.format === LabelFormatEnum.rectangle ? (
        <RectangleDiscountLabel
          key={`label-${productInfo.id}`}
          price={productInfo.price}
          title={productInfo.title || productInfo.product}
          subtitle={productInfo.subtitle}
          discountPercent={productInfo.discountPercent}
          discountPrice={productInfo.discountPrice}
          arrow={productInfo.arrow}
          onClick={() => onLabelClick(productInfo)}
        />
      ) : (
        <SquareDiscountLabel
          key={`label-${productInfo.id}`}
          price={productInfo.price}
          title={productInfo.title || productInfo.product}
          subtitle={productInfo.subtitle}
          discountPercent={productInfo.discountPercent}
          discountPrice={productInfo.discountPrice}
          arrow={productInfo.arrow}
          onClick={() => onLabelClick(productInfo)}
        />
      )
    } else {
      return productInfo.format === LabelFormatEnum.rectangle ? (
        <RectangleLabel
          key={`label-${productInfo.id}`}
          price={productInfo.price}
          title={productInfo.title || productInfo.product}
          subtitle={productInfo.subtitle}
          arrow={productInfo.arrow}
          onClick={() => onLabelClick(productInfo)}
        />
      ) : (
        <SquareLabel
          key={`label-${productInfo.id}`}
          price={productInfo.price}
          title={productInfo.title || productInfo.product}
          subtitle={productInfo.subtitle}
          arrow={productInfo.arrow}
          onClick={() => onLabelClick(productInfo)}
        />
      )
    }
  }

  const printLabels = () => {
    const doc = new jsPDF('p', 'mm', 'a4')
    doc.setProperties({ title: 'labels' })
    doc.deletePage(1)

    const rectanglePromises = rectangleLabelRefs.map((ref) => {
      return html2canvas(ref.current!, { scale: (window.devicePixelRatio = 1) })
    })

    const squarePromises = squareLabelRefs.map((ref) => {
      return html2canvas(ref.current!, { scale: (window.devicePixelRatio = 1) })
    })

    return Promise.all(rectanglePromises).then((canvasCollection) => {
      canvasCollection.forEach((canvas) => {
        const dataUrl = canvas.toDataURL('image/jpeg', 1.0)

        getNewPdfRectangleLayout(doc)
        const imgProps = doc.getImageProperties(dataUrl)
        // imgProps width & height are in px
        // rectangle labels are 120*72 px and we add a X2 zoom factor
        // and 30*18 mm
        doc.addImage(dataUrl, 'jpeg', 15, 13, (imgProps.width / (120 * 2)) * 30, (imgProps.height / (72 * 2)) * 18)
      })

      Promise.all(squarePromises).then((canvasCollection) => {
        canvasCollection.forEach((canvas) => {
          const dataUrl = canvas.toDataURL('image/jpeg', 1.0)

          getNewPdfSquareLayout(doc)
          const imgProps = doc.getImageProperties(dataUrl)
          // imgProps width & height are in px
          // square labels are 98*98 px and we add a X2 zoom factor
          // and 26*26 mm
          doc.addImage(dataUrl, 'jpeg', 14, 18, (imgProps.width / (98 * 2)) * 26, (imgProps.height / (98 * 2)) * 26)
        })

        window.open(doc.output('bloburl'))
        setGeneratingPdf(false)
      })
    })
  }

  const location = useLocation() as Location & { state: null | { product: SealedProduct } }
  const navigate = useNavigate()
  useEffect(() => {
    // Listen search made from order sidebar search
    // if it change, update queryKey to match the request made by the search bar
    // and display results directly
    if (location.state?.product) {
      const sealedProduct = location.state.product
      const productStore = sealedProduct?.productStores?.find(
        (productStore) => productStore.store?.['@id'] === store?.['@id']
      )

      const discountPrice = getDiscountPrice(productStore)

      if (productStore) {
        setLabels([
          {
            id: uuid(),
            product: sealedProduct?.storeLabelTitle! || sealedProduct?.name!,
            subtitle: sealedProduct?.storeLabelSubtitle!,
            price: productStore?.customerSellPrice!,
            discountPrice,
            format: LabelFormatEnum.rectangle,
            arrow: ArrowLabelEnum.none,
            discountPercent:
              sealedProduct.price && discountPrice
                ? getDiscountPercentage(sealedProduct.price, discountPrice)
                : undefined
          }
        ])
      }

      // as we consumed current state, we reset it for futur reload
      navigate(getPath(RouteName.StoreLabels), { replace: true, state: null })
    }
  }, [location.state, navigate, store])

  return (
    <Section>
      <MainTitle title={t('page.store.labels.title')} showStoreSelect={true} />
      <StyledRowContainer>
        <ProductsByFamily store={store!} />
        <CsvFileUploader handleData={setLabels} />
      </StyledRowContainer>
      <CardLayout>
        <Title2>{t('page.store.labels.print.title')}</Title2>
        <GridContainer>
          <PrintActionContainer>
            <Text fontWeight="light" color="secondary">
              {t('page.store.labels.print.addProduct.text')}
            </Text>
            <Button
              variant="white"
              icon={MoreIcon}
              onClick={() => {
                setEditionMode('add')
                setModalOpen(true)
              }}
            >
              {t('page.store.labels.print.addProduct.button')}
            </Button>
          </PrintActionContainer>
          <PrintActionContainer>
            <Text fontWeight="light" color="secondary">
              {t('page.store.labels.print.printLabels.text')}
            </Text>
            <Button
              icon={PrintIcon}
              onClick={() => {
                setGeneratingPdf(true)
                // printLabels will freeze the page while generating snapshop
                // we delay it so setGeneratingPdf can refresh the DOM before being paused
                setTimeout(printLabels, 200)
              }}
              disabled={!labels.length || generatingPdf}
              isLoading={generatingPdf}
            >
              {t('page.store.labels.print.printLabels.button')}
            </Button>
          </PrintActionContainer>
        </GridContainer>
        <StoreLabelsContainer>
          <LabelsContainer>
            {_.chain(labels)
              .filter((label) => label.format === LabelFormatEnum.rectangle)
              .chunk(nbRectangleLabelsPerPage)
              .map((rectangleLabelsChunk, index) => {
                return (
                  <PrintContainer ref={rectangleLabelRefs[index]} key={`rectangle-${index}`}>
                    <StoreRectangleLabels>{rectangleLabelsChunk.map(getLabel)}</StoreRectangleLabels>
                  </PrintContainer>
                )
              })
              .value()}
          </LabelsContainer>
          <LabelsContainer>
            {_.chain(labels)
              .filter((label) => label.format === LabelFormatEnum.square)
              .chunk(nbSquareLabelsPerPage)
              .map((squareLabelsChunk, index) => {
                return (
                  <PrintContainer key={`square-${index}`} ref={squareLabelRefs[index]}>
                    <StoreSquareLabels>{squareLabelsChunk.map(getLabel)}</StoreSquareLabels>
                  </PrintContainer>
                )
              })
              .value()}
          </LabelsContainer>
        </StoreLabelsContainer>
      </CardLayout>

      <LabelEditionModal
        open={modalOpen}
        setOpen={() => setModalOpen(false)}
        editionMode={editionMode}
        onClose={() => {
          setEditionMode(undefined)
          setSelectedLabel(undefined)
        }}
        storeId={store?.['@id']!}
        setLabels={setLabels}
        selectedLabel={selectedLabel}
      />
    </Section>
  )
}

export default StoreLabels

const getNewPdfRectangleLayout = (doc: jsPDF) => {
  doc.addPage()
  doc.setDrawColor(196, 196, 196)

  //Rectangle tags
  // Width marks
  for (let i = 0; i <= 4; i++) {
    doc.line(15 + i * 40, 0, 15 + i * 40, 10)
  }
  // height marks
  for (let i = 0; i <= 15; i++) {
    doc.line(0, 13 + i * 18, 10, 13 + i * 18)
  }
}

const getNewPdfSquareLayout = (doc: jsPDF) => {
  doc.addPage()
  doc.setDrawColor(196, 196, 196)

  //Square tags
  // Width marks
  for (let i = 0; i <= 7; i++) {
    doc.line(14 + i * 26, 0, 14 + i * 26, 10)
  }
  // height marks
  for (let i = 0; i <= 10; i++) {
    doc.line(0, 18 + i * 26, 10, 18 + i * 26)
  }
}
