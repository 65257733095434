import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Card } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export type CardPropertiesQueryParams = Api.GetCards.RequestQuery
export const getCardsUri = (params: CardPropertiesQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.cards.root, queryString].join('?')
}

type FetchCardResponse = Api.GetCards.ResponseBody
export const usePaginatedCards = (
  queryKey: QueryKey,
  params: CardPropertiesQueryParams,
  options: PaginatedHookOptions<FetchCardResponse['hydra:member'][0]>
) => usePaginatedQuery<FetchCardResponse['hydra:member'][0]>(queryKey, getCardsUri(params), options)

export type PatchCardsParams  = {cardIri:string, body:Api.PatchCardsId.RequestBody}
type PatchCardsResponse = Api.PatchCardsId.ResponseBody
export const patchCard = async ({cardIri,body}:PatchCardsParams): Promise<Card> => {
  let res = await playInApi.patch<Api.PatchCardsId.RequestBody , AxiosResponse<PatchCardsResponse>>(
    cardIri,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}