import styled from 'styled-components'

export const VoucherOption = styled.div`
  display: inline-flex;
  align-items: center;
  & > span {
    &::after {
      content: '\u279c';
      margin: 0 0.5rem;
    }
  }
`
export const AddPointForm = styled.form`
  display: grid;
  grid-template-columns: 15rem minmax(15rem, 25rem) minmax(15rem, 25rem) minmax(25rem, 50rem) auto;
  gap: 2rem;
  align-items: flex-end;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(4, 1fr) auto;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const PointVoucherForm = styled.form`
  display: grid;
  grid-template-columns: 18.5rem 35rem 1fr;
  align-items: flex-end;
  gap: 2rem 3.5rem;
  margin-bottom: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 18.5rem 1fr auto;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const AddVoucherFormLayout = styled.form`
  display: flex;
  align-items: flex-end;
  gap: 2rem;
  display: grid;
  grid-template-columns: minmax(10rem, 15rem) minmax(15rem, 25rem) 12rem minmax(15rem, 25rem) minmax(25rem, 50rem) auto;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(5, 1fr) auto;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
