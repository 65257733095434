import { patchStock, stockByIdQueryKey } from 'api/stock'
import { deleteStockEntry, useGetTransfers } from 'api/stockEntry'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import ApiResponseIcon from 'components/Icon/ApiResponseIcon'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { DeleteIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useMutation } from 'hooks/useAxiosMutation'
import SingleInputForm from 'hooks/useSingleInputForm'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { TextColors } from 'theme/theme'
import { Stock } from 'types/entities'
import { UnmodifiableStockStatus } from 'types/enums'
import { RoleAction, StockStatus } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import {
  AlignRight,
  Disclaimer,
  MediumSpan,
  ProductActions,
  TotalItem,
  TotalsContainer
} from '../../StockDetails.styles'
import AddStockEntryForm from '../addStockEntry/AddStockEntryForm'
import { LabelAndTooltipContainer } from '../EditStockForm.styles'
import StockEntriesTable from './StockEntriesTable'

interface Props {
  stock: Stock
}

interface FormData {
  discountRate: number
  shippingFees: number
  miscFees: number
}

const EditStockEntriesForm = ({ stock }: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const cantEditEntries = UnmodifiableStockStatus.includes(stock?.status!)
  const [selectedProducts, setSelectedProducts] = useState<Array<number>>([])
  const [isDeletingEntries, setIsDeletingEntries] = useState(false)
  const discountRateSchema = yup
    .number()
    .nullable()
    .transform((value) => value ?? 0)
    .test('is-valid-percent', t('form.validation.format.discountRate'), (value) => {
      if (value && (value < 0 || value > 100)) {
        return false
      }
      return true
    })

  const { mutateAsync: deleteStockEntryMutation } = useMutation((id: number) => deleteStockEntry(id), {
    onSuccess: (_, id) => {
      // @ts-ignore id should not be undefined
      const prev = queryClient.getQueryData<Stock>(stockByIdQueryKey(stock?.id))
      // @ts-ignore id should not be undefined
      queryClient.setQueryData(stockByIdQueryKey(stock?.id), {
        ...prev,
        entries: prev?.entries.filter((entry) => entry.id !== id)
      })
    }
  })

  const handleDeleteEntries = async () => {
    setIsDeletingEntries(true)
    try {
      for (let id of selectedProducts) {
        await deleteStockEntryMutation(id)
      }
    } catch (error) {
      setIsDeletingEntries(false)
    }
    //after all products are deleted, empty selected product and stop loading
    setSelectedProducts([])
    setIsDeletingEntries(false)
  }

  // TODO: Fix api types
  const {
    mutate: putStockMutation,
    isLoading,
    isError,
    isSuccess
  } = useMutation((stockData: FormData) => patchStock(stock.id!, stockData), {
    onSuccess: async (data) => {
      // @ts-ignore id should not be undefined
      const stockPrev = queryClient.getQueryData<Stock>(stockByIdQueryKey(stock?.id))

      // @ts-ignore id should not be undefined
      queryClient.setQueryData(stockByIdQueryKey(stock?.id), {
        ...stockPrev,
        ...data
      })
    }
  })

  const [modifiedField, setModifiedField] = useState<string | null>(null)

  useEffect(() => {
    const timeout = setTimeout(() => setModifiedField(null), 2000)
    return () => clearTimeout(timeout)
  }, [modifiedField])

  const onTotalFormSubmit = (field: string, formData: FormData) => {
    putStockMutation(formData)
    setModifiedField(field)
  }

  const stockUpdateDisclaimer = {
    validated: {
      text: <Trans i18nKey="page.stock.detail.products.disclaimers.validated" components={{ bold: <MediumSpan /> }} />,
      color: 'info' as keyof TextColors
    },
    treated: {
      text: <Trans i18nKey="page.stock.detail.products.disclaimers.treated" components={{ bold: <MediumSpan /> }} />,
      color: 'danger' as keyof TextColors
    }
  }[stock.status ?? '']

  const { getStockEntryTransfersQuantity } = useGetTransfers(stock.id!, stock.status)
  const deletableEntries: number[] = stock?.entries
    .filter((entry) => !getStockEntryTransfersQuantity(entry.id).quantityTotal && !entry.soldQuantity)
    .map((e) => e.id!)

  return (
    <>
      {!stock.entries.length ? (
        <Disclaimer>
          <Text color="danger">{t('page.stock.detail.products.empty')}</Text>
        </Disclaimer>
      ) : (
        stockUpdateDisclaimer && <Text color={stockUpdateDisclaimer.color}>{stockUpdateDisclaimer.text}</Text>
      )}

      <ShouldHide
        permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]}
        deniedExtraCondition={![StockStatus.Created, StockStatus.Validated].includes(stock.status!)}
      >
        {!!deletableEntries.length && (
          <ProductActions>
            <Checkbox
              className={classNames({
                checked: deletableEntries.length === selectedProducts.length
              })}
              onClick={() =>
                setSelectedProducts((prev) => (prev.length === deletableEntries.length ? [] : deletableEntries))
              }
            />
            <TooltipHover
              id="tooltip-delete-stock-entries"
              content={t('page.stock.detail.products.tooltips.deleteEntries')}
            >
              <Button
                icon={DeleteIcon}
                shape="circle"
                variant="white"
                disabled={!selectedProducts.length}
                onClick={handleDeleteEntries}
                isLoading={isDeletingEntries}
              />
            </TooltipHover>
          </ProductActions>
        )}

        <ShouldHide permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]}>
          <AddStockEntryForm stockId={stock?.id!} stockRef={stock?.['@id']!} />
        </ShouldHide>
      </ShouldHide>

      {stock.status === StockStatus.Canceled && (
        <Text color="danger">
          <Trans i18nKey="page.stock.detail.products.isCanceled" components={{ bold: <strong /> }} />
        </Text>
      )}
      {!!stock.entries.length && (
        <div>
          <StockEntriesTable
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            stock={stock}
            deletableEntries={deletableEntries}
          />

          <TotalsContainer>
            <TotalItem>
              <Text>{t('page.stock.detail.products.totalHT')}</Text>
              <TextNumber
                value={stock.entries.reduce((acc, entry) => {
                  // if stock entry has transfered items, we need to sum both current stock quantity + transfered quantity
                  const { quantityTotal } = getStockEntryTransfersQuantity(entry.id)
                  const transfersQuantity =
                    stock?.status !== StockStatus.Created ? quantityTotal : 0
                  const productQuantity =
                    stock?.status !== StockStatus.Created ? entry.quantity! + transfersQuantity : entry.quantity

                  return acc + entry.buyPriceVatExcluded! * productQuantity!
                }, 0)}
                ContainerComponent={AlignRight}
                suffix=" €"
                decimalScale={4}
                fontWeight="medium"
              />
            </TotalItem>
            <TotalItem>
              <LabelAndTooltipContainer>
                <Text>{t('page.stock.detail.products.discount')}</Text>
                <TextTooltip
                  id="discountTooltip"
                  text={t('page.stock.detail.products.tooltips.discount.text')}
                  title={t('page.stock.detail.products.tooltips.discount.title')}
                />
              </LabelAndTooltipContainer>
              <CanAccess
                permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]}
                deniedExtraCondition={cantEditEntries}
                allowedComponent={
                  <LabelAndTooltipContainer>
                    <SingleInputForm
                      type="formattedNumber"
                      defaultValue={stock?.discountRate}
                      // @ts-ignore
                      onSubmit={({ inputValue }) => onTotalFormSubmit('discountRate', { discountRate: inputValue })}
                      suffix=" %"
                      placeholder="0 %"
                      fieldSchema={discountRateSchema}
                      decimalScale={2}
                    />
                    <ApiResponseIcon
                      showIcon={modifiedField === 'discountRate'}
                      isLoading={isLoading}
                      isError={isError}
                      isSuccess={isSuccess}
                    />
                  </LabelAndTooltipContainer>
                }
                deniedComponent={
                  <TextNumber
                    value={stock?.discountRate}
                    ContainerComponent={AlignRight}
                    suffix=" %"
                    placeholder="0 %"
                    decimalScale={2}
                  />
                }
              />
            </TotalItem>
            <TotalItem>
              <LabelAndTooltipContainer>
                <Text>{t('page.stock.detail.products.shippingFees')}</Text>
                <TextTooltip
                  id="shippingFeesTooltip"
                  text={t('page.stock.detail.products.tooltips.shippingFees.text')}
                  title={t('page.stock.detail.products.tooltips.shippingFees.title')}
                />
              </LabelAndTooltipContainer>
              <CanAccess
                permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]}
                deniedExtraCondition={cantEditEntries}
                allowedComponent={
                  <LabelAndTooltipContainer>
                    <SingleInputForm
                      type="formattedNumber"
                      defaultValue={stock?.shippingFees}
                      // @ts-ignore
                      onSubmit={({ inputValue }) => onTotalFormSubmit('shippingFees', { shippingFees: inputValue })}
                      suffix=" €"
                      decimalScale={2}
                      placeholder="0.00 €"
                    />
                    <ApiResponseIcon
                      showIcon={modifiedField === 'shippingFees'}
                      isLoading={isLoading}
                      isError={isError}
                      isSuccess={isSuccess}
                    />
                  </LabelAndTooltipContainer>
                }
                deniedComponent={
                  <TextNumber
                    value={stock?.shippingFees}
                    ContainerComponent={AlignRight}
                    suffix=" €"
                    decimalScale={2}
                    placeholder="0.00 €"
                  />
                }
              />
            </TotalItem>
            <TotalItem>
              <Text>{t('page.stock.detail.products.miscFees')}</Text>
              <CanAccess
                permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]}
                deniedExtraCondition={cantEditEntries}
                allowedComponent={
                  <LabelAndTooltipContainer>
                    <SingleInputForm
                      type="formattedNumber"
                      defaultValue={stock?.miscFees}
                      // @ts-ignore
                      onSubmit={({ inputValue }) => onTotalFormSubmit('miscFees', { miscFees: inputValue })}
                      suffix=" €"
                      decimalScale={2}
                      placeholder="0.00 €"
                    />
                    <ApiResponseIcon
                      showIcon={modifiedField === 'miscFees'}
                      isLoading={isLoading}
                      isError={isError}
                      isSuccess={isSuccess}
                    />
                  </LabelAndTooltipContainer>
                }
                deniedComponent={
                  <TextNumber
                    value={stock?.miscFees}
                    ContainerComponent={AlignRight}
                    suffix=" €"
                    decimalScale={2}
                    placeholder="0.00 €"
                  />
                }
              />
            </TotalItem>
            <TotalItem>
              <Text>{t('page.stock.detail.products.totalTTC')}</Text>
              <TextNumber
                value={
                  stock.entries.reduce((acc, entry) => {
                    // if stock entry has transfered items, we need to sum both current stock quantity + transfered quantity
                    const { quantityTotal } = getStockEntryTransfersQuantity(entry.id)
                    const transfersQuantity =
                      stock?.status !== StockStatus.Created && quantityTotal
                    const productQuantity =
                      stock?.status !== StockStatus.Created && transfersQuantity
                        ? entry.quantity! + transfersQuantity
                        : entry.quantity

                    return acc + entry.buyPrice! * productQuantity!
                  }, 0) +
                  stock.miscFees +
                  stock.shippingFees
                }
                ContainerComponent={AlignRight}
                suffix=" €"
                decimalScale={5}
                fontWeight="semiBold"
              />
            </TotalItem>
          </TotalsContainer>
        </div>
      )}
    </>
  )
}

export default EditStockEntriesForm
