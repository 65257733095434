import styled from 'styled-components'
import { PriceText, TextContainer, TwoLinesText } from '../StoreLabels.styles'

export const HalfMoonSquare = styled.div`
  width: 100%;
  height: 16rem;
  margin-top: -7rem;
  background-color: #1b1437;
  border-radius: 0% 0% 50% 50%;
`

export const Subtitle = styled(TwoLinesText)``

export const SquareContainer = styled.div`
  position: relative;
  width: 19.6rem;
  height: 19.6rem;
  overflow: hidden;

  cursor: pointer;
`

export const StyledPriceText = styled(PriceText)`
  top: 1.4rem;
`

export const StyledTextContainer = styled(TextContainer)`
  top: 9.92rem;
`