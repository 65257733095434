import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const BillRowContainer = styled(RowContainer)`
  & > :first-child {
    flex-grow: 1;
  }

  & > :last-child {
    white-space: pre;
  }
`

export const BillPlusRowContainer = styled(BillRowContainer)`
  ::before {
    content: '+ ';
  }
`

export const BillMinusRowContainer = styled(BillRowContainer)`
  ::before {
    content: '- ';
  }
`

export const BillTitle2 = styled(Title2)`
  margin-bottom: 0.5rem;
`
export const BolderTitle2 = styled(BillTitle2)`
  font-weight: ${(props) => props.theme.fontWeight.bolder};
`

export const TotalTitle = styled(Title2)`
  margin-bottom: 0;
`

export const InnerTitle2 = styled.div`
  & > * {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin-bottom: 0.5rem;
  }
`

export const InnerBolderTitle2 = styled.div`
  & > * {
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.fontWeight.bolder};
    margin-bottom: 0.5rem;
  }
`

export const Disclaimer = styled(Text)`
  margin-top: 1rem;
`
