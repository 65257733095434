import { fetchFidelityPoints, getFidelityPointUri } from 'api/fidelityPoints'
import {
  EditOrderBody,
  GetOrderByIdResponse,
  assignBillNumber,
  cancelOrderTreated,
  editOrder,
  orderByIdQueryKey,
  reorder
} from 'api/orders'
import { useOrderContext } from 'app/OrderDetails/helper'
import CancelOrderModal from 'app/OrderDetails/modals/CancelOrderModal/CancelOrderModal'
import { ActionButton, WideActionButton } from 'app/OrderDetails/tabs/MiscTab/MiscTab.styles'
import Loader from 'components/Loader/Loader'
import { ArchiveIcon, DeleteIcon, InvoiceIcon, SyncIcon } from 'constants/icons'
import ConfirmModal from 'features/Modals/ConfirmModal'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useMutation } from 'hooks/useAxiosMutation'
import { RouteName, getPath } from 'permissions/permissions'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { OrderStatus, RoleAction } from 'types/playInApiInterfaces'
import { ActionButtonsContainer, ModalAlertText } from './ActionButtons.style'

interface Props {
  order: GetOrderByIdResponse
}
export default function ActionButtons({ order }: Props) {
  const [cancelOrderModalOpen, toggleCancelOrderModal] = useState(false)
  const [treatOrderModalOpen, toggleTreatOrderModal] = useState(false)
  const [generateBillNumberModalOpen, toggleGenerateBillNumberModal] = useState(false)
  const [cancelTreatedModalOpen, toggleCancelTreatedModal] = useState(false)
  const [reorderModalOpen, toggleReorderModal] = useState(false)
  const [cancelCancelRequetModalOpen, toggleCancelCancelRequestModal] = useState(false)

  const { t } = useTranslation()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const updateCurrentOrder = (updatedOrder: any) => {
    let prev = queryClient.getQueriesData(orderByIdQueryKey(order?.id!))
    queryClient.setQueryData(orderByIdQueryKey(order?.id!), { ...prev, ...updatedOrder })
  }

  const { mutate: editOrderMutation, isLoading: editLoading } = useMutation(
    (body: EditOrderBody) => editOrder(body, order?.id!),
    {
      onSuccess: (updatedOrder) => updateCurrentOrder(updatedOrder)
    }
  )

  const { mutate: cancelOrderTreatedMutation, isLoading: isCancelTreatedLoading } = useMutation(
    (id: number | string) => cancelOrderTreated(id),
    {
      onSuccess: (updatedOrder) => updateCurrentOrder(updatedOrder)
    }
  )

  const { mutate: reorderMutation, isLoading: isReorderLoading } = useMutation((id: number | string) => reorder(id), {
    onSuccess: (createdOrder) => navigate(getPath(RouteName.OrderDetails).replace(':id', `${createdOrder.id!}`))
  })

  const { mutate: assignBillNumberMutation, isLoading: isAssignBillNumberLoading } = useMutation(
    (id: string | number) => assignBillNumber(id),
    {
      onSuccess: (updatedOrder) => updateCurrentOrder(updatedOrder)
    }
  )

  const fidelityPointQuery = getFidelityPointUri({
    customer: order?.customer?.['@id'],
    pagination: false,
    activeOrWaiting: true
  })

  const orderPoint = order?.fidelityPoint?.value ?? 0

  const { data: points, isFetching } = useQuery(fidelityPointQuery, () => fetchFidelityPoints(fidelityPointQuery), {
    enabled: cancelTreatedModalOpen && orderPoint > 0
  })

  const fidelityPointCount =
    points?.filter((point) => point.active).reduce((prev, point) => prev + (point.value! - point.consumed!), 0) ?? 0

  const { inMyStoresOrder } = useOrderContext()

  return (
    <>
      <ActionButtonsContainer>
        {order?.status === OrderStatus.Treated ? (
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_ORDER_CANCEL_TREAT]}
            allowedComponent={
              <ActionButton
                variant="danger"
                icon={ArchiveIcon}
                onClick={() => toggleCancelTreatedModal(true)}
                isLoading={isCancelTreatedLoading}
                disabled={order?.invoiced}
              >
                {t('page.stock.detail.action.terminated.cancel')}
              </ActionButton>
            }
            deniedComponent={
              <ActionButton icon={ArchiveIcon} disabled={true}>
                {t('page.order.detail.tab.misc.conclude')}
              </ActionButton>
            }
            deniedExtraCondition={!inMyStoresOrder}
          />
        ) : (
          !order?.paymentReceived && !order?.billNumber && (order?.totalToPay! > 0) ? (
            <ShouldDisable permissions={[RoleAction.ROLE_ACTION_ORDER_TREAT]} deniedExtraCondition={!inMyStoresOrder}>
              <ActionButton
                variant="default"
                icon={InvoiceIcon}
                disabled={order?.entries?.length === 0 || order.status === OrderStatus.Canceled}
                onClick={() => toggleGenerateBillNumberModal(true)}
                isLoading={isAssignBillNumberLoading}
              >
                {t('page.order.detail.tab.misc.generateBillNumber')}
              </ActionButton>
            </ShouldDisable>
          ) : (
            <ShouldDisable permissions={[RoleAction.ROLE_ACTION_ORDER_TREAT]} deniedExtraCondition={!inMyStoresOrder}>
              <ActionButton
                variant="warning"
                icon={ArchiveIcon}
                disabled={
                  order?.entries?.length === 0 ||
                  [OrderStatus.Canceled, OrderStatus.CanceledRequested].includes(order?.status!)
                }
                onClick={() => toggleTreatOrderModal(true)}
                isLoading={editLoading}
              >
                {t('page.order.detail.tab.misc.conclude')}
              </ActionButton>
            </ShouldDisable>
          )
        )}

        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_ORDER_CREATE]} deniedExtraCondition={!inMyStoresOrder}>
          <ActionButton icon={SyncIcon} isLoading={isReorderLoading} onClick={() => toggleReorderModal(true)}>
            {t('page.order.detail.tab.misc.reorder')}
          </ActionButton>
        </ShouldDisable>

        {order.status === OrderStatus.CanceledRequested && (
          <ShouldHide
            permissions={[RoleAction.ROLE_ACTION_ORDER_CANCELREQUEST]}
            deniedExtraCondition={!inMyStoresOrder}
          >
            <WideActionButton onClick={() => toggleCancelCancelRequestModal(true)} variant="danger">
              {t('page.order.detail.tab.misc.cancelCancelRequest')}
            </WideActionButton>
          </ShouldHide>
        )}

        <ShouldDisable permissions={[RoleAction.ROLE_ACTION_ORDER_CANCEL]} deniedExtraCondition={!inMyStoresOrder}>
          <ActionButton
            variant="danger"
            icon={DeleteIcon}
            onClick={() => toggleCancelOrderModal(true)}
            disabled={[OrderStatus.Treated, OrderStatus.Canceled].includes(order?.status!) || order?.invoiced}
          >
            {t('page.order.detail.tab.misc.cancel')}
          </ActionButton>
        </ShouldDisable>
      </ActionButtonsContainer>

      <CancelOrderModal order={order} open={cancelOrderModalOpen} onClose={() => toggleCancelOrderModal(false)} />

      <ConfirmModal
        open={treatOrderModalOpen}
        onClose={() => toggleTreatOrderModal(false)}
        callback={() => editOrderMutation({ status: OrderStatus.Treated })}
        title={t('page.order.detail.modals.treatOrderTitle', { referenceNumber: order?.referenceNumber })}
        confirmLabel={t('page.order.detail.orderEntries.buttons.conclude')}
        confirmBtnProps={{
          variant: 'warning'
        }}
      />

      <ConfirmModal
        open={generateBillNumberModalOpen}
        onClose={() => toggleGenerateBillNumberModal(false)}
        callback={() => assignBillNumberMutation(order?.id!)}
        title={t('page.order.detail.modals.generateBillNumberTitle', { referenceNumber: order?.referenceNumber })}
        confirmLabel={t('page.order.detail.tab.misc.generateBillNumber')}
        confirmBtnProps={{
          variant: 'default'
        }}
      />

      <ConfirmModal
        open={cancelTreatedModalOpen}
        onClose={() => toggleCancelTreatedModal(false)}
        callback={() => {
          cancelOrderTreatedMutation(order?.id!)
        }}
        title={t('page.order.detail.modals.cancelTreatOrderTitle', { referenceNumber: order?.referenceNumber })}
        confirmLabel={t('page.stock.detail.action.terminated.cancel')}
      >
        <>
          {orderPoint > 0 &&
            (isFetching ? (
              <Loader />
            ) : (
              <ModalAlertText>
                {fidelityPointCount >= orderPoint ? (
                  t('page.order.detail.modals.body.cancelTreatOrder.body1', { orderPoint: orderPoint })
                ) : (
                  <Trans
                    i18nKey="page.order.detail.modals.body.cancelTreatOrder.body2"
                    components={{ error: <span /> }}
                    values={{ orderPoint: orderPoint, customerPoints: fidelityPointCount }}
                  />
                )}
              </ModalAlertText>
            ))}
        </>
      </ConfirmModal>

      <ConfirmModal
        open={reorderModalOpen}
        onClose={() => toggleReorderModal(false)}
        title={t('page.order.detail.modals.reorderTitle')}
        confirmLabel={t('page.order.detail.tab.misc.reorder')}
        callback={() => reorderMutation(order?.id!)}
        confirmBtnProps={{
          variant: 'default'
        }}
      />

      <ConfirmModal
        open={cancelCancelRequetModalOpen}
        onClose={() => toggleCancelCancelRequestModal(false)}
        title={t('page.order.detail.modals.cancelCancelRequestTitle')}
        confirmLabel={t('page.order.detail.tab.misc.cancelCancelRequest')}
        callback={() => editOrderMutation({ cancelRequested: false })}
      />
    </>
  )
}
