import styled from 'styled-components'
import { Text } from '../Text/Text.styles'

export const TitleText = styled(Text)`
  margin-bottom: 1rem;
`

export const TooltipColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
