import { yupResolver } from '@hookform/resolvers/yup'
import {
  addOrderPayment,
  AddOrderPaymentQueryParams,
  GetOrderByIdResponse,
  orderByIdQueryKey,
  removeOrderPayment,
  RemoveOrderPaymentQueryParams
} from 'api/orders'
import { useCustomerVouchers } from 'api/vouchers'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import FiltersList, { Filter } from 'components/FiltersList/FiltersList'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import { FormattedNumberComponent } from 'components/InputFormattedNumber/InputFormattedNumber'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import { SaveIcon } from 'constants/icons'
import { useGetPaymentModeLabel } from 'hooks/entityHooks/paymentModeHooks'
import { useMutation } from 'hooks/useAxiosMutation'
import { usePaymentModeFilters } from 'hooks/useSelectOptions'
import _ from 'lodash'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { PaymentModes, UnmodifiableOrderStatuses } from 'types/enums'
import { getIdFromIri } from 'utils/queryParams'
import * as yup from 'yup'
import {
  AmountInput,
  OverviewTextContainer,
  OverviewTextRow,
  PaymentModeCardFormContainer,
  PaymentModeFilter,
  PaymentModeInputRow,
  PaymentModesLabelStyled
} from './PaymentModeCard.style'

interface PaymentModeCardProps {
  order?: GetOrderByIdResponse
}

interface PaymentModeFormInputs {
  amount: number | null
  paymentMode: string // Most likely refers to `PaymentMode.['@id']`
}

const schema = yup.object().shape({
  paymentMode: yup.string().trim().required(),
  amount: yup.number().nullable(true)
})

export default function PaymentModeCard({ order }: PaymentModeCardProps) {
  const { t } = useTranslation()

  const { data: customerVouchers, refetch } = useCustomerVouchers(order?.customer?.id)

  const voucherTotal = _.sum(customerVouchers?.map((el) => Number(el.value)))

  const { paymentModes } = usePaymentModeFilters()
  const { getPaymentModeLabel } = useGetPaymentModeLabel()

  const paymentModeOptions = useMemo(() => {
    return (
      _.sortBy(
        paymentModes?.map((paymentMode) => ({
          value: paymentMode['@id']!,
          label: getPaymentModeLabel(paymentMode.codeName),
          disabled: paymentMode.codeName === PaymentModes.Voucher && voucherTotal === 0
        })),
        'label'
      ) ?? []
    )
  }, [paymentModes, voucherTotal, getPaymentModeLabel])

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<PaymentModeFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)

  const { mutate: addPayment, isLoading: isAddLoading } = useMutation(
    (params: AddOrderPaymentQueryParams) => addOrderPayment(params),
    {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, { ...prev, ...data })
        reset()
        refetch()
      }
    }
  )

  const { mutate: removePayment } = useMutation((params: RemoveOrderPaymentQueryParams) => removeOrderPayment(params), {
    onSuccess: (updatedOrder) => {
      queryClient.setQueryData<Order>(key, (prev) => ({
        ...prev,
        ...updatedOrder,
        paidAt: updatedOrder.paidAt ?? null
      }))
      refetch()
    }
  })

  const onSubmit = ({ paymentMode, amount }: PaymentModeFormInputs) => {
    addPayment({
      id: order?.id?.toString()!,
      paymentMode: getIdFromIri(paymentMode),
      value: amount ? amount.toFixed(2) : order?.leftToPay?.toFixed(2)!
    })
  }

  const onRemoveFilter = (filter: Filter) => {
    removePayment({
      id: order?.id?.toString()!,
      paymentMode: filter.filterId?.toString()!,
      value: (filter.value as number)?.toFixed(2)!
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title2>{t('page.order.detail.tab.payment.paymentModesTitle')}</Title2>
        <PaymentModeCardFormContainer>
          <div>
            <PaymentModesLabelStyled>
              {t('common.label.paymentMethod')}
              <MultiLineTooltip
                id={'customerLitigationsTooltip'}
                lines={[
                  t('page.order.detail.tab.payment.paymentModeTooltipFirst'),
                  t('page.order.detail.tab.payment.paymentModeTooltipSecond'),
                  t('page.order.detail.tab.payment.paymentModeTooltipThird')
                ]}
              />
            </PaymentModesLabelStyled>
            <PaymentModeInputRow>
              <AmountInput
                className={classnames({ 'is-invalid': errors.amount })}
                decimalScale={2}
                control={control}
                suffix=" €"
                id="amount"
              />
              <Select
                placeholder={t('common.select.defaultOptions.paymentMode')}
                options={paymentModeOptions}
                id="paymentMode"
                control={control}
                enableUnselect
                className={classnames({ 'is-invalid': errors.paymentMode })}
              />
            </PaymentModeInputRow>
            <BasicFieldValidationError
              error={errors['paymentMode']}
              message={t('page.order.detail.tab.payment.requiredPaymentMode')}
            />
          </div>
          <OverviewTextContainer>
            <OverviewTextRow>
              <Text
                fontWeight="light"
                fontStyle="italic"
                color={!!order?.paymentModes?.payments?.length && order?.leftToPay !== 0 ? 'danger' : 'secondary'}
              >
                {t('common.label.leftToPay')}
              </Text>
              <FormattedNumberComponent
                fontWeight="light"
                fontStyle="italic"
                color={!!order?.paymentModes?.payments?.length && order?.leftToPay !== 0 ? 'danger' : 'secondary'}
                value={order?.leftToPay}
                suffix=" €"
                decimalScale={2}
              />
            </OverviewTextRow>
            <OverviewTextRow>
              <Text fontWeight="light" fontStyle="italic" color="secondary">
                {t('page.order.detail.tab.payment.totalVouchers')}
              </Text>
              <FormattedNumberComponent
                fontWeight="light"
                fontStyle="italic"
                color="secondary"
                value={voucherTotal}
                suffix=" €"
                decimalScale={2}
              />
            </OverviewTextRow>
          </OverviewTextContainer>
          <Button
            buttonType="submit"
            icon={SaveIcon}
            shape="circle"
            disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
            isLoading={isAddLoading}
          />
        </PaymentModeCardFormContainer>
      </form>
      <FiltersList
        showClearAll={false}
        filters={
          order?.paymentModes?.payments?.map((paymentMode, index) => ({
            id: 'paymentMode',
            value: paymentMode.value,
            filterId: `${paymentMode?.orderPaymentId!}-${index}`,
            label: (
              <PaymentModeFilter>
                <FormattedNumberComponent value={paymentMode.value} suffix=" €" decimalScale={2} />
                <Text fontWeight="regular">{getPaymentModeLabel(paymentMode.name)}</Text>
              </PaymentModeFilter>
            )
          })) ?? []
        }
        disableClick={UnmodifiableOrderStatuses.includes(order?.status!) || !!order?.paybacks?.length}
        onRemoveFilter={onRemoveFilter}
      />
    </>
  )
}
