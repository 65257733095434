import classNames from 'classnames'
import { useRef } from 'react'
import { Path, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import InputLayout, { LayoutProps } from '../Layouts/InputLayout/InputLayout'
import { ButtonContainer, IncrementIcon, StyledInputNumber } from './InputNumber.styles'

export interface InputNumberProps<T> extends LayoutProps {
  id: Path<T>
  disabled?: boolean
  max?: number
  min?: number
  showArrows?: boolean
  step?: number | string
}

interface ReactHookFormProps<T> {
  register: UseFormRegister<T>
  setValue: UseFormSetValue<T>
  trigger: UseFormTrigger<T>
}

interface Props<T> extends InputNumberProps<T>, ReactHookFormProps<T> {}

function InputNumber<T>({
  className,
  label,
  layout = 'column',
  tooltip,
  id,
  register,
  setValue,
  trigger,
  disabled,
  max,
  showArrows = true,
  min = 0,
  step = 1
}: Props<T>) {
  const { ref, ...registerProps } = register(id)
  const myRef = useRef<HTMLInputElement | null>(null)

  const onClick = (inc: 'up' | 'down') => {
    if (disabled) return

    if (myRef.current && showArrows) {
      if (inc === 'up') {
        myRef.current.stepUp()
      }
      if (inc === 'down') {
        myRef.current.stepDown()
      }

      setValue(id, myRef.current.value as any)
      trigger(id)
      myRef.current.focus()
    }
  }

  return (
    <InputLayout className={className} label={label} layout={layout} tooltip={tooltip} disabled={disabled}>
      <StyledInputNumber
        type="number"
        {...registerProps}
        ref={(currentRef) => {
          ref(currentRef)
          myRef.current = currentRef
        }}
        step={step}
        min={min}
        max={max}
        disabled={disabled}
      />

      {showArrows && (
        <ButtonContainer>
          <IncrementIcon className={classNames({ disabled })} onClick={() => onClick('up')} />
          <IncrementIcon className={classNames('invert', { disabled })} onClick={() => onClick('down')} />
        </ButtonContainer>
      )}
    </InputLayout>
  )
}

export default InputNumber
