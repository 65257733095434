import { Skeleton } from '@mui/material'
import { getSealedProductsUri } from 'api/sealedProducts'
import Button from 'components/Button/Button'
import Image from 'components/Image/Image'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { Option } from 'components/InputAutoComplete/InputAutoComplete.styles'
import { DeleteImage, ImageCard } from 'components/InputFile/InputFile.styles'
import { Text } from 'components/Text/Text.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { CrossIcon, MoreIcon } from 'constants/icons'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import { SortingDirection } from 'types/sorting'
import {
  ProductSelectorInputWrapper,
  ProductSelectorName,
  ProductSelectorWrapper
} from '../form/CollectionCreateForm.style'

interface Props {
  handleProduct: (sealedProduct: SealedProduct | null) => void
  masterProduct: SealedProduct | null
}

type Inputs = {
  product: string
}
export default function ProductSelector({ handleProduct, masterProduct }: Props) {
  const [product, setProduct] = useState<SealedProduct | null>(null)
  const {
    watch,
    control,
    formState: { touchedFields },
    reset
  } = useForm<Inputs>({
    defaultValues: {
      product: ''
    }
  })
  const { t } = useTranslation()

  const onClick = () => {
    reset()
    if (product) handleProduct(product)
  }

  return (
    <ProductSelectorWrapper>
      <ProductSelectorInputWrapper>
        <InputAutoComplete
          getUrlFromSearch={(search) =>
            getSealedProductsUri({
              search,
              'exists[secondHandOf]': false,
              'order[old]': 'asc',
              'order[name]': SortingDirection.Asc
            })
          }
          labelKey="name"
          valueKey="id"
          control={control}
          id="product"
          placeholder={t('common.placeholder.product')}
          onEntitySelected={setProduct}
          isTouched={touchedFields.product}
          label={t('page.collection.create.labels.masterProduct')}
          tooltip={
            <Tooltip id="masterProductTooltip">
              <Trans i18nKey="page.collection.create.tooltips.masterProduct" />
            </Tooltip>
          }
          formatOption={(product: SealedProduct, onClick) => (
            <Option onClick={onClick}>
              <Text fontStyle={product.old ? 'italic' : 'normal'}>
                {product.old ? t('page.stock.detail.products.oldOption', { name: product.name }) : product.name}
              </Text>
            </Option>
          )}
        />
        <Button variant="white" shape="circle" icon={MoreIcon} disabled={!watch('product')} onClick={onClick} />
      </ProductSelectorInputWrapper>

      {masterProduct && (
        <ImageCard>
          <DeleteImage svg={CrossIcon} color="grey4" size="2rem" onClick={() => handleProduct(null)} />

          <Image
            src={masterProduct.mainImageUrl || undefined}
            height={225}
            skeleton={<Skeleton variant="rectangular" width={225} height={225} />}
          />
          <ProductSelectorName>{masterProduct.name}</ProductSelectorName>
        </ImageCard>
      )}
    </ProductSelectorWrapper>
  )
}
