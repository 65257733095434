import { useState } from 'react'
import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from 'react-query'

export const useFetchQuery = <T>(
  queryKey: QueryKey,
  queryFn: QueryFunction<T>,
  options?: Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>
) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false)

  const queryHook = useQuery(queryKey, queryFn, {
    ...options,
    onSuccess: (...args) => {
      setHasBeenCalled(true)
      if (options?.onSuccess) {
        options.onSuccess(...args)
      }
    }
  })

  return {
    ...queryHook,
    hasBeenCalled
  }
}
