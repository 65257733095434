import { FunctionComponent } from 'react'
import { StyledCell } from './SortedTable.styles'

type Props = {
  className?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const Cell: FunctionComponent<Props> = ({ children, className, onMouseEnter, onMouseLeave }) => {
  return (
    <StyledCell className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </StyledCell>
  )
}
export default Cell
