import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const DeleteContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid ${(props) => props.theme.borderColors.secondary};
  margin-top: 3rem;
  padding-top: 4rem;
  align-items: center;
`

export const NoMarginTitle = styled(Title2)`
  margin: 0;
`
