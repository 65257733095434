import ClickableCell from 'components/SortedTable/ClickableCell'
import Svg from 'components/Svg/Svg'
import styled from 'styled-components'

export const SectionCentered = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
`

export const CreateSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const OldIcon = styled(Svg)`
  background: ${(props) => props.theme.colors[props.color ?? 'secondary']};
  width: ${(props) => props.size ?? '1.6rem'};
  height: ${(props) => props.size ?? '1.6rem'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    path {
      fill: white !important;
    }
    width: 1rem;
    height: 1rem;
  }
`

export const PackInfo = styled.span`
  margin-right: 0.5rem;
  font-weight: bold;
`

export const StyledClickableCell = styled(ClickableCell)`
  &.old {
    background: ${(props) => props.theme.backgroundColors.secondary};
  }
`
