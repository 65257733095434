import { getStocksUri, useStocksPaginated } from 'api/stock'
import { SearchForm, useInputSearchForm } from 'components/InputSearchForm/InputSearchForm'
import { StockIcon } from 'constants/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SortingDirection } from 'types/sorting'

export const defaultStockSideBarQp = { 'order[createdAt]': SortingDirection.Desc }

const StockSearchForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { reset, InputSearchForm } = useInputSearchForm()

  const { isFetching } = useStocksPaginated(
    ['stockSidebar', search, defaultStockSideBarQp],
    getStocksUri({ ...defaultStockSideBarQp, search }),
    {
      enabled: search !== '',
      onSuccess: (data) => {
        if (data.length === 1) {
          navigate(`/stock-details/${data[0].id}`)
        } else {
          navigate('/stock-manage', { state: { search } })
        }
        setSearch('')
        reset()
      }
    }
  )

  const onSearch = async (formData: SearchForm) => {
    if (!formData.value.trim()) {
      navigate('/stock-manage')
    } else {
      setSearch(formData.value)
    }
  }

  return (
    <InputSearchForm
      svg={StockIcon}
      text={t('inputSearchForm.tooltip.stock')}
      isLoading={isFetching}
      onSearch={onSearch}
      title={t('inputSearchForm.tooltip.stockTitle')}
    />
  )
}

export default StockSearchForm
