import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'

export type FetchCardTypesParams = Api.GetCardTypes.RequestQuery
const getCardTypesUri = (params?: FetchCardTypesParams) => {
  const qs = stringify(params ?? {})
  return [API_ROUTES.cardTypes.root, qs].join('?')
}

type FetchCardTypesResponse = Api.GetCardTypes.ResponseBody['hydra:member'][0]
export const usePaginatedCardTypes = (
  queryKey: QueryKey,
  params: FetchCardTypesParams,
  options?: PaginatedHookOptions<FetchCardTypesResponse>
) => usePaginatedQuery<FetchCardTypesResponse>(queryKey, getCardTypesUri(params), options)
