import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { StyledVerticalSeparator } from 'components/Separator/Separator.style'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import Link from 'features/Permissions/Link/Link'
import styled from 'styled-components'

export const UnspacedTitle2 = styled(Title2)`
  margin-top: 0;
  margin-bottom: 0;
  display: inline;
`

export const RelayRecipientTitle2 = styled.span`
  margin-left: 0.2rem;
  color: ${(props) => props.theme.textColors.yellow};
`

export const AddressSectionContainer = styled(RowContainer)`
  align-items: stretch;
  margin-top: 2rem;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    flex-direction: column;
    gap: 1rem;

    & > * {
      gap: 1rem;
    }
  }
`

export const ShippingTitleContainer = styled(RowContainer)`
  gap: 0.8rem;
  margin-bottom: 0.2rem;
`

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.textColors.darkPurple};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`

export const HorizontalSeparator = styled(StyledVerticalSeparator)`
  margin: 0 1rem;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    display: none;
  }
`

export const ExternalOrderTitleText = styled(Title2)`
  margin-top: auto;
  margin-bottom: auto;
  color: ${(props) => props.theme.textColors[props.color || 'black']};

  :hover {
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.textColors[props.color || 'black']};
  }
`

export const LinkedOrderSubTitleText = styled(Text)`
  & > *:not(:last-child):after {
    margin-right: 0.4rem;
    content: ',';
  }
`
