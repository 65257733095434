import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'

export type FetchOrderPrintsQueryParams = Api.GetOrderPrints.RequestQuery
type GetOrderPrintResponse = Api.GetOrderPrints.ResponseBody

const getOrderPrintsUri = (params: FetchOrderPrintsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.orderPrints.root, queryString].join('?')
}

export const useLastOrderPrintsUpToFifteenDaysAgo = (
  params: FetchOrderPrintsQueryParams,
  options?: PaginatedHookOptions<GetOrderPrintResponse['hydra:member'][0]>
) => {
  return usePaginatedQuery<GetOrderPrintResponse['hydra:member'][0]>(
    ['orders:last-15-days', params],
    getOrderPrintsUri({
      'printedAt[before]': 'now',
      'printedAt[after]': '15 days ago',
      ...params
    }),
    options
  )
}
