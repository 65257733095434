import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import classNames from 'classnames'
import { getYear } from 'date-fns'
import frLocale from 'date-fns/locale/fr'
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { InputTextStyle } from '../Input/Input.styles'
import InputLayout, { LayoutProps } from '../Layouts/InputLayout/InputLayout'
import { InputContainer } from './DatePicker.styles'
interface InputProps<T extends FieldValues> {
  inputRef: MutableRefObject<HTMLInputElement | null>
  value?: string
  onChange: (date: Date | null) => void
  calendar?: ReactNode
  id: Path<T>
  register: UseFormRegister<T>
  trigger?: any
  disabled?: boolean
  autofocus?: boolean
}

const getFormattedDay = (day: string): string => {
  if (day?.length === 1) day = '0' + day
  return parseInt(day) > 31 ? '31' : day
}

const getFormattedMonth = (month: string): string => {
  if (month?.length === 1) month = '0' + month
  return parseInt(month) > 12 ? '12' : month
}

const getFormattedYear = (year: string): string => {
  switch (year?.length) {
    case 2:
      return '20' + year
    case 4:
      return year
    default:
      return `${getYear(new Date())}`
  }
}

function Input<T extends FieldValues>({
  inputRef,
  value,
  onChange,
  calendar,
  id,
  trigger,
  disabled,
  autofocus
}: InputProps<T>) {
  // The date with format dd/mm/yyyy
  const [fieldValue, setFieldValue] = useState(value)

  // When we pick from Calendar, value change, we update the input value
  useEffect(() => {
    setFieldValue(value)
  }, [value])

  // onBlur, we reset the input with current Calendar state value.
  // And we trigger a from validation for the input
  const handleBlur = (fieldValue?: string) => {
    const [day, month, year] = fieldValue?.split('/') ?? []
    const formattedDay = getFormattedDay(day)
    const formattedMonth = getFormattedMonth(month)
    const formattedYear = getFormattedYear(year)

    if ([formattedDay, formattedMonth, formattedYear].join('/')?.match(/(\d{2})\/(\d{2})\/(\d{4}|\d{2})/)) {
      onChange(new Date(parseInt(formattedYear), parseInt(formattedMonth) - 1, parseInt(formattedDay)))
    }

    trigger(id)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Filter for digits and / only
    const arr = e.target.value.split('').filter((char) => char.match(/^(\d|\/)$/))
    const currentIndex = e.target.selectionStart ?? 0
    const lastTyped = arr[currentIndex - 1]
    const allowedSlashIndex = [2, 5]

    // Avoid "/" missplacement
    if (lastTyped === '/' && !allowedSlashIndex.includes(currentIndex - 1)) {
      return
    }

    // after two digits (dd) we push / automaticly
    if (arr.length === 2 && fieldValue?.length === 1 && lastTyped !== '/') {
      arr.push('/')
    }

    // after 5 digits (dd/mm) we push / automaticly
    if (arr.length === 5 && fieldValue?.length === 4 && lastTyped !== '/') {
      arr.push('/')
    }

    // we adjust the / position, in case user remove the /
    if (arr.length === 6 && fieldValue?.length === 5) {
      arr.splice(5, 0, '/')
    }

    const newDate = arr.splice(0, 10).join('') //avoid using too much digit

    // if the use reset the fields
    if (newDate === '') {
      onChange(null)
      trigger(id)
    }

    if (newDate.length === 10) {
      handleBlur(newDate)
      trigger(id)
    }

    // validate date format dd/mm/yyyy
    setFieldValue(newDate)
  }

  return (
    <InputContainer className={classNames({ disabled })}>
      <InputTextStyle
        ref={inputRef}
        value={fieldValue}
        onChange={handleChange}
        onBlur={() => handleBlur(fieldValue)}
        placeholder="jj/mm/aaaa"
        autoFocus={autofocus}
      />
      {calendar}
    </InputContainer>
  )
}

export interface DatePickerProps<T> extends LayoutProps {
  id: Path<T>
  defaultValue?: any
  autofocus?: boolean
}

interface ReactHookFormProps<T extends FieldValues> {
  register: UseFormRegister<T>
  setValue: any
  trigger: any
}

interface Props<T extends FieldValues> extends DatePickerProps<T>, ReactHookFormProps<T> {}

const theme = createTheme({
  typography: {
    htmlFontSize: 10
  }
})

// To reset the field,
// use getValues from react-hook-form to provride defaultValue
export default function DatePicker<T extends FieldValues>({
  className,
  label,
  tooltip,
  layout,
  id,
  register, // function from react-hook-form
  setValue, // function from react-hook-form
  defaultValue, // function from react-hook-form
  trigger, // function from react-hook-form
  disabled,
  autofocus
}: Props<T>) {
  const [date, setDate] = useState<Date | null>(new Date(defaultValue))
  const datepickerRef = useRef<HTMLInputElement | null>(null)

  // When a change is fired from either Calendar or Input component
  // We sync both values
  const onChange = (newValue: Date | null) => {
    setDate(newValue)
    setValue(id, newValue?.toLocaleDateString('en-EN'))
  }

  useEffect(() => {
    setDate(defaultValue !== '' ? defaultValue : null)
  }, [defaultValue])

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
        <DesktopDatePicker
          value={date}
          onChange={(e) => {
            onChange(e)
            trigger(id)
          }}
          // we need setTimeout, to make datepickerRef focus occurs after "the calendar button" focus on close
          onClose={() => setTimeout(() => datepickerRef.current?.focus())}
          renderInput={({ inputRef, inputProps, InputProps }) => {
            return (
              <InputLayout
                inputRef={inputRef} // this ref is used to anchor popin calendar to InputLayout
                className={className}
                label={label}
                layout={layout}
                tooltip={tooltip}
                disabled={disabled}
              >
                <Input
                  inputRef={datepickerRef}
                  value={inputProps?.value}
                  calendar={InputProps?.endAdornment}
                  onChange={onChange}
                  register={register}
                  id={id}
                  trigger={trigger}
                  disabled={disabled}
                  autofocus={autofocus}
                />
              </InputLayout>
            )
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  )
}
