import { useEffect, useState } from 'react'

type Position = {
  mousePosX: number
  mousePosY: number
}

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState<Position>({ mousePosX: 0, mousePosY: 0 })

  useEffect(() => {
    const updateMousePosition = (mouseEvent: MouseEvent) => {
      setMousePosition({
        mousePosX: mouseEvent.pageX,
        mousePosY: mouseEvent.pageY
      })
    }

    window.addEventListener('mousemove', updateMousePosition)

    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
    }
  }, [])

  return mousePosition
}
