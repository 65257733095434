import {
  deleteStoreExceptionalHours,
  postStoreExceptionalHours,
  PostStoreExceptionalHoursBody,
  storeByIdQueryKey
} from 'api/stores'
import { default as classNames, default as classnames } from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import DatePicker from 'components/DatePicker/DatePicker'
import { LabelStyled } from 'components/Layouts/InputLayout/InputLayout.styles'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import TimePicker from 'components/TimePicker/TimePicker'
import Tooltip from 'components/Tooltip/Tooltip'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon, MoreIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { useState } from 'react'
import { FieldArrayWithId, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { StoreDetails, StoreExceptionalHours } from 'types/entities'
import { formatCalendarDate, hourFormat } from 'utils/dates'
import useSchedule from '../hooks/useSchedule'
import {
  AddButton,
  AfternoonSchedule,
  DivStoreExceptionalHours,
  FormScheduleSection,
  MorningSchedule,
  ScheduleRow,
  ScheduleWrapper,
  StoreExceptionalHoursAction,
  StoreExceptionalHoursRow,
  StoreExceptionalHoursSetter
} from '../StoreInformationForm.style'
import { StoreInformationsInputs } from '../StoreInformationsForm'

interface Props {
  store: StoreDetails
  timeFields: FieldArrayWithId<StoreInformationsInputs, 'days', 'id'>[]
}

export default function StoreSchedule({ timeFields, store }: Props) {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    watch,
    register,
    trigger,
    setValue
  } = useFormContext()

  const [selectedExceptionalHours, setExceptionalHours] = useState<number[]>([])

  const { getDayByIndex } = useSchedule()

  const queryClient = useQueryClient()

  const { mutate: postExceptionalHoursMutation, isLoading: postExceptionalHoursMutationLoading } = useMutation(
    (body: PostStoreExceptionalHoursBody) => postStoreExceptionalHours(body),
    {
      onSuccess: () => {
        queryClient.refetchQueries(storeByIdQueryKey(store.id!))
      }
    }
  )

  const { mutate: deleteExceptionalHoursMutation, isLoading: deleteExceptionalHoursMutationLoading } = useMutation(
    (id: number | undefined) => deleteStoreExceptionalHours(id),
    {
      onSuccess: () => {
        queryClient.refetchQueries(storeByIdQueryKey(store.id!))
      }
    }
  )

  const formatedExceptionalHoursRow = (opensAt, closesAt, opensAfternoonAt, closesAfternoonAt) => {
    if (!!opensAt && !!closesAt) {
      if (!!opensAfternoonAt && !!closesAfternoonAt) {
        return (
          hourFormat(opensAt) +
          ' - ' +
          hourFormat(closesAt) +
          ' ' +
          hourFormat(opensAfternoonAt) +
          ' - ' +
          hourFormat(closesAfternoonAt)
        )
      } else {
        return hourFormat(opensAt) + ' - ' + hourFormat(closesAt)
      }
    } else {
      return '---'
    }
  }

  const exceptionalDate = watch('exceptionalDate')
  const morningOpensAt = watch('morningOpensAt')
  const morningClosesAt = watch('morningClosesAt')
  const afternoonOpenAt = watch('afternoonOpenAt')
  const afternoonClosesAt = watch('afternoonClosesAt')

  const handleAddExceptionalHours = () => {
    postExceptionalHoursMutation({
      store: store!['@id']!,
      exceptionalDate: exceptionalDate,
      opensAt: morningOpensAt,
      closesAt: morningClosesAt,
      opensAfternoonAt: afternoonOpenAt,
      closesAfternoonAt: afternoonClosesAt,
      isOpen: morningOpensAt && morningClosesAt ? true : false
    })
  }

  const handleDeleteExceptionalHours = () => {
    selectedExceptionalHours?.map((entry) => deleteExceptionalHoursMutation(entry))
  }

  const columns: Column<StoreExceptionalHours>[] = [
    {
      key: 'checkboxDelete',
      decorator: (storeExceptionalHours: StoreExceptionalHours) => (
        <Checkbox
          className={classnames({ checked: selectedExceptionalHours?.includes(storeExceptionalHours.id!) })}
          onClick={() => setExceptionalHours((prev) => _.xor(prev, [storeExceptionalHours.id!]))}
        />
      )
    },
    {
      key: 'exceptionalDay',
      name: t('page.stores.details.labels.exceptionalDays'),
      decorator: (storeExceptionalHours: StoreExceptionalHours) => (
        <StoreExceptionalHoursRow>{formatCalendarDate(storeExceptionalHours.exceptionalDate)}</StoreExceptionalHoursRow>
      )
    },
    {
      key: 'exceptionalHours',
      name: t('page.stores.details.labels.exceptionalHours'),
      decorator: (storeExceptionalHours: StoreExceptionalHours) => (
        <StoreExceptionalHoursRow>
          {formatedExceptionalHoursRow(
            storeExceptionalHours.opensAt,
            storeExceptionalHours.closesAt,
            storeExceptionalHours.opensAfternoonAt,
            storeExceptionalHours.closesAfternoonAt
          )}
        </StoreExceptionalHoursRow>
      )
    },
    {
      key: 'exceptionalOpeningClosing',
      name: t('page.stores.details.labels.exceptionalOpeningClosing'),
      decorator: (storeExceptionalHours: StoreExceptionalHours) => (
        <StoreExceptionalHoursRow>
          {!!storeExceptionalHours.opensAt && !!storeExceptionalHours.closesAt
            ? t('page.stores.details.labels.Opening')
            : t('page.stores.details.labels.Closing')}
        </StoreExceptionalHoursRow>
      )
    }
  ]

  return (
    <FormScheduleSection>
      <ScheduleWrapper>
        <LabelStyled>{t('page.stores.details.labels.schedule')}</LabelStyled>

        {timeFields.map((day, index) => {
          const [watchMorningOpensAt, watchMorningClosesAt, watchAfternoonOpensAt, watchAfternoonClosesAt] = watch([
            `days.${index}.morningOpensAt`,
            `days.${index}.morningClosesAt`,
            `days.${index}.afternoonOpensAt`,
            `days.${index}.afternoonClosesAt`
          ])
          const disabledAfternoon =
            (!watchMorningOpensAt || !watchMorningClosesAt) && !watchAfternoonClosesAt && !watchAfternoonOpensAt

          return (
            <ScheduleRow key={`day-${day.id}`}>
              <Text>{getDayByIndex(index)}</Text>
              <MorningSchedule>
                <TimePicker
                  control={control}
                  id={`days.${index}.morningOpensAt`}
                  className={classNames({
                    'is-invalid': errors?.days?.[index]?.morningOpensAt
                  })}
                />
                <TimePicker
                  control={control}
                  id={`days.${index}.morningClosesAt`}
                  className={classNames({
                    'is-invalid': errors?.days?.[index]?.morningClosesAt
                  })}
                />
              </MorningSchedule>
              <AfternoonSchedule>
                <TimePicker
                  control={control}
                  id={`days.${index}.afternoonOpensAt`}
                  className={classNames({
                    'is-invalid': errors?.days?.[index]?.afternoonOpensAt
                  })}
                  disabled={disabledAfternoon}
                />
                <TimePicker
                  control={control}
                  id={`days.${index}.afternoonClosesAt`}
                  className={classNames({
                    'is-invalid': errors?.days?.[index]?.afternoonClosesAt
                  })}
                  disabled={disabledAfternoon}
                />
              </AfternoonSchedule>
            </ScheduleRow>
          )
        })}
      </ScheduleWrapper>
      <div>
        <LabelStyled>
          {t('page.stores.details.labels.exceptionalOpeningLabel')}
          <Tooltip id="exceptionalOpeningDescriptionTooltip">
            <Trans i18nKey="page.stores.details.tooltips.exceptionalHoursDescription" />
          </Tooltip>
        </LabelStyled>
        <StoreExceptionalHoursRow>
          <StoreExceptionalHoursSetter>
            <DatePicker
              id="exceptionalDate"
              register={register}
              setValue={setValue}
              trigger={trigger}
              defaultValue=""
              label={t('page.stores.details.labels.exceptionalDays')}
            />
            <DivStoreExceptionalHours>
              <TimePicker
                control={control}
                id="morningOpensAt"
                label={t('page.stores.details.labels.exceptionalHours')}
              />
              <TimePicker control={control} id="morningClosesAt" label={' '} />
            </DivStoreExceptionalHours>
            <DivStoreExceptionalHours>
              <TimePicker control={control} id="afternoonOpenAt" label={' '} />
              <TimePicker control={control} id="afternoonClosesAt" label={' '} />
            </DivStoreExceptionalHours>
            <AddButton
              shape="circle"
              icon={MoreIcon}
              iconColor="white"
              onClick={handleAddExceptionalHours}
              isLoading={postExceptionalHoursMutationLoading}
            />
          </StoreExceptionalHoursSetter>
        </StoreExceptionalHoursRow>

        {typeof store.exceptionalHours !== 'undefined' && (
          <>
            <StoreExceptionalHoursRow>
              <StoreExceptionalHoursAction>
                <Checkbox
                  onClick={() =>
                    setExceptionalHours(
                      (prev) =>
                        (prev.length === store.exceptionalHours?.length
                          ? []
                          : store.exceptionalHours?.map((e) => e.id!)) ?? []
                    )
                  }
                  className={classNames({
                    checked:
                      !!store.exceptionalHours?.length &&
                      selectedExceptionalHours.length === store.exceptionalHours?.length
                  })}
                />
                <TooltipHover
                  id="delete"
                  content={<Text>{t('page.stores.details.labels.deleteExceptionalHours')}</Text>}
                >
                  <Button
                    shape="circle"
                    variant="white"
                    icon={DeleteIcon}
                    onClick={handleDeleteExceptionalHours}
                    isLoading={deleteExceptionalHoursMutationLoading}
                  />
                </TooltipHover>
              </StoreExceptionalHoursAction>
            </StoreExceptionalHoursRow>
            <Listing
              data={store.exceptionalHours}
              columns={columns}
              emptyText=""
              totalCount={store.exceptionalHours.length}
            />
          </>
        )}
      </div>
    </FormScheduleSection>
  )
}
