import classnames from 'classnames'
import { StyledInputLayout, StyledLoader } from 'components/InputAutoComplete/InputAutoComplete.styles'
import { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import Loader from 'components/Loader/Loader'
import { Text } from 'components/Text/Text.styles'
import { useClickAway } from 'hooks/useClickAway'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Control, Controller, FieldPathValue, FieldValues, Path, UnpackNestedValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import {
  InputContainer,
  OpenIcon,
  OptionElement,
  PlaceholderLabel,
  SelectContainer,
  SelectedLabel,
  StyledOptions
} from './Select.styles'

export type Option = {
  label: string | JSX.Element
  value: string | number | boolean | undefined
  disabled?: boolean
}

interface ReactHookFormProps<T extends FieldValues> {
  id: Path<T>
  control: Control<T>
}

export interface Props<T extends FieldValues> extends ReactHookFormProps<T>, LayoutProps, SelectComponentProps {
  autoOpen?: boolean
}

function Select<T extends FieldValues>({
  id,
  control,
  className,
  label,
  tooltip,
  layout,
  disabled,
  placeholder,
  options,
  emptyOptionLabel,
  enableUnselect,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  autoOpen = false,
  labelComponent,
  isLoading
}: Props<T>) {
  const [displayOptions, setDisplayOptions] = useState(false)
  const [selectOptions, setSelectOptions] = useState<Option[]>([])

  useEffect(() => {
    if (!_.isEqual(_.omit(options, ['label']), _.omit(selectOptions, ['label']))) {
      setSelectOptions(options)
      //We don't want to hide options on new data if autoOpen is false
      if (autoOpen) setDisplayOptions(autoOpen)
    }
  }, [options, selectOptions, autoOpen])

  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => {
        const { value, onBlur, onChange } = field

        return (
          <StyledInputLayout
            className={className}
            label={label}
            layout={layout}
            tooltip={tooltip}
            disabled={disabled}
            labelComponent={labelComponent}
          >
            <SelectComponent
              value={value}
              options={selectOptions}
              placeholder={placeholder}
              disabled={disabled}
              enableUnselect={enableUnselect}
              displayOptions={displayOptions}
              setDisplayOptions={setDisplayOptions}
              emptyOptionLabel={emptyOptionLabel}
              onBlur={onBlur}
              onChange={onChange}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
            />
          </StyledInputLayout>
        )
      }}
    />
  )
}

type SelectComponentProps = {
  options: Option[]
  placeholder?: string | JSX.Element
  disabled?: boolean
  enableUnselect?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => void
  isFetchingNextPage?: boolean
  emptyOptionLabel?: string
  isLoading?: boolean
}

type ControlledProps<T extends FieldValues> = SelectComponentProps & {
  value: UnpackNestedValue<FieldPathValue<T, Path<T>>>
  onChange: (value: Option['value']) => void
  onBlur: () => void
  displayOptions: boolean
  setDisplayOptions: Dispatch<SetStateAction<boolean>>
}

function SelectComponent<T extends FieldValues>({
  value,
  options,
  placeholder = '',
  disabled,
  enableUnselect = false,
  displayOptions,
  emptyOptionLabel,
  setDisplayOptions,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  onChange,
  onBlur,
  isLoading
}: ControlledProps<T>) {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<Option['label']>('')
  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === value)?.label ?? '')
  }, [options, value])

  // On ClickAway, we close the select panel
  // And we trigger field validation with onBlur
  const { ref } = useClickAway(() => {
    if (displayOptions) {
      setDisplayOptions(false)
      onBlur()
    }
  })

  const { ref: infiniteScrollRef, inView } = useInView({
    threshold: 1 // Trigger component is in view when fully visible
  })

  useEffect(() => {
    if (inView && hasNextPage && fetchNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage])

  const onOptionClick = (event: React.MouseEvent, option: Option) => {
    event.stopPropagation()
    onChange(option.value)
    onBlur()
    setSelectedOption(option.label)
    setDisplayOptions(false)
  }

  return (
    <SelectContainer ref={ref}>
      <InputContainer
        onClick={() => {
          return setDisplayOptions((prev) => !prev)
        }}
      >
        {isLoading ? (
          <Loader />
        ) : placeholder && !selectedOption ? (
          <PlaceholderLabel>{placeholder}</PlaceholderLabel>
        ) : (
          <SelectedLabel>{selectedOption}</SelectedLabel>
        )}

        <OpenIcon className={displayOptions && !disabled ? 'open' : 'close'} />
      </InputContainer>
      <StyledOptions className={displayOptions && !disabled ? 'open' : 'close'}>
        {displayOptions && enableUnselect && selectedOption && (
          <OptionElement onClick={(e) => onOptionClick(e, { value: '', label: '' })} className="header">
            <PlaceholderLabel>{t('common.select.unselect')}</PlaceholderLabel>
          </OptionElement>
        )}
        {displayOptions && options.length === 0 && (
          <OptionElement className="empty">
            <Text color="danger">{emptyOptionLabel || t('common.select.defaultOptions.emptyOptions')}</Text>
          </OptionElement>
        )}
        {displayOptions &&
          options.map((option, index) => {
            return (
              <OptionElement
                className={classnames({ disabled: option.disabled })}
                key={index}
                onClick={(event) => onOptionClick(event, option)}
                ref={options.length === index + 1 ? infiniteScrollRef : undefined}
              >
                {option.label}
              </OptionElement>
            )
          })}
        <div>{isFetchingNextPage && <StyledLoader />}</div>
      </StyledOptions>
    </SelectContainer>
  )
}

export default Select
