import { yupResolver } from '@hookform/resolvers/yup'
import { useDownloadFile } from 'api/basePlayInApi'
import { countPurchaseOrders } from 'api/orders'
import { PreviewCounterText } from 'app/OrderPrint/utils.style'
import Button from 'components/Button/Button'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import Loader from 'components/Loader/Loader'
import Select from 'components/Select/Select'
import { TooltipCol } from 'components/Tooltip/Tooltip.styles'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon } from 'constants/icons'
import { useEmit } from 'hooks/useEventEmitter'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { SealedProduct, Store } from 'types/entities'
import { EventType } from 'types/events'
import { stringify } from 'utils/queryParams'
import * as yup from 'yup'
import { Shipping } from '../../types'
import { PreviewButtonContainer } from '../../utils.style'
import ProductsSelector from './components/ProductsSelector'
import { FieldsWrapper, PreorderLayoutForm, WarehouseFieldsWrapper } from './otherProductsTab.style'
import SearchByEditionForm from './searchForms/SearchByEditionForm'
import SearchByReleaseDateForm from './searchForms/SearchByReleaseDateForm'
import SearchProductForm from './searchForms/SearchProductForm'

interface Props {
  store: Store
  active: boolean
}

export type SearchQuery = {
  isLoading?: boolean
  isFetched?: boolean
}

export type WarehousePrintFormInput = {
  selectedProductOnly: boolean
  singleProductOrder: boolean
  shippingMode: string | null
  withCards?: boolean | null
}

const schema = yup.object().shape({
  shippingMode: yup.string().nullable(),
  selectedProductOnly: yup.boolean(),
  singleProductOrder: yup.boolean(),
  withCards: yup.boolean().nullable()
})

const PreorderTooltip = (
  <TooltipCol>
    <Trans i18nKey="page.order.print.otherProduct.tooltips.warehousePreorder" components={{ line: <span /> }} />
  </TooltipCol>
)

const getDefaultValues = (store: Store): Partial<WarehousePrintFormInput> => ({
  shippingMode: store.warehouse ? null : Shipping.ClickAndCollectStore,
  withCards: store.warehouse ? false : null
})

const OtherPreoductPreorderForm = ({ store, active }: Props) => {
  const { t } = useTranslation()
  const { downloadFile, isLoading: downloading } = useDownloadFile()

  const [productsList, setProductsList] = useState<SealedProduct[]>([])
  const [selectedProducts, setSelectedProducts] = useState<number[]>([])

  const [productsFromAutocomplete, setProductsFromAutocomplete] = useState<SealedProduct[]>([])

  const [searchFromEditionStatus, setSearchFromEditionStatus] = useState<SearchQuery>({})
  const [productsFromReleaseDate, setProductsFromReleaseDate] = useState<SealedProduct[]>([])
  const { isLoading: fromEditionLoading, isFetched: fromEditionFetched } = searchFromEditionStatus

  const [searchFromReleaseDateStatus, setSearchFromReleaseDateStatus] = useState<SearchQuery>({})
  const { isLoading: fromReleaseDateLoading, isFetched: fromReleaseDateFetched } = searchFromReleaseDateStatus
  const [productsFromEdition, setProductsFromEdition] = useState<SealedProduct[]>([])

  const isLoading = fromEditionLoading || fromReleaseDateLoading
  const isFetched = fromEditionFetched || fromReleaseDateFetched

  const handleProducts = useCallback(
    (searchFrom: 'releaseDate' | 'productSearch' | 'edition', searchedProducts?: SealedProduct[]) => {
      if (searchedProducts) {
        // Due to the next step, we need to keep in memory data, sorted by origin
        if (searchFrom === 'releaseDate') setProductsFromReleaseDate(searchedProducts)
        if (searchFrom === 'edition') setProductsFromEdition(searchedProducts)
        if (searchFrom === 'productSearch') {
          setProductsFromAutocomplete((prev) => _.uniqBy([...prev, ...searchedProducts], 'id'))
          if (searchedProducts[0]) {
            setSelectedProducts((prev) => _.uniq([...prev, searchedProducts[0].id!]))
          }
        }

        // We need to set non-duplicate data on array start
        setProductsList((prev) => {
          // Set data which are not supposed to be erased
          let notFromSearch: SealedProduct[] = []

          if (searchFrom === 'releaseDate') notFromSearch = [...productsFromEdition, ...productsFromAutocomplete]
          if (searchFrom === 'edition') notFromSearch = [...productsFromReleaseDate, ...productsFromAutocomplete]
          if (searchFrom === 'productSearch')
            notFromSearch = [...productsFromReleaseDate, ...productsFromEdition, ...productsFromAutocomplete]

          // Find non-duplicate new data
          let newProducts = searchedProducts.filter((product) => !_.some(notFromSearch, { id: product.id }))

          // Keep data from the 2 other search
          let restProducts = prev.filter((product) => _.some(notFromSearch, { id: product.id }))
          return [...newProducts, ...restProducts]
        })
      }
    },
    [productsFromEdition, productsFromReleaseDate, productsFromAutocomplete]
  )

  // Unselect removed products on list update
  useEffect(() => {
    setSelectedProducts((prev) => prev.filter((productId) => _.some(productsList, { id: productId })))
  }, [productsList])

  const shippingOptions = [
    { value: Shipping.BnfClickAndCollect, label: t('page.order.print.details.shippingType.shop.bnf') },
    { value: Shipping.RivoliClickAndCollect, label: t('page.order.print.details.shippingType.shop.rivoli') },
    { value: Shipping.Classic, label: t('page.order.print.details.shippingType.classic') }
  ]

  const { handleSubmit, control, reset, watch } = useForm<WarehousePrintFormInput>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  useEffect(() => {
    reset(getDefaultValues(store))
    setProductsList([])
  }, [store, reset, setProductsList])

  const emit = useEmit()

  const [selectedProductOnly, shippingMode, singleProductOrder, withCards] = watch([
    'selectedProductOnly',
    'shippingMode',
    'singleProductOrder',
    'withCards'
  ])

  const preOrderPayload = useMemo(() => {
    const releasedDateMax = _.chain(productsList)
      .filter((product) => selectedProducts.includes(product.id!))
      .orderBy('releasedAt', 'desc')
      .head()
      .get('releasedAt')
      .value()

    const params: {
      preorder: boolean
      withCards?: boolean
      name?: string
      'notClickAndCollect[]'?: number[]
      'clickAndCollect[]'?: number[]
      'products[]': number[]
      releasedDateMax?: string
      send?: boolean
      singleProductOrder?: boolean
      selectedProductOnly?: boolean
    } = {
      preorder: true,
      'products[]': selectedProducts,
      releasedDateMax,
      singleProductOrder: singleProductOrder ?? undefined,
      selectedProductOnly: selectedProductOnly ?? undefined,
      withCards: !_.isNil(withCards) ? withCards : undefined
    }

    switch (shippingMode) {
      case Shipping.Classic:
        params.name = 'Préco envoi'
        params['notClickAndCollect[]'] = [1, 3]
        break
      case Shipping.BnfClickAndCollect:
        params.name = 'Préco Click & Collect Paris BNF'
        params['clickAndCollect[]'] = [1]
        break
      case Shipping.RivoliClickAndCollect:
        params.name = 'Préco Click & Collect Paris Rivoli'
        params['clickAndCollect[]'] = [3]
        break

      case Shipping.ClickAndCollectStore:
        params.name = 'Préco Click & Collect'
        params['clickAndCollect[]'] = [store.id]
        params.send = false
        break

      default:
        params.name = 'Préco'
        break
    }

    return params
  }, [selectedProductOnly, shippingMode, singleProductOrder, productsList, selectedProducts, withCards, store.id])

  const onSubmit = async () => {
    await downloadFile(`${API_ROUTES.downloads.multipleOrders}/${store.id}?${stringify(preOrderPayload)}`)
    emit(EventType.PrintOrder, null)
  }

  const { data: preOrders, isFetching: preOrdersLoading } = useQuery(
    ['countOrderPrint', store.id, preOrderPayload],
    () => countPurchaseOrders(store.id, preOrderPayload),
    {
      enabled: active && !!selectedProducts?.length
    }
  )

  return (
    <CardLayout>
      <Title2Tooltip
        title={t('page.order.print.details.preorder.title')}
        text={
          store?.warehouse
            ? PreorderTooltip
            : t('page.order.print.otherProduct.tooltips.preorder', { store: store.shortName })
        }
      />
      <FieldsWrapper>
        <SearchByEditionForm
          handleSearchStatus={setSearchFromEditionStatus}
          handleProducts={handleProducts}
          store={store}
          active={active}
        />
        <SearchByReleaseDateForm
          handleSearchStatus={setSearchFromReleaseDateStatus}
          handleProducts={handleProducts}
          store={store}
          active={active}
        />
        <SearchProductForm onSelect={handleProducts} store={store} />
      </FieldsWrapper>

      <PreorderLayoutForm onSubmit={handleSubmit(onSubmit)}>
        {store?.warehouse && (
          <WarehouseFieldsWrapper>
            <Select
              options={shippingOptions}
              id="shippingMode"
              control={control}
              label={t('common.label.deliveryMode')}
              enableUnselect={true}
              placeholder={t('common.select.defaultOptions.deliveryMode')}
            />
            <InputLayout
              label={t('page.order.print.details.labels.orderContent')}
              className="no-border"
              tooltip={
                <TextTooltip id="tooltipSelectedProduct" text={t('page.order.print.details.tooltips.orderContent')} />
              }
            >
              <InputRadio
                id="withCards"
                control={control}
                labelAccept={t('page.order.print.details.labels.withCards.accept')}
                labelDecline={t('page.order.print.details.labels.withCards.decline')}
              />
              <InputRadio
                display="checkbox"
                label={t('page.order.print.details.preorder.selectedProductOnly')}
                control={control}
                id="selectedProductOnly"
                layout="row-reverse"
              />
            </InputLayout>
            <InputLayout
              label={t('page.order.print.details.labels.specificQuantity')}
              className="no-border"
              tooltip={
                <TextTooltip
                  id="tooltipSelectedProduct"
                  text={t('page.order.print.details.tooltips.specificQuantity')}
                />
              }
            >
              <InputRadio
                display="checkbox"
                label={t('page.order.print.details.labels.oneProductOnly')}
                control={control}
                id="singleProductOrder"
                layout="row-reverse"
              />
            </InputLayout>
          </WarehouseFieldsWrapper>
        )}

        {(isFetched || productsList.length > 0) && (
          <ProductsSelector
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            productsList={productsList}
          />
        )}

        <PreviewButtonContainer>
          <Button
            variant="warning"
            icon={PrintIcon}
            disabled={!selectedProducts?.length}
            buttonType="submit"
            isLoading={isLoading || downloading}
            size="long"
          >
            {t('page.order.print.actions.printPreorder')}
          </Button>
          {!!selectedProducts?.length &&
            (preOrdersLoading ? (
              <Loader />
            ) : (
              <PreviewCounterText>
                {t('page.order.print.details.pendingOrdersCount', { count: preOrders?.totalItems })}
              </PreviewCounterText>
            ))}
        </PreviewButtonContainer>
      </PreorderLayoutForm>
    </CardLayout>
  )
}

export default OtherPreoductPreorderForm
