import { patchStock, PatchStockResponse, stockByIdQueryKey, StockCollaborator } from 'api/stock'
import { StatusTypes } from 'components/StatusBlock/StatusBlock'
import { useApiNotifications } from 'hooks/useApiNotifications'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEmit } from 'hooks/useEventEmitter'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Stock } from 'types/entities'
import { EventType } from 'types/events'
import { StockStatus } from 'types/playInApiInterfaces'
import { formatDateAndHour } from 'utils/dates'

const getFullName = (collaborator?: StockCollaborator | null): string | undefined => {
  if (!collaborator) {
    return undefined
  }

  return [collaborator.firstname, collaborator.lastname].join(' ')
}

interface StatusStep {
  type: StatusTypes
  date?: string
  name?: string
}

export const formatStockStatus = (data?: Stock): Array<StatusStep | undefined> => {
  if (!data) {
    return []
  }

  const nameCreatedBy = getFullName(data.createdBy)
  const nameValidatedBy = getFullName(data.validatedBy)
  const nameTreatedBy = getFullName(data.treatedBy)
  const nameCanceledBy = getFullName(data.canceledBy)

  const createdStep = {
    type: StatusTypes.history,
    date: formatDateAndHour(data.createdAt),
    name: nameCreatedBy
  }

  const validatedStep = {
    type: StatusTypes.history,
    date: formatDateAndHour(data.validatedAt),
    name: nameValidatedBy
  }

  const treatedStep = {
    type: StatusTypes.history,
    date: formatDateAndHour(data.treatedAt),
    name: nameTreatedBy
  }

  const pendingStep = {
    type: StatusTypes.pending
  }

  switch (data.status) {
    case StockStatus.Canceled:
      return [
        createdStep,
        validatedStep,
        treatedStep,
        {
          type: StatusTypes.canceled,
          date: formatDateAndHour(data.canceledAt),
          name: nameCanceledBy
        }
      ]
    case StockStatus.Treated:
      return [
        createdStep,
        validatedStep,
        {
          ...treatedStep,
          type: StatusTypes.active
        },
        undefined
      ]
    case StockStatus.Validated:
      return [
        createdStep,
        {
          ...validatedStep,
          type: StatusTypes.active
        },
        pendingStep,
        undefined
      ]
    default:
      return [
        {
          ...createdStep,
          type: StatusTypes.active
        },
        pendingStep,
        pendingStep,
        undefined
      ]
  }
}

export type StockError = {
  key: string
  value?: any
}
export const useStockActionsNotifications = () => {
  const queryClient = useQueryClient()
  const { emitErrorNotification } = useApiNotifications()
  const { t } = useTranslation()

  const updateStockCache = (id: number, data?: PatchStockResponse) => {
    const prev = queryClient.getQueryData<Stock>(stockByIdQueryKey(id))
    queryClient.setQueryData(stockByIdQueryKey(id), { ...prev, ...data })
  }

  const emit = useEmit()

  // @ts-ignore TODO: get rid of mandatory fields
  const validationMutation = useMutation((id: number) => patchStock(id, { status: StockStatus.Validated }), {
    onSuccess: (data, id) => {
      updateStockCache(id, data)
      emit(EventType.PatchStockError, [])
    },
    onError: (error: any, id) => {
      emitErrorNotification(error.status, error.data, t('notification.stocks.alreadyValidated', { stockId: id }))
      let errors: StockError[] = []
      if (error?.status === 422) {
        error.data.violations.forEach((violation) => {
          if (violation.propertyPath.match(/entries\[[0-9]+]/g)) {
            const productId = violation.propertyPath.match(/[0-9]+/g)[0]
            errors.push({ key: 'entry', value: parseInt(productId) })
          } else {
            errors.push({ key: violation.propertyPath })
          }
        })

        emit(EventType.PatchStockError, errors)
      }
    }
  })

  // @ts-ignore TODO: get rid of mandatory fields
  const cancelValidationMutation = useMutation((id: number) => patchStock(id, { status: StockStatus.Created }), {
    onSuccess: (data, id) => {
      updateStockCache(id, data)
    }
  })

  // @ts-ignore TODO: get rid of mandatory fields
  const treatmentMutation = useMutation((id: number) => patchStock(id, { status: StockStatus.Treated }), {
    onSuccess: (data, id) => {
      updateStockCache(id, data)
    },
    emitDefaultErrorNotification: false,
    onError: (error, id) => {
      emitErrorNotification(error.status, error.data, t('notification.stocks.alreadyTreated', { stockId: id }))
    }
  })

  // @ts-ignore TODO: get rid of mandatory fields
  const cancelTreatmentMutation = useMutation((id: number) => patchStock(id, { status: StockStatus.Validated }), {
    onSuccess: (data, id) => {
      updateStockCache(id, data)
    }
  })

  // @ts-ignore TODO: get rid of mandatory fields
  const cancelMutation = useMutation(async (id: number) => patchStock(id, { status: StockStatus.Canceled }), {
    onSuccess: (data, id) => {
      updateStockCache(id, data)
    }
  })

  return {
    treatmentMutation,
    cancelTreatmentMutation,
    validationMutation,
    cancelValidationMutation,
    cancelMutation
  }
}
