import { yupResolver } from '@hookform/resolvers/yup'
import {
  getCollaboratorsUri,
  patchCollaborator,
  PatchCollaboratorBody,
  usePaginatedCollaborators
} from 'api/collaborators'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import { LineBreakLabel } from 'components/Layouts/InputLayout/InputLayout.styles'
import Select, { Option } from 'components/Select/Select'
import Tooltip from 'components/Tooltip/Tooltip'
import { MoreIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Collaborator, StoreDetails } from 'types/entities'
import * as yup from 'yup'
import { AddCashboxFormLayout, AddCashboxFormWrapper } from '../StoreManageTab.style'

interface Props {
  store: StoreDetails
  callback: () => void
  currentCollaborators: Collaborator[]
}
const schema = yup.object({
  collaborator: yup.string().trim().required(),
  cashboxName: yup.string().trim().required()
})
type CashboxInputs = yup.InferType<typeof schema>

export default function AddCashboxForm({ store, callback, currentCollaborators }: Props) {
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset
  } = useForm<CashboxInputs>({
    resolver: yupResolver(schema)
  })

  const collaboratorsQuery = getCollaboratorsUri({ mainStore: `${store.id}`, 'exists[cashboxName]': false })
  const {
    data: collaborators,
    hasNextPage,
    fetchNextPage
  } = usePaginatedCollaborators(collaboratorsQuery, collaboratorsQuery)

  const { mutate: addCashbox, isLoading } = useMutation(
    ({ body, id }: { id: string; body: PatchCollaboratorBody }) => patchCollaborator({ id, body }),
    {
      onSuccess: () => {
        callback()
        reset()
      }
    }
  )

  const onSubmit = (formData: CashboxInputs) => {
    addCashbox({
      // @ts-ignore TODO Useless required data in patch
      body: { cashboxName: formData.cashboxName },
      id: formData.collaborator
    })
  }

  const collaboratorsOptions: Option[] =
    collaborators?.map((collaborator) => ({
      value: collaborator.id,
      label: `${collaborator.lastname} ${collaborator.firstname}`,
      disabled: !!currentCollaborators?.find((e) => e['@id'] === collaborator['@id'])
    })) ?? []

  const CashboxNameTooltip = (
    <Tooltip id="cashboxNameTooltip">
      <Trans i18nKey="page.stores.details.storeManage.tooltips.cashboxName" />
    </Tooltip>
  )
  return (
    <AddCashboxFormLayout onSubmit={handleSubmit(onSubmit)}>
      <AddCashboxFormWrapper>
        <Input
          register={register}
          id="cashboxName"
          label={t('page.stores.details.storeManage.cashbox.labels.cashboxName')}
          tooltip={CashboxNameTooltip}
          className={classNames({
            'is-invalid': errors.cashboxName
          })}
        />

        <Select
          control={control}
          id="collaborator"
          label={t('page.stores.details.storeManage.cashbox.labels.addCashbox')}
          labelComponent={LineBreakLabel}
          options={collaboratorsOptions}
          placeholder={t('common.select.defaultOptions.collaboratorsChoose')}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          className={classNames({
            'is-invalid': errors.collaborator
          })}
        />

        <Button buttonType="submit" shape="circle" icon={MoreIcon} iconColor="white" isLoading={isLoading} />
      </AddCashboxFormWrapper>
      <AddCashboxFormWrapper>
        <BasicFieldValidationError error={errors.cashboxName} message={t('form.validation.required.name')} />
        <BasicFieldValidationError error={errors.collaborator} message={t('form.validation.required.collaborator')} />
      </AddCashboxFormWrapper>
    </AddCashboxFormLayout>
  )
}
