import Input from 'components/Input/Input'
import styled from 'styled-components'

export const StyledInput = styled(Input)`
  width: 18.5rem;
  max-width: 100%;
` as typeof Input
export const LargeInput = styled(StyledInput)`
  width: 22.5rem;
` as typeof Input

export const SearchCustomerWrapper = styled.div`
  padding-bottom: 3.5rem;
`
export const SearchCustomerFormLayout = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 3.5rem;
  align-items: flex-end;
`
export const CustomerResults = styled.div`
  display: grid;
  gap: 1rem 0;
  padding-top: 2rem;
`
export const CustomerResultItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`
export const AddCustomerFormLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 3.5rem;
  padding: 3.5rem 0;
  border-top: 1px solid ${(props) => props.theme.borderColors.secondary};
`

export const AddCustomerButtonsWrapper = styled.div<{ withPadding?: boolean }>`
  display: flex;
  gap: 5rem;
  ${(props) => (props.withPadding ? `padding-top:2rem;` : ``)}
`
export const TwoColField = styled.div`
  grid-column: span 2;
`

export const SearchCustomerButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
`
