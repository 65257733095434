import Loader from 'components/Loader/Loader'
import ClickableCell from 'components/SortedTable/ClickableCell'
import Svg from 'components/Svg/Svg'
import Tabs from 'components/Tabs/Tabs'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { PlayinPIcon, WarehouseIcon } from 'constants/icons'
import { Columns } from 'features/Listing/Listing'
import { useOrderStatusOptions } from 'hooks/useSelectOptions'
import _ from 'lodash'
import { RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Customer, Order } from 'types/entities'
import { OrderStatus, ShippingTypeName } from 'types/playInApiInterfaces'
import { formatDateAndHour } from 'utils/dates'
import { OrderListLayout, TabNameContainer } from './CustomerList.style'
import CustomerCanceledOrders from './orders/CustomerCanceledOrders'
import CustomerCurrentOrders from './orders/CustomerCurrentOrders'
import CustomerTreatedOrders from './orders/CustomerTreatedOrders'

interface Props {
  customer: Customer
}

export const checkStatus = (selectedStatus: OrderStatus | Array<OrderStatus>, orderStatus?: OrderStatus) => {
  if (orderStatus) {
    if (Array.isArray(selectedStatus)) return selectedStatus.includes(orderStatus)
    return orderStatus === selectedStatus
  }
  return false
}

export default function OrderList({ customer }: Props) {
  const { t } = useTranslation()

  const [currentOrdersCount, setCurrentOrdersCount] = useState<number | undefined>(undefined)
  const [treatedOrdersCount, setTreatedOrdersCount] = useState<number | undefined>(undefined)
  const [canceledOrdersCount, setCanceledOrdersCount] = useState<number | undefined>(undefined)

  const { getStatusTraduction } = useOrderStatusOptions()

  const columns: Columns<Order> = [
    {
      name: t('common.label.date'),
      key: 'date',
      decorator: (order: Order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text>{formatDateAndHour(order.createdAt)}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.number'),
      key: 'referenceNumber',
      decorator: (order: Order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text color="secondary" fontWeight="light">
            {order.referenceNumber}
          </Text>
        </ClickableCell>
      )
    },

    {
      name: t('common.label.total'),
      key: 'total',
      decorator: (order: Order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <TextNumber value={order.total} suffix="€" decimalScale={2} />
        </ClickableCell>
      )
    },
    {
      name: t('common.label.orderStatus'),
      key: 'status',
      decorator: (order: Order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text>{getStatusTraduction(order.status)}</Text>
        </ClickableCell>
      )
    },
    {
      key: 'shippingType',
      name: t('common.label.shippingMode'),

      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text color={order.shippingMode?.shippingType === ShippingTypeName.FAST ? 'danger' : 'black'}>
            {order.shippingMode?.name || '-'}
          </Text>
        </ClickableCell>
      )
    },
    {
      key: 'stock',
      name: t('common.label.stock'),

      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <TooltipHover id={`stock-${order.id}`} content={<Text>{order.stock?.shortName}</Text>}>
            {order.stock?.['@id'] === WAREHOUSE_STORE_ID ? <Svg svg={WarehouseIcon} /> : <Svg svg={PlayinPIcon} />}
          </TooltipHover>
        </ClickableCell>
      )
    }
  ]

  return (
    <OrderListLayout>
      <Title2>{t('page.customer.details.orders.title')}</Title2>
      <Tabs
        idle={true}
        tabs={[
          {
            tabLabel: _.isNumber(currentOrdersCount) ? (
              t('page.customer.details.orders.currentTab', { count: currentOrdersCount })
            ) : (
              <TabNameContainer>
                {t('page.customer.details.orders.currentTab_zero')}
                <Loader />
              </TabNameContainer>
            ),
            panelComponent: (
              <CustomerCurrentOrders customer={customer} columns={columns} handleCount={setCurrentOrdersCount} />
            )
          },
          {
            tabLabel: _.isNumber(treatedOrdersCount) ? (
              t('page.customer.details.orders.treatedTab', { count: treatedOrdersCount })
            ) : (
              <TabNameContainer>
                {t('page.customer.details.orders.treatedTab_zero')}
                <Loader />
              </TabNameContainer>
            ),
            panelComponent: (
              <CustomerTreatedOrders customer={customer} columns={columns} handleCount={setTreatedOrdersCount} />
            )
          },
          {
            tabLabel: _.isNumber(canceledOrdersCount) ? (
              t('page.customer.details.orders.canceledTab', { count: canceledOrdersCount })
            ) : (
              <TabNameContainer>
                {t('page.customer.details.orders.canceledTab_zero')}
                <Loader />
              </TabNameContainer>
            ),
            panelComponent: (
              <CustomerCanceledOrders customer={customer} columns={columns} handleCount={setCanceledOrdersCount} />
            )
          }
        ]}
      />
    </OrderListLayout>
  )
}
