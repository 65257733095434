import Loader from 'components/Loader/Loader'
import Svg from 'components/Svg/Svg'
import { CheckIcon, CrossIcon } from 'constants/icons'
import { EmptyIcon } from './Icon.styles'

const ApiResponseIcon = ({ showIcon, isLoading, isError, isSuccess }) => {
  return (
    <>
      {showIcon ? (
        <>
          {isLoading && <Loader />}
          {isError && <Svg svg={CrossIcon} color="danger" size="1.4rem" />}
          {isSuccess && <Svg svg={CheckIcon} color={'success'} size="1.4rem" />}
        </>
      ) : (
        <EmptyIcon />
      )}
    </>
  )
}

export default ApiResponseIcon
