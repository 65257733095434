import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const ActionLogTitle2 = styled(Title2)`
  font-size: 1.9rem;
  margin-bottom: 3.5rem;
`

export const ScrollBox = styled.div`
  overflow: auto;
  max-height: 50vh;
`

export const ModalColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  position: relative;
`

export const Content = styled.div`
  width: 80rem;
`
