import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import styled from 'styled-components'

export const LargeModalBody = styled.div`
  width: 85rem;
  max-width: 100%;
`

export const Row = styled.div`
  display: flex;
  gap: 4rem;
`

export const RowButtons = styled(Row)`
  margin-top: 2rem;

  & > * {
    width: 50%;
    &:first-child {
      display: flex;
      justify-content: end;
    }
  }
`

export const DescriptionText = styled(Text)`
  margin-bottom: 2rem;
`

export const ModalListingWrapper = styled.div`
  margin-bottom: 2rem;
`
export const RefundModalTitle = styled(Title2)`
  & * {
    display: inline !important;
  }
`
export const OrderTotalPaid = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const ChildrenPackRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`
export const CreditNoteFormContainer = styled.div`
  gap: 2rem 5rem;
`