import classNames from 'classnames'
import InputLayout, { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
interface Props extends Omit<LayoutProps, 'disabled'> {
  value?: string | null | JSX.Element
  placeholder?: JSX.Element | string
}

export default function FieldDisplay({ value, placeholder, className, ...layoutProps }: Props) {
  return (
    <InputLayout className={classNames('no-border', className)} {...layoutProps}>
      <TextFieldDisplay text={value} placeholder={placeholder} />
    </InputLayout>
  )
}
