export enum NotificationType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error'
}

export interface NotificationEvent {
  type: NotificationType
  id: NotificationId
  title: string
  text?: string | string[]
  subtext?: string
}

// Notification unique identifier
export enum NotificationId {
  BadCredentials = 'bad-credentials',
  InactiveUser = 'inactive-user',
  Success = 'success',
  Warning = 'warning',
  APIWarning = 'api-warning',
  APIError = 'api-error',
  Error = 'error',
  TooManyCashboxes = 'too-many-cashboxes'
}
