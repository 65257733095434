import { yupResolver } from '@hookform/resolvers/yup'
import { createCustomer } from 'api/customers'
import Button from 'components/Button/Button'
import EmailValidationErrors from 'components/FormValidationErrors/EmailValidationErrors'
import Input from 'components/Input/Input'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import NotAllowedText from 'components/Text/NotAllowedText'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { CreateIcon } from 'constants/icons'
import CanAccess from 'features/Permissions/CanAccess'
import { useApiNotifications } from 'hooks/useApiNotifications'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoleAction } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { FormRegister, Line, RegisterFormActions } from './CustomerForms.style'

type CollaboratorForm = {
  email: string
  firstname: string
  lastname: string
  fidelityCardNumber?: string
  newsletter: boolean
  commercialEmails: boolean
}

const schema = yup.object().shape({
  firstname: yup.string().trim(),
  lastname: yup.string().trim(),
  email: yup.string().email().required(),
  fidelityCardNumber: yup.string().trim(),
  newsletter: yup.boolean(),
  commercialEmails: yup.boolean()
})

type Props = {
  defaultEmail?: string
}

const CustomerRegisterForm = ({ defaultEmail }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { emitErrorNotification } = useApiNotifications()

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    control
  } = useForm<CollaboratorForm>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      newsletter: false,
      commercialEmails: false,
      email: defaultEmail ?? ''
    }
  })

  useEffect(() => {
    setValue('email', defaultEmail ?? '')
  }, [defaultEmail, setValue])

  const onRegister = async (formData: CollaboratorForm) => {
    createCustomerMutation({
      ...formData,
      fidelityCardNumber: formData.fidelityCardNumber || null
    })
  }

  const { mutate: createCustomerMutation, isLoading } = useMutation(createCustomer, {
    onSuccess: (customer) => {
      navigate(`/customer-details/${customer.id}`)
    },
    emitDefaultErrorNotification: false,
    onError: (error) => {
      if (error.status === 422) {
        emitErrorNotification(error.status, error.data, t('notification.customer.createError'))
      } else {
        emitErrorNotification(error.status, error.data)
      }
    }
  })

  return (
    <CardLayout>
      <Title2>{t('page.customer.manage.create')}</Title2>
      <CanAccess
        permissions={[RoleAction.ROLE_ACTION_CUSTOMER_CREATE]}
        deniedComponent={<NotAllowedText />}
        allowedComponent={
          <FormRegister onSubmit={handleSubmit(onRegister)}>
            <div>
              <Input
                className={`${errors.lastname ? 'is-invalid' : ''}`}
                label={t('common.label.lastName')}
                id="lastname"
                register={register}
              />
            </div>

            <div>
              <Input
                className={`${errors.firstname ? 'is-invalid' : ''}`}
                label={t('common.label.firstName')}
                id="firstname"
                register={register}
              />
            </div>

            <Line>
              <Input
                className={`${errors.email ? 'is-invalid' : ''}`}
                label={t('common.label.mandatory.email')}
                type="email"
                id="email"
                register={register}
              />
              <EmailValidationErrors error={errors.email} />
            </Line>

            <Line>
              <Text fontWeight="medium" color="info">
                {t('common.password.autogenerated')}
              </Text>
            </Line>

            <InputRadio
              control={control}
              id="newsletter"
              display="checkbox"
              label={t('page.customer.manage.newsletter')}
              layout="row-reverse"
            />
            <InputRadio
              control={control}
              id="commercialEmails"
              display="checkbox"
              label={t('page.customer.manage.commercialEmails')}
              layout="row-reverse"
            />
            <Input register={register} id="fidelityCardNumber" label={t('page.customer.manage.fidelityCardNumber')} />
            <RegisterFormActions>
              <Button shape="circle" buttonType="submit" isLoading={isLoading} icon={CreateIcon} />
            </RegisterFormActions>
          </FormRegister>
        }
      />
    </CardLayout>
  )
}

export default CustomerRegisterForm
