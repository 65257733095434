import styled from 'styled-components'

export const AddProductLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 1.25rem 1rem;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColors.lightPurple};
  border-bottom: 2px solid ${(props) => props.theme.borderColors.black};
  gap: 0 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    align-items: flex-start;
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  @media screen and (${(props) => props.theme.screenWidth.minDesktopScreen}) {
    .productField {
      max-width: 50rem;
    }
    .quantityField {
      max-width: 10rem;
    }
    .priceCalculationField {
      max-width: 20rem;
    }
    .priceField {
      max-width: 20rem;
    }
  }

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .productField {
      grid-column: 1/11;
    }
    .quantityField {
      grid-column: 11/13;
    }
    .priceCalculationField {
      grid-column: 1/6;
    }
    .priceField {
      grid-column: 6/12;
    }
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    .productField {
      grid-column: 1/13;
    }
    .quantityField {
      grid-column: 1/4;
    }
    .priceCalculationField {
      grid-column: 4/13;
    }
    .priceField {
      grid-column: 1/11;
    }
  }
`

export const SmallInputWrapper = styled.div`
  max-width: 8.5rem;
`
