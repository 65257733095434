import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import { useTranslation } from 'react-i18next'
import ActionLogListing from './ActionLogListing'
import { ActionLogTitle2, Content, ModalColumnBody, ScrollBox } from './ActionLogModal.style'

interface Props extends Omit<ModalProps, 'children'> {
  target: string
}

export default function ActionLogModal({ open, onClose, target }: Props) {
  const { t } = useTranslation()
  return (
    <Modal open={open} onClose={onClose}>
      <Content>
        <ActionLogTitle2>{t('common.modals.actionLogModal.title')}</ActionLogTitle2>
        <ModalColumnBody>
          <BlockLayout>
            <Text fontWeight="light">{t('common.modals.actionLogModal.description')}</Text>
          </BlockLayout>
          <ScrollBox>
            <ActionLogListing target={target} isActive={open} />
          </ScrollBox>
        </ModalColumnBody>
      </Content>
    </Modal>
  )
}
