import { eventByIdQueryKey, patchStoreEvent, PatchStoreEventBody, useGetStoreEventById } from 'api/storeEvents'
import StoreEventForm, { CreateEventInputs } from 'app/StoreEventCreate/form/StoreEventForm'
import BottomMessage from 'components/BottomMessage/BottomMesssage'
import Loader from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { useMutation } from 'hooks/useAxiosMutation'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { StoreEvent } from 'types/entities'
import { UnmodifiableStoreEventStatus } from 'types/enums'
import { hourFormat, shortDateAndHourFormat } from 'utils/dates'
import CommentBox from './components/CommentBox/CommentBox'
import CustomerList from './components/CustomerList'
import EventStatusHeader from './components/EventStatusHeader'
import useEventStatus from './hook/useEventStatus'
import { getEventName } from './utils'

export default function StoreEventDetails() {
  const { id: eventId } = useParams()

  const { t } = useTranslation()

  const { data: event } = useGetStoreEventById(eventId!)

  const { openStep, closedStep, voucherStep } = useEventStatus(event)

  const queryClient = useQueryClient()

  const { mutate: updateStoreEvent, isLoading } = useMutation(
    (body: PatchStoreEventBody) => patchStoreEvent(body, event?.id!),
    {
      onSuccess: (updatedEvent) => {
        const prev = queryClient.getQueryData<StoreEvent>(eventByIdQueryKey(updatedEvent.id!))
        queryClient.setQueryData(eventByIdQueryKey(updatedEvent.id!), {
          ...prev,
          ...updatedEvent,
          format: updatedEvent.format || null
        })
      }
    }
  )

  const onSubmit = (formData: CreateEventInputs) => {
    const { format, occursAt, endsAt, startsAt, subtitle, subtitleEn, raisedPrice, ...restFormData } = formData
    updateStoreEvent({
      format: !!format ? format : null,
      ...restFormData,
      priceWithoutDiscount: raisedPrice,
      subtitle: subtitle ?? undefined,
      subtitleEn: subtitleEn ?? undefined,
      occursAt: event?.occursAt !== occursAt ? occursAt : undefined,
      endsAt: hourFormat(event?.endsAt) !== endsAt ? endsAt : undefined,
      startsAt: hourFormat(event?.startsAt) !== startsAt ? startsAt : undefined
    })
  }

  const eventName = event && getEventName(event)

  return (
    <Section>
      {event ? (
        <>
          <EventStatusHeader statusStep={{ open: openStep, closed: closedStep, discount: voucherStep }} />
          {!!eventName && (
            <MainTitle
              title={`${shortDateAndHourFormat(event.startsAt)} - ${eventName}`}
              subtitle={event.store?.shortName}
            />
          )}
          <CustomerList event={event} />

          <CommentBox comment={event?.comment!} eventId={event?.id!} />

          <StoreEventForm
            onSubmit={onSubmit}
            editMode
            canEdit={!UnmodifiableStoreEventStatus.includes(event.status!)}
            isLoading={isLoading}
            storeEvent={event}
          />

          <BottomMessage text={t('common.requiredLabel')} />
        </>
      ) : (
        <Loader />
      )}
    </Section>
  )
}
