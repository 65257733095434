import { InputTextStyle } from 'components/Input/Input.styles'
import InputLayout, { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import Svg from 'components/Svg/Svg'
import { TimeIcon } from 'constants/icons'
import React from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { TimePickerContainer } from './TimePicker.style'

type ControlledProps = {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

function TimePickerComponent({ value, onChange, disabled }: ControlledProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let arr = event.target.value.split('').filter((value) => value.match(/(\d|:)/g))

    // Avoid ":" missplacement
    const colonIndex = arr.indexOf(':')
    if (colonIndex !== -1 && colonIndex !== 2) {
      return
    }

    //Auto set ":" after 2 first digit
    if (value?.length === 1 && arr.length === 2) arr.push(':')

    //Remove ":" and prev value on ":" delete
    if (value?.length === 3 && arr.length === 2) {
      arr.splice(1, 1)
    }

    onChange(arr.splice(0, 5).join(''))
  }

  //Autocomplete hour
  const handleBlur = () => {
    if (value) {
      let arr = value.split('')

      //Transform 1 to 01
      if (arr.length === 1) arr.splice(0, 0, '0')

      //Add missing 0 & ":" if needed
      for (let i = 0; i < 5; i++) {
        if (!arr[i]) {
          if (i === 2) arr.push(':')
          else arr.push('0')
        }
      }

      let formattedArr = arr
        .join('')
        .split(':')
        .map((item, index) => {
          let value = parseInt(item)
          if (value < 0) return '00'
          if (index === 0 && value > 23) return '23'
          if (index === 1 && value > 59) return '59'
          return item
        })

      onChange(formattedArr.join(':'))
    }
  }

  return (
    <TimePickerContainer>
      <InputTextStyle
        placeholder="hh:mm"
        onChange={(event) => handleChange(event)}
        value={value ?? ''}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <Svg svg={TimeIcon} size="1.2rem" />
    </TimePickerContainer>
  )
}

export interface TimePickerProps<T extends FieldValues> extends LayoutProps {
  id: Path<T>
  defaultValue?: any
  control: Control<T>
}

interface Props<T extends FieldValues> extends TimePickerProps<T> {}

//TODO : check typescript for Controller value
export default function TimePicker<T extends FieldValues>({ label, className, control, id, disabled }: Props<T>) {
  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => {
        const { value, onChange } = field
        return (
          <InputLayout label={label} className={className} disabled={disabled}>
            <TimePickerComponent value={value as string} onChange={onChange} disabled={disabled} />
          </InputLayout>
        )
      }}
    ></Controller>
  )
}
