import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import Loader from 'components/Loader/Loader'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { ListActions } from 'components/SortedTable/SortedTable.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useCollaboratorByRole } from 'hooks/entityHooks/permissionsHooks'
import { useApiNotifications } from 'hooks/useApiNotifications'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collaborator, Role as RoleEntity } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import AddCollaboratorForm, { AddCollaboratorInputs } from '../form/AddRoleToCollaboratorForm'
import { DeleteModalDescription } from '../PermissionManage.style'

interface Props {
  role: RoleEntity
}
export default function CollaboratorsByRole({ role }: Props) {
  const { t } = useTranslation()
  const { checkMatch, hasRole } = useCheckMatch()

  const {
    withRoleCollaborators,
    isLoading,
    patchCollaboratorMutation,
    availableCollaborators,
    availableCollaboratorsOptions
  } = useCollaboratorByRole(role)

  const [selectedCollaborators, setSelectedCollaborators] = useState<string[]>([])
  const [deleteModalOpen, toggleDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const columns = _.filter<Column<Collaborator>>(
    [
      {
        key: 'checkbox',
        decorator: (collaborator) => (
          <Cell>
            <Checkbox
              className={classNames({
                checked: selectedCollaborators.includes(collaborator['@id']!)
              })}
              onClick={() => setSelectedCollaborators((prev) => _.xor(selectedCollaborators, [collaborator['@id']!]))}
            />
          </Cell>
        )
      },
      {
        key: 'name',
        name: t('page.permissions.manage.labels.collaboratorWithRole'),
        decorator: (collaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={collaborator.id}>
            {` ${collaborator.lastname} ${collaborator.firstname}`}
          </ClickableCell>
        )
      },
      {
        key: 'store',
        name: t('common.label.location'),
        decorator: (collaborator) => (
          <ClickableCell route={RouteName.CollaboratorDetails} value={collaborator.id}>
            {collaborator.stores?.map((e) => e.shortName).join(', ')}
          </ClickableCell>
        )
      }
    ],
    (col) => {
      if (col.key === 'checkbox')
        return checkMatch([RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]) && hasRole([role.roleName!])
      return true
    }
  )

  const { emitSuccessNotification } = useApiNotifications()

  const removeRoleFromCollaborators = async () => {
    setIsDeleting(true)
    try {
      for (let collaboratorIri of selectedCollaborators) {
        const collaborator = _.find(withRoleCollaborators, (e) => e['@id'] === collaboratorIri)
        await patchCollaboratorMutation({
          id: `${collaborator?.id}`,
          body: {
            collaboratorRoles: _.difference(
              collaborator?.collaboratorRoles?.map((collaboratorRole) => collaboratorRole['@id']!),
              [role['@id']!]
            )
          }
        })
      }
      //We only send success notification at the end of ALL patch
      emitSuccessNotification()
      setSelectedCollaborators([])
      toggleDeleteModal(false)
    } finally {
      setIsDeleting(false)
    }
  }

  //Add selected role to a set of collaborators
  const addRoleToCollaborator = (formData: AddCollaboratorInputs) =>
    new Promise<void>(async (resolve) => {
      for (let collaboratorIri of formData.collaborators) {
        const collaborator = _.find(availableCollaborators, (e) => e['@id'] === collaboratorIri)
        await patchCollaboratorMutation({
          id: `${collaborator?.id}`,
          body: {
            collaboratorRoles: _.map(
              collaborator?.collaboratorRoles,
              (collaboratorRole) => collaboratorRole['@id']!
            ).concat(role['@id']!)
          }
        })
      }
      //We only send success notification at the end of ALL patch
      emitSuccessNotification()
      resolve()
    })

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <ShouldHide
        permissions={[RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]}
        deniedExtraCondition={!hasRole([role.roleName!])}
      >
        <AddCollaboratorForm
          onSubmit={addRoleToCollaborator}
          availableCollaboratorsOptions={availableCollaboratorsOptions}
        />
        {!!withRoleCollaborators.length && (
          <ListActions>
            <Checkbox
              className={classNames({
                checked: selectedCollaborators.length === withRoleCollaborators.length
              })}
              onClick={() =>
                setSelectedCollaborators((prev) =>
                  prev.length === withRoleCollaborators.length ? [] : withRoleCollaborators.map((e) => e['@id']!)
                )
              }
            />
            <TooltipHover
              id="removeRoleFromCollaboratorTooltip"
              content={t('page.permissions.manage.role.tooltips.removeCollaborator', { roleName: role.name })}
              container="tooltipContainer"
            >
              <Button
                variant="white"
                shape="circle"
                icon={DeleteIcon}
                disabled={!selectedCollaborators.length}
                onClick={() => toggleDeleteModal(true)}
              />
            </TooltipHover>
          </ListActions>
        )}

        <ConfirmModal
          open={deleteModalOpen}
          onClose={() => toggleDeleteModal(false)}
          callback={removeRoleFromCollaborators}
          closeOnCallback={false}
          title={t('page.permissions.manage.role.removeCollaboratorModal.title', {
            count: selectedCollaborators.length
          })}
          confirmBtnProps={{
            isLoading: isDeleting
          }}
          confirmLabel={t('common.button.delete')}
        >
          <DeleteModalDescription fontWeight="light" color="secondary">
            {t('page.permissions.manage.role.removeCollaboratorModal.decription', {
              count: selectedCollaborators.length,
              roleName: role.name
            })}
          </DeleteModalDescription>
          <Listing
            columns={columns.filter((col) => col.key !== 'checkbox')}
            data={withRoleCollaborators.filter((action) => selectedCollaborators.includes(action['@id']!))}
          />
        </ConfirmModal>
      </ShouldHide>

      <Listing
        columns={columns}
        data={withRoleCollaborators}
        totalCount={withRoleCollaborators.length}
        emptyText={t('page.permissions.manage.role.listing.collaboratorsEmpty')}
      />
    </div>
  )
}
