import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type GetPrintExclusionsParams = Api.GetPrintExclusions.RequestQuery
export type GetPrintExclusionsQueryResponse = Api.GetPrintExclusions.ResponseBody['hydra:member'][0]

export const getPrintExclusionsUri = (params: GetPrintExclusionsParams) => {
  const queryString = stringify(params)
  return [API_ROUTES.printExclusions.root, queryString].join('?')
}

type AddPrintExclusionsResponse = Api.PostPrintExclusions.ResponseBody
type AddPrintExclusionsBody = Api.PostPrintExclusions.RequestBody
export const addPrintExclusion = async ({ store, edition }: AddPrintExclusionsBody) => {
  const response = await playInApi.post<AddPrintExclusionsBody, AxiosResponse<AddPrintExclusionsResponse>>(
    `${API_ROUTES.printExclusions.root}`,
    {
      store,
      edition
    }
  )
  return response.data
}

export const deletePrintExclusion = async (id: string) => {
  return await playInApi.delete(`${API_ROUTES.printExclusions.root}/${id}`)
}
