import { yupResolver } from '@hookform/resolvers/yup'
import { postPayback, PostPaybackBody } from 'api/paybacks'
import Button from 'components/Button/Button'
import InputRadio from 'components/InputRadio/InputRadio'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { ModalButtonWrapper } from 'components/Modal/Modal.styles'
import Select from 'components/Select/Select'
import TextNumber from 'components/TextNumber/TextNumber'
import { RefundIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import { useMyStoresOptions, usePaymentModeFilters } from 'hooks/useSelectOptions'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { OrderEntry } from 'types/entities'
import { object, string } from 'yup'
import { StyledCell } from '../forms/orderEntriesTable/OrderEntriesTable.styles'
import { DescriptionText, ModalListingWrapper, RefundModalTitle } from './BaseModal.style'
import EntryQuantity from './components/EntryQuantity'
import { TwoColGridRow } from './CustomerEditModal/CustomerEditModal.style'

interface Props extends ModalProps {
  orderId: number
  orderEntries: OrderEntry[]
  columns: Columns<OrderEntry>
  getCellProperties: (entry: OrderEntry) => any
  callback?: () => any
}

type FormData = {
  paymentMode: string
  relatedStore: string
  stockError: boolean
}
const schema = object().shape({
  paymentMode: string().required(),
  relatedStore: string().required()
})

type EntryQty = { id: string; quantity: number }
export default function RefundModal({
  open,
  onClose,
  orderId,
  orderEntries,
  columns,
  getCellProperties,
  callback
}: Props) {
  const { t } = useTranslation()
  const [entriesQty, setEntriesQty] = useState<EntryQty[]>([])

  const getQuantity = (id: string): number =>
    entriesQty.find((entry) => entry.id === id)?.quantity ??
    orderEntries.find((entry) => entry['@id'] === id)?.quantity ??
    0

  const handleQuantity = useCallback((entry: OrderEntry, quantity: number) => {
    setEntriesQty((prev) => {
      let entries = [...prev]
      let index = entries.findIndex((prevEntry) => prevEntry.id === entry['@id'])
      if (index >= 0) {
        entries[index] = { ...prev[index], quantity }
      } else {
        entries.push({ id: entry['@id']!, quantity })
      }
      return entries
    })
  }, [])

  const queryClient = useQueryClient()

  const { mutateAsync: refundMutate, isLoading } = useMutation((body: PostPaybackBody) => postPayback(body), {
    onSuccess: () => {
      onClose()
      if (callback) callback()
    }
  })

  const onSubmit = async (formData: FormData) => {
    for (let entry of orderEntries) {
      let body: PostPaybackBody = {
        relatedOrderEntry: entry['@id']!,
        quantity: getQuantity(entry['@id']!),
        paymentMode: formData.paymentMode,
        total: entry.paidPrice! * getQuantity(entry['@id']!),
        relatedStore: formData.relatedStore,
        stockError: formData.stockError
      }
      await refundMutate(body)
    }
    queryClient.refetchQueries(['orderEntries', orderId])
  }

  const { me } = useAuth()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      relatedStore: me?.mainStore?.['@id']
    }
  })

  columns = [
    {
      key: 'quantity',
      name: t('common.label.quantity'),
      decorator: (entry) => {
        return (
          <StyledCell className={getCellProperties(entry).classname}>
            <EntryQuantity entry={entry} handleQuantity={handleQuantity} />
          </StyledCell>
        )
      }
    },
    ...columns?.filter((e) => ['cardOrProduct', 'lang'].includes(e.key)),

    {
      key: 'price',
      name: t('common.label.totalUnitPrice'),
      decorator: (entry) => (
        <StyledCell className={getCellProperties(entry).classname}>
          <TextNumber value={entry.paidPrice! * getQuantity(entry['@id']!)} suffix=" €" decimalScale={2} />
        </StyledCell>
      )
    }
  ]

  const totalPrice = orderEntries.reduce((prev, entry) => prev + getQuantity(entry['@id']!) * entry.paidPrice!, 0)

  const { myStoresOptions } = useMyStoresOptions()
  const { paymentModeOptions } = usePaymentModeFilters()

  return (
    <Modal open={open} onClose={onClose}>
      <ModalListingWrapper>
        <RefundModalTitle>
          <Trans
            i18nKey={'page.order.detail.modals.refund.title'}
            components={{ amount: <TextNumber value={totalPrice} suffix="€" fontWeight="bold" decimalScale={2} /> }}
          />
        </RefundModalTitle>
        <DescriptionText color="secondary">{t('page.order.detail.modals.refund.description')}</DescriptionText>
        <Listing data={orderEntries} columns={columns} />
      </ModalListingWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TwoColGridRow>
          <Select
            options={myStoresOptions}
            control={control}
            label={t('page.order.detail.modals.refund.label.localisation')}
            id="relatedStore"
            className={errors.relatedStore ? 'is-invalid' : ''}
          />
          <Select
            options={paymentModeOptions}
            control={control}
            label={t('page.order.detail.modals.refund.label.paymentMode')}
            id="paymentMode"
            className={errors.paymentMode ? 'is-invalid' : ''}
            placeholder={t('page.order.detail.modals.refund.placeholder.paymentMode')}
          />
        </TwoColGridRow>
        <InputRadio
          id="stockError"
          label={t('page.order.detail.modals.refund.stockError')}
          display="checkbox"
          layout="row-reverse"
          control={control}
        />
        <ModalButtonWrapper>
          <Button icon={RefundIcon} isLoading={isLoading} buttonType="submit">
            {t('page.order.detail.modals.refund.button')}
          </Button>
          <Button variant="white" onClick={() => onClose()}>
            {t('common.label.cancel')}
          </Button>
        </ModalButtonWrapper>
      </form>
    </Modal>
  )
}
