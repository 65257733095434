import { CardPropertiesQueryParams, PatchCardsParams, patchCard, usePaginatedCards } from 'api/cards'
import Button from 'components/Button/Button'
import FollowMouseContent from 'components/FollowMouseContent/FollowMouseContent'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import Cell from 'components/SortedTable/Cell'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { DeleteIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import { useProductFamilies } from 'hooks/entityHooks/familyHooks'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Card } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatDateAndHour } from 'utils/dates'
import { mergeFormQueryParams } from 'utils/queryParams'
import {
  Image,
  ProductImageContainer,
  StyledLoader
} from './PricingTagTable.styles'
import AddPricingTagForm from './form/AddPricingTagForm'
import SearchCardForm from './form/SearchCardForm'

const defaultSort: OrderParam<CardPropertiesQueryParams> = {
  sortedBy: 'name',
  direction: SortingDirection.Asc,
  queryParams: { 'order[name]': SortingDirection.Asc }
}

export type AddPricingTagInputs = {
  cardIri: string
  pricingTag?: string
}

export type SearchCardInputs = {
  cardName?: string
  pricingTag?: number
  collaborator?: number
  afterDeadline?: boolean
}

export default function PricingTag() {
  const { t } = useTranslation()
  const [searchQueryParams, setSearchQueryParams] = useState<CardPropertiesQueryParams>()
  const [searchOrderParams, setSearchOrderParams] = useState<OrderParam<CardPropertiesQueryParams>>(defaultSort)
  const [isProductImageLoaded, setIsProductImageLoaded] = useState(false)
  const [cardImage, setCardImage] = useState<string | undefined>(undefined)

  const queryParams = mergeFormQueryParams({ searchQueryParams, orderParams: searchOrderParams?.queryParams })

  const { getFamilyIcon, getFamilyName } = useProductFamilies()

  const paginatedCardsQueryKey = searchQueryParams?.search && defaultSort.queryParams === searchOrderParams.queryParams
  ? ['cards:sideSearch', searchQueryParams.search]
  : [queryParams]

  //Search cards
  const {
    data: cards,
    isLoading: isLoadingSearch,
    isIdle,
    totalItems,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = usePaginatedCards(
    paginatedCardsQueryKey,
    queryParams,
    {
      staleTime: 1000,
      enabled: !!searchQueryParams
    }
  )

  const queryClient = useQueryClient()

  // Add tag to card
  const { mutate: patchCardMutation, isLoading: isLoadingAddTag } = useMutation(
    (params: PatchCardsParams) => patchCard(params),
    {
      onSuccess: (data) => {
      }
    }
  )

  //Delete tag from card
  const { mutate: deleteTag, isLoading: isLoadingDeleteTag } = useMutation(
    (params: PatchCardsParams) => patchCard(params),
      {
        onSuccess: (data) => {
          queryClient.refetchQueries(paginatedCardsQueryKey)
        }
      }
  )


  const onSubmitAddTag = (formData: AddPricingTagInputs) => {
    const body = {pricingTag: formData?.pricingTag}
    patchCardMutation({
      cardIri: formData.cardIri,
      body
    })
  }

  const onSubmitSearch = (formData: SearchCardInputs) => {
    setSearchQueryParams({
      name: formData.cardName,
      'pricingTag.id': formData.pricingTag,
      'taggedBy.id': formData.collaborator,
      isAfterDeadline: formData.afterDeadline
    })
  }

  const handleDelete = (iri: string) => {
    const body = {pricingTag: null}
    deleteTag({
      cardIri: iri,
      body
    })
  }

  const columns: Columns<Card, CardPropertiesQueryParams> = [
    {
      key: 'name',
      name: t('common.label.nameFr'),
      sortable: true,
      orderQueryParam: 'order[name]',
      decorator: (card) => (
        <Cell
          onMouseEnter={() => {
            setIsProductImageLoaded(false)
            setCardImage(
              card.defaultVariation?.imageUrl
            )
          }}
          onMouseLeave={() => {
            setIsProductImageLoaded(false)
            setCardImage(undefined)
          }}
        >
          <Text>{card.name}</Text>
        </Cell>
      )
    },
    {
      key: 'nameEn',
      name: t('common.label.nameEn'),
      sortable: true,
      orderQueryParam: 'order[englishName]',
      decorator: (card) => (
        <Cell
          onMouseEnter={() => {
            setIsProductImageLoaded(false)
            setCardImage(
              card.defaultVariation?.imageUrl
            )
          }}
          onMouseLeave={() => {
            setIsProductImageLoaded(false)
            setCardImage(undefined)
          }}
        >
          <Text>{card.englishName}</Text>
        </Cell>
      )
    },
    {
      key: 'range',
      name: t('common.label.range'),
      sortable: true,
      orderQueryParam: 'order[family.name]',
      decorator: (card) => {
        const icon = getFamilyIcon(card.family?.codeName)
        const name = getFamilyName(card.family?.codeName)
        return (
          <Cell>
            <Text>{icon && <Svg svg={icon} size="2rem" title={name} />}</Text>
          </Cell>
        )
      }
    },
    {
      key: 'tag',
      name: t('common.label.tag'),
      sortable: true,
      orderQueryParam: 'order[pricingTag.id]',
      decorator: (card) => (
        <Cell>
          <Text>{card.pricingTag?.name}</Text>
        </Cell>
      )
    },
    {
      key: 'taggedBy',
      name: t('common.label.taggedBy'),
      decorator: (card) => (
        <Cell>
          <Text>{card.taggedBy?.firstname + ' ' + card.taggedBy?.lastname}</Text>
        </Cell>
      )
    },
    {
      key: 'taggedAt',
      name: t('common.label.taggedAt'),
      sortable: true,
      orderQueryParam: 'order[taggedAt]',
      decorator: (card) => (
        <Cell>
          <Text>{formatDateAndHour(card.taggedAt)}</Text>
        </Cell>
      )
    },
    {
      key: 'priceCheckedAt',
      name: t('common.label.priceCheckedAt'),
      sortable: true,
      orderQueryParam: 'order[priceCheckedAt]',
      decorator: (card) => (
        <Cell>
          <Text>{formatDateAndHour(card.priceCheckedAt)}</Text>
        </Cell>
      )
    },
    {
      key: 'action',
      decorator: (card) => (
        <div>
          <Button 
            icon={DeleteIcon} 
            shape="circle" 
            isLoading={isLoadingDeleteTag} 
            onClick={() => handleDelete(card['@id']!)}
            disabled={!card.pricingTag}
          />
        </div>
      )
    }
  ]
  
  return (
    <Section>
      <MainTitle title={t('page.pricingTag.title')} />
      <AddPricingTagForm onSubmit={onSubmitAddTag} isLoading={isLoadingAddTag} />
      <SearchCardForm onSubmit={onSubmitSearch} isLoading={isLoadingSearch} />
      {!isIdle && (
        <CardLayout>
          <Listing
            columns={columns}
            data={cards}
            totalCount={totalItems}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            emptyText={t('page.card.manage.listing.emptyText')}
            title={t('page.card.manage.listing.title', { count: totalItems ?? 0 })}
            countText={t('page.card.manage.listing.countText', { count: cards?.length ?? 0, total: totalItems ?? 0 })}
            defaultSort={defaultSort}
            onOrderChanged={setSearchOrderParams}
          />
          <FollowMouseContent show={!!cardImage}>
            <ProductImageContainer>
              {!isProductImageLoaded && <StyledLoader size="3em" />}
              <Image src={cardImage} onLoad={() => setIsProductImageLoaded(true)} />
            </ProductImageContainer>
          </FollowMouseContent>
        </CardLayout>
      )}
    </Section>
  )
}
