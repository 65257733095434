import styled from 'styled-components'

export const PaymentTabCardContainer = styled.div`
  max-width: 100%;
  display: grid;
  gap: 2rem;

  grid-template-columns: 2fr 1fr 1fr;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`
