import {
  FetchCustomersQueryParams,
  getCustomersQuickSearchUri,
  getCustomersUri,
  usePaginatedCustomers
} from 'api/customers'
import BottomMessage from 'components/BottomMessage/BottomMesssage'
import { Section } from 'components/Section/Section.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import Listing from 'features/Listing/Listing'
import { RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { Customer } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { Grid, StyledCardLayout } from './CustomerManagement.styles'
import CustomerRegisterForm from './forms/CustomerRegisterForm'
import CustomerSearchForm from './forms/CustomerSearchForm '

const defaultOrder: OrderParam<FetchCustomersQueryParams> = {
  sortedBy: 'lastname',
  direction: SortingDirection.Asc,
  queryParams: { 'order[lastname]': SortingDirection.Asc }
}

function CustomerManagement() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation() as Location & { state: null | { search: string } }
  const [searchInputs, setSearchInputs] = useState<FetchCustomersQueryParams | null>(null)
  const [searchedEmail, setSearchedEmail] = useState<string | undefined>(undefined)
  const [orderParams, setOrderParams] = useState<OrderParam<FetchCustomersQueryParams>>(defaultOrder)

  const searchQuery = searchInputs?.search
    ? getCustomersQuickSearchUri(searchInputs?.search, {
        ...orderParams.queryParams,
        'order[firstname]': SortingDirection.Asc
      })
    : getCustomersUri({
        ...searchInputs,
        ...orderParams.queryParams,
        'order[firstname]': SortingDirection.Asc
      })

  const {
    data: customers,
    totalItems,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    isIdle
  } = usePaginatedCustomers(
    searchInputs?.search
      ? ['customers:sideSearch', searchInputs?.search, orderParams.queryParams]
      : ['customers', searchQuery],
    searchQuery,
    {
      staleTime: 1000,
      enabled: !!searchInputs,
      onSuccess: (data, total) => {
        if (total === 1) {
          navigate(`/customer-details/${data[0].id}`)
        }

        if (total === 0) {
          setSearchedEmail(searchInputs?.email)
        }
      }
    }
  )

  useEffect(() => {
    // Listen search made from order sidebar search
    // if it change, update queryKey to match the request made by the search bar
    // and display results directly
    if (location.state) {
      setSearchInputs(location.state)
      // as we consumed current state, we reset it for futur reload
      navigate('/customer-manage', { replace: true, state: null })
    }
  }, [location.state, navigate])

  const handleSearch = async ({ email, firstname, lastname, zipCode }: FetchCustomersQueryParams) => {
    setSearchInputs({
      name: [lastname, firstname].join(' '),
      email,
      zipCode
    })
    setSearchedEmail(undefined)
  }

  const handleSort = (orderParam: OrderParam<FetchCustomersQueryParams>) => {
    setOrderParams(orderParam)
  }

  const columns: Column<Customer, FetchCustomersQueryParams>[] = [
    {
      name: t('common.label.id'),
      key: 'id',
      decorator: (customer: Customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          <Text color="secondary" fontWeight="light">
            {customer.id}
          </Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.lastName'),
      key: 'lastname',
      sortable: true,
      orderQueryParam: 'order[lastname]',
      decorator: (customer: Customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          <Text>{customer.lastname}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.firstName'),
      key: 'firstname',
      decorator: (customer: Customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          <Text>{customer.firstname}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.email'),
      key: 'email',
      decorator: (customer: Customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          <Text>{customer.email}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.address'),
      key: 'address',
      decorator: (customer: Customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          <Text fontWeight="light" color="secondary">
            {customer.address}
          </Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.zipCodeAbbreviated'),
      key: 'zipCode',
      decorator: (customer: Customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          <Text fontWeight="light" color="secondary">
            {customer.zipCode}
          </Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.city'),
      key: 'city',
      decorator: (customer: Customer) => (
        <ClickableCell route={RouteName.CustomerDetails} value={customer.id}>
          <Text fontWeight="light" color="secondary">
            {customer.city}
          </Text>
        </ClickableCell>
      )
    }
  ]

  return (
    <Section>
      <MainTitle title={t('page.customer.manage.title')} />
      <Grid>
        <CustomerSearchForm onSearch={handleSearch} isSubmitting={isFetching && !isFetchingNextPage} />

        <CustomerRegisterForm defaultEmail={searchedEmail} />

        {!isIdle && (
          <StyledCardLayout>
            <Listing
              columns={columns}
              data={customers}
              title={t('page.customer.manage.count', {
                count: totalItems ?? 0
              })}
              isFetchingNextPage={isFetchingNextPage}
              isFetching={isFetching && !totalItems}
              fetchNextPage={fetchNextPage}
              emptyText={t('page.customer.manage.empty')}
              countText={t('page.customer.manage.countText', {
                count: customers.length,
                total: totalItems ?? 0
              })}
              hasNextPage={hasNextPage}
              totalCount={totalItems}
              defaultSort={defaultOrder}
              onOrderChanged={handleSort}
            />
          </StyledCardLayout>
        )}
      </Grid>
      <BottomMessage text={t('common.requiredLabel')} />
    </Section>
  )
}

export default CustomerManagement
