import { GetOrderByIdResponse } from 'api/orders'
import { FormattedNumberComponent } from 'components/InputFormattedNumber/InputFormattedNumber'
import { BlockLayout, RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { PlayinPIcon, WarehouseIcon } from 'constants/icons'
import { useTranslation } from 'react-i18next'
import { ShippingTypeName } from 'types/playInApiInterfaces'
import { LabelOption, StockBlock, StockLabel } from './ShippingSection.style'

interface ShippingSectionProps {
  order?: GetOrderByIdResponse
}

const ShippingSectionDisplay = ({ order }: ShippingSectionProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Title2>{t('page.order.detail.shippingSection.title')}</Title2>
      <BlockLayout>
        <InputLayout className="no-border" label={t('common.label.shippingMode')}>
          <LabelOption>
            <Text fontWeight="bolder" size="1.8rem">
              {order?.shippingMode?.name}
            </Text>
            <FormattedNumberComponent value={order?.shippingFees} decimalScale={2} prefix="(" suffix={'\u00a0€)'} />
          </LabelOption>
        </InputLayout>
      </BlockLayout>

      {(order?.prioritySend || order?.shippingMode?.shippingType === ShippingTypeName.FAST) && (
        <BlockLayout>
          <Text color="danger" fontWeight="bold">
            {t('page.order.detail.prioritySend').toUpperCase()}
          </Text>
        </BlockLayout>
      )}
      <RowContainer>
        <StockBlock>
          <StockLabel>{t('common.label.stockOrigin')}</StockLabel>
          <RowContainer>
            {order?.stock?.warehouse ? (
              <WarehouseIcon width="25" height="25" />
            ) : (
              <PlayinPIcon width="25" height="25" />
            )}
            <Text size="1.8rem" fontWeight="bolder">{`${t('common.label.stock')} ${order?.stock?.name}`}</Text>
          </RowContainer>
        </StockBlock>
      </RowContainer>
    </>
  )
}

export default ShippingSectionDisplay
