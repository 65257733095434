import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import styled from 'styled-components'

export const HiddenOverflowSection = styled(Section)`
  overflow: hidden;
`

export const MainCardContainer = styled(BlockLayout)`
  display: grid;
  gap: 2rem;

  @media screen and (${(props) => props.theme.screenWidth.minLaptopScreen}) and (${(props) =>
      props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
    & > :first-child {
      grid-column: span 2;
    }
  }

  @media screen and (${(props) => props.theme.screenWidth.minDesktopScreen}) {
    grid-template-columns: 4fr 3fr 3fr;
  }

  @media screen and (${(props) => props.theme.screenWidth.minLargeScreen}) {
    grid-template-columns: 5fr 3fr 2fr;
  }
`

export const DuoSectionGrid = styled.div`
  display: grid;
  gap: 5rem;

  grid-template-columns: 1fr 1fr;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`

// FIXME: Cette card avait besoin d'une largeur minimale spécifique pour les Tabs
export const AddressCardLayout = styled(CardLayout)`
  width: 100%;
  margin-bottom: 0;

  .MuiTab-root {
    min-width: 50%;
    max-width: 100%;
  }
`

export const ShippingCardLayout = styled(CardLayout)`
  width: 100%;
  margin-bottom: 0;
`

export const BillingCardLayout = styled(CardLayout)`
  width: 100%;
  margin-bottom: 0;
`
