import { API_ROUTES } from 'constants/configs'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { LogEntry } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'

type FetchLogEntriesQueryParams = Api.GetLogEntries.RequestParams

const getLogEntriesUri = (params: FetchLogEntriesQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.logEntries.root, queryString].join('?')
}

export const useGetLogEntriesByOrder = (orderIri: string, opts: { enabled: boolean }) => {
  return usePaginatedQuery<LogEntry>(`log-entries:${orderIri}`, getLogEntriesUri({ target: orderIri }), { ...opts })
}
