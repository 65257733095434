import styled from 'styled-components'
import theme from 'theme/theme'

export const CardLayout = styled.div`
  width: 100%;

  padding: 2rem;
  margin-bottom: 2rem;

  background-color: ${theme.colors.white};
  border-radius: 0.2rem;
  border: 1px solid ${theme.colors.grey3};
  box-shadow: 1px 1px 4px ${theme.colors.grey3};
  background-color: ${theme.colors.white};
`
