import { stockByIdQueryKey, StockEntryFromStock } from 'api/stock'
import { patchStockEntry, PatchStockEntryBody, StockTransfersDetail, useGetTransfers } from 'api/stockEntry'
import QuantityDetailModal from 'app/StockDetails/modals/QuantityDetailModal'
import classNames from 'classnames'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import ApiResponseIcon from 'components/Icon/ApiResponseIcon'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import ToggleIcon from 'components/Tooltip/ToggleIcon'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import CanAccess from 'features/Permissions/CanAccess'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEventEmitter } from 'hooks/useEventEmitter'
import SingleInputForm from 'hooks/useSingleInputForm'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Stock } from 'types/entities'
import { UnmodifiableStockStatus } from 'types/enums'
import { EventType } from 'types/events'
import { ProductJsonldStockReadItemStockRead, RoleAction, StockStatus } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { RowCell, SplitCell, StyledSingleInputForm, StyledSortedTable } from '../../StockDetails.styles'
import { ToolTipContent, TooltipLine } from './StockEntriesTable.styles'

interface Props {
  selectedProducts: Array<number>
  setSelectedProducts: React.Dispatch<React.SetStateAction<number[]>>
  stock: Stock
  deletableEntries: number[]
}

const StockEntriesTable = ({ selectedProducts, setSelectedProducts, stock, deletableEntries }: Props) => {
  const { t } = useTranslation()
  const { checkMatch } = useCheckMatch()
  const queryClient = useQueryClient()
  const [stockTransfersDetail, setStockTransfersDetail] = useState<StockTransfersDetail[]>([])
  const [stockTransferProduct, setStockTransferProduct] = useState<ProductJsonldStockReadItemStockRead | null>()
  const [invalidProducts, setInvalidProducts] = useState<number[]>([])
  const cantEditEntries = UnmodifiableStockStatus.includes(stock.status!)

  const { transfers, getStockEntryTransfersQuantity } = useGetTransfers(stock.id!, stock.status)

  const {
    mutate: updateStockEntry,
    isLoading,
    isSuccess,
    isError
  } = useMutation(({ id, body }: { id: number; body: PatchStockEntryBody }) => patchStockEntry(id, body), {
    onSuccess: (data, { id }) => {
      const prev = queryClient.getQueryData<Stock>(stockByIdQueryKey(stock?.id!))

      queryClient.setQueryData(stockByIdQueryKey(stock?.id!), {
        ...prev,
        entries: prev?.entries.map((entry) => (entry.id === id ? { ...entry, ...data } : entry))
      })
    },
    emitDefaultSuccessNotification: false
  })

  const [showInputIcon, setShowInputIcon] = useState<string | null>()

  useEffect(() => {
    const timeout = setTimeout(() => setShowInputIcon(null), 5000)
    return () => clearTimeout(timeout)
  }, [showInputIcon])

  const { useListener } = useEventEmitter()

  useListener(EventType.PatchStockError, (errors) => {
    // We're only interested in product errors here
    setInvalidProducts(errors.filter((error) => error.key === 'entry').map((error) => error.value))
  })

  const columns = useMemo(() => {
    const col: Array<Column<StockEntryFromStock>> = [
      {
        key: 'checkbox',
        decorator: (entry) => {
          return (
            <Cell>
              <Checkbox
                className={classNames({
                  checked: selectedProducts.includes(entry.id!),
                  disabled: !deletableEntries.includes(entry.id!)
                })}
                onClick={() => {
                  if (selectedProducts.includes(entry.id!)) {
                    setSelectedProducts((prev) => prev.filter((item) => item !== entry.id))
                  } else {
                    setSelectedProducts((prev) => [...prev, entry.id!])
                  }
                }}
              />
            </Cell>
          )
        }
      },
      {
        name: t('common.label.product'),
        key: 'productName',
        decorator: (entry) => (
          <ClickableCell route={RouteName.ProductDetails} value={entry?.product?.id!}>
            <Text>{entry?.product?.name!}</Text>
          </ClickableCell>
        )
      },
      {
        name: t('common.label.tva'),
        key: 'tva',
        decorator: (entry) => (
          <Cell>
            <TextNumber value={entry?.tax?.rate} color="secondary" fontWeight="light" suffix=" %" />
          </Cell>
        )
      },
      {
        name: t('common.label.sellPrice'),
        key: 'price',
        decorator: (entry) => (
          <SplitCell>
            <TextNumber
              value={entry?.product?.price}
              color="secondary"
              fontWeight="light"
              suffix=" €"
              decimalScale={2}
            />
          </SplitCell>
        )
      },
      {
        name: stock.status === StockStatus.Created ? t('common.label.quantity') : t('common.label.initialQuantity'),
        key: 'quantity',
        decorator: (entry) => {
          const stockEntryTransfers = (transfers || []).filter((transfer) => transfer?.sourceId === entry.id)
          // if stock entry has transfered items, we need to sum both current stock quantity + transfered quantity

          const { quantityTotal, quantityInProgress, quantityDone } = getStockEntryTransfersQuantity(entry.id)
          return (
            <RowCell>
              <CanAccess
                permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]}
                deniedExtraCondition={cantEditEntries}
                allowedComponent={
                  <>
                    <StyledSingleInputForm
                      type="number"
                      defaultValue={entry.quantity! + quantityTotal}
                      onSubmit={async ({ inputValue }) => {
                        setShowInputIcon(`${entry.id} quantity`)
                        updateStockEntry({
                          id: entry.id!,
                          // we display quantity + transfers quantity. so we fix it here before saving
                          body: { quantity: parseInt(inputValue) - quantityTotal}
                        })
                      }}
                      displayError={false}
                      fieldSchema={yup
                        .number()
                        .min(stock.status !== StockStatus.Created ? quantityTotal + entry.soldQuantity! : 1)}
                    />

                    <ApiResponseIcon
                      showIcon={showInputIcon === `${entry.id} quantity`}
                      isLoading={isLoading}
                      isError={isError}
                      isSuccess={isSuccess}
                    />
                  </>
                }
                deniedComponent={
                  <>
                    <TextNumber value={entry.quantity! + quantityTotal} />
                  </>
                }
              />
              {stock?.status !== StockStatus.Created && !!stockEntryTransfers.length && (
                <>
                  <TooltipHover
                    id="stockTransfersTooltip"
                    content={
                      <ToolTipContent>
                        <TooltipLine className="head">
                          <Text fontWeight="medium">{t('page.stock.detail.transferredFrom.initialQuantity')}</Text>
                          <TextNumber value={entry.quantity! + quantityTotal} fontWeight="medium" />
                        </TooltipLine>
                        <TooltipLine>
                          <Text fontWeight="light">{t('page.stock.detail.transferredFrom.transferedInProgress')}</Text>
                          <TextNumber value={quantityInProgress} fontWeight="light" />
                        </TooltipLine>
                        <TooltipLine>
                          <Text fontWeight="light">{t('page.stock.detail.transferredFrom.transferedQuantity')}</Text>
                          <TextNumber value={quantityDone} fontWeight="light" />
                        </TooltipLine>
                        <TooltipLine>
                          <Text fontWeight="light">{t('page.stock.detail.transferredFrom.soldQuantity')}</Text>
                          <TextNumber value={entry.soldQuantity} fontWeight="light" />
                        </TooltipLine>
                        <TooltipLine className="tail">
                          <Text fontWeight="medium">{t('page.stock.detail.transferredFrom.remainingQuantity')}</Text>
                          <TextNumber fontWeight="medium" value={entry.remainingQuantity} />
                        </TooltipLine>
                      </ToolTipContent>
                    }
                  >
                    <ToggleIcon
                      onClick={() => {
                        setStockTransfersDetail(stockEntryTransfers)
                        setStockTransferProduct(entry.product)
                      }}
                    />
                  </TooltipHover>
                </>
              )}
            </RowCell>
          )
        }
      },
      {
        name: t('common.label.unitPriceTaxFree'),
        key: 'unitPrice',
        decorator: (entry) => (
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]}
            deniedExtraCondition={cantEditEntries}
            allowedComponent={
              <RowCell>
                <SingleInputForm
                  type="formattedNumber"
                  decimalScale={4}
                  suffix=" €"
                  placeholder="0.00 €"
                  defaultValue={entry.buyPriceVatExcluded}
                  onSubmit={async ({ inputValue }) => {
                    setShowInputIcon(`${entry.id} unitPrice`)
                    return updateStockEntry({
                      id: entry.id!,
                      body: { buyPriceVatExcluded: inputValue || 0 }
                    })
                  }}
                  inputClassName={classNames({
                    'is-invalid': invalidProducts.includes(entry?.id!)
                  })}
                />
                <ApiResponseIcon
                  showIcon={showInputIcon === `${entry.id} unitPrice`}
                  isLoading={isLoading}
                  isError={isError}
                  isSuccess={isSuccess}
                />
              </RowCell>
            }
            deniedComponent={
              <Cell>
                <TextNumber value={entry.buyPriceVatExcluded} decimalScale={4} suffix=" €" />
              </Cell>
            }
          />
        )
      },
      {
        name: t('common.label.totalPriceTaxFree'),
        key: 'totalPrice',
        decorator: (entry) => {
          const { quantityTotal } = getStockEntryTransfersQuantity(entry.id)
          // if stock entry has transfered items, we need to sum both current stock quantity + transfered quantity

          const quantity =
            stock?.status !== StockStatus.Created && quantityTotal
              ? entry.quantity! + quantityTotal
              : entry.quantity

          return (
            <CanAccess
              permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]}
              deniedExtraCondition={cantEditEntries}
              allowedComponent={
                <RowCell>
                  <SingleInputForm
                    type="formattedNumber"
                    decimalScale={4}
                    suffix=" €"
                    placeholder="0.00 €"
                    defaultValue={entry.buyPriceVatExcluded! * quantity!}
                    disabled={quantity === 0}
                    onSubmit={async ({ inputValue }) => {
                      setShowInputIcon(`${entry.id} totalPrice`)
                      return updateStockEntry({
                        id: entry.id!,
                        body: { buyPriceVatExcluded: inputValue / quantity! }
                      })
                    }}
                    inputClassName={classNames({
                      'is-invalid': invalidProducts.includes(entry?.id!)
                    })}
                  />
                  <ApiResponseIcon
                    showIcon={showInputIcon === `${entry.id} totalPrice`}
                    isLoading={isLoading}
                    isError={isError}
                    isSuccess={isSuccess}
                  />
                </RowCell>
              }
              deniedComponent={
                <Cell>
                  <TextNumber value={entry.buyPriceVatExcluded! * quantity!} decimalScale={3} suffix=" €" />
                </Cell>
              }
            />
          )
        }
      }
    ]

    if (cantEditEntries || !checkMatch([RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS])) {
      // remove checkbox item
      col.shift()
    }

    if ([StockStatus.Validated, StockStatus.Canceled, StockStatus.Treated].includes(stock.status!)) {
      // Insert totalStock & totalQuantity columns
      col.splice(
        // if validated we still have the first checkbox column
        stock.status === StockStatus.Validated && checkMatch([RoleAction.ROLE_ACTION_STOCK_EDIT_CONTENTS]) ? 5 : 4,
        0,
        {
          name: t('common.label.remainingQuantity'),
          key: 'remainingQuantity',
          decorator: (entry) => (
            <Cell>
              <TextNumber value={entry.remainingQuantity} prefix="(" suffix=")" />
            </Cell>
          )
        }
      )
    }

    return col
  }, [
    t,
    selectedProducts,
    setSelectedProducts,
    updateStockEntry,
    isLoading,
    isError,
    isSuccess,
    showInputIcon,
    stock,
    transfers,
    cantEditEntries,
    invalidProducts,
    checkMatch,
    getStockEntryTransfersQuantity,
    deletableEntries
  ])

  return (
    <>
      <StyledSortedTable columns={columns} data={stock.entries} isLoading={false} />
      <QuantityDetailModal
        open={!!stockTransfersDetail.length}
        onClose={() => setStockTransfersDetail([])}
        stockTransfersDetail={stockTransfersDetail}
        stockTransferProduct={stockTransferProduct}
      />
    </>
  )
}

export default StockEntriesTable
