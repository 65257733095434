import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'

type GetRestockPrintsQueryParams = Api.GetRestockPrints.RequestQuery
export const getRestockPrintsUri = (params: GetRestockPrintsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.restockPrints.root, queryString].join('?')
}
export type GetRestockPrintsQueryResponse = Api.GetRestockPrints.ResponseBody['hydra:member'][0]
export const useRestockPrintsPaginated = (
  queryKey: QueryKey,
  query: string,
  options?: PaginatedHookOptions<GetRestockPrintsQueryResponse>
) => usePaginatedQuery<GetRestockPrintsQueryResponse>(queryKey, query, options)
