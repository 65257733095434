import { yupResolver } from '@hookform/resolvers/yup'
import { useDownloadFile } from 'api/basePlayInApi'
import { useGetEditions } from 'api/cardEditions'
import { countPurchaseOrders } from 'api/orders'
import { ColumnGapContainer, ErrorDisplay, PreviewCounterText } from 'app/OrderPrint/utils.style'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import Loader from 'components/Loader/Loader'
import Select from 'components/Select/Select'
import { API_ROUTES } from 'constants/configs'
import { PrintIcon } from 'constants/icons'
import { useEmit } from 'hooks/useEventEmitter'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Store } from 'types/entities'
import { EventType } from 'types/events'
import { stringify } from 'utils/queryParams'
import * as yup from 'yup'
import { Shipping } from '../../types'
import { PreviewButtonContainer } from '../../utils.style'
import { PreorderFormGrid } from './CardsPreorderPrintForm.styles'

type PreorderFormInputs = {
  edition: number | null
  language: Languages | null
  linkEditionOnly: boolean | null
  shippingMode: Shipping | null
}

const schema = yup.object().shape({
  edition: yup.number().required(),
  language: yup.string().trim().required(),
  linkEditionOnly: yup.string().trim().required(),
  shippingMode: yup.string().trim().required()
})

enum Languages {
  Fr = 'Fr',
  En = 'En',
  FrEn = 'FrEn',
  All = 'All'
}

type Props = {
  store: Store
  active: boolean
}

const PreorderForm = ({ store, active }: Props) => {
  const { t } = useTranslation()
  const { downloadFile, isLoading } = useDownloadFile()
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch
  } = useForm<PreorderFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      edition: null,
      language: null,
      linkEditionOnly: null,
      shippingMode: null
    }
  })

  const [watchEdition, watchLng, watchLinked, watchShipping] = watch([
    'edition',
    'language',
    'linkEditionOnly',
    'shippingMode'
  ])
  const isFormFilled = !!watchEdition && !!watchLng && _.isBoolean(watchLinked) && !!watchShipping

  useEffect(() => reset(), [store, reset])

  const { data: editions } = useGetEditions(
    { 'releasedAt[after]': 'now', withCards: true },
    {
      enabled: active
    }
  )

  const emit = useEmit()

  const cardPreorderPayload = useMemo(() => {
    if (isFormFilled) {
      const selectedEdition = editions?.find((e) => e.id === watchEdition)

      const params: {
        preorder: boolean
        withCards: boolean
        'cardEdition[]': number[]
        linkEditionOnly: boolean
        releasedDateMax?: string
        name?: string
        withProduct?: boolean
        'notClickAndCollect[]'?: number[]
        'clickAndCollect[]'?: number[]
      } = {
        preorder: true,
        withCards: true,
        'cardEdition[]': [watchEdition],
        linkEditionOnly: watchLinked,
        releasedDateMax: selectedEdition?.releasedAt
      }

      switch (watchLng) {
        case Languages.Fr:
          params['cardLang[]'] = [Languages.Fr]
          break
        case Languages.En:
          params['cardLang[]'] = [Languages.En]
          break
        case Languages.FrEn:
          params['cardLang[]'] = [Languages.Fr, Languages.En]
          break
      }

      switch (watchShipping) {
        case Shipping.Classic:
          params.name = 'Préco cartes envoi'
          params['notClickAndCollect[]'] = [1, 3]
          break
        case Shipping.BnfClickAndCollect:
          params.name = 'Préco cartes Paris BNF'
          params.withProduct = false
          params['clickAndCollect[]'] = [1]
          break
        case Shipping.RivoliClickAndCollect:
          params.name = 'Préco cartes Paris Rivoli'
          params.withProduct = false
          params['clickAndCollect[]'] = [3]
          break
      }

      return params
    }
    return {}
  }, [editions, watchEdition, watchLng, watchLinked, watchShipping, isFormFilled])

  const onSubmit = async () => {
    await downloadFile(`${API_ROUTES.downloads.multipleOrders}/${store.id}?${stringify(cardPreorderPayload)}`)
    emit(EventType.PrintOrder, null)
  }

  const { data: preOrders, isFetching: preOrdersLoading } = useQuery(
    ['countOrderPrint', cardPreorderPayload],
    () => countPurchaseOrders(store.id, cardPreorderPayload),
    {
      enabled: active && isFormFilled
    }
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ColumnGapContainer>
        <PreorderFormGrid>
          <Select
            id="edition"
            className={classnames({ 'is-invalid': errors.edition })}
            label={`${t('common.label.cardEdition')} *`}
            placeholder={t('common.select.defaultOptions.cardEdition')}
            control={control}
            options={(editions || []).map((edition) => ({ value: edition.id!, label: edition.name! }))}
          />
          <Select
            id="shippingMode"
            className={classnames({ 'is-invalid': errors.shippingMode })}
            label={`${t('common.label.shippingMode')} *`}
            placeholder={t('common.select.defaultOptions.shippingMode')}
            control={control}
            options={[
              { value: Shipping.Classic, label: t('page.order.print.details.shippingType.classic') },
              {
                value: Shipping.BnfClickAndCollect,
                label: t('page.order.print.details.shippingType.shop.bnf')
              },
              {
                value: Shipping.RivoliClickAndCollect,
                label: t('page.order.print.details.shippingType.shop.rivoli')
              }
            ]}
          />
          <Select
            id="linkEditionOnly"
            className={classnames({ 'is-invalid': errors.linkEditionOnly })}
            label={t('page.order.print.details.orderContent.title')}
            placeholder={t('page.order.print.details.orderContent.placeholder')}
            control={control}
            options={[
              { value: true, label: t('page.order.print.details.orderContent.editionOnly') },
              { value: false, label: t('page.order.print.details.orderContent.variousContent') }
            ]}
          />
          <Select
            id="language"
            className={classnames({ 'is-invalid': errors.language })}
            label={t('page.order.print.details.labels.cardLanguage')}
            placeholder={t('common.select.defaultOptions.lang')}
            control={control}
            options={[
              { value: Languages.Fr, label: t('common.languages.french') },
              { value: Languages.En, label: t('common.languages.english') },
              { value: Languages.FrEn, label: t('page.order.print.details.labels.frenchEnglish') },
              { value: Languages.All, label: t('page.order.print.details.labels.allLanguages') }
            ]}
          />
        </PreorderFormGrid>
        <ErrorDisplay
          message={t('page.order.print.details.errors.fillAllFields')}
          error={errors['edition'] || errors['language'] || errors['shippingMode'] || errors['linkEditionOnly']}
        />
        <PreviewButtonContainer>
          <Button
            variant="warning"
            buttonType="submit"
            shape="default"
            icon={PrintIcon}
            size="long"
            isLoading={isLoading}
          >
            {t('page.order.print.actions.printPreorderCards')}
          </Button>
          {isFormFilled &&
            (preOrdersLoading ? (
              <Loader />
            ) : (
              <PreviewCounterText>
                {t('page.order.print.details.pendingOrdersCount', { count: preOrders.totalItems })}
              </PreviewCounterText>
            ))}
        </PreviewButtonContainer>
      </ColumnGapContainer>
    </form>
  )
}

export default PreorderForm
