import { yupResolver } from '@hookform/resolvers/yup'
import { PatchStoreBody } from 'api/stores'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'
import Button, { Props as ButtonProps } from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { UseMutateFunction } from 'react-query'
import { StoreDetails } from 'types/entities'
import { useFormSibblingFields } from 'utils/formHelper'
import * as yup from 'yup'
import { FormSection } from '../StoreSeoTab'
import { FieldsWrapper, FormCard } from '../StoreSeoTab.style'

const schema = yup.object().shape(
  {
    description: yup
      .string()
      .trim()
      .nullable()
      .when('descriptionEn', { is: (value) => !!value, then: (schema) => schema.required() }),
    descriptionEn: yup
      .string()
      .trim()
      .nullable()
      .when('description', { is: (value) => !!value, then: (schema) => schema.required() })
  },
  [['description', 'descriptionEn']]
)

interface FormSectionInputs {
  description?: string | null
  descriptionEn?: string | null
}

interface Props {
  getSubmitButtonProps: (id: FormSection) => ButtonProps
  mutation: UseMutateFunction<StoreDetails, AxiosResponse<never>, PatchStoreBody>
  store: StoreDetails
}

const getDefaultValues = (store: StoreDetails): FormSectionInputs => ({
  description: store?.description,
  descriptionEn: store?.descriptionEn
})

export default function StoreSellFormSection({ mutation, getSubmitButtonProps, store }: Props) {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    trigger
  } = useForm<FormSectionInputs>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(store)
  })

  const onSubmit = (formData: FormSectionInputs) => {
    mutation(formData)
  }

  useFormSibblingFields([['description', 'descriptionEn']], { watch, trigger })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormCard>
        <Title2>{t('page.stores.details.storeSeo.sections.sellArea')}</Title2>
        <FieldsWrapper>
          <div>
            <RichTextEditor
              control={control}
              id="description"
              label={t('page.stores.details.storeSeo.labels.description')}
              className={classNames({ 'is-invalid': errors.description })}
              tooltip={
                <Tooltip id="fieldTooltip">
                  <Trans i18nKey="page.stores.details.storeSeo.tooltips.description" />
                </Tooltip>
              }
            />
            <BasicFieldValidationError error={errors.description} message={t('form.validation.required.genericFr')} />
          </div>

          <div>
            <RichTextEditor
              control={control}
              id="descriptionEn"
              label={t('page.stores.details.storeSeo.labels.descriptionEn')}
              className={classNames({ 'is-invalid': errors.descriptionEn })}
            />
            <BasicFieldValidationError error={errors.descriptionEn} message={t('form.validation.required.genericEn')} />
          </div>
        </FieldsWrapper>
        <Button {...getSubmitButtonProps('description')} />
      </FormCard>
    </form>
  )
}
