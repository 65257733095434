import { yupResolver } from '@hookform/resolvers/yup'
import { getCardsUri } from 'api/cards'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { Option } from 'components/InputAutoComplete/InputAutoComplete.styles'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { SearchIcon } from 'constants/icons'
import { usePricingTagsOptions } from 'hooks/entityHooks/cardHooks'
import { useCollaboratorsOptions } from 'hooks/entityHooks/collaboratorHooks'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CardProduct } from 'types/entities'
import { hasOneOfFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import { FormContainer } from '../../StoreEventManage/StoreEventManage.styles'
import { SearchCardInputs } from '../PricingTag'

interface Props {
  onSubmit: (formData: SearchCardInputs) => void
  isLoading?: boolean
}

const schema = yup
  .object()
  .shape({
    cardName: yup.string().trim(),
    pricingTag: yup.number(),
    collaborator: yup.number(),
    afterDeadline: yup.boolean()
  })
  .test(hasOneOfFieldsMethod())

export default function SearchCardForm({ onSubmit, isLoading }: Props) {
  const { t } = useTranslation()

  const formatCardNameOption = (card: CardProduct, onClick: () => void) => (
    <Option onClick={onClick}>
      <Text>{card.name}</Text>
      <Text fontStyle="italic">{`(${card.englishName})`}</Text>
    </Option>
  )

  const {
    handleSubmit,
    formState: { errors, touchedFields },
    control
  } = useForm<SearchCardInputs>({
    resolver: yupResolver(schema)
  })

  const { pricingTagsOptions } = usePricingTagsOptions(false)
  const { collaboratorOptions } = useCollaboratorsOptions()

  const handleForm = (formData: SearchCardInputs) => {
    onSubmit(formData)
  }


  return (
    <CardLayout>
      <Title2>{t('page.pricingTag.titleSearch')}</Title2>
      <FormContainer onSubmit={handleSubmit(handleForm)}>
        <InputAutoComplete
          id="cardName"
          className={errors.cardName ? 'is-invalid' : ''}
          placeholder={t('page.order.detail.orderEntries.table.placeholders.cardName')}
          labelKey="name"
          valueKey="name"
          control={control}
          isTouched={touchedFields.cardName}
          getUrlFromSearch={(search) => getCardsUri({ search: search })}
          formatOption={formatCardNameOption}
          label={t('common.label.cardName')}
        />
        <Select
          id="pricingTag"
          label={t('common.label.tag')}
          options={pricingTagsOptions}
          control={control}
          placeholder={t('common.select.defaultOptions.tag')}
          enableUnselect={true}
        />
        <Select
          id="collaborator"
          label={t('common.label.collaborator')}
          options={collaboratorOptions}
          control={control}
          placeholder={t('common.select.defaultOptions.collaborators')}
          enableUnselect={true}
        />
        <InputRadio
          id="afterDeadline"
          label={t('common.label.afterDeadline')}
          control={control}
          display="checkbox"
          layout="row-reverse"
        />
        <Button icon={SearchIcon} shape="circle" buttonType="submit" isLoading={isLoading} />
      </FormContainer>
      <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')} />
    </CardLayout>
  )
}
