import RichTextDisplay from 'components/RichTextEditor/RichTextDisplay'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { Trans, useTranslation } from 'react-i18next'
import { StoreDetails } from 'types/entities'
import { FieldsWrapper, FormCard } from '../StoreSeoTab.style'

interface Props {
  store: StoreDetails
}

export default function GameRentalFormDisplay({ store }: Props) {
  const { t } = useTranslation()

  return (
    <FormCard>
      <Title2>{t('page.stores.details.storeSeo.sections.gameRental')}</Title2>
      <FieldsWrapper>
        <RichTextDisplay
          value={store?.gameRentalDescription}
          label={t('page.stores.details.storeSeo.labels.gameRentalDescription')}
          tooltip={
            <Tooltip id="fieldTooltip">
              <Trans i18nKey="page.stores.details.storeSeo.tooltips.gameRentalDescription" />
            </Tooltip>
          }
        />

        <RichTextDisplay
          value={store?.gameRentalDescriptionEn}
          label={t('page.stores.details.storeSeo.labels.gameRentalDescriptionEn')}
        />
      </FieldsWrapper>
    </FormCard>
  )
}
