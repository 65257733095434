import { useMutateCollaborator } from 'api/collaborators'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import Cell from 'components/SortedTable/Cell'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import { usePermissionsByCollaborator } from 'hooks/entityHooks/permissionsHooks'
import useAuth from 'hooks/useAuth'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collaborator } from 'types/entities'
import { CollaboratorRoleActionJsonldUserRead, RoleAction } from 'types/playInApiInterfaces'
import { ListActions, RoleActionsManageWrapper } from '../CollaboratorDetails.style'
import AddRoleActionForm from '../forms/AddRoleActionForm'
import { isInMyStores } from '../utils'

interface Props {
  collaborator: Collaborator
}
export default function RoleActionsManage({ collaborator }: Props) {
  const { t } = useTranslation()
  const { me } = useAuth()
  const { deletableRoleActions, canDeleteRoleAction } = usePermissionsByCollaborator(collaborator)
  const [selectedRoleActions, setSelectedRoleActions] = useState<string[]>([])
  const [openDeleteModal, toggleDeleteModal] = useState(false)

  const { checkMatch } = useCheckMatch()

  const columns = _.filter<Column<CollaboratorRoleActionJsonldUserRead>>(
    [
      {
        key: 'checkbox',
        decorator: (action) => (
          <Cell>
            <TooltipHover
              id={`selectRoleAction-${action.id}`}
              content={canDeleteRoleAction(action).message}
              container="tooltipContainer"
            >
              <Checkbox
                className={classNames({
                  checked: selectedRoleActions.includes(action['@id']!),
                  disabled: !deletableRoleActions.find((e) => e['@id'] === action['@id'])
                })}
                onClick={() => setSelectedRoleActions((prev) => _.xor(prev, [action['@id']!]))}
              />
            </TooltipHover>
          </Cell>
        )
      },
      {
        key: 'name',
        name: t('page.collaborator.details.roleActions.labels.associatedRole'),
        decorator: (action) => (
          <ClickableCell route={RouteName.PermissionManage} state={{ roleAction: action.id }}>
            {action.displayName}
          </ClickableCell>
        )
      },
      {
        key: 'details',
        name: t('page.collaborator.details.roleActions.labels.details'),
        decorator: (action) => (
          <ClickableCell route={RouteName.PermissionManage} state={{ roleAction: action.id }}>
            <Text fontWeight="light" color="secondary">
              {action.description}
            </Text>
          </ClickableCell>
        )
      }
    ],
    (column) => {
      if (column.key === 'checkbox')
        return (
          checkMatch([RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]) &&
          !!collaborator.active &&
          isInMyStores(collaborator, me?.stores)
        )
      return true
    }
  )

  const { mutate, isLoading } = useMutateCollaborator(collaborator.id!, () => {
    toggleDeleteModal(false)
    setSelectedRoleActions([])
  })

  const handleDelete = () => {
    mutate({
      additionalActions:
        collaborator.additionalActions?.filter((e) => !selectedRoleActions.includes(e['@id']!)).map((e) => e['@id']!) ??
        []
    })
  }

  return (
    <RoleActionsManageWrapper>
      <ShouldHide
        permissions={[RoleAction.ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS]}
        deniedExtraCondition={!collaborator.active || !isInMyStores(collaborator, me?.stores)}
      >
        <>
          <AddRoleActionForm collaborator={collaborator} />
          {!!collaborator.additionalActions?.length && (
            <ListActions>
              <Checkbox
                onClick={() =>
                  setSelectedRoleActions((prev) =>
                    prev.length === deletableRoleActions?.length
                      ? []
                      : deletableRoleActions?.map((e) => e['@id']!) ?? []
                  )
                }
                className={classNames({
                  checked:
                    !!deletableRoleActions?.length && selectedRoleActions.length === deletableRoleActions?.length,
                  disabled: !collaborator.active || !deletableRoleActions?.length
                })}
              />
              <TooltipHover
                id="deleteStoreTooltip"
                content={t('page.collaborator.details.roleActions.tooltips.deleteRole')}
                container="tooltipContainer"
              >
                <Button
                  shape="circle"
                  icon={DeleteIcon}
                  variant="white"
                  onClick={() => toggleDeleteModal(true)}
                  disabled={!collaborator.active || !selectedRoleActions.length}
                />
              </TooltipHover>
            </ListActions>
          )}
          <ConfirmModal
            open={openDeleteModal}
            onClose={() => toggleDeleteModal(false)}
            title={t('page.collaborator.details.roleActions.deleteTitle')}
            callback={handleDelete}
            closeOnCallback={false}
            confirmBtnProps={{
              isLoading,
              icon: DeleteIcon,
              disabled: !collaborator.active
            }}
          >
            <Listing
              columns={columns.filter((col) => col.key !== 'checkbox')}
              data={
                collaborator.additionalActions?.filter((store) => selectedRoleActions.includes(store['@id']!)) ?? []
              }
            />
          </ConfirmModal>
        </>
      </ShouldHide>
      <Listing
        data={collaborator.additionalActions ?? []}
        emptyText={t('page.collaborator.details.roleActions.emptyText')}
        columns={columns}
        totalCount={collaborator.additionalActions?.length}
      />
    </RoleActionsManageWrapper>
  )
}
