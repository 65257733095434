import { fetchCommercialOffers } from 'api/commercialOffers'
import { ProductStoreFromSealedProductCollection as ProductStore } from 'api/sealedProducts'
import { usePaginatedStoreZones } from 'api/storeZones'
import { useFetchStores } from 'api/stores'
import { Option } from 'components/Select/Select'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { startOfDay } from 'date-fns'
import { getFilterLabel } from 'hooks/useSelectOptions'
import { isNil } from 'lodash'
import find from 'lodash/find'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { TextColors } from 'theme/theme'
import { SealedProduct } from 'types/entities'
import { ProductVisibility } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { formatCalendarDate } from 'utils/dates'
import ProductInfo from './ProductDetailHeader/ProductInfo'
import ProductPrice from './ProductDetailHeader/ProductPrice'
import { InfoText } from './ProductDetails.styles'

export enum StockStatus {
  InStock = 'inStock',
  Hidden = 'hidden',
  Shown = 'shown',
  NoStock = 'noStock',
  Preorder = 'preorder',
  SoonInStock = 'soonInStock',
  Restock = 'restock',
  StockError = 'stockError',
  NotAvailable = 'notAvailable'
}

export function getStockStatus(product: SealedProduct, productStore?: ProductStore): StockStatus {
  const now = new Date()
  const quantity = productStore?.quantity

  if (!productStore) {
    return StockStatus.NotAvailable
  }

  if (isNil(quantity) || quantity < 0) {
    return StockStatus.StockError
  }

  if (quantity === 0) {
    if (product.visibility === ProductVisibility.AlwaysHidden) return StockStatus.Hidden
    else if (product.visibility === ProductVisibility.AlwaysShown) return StockStatus.Shown

    return StockStatus.NoStock
  }

  if (product.price! > 0) {
    if (product.releasedAt) {
      const releaseDate = new Date(product.releasedAt)
      if (releaseDate > now) {
        if (productStore?.closestRestockDate && new Date(productStore.closestRestockDate) > releaseDate) {
          return StockStatus.SoonInStock
        } else {
          return StockStatus.Preorder
        }
      }
    }
    if (productStore?.closestRestockDate && new Date(productStore.closestRestockDate) > now) {
      return StockStatus.Restock
    } else {
      return StockStatus.InStock
    }
  }

  return StockStatus.NotAvailable
}

export function useProductInfoHelpers() {
  const { t } = useTranslation()

  const { data: commercialOffers } = useQuery(['commercialOffers', startOfDay(new Date())], () =>
    fetchCommercialOffers({ 'startsAt[before]': 'today', 'endsAt[strictly_after]': 'now' })
  )

  function getProductStore(productStores?: ProductStore[], storeId?: string): ProductStore | undefined {
    return find(productStores, (productStore) => productStore.store?.['@id'] === storeId)
  }

  const { data: allStores } = useFetchStores()

  function getStockInfo(product: SealedProduct, productStore?: ProductStore): string {
    const stockStatus = getStockStatus(product, productStore)

    switch (stockStatus) {
      case StockStatus.Preorder:
        return t('page.product.detail.stockInfo.preorder')
      case StockStatus.SoonInStock:
        return productStore?.approximateRestockDate
          ? t('page.product.detail.stockInfo.soonInStockApproximate', {
              date: productStore?.approximateRestockDate
            })
          : t('page.product.detail.stockInfo.soonInStock', {
              date: formatCalendarDate(productStore?.closestRestockDate)
            })
      case StockStatus.Restock:
        return productStore?.approximateRestockDate
          ? t('page.product.detail.stockInfo.restockApproximate', {
              date: productStore?.approximateRestockDate
            })
          : t('page.product.detail.stockInfo.restock', {
              date: formatCalendarDate(productStore?.closestRestockDate)
            })
      case StockStatus.Hidden:
        return t('page.product.detail.stockInfo.hidden')
      case StockStatus.Shown:
        return t('page.product.detail.stockInfo.shown')
      case StockStatus.NoStock:
        return t('page.product.detail.stockInfo.noStock')
      case StockStatus.InStock:
        return t('page.product.detail.stockInfo.inStock')
      case StockStatus.StockError:
        return t('page.product.detail.stockInfo.stockError')
      case StockStatus.NotAvailable:
        return t('page.product.detail.stockInfo.notAvailable')
    }
  }

  function getModalStockInfo(product: SealedProduct, productStore?: ProductStore): string {
    const stockStatus = getStockStatus(product, productStore)

    switch (stockStatus) {
      case StockStatus.Preorder:
      case StockStatus.SoonInStock:
        return t('page.product.detail.stockModal.preorder', { quantity: productStore?.quantity })
      case StockStatus.Hidden:
        return t('page.product.detail.stockInfo.hidden')
      case StockStatus.Shown:
        return t('page.product.detail.stockInfo.shown')
      case StockStatus.NoStock:
        return t('page.product.detail.stockInfo.noStock')
      case StockStatus.InStock:
        return t('page.product.detail.stockModal.inStock', { quantity: productStore?.quantity })
      case StockStatus.Restock:
        return t('page.product.detail.stockModal.restock', { quantity: productStore?.quantity })
      case StockStatus.StockError:
        return t('page.product.detail.stockInfo.stockError')
      case StockStatus.NotAvailable:
        return t('page.product.detail.stockInfo.notAvailable')
    }
  }

  function getStockInfoColor(product: SealedProduct, productStore?: ProductStore): keyof TextColors {
    const stockStatus = getStockStatus(product, productStore)

    switch (stockStatus) {
      case StockStatus.Preorder:
      case StockStatus.SoonInStock:
      case StockStatus.Restock:
        return 'warning'
      case StockStatus.Hidden:
      case StockStatus.Shown:
      case StockStatus.NoStock:
      case StockStatus.StockError:
        return 'danger'
      case StockStatus.InStock:
        return 'success'
      case StockStatus.NotAvailable:
        return 'secondary2'
    }
  }

  function getStockQuantityColor(product: SealedProduct, productStore: ProductStore | undefined): keyof TextColors {
    const stockStatus = getStockStatus(product, productStore)

    switch (stockStatus) {
      case StockStatus.Preorder:
      case StockStatus.SoonInStock:
      case StockStatus.Restock:
        return 'warning'
      case StockStatus.Hidden:
      case StockStatus.Shown:
      case StockStatus.NoStock:
      case StockStatus.StockError:
        return 'danger'
      case StockStatus.InStock:
        return 'black'
      case StockStatus.NotAvailable:
        return 'secondary2'
    }
  }

  function getReleaseDateColor(product: SealedProduct): keyof TextColors {
    const now = new Date()
    if (product.approximateReleaseDate && !product.releasedAt) return 'warning'
    if (product.releasedAt && new Date(product.releasedAt) > now) return 'warning'

    return 'secondary2'
  }

  function getDiscountInfo(product: SealedProduct): string | undefined {
    const discountType = (product?.isSpecialDiscount && commercialOffers?.[0]?.transName) || t('page.product.detail.discount.cut')

    if (product.discountPercentage) {
      return t('page.product.detail.stockInfo.discount', {
        percentage: product.discountPercentage,
        discountType
      })
    }
  }

  function getMainStoreDiscountInfo(product: SealedProduct, storeId?: string): string | undefined {
    const productByStore = getProductStore(product?.productStores, storeId)

    const discountType = (productByStore?.specialDiscount && commercialOffers?.[0]?.transName) || t('page.product.detail.discount.cut')

    if (productByStore?.discountPercentage) {
      return t('page.product.detail.stockInfo.discount', {
        percentage: productByStore.discountPercentage,
        discountType
      })
    }
  }

  function getWarehouseInfo(product: SealedProduct) {
    const productByStore = getProductStore(product?.productStores, WAREHOUSE_STORE_ID)

    return (
      <ProductInfo
        title={t('page.product.infos.warehouseStock')}
        info={getStockInfo(product, productByStore)}
        infoColor={getStockInfoColor(product, productByStore)}
      >
        <InfoText color={getStockQuantityColor(product, productByStore)}>{productByStore?.quantity ?? 0}</InfoText>
      </ProductInfo>
    )
  }

  function getStoreInfo(product: SealedProduct, storeId?: string) {
    const productByStore = getProductStore(product?.productStores, storeId)

    const storeData = allStores?.find((e) => e['@id'] === storeId)

    return (
      <ProductInfo
        title={t('page.product.infos.stockStore', { storeName: storeData?.shortName })}
        info={getStockInfo(product, productByStore)}
        infoColor={getStockInfoColor(product, productByStore)}
      >
        <InfoText color={getStockQuantityColor(product, productByStore)}>{productByStore?.quantity ?? 0}</InfoText>
      </ProductInfo>
    )
  }

  function getMainStoreProductPrice(product: SealedProduct, storeId?: string) {
    const productByStore = getProductStore(product?.productStores, storeId)
    if (
      (product.price === productByStore?.customerSellPrice &&
        product.discount === productByStore?.customerDiscount &&
        product.isSpecialDiscount === productByStore?.specialDiscount) ||
      (!productByStore?.sellPrice && !productByStore?.discount)
    ) {
      return <></>
    }

    return (
      <ProductInfo
        title={`${t('page.product.infos.price')} ${productByStore?.store?.shortName}`}
        info={getMainStoreDiscountInfo(product, storeId)}
        infoColor="danger"
      >
        <ProductPrice price={productByStore?.customerSellPrice!} discount={productByStore?.customerDiscount} />
      </ProductInfo>
    )
  }

  function getPreorderDate(product: SealedProduct, productStore?: ProductStore): string | undefined {
    if (!productStore) {
      return
    }

    const stockStatus = getStockStatus(product, productStore)

    switch (stockStatus) {
      case StockStatus.Preorder:
        return product?.approximateReleaseDate
          ? t('page.product.detail.stockModal.preorderApproximateDate', {
              date: product?.approximateReleaseDate
            })
          : t('page.product.detail.stockModal.preorderDate', {
              date: formatCalendarDate(product?.releasedAt)
            })
      case StockStatus.SoonInStock:
        return productStore?.approximateRestockDate
          ? t('page.product.detail.stockInfo.soonInStockApproximate', {
              date: productStore?.approximateRestockDate
            })
          : t('page.product.detail.stockInfo.soonInStock', {
              date: formatCalendarDate(productStore?.closestRestockDate)
            })
      case StockStatus.Restock:
        return productStore?.approximateRestockDate
          ? t('page.product.detail.stockInfo.restockApproximate', {
              date: productStore?.approximateRestockDate
            })
          : t('page.product.detail.stockInfo.restock', {
              date: formatCalendarDate(productStore?.closestRestockDate)
            })
    }
  }

  function getReleaseDate(product: SealedProduct): string | undefined {
    const now = new Date()

    if (product.releasedAt) {
      const releaseDate = new Date(product.releasedAt)
      if (releaseDate > now) {
        return product?.approximateReleaseDate
          ? product?.approximateReleaseDate
          : formatCalendarDate(product.releasedAt)
      } else return formatCalendarDate(product.releasedAt)
    } else return product?.approximateReleaseDate!
  }

  return {
    getProductStore,
    getStockInfo,
    getStockInfoColor,
    getStockQuantityColor,
    getReleaseDateColor,
    getDiscountInfo,
    getStoreInfo,
    getWarehouseInfo,
    getMainStoreProductPrice,
    getModalStockInfo,
    getPreorderDate,
    getReleaseDate
  }
}

export function useStoreZonesOptions({ store, enabled }: { store?: string; enabled?: boolean }) {
  const params = { store, 'order[name]': SortingDirection.Asc }
  const {
    data: storeZones,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading
  } = usePaginatedStoreZones(['storeZones', params], params, { enabled })

  const storeZonesOptions: Option[] = (storeZones ?? []).map((zone) => ({
    label: zone.name ?? '',
    value: zone['@id']
  }))

  return {
    storeZonesOptions,
    storeZonesOptionsPagination: { isFetchingNextPage, fetchNextPage, hasNextPage, isLoading },
    getStoreZoneLabel: (value?: string) => getFilterLabel(storeZonesOptions, value)
  }
}
