import styled from 'styled-components'

export const PrepareRadioGroup = styled.div`
  min-height: 7rem;
  margin-bottom: 1rem;
`

export const PrepareRadioContainer = styled.div`
  label {
    white-space: initial;
    font-weight: ${(props) => props.theme.fontWeight.regular};
  }
`
