import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

export type PatchOrderCouponRequest = Api.PatchOrderCouponsId.RequestBody
type PatchOrderCouponResponse = Api.PatchOrderCouponsId.ResponseBody
export const editOrderCoupon = async (body: PatchOrderCouponRequest, id: string) => {
  const response = await playInApi.patch<PatchOrderCouponRequest, AxiosResponse<PatchOrderCouponResponse>>(
    `${API_ROUTES.orderCoupons.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

  return response.data
}

export type PostOrderCouponBody = Api.PostOrderCoupons.RequestBody
type PostOrderCouponResponse = Api.PostOrderCoupons.ResponseBody
export const postOrderCoupon = async (body: PostOrderCouponBody) => {
  const response = await playInApi.post<PostOrderCouponBody, AxiosResponse<PostOrderCouponResponse>>(
    API_ROUTES.orderCoupons.root,
    body
  )

  return response.data
}
