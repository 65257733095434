import { GetOrderByIdResponse } from 'api/orders'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { ModalBody, Separator } from './CustomerEditModal.style'
import EditOrderAddressForm from './EditOrderAddressForm'
import EditOrderCustomerForm from './EditOrderCustomerForm'

interface Props extends Omit<ModalProps, 'children'> {
  order?: GetOrderByIdResponse
}

export default function CustomerEditModal({ open, onClose, order }: Props) {
  const { t } = useTranslation()
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <BlockLayout>
          <Title2>{t('page.order.detail.customerModal.switchCustomerTitle')}</Title2>
        </BlockLayout>
        <BlockLayout>
          <EditOrderCustomerForm open={open} onClose={onClose} order={order} />
        </BlockLayout>
        <Separator />
        <BlockLayout>
          <EditOrderAddressForm open={open} order={order} onClose={onClose} />
        </BlockLayout>
      </ModalBody>
    </Modal>
  )
}
