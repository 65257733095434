import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { TextareaLayout } from 'components/Textarea/Textarea.style'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { Trans, useTranslation } from 'react-i18next'
import { StoreDetails } from 'types/entities'
import { FieldsWrapper, FormCard } from '../StoreSeoTab.style'

interface Props {
  store: StoreDetails
}

export default function StoreFormDisplay({ store }: Props) {
  const { t } = useTranslation()

  return (
    <FormCard>
      <Title2>{t('page.stores.details.storeSeo.sections.store')}</Title2>
      <FieldsWrapper>
        <TextareaLayout
          label={t('page.stores.details.storeSeo.labels.shortDescription', { store: store.name })}
          tooltip={
            <Tooltip id="shortDescriptionTooltip">
              <Trans i18nKey="page.stores.details.storeSeo.tooltips.shortDescription" />
            </Tooltip>
          }
          className="no-border"
        >
          <TextFieldDisplay text={store?.shortDescription} />
        </TextareaLayout>

        <TextareaLayout
          label={t('page.stores.details.storeSeo.labels.shortDescriptionEn', { store: store.name })}
          className="no-border"
        >
          <TextFieldDisplay text={store?.shortDescriptionEn} />
        </TextareaLayout>
      </FieldsWrapper>
    </FormCard>
  )
}
