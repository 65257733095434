import styled from 'styled-components'
import theme from 'theme/theme'

export const StatusHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  gap: 0.1rem;
  margin-bottom: 2rem;
  overflow-x: auto;
  @media screen and (${theme.screenWidth.maxLaptopScreen}) {
    height: 8rem;
  }
`

export const StatusWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`
export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  height: 6.5rem;
  min-width: 25rem;

  padding: 1rem 0;
  border-radius: 2px;

  @media screen and (${theme.screenWidth.maxLaptopScreen}) {
    flex-grow: unset;
    min-width: 300px;
  }

  &.active {
    background-color: ${(props) => props.theme.backgroundColors.purple};
    color: ${(props) => props.theme.textColors.white};
  }

  &.history {
    background-color: ${(props) => props.theme.backgroundColors.lightPurple};
    border: 1px solid ${(props) => props.theme.borderColors.secondary};
    color: ${(props) => props.theme.textColors.black};
  }

  &.pending {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.borderColors.secondary};
    color: ${(props) => props.theme.textColors.secondary2};
  }

  &.canceled {
    background-color: ${(props) => props.theme.backgroundColors.danger};
    color: ${(props) => props.theme.textColors.white};
  }
  &.old {
    background-color: ${(props) => props.theme.backgroundColors.lightPurple};
  }
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.3rem;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
  & > *:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (${theme.screenWidth.maxDesktopScreen}) {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
  }
  padding: 0 1rem;
`

export const TitleBlock = styled.div`
  font-size: 1.6rem;
  text-align: center;
  padding: 0 3.5rem;
`

export const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  position: absolute;

  top: 1rem;
  left: 1rem;
`

export const SecondaryStatusBlock = styled(StatusContainer)`
  height: auto;
  padding: 0 1rem;
`
export const SecondaryStatusInfo = styled.div`
  padding: 1rem 0;
  text-align: center;
  font-size: 1.3rem;
  border-top: 1px solid #fff;
`
