import { deleteLitigation, DeleteLitigationParams, getCustomerLitigationsQueryKey } from 'api/litigations'
import { orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import { Text } from 'components/Text/Text.styles'
import { DeleteIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useQueryClient } from 'react-query'
import { Litigation, Order } from 'types/entities'
import { OrderJsonldOrderReadOrderReadItem } from 'types/playInApiInterfaces'
import { formatCalendarDate } from 'utils/dates'
import { getIdFromIri } from 'utils/queryParams'
import { LitigationDate, LitigationName, LitigationRow } from './OrderLitigationModal.style'

interface Props {
  litigation: Litigation
  order?: OrderJsonldOrderReadOrderReadItem
}

export function DeletableLitigationItem({ litigation, order }: Props) {
  const { mutate } = useMutation((body: DeleteLitigationParams) => deleteLitigation(body))
  const queryClient = useQueryClient()
  const orderKey = orderByIdQueryKey(order?.id!)
  const litigationsKey = getCustomerLitigationsQueryKey(order?.customer?.id!)
  const onDelete = () => {
    mutate(
      { id: getIdFromIri(litigation['@id']!) },
      {
        onSuccess: () => {
          // If order is provided, we can update its litigations and delete the item.
          if (order) {
            const prev = queryClient.getQueryData<Order>(orderKey)
            queryClient.setQueryData<Order>(orderKey, {
              ...prev,
              litigations: prev?.litigations!.filter((el) => el['@id'] !== litigation['@id'])
            })
            const customerLitigations = queryClient.getQueryData<Litigation[]>(litigationsKey)
            queryClient.setQueryData<Litigation[]>(
              litigationsKey,
              customerLitigations!.filter((el) => el['@id'] !== litigation['@id'])
            )
          }
        }
      }
    )
  }
  return (
    <LitigationRow>
      <LitigationName fontWeight="medium" color="danger">
        {litigation.category!.name}
      </LitigationName>
      <Text color="danger">
        <LitigationDate>{formatCalendarDate(litigation.issuedAt)}</LitigationDate>
      </Text>
      <Button shape="circle" variant="white" icon={DeleteIcon} onClick={onDelete} />
    </LitigationRow>
  )
}
