import { GetOrderByIdResponse } from 'api/orders'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { TwoColGridRow } from './PreparationCardSection.style'
import { useCardAssemblingOptions, useCardCheckingOptions } from './PreparationOptions'

interface PreparationCardSectionProps {
  order?: GetOrderByIdResponse
}

export default function PreparationCardDisplay({ order }: PreparationCardSectionProps) {
  const { t } = useTranslation()

  const { options: cardAssemblingOptions } = useCardAssemblingOptions()
  const { options: cardCheckingOptions } = useCardCheckingOptions()

  return (
    <>
      <Title2>{t('page.order.detail.tab.prepare.cardSectionTitle')}</Title2>
      <TwoColGridRow>
        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.cardAssemblingLabel')}>
          <TextFieldDisplay
            text={cardCheckingOptions.find((opt) => opt.value === order?.cardPreparedSecondaryStatus)?.label}
          />
        </InputLayout>

        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.cardCheckingLabel')}>
          <TextFieldDisplay
            text={cardAssemblingOptions.find((opt) => opt.value === order?.cardCheckedSecondaryStatus)?.label}
          />
        </InputLayout>

        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.cardPreparatorLabel')}>
          <TextFieldDisplay
            text={
              order?.cardPreparer &&
              [`${order.cardPreparer.id} -`, order.cardPreparer.lastname, order.cardPreparer.firstname].join(' ')
            }
          />
        </InputLayout>

        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.cardCheckerLabel')}>
          <TextFieldDisplay
            text={
              order?.cardChecker &&
              [`${order.cardChecker.id} -`, order.cardChecker.lastname, order.cardChecker.firstname].join(' ')
            }
          />
        </InputLayout>

        <InputLayout className="no-border" label={t('page.order.detail.tab.prepare.transitLabel')}>
          <TextFieldDisplay text={order?.cardInTransit ? t('common.radioButton.yes') : t('common.radioButton.no')} />
        </InputLayout>
      </TwoColGridRow>
    </>
  )
}
