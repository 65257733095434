import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Auth from './contexts/auth'
import EventEmitter from './contexts/eventEmitter'
import SnackbarManager from './features/SnackbarManager'
import Routes from './routes'
import theme from './theme/theme'

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <EventEmitter>
          <Auth>
            <BrowserRouter>
              <Routes />
              <SnackbarManager />
            </BrowserRouter>
          </Auth>
        </EventEmitter>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
