import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: flex-end;
  order: 2;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    width: 100%;
    order: 0;
    justify-content: flex-start;
  }
`

export const ModalAlertText = styled(Text)`
  span {
    color: ${(props) => props.theme.textColors.danger};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
`
