import { yupResolver } from '@hookform/resolvers/yup'
import { editOrder, EditOrderBody, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import { postRelay, PostRelayBody } from 'api/postalRelays'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputRadio from 'components/InputRadio/InputRadio'
import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { ModalProps } from 'components/Modal/Modal'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { DEFAULT_COUNTRY_CODE } from 'constants/configs'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useCountryOptions } from 'hooks/useSelectOptions'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { ShippingLabelTypeName, ShippingTypeName } from 'types/playInApiInterfaces'
import { hasOneOfGroupFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import {
  CountryPhoneRow,
  ShippingAddressFormContainer,
  TwoColGridRow,
  ValidationErrorContainer,
  ZipCodeCityRow,
  ZipCodeInput
} from './CustomerEditModal.style'

interface Props extends Omit<ModalProps, 'children'> {
  order?: GetOrderByIdResponse
}

interface FormData {
  recipientName: string
  recipientStreet: string
  recipientState?: string | null
  recipientCity: string
  recipientZipCode: string
  recipientExtraInformations?: string | null
  recipientPhone?: string | null
  recipientHomePhone?: string | null
  recipientCountry?: string | null
  billingName?: string
  billingStreet?: string
  billingExtraInformations?: string
  billingZipCode?: string
  billingCity?: string
  billingCountry?: string | null
  billingState?: string
  relatedRelayReference?: string
  sameAddressBilling: boolean
}

const baseSchema = {
  recipientName: yup.string().trim().required(),
  recipientStreet: yup.string().trim().required(),
  recipientExtraInformations: yup.string().trim(),
  recipientZipCode: yup.string().trim().required(),
  recipientCity: yup.string().trim().required(),
  recipientCountry: yup.string().trim().required(),
  recipientState: yup.string().trim(),
  recipientPhone: yup.string().trim(),
  recipientHomePhone: yup.string().trim()
}

// Apply this schema for non-relay orders
const schemaAddresses = yup.object().shape({
  ...baseSchema,
  sameAddressBilling: yup.boolean().required(),
  billingName: yup
    .string()
    .trim()
    .when('sameAddressBilling', {
      is: (ref) => !ref,
      then: yup.string().trim().required()
    }),
  billingStreet: yup
    .string()
    .trim()
    .when('sameAddressBilling', {
      is: (ref) => !ref,
      then: yup.string().trim().required()
    }),
  billingExtraInformations: yup.string().trim(),
  billingZipCode: yup
    .string()
    .trim()
    .when('sameAddressBilling', {
      is: (ref) => !ref,
      then: yup.string().trim().required()
    }),
  billingCity: yup
    .string()
    .trim()
    .when('sameAddressBilling', {
      is: (ref) => !ref,
      then: yup.string().trim().required()
    }),
  billingCountry: yup
    .string()
    .trim()
    .when('sameAddressBilling', {
      is: (ref) => !ref,
      then: yup.string().trim().required()
    }),
  billingState: yup.string().trim()
})

// Apply this schema for relay orders
const schemaRelay = yup.object().shape({
  ...baseSchema,
  relatedRelayReference: yup.string().trim()
})

export default function EditRegularOrderAddressForm({ order, onClose }: Props) {
  const { t } = useTranslation()
  const isRelay = !!order?.address?.relatedRelay || order?.shippingMode?.shippingType === ShippingTypeName.RELAY
  const isClickAndCollect = !!order?.address?.relatedStoreAddress
  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)
  const { mutate, isLoading } = useMutation(
    (body: EditOrderBody['address']) => editOrder({ address: body }, order?.id!),
    {
      onSuccess: (data) => {
        const prev = queryClient.getQueryData<Order>(key)
        queryClient.setQueryData(key, { ...prev, ...data })
        onClose()
      }
    }
  )

  const { mutate: editRelay, isLoading: isRelayLoading } = useMutation(({ relayId }: PostRelayBody) =>
    postRelay(relayId, order?.shippingMode?.labelType?.toString())
  )
  const { countryOptions, getCountryId } = useCountryOptions()

  const extendedSchemaAddresses = useMemo(
    () =>
      // for chronopost shipping, we need at least one phoneNumber
      order?.shippingMode?.labelType === ShippingLabelTypeName.CHRONOPOST
        ? schemaAddresses.test(hasOneOfGroupFieldsMethod(['recipientPhone', 'recipientHomePhone'])())
        : schemaAddresses,
    [order?.shippingMode]
  )

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    watch
  } = useForm<FormData>({
    resolver: yupResolver(isRelay ? schemaRelay : extendedSchemaAddresses),
    mode: 'onSubmit',
    defaultValues: {
      recipientName: order?.address?.recipientName,
      recipientStreet: order?.address?.recipientStreet,
      recipientExtraInformations: order?.address?.recipientExtraInformations,
      recipientZipCode: order?.address?.recipientZipCode,
      recipientCity: order?.address?.recipientCity,
      recipientState: order?.address?.recipientState,
      recipientCountry: order?.address?.recipientCountry?.alpha2 ?? DEFAULT_COUNTRY_CODE,
      recipientPhone: order?.address?.recipientPhone,
      recipientHomePhone: order?.address?.recipientHomePhone,
      billingName: order?.address?.billingName ?? '',
      billingStreet: order?.address?.billingStreet ?? '',
      billingExtraInformations: order?.address?.billingExtraInformations ?? '',
      billingZipCode: order?.address?.billingZipCode ?? '',
      billingCity: order?.address?.billingCity ?? '',
      billingState: order?.address?.billingState ?? '',
      billingCountry: order?.address?.billingCountry?.alpha2 ?? DEFAULT_COUNTRY_CODE,
      sameAddressBilling: !order?.address?.distinctBillingAddress
      // TODO: Set up related relay fields
    }
  })

  console.log(errors)
  const sameAddressBilling = watch('sameAddressBilling')

  const onSubmit = (data: FormData) => {
    const { relatedRelayReference, ...restData } = data
    if (isRelay && relatedRelayReference) {
      return editRelay(
        {
          relayId: relatedRelayReference
        },
        {
          onSuccess: (relay) => {
            updateOrderAddress({ ...data, relatedRelayReference: relay['@id'] })
          }
        }
      )
    }
    return updateOrderAddress(restData)
  }

  const updateOrderAddress = (data: FormData) => {
    const { sameAddressBilling, relatedRelayReference, ...payload } = data

    if (data.sameAddressBilling) {
      return mutate({
        ...payload,
        relatedRelay: relatedRelayReference,
        recipientCountry: data.recipientCountry ? getCountryId(data.recipientCountry) : undefined,
        // If both addresses are the same, reset billing address fields, including 'billingCountry'.
        billingName: '',
        billingStreet: '',
        billingExtraInformations: '',
        billingZipCode: '',
        billingCity: '',
        billingCountry: null,
        billingState: '',
        distinctBillingAddress: false
      })
    }

    mutate({
      ...payload,
      relatedRelay: relatedRelayReference,
      recipientCountry: data.recipientCountry ? getCountryId(data.recipientCountry) : undefined,
      billingCountry: data.billingCountry ? getCountryId(data.billingCountry) : undefined,
      distinctBillingAddress: true
    })
  }
  const getRequiredLabel = (label: string) => {
    return `${label}\u00a0*`
  }
  const getFieldClassname = (id: string) => {
    return classnames({ 'is-invalid': errors[id] })
  }
  useEffect(() => {
    clearErrors(['billingCity', 'billingName', 'billingStreet', 'billingZipCode'])
  }, [sameAddressBilling, clearErrors])

  const hasRelayError =
    isRelay &&
    _.some(
      ['relatedRelayName', 'relatedRelayStreet', 'relatedRelayZipCode', 'relatedRelayCity', 'relatedRelayReference'],
      (field) => field in errors
    )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isRelay && (
        <>
          <Title2>{t('page.order.detail.customerModal.editRelayAddressTitle')}</Title2>
          <ShippingAddressFormContainer>
            <TwoColGridRow>
              <Input
                label={t('common.label.postalReference')}
                id="relatedRelayReference"
                register={register}
                className={getFieldClassname('relatedRelayReference')}
              />
            </TwoColGridRow>
            {hasRelayError && <Text color="danger">{t('page.order.detail.customerModal.editRelayError')}</Text>}
          </ShippingAddressFormContainer>
        </>
      )}
      {
        <>
          <Title2>
            {(isClickAndCollect || isRelay) && sameAddressBilling
              ? t('page.order.detail.customerModal.editBillingAddressTitle')
              : t('page.order.detail.customerModal.editShippingAddressTitle')}
          </Title2>
          <ShippingAddressFormContainer>
            <TwoColGridRow>
              <Input
                label={getRequiredLabel(`${t('common.label.lastName')} ${t('common.label.firstName')}`)}
                id="recipientName"
                register={register}
                className={getFieldClassname('recipientName')}
              />
              <Input
                label={getRequiredLabel(t('common.label.address'))}
                id="recipientStreet"
                register={register}
                className={getFieldClassname('recipientStreet')}
              />
            </TwoColGridRow>
            <TwoColGridRow>
              <Input label={t('common.label.extraAddress')} id="recipientExtraInformations" register={register} />
              <ZipCodeCityRow>
                <ZipCodeInput
                  label={getRequiredLabel(t('common.label.zipCode'))}
                  id="recipientZipCode"
                  register={register}
                  className={getFieldClassname('recipientZipCode')}
                />
                <Input
                  label={getRequiredLabel(t('common.label.city'))}
                  id="recipientCity"
                  register={register}
                  className={getFieldClassname('recipientCity')}
                />
              </ZipCodeCityRow>
            </TwoColGridRow>
            <CountryPhoneRow>
              <Input label={t('common.label.state')} id="recipientState" register={register} />
              <Select
                label={getRequiredLabel(t('common.label.country'))}
                id="recipientCountry"
                control={control}
                options={countryOptions}
                className={getFieldClassname('recipientCountry')}
              />
              <Input
                label={
                  order?.shippingMode?.labelType === ShippingLabelTypeName.CHRONOPOST
                    ? getRequiredLabel(t('common.label.mobilePhone'))
                    : t('common.label.mobilePhone')
                }
                id="recipientPhone"
                register={register}
                className={getFieldClassname('recipientPhone')}
              />
              <Input
                label={
                  order?.shippingMode?.labelType === ShippingLabelTypeName.CHRONOPOST
                    ? getRequiredLabel(t('common.label.homePhone'))
                    : t('common.label.homePhone')
                }
                id="recipientHomePhone"
                register={register}
              />
            </CountryPhoneRow>
            {!isClickAndCollect && !isRelay && (
              <RowContainer>
                <InputRadio
                  id="sameAddressBilling"
                  control={control}
                  label={t('page.order.detail.customerModal.identicalAddress')}
                  display="checkbox"
                  layout="row-reverse"
                />
              </RowContainer>
            )}
          </ShippingAddressFormContainer>
        </>
      }

      {!sameAddressBilling && (
        <>
          <Title2>{t('page.order.detail.customerModal.editBillingAddressTitle')}</Title2>
          <ShippingAddressFormContainer>
            <TwoColGridRow>
              <Input
                label={getRequiredLabel(`${t('common.label.lastName')} ${t('common.label.firstName')}`)}
                id={'billingName'}
                register={register}
                className={getFieldClassname('billingName')}
              />
              <Input
                label={getRequiredLabel(t('common.label.address'))}
                id={'billingStreet'}
                register={register}
                className={getFieldClassname('billingStreet')}
              />
            </TwoColGridRow>
            <TwoColGridRow>
              <Input label={t('common.label.extraAddress')} id={'billingExtraInformations'} register={register} />
              <ZipCodeCityRow>
                <ZipCodeInput
                  label={getRequiredLabel(t('common.label.zipCode'))}
                  id={'billingZipCode'}
                  register={register}
                  className={getFieldClassname('billingZipCode')}
                />
                <Input
                  label={getRequiredLabel(t('common.label.city'))}
                  id={'billingCity'}
                  register={register}
                  className={getFieldClassname('billingCity')}
                />
              </ZipCodeCityRow>
            </TwoColGridRow>
            <CountryPhoneRow>
              <Input label={t('common.label.state')} id={'billingState'} register={register} />
              <Select
                label={getRequiredLabel(t('common.label.country'))}
                id={'billingCountry'}
                control={control}
                options={countryOptions}
                className={getFieldClassname('billingCountry')}
              />
            </CountryPhoneRow>
          </ShippingAddressFormContainer>
        </>
      )}
      {_.size(errors) > 0 && (
        <ValidationErrorContainer>
          <BasicFieldValidationError
            error={{ type: 'required' }}
            message={t('page.order.detail.customerModal.fillAllRequiredFields')}
          />
        </ValidationErrorContainer>
      )}
      <Button icon={SaveIcon} buttonType="submit" isLoading={isLoading || isRelayLoading}>
        {t('common.label.edit')}
      </Button>
    </form>
  )
}
