import RichTextDisplay from 'components/RichTextEditor/RichTextDisplay'
import FieldDisplay from 'components/Text/FieldDisplay'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { Trans, useTranslation } from 'react-i18next'
import { StoreDetails } from 'types/entities'
import { FieldsWrapper, FormCard } from '../StoreSeoTab.style'

interface Props {
  store: StoreDetails
}

export default function PlayAreaFormDisplay({ store }: Props) {
  const { t } = useTranslation()

  const getPlayArea = (store: StoreDetails) => {
    if (store.catering) {
      return t('page.stores.details.storeSeo.labels.playArea.options.catering')
    } else if (store.playArea) {
      return t('page.stores.details.storeSeo.labels.playArea.options.playArea')
    }

    return t('page.stores.details.storeSeo.labels.playArea.options.none')
  }

  return (
    <FormCard>
      <Title2>{t('page.stores.details.storeSeo.sections.playArea')}</Title2>

      <FieldDisplay
        label={t('page.stores.details.storeSeo.labels.playArea.title')}
        tooltip={
          <Tooltip id="shortDescriptionTooltip">
            <Trans i18nKey="page.stores.details.storeSeo.tooltips.shortDescription" />
          </Tooltip>
        }
        value={getPlayArea(store)}
      />

      <FieldsWrapper>
        <RichTextDisplay
          value={store?.playAreaDescription}
          label={t('page.stores.details.storeSeo.labels.playAreaDescription')}
          tooltip={
            <Tooltip id="fieldTooltip">
              <Trans i18nKey="page.stores.details.storeSeo.tooltips.playAreaDescription" />
            </Tooltip>
          }
        />

        <RichTextDisplay
          value={store?.playAreaDescriptionEn}
          label={t('page.stores.details.storeSeo.labels.playAreaDescriptionEn')}
        />
      </FieldsWrapper>
    </FormCard>
  )
}
