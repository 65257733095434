import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { ColumnContainer } from 'components/Layouts/InputLayout/InputLayout.styles'
import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { DeleteIcon } from 'constants/icons'
import { useTranslation } from 'react-i18next'
import { BannerForm, FormData } from '../BannerCreate/BannerForm'

const BannerEdit = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <MainTitle title={t('page.banner.edit.title')} />

      <BannerForm
        defaultValues={{ lang: 'fr', img: undefined, imgLarge: undefined, title: '', urlLink: '' }}
        onSubmit={(data: FormData) => {}}
        mode="edit"
      />

      <CardLayout>
        <Title2>{t('page.banner.delete.title')}</Title2>
        <ColumnContainer>
          <Text fontWeight="light" color="secondary">
            {t('page.banner.delete.description')}
          </Text>
          <Button icon={DeleteIcon} variant="danger">
            {t('common.button.delete')}
          </Button>
        </ColumnContainer>
      </CardLayout>
    </Section>
  )
}

export default BannerEdit
