import { API_ROUTES } from 'constants/configs'
import { ProductStats } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

type FetchProductStatsResponse = Api.GetProductStats.ResponseBody
export const fetchStoreProductStats = async (productId: string, storeId: string): Promise<ProductStats | undefined> => {
  const res = await playInApi.get<FetchProductStatsResponse>(
    getProductsStatsUri({ product: productId, store: storeId })
  )

  return res.data['hydra:member'][0] ?? undefined
}

type FetchProductStatsQueryParams = Api.GetProductStats.RequestQuery
const getProductsStatsUri = (params: FetchProductStatsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.productStats.root, queryString].join('?')
}
