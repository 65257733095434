import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { Text } from 'components/Text/Text.styles'
import { useTranslation } from 'react-i18next'
import { SealedProduct } from 'types/entities'
import { CheckboxLayout, OptionsGrid } from './PreorderPrintForm.styles'

interface Props {
  setSelectedProducts: (products: any) => void
  selectedProducts: number[]
  productsList: SealedProduct[]
}

export default function ProductsSelector({ selectedProducts, setSelectedProducts, productsList }: Props) {
  const { t } = useTranslation()

  const handleCheckbox = (product: SealedProduct) => {
    if (selectedProducts.includes(product.id!)) {
      setSelectedProducts((prev) => prev.filter((e) => e !== product.id))
    } else {
      setSelectedProducts((prev) => [...prev, product.id!])
    }
  }

  return !!productsList?.length ? (
    <>
      <OptionsGrid>
        {productsList.map((product) => (
          <CheckboxLayout key={`checkbox-${product.id}`}>
            <Checkbox
              className={selectedProducts.includes(product.id!) ? 'checked' : ''}
              onClick={() => handleCheckbox(product)}
            />
            <Text>{product.name}</Text>
          </CheckboxLayout>
        ))}
      </OptionsGrid>
    </>
  ) : (
    <Text color="secondary" fontStyle="italic">
      {t('page.product.management.empty')}
    </Text>
  )
}
