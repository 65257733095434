import { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import Select, { Option } from 'components/Select/Select'
import useAuth from 'hooks/useAuth'
import { useMyStoresOptions } from 'hooks/useSelectOptions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Store } from 'types/entities'

type LocationForm = {
  store: string
}

type Props = LayoutProps & {
  onLocationChange: (store: Store) => void
  store: Store
  storeOptions?: Option[]
}

const StoreSelect = ({ onLocationChange, store, storeOptions, ...inputLayoutProps }: Props) => {
  const { me } = useAuth()
  const { myStoresOptions } = useMyStoresOptions()

  const { watch, control } = useForm<LocationForm>({
    defaultValues: {
      store: store['@id']!
    }
  })

  useEffect(() => {
    const subscription = watch((newLocation) => {
      const myStore = (me?.stores || []).find((s) => s['@id'] === newLocation.store)
      onLocationChange(myStore!)
    })
    return () => subscription.unsubscribe()
  }, [watch, onLocationChange, me?.stores])

  return <Select {...inputLayoutProps} id="store" options={storeOptions || myStoresOptions} control={control} />
}

export default StoreSelect
