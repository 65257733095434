import { GetOrderByIdResponse } from 'api/orders'
import BillCouponPrintCard from 'app/OrderDetails/components/BillCouponPrintCard/BillCouponPrintCard'
import PreparePrintCard from 'app/OrderDetails/components/PreparePrintCard/PreparePrintCard'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { PrintTabCardContainer } from './PrintTab.style'

interface PrintTabProps {
  order?: GetOrderByIdResponse
}

export const PrintTab = ({ order }: PrintTabProps) => {
  return (
    <PrintTabCardContainer>
      <CardLayout>
        <PreparePrintCard order={order} />
      </CardLayout>
      <CardLayout>
        <BillCouponPrintCard order={order} />
      </CardLayout>
    </PrintTabCardContainer>
  )
}

export default PrintTab
