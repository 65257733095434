import NumberFormat from 'react-number-format'
import styled from 'styled-components'

export const StyledNumberFormat = styled(NumberFormat)`
  width: 100%;
  height: 100%;

  padding: 0 1rem;

  background: transparent;
  border: 0;
  outline: none;
  &.disabled,
  &:disabled {
    background-color: ${(props) => props.theme.backgroundColors.secondary};
  }

  &::placeholder {
    opacity: 1;
    font-size: 1.3rem;
    font-style: italic;
    font-weight: ${(props) => props.theme.fontWeight.light};
    color: ${(props) => props.theme.textColors.secondary};
  }
`

export const DefaultContainer = styled.div`
  white-space: nowrap;
`
