import { useEffect, useRef } from 'react'

export const useClickAway = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickAway = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    window.addEventListener('mousedown', handleClickAway)
    return () => {
      window.removeEventListener('mousedown', handleClickAway)
    }
  }, [ref, callback])

  return { ref }
}
