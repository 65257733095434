import styled from 'styled-components'
import { TextColors } from 'theme/theme'

export const Container = styled.div`
  width: 70rem;
  max-width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 50%;

  margin: auto;
  margin-top: 5rem;
  gap: 2rem;

  & > *:not(:last-child) {
    border-bottom: solid 1px ${(props) => props.theme.borderColors.secondary};
  }
`

export const StoreContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
`

export const StoreContent = styled.div`
  padding: 0 2rem;
  margin-top: 1rem;
  & > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`
export const PriceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
`

export const Dot = styled.div<{ color: keyof TextColors }>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.textColors[props.color]};
  margin-right: 1rem;
`
export const QuantityBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`
