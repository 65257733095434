import { getCustomersQuickSearchUri, usePaginatedCustomers } from 'api/customers'
import { SearchForm, useInputSearchForm } from 'components/InputSearchForm/InputSearchForm'
import { UserIcon } from 'constants/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SortingDirection } from 'types/sorting'

const CustomerSearchForm = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { reset, InputSearchForm } = useInputSearchForm()

  const { isFetching } = usePaginatedCustomers(
    ['customers:sideSearch', search, { 'order[lastname]': SortingDirection.Asc }],
    getCustomersQuickSearchUri(search, {
      'order[lastname]': SortingDirection.Asc,
      'order[firstname]': SortingDirection.Asc
    }),
    {
      enabled: search !== '',
      onSuccess: (data, totalItems) => {
        if (totalItems === 1) {
          navigate(`/customer-details/${data[0].id}`)
        } else {
          navigate(`/customer-manage`, { state: { search } })
        }
        setSearch('')
        reset()
      }
    }
  )
  const { t } = useTranslation()

  const onSearch = async (formData: SearchForm) => {
    if (!formData.value.trim()) {
      navigate(`/customer-manage`)
    } else {
      setSearch(formData.value)
    }
  }

  return (
    <InputSearchForm
      svg={UserIcon}
      text={t('inputSearchForm.tooltip.customer')}
      onSearch={onSearch}
      isLoading={isFetching}
      title={t('page.customer.manage.search')}
    />
  )
}

export default CustomerSearchForm
