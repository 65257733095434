import { yupResolver } from '@hookform/resolvers/yup'
import { useGetIncomingCardEditions } from 'api/cardEditions'
import { addPrintExclusion, GetPrintExclusionsQueryResponse } from 'api/printExclusions'
import { ColumnGapContainer, ErrorDisplay, RowLargeGapContainer } from 'app/OrderPrint/utils.style'
import classnames from 'classnames'
import { Text } from 'components/Text/Text.styles'
import { useMutation } from 'hooks/useAxiosMutation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Store } from 'types/entities'
import * as yup from 'yup'
import { StyledButton, StyledSelect } from './printExclusions.styles'

type ExclusionFormInputs = {
  edition: string
}

const schema = yup.object().shape({
  edition: yup.string().trim().required()
})

type Props = {
  setPrintExclusions: React.Dispatch<React.SetStateAction<GetPrintExclusionsQueryResponse[]>>
  active: boolean
  store: Store
}

function ExclusionPrintForm({ setPrintExclusions, active, store }: Props) {
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<ExclusionFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      edition: ''
    }
  })

  const { data: editions, refetch } = useGetIncomingCardEditions(store, { enabled: active })

  const { mutate: mutateAddPrintExclusions, isLoading } = useMutation(
    (edition: string) =>
      addPrintExclusion({
        store: store['@id'],
        edition: edition!.toString()
      }),
    {
      onSuccess: (excludedEdition) => {
        setPrintExclusions((prev) => [...prev, excludedEdition])
        refetch()
        reset()
      }
    }
  )

  const onSubmit = ({ edition }: ExclusionFormInputs) => {
    mutateAddPrintExclusions(edition)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ColumnGapContainer>
        <RowLargeGapContainer>
          <StyledSelect
            className={classnames({ 'is-invalid': errors.edition })}
            label={t('common.label.cardEdition')}
            placeholder={t('common.select.defaultOptions.cardEdition')}
            options={(editions || []).map((edition) => ({ value: edition['@id'], label: edition.name! }))}
            id={'edition'}
            control={control}
          />
          <StyledButton buttonType="submit" isLoading={isLoading}>
            {t('page.order.print.actions.exclude')}
          </StyledButton>
        </RowLargeGapContainer>
        <ErrorDisplay error={errors.edition} message={t('page.order.print.actions.excludeError')} />
        <Text color="info">{t('page.order.print.details.tips.editionsPreorder')}</Text>
      </ColumnGapContainer>
    </form>
  )
}

export default ExclusionPrintForm
