import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import InputNumber from 'components/InputNumber/InputNumber'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { CalculateIcon } from 'constants/icons'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CashboxesCountDetails } from 'types/entities'
import * as yup from 'yup'
import useCashbox, {
  AllCashboxFields,
  CashboxBillsCountEuro,
  CashboxCoinsCountEuro,
  DepositFields
} from '../utils/cashHelper'
import { FormActions, FormContainer, SectionTitle, SplitFieldsContainer } from './CashboxDetailsForm.style'

export type FormData = { [key in AllCashboxFields]?: number }

interface Props {
  disabledFields?: boolean
  onSubmit?: (formData: FormData) => void
  isLoading?: boolean
  tooltipText?: string
  defaultValues?: CashboxesCountDetails
  enableDeposit?: boolean
}

const coinsSchema = {
  [CashboxBillsCountEuro.Euros5]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxBillsCountEuro.Euros10]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxBillsCountEuro.Euros20]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxBillsCountEuro.Euros50]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxBillsCountEuro.Euros100]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxBillsCountEuro.Euros200]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxBillsCountEuro.Euros500]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxCoinsCountEuro.Cent1]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxCoinsCountEuro.Cents2]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxCoinsCountEuro.Cents5]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxCoinsCountEuro.Cents10]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxCoinsCountEuro.Cents20]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxCoinsCountEuro.Cents50]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxCoinsCountEuro.Euro1]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  [CashboxCoinsCountEuro.Euros2]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
}

const depositSchema = {
  [DepositFields.Deposit]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  [DepositFields.Gift]: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required()
}

export default function CashboxDetailForm({
  disabledFields,
  onSubmit = () => {},
  isLoading = false,
  tooltipText,
  defaultValues,
  enableDeposit = false
}: Props) {
  const { t } = useTranslation()
  const { billFields, depositFields, coinFields } = useCashbox()

  const schema = yup.object().shape({
    ...coinsSchema,
    ...(enableDeposit && depositSchema)
  })

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    trigger
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: defaultValues
  })

  const getValidationErrorMessage = (key: AllCashboxFields) => {
    if (key === DepositFields.Deposit) {
      return t('page.cashbox.accountStep.depositTotalRequired')
    }
    if (key === DepositFields.Gift) {
      return t('page.cashbox.accountStep.depositGiftTotalRequired')
    }
    return t('form.required.field')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <div>
          <SectionTitle color="secondary" fontWeight="light">
            {t('common.label.coinNumber')}
          </SectionTitle>
          <SplitFieldsContainer>
            {_.map(
              coinFields,
              (value, key: CashboxCoinsCountEuro) =>
                value && (
                  <InputNumber
                    setValue={setValue}
                    register={register}
                    id={key}
                    label={value.label}
                    showArrows={false}
                    key={`input-${key}`}
                    disabled={disabledFields}
                    trigger={trigger}
                  />
                )
            )}
          </SplitFieldsContainer>
        </div>

        <div>
          <SectionTitle color="secondary" fontWeight="light">
            {t('common.label.banknoteNumber')}
          </SectionTitle>
          <SplitFieldsContainer>
            {_.map(
              billFields,
              (value, key: CashboxBillsCountEuro) =>
                value && (
                  <InputNumber
                    setValue={setValue}
                    register={register}
                    id={key}
                    label={value.label}
                    showArrows={false}
                    key={`input-${key}`}
                    disabled={disabledFields}
                    trigger={trigger}
                    step="any"
                  />
                )
            )}
          </SplitFieldsContainer>
        </div>

        {enableDeposit &&
          _.map(
            depositFields,
            (value, key: AllCashboxFields) =>
              value && (
                <div key={`input-${key}`}>
                  <InputNumber
                    setValue={setValue}
                    register={register}
                    id={key}
                    label={value.label}
                    showArrows={false}
                    className={classNames({ 'fullwidth-input': true, 'is-invalid': errors[key] })}
                    disabled={disabledFields}
                    trigger={trigger}
                    step="any"
                  />
                  <BasicFieldValidationError error={errors[key]} message={getValidationErrorMessage(key)} />
                </div>
              )
          )}
      </FormContainer>

      {!disabledFields && (
        <FormActions>
          {tooltipText ? (
            <TooltipHover id="count-form-title" content={tooltipText}>
              <Button icon={CalculateIcon} shape="circle" buttonType="submit" isLoading={isLoading} />
            </TooltipHover>
          ) : (
            <Button icon={CalculateIcon} shape="circle" buttonType="submit" isLoading={isLoading} />
          )}
          <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')} />
        </FormActions>
      )}
    </form>
  )
}
