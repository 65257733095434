import Tooltip from 'components/Tooltip/Tooltip'
import { Trans } from 'react-i18next'

export default function EventDescriptionTooltip() {
  return (
    <Tooltip id="descriptionTooltip" width="40rem">
      <Trans
        i18nKey="page.events.create.tooltips.description"
        components={{
          br: <br />,
          i: <i />
        }}
      />
    </Tooltip>
  )
}
