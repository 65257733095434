import { Skeleton } from '@mui/material'
import Button from 'components/Button/Button'
import { MoreIcon } from 'constants/icons'
import { useState } from 'react'
import { Banner } from '../BannerManagement'
import { CatalogItemLayout } from '../BannerManagement.styles'
import { Image } from '../ImageCard.styles'

interface Props {
  banner: Banner
  handleAdd: (banner: Banner) => void
}
export default function CatalogItem({ banner, handleAdd }: Props) {
  const [isImageLoaded, setImageLoaded] = useState(false)
  return (
    <CatalogItemLayout key={`banner-${banner.id}`}>
      <Button icon={MoreIcon} shape="circle" variant="white" onClick={() => handleAdd(banner)} />
      {!isImageLoaded && <Skeleton variant="rectangular" width={250} height={100} />}
      <Image src={banner.src} onLoad={() => setImageLoaded(true)} />
    </CatalogItemLayout>
  )
}
