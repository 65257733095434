import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

export type PostPaybackBody = Api.PostPaybacksReport.RequestBody
type PostPaybackReponse = Api.PostPaybacksReport.ResponseBody

export const postPayback = async (body: PostPaybackBody) => {
  let res = await playInApi.post<PostPaybackBody, AxiosResponse<PostPaybackReponse>>(API_ROUTES.paybacks.root, body)
  return res.data
}