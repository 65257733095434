import { Section } from 'components/Section/Section.styles'
import Tabs, { TabProps } from 'components/Tabs/Tabs'
import MainTitle from 'components/Title/MainTitle'
import { BNF_STORE_ID } from 'constants/configs'
import { useAtom } from 'jotai'
import { useCheckMatch } from 'permissions/permissions'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoleAction } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import CardsProductsTab from './tabs/cardsProductsTab/CardsProductsTab'
import CustomPrintTab from './tabs/customPrintTab/CustomPrintTab'
import OtherProductsTab from './tabs/otherProductsTab/OtherProductsTab'
import PrintExclusionTab from './tabs/printExclusionsTab/PrintExclusionsTab'

function OrderPrint() {
  const { t } = useTranslation()
  const [store] = useAtom(defaultStoreAtom)
  const { checkMatch } = useCheckMatch()
  const [selectedTab, setSelectedTab] = useState<number | null>()

  const tabs: TabProps[] = useMemo(
    () => [
      {
        tabLabel: t('page.order.print.details.tabs.cardProducts'),
        noStyle: true,
        panelComponent: <CardsProductsTab store={store!} active={selectedTab === 0} />,
        disabled: store?.['@id'] !== BNF_STORE_ID || !checkMatch([RoleAction.ROLE_ACTION_ORDERS_PRINT_CARDS])
      },
      {
        tabLabel: t('page.order.print.details.tabs.otherProducts'),
        noStyle: true,
        panelComponent: <OtherProductsTab store={store!} active={selectedTab === 1} />
      },
      {
        tabLabel: t('page.order.print.details.tabs.customPrint'),
        noStyle: true,
        panelComponent: <CustomPrintTab active={selectedTab === 2} />,
        disabled:
          !store?.warehouse &&
          !(store?.['@id'] === BNF_STORE_ID && checkMatch([RoleAction.ROLE_ACTION_ORDERS_PRINT_CARDS]))
      },
      {
        tabLabel: t('page.order.print.details.tabs.ignoreList'),
        noStyle: true,
        panelComponent: <PrintExclusionTab store={store!} active={selectedTab === 3} />,
        disabled: store?.warehouse || !checkMatch([RoleAction.ROLE_ACTION_EXCLUDE_EXT_PRINT])
      }
    ],
    [t, store, checkMatch, selectedTab]
  )

  return (
    <Section>
      <MainTitle title={t('page.order.print.title')} showStoreSelect={true} />
      <Tabs tabs={tabs} onTabChange={setSelectedTab} />
    </Section>
  )
}

export default OrderPrint
