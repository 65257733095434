import checkSvg from 'assets/svg/icon/check.svg'
import styled from 'styled-components'

export const Checkbox = styled.div`
  height: 2.5rem;
  width: 2.5rem;

  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.borderColors.secondary};
  border-radius: 0.4rem;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &.checked,
  :checked {
    background-image: url(${checkSvg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    background-color: ${(props) => props.theme.backgroundColors.secondary};
  }

  &.is-invalid {
    border: 2px solid ${(props) => props.theme.borderColors.danger};
  }
`
