import { PLAYIN_SUPPORT_EMAIL } from 'constants/configs'
import { isNil } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EventType } from 'types/events'
import { NotificationId, NotificationType } from 'types/notifications'
import { useEmit } from './useEventEmitter'

export const useApiNotifications = () => {
  const { t } = useTranslation()
  const emitNotification = useEmit()

  const successNotificationContent = useMemo(
    () => ({
      id: NotificationId.Success,
      type: NotificationType.Success,
      title: t('notification.success.title')
    }),
    [t]
  )

  const warningNotificationContent = useMemo(
    () => ({
      id: NotificationId.Warning,
      type: NotificationType.Warning,
      title: t('notification.warning.title'),
      text: t('notification.warning.text')
    }),
    [t]
  )

  const errorNotificationContent = useMemo(
    () => ({
      id: NotificationId.Error,
      type: NotificationType.Error,
      title: t('notification.error.title'),
      text: t('notification.error.text', { email: PLAYIN_SUPPORT_EMAIL })
    }),
    [t]
  )

  const printApiNotificationTitle = useCallback(
    (type: NotificationType, apiContent?: any): string => {
      if (apiContent?.violations?.length) {
        if (apiContent?.violations?.length > 1) return t('notification.errors.title')
        return apiContent?.violations[0].message
      }
      if (apiContent['hydra:description']) return apiContent['hydra:description']
      if (apiContent && typeof apiContent === 'string') return apiContent
      return type === NotificationType.Warning ? t('notification.warning.title') : t('notification.error.title')
    },
    [t]
  )

  const printApiNotificationText = useCallback(
    (type: NotificationType, apiContent?: any, text?: string): string | string[] => {
      if (apiContent?.violations?.length > 1) return apiContent.violations.map((violation) => violation.message)
      if (!isNil(text)) return text
      return type === NotificationType.Warning ? '' : t('notification.error.text', { email: PLAYIN_SUPPORT_EMAIL })
    },
    [t]
  )

  const getApiWarningNotificationContent = useCallback(
    (apiContent?: any, text?: string) => ({
      id: NotificationId.APIWarning,
      type: NotificationType.Warning,
      title: printApiNotificationTitle(NotificationType.Warning, apiContent),
      text: printApiNotificationText(NotificationType.Warning, apiContent, text)
    }),
    [printApiNotificationTitle, printApiNotificationText]
  )

  const getApiErrorNotificationContent = useCallback(
    (apiContent?: any, text?: string) => ({
      id: NotificationId.APIError,
      type: NotificationType.Error,
      title: printApiNotificationTitle(NotificationType.Error, apiContent),
      text: printApiNotificationText(NotificationType.Error, apiContent, text)
    }),
    [printApiNotificationTitle, printApiNotificationText]
  )

  const emitSuccessNotification = () => emitNotification(EventType.Notification, successNotificationContent)

  const emitErrorNotification = useCallback(
    (status: number, errorData?: any, text?: string) => {
      if (status >= 500) {
        emitNotification(EventType.Notification, errorNotificationContent)
      } else if (status === 422 || status === 403 || status === 404) {
        emitNotification(EventType.Notification, getApiWarningNotificationContent(errorData, text))
      } else emitNotification(EventType.Notification, warningNotificationContent)
    },
    [emitNotification, getApiWarningNotificationContent, errorNotificationContent, warningNotificationContent]
  )

  return {
    successNotificationContent,
    warningNotificationContent,
    errorNotificationContent,
    getApiWarningNotificationContent,
    getApiErrorNotificationContent,
    emitSuccessNotification,
    emitErrorNotification
  }
}
