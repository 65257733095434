import Svg from 'components/Svg/Svg'
import Link from 'features/Permissions/Link/Link'
import styled from 'styled-components'

export const Aside = styled.aside`
  padding: 2rem 1.5rem;
  padding-top: 0;
  box-shadow: 0px 20px 20px 5px rgb(0 0 0 / 15%);
  background-color: ${(props) => props.theme.backgroundColors.secondary2};
  position: relative;
  min-height: calc(100vh - 5rem);
  width: 22.5rem;
  @media screen and (max-width: ${(props) => props.theme.screenWidth.tabletScreen}) {
    display: none;
  }

  &.open-sidebar {
    transition: margin 0.3s linear;

    @media screen and (max-width: ${(props) => props.theme.screenWidth.tabletScreen}) {
      display: block;
      width: 35%;
    }

    @media screen and (max-width: ${(props) => props.theme.screenWidth.mobileScreen}) {
      width: 90%;
    }
  }

  &.close-sidebar {
    transition: margin 0.3s linear;
    margin-left: -22.5rem;
  }
`
export const MenuSearch = styled.div`
  position: sticky;
  top: -1px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: ${(props) => props.theme.backgroundColors.secondary2};

  & > * {
    width: 19.5rem;
    margin-bottom: 1rem;
    max-width: 100%;
  }

  &.stuck {
    border-bottom: 1px solid ${(props) => props.theme.colors.grey4};
  }
`
export const MenuItem = styled.div`
  margin-bottom: 2rem;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1.5rem;
`

export const MainTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.textColors.purple};
  text-transform: uppercase;
  font-size: 1.6rem;
`

export const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.purple4};
  cursor: pointer;
`

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 0.2rem solid ${(props) => props.theme.borderColors.mysteryBlue};
  padding-left: 1rem;
  gap: 1rem;

  &.close {
    display: none;
  }

  &.open {
    display: flex;
    flex-direction: column;
  }
`

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.textColors.mysteryBlue};
`

export const StyledTextLink = styled.a`
  color: ${(props) => props.theme.textColors.mysteryBlue};
`

export const AccountNavContainer = styled.div`
  display: none;

  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
`

export const ArrowIcon = styled(Svg)`
  &.arrow-down {
    transform: rotate(180deg);
  }
`
