import { CashboxCollectionBody, getCashboxCollectionUri, usePaginatedCashboxes } from 'api/cashbox'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { EditIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import LinkButton from 'features/Permissions/LinkButton'
import { RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Cashbox, Store } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatDateAndHour } from 'utils/dates'
import { mergeFormQueryParams } from 'utils/queryParams'
import { ButtonContainer } from './CashboxManagement.style'

interface Props {
  formData?: CashboxCollectionBody
  watchLoading: (loading: boolean) => void
  store: Store
}

const defaultSort: OrderParam<CashboxCollectionBody> = {
  sortedBy: 'createdAt',
  direction: SortingDirection.Desc,
  queryParams: { 'order[createdAt]': SortingDirection.Desc }
}

export default function OpenedClashbox({ watchLoading, formData, store }: Props) {
  const [orderParams, setOrderParams] = useState<OrderParam<CashboxCollectionBody>>(defaultSort)
  const [cashboxQueryUri, setCashboxQueryUri] = useState<string>('')

  const { t } = useTranslation()
  const {
    data: openedCashboxes,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    totalItems,
    hasNextPage,
    hasBeenCalled,
    refetch,
    isRefetching
  } = usePaginatedCashboxes(cashboxQueryUri, cashboxQueryUri, {
    enabled: !!cashboxQueryUri
  })

  useEffect(() => {
    if (formData) {
      const newUri = getCashboxCollectionUri(
        mergeFormQueryParams<CashboxCollectionBody>({
          data: { ...formData, 'exists[closedAt]': false },
          orderParams: orderParams?.queryParams
        })
      )

      if (newUri === cashboxQueryUri) refetch()
      else setCashboxQueryUri(newUri)
    }
  }, [formData, orderParams, cashboxQueryUri, refetch])

  useEffect(() => {
    watchLoading(isLoading || isRefetching)
  }, [isLoading, watchLoading, isRefetching])

  const columns: Column<Cashbox, CashboxCollectionBody>[] = [
    {
      key: 'createdAt',
      name: t('common.label.openedAt'),
      sortable: true,
      orderQueryParam: 'order[createdAt]',
      decorator: (cashbox: Cashbox) => (
        <Cell>
          <Text>{formatDateAndHour(cashbox.createdAt)}</Text>
        </Cell>
      )
    },
    {
      key: 'createdBy',
      name: t('common.label.collaborator'),
      decorator: (cashbox: Cashbox) => (
        <Cell>
          <Text>{`${cashbox.createdBy!.firstname} ${cashbox.createdBy!.lastname}`}</Text>
        </Cell>
      )
    },
    {
      key: 'action',
      decorator: (cashbox: Cashbox) => (
        <ButtonContainer>
          <LinkButton
            icon={EditIcon}
            shape="circle"
            variant="white"
            route={RouteName.CashboxDetails}
            value={cashbox.id}
          />
        </ButtonContainer>
      )
    }
  ]

  return (
    <>
      {formData && hasBeenCalled && (
        <CardLayout>
          <Listing
            emptyText={t('page.cashbox.manage.cashboxEmpty')}
            title={t('page.cashbox.manage.openedTitle', { store: store.shortName })}
            countText={t('page.cashbox.manage.cashboxCountText', {
              current: openedCashboxes.length,
              total: totalItems
            })}
            data={openedCashboxes}
            columns={columns}
            totalCount={totalItems}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching && !isFetchingNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onOrderChanged={setOrderParams}
            defaultSort={defaultSort}
          />
        </CardLayout>
      )}
    </>
  )
}
