import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { ProductStore } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type PatchProductStoreResponse = Api.PatchProductStoresId.ResponseBody
export type PatchProductStoreBody = Api.PatchProductStoresId.RequestBody
export const patchProductStore = async (
  productStoreId: string | number,
  body: PatchProductStoreBody
): Promise<ProductStore> => {
  const res = await playInApi.patch<PatchProductStoreBody, AxiosResponse<PatchProductStoreResponse>>(
    `${API_ROUTES.productStores.root}/${productStoreId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

  return res.data
}
