import { getDifferenceColor, getDifferencePrefix } from 'app/CashboxDetails/utils/cashHelper'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { MainTitleStyle, Title2 } from 'components/Title/Title.styles'
import { useTranslation } from 'react-i18next'
import { Cashbox } from 'types/entities'
import { formatDateAndHour } from 'utils/dates'
import { DetailsBody, DetailSection, TotalText } from '../CashboxManagement.style'
import InfoRow from './InfoRow'

interface Props extends Omit<ModalProps, 'children'> {
  cashbox?: Cashbox
}

export default function DetailsModal({ open, onClose, cashbox }: Props) {
  const { t } = useTranslation()

  const expectedCashDifference = cashbox
    ? cashbox.totalCashCalculated! - cashbox.totalCash! - (cashbox.cashFundStartCalculated ?? 0)
    : 0

  return (
    <Modal open={open} onClose={onClose}>
      <DetailsBody>
        {cashbox && (
          <>
            <MainTitleStyle as="h2">{t('page.cashbox.manage.details.title')}</MainTitleStyle>

            <DetailSection>
              <Title2>{t('page.cashbox.manage.details.cashboxDetail')}</Title2>
              <div>
                <InfoRow label={t('common.label.openedAt')}>
                  <Text fontWeight="light">{formatDateAndHour(cashbox.createdAt)}</Text>
                </InfoRow>
                <InfoRow label={t('common.label.collaborator')}>
                  <Text fontWeight="light">{`${cashbox.createdBy?.firstname} ${cashbox.createdBy?.lastname}`}</Text>
                </InfoRow>
                <InfoRow label={t('page.cashbox.cashFundStart')}>
                  <TextNumber
                    suffix={'\u00a0€'}
                    value={cashbox.cashFundStartCalculated}
                    fontWeight="light"
                    decimalScale={2}
                  />
                </InfoRow>
                <InfoRow
                  label={t('page.cashbox.expectedDifference')}
                  fontStyle="italic"
                  fontWeight="medium"
                  color={getDifferenceColor(cashbox.cashFundStartCalculated! - cashbox.cashFundStart!)}
                >
                  <TextNumber
                    suffix={'\u00a0€'}
                    value={cashbox.cashFundStartCalculated! - cashbox.cashFundStart!}
                    prefix={getDifferencePrefix(cashbox.cashFundStartCalculated! - cashbox.cashFundStart!)}
                    color={getDifferenceColor(cashbox.cashFundStartCalculated! - cashbox.cashFundStart!)}
                    fontStyle="italic"
                    fontWeight="medium"
                    decimalScale={2}
                  />
                </InfoRow>
              </div>
              <div>
                <InfoRow label={t('common.label.closedAt')}>
                  <Text fontWeight="light">{formatDateAndHour(cashbox.closedAt)}</Text>
                </InfoRow>
                <InfoRow label={t('common.label.collaborator')}>
                  <Text fontWeight="light">{`${cashbox.closedBy?.firstname} ${cashbox.closedBy?.lastname}`}</Text>
                </InfoRow>
                <InfoRow label={t('page.cashbox.cashFundEnd')}>
                  <TextNumber
                    suffix={'\u00a0€'}
                    value={cashbox.cashFundEndCalculated}
                    fontWeight="light"
                    decimalScale={2}
                  />
                </InfoRow>
              </div>
            </DetailSection>
            <DetailSection>
              <Title2>{t('page.cashbox.manage.details.cashboxDeposit')}</Title2>
              <TotalText>
                <TextNumber
                  value={cashbox.totalCashCalculated! - cashbox.cashFundEndCalculated!}
                  suffix={'\u00a0€'}
                  size="2.8rem"
                  decimalScale={2}
                />
              </TotalText>
              <div>
                <InfoRow fontWeight="regular" label={t('page.cashbox.manage.details.totalCashCalculated')}>
                  <TextNumber value={cashbox.totalCashCalculated} suffix={'\u00a0€'} decimalScale={2} />
                </InfoRow>
                <InfoRow
                  label={t('page.cashbox.expectedDifference')}
                  fontStyle="italic"
                  color={getDifferenceColor(expectedCashDifference)}
                  fontWeight="medium"
                >
                  <TextNumber
                    fontStyle="italic"
                    value={expectedCashDifference}
                    suffix={'\u00a0€'}
                    prefix={getDifferencePrefix(expectedCashDifference)}
                    color={getDifferenceColor(expectedCashDifference)}
                    fontWeight="medium"
                    decimalScale={2}
                  />
                </InfoRow>
              </div>
              <div>
                <InfoRow fontWeight="regular" label={t('page.cashbox.manage.details.totalChecksCalculated')}>
                  <TextNumber value={cashbox.totalChecksCalculated} suffix={'\u00a0€'} decimalScale={2} />
                </InfoRow>
                <InfoRow
                  fontWeight="medium"
                  label={t('page.cashbox.expectedDifference')}
                  fontStyle="italic"
                  color={getDifferenceColor(cashbox.totalChecksCalculated! - cashbox.totalChecks!)}
                >
                  <TextNumber
                    fontStyle="italic"
                    value={cashbox.totalChecksCalculated! - cashbox.totalChecks!}
                    suffix={'\u00a0€'}
                    prefix={getDifferencePrefix(cashbox.totalChecksCalculated! - cashbox.totalChecks!)}
                    color={getDifferenceColor(cashbox.totalChecksCalculated! - cashbox.totalChecks!)}
                    fontWeight="medium"
                    decimalScale={2}
                  />
                </InfoRow>
              </div>
              <div>
                <InfoRow fontWeight="regular" label={t('page.cashbox.manage.details.totalGiftsCalculated')}>
                  <TextNumber value={cashbox.totalGiftVouchersCalculated} suffix={'\u00a0€'} decimalScale={2} />
                </InfoRow>
                <InfoRow
                  fontWeight="medium"
                  label={t('page.cashbox.expectedDifference')}
                  fontStyle="italic"
                  color={getDifferenceColor(cashbox.totalGiftVouchersCalculated! - cashbox.totalGiftVouchers!)}
                >
                  <TextNumber
                    fontStyle="italic"
                    value={cashbox.totalGiftVouchersCalculated! - cashbox.totalGiftVouchers!}
                    suffix={'\u00a0€'}
                    prefix={getDifferencePrefix(cashbox.totalGiftVouchersCalculated! - cashbox.totalGiftVouchers!)}
                    color={getDifferenceColor(cashbox.totalGiftVouchersCalculated! - cashbox.totalGiftVouchers!)}
                    fontWeight="medium"
                    decimalScale={2}
                  />
                </InfoRow>
              </div>
              <div>
                <InfoRow label={t('page.cashbox.manage.details.totalCreditCard')}>
                  <TextNumber value={cashbox.totalCreditCard} suffix={'\u00a0€'} fontWeight="light" decimalScale={2} />
                </InfoRow>
                <InfoRow label={t('page.cashbox.manage.details.totalMealBenefits')}>
                  <TextNumber
                    value={cashbox.totalMealBenefits}
                    suffix={'\u00a0€'}
                    fontWeight="light"
                    decimalScale={2}
                  />
                </InfoRow>
                <InfoRow label={t('page.cashbox.manage.details.refund')}>
                  <TextNumber value={cashbox.totalRefund} suffix={'\u00a0€'} fontWeight="light" decimalScale={2} />
                </InfoRow>
              </div>
            </DetailSection>

            <DetailSection>
              <Title2>{t('page.cashbox.manage.details.cashboxComment')}</Title2>
              <Text fontStyle="italic" fontWeight="light">
                {cashbox.comment ? cashbox.comment : t('common.label.noComment')}
              </Text>
            </DetailSection>
          </>
        )}
      </DetailsBody>
    </Modal>
  )
}
