import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import { ColumnContainer, RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import styled from 'styled-components'
import theme from 'theme/theme'

export const PreviewCounterText = styled.span`
  font-style: italic;
  color: ${theme.textColors.secondary2};
`

export const ColumnGapContainer = styled(ColumnContainer)`
  gap: 2rem;
`

export const RowLargeGapContainer = styled(RowContainer)`
  gap: 2rem;
`

export const ErrorDisplay = styled(BasicFieldValidationError)`
  margin-top: 0;
`

export const PreviewButtonContainer = styled(RowLargeGapContainer)`
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
