import styled, { css } from 'styled-components'

const formCss = css`
  display: grid;
  gap: 3.5rem 0;
`

export const Form = styled.form`
  ${formCss}
`

export const FormSection = styled.div`
  display: grid;
  gap: 2rem 7.5rem;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const FieldsSection = styled.div`
  display: grid;
  gap: 2rem 3.5rem;
  grid-template-columns: 1fr 1fr;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`
export const FormBlock = styled.div`
  display: grid;
  gap: 3.5rem 0;
`

export const DescriptionWrapper = styled.div`
  display: grid;
  gap: 2rem 0;
  align-items: flex-start;
`

export const TimeFieldsSection = styled(FieldsSection)`
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
  }
`
export const SubmitWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
`
