import Button from 'components/Button/Button'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 10rem;
  align-items: flex-start;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 1fr;
  }
`

export const AdditionalSection = styled.div`
  margin-top: 5rem;
  padding-top: 5rem;
  border-top: 1px solid ${(props) => props.theme.borderColors.secondary2};
`
export const TwoColsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`
export const SubmitButton = styled(Button)`
  margin-top: 2rem;
`

export const ProductSelectorWrapper = styled.div`
  display: grid;
  gap: 2rem;
`

export const ProductSelectorInputWrapper = styled.div`
  max-width: 41rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;
  align-items: flex-end;
`

export const ProductSelectorName = styled(Text)`
  margin-top: 1rem;
  text-align: center;
  max-width: 21.5rem;
`

export const SavedImageInfo = styled.div`
  margin-top: 1rem;
  margin-left: 5px;
  color: green;
  display: flex;
  flex-flow: row wrap;
`

export const SavedImageText = styled.div`
  margin-right: 5px;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`