import _ from 'lodash'
import qs from 'qs'

export const stringify = (obj: Object) =>
  qs.stringify(obj, {
    skipNulls: true,
    arrayFormat: 'repeat',
    filter: (prefix, value) => (value === '' ? undefined : value)
  })

export const mergeFormQueryParams = <T extends object>(queryParams: { [key: string]: T | undefined }): T =>
  _.reduce<{ [key: string]: T | undefined }, T>(
    queryParams,
    (prev, current) => _.merge(prev, _.omitBy(current, _.isNil)), // omitBy: don't add nil queryparams in results
    {} as T
  )

export const getIdFromIri = (iri: string) => `${iri.split('/').pop()}`

export const isObjectEmpty = (queryParams) => {
  // find a value that is not '', empty array, empty object, null, undefined
  return (
    _.find(
      queryParams,
      (value) => value !== '' && !_.isEqual(value, []) && !_.isEqual(value, {}) && !_.isNil(value)
    ) === undefined
  )
}
