import { GetOrderByIdResponse } from 'api/orders'
import { useOrderContext } from 'app/OrderDetails/helper'
import EmailSendModalModal from 'app/OrderDetails/modals/EmailSendModal/EmailSendModal'
import OrderLitigationModal from 'app/OrderDetails/modals/OrderLitigationModal/OrderLitigationModal'
import ActionLogModal from 'features/Modals/ActionLog/ActionLogModal'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoleAction } from 'types/playInApiInterfaces'
import { ActionButton, ButtonsContainer, ButtonsLayout } from './MiscTab.styles'

interface MiscTabProps {
  toggleComment: MouseEventHandler
  toggleInstruction: MouseEventHandler
  order: GetOrderByIdResponse
}

const MiscTab = ({ toggleComment, toggleInstruction, order }: MiscTabProps) => {
  const { t } = useTranslation()
  const [actionLogOpen, toggleActionLog] = useState(false)
  const [emailModalOpen, toggleEmailModal] = useState(false)
  const [orderLitigationModalOpen, toggleOrderLitigationModal] = useState(false)
  const { inMyStoresOrder } = useOrderContext()

  return (
    <>
      <ButtonsContainer>
        <ButtonsLayout>
          <ActionButton variant="white" onClick={toggleComment}>
            {t('page.order.detail.tab.misc.comment')}
          </ActionButton>
          <ActionButton variant="white" onClick={() => toggleActionLog(true)}>
            {t('page.order.detail.tab.misc.actionLog')}
          </ActionButton>
          <ShouldDisable
            permissions={[RoleAction.ROLE_ACTION_ORDER_SEND_EMAIL]}
            deniedExtraCondition={!inMyStoresOrder}
          >
            <ActionButton variant="white" onClick={() => toggleEmailModal(true)}>
              {t('page.order.detail.tab.misc.sendEmail')}
            </ActionButton>
          </ShouldDisable>
          <ShouldDisable
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_LITIGATION]}
            deniedExtraCondition={!inMyStoresOrder}
          >
            <ActionButton variant="white" onClick={() => toggleOrderLitigationModal(true)}>
              {t('page.order.detail.tab.misc.dispute')}
            </ActionButton>
          </ShouldDisable>
          <ActionButton variant="white" onClick={toggleInstruction}>
            {t('page.order.detail.tab.misc.instruction')}
          </ActionButton>
        </ButtonsLayout>
      </ButtonsContainer>

      <ActionLogModal open={actionLogOpen} onClose={() => toggleActionLog(false)} target={order['@id']!} />
      <EmailSendModalModal open={emailModalOpen} onClose={() => toggleEmailModal(false)} order={order} />
      <OrderLitigationModal
        open={orderLitigationModalOpen}
        onClose={() => toggleOrderLitigationModal(false)}
        order={order}
      />
    </>
  )
}

export default MiscTab
