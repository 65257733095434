import { fetchOrderEntries } from 'api/orderEntries'
import { FetchOrdersQueryParams } from 'api/orders'
import Loader from 'components/Loader/Loader'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Column } from 'components/SortedTable/SortedTable'
import Svg from 'components/Svg/Svg'
import { Text, TextWithIcon } from 'components/Text/Text.styles'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { CheckIcon, CrossIcon, PlayinPIcon, WarehouseIcon } from 'constants/icons'
import { useOrderStatusOptions } from 'hooks/useSelectOptions'
import { RouteName } from 'permissions/permissions'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Order } from 'types/entities'
import { formatCalendarDate } from 'utils/dates'
import { getCustomerIcon } from 'utils/externalSellers'
import { RoundIcon, TwoLinesCell } from './ProductSalesTab.styles'

export type FormattedOrder = {
  id?: number
  date?: string | null
  customerName?: string | null
  sourceName?: string | null
  playinAccountType?: string | null
  quantity?: number
  stock?: string
  status?: string | null
  shippingMode?: string
  trackingNumber?: string
  sent: boolean
  paid: boolean
  location?: any
}

const OrderEntryQuantity = ({ productId, orderId }: { productId: string; orderId?: number }) => {
  const { data: orderEntries, isFetching } = useQuery(['orderEntries', productId, orderId], () =>
    fetchOrderEntries({ product: productId, order: `${orderId}`, hideChild: false })
  )

  return isFetching ? <Loader /> : <Text>{orderEntries?.[0].quantity}</Text>
}

export const useOrdersTable = (productId: string) => {
  const { t } = useTranslation()

  const { getStatusTraduction } = useOrderStatusOptions()

  const columns: Column<FormattedOrder, FetchOrdersQueryParams>[] = [
    {
      name: t('common.label.date'),
      key: 'date',
      sortable: true,
      orderQueryParam: 'order[createdAt]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text>{formatCalendarDate(order.date)}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.clientProfile'),
      key: 'customerName',
      decorator: (order) => {
        const icon = getCustomerIcon(order.playinAccountType, order.sourceName)
        return (
          <ClickableCell route={RouteName.OrderDetails} value={order.id}>
            <TextWithIcon iconPosition="left" fontWeight="light" color="secondary">
              {icon && <Svg svg={icon} />}
              {order.customerName}
            </TextWithIcon>
          </ClickableCell>
        )
      }
    },
    {
      name: t('common.label.quantity'),
      key: 'quantity',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <OrderEntryQuantity productId={productId} orderId={order.id} />
        </ClickableCell>
      )
    },
    {
      name: t('common.label.stock'),
      key: 'stock',
      sortable: true,
      orderQueryParam: 'order[stock.shortName]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <div title={t('page.product.infos.stockStore', { storeName: order.location?.shortName })}>
            {order.stock === WAREHOUSE_STORE_ID ? <Svg svg={WarehouseIcon} /> : <Svg svg={PlayinPIcon} />}
          </div>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.status'),
      key: 'status',
      sortable: true,
      orderQueryParam: 'order[status]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <Text fontStyle="italic">{order.status}</Text>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.shippingMode'),
      key: 'shippingMode',
      sortable: true,
      orderQueryParam: 'order[shippingMode.name]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          <TwoLinesCell>
            <Text>{order.shippingMode}</Text>
            {order.trackingNumber && (
              <Text fontWeight="light" color="secondary" size="1.3rem">
                {t('page.product.detail.tabs.sales.trackingNumber', { trackingNumber: order.trackingNumber })}
              </Text>
            )}
          </TwoLinesCell>
        </ClickableCell>
      )
    },
    {
      name: t('common.label.sent'),
      key: 'sent',
      sortable: true,
      orderQueryParam: 'order[productSent]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          {order.sent ? (
            <RoundIcon svg={CheckIcon} background="success" />
          ) : (
            <RoundIcon svg={CrossIcon} background="danger" />
          )}
        </ClickableCell>
      )
    },
    {
      name: t('common.label.paid'),
      key: 'paid',
      sortable: true,
      orderQueryParam: 'order[paymentReceived]',
      decorator: (order) => (
        <ClickableCell route={RouteName.OrderDetails} value={order.id}>
          {order.paid ? (
            <RoundIcon svg={CheckIcon} background="success" />
          ) : (
            <RoundIcon svg={CrossIcon} background="danger" />
          )}
        </ClickableCell>
      )
    }
  ]

  const formatOrderEntry = (order: Order): FormattedOrder => {
    return {
      id: order.id,
      date: order.createdAt,
      customerName: order?.customer?.fullName,
      sourceName: order.sourceName,
      playinAccountType: order.customer?.playinAccountType?.['@id'],
      stock: order.stock?.['@id'],
      status: getStatusTraduction(order.status),
      shippingMode: order?.shippingMode?.technicalName,
      trackingNumber: order.trackingNumber,
      sent: !!order.productSent,
      paid: !!order.paidAt,
      location: order.stock
    }
  }

  return {
    columns,
    formatOrderEntry
  }
}
