import { useGetMyCashboxes } from 'api/cashbox'
import { getStoreById } from 'api/stores'
import CashboxCreateStep from 'app/CashboxDetails/steps/CashboxCreateStep'
import { CashboxesList } from 'app/CashboxDetails/steps/CashboxStep.style'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import LinkButton from 'features/Permissions/LinkButton'
import useAuth from 'hooks/useAuth'
import { useAtom } from 'jotai'
import { RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { defaultStoreAtom } from 'utils/jotaiAtom'

export default function CashboxCreate() {
  const { me } = useAuth()
  const [store] = useAtom(defaultStoreAtom)

  const [tooManyCashboxes, hasTooManyCashboxes] = useState<boolean>(false)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: userCashboxes, isFetching: userCashboxLoading } = useGetMyCashboxes()

  const { data: storeData } = useQuery(['store', store?.id], () => getStoreById(store?.id!), {
    enabled: userCashboxes && userCashboxes.length === 0 //Store datas are stored in cashbox object, so we only need to fetch them if we dont find opened cashbox
  })

  useEffect(() => {
    //In practice, users can't only have 1 opened cashbox
    if (userCashboxes && userCashboxes.length === 1) {
      //If this cashbox is found, no need to create her so we redirect user to the cashbox-details/:id page
      navigate(`/cashbox-details/${userCashboxes[0].id}`, { replace: true })
    } else if (userCashboxes && userCashboxes.length > 1) {
      hasTooManyCashboxes(true)
    }
  }, [userCashboxes, navigate])

  return (
    <Section>
      <MainTitle
        title={t('page.cashbox.title', { fullName: `${me?.firstname} ${me?.lastname}` })}
        disabled={tooManyCashboxes}
        showStoreSelect={true}
      />
      {userCashboxLoading ? (
        <Loader />
      ) : (
        <>
          {tooManyCashboxes ? (
            <CardLayout>
              <Title2>{t('page.cashbox.inProgess')}</Title2>
              <Text color="danger">{t('page.cashbox.tooManyCashboxes')}</Text>
              <CashboxesList>
                {userCashboxes?.map((cashbox, index) => (
                  <LinkButton route={RouteName.CashboxDetails} value={cashbox.id} key={`cashbox-${index}`}>
                    {cashbox.store.shortName}
                  </LinkButton>
                ))}
              </CashboxesList>
            </CardLayout>
          ) : (
            <CashboxCreateStep isActive={true} store={storeData} />
          )}
        </>
      )}
    </Section>
  )
}
