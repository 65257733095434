import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const InfoText = styled(Text)`
  font-size: 2.8rem;
  text-align: center;
`

export const StrokePrice = styled(Text)`
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.fontWeight.light};
  text-decoration-line: line-through;
  text-decoration-color: ${(props) => props.theme.textColors.danger};
  text-align: center;
  line-height: 2.5rem;
`

export const InfoCard = styled(CardLayout)`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
`

export const CardContainer = styled.div`
  display: flex;
  gap: 0 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    display: grid;
    grid-template-columns: auto 1fr;
    & > :nth-child(3) {
      grid-column: span 2;
    }
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    display: grid;
    grid-template-columns: 1fr;
    & > :nth-child(3) {
      grid-column: span 1;
    }
  }
`

export const InfoBlockContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 3rem;

  & > *:not(:last-child) {
    border-right: solid 1px ${(props) => props.theme.borderColors.secondary};
  }
  & > *:not(:first-child) {
    padding-left: 2rem;
  }

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    display: grid;
    grid-template-columns: 1fr;

    & > *:not(:last-child) {
      border-right: none;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: solid 1px ${(props) => props.theme.borderColors.secondary};
    }
    & > *:not(:first-child) {
      padding-left: 0;
    }
  }
`

export const PriceContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const DescriptionContainer = styled.div`
  display: flex;
  & > *:not(:first-child) {
    padding-left: 2rem;
  }
`

export const DescriptionBlock = styled.div`
  flex-basis: 100%;
`

export const PackBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 1.2rem;
  padding: 1rem;

  color: ${(props) => props.theme.textColors.white};
  background-color: ${(props) => props.theme.colors.blue3};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-transform: uppercase;

  @media screen and (${(props) => props.theme.screenWidth.maxLargeScreen}) and (${(props) =>
      props.theme.screenWidth.minLaptopScreen}) {
    padding: 0.5rem 1rem;
  }
`

export const PricesBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  gap: 3rem;
`

export const Img = styled.img`
  max-width: 20rem;
  max-height: 20rem;
`

export const ImgCard = styled(CardLayout)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  max-width: 22rem;
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    max-width: 100%;
  }
`

export const ModalButton = styled(Button)`
  align-self: center;
`
