import { FetchOrderPrintsQueryParams, useLastOrderPrintsUpToFifteenDaysAgo } from 'api/orderPrints'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import { PrintIcon } from 'constants/icons'
import Link from 'features/Permissions/Link/Link'
import { useEventEmitter } from 'hooks/useEventEmitter'
import { RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderPrint } from 'types/entities'
import { EventType } from 'types/events'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatDateAndHour } from 'utils/dates'
import { MarginButton, OrderGrid, StyledCell, StyledListing } from './CardsPreorderPrintForm.styles'

type Props = {
  active: boolean
  storeId: string | number
  hasCards?: boolean
}

const defaultSort: OrderParam<FetchOrderPrintsQueryParams> = {
  sortedBy: 'printedAt',
  direction: SortingDirection.Desc,
  queryParams: { 'order[printedAt]': SortingDirection.Desc }
}

function OrderPrintList({ storeId, active, hasCards }: Props) {
  const { t } = useTranslation()
  const [orderParams, setOrderParams] = useState<OrderParam<FetchOrderPrintsQueryParams>>(defaultSort)

  const {
    data: orderPrints,
    hasNextPage,
    fetchNextPage,
    isFetching,
    totalItems,
    isFetchingNextPage,
    refetch
  } = useLastOrderPrintsUpToFifteenDaysAgo(
    { store: `${storeId}`, hasCards, ...orderParams.queryParams },
    {
      enabled: active
    }
  )

  const { useListener } = useEventEmitter()

  useListener(EventType.PrintOrder, () => {
    if (active) refetch()
  })

  const orderPrintColumns: Array<Column<OrderPrint, FetchOrderPrintsQueryParams>> = [
    {
      name: t('common.label.date'),
      key: 'printedAt',
      sortable: true,
      orderQueryParam: 'order[printedAt]',
      decorator: (print) => (
        <StyledCell>
          <Text>{formatDateAndHour(print.printedAt)}</Text>
        </StyledCell>
      )
    },
    {
      name: t('page.order.print.details.printName'),
      key: 'type',
      sortable: true,
      orderQueryParam: 'order[type]',
      decorator: (print) => (
        <StyledCell>
          <Text>{print.type}</Text>
        </StyledCell>
      )
    },
    {
      name: t('page.order.print.details.printedOrdersNumbers'),
      key: 'orders',
      decorator: (print) => (
        <StyledCell>
          <OrderGrid>
            {print.orders?.split(',').map((order) => (
              <Link key={order} route={RouteName.OrderDetails} value={order}>
                {order}
              </Link>
            ))}
          </OrderGrid>
        </StyledCell>
      )
    },
    {
      // TODO: check printedBy typing (string -> should be Collaborator)
      name: t('common.label.collaborator'),
      key: 'printedBy',
      decorator: (print) => (
        <StyledCell>
          <Text>{[print.printedBy?.firstname, print.printedBy?.lastname].join(' ')}</Text>
        </StyledCell>
      )
    },
    {
      name: t('common.label.reprint'),
      key: 'downloadLink',
      decorator: (print) =>
        print.downloadLink ? (
          <a href={`${process.env.REACT_APP_API_URL}${print.downloadLink}`} target="_blank" rel="noopener noreferrer">
            <MarginButton
              variant={print.type?.match('Préco') ? 'warning' : 'default'}
              shape="circle"
              icon={PrintIcon}
              disabled={!print.downloadLink}
            />
          </a>
        ) : (
          <MarginButton shape="circle" icon={PrintIcon} disabled={true} />
        )
    }
  ]

  return (
    <StyledListing
      columns={orderPrintColumns}
      data={orderPrints}
      totalCount={totalItems}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      countText={t('page.order.print.details.orderPrintsCurrentCount', {
        current: orderPrints?.length,
        total: totalItems ?? ''
      })}
      emptyText={t('page.order.print.details.orderPrintsCount_zero')}
      onOrderChanged={setOrderParams}
      defaultSort={defaultSort}
    />
  )
}

export default OrderPrintList
