import { Props as ButtonProps } from 'components/Button/Button'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { ConfirmModalWrapper, DeleteModalButton, ModalButtonWrapper } from 'components/Modal/Modal.styles'
import { Title2 } from 'components/Title/Title.styles'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface DeleteModalProps extends ModalProps {
  title: string
  callback: () => void
  cancelLabel?: string | JSX.Element
  confirmLabel?: string | JSX.Element
  children?: ReactNode
  confirmBtnProps?: ButtonProps
  closeOnCallback?: boolean
}
export default function ConfirmModal({
  title,
  callback,
  onClose,
  open,
  cancelLabel,
  confirmLabel,
  children,
  confirmBtnProps,
  closeOnCallback = true
}: DeleteModalProps) {
  const { t } = useTranslation()

  const handleConfirm = () => {
    if (closeOnCallback) onClose()
    callback()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ConfirmModalWrapper>
        <Title2>{title}</Title2>
        {children}

        <ModalButtonWrapper>
          <DeleteModalButton variant="danger" onClick={handleConfirm} {...confirmBtnProps}>
            {confirmLabel ?? t('common.label.validate')}
          </DeleteModalButton>
          <DeleteModalButton variant="white" onClick={() => onClose()}>
            {cancelLabel ?? t('common.label.cancel')}
          </DeleteModalButton>
        </ModalButtonWrapper>
      </ConfirmModalWrapper>
    </Modal>
  )
}
