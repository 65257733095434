import { usePatchRoleAction } from 'api/roleAction'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import Cell from 'components/SortedTable/Cell'
import { ListActions } from 'components/SortedTable/SortedTable.styles'
import { Text } from 'components/Text/Text.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { DeleteIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useRoleByRoleAction } from 'hooks/entityHooks/permissionsHooks'
import _ from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Role, RoleAction as RoleActionEntity } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import AddRoleForm from '../form/AddRoleForm'
import { DeleteModalDescription } from '../PermissionManage.style'
interface Props {
  roleAction: RoleActionEntity
}
export default function RoleByAction({ roleAction }: Props) {
  const { t } = useTranslation()
  const [selectedRoles, setSelectedRoles] = useState<string[]>([])
  const { deletableRoles, canDeleteRoleRow } = useRoleByRoleAction(roleAction)
  const [deleteModalOpen, toggleDeleteModal] = useState(false)

  const { checkMatch } = useCheckMatch()

  const roleColumns: Columns<Role> = [
    {
      key: 'checkbox',
      decorator: (role) => (
        <Cell>
          <TooltipHover
            content={canDeleteRoleRow(role)?.message}
            id="cantDeleteRoleRowTooltip"
            container="tooltipContainer"
          >
            <Checkbox
              className={classNames({
                disabled: !deletableRoles.find((e) => e['@id'] === role['@id']),
                checked: selectedRoles.includes(role['@id'])
              })}
              onClick={() => setSelectedRoles((prev) => _.xor(prev, [role['@id']]))}
            />
          </TooltipHover>
        </Cell>
      )
    },
    {
      key: 'name',
      name: t('page.permissions.manage.labels.associatedRole'),
      decorator: (role) => <Cell>{role.name}</Cell>
    },
    {
      key: 'details',
      name: t('page.permissions.manage.labels.roleDetails'),
      decorator: (role) => (
        <Cell>
          <Text fontWeight="light" color="secondary">
            {role.description}
          </Text>
        </Cell>
      )
    }
  ].filter((col) => {
    if (col.key === 'checkbox')
      return checkMatch([RoleAction.ROLE_ACTION_PERMISSIONS_MANAGE_EDIT]) && checkMatch([roleAction.name!])
    return true
  })

  const { mutate, isLoading } = usePatchRoleAction(roleAction.id!, {
    callback: () => {
      setSelectedRoles([])
      toggleDeleteModal(false)
    }
  })

  const handleDelete = () => {
    mutate({
      collaboratorRoles: _.difference(
        roleAction.collaboratorRoles?.map((role) => role['@id']!),
        selectedRoles
      )
    })
  }

  return (
    <div>
      <ShouldHide
        permissions={[RoleAction.ROLE_ACTION_PERMISSIONS_MANAGE_EDIT]}
        deniedExtraCondition={!checkMatch([roleAction.name!])}
      >
        <>
          <AddRoleForm roleAction={roleAction} />
          {!!roleAction.collaboratorRoles?.length && (
            <ListActions>
              <Checkbox
                className={classNames({
                  checked: !!deletableRoles.length && deletableRoles.length === selectedRoles.length,
                  disabled: !deletableRoles?.length
                })}
                onClick={() =>
                  setSelectedRoles((prev) =>
                    prev.length === deletableRoles.length ? [] : deletableRoles.map((role) => role['@id']!)
                  )
                }
              />
              <TooltipHover
                id="deleteRoleTooltip"
                content={t('page.permissions.manage.roleAction.tooltips.deleteRole', {
                  roleName: roleAction.displayName
                })}
                container="tooltipContainer"
              >
                <Button
                  icon={DeleteIcon}
                  shape="circle"
                  variant="white"
                  disabled={!selectedRoles.length}
                  onClick={() => toggleDeleteModal(true)}
                />
              </TooltipHover>
            </ListActions>
          )}

          <ConfirmModal
            open={deleteModalOpen}
            onClose={() => toggleDeleteModal(false)}
            callback={handleDelete}
            closeOnCallback={false}
            title={t('page.permissions.manage.roleAction.deleteModal.title', { count: selectedRoles.length })}
            confirmBtnProps={{
              isLoading
            }}
            confirmLabel={t('common.button.delete')}
          >
            <DeleteModalDescription fontWeight="light" color="secondary">
              {t('page.permissions.manage.roleAction.deleteModal.decription', {
                count: selectedRoles.length,
                roleName: roleAction.displayName
              })}
            </DeleteModalDescription>
            <Listing
              columns={roleColumns.filter((col) => col.key !== 'checkbox')}
              data={_.sortBy(
                roleAction.collaboratorRoles?.filter((role) => selectedRoles.includes(role['@id']!)) ?? [],
                'name'
              )}
            />
          </ConfirmModal>
        </>
      </ShouldHide>

      <Listing
        columns={roleColumns}
        data={_.sortBy(roleAction.collaboratorRoles ?? [], 'name')}
        totalCount={roleAction.collaboratorRoles?.length}
      />
    </div>
  )
}
