import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import styled from 'styled-components'

export const Row = styled(RowContainer)`
  gap: 10rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`
