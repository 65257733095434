import RichTextDisplay from 'components/RichTextEditor/RichTextDisplay'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { Trans, useTranslation } from 'react-i18next'
import { StoreDetails } from 'types/entities'
import { FieldsWrapper, FormCard } from '../StoreSeoTab.style'

interface Props {
  store: StoreDetails
}

export default function StoreSellFormDisplay({ store }: Props) {
  const { t } = useTranslation()

  return (
    <FormCard>
      <Title2>{t('page.stores.details.storeSeo.sections.sellArea')}</Title2>
      <FieldsWrapper>
        <RichTextDisplay
          value={store.description}
          label={t('page.stores.details.storeSeo.labels.description')}
          tooltip={
            <Tooltip id="fieldTooltip">
              <Trans i18nKey="page.stores.details.storeSeo.tooltips.description" />
            </Tooltip>
          }
        />

        <RichTextDisplay value={store.descriptionEn} label={t('page.stores.details.storeSeo.labels.descriptionEn')} />
      </FieldsWrapper>
    </FormCard>
  )
}
