import { Section } from 'components/Section/Section.styles'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { PLAYIN_SUPPORT_EMAIL } from 'constants/configs'
import { useTranslation } from 'react-i18next'
import { StyledCardLayout, UnderlineText } from './Errors.styles'

function Error403() {
  const { t } = useTranslation()
  return (
    <Section>
      <MainTitle title={t('page.error403.title')} />

      <StyledCardLayout>
        <Text fontWeight="medium" color="danger">
          {t('page.error403.mainText')}
        </Text>
        <Text fontWeight="light" color="danger">
          {t('page.error403.text')}
          <a href={`mailto:${PLAYIN_SUPPORT_EMAIL}`}>
            <UnderlineText fontWeight="light" color="danger">
              {PLAYIN_SUPPORT_EMAIL}
            </UnderlineText>
          </a>
        </Text>
      </StyledCardLayout>
    </Section>
  )
}

export default Error403
