import {
  FetchProductCreationRequestsParams,
  rejectProductCreationRequest,
  usePaginatedProductCreationRequests,
  validateProductCreationRequest
} from 'api/productCreationRequests'
import { OldIcon } from 'app/ProductManagement/ProductManagement.style'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import { ListActions } from 'components/SortedTable/SortedTable.styles'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import { CheckIcon, CrossIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import ConfirmModal from 'features/Modals/ConfirmModal'
import LinkButton from 'features/Permissions/LinkButton'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useProductFamilies } from 'hooks/entityHooks/familyHooks'
import { useProductSheetStatusOptions } from 'hooks/entityHooks/productSheetsHook'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Colors } from 'theme/theme'
import {
  ProductCreationRequestJsonldProductCreationRequestRead,
  ProductCreationRequestStatus,
  RoleAction
} from 'types/playInApiInterfaces'
import { OrderParam, SortingDirection } from 'types/sorting'
import { formatDateAndHour } from 'utils/dates'
import { mergeFormQueryParams } from 'utils/queryParams'
import SearchSheetsForm, { SearchSheetsInputs } from './form/SearchRequestsForm'
import { CreateCardLayout, NameCell } from './ProductRequestsManage.style'

type ProductCreationRequest = ProductCreationRequestJsonldProductCreationRequestRead

const defaultSort: OrderParam<FetchProductCreationRequestsParams> = {
  direction: SortingDirection.Desc,
  sortedBy: 'createdAt',
  queryParams: { 'order[createdAt]': SortingDirection.Desc }
}

export default function ProductRequestsManage() {
  const { t } = useTranslation()
  const { getFamilyIcon, getFamilyName } = useProductFamilies()
  const { getSheetStateColor, getSheetStateIcon, getSheetStatusLabel } = useProductSheetStatusOptions()

  const [selectedSheets, setSelectedSheets] = useState<number[]>([])
  const [openRejectModal, toggleRejectModal] = useState(false)
  const [openValidatetModal, toggleValidateModal] = useState(false)
  const [searchParams, setSearchParams] = useState<FetchProductCreationRequestsParams>()
  const [sortParams, setSortParams] = useState<OrderParam<FetchProductCreationRequestsParams>>(defaultSort)
  const defaultQueryParams: FetchProductCreationRequestsParams = {
    status: ProductCreationRequestStatus.Created
  }

  const queryParams = mergeFormQueryParams<FetchProductCreationRequestsParams>({
    qp: searchParams ?? defaultQueryParams,
    orderParams: sortParams.queryParams
  })
  const {
    data: productSheets,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalItems,
    isLoading
  } = usePaginatedProductCreationRequests(['productSheets', queryParams], queryParams)

  const { checkMatch } = useCheckMatch()

  const columns = _.filter<Column<ProductCreationRequest, FetchProductCreationRequestsParams>>(
    [
      {
        key: 'checkbox',
        decorator: (productSheet) => (
          <Cell>
            <Checkbox
              className={classNames({
                checked: selectedSheets.includes(productSheet.id!),
                disabled: productSheet.status !== ProductCreationRequestStatus.Created
              })}
              onClick={() => setSelectedSheets((prev) => _.xor(prev, [productSheet.id!]))}
            />
          </Cell>
        )
      },
      {
        key: 'name',
        name: t('common.label.productName'),
        sortable: true,
        orderQueryParam: 'order[name]',
        decorator: (productSheet) => (
          <NameCell>
            {productSheet.comment && <Tooltip id={`commentooltip-${productSheet.id}`}>{productSheet.comment}</Tooltip>}
            <Text fontWeight="medium">{productSheet.name}</Text>
          </NameCell>
        )
      },
      {
        key: 'barcode',
        name: t('common.label.barcode'),
        decorator: (productSheet) => <Cell>{productSheet.barcode}</Cell>
      },
      {
        key: 'family',
        name: t('common.label.range'),
        decorator: (productSheet) => {
          const familyIcon = getFamilyIcon(productSheet.family?.codeName)
          const familyName = getFamilyName(productSheet.family?.codeName)
          return (
            <Cell>
              <TooltipHover id={`familiyTooltip-${productSheet.family}`} content={familyName}>
                {familyIcon && <Svg svg={familyIcon} size="2rem" />}
              </TooltipHover>
            </Cell>
          )
        }
      },
      {
        key: 'bookLaw',
        name: t('common.label.bookLaw'),
        decorator: (productSheet) => {
          return (
            <Cell>
              {productSheet.bookLaw ? (
                <TooltipHover
                  id={`bookLaw-${productSheet.id}`}
                  content={<Text>{t('common.label.bookLawSubjected')}</Text>}
                >
                  <OldIcon svg={CheckIcon} color={'success'} size="2rem" />
                </TooltipHover>
              ) : (
                <TooltipHover
                  id={`bookLaw-${productSheet.id}`}
                  content={<Text>{t('common.label.bookLawSubjected')}</Text>}
                >
                  <OldIcon svg={CrossIcon} color="danger" size="2rem" />
                </TooltipHover>
              )}
            </Cell>
          )
        }
      },
      {
        key: 'vat',
        name: t('common.label.tva'),
        decorator: (productSheet) => (
          <Cell>
            <TextNumber value={productSheet.tax?.rate} suffix={`\u00a0%`} decimalScale={2} />
          </Cell>
        )
      },

      {
        key: 'sellPrice',
        name: t('common.label.sellPrice'),
        decorator: (productSheet) => (
          <Cell>
            <TextNumber value={productSheet.sellPrice} suffix={`\u00a0€`} decimalScale={2} />
          </Cell>
        )
      },
      {
        key: 'createdAt',
        name: t('page.productRequest.manage.labels.createdAt'),
        sortable: true,
        orderQueryParam: 'order[createdAt]',
        decorator: (productSheet) => (
          <Cell>
            <Text fontStyle="italic">{formatDateAndHour(productSheet.createdAt)}</Text>
          </Cell>
        )
      },
      {
        key: 'collaborator',
        name: t('common.label.collaborator'),
        decorator: (productSheet) => (
          <Cell>
            <Text fontStyle="italic">{`${productSheet.createdBy?.firstname} ${productSheet.createdBy?.lastname}`}</Text>
          </Cell>
        )
      },
      {
        key: 'status',
        name: t('page.productRequest.manage.labels.state'),
        decorator: (productSheet) => (
          <Cell>
            <TooltipHover
              id={`statusTooltip-${productSheet.family}`}
              content={getSheetStatusLabel(productSheet.status!)}
            >
              <OldIcon
                svg={getSheetStateIcon(productSheet.status!)}
                color={getSheetStateColor(productSheet.status!) as keyof Colors}
              />
            </TooltipHover>
          </Cell>
        )
      }
    ],
    (col) => {
      if (col.key === 'checkbox') return checkMatch([RoleAction.ROLE_ACTION_PRODUCT_CREATE])
      return true
    }
  )

  const onSubmit = (formData: SearchSheetsInputs) => {
    const { status, ...restData } = formData
    setSearchParams({
      status: status || undefined,
      ...restData
    })
  }

  const queryClient = useQueryClient()

  const { mutateAsync: validateMutation, isLoading: isValidateLoading } = useMutation(
    (id: number) => validateProductCreationRequest(id),
    {
      onSuccess: () => queryClient.refetchQueries(['productSheets', queryParams])
    }
  )
  const { mutateAsync: rejectMutation, isLoading: isRejectLoading } = useMutation(
    (id: number) => rejectProductCreationRequest(id),
    {
      onSuccess: () => queryClient.refetchQueries(['productSheets', queryParams])
    }
  )

  const handleValidate = async () => {
    for (let creationRequest of selectedSheets) {
      await validateMutation(creationRequest)
    }
    toggleValidateModal(false)
    setSelectedSheets([])
  }

  const handleReject = async () => {
    for (let creationRequest of selectedSheets) {
      await rejectMutation(creationRequest)
    }
    toggleRejectModal(false)
  }

  const selectableSheets = productSheets.filter((e) => e.status === ProductCreationRequestStatus.Created)
  const allSheetsSelected = !!selectableSheets.length && selectableSheets.length === selectedSheets.length

  return (
    <>
      <Section>
        <MainTitle title={t('page.productRequest.manage.title')} />
        <CreateCardLayout>
          <Title2>{t('page.productRequest.manage.createTitle')}</Title2>
          <LinkButton size="app" route={RouteName.ProductSheetRequest}>
            {t('page.productRequest.manage.createBtn')}
          </LinkButton>
        </CreateCardLayout>

        <SearchSheetsForm onSubmit={onSubmit} isLoading={isLoading} />
        <CardLayout>
          <Listing
            columns={columns}
            data={productSheets}
            defaultSort={defaultSort}
            onOrderChanged={setSortParams}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetching={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            totalCount={totalItems}
            title={
              searchParams
                ? t('page.productRequest.manage.listing.searchTitle', { count: totalItems ?? 0 })
                : t('page.productRequest.manage.listing.title', { count: totalItems ?? 0 })
            }
            countText={t('page.productRequest.manage.countText', { current: productSheets.length, total: totalItems })}
            emptyText={t('page.productRequest.manage.empty')}
            topComponent={
              <ShouldHide permissions={[RoleAction.ROLE_ACTION_PRODUCT_CREATE]}>
                <ListActions>
                  <Checkbox
                    className={allSheetsSelected ? 'checked' : ''}
                    onClick={() => setSelectedSheets(allSheetsSelected ? [] : selectableSheets.map((e) => e.id!))}
                  />
                  <TooltipHover id="validateSheets" content={t('page.productRequest.manage.tooltips.validateSheets')}>
                    <Button
                      shape="circle"
                      variant="white"
                      icon={CheckIcon}
                      onClick={() => toggleValidateModal(true)}
                      disabled={!selectedSheets.length}
                    />
                  </TooltipHover>

                  <TooltipHover id="rejectSheets" content={t('page.productRequest.manage.tooltips.rejectSheets')}>
                    <Button
                      shape="circle"
                      variant="white"
                      icon={CrossIcon}
                      onClick={() => toggleRejectModal(true)}
                      disabled={!selectedSheets.length}
                    />
                  </TooltipHover>
                </ListActions>
              </ShouldHide>
            }
          />
        </CardLayout>
      </Section>
      <ShouldHide permissions={[RoleAction.ROLE_ACTION_PRODUCT_CREATE]}>
        <ConfirmModal
          callback={handleReject}
          open={openRejectModal}
          onClose={() => toggleRejectModal(false)}
          title={t('page.productRequest.manage.rejectModals.title')}
          confirmLabel={t('page.productRequest.manage.rejectModals.btn')}
          closeOnCallback={false}
          confirmBtnProps={{
            isLoading: isRejectLoading
          }}
        >
          <Listing
            columns={columns.filter((col) => col.key !== 'checkbox')}
            data={productSheets.filter((e) => selectedSheets.includes(e.id!))}
          />
        </ConfirmModal>
        <ConfirmModal
          callback={handleValidate}
          open={openValidatetModal}
          onClose={() => toggleValidateModal(false)}
          title={t('page.productRequest.manage.validateModal.title')}
          confirmBtnProps={{
            variant: 'warning',
            isLoading: isValidateLoading
          }}
          closeOnCallback={false}
        >
          <Listing
            columns={columns.filter((col) => col.key !== 'checkbox')}
            data={productSheets.filter((e) => selectedSheets.includes(e.id!))}
          />
        </ConfirmModal>
      </ShouldHide>
    </>
  )
}
