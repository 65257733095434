import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

export type SendEmailBody = Api.PostSendEmail.RequestBody
type SendEmailResponse = Api.PostSendEmail.ResponseBody
export async function sendEmail(body: SendEmailBody, id: string | number) {
  const res = await playInApi.post<SendEmailBody, AxiosResponse<SendEmailResponse>>(
    `${API_ROUTES.orders.email}/${id}`,
    body
  )
  return res.data
}
