import { styled as muiStyled } from '@mui/material/styles'
import MuiTab from '@mui/material/Tab'
import styled from 'styled-components'

export const StyledTabs = styled.div`
  display: flex;
  border-bottom: 5px solid #190f2c;
  overflow-x: auto;
`

export const Tab = muiStyled(MuiTab)(({ theme }) => ({
  maxWidth: 250,
  minWidth: 200,
  flexGrow: 1,
  fontSize: 'unset',
  textTransform: 'none',
  color: 'black',
  opacity: 1,
  '&.selected': {
    fontWeight: 700,
    backgroundColor: 'white',
    borderRadius: '2px 2px 0px 0px',
    boxShadow: '6px 0px 10px #c4c4c4',
    border: '1px solid #ececec'
  },
  '&.disabled': {
    pointerEvents: 'none',
    color: '#c4c4c4'
  },
  '&.solo': {
    pointerEvents: 'none'
  }
}))

export const PanelContainer = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  padding: 2rem;
`
