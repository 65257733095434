import { createCashbox } from 'api/cashbox'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { DEFAULT_CASHBOX_VALUE } from 'constants/configs'
import { useMutation } from 'hooks/useAxiosMutation'
import { isNil } from 'lodash'
import { getPath, RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { Cashbox, StoreItem } from 'types/entities'
import { formatCalendarDate } from 'utils/dates'
import CashboxDetailForm, { FormData } from '../form/CashboxDetailForm'
import { ValidationButton } from '../form/CashboxDetailsForm.style'
import { getCashboxCount, getDifferenceColor } from '../utils/cashHelper'
import { ActiveStepLayout, DifferenceText, SectionBottom, TotalText } from './CashboxStep.style'

interface Props {
  isActive: boolean
  store?: StoreItem
  cashbox?: Cashbox
}
export default function CashboxCreateStep({ isActive, store, cashbox }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [totalCount, setTotalCount] = useState<number | undefined>()
  const [expectedDifference, setExpectedDifference] = useState<number>(0)

  const defaultCashboxValue = store?.defaultCashboxValue ?? DEFAULT_CASHBOX_VALUE

  const handleCount = (formData: FormData) => {
    let totalCount = getCashboxCount(formData)
    setTotalCount(totalCount)
    setExpectedDifference(totalCount - defaultCashboxValue)
  }
  const queryClient = useQueryClient()

  const { mutate: createCashboxMutation, isLoading } = useMutation(createCashbox, {
    onSuccess: (data) => {
      queryClient.setQueryData(['cashbox', `${data.id}`], data)
      navigate(getPath(RouteName.CashboxDetails).replace(':id', `${data.id}`), {
        replace: true,
        state: { fromCreate: true }
      })
    }
  })

  const handleCreate = () => {
    createCashboxMutation({
      cashFundStartCalculated: totalCount,
      store: store!['@id']!
    })
  }

  return isActive ? (
    <ActiveStepLayout>
      <CardLayout>
        <Title2>{t('page.cashbox.createStep.formTitle')}</Title2>
        <CashboxDetailForm onSubmit={handleCount} tooltipText={t('page.cashbox.createStep.tooltip')} />
      </CardLayout>
      <CardLayout>
        <Title2>{t('page.cashbox.createStep.countTitle')}</Title2>

        <TextNumber
          value={totalCount}
          suffix="€"
          placeholder={t('common.label.notAvailable')}
          color={totalCount ? 'black' : 'secondary2'}
          fontWeight={totalCount ? 'light' : 'regular'}
          ContainerComponent={TotalText}
          decimalScale={2}
        />

        <TextNumber
          value={expectedDifference}
          prefix={expectedDifference > 0 ? '+' : ''}
          suffix="€"
          label={t('page.cashbox.expectedDifference')}
          color={getDifferenceColor(expectedDifference)}
          fontWeight={expectedDifference === 0 ? 'light' : 'regular'}
          ContainerComponent={DifferenceText}
          decimalScale={2}
        />

        <SectionBottom border={true}>
          <Text color="secondary" fontWeight="light">
            {t('page.cashbox.createStep.information')}
          </Text>
          <ValidationButton
            isLoading={isLoading}
            disabled={isNil(totalCount) || store === undefined}
            onClick={handleCreate}
          >
            {t('page.cashbox.createStep.validate')}
          </ValidationButton>
        </SectionBottom>
      </CardLayout>
    </ActiveStepLayout>
  ) : (
    <>
      {cashbox && (
        <CardLayout>
          <Title2>{t('page.cashbox.createStep.date', { date: formatCalendarDate(cashbox?.createdAt) })}</Title2>

          <TextNumber
            size="2.8rem"
            color="black"
            fontWeight="light"
            value={cashbox?.cashFundStartCalculated}
            suffix="€"
            decimalScale={2}
          />
        </CardLayout>
      )}
    </>
  )
}
