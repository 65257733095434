import styled from 'styled-components'

export const BillCouponPrintActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    flex-direction: row;
    align-items: flex-end;
    gap: 2.5rem;
  }
`
