import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'

export const StyledCardLayout = styled(CardLayout)`
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`
export const UnderlineText = styled(Text)`
  text-decoration: underline;
  margin-left: 0.5rem;
  display: inline-block;
`
