import classNames from 'classnames'
import { useMousePosition } from 'hooks/useMousePosition'
import { useRef } from 'react'
import { FloatingContainer } from './FollowMouseContent.styles'

type Props = {
  mouseOffsetX?: number
  mouseOffsetY?: number
  windowOffset?: number
  show: boolean
}

const FollowMouseContent: React.FunctionComponent<Props> = ({
  mouseOffsetX = 20,
  mouseOffsetY = 20,
  windowOffset = 10,
  children,
  show
}) => {
  const { mousePosX, mousePosY } = useMousePosition()

  const ref = useRef<HTMLImageElement>(null)

  const maxFollowPosX = window.innerWidth + window.scrollX - (ref?.current?.offsetWidth ?? 0) - windowOffset
  const maxFollowPosY = window.innerHeight + window.scrollY - (ref?.current?.offsetHeight ?? 0) - windowOffset

  return (
    <FloatingContainer
      className={classNames({
        show,
        switch: mousePosX > maxFollowPosX
      })}
      style={{
        top: Math.min(mousePosY + mouseOffsetY, maxFollowPosY),
        left: Math.min(mousePosX + mouseOffsetX, maxFollowPosX)
      }}
    >
      <div ref={ref}>{children}</div>
    </FloatingContainer>
  )
}

export default FollowMouseContent
