import Button from 'components/Button/Button'
import { InputContainer } from 'components/Layouts/InputLayout/InputLayout.styles'
import Svg from 'components/Svg/Svg'
import styled from 'styled-components'
import { TextColors } from 'theme/theme'

export const FilterGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem 5rem;

  margin-top: 2rem;

  ${InputContainer} {
    min-width: 15rem;
    max-width: 25rem;
  }

  @media screen and (${(props) => props.theme.screenWidth.maxLargeScreen}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 100%;
  }
`

export const RoundIcon = styled(Svg)<{ background: keyof TextColors }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.textColors[props.background]};
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;

  & svg {
    path {
      fill: white !important;
    }
    width: 1rem;
    height: 1rem;
  }
`

export const TwoLinesCell = styled.div`
  display: flex;
  flex-direction: column;
`

export const FieldCol = styled.div`
  display: grid;
  gap: 1rem 5rem;
  grid-template-columns: 1fr;
`
export const DateContainer = styled(FieldCol)`
  @media screen and (${(props) => props.theme.screenWidth.minDesktopScreen}) {
    grid-column: span 2;
    grid-template-columns: 1fr 1fr;
  }
`

export const SearchButton = styled(Button)`
  align-self: flex-end;
`
