import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputFile from 'components/InputFile/InputFile'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { ColumnContainer } from 'components/Layouts/InputLayout/InputLayout.styles'
import { Text } from 'components/Text/Text.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { CreateIcon, SaveIcon } from 'constants/icons'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Form, Image as ImageComponent, Row } from './BannerCreate.styles'

const schema = yup.object({
  title: yup.string().trim().required(),
  urlLink: yup.string().trim().required(),
  lang: yup.string().trim().required(),
  img: yup
    .mixed()
    .required()
    .test('fileDimension', (value) => {
      return value?.width === 1280 && value?.height === 300
    }),
  imgLarge: yup
    .mixed()
    .nullable(true)
    .test('fileDimension', (value) => {
      // imgLarge is not required, but if defined it must respect dimension
      return !value ? true : value?.width === 1280 && value?.height === 500
    })
})

export interface FormData extends yup.InferType<typeof schema> {}

type Props = {
  defaultValues: FormData
  onSubmit: (formData: FormData) => void
  mode: 'create' | 'edit'
}

export const BannerForm = ({ defaultValues, onSubmit, mode }: Props) => {
  const { t } = useTranslation()

  const [img, setImg] = useState<string | null>(null)
  const [imgLarge, setImgLarge] = useState<string | null>(null)

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<FormData>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues
  })

  return (
    <CardLayout>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Input
            id="title"
            register={register}
            label={`${t('common.label.title')} *`}
            className={classnames({ 'is-invalid': errors.title })}
          />
          <Input
            id="urlLink"
            register={register}
            label={`${t('common.label.link')} *`}
            className={classnames({ 'is-invalid': errors.urlLink })}
          />
        </Row>

        <InputRadio
          id="lang"
          control={control}
          label={t('common.label.lang')}
          labelAccept={t('common.languages.french')}
          acceptValue="fr"
          labelDecline={t('common.languages.english')}
          declineValue="en"
        />

        <Row>
          <ColumnContainer>
            <InputFile
              label={t('page.banner.create.images.classic.label')}
              handleFile={(file) => {
                setImg(URL.createObjectURL(file))
                setValue('img', URL.createObjectURL(file))
              }}
              tooltip={
                <Tooltip id="bannerCreateImg" width="40rem">
                  <Trans
                    i18nKey="page.banner.create.images.classic.tooltip"
                    components={{
                      br: <br />
                    }}
                  />
                </Tooltip>
              }
            />
            <BasicFieldValidationError
              error={errors.img}
              messages={{
                required: t('form.validation.required.image'),
                fileDimension: t('form.validation.image.format')
              }}
            />
            {img && <ImageComponent src={img} />}
          </ColumnContainer>
          <ColumnContainer>
            <InputFile
              label={t('page.banner.create.images.large.label')}
              handleFile={(file) => {
                setImgLarge(URL.createObjectURL(file))
                setValue('imgLarge', URL.createObjectURL(file))
              }}
              tooltip={
                <Tooltip id="bannerCreateLargeImg">
                  <Text>{t('page.banner.create.images.large.tooltip')}</Text>
                </Tooltip>
              }
            />
            <BasicFieldValidationError
              error={errors.imgLarge}
              messages={{
                fileDimension: t('form.validation.required.image')
              }}
            />
            {imgLarge && <ImageComponent src={imgLarge} />}
          </ColumnContainer>
        </Row>

        {mode === 'create' && (
          <Button buttonType="submit" icon={CreateIcon}>
            {t('common.button.create')}
          </Button>
        )}

        {mode === 'edit' && (
          <Button buttonType="submit" icon={SaveIcon}>
            {t('common.button.save')}
          </Button>
        )}
      </Form>
    </CardLayout>
  )
}

export default BannerForm
