import { useMutateCollaborator } from 'api/collaborators'
import Button from 'components/Button/Button'
import MultipleSelect from 'components/Select/MultipleSelect'
import { MoreIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Collaborator } from 'types/entities'
import { StoreFormLayout } from '../CollaboratorDetails.style'
import { useCollaboratorStoresOptions } from '../utils'

interface Props {
  collaborator: Collaborator
}

interface AddStoreInputs {
  stores: string[]
}
export default function AddStoreForm({ collaborator }: Props) {
  const { t } = useTranslation()

  const { mainStore } = collaborator

  const { handleSubmit, control, reset } = useForm<AddStoreInputs>()

  const { mutate, isLoading } = useMutateCollaborator(collaborator.id!, () => reset())
  const { addableStoresOptions, deletableStoresIds } = useCollaboratorStoresOptions(collaborator)

  const onSubmit = (formData: AddStoreInputs) => {
    //We need to send all deletableStores, with selected stores and mainStore
    mutate({
      stores: [...deletableStoresIds, ...formData.stores, mainStore?.['@id']!]
    })
    reset()
  }

  return (
    <StoreFormLayout onSubmit={handleSubmit(onSubmit)}>
      <MultipleSelect
        id="stores"
        options={addableStoresOptions}
        control={control}
        label={t('page.collaborator.details.stores.labels.addStore')}
        placeholder={t('common.select.defaultOptions.store')}
        disabled={!addableStoresOptions.length}
      />
      <Button icon={MoreIcon} shape="circle" iconColor="white" buttonType="submit" isLoading={isLoading} />
    </StoreFormLayout>
  )
}
