import { StyledText as ErrorText } from 'components/FormValidationErrors/FormValidationErrors.styles'
import InputFile from 'components/InputFile/InputFile'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import Papa from 'papaparse'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { CardTitleContainer } from '../StoreLabels.styles'
import { ProductLabel } from '../storeLabelUtils'
type Props = {
  handleData: React.Dispatch<React.SetStateAction<ProductLabel[]>>
}

const CsvFileUploader = ({ handleData }: Props) => {
  const { t } = useTranslation()
  const [fileTypeError, setFileTypeError] = useState(false)

  const handleCsvFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = (evt) => {
      const { data } = Papa.parse<ProductLabel>(evt.target?.result?.toString()!, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header, index) => {
          switch (index) {
            case 0:
              return 'id'
            case 1:
              return 'product'
            case 2:
              return 'title'
            case 3:
              return 'price'
            case 4:
              return 'discountPrice'
            case 5:
              return 'discountPercent'
            case 6:
              return 'format'
            case 7:
              return 'arrow'
          }

          return ''
        },
        transform: (value, column) => {
          switch (column) {
            case 'id':
              // rewrite product id to have duplicated labels
              return uuid()
            case 'price':
            case 'discountPrice':
            case 'discountPercent':
              return value ? parseFloat(value.replace(',', '.')) || 0 : undefined
          }

          return value
        }
      })
      handleData((prevLabels) => [...prevLabels, ...data])
    }

    const isCSV = file.type === 'text/csv'
    if (isCSV) {
      reader.readAsText(file)
    }
    setFileTypeError(!isCSV)
  }

  return (
    <CardLayout>
      <CardTitleContainer>
        <Title2>{t('page.store.labels.upload.title')}</Title2>
        <MultiLineTooltip
          id="importCSVTooltip"
          lines={[t('page.store.labels.upload.tooltip.line1'), t('page.store.labels.upload.tooltip.line2')]}
        />
      </CardTitleContainer>
      <InputFile label={t('page.store.labels.upload.label')} handleFile={handleCsvFile} accept=".csv" />
      {fileTypeError && <ErrorText color="danger">{t('form.validation.format.files.csv')}</ErrorText>}
    </CardLayout>
  )
}

export default CsvFileUploader
