import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker/DatePicker'
import Modal from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import TimePicker from 'components/TimePicker/TimePicker'
import { CrossIcon, EditIcon, SaveIcon } from 'constants/icons'
import { formatISO, isAfter } from 'date-fns'
import { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Ranges } from 'types/entities'
import { hourFormat } from 'utils/dates'
import * as yup from 'yup'
import { Banner } from './BannerManagement'
import { CenteredButton } from './BannerManagement.styles'
import { combineDate, getDateText, getFamilyUrlByName } from './bannerUtils'
import {
  CardContainer,
  DateLabel,
  FormLayout,
  FormWrapper,
  ImageCardActions,
  ImageContainer,
  ModalImage,
  ModalTitle,
  ModalWrapper
} from './ImageCard.styles'

interface Props {
  handleDelete: (index: number) => void
  selectedFamily: Ranges
  index: number
  handleDate: (index: number, data: { startDate?: string; endDate?: string }) => void
  banner: Banner
}

const schema = yup.object({
  startAt: yup.string(),
  startAtHour: yup.string(),
  endAt: yup
    .string()
    .test('isEndAfter', 'End date need to take place after start date', (value, context) =>
      !!value
        ? isAfter(
            combineDate(value, context.parent.endAtHour),
            combineDate(context.parent.startAt, context.parent.startAtHour)
          )
        : true
    ),
  endAtHour: yup
    .string()
    .test('isEndAfter', 'End date need to take place after start date', (value, context) =>
      !!value
        ? isAfter(
            combineDate(context.parent.endAt, value),
            combineDate(context.parent.startAt, context.parent.startAtHour)
          )
        : true
    )
})

export interface BannerItemInputs {
  startAt?: string
  startAtHour?: string
  endAt?: string
  endAtHour?: string
}

const getDefaultValues = (banner: Banner): BannerItemInputs => ({
  startAt: banner.startDate,
  startAtHour: hourFormat(banner.startDate),
  endAt: banner.endDate,
  endAtHour: hourFormat(banner.endDate)
})
const ImageCard = ({ banner, index, handleDelete, handleDate, selectedFamily }: Props) => {
  const [openModal, toggleModal] = useState<boolean>(false)
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    register,
    setValue,
    trigger,
    formState: { errors },
    reset
  } = useForm<BannerItemInputs>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(banner)
  })

  const onSubmit = (formData: BannerItemInputs) => {
    handleDate(index, {
      startDate: formatISO(combineDate(formData.startAt, formData.startAtHour || '00:00')),
      endDate: formatISO(combineDate(formData.endAt, formData.endAtHour || '23:59:59'))
    })
    toggleModal(false)
  }

  const { dateText, dateColor } = getDateText(banner.startDate, banner.endDate)

  useEffect(() => reset(getDefaultValues(banner)), [reset, banner])

  return (
    <>
      <Draggable draggableId={`${banner.id}-${index}`} index={index}>
        {(provided) => (
          <CardContainer ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <ImageContainer backgroundImage={banner.src} />
            <div>
              <Text color={dateColor}>{dateText}</Text>
            </div>
            <ImageCardActions>
              <Button shape="circle" variant="white" icon={EditIcon} onClick={() => toggleModal(true)} />
              <Button shape="circle" variant="white" icon={CrossIcon} onClick={() => handleDelete(index)} />
            </ImageCardActions>
          </CardContainer>
        )}
      </Draggable>

      <Modal
        open={openModal}
        onClose={() => {
          toggleModal(false)
          reset()
        }}
      >
        <ModalTitle>
          {t('page.banner.management.modals.title', { url: getFamilyUrlByName(selectedFamily.name) })}
        </ModalTitle>

        <ModalWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormWrapper>
              <ModalImage src={banner.src} />
              <FormLayout>
                <DatePicker
                  register={register}
                  id="startAt"
                  setValue={setValue}
                  trigger={trigger}
                  defaultValue={banner.startDate || ''}
                  label={
                    <DateLabel>
                      <Trans i18nKey="page.banner.management.labels.startAt" components={{ span: <span /> }} />
                    </DateLabel>
                  }
                />
                <TimePicker control={control} id="startAtHour" label={t('page.banner.management.labels.to')} />
                <DatePicker
                  register={register}
                  id="endAt"
                  setValue={setValue}
                  trigger={trigger}
                  defaultValue={banner.endDate || ''}
                  className={classNames({
                    'is-invalid': errors.endAt
                  })}
                  label={
                    <DateLabel>
                      <Trans i18nKey="page.banner.management.labels.endAt" components={{ span: <span /> }} />
                    </DateLabel>
                  }
                />
                <TimePicker
                  control={control}
                  id="endAtHour"
                  className={classNames({
                    'is-invalid': errors.endAtHour
                  })}
                  label={t('page.banner.management.labels.to')}
                />
              </FormLayout>
              <CenteredButton icon={SaveIcon} buttonType="submit">
                {t('common.button.save')}
              </CenteredButton>
            </FormWrapper>
          </form>
        </ModalWrapper>
      </Modal>
    </>
  )
}
export default ImageCard
