import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { useMutation } from 'hooks/useAxiosMutation'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey, useQueryClient } from 'react-query'
import { RoleAction } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

/* -------------------------------------------------------------------------- */
/*                                    FETCH                                   */
/* -------------------------------------------------------------------------- */

export const roleActionByIdQueryKey = (id?: string | number) => ['roleAction', `${id}`]

type FetchRoleActionsParams = Api.GetCollaboratorRoleActions.RequestQuery
type FetchRoleActionsResponse = Api.GetCollaboratorRoleActions.ResponseBody

const getRoleActionUri = (params: FetchRoleActionsParams = {}): string => {
  const qs = stringify(params)
  return [API_ROUTES.roleActions.root, qs].join('?')
}
export const fetchRoleActionById = async (id: number | string) => {
  const res = await playInApi.get<FetchRoleActionsResponse['hydra:member'][0]>(`${API_ROUTES.roleActions.root}/${id}`)
  return res.data
}
export const usePaginatedRoleActions = (
  queryKey: QueryKey,
  params: FetchRoleActionsParams,
  options?: PaginatedHookOptions<RoleAction>
) => usePaginatedQuery<RoleAction>(queryKey, getRoleActionUri(params), options)

/* -------------------------------------------------------------------------- */
/*                                    PATCH                                   */
/* -------------------------------------------------------------------------- */

type PatchRoleActionParams = Api.PatchCollaboratorRoleActionsId.RequestBody
const patchRoleAction = async (id: string | number, body: PatchRoleActionParams) => {
  const res = await playInApi.patch<PatchRoleActionParams, AxiosResponse<RoleAction>>(
    `${API_ROUTES.roleActions.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

interface UsePatchRoleActionOptions {
  callback?: () => void
}
export const usePatchRoleAction = (id: string | number, options: UsePatchRoleActionOptions = {}) => {
  const queryClient = useQueryClient()
  const { callback } = options
  return useMutation((body: PatchRoleActionParams) => patchRoleAction(id, body), {
    onSuccess: (updatedRole) => {
      queryClient.setQueryData(roleActionByIdQueryKey(id), updatedRole)
      if (callback) callback()
    }
  })
}
