import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import styled from 'styled-components'
export const ExportButtonsCardLayout = styled(CardLayout)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  & > div {
    padding-right: 5rem;

    &:not(:first-of-type) {
      border-left: 1px solid ${(props) => props.theme.borderColors.secondary};
      padding-left: 5rem;
    }
  }

  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
    & > div {
      padding-right: 0;

      &:not(:first-of-type) {
        border-left: none;
        padding-left: 0;
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: 1px solid ${(props) => props.theme.borderColors.secondary};
      }
    }
  }
`
export const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
export const FieldsCol = styled.div`
  display: grid;
  gap: 2rem;
`
export const FieldsColWithBorder = styled(FieldsCol)`
  padding: 0 5rem;
  border-left: 1px solid ${(props) => props.theme.borderColors.secondary};
  border-right: 1px solid ${(props) => props.theme.borderColors.secondary};
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    border-right: none;
    padding-right: 0;
    padding-left: 2rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    border-left: none;
    padding-left: 0;
  }
`

export const ProductWrapper = styled.div`
  display: grid;
  gap: 2rem;
  padding-top: 2rem;
`
export const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`
export const ProductCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const WarehouseFieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 25rem 45rem 25rem;
  gap: 3.5rem;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const PreorderLayoutForm = styled.form`
  display: grid;
  gap: 2rem;
  padding-top: 2rem;
`
