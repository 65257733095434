import { StyledText } from './FormValidationErrors.styles'

type Error = {
  type: string
  message?: string
}
interface Props {
  className?: string
  error?: Error
  message?: string
  messages?: Object
}

const getErrorLabel = (error: Error, messages: Object) => messages[error.type] ?? error.message
const BasicFieldValidationError = ({ className, error, message, messages }: Props) => {
  return (
    <>
      {error && (
        <StyledText className={className} color="danger">
          {messages ? getErrorLabel(error, messages) : message}
        </StyledText>
      )}
    </>
  )
}

export default BasicFieldValidationError
