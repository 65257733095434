import { ReactNode, useState } from 'react'
import { StyledPopover, TooltipAutoContainer, TooltipContainer } from '../Tooltip/Tooltip.styles'

interface HoverProps {
  content?: ReactNode
  children: ReactNode
  id: string
  container?: 'auto' | 'tooltipContainer'
}

export function TooltipHover({ id, children, content, container = 'auto' }: HoverProps) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleHover = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const tooltipId = open ? id : undefined
  return content ? (
    <>
      <div onMouseEnter={handleHover} onMouseLeave={handleClose}>
        {children}
      </div>
      <StyledPopover
        id={tooltipId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          pointerEvents: 'none'
        }}
        disableRestoreFocus
      >
        <>
          {container === 'auto' && <TooltipAutoContainer>{content}</TooltipAutoContainer>}
          {container === 'tooltipContainer' && <TooltipContainer>{content}</TooltipContainer>}
        </>
      </StyledPopover>
    </>
  ) : (
    <>{children}</>
  )
}
