import Button from 'components/Button/Button'
import MultipleSelect from 'components/Select/MultipleSelect'
import { Option } from 'components/Select/Select'
import { MoreIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AddFormLayout } from '../PermissionManage.style'

export interface AddCollaboratorInputs {
  collaborators: string[]
}

interface Props {
  onSubmit: (formData: AddCollaboratorInputs) => Promise<void>
  availableCollaboratorsOptions: Option[]
}
export default function AddCollaboratorForm({ availableCollaboratorsOptions, onSubmit }: Props) {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting }
  } = useForm<AddCollaboratorInputs>()

  return (
    <AddFormLayout
      onSubmit={handleSubmit(async (formData) => {
        await onSubmit(formData)
        reset()
      })}
    >
      <MultipleSelect
        options={availableCollaboratorsOptions}
        control={control}
        id="collaborators"
        label={t('page.permissions.manage.labels.addCollaborator')}
        placeholder={t('page.permissions.manage.labels.placeholder.addCollaborator')}
        disabled={!availableCollaboratorsOptions}
      />
      <Button
        shape="circle"
        icon={MoreIcon}
        buttonType="submit"
        iconColor="white"
        isLoading={isSubmitting}
        disabled={!watch('collaborators')}
      />
    </AddFormLayout>
  )
}
