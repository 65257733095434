import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import { InputAutoCompleteInput, Option } from 'components/InputAutoComplete/InputAutoComplete.styles'
import InputNumber from 'components/InputNumber/InputNumber'
import { RowContainer as Row } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Loader from 'components/Loader/Loader'
import Select from 'components/Select/Select'
import { StyledVerticalSeparator } from 'components/Separator/Separator.style'
import Cell from 'components/SortedTable/Cell'
import { LinkCell } from 'components/SortedTable/SortedTable.styles'
import { Text } from 'components/Text/Text.styles'
import { TooltipContainer } from 'components/Tooltip/Tooltip.styles'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import Listing from 'features/Listing/Listing'
import SingleInputForm from 'hooks/useSingleInputForm'
import Flag from 'react-world-flags'
import styled, { css } from 'styled-components'

export const StyledListing = styled(Listing)`
  margin-bottom: 0;
` as typeof Listing

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  order: 1;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    gap: 1rem;
    flex-wrap: wrap;
  }
`
export const CardRowContainer = styled(RowContainer)`
  padding-left: 5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    width: unset;
    flex: 1;
    padding-left: 0;
    gap: 1rem;
  }
`
export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 2rem 0;
`

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 1.25rem 1rem;
  flex-wrap: wrap;

  background-color: ${(props) => props.theme.backgroundColors.lightPurple};
  border-bottom: 2px solid ${(props) => props.theme.borderColors.black};
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    gap: 1em;
  }
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
`

export const SmallAutoComplete = styled(InputAutoComplete)`
  max-width: 35rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    max-width: unset;
    width: unset;
    flex: 1 0 auto;
  }
` as typeof InputAutoComplete

export const ProductAutoComplete = styled(SmallAutoComplete)`
  &.is-invalid {
    ${InputAutoCompleteInput} {
      color: ${(props) => props.theme.textColors.danger};
    }
  }
` as typeof InputAutoComplete

export const IconContainer = styled.div`
  width: 3rem;
`

export const ProductOption = styled(Option)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const UnavailableProductOption = styled(ProductOption)`
  background-color: ${(props) => props.theme.backgroundColors.secondary2};
  cursor: not-allowed;

  &:hover {
    background-color: ${(props) => props.theme.backgroundColors.secondary2};
  }
`

export const CardOption = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 0.5fr 1fr 1fr 1.5fr;
  column-gap: 1rem;
`

export const FlagIcon = styled(Flag)`
  height: 1.7rem;
  width: 2.5rem;
`

const CellStyle = css`
  position: relative;
  gap: 1rem;

  &.preorder {
    background-color: ${(props) => props.theme.backgroundColors.preorder};
  }

  &.product-separation {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid ${(props) => props.theme.borderColors.black};
      z-index: 1; // bring the border to the foreground
      pointer-events: none;
    }
  }
`

export const StyledCell = styled(Cell)`
  justify-content: space-between;
  ${CellStyle}
`

export const RowWithSeparator = styled(Row)`
  ${CellStyle}
  height: 100%;
  padding-left: 1rem;

  ${LinkCell} {
    padding-left: 0;
  }
`

export const UpdateQuantity = styled(SingleInputForm)`
  width: 8.5rem;
`

export const UpdatePrice = styled(SingleInputForm)`
  width: 10.5rem;
`

export const SmallInputNumber = styled(InputNumber)`
  max-width: 8.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    max-width: unset;
    width: unset;
    flex: 1 0 auto;
  }
` as typeof InputNumber

export const GiftDisclaimer = styled(Text)`
  border-bottom: 2px solid ${(props) => props.theme.borderColors.black};
  border-top: 2px solid ${(props) => props.theme.borderColors.black};
  padding: 1.5rem 2rem;
`

export const EditionSelect = styled(Select)`
  max-width: 72rem;
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    width: unset;
    max-width: unset;
    flex-grow: 1;
  }
` as typeof Select

// ##### Product image on hover #####

export const StyledLoader = styled(Loader)`
  padding: 1rem;
`

export const Image = styled.img`
  max-height: 20rem;
`

export const ProductImageContainer = styled.div`
  position: relative;
  width: auto;
  height: auto;
  padding: 1rem;
`

export const FullSizeMultiLinesTooltip = styled(MultiLineTooltip)`
  ${TooltipContainer} {
    width: auto;
    padding: 2rem;
  }
`

export const GripProductsInPack = styled.div`
  display: grid;
  grid-template-columns: 3rem 1rem auto;
  gap: 1rem;
`

export const SealedProductPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 10rem;
`
export const CardOptions = styled(Option)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

export const StrokePrice = styled(Text)`
  text-decoration-line: line-through;
  text-decoration-color: ${(props) => props.theme.textColors.black};
  white-space: nowrap;
`

export const HorizontalSeparator = styled(StyledVerticalSeparator)`
  margin: 0 1rem;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    display: none;
  }
`

export const RowCreditNote = styled(RowContainer)`
  margin-bottom: 2rem;
  font-size: 400;
`