import { CashboxCollectionBody } from 'api/cashbox'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import { endOfDay, formatISO } from 'date-fns'
import useAuth from 'hooks/useAuth'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import ClosedCashboxList from './ClosedCashboxList'
import CashboxManagementForm, { FormData } from './form/CashboxManagementForm'
import OpenedCashboxList from './OpenedCashboxList'

//Needed because of yupResolver changing createdAt[after] to createdAt : {after : ...}
const formDataToQueryParams = (formData: FormData): CashboxCollectionBody => {
  const { createdBefore, createdAfter, ...params } = formData
  return {
    ...params,
    'createdAt[after]': createdAfter ? formatISO(createdAfter) : undefined,
    'createdAt[before]': createdBefore ? formatISO(createdBefore) : undefined
  }
}

function CashboxManagement() {
  const { t } = useTranslation()
  const { me } = useAuth()
  const { checkMatch } = useCheckMatch()

  const [openedCashboxesLoading, setOpenedCashboxesLoading] = useState<boolean>(false)
  const [closedCashboxesLoading, setClosedCashboxesLoading] = useState<boolean>(false)
  const [store, setStore] = useState<Store>(me?.mainStore!)
  const [formData, setFormData] = useState<CashboxCollectionBody>()

  const canManageCashboxes = checkMatch([RoleAction.ROLE_ACTION_CASHBOXES_MANAGE])
  const handleSubmit = (formData: FormData) => {
    const selectedStore = me?.stores?.find((e) => e['@id'] === formData.store)
    if (selectedStore) setStore(selectedStore)
    const { createdBy, ...restData } = formData
    setFormData(
      formDataToQueryParams({
        ...restData,
        createdBy: canManageCashboxes ? createdBy : `${me?.id}`,
        // Make sure the related choosen day is included as well
        createdBefore: formData.createdBefore ? endOfDay(formData.createdBefore) : null
      })
    )
  }

  return (
    <Section>
      <MainTitle title={t('page.cashbox.manage.title')} subtitle={store.shortName} />
      <CashboxManagementForm
        isLoading={openedCashboxesLoading || closedCashboxesLoading}
        onSubmit={handleSubmit}
        canEdit={canManageCashboxes}
      />
      <OpenedCashboxList
        watchLoading={(loading) => setOpenedCashboxesLoading(loading)}
        formData={formData}
        store={store}
      />
      <ClosedCashboxList
        watchLoading={(loading) => setClosedCashboxesLoading(loading)}
        formData={formData}
        store={store}
      />
    </Section>
  )
}

export default CashboxManagement
