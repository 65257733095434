import {
  anonymiseCustomer,
  customerByIdQueryKey,
  patchCustomer,
  PatchCustomerBody,
  updateProCustomer
} from 'api/customers'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import InputRadio from 'components/InputRadio/InputRadio'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import TextFieldDisplay from 'components/Text/TextFieldDisplay'
import Textarea from 'components/Textarea/Textarea'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { WAREHOUSE_STORE_ID } from 'constants/configs'
import { SaveIcon } from 'constants/icons'
import ConfirmModal from 'features/Modals/ConfirmModal'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import useAuth from 'hooks/useAuth'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { getPath, RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import { Customer } from 'types/entities'
import { CustomerType } from 'types/enums'
import { RoleAction } from 'types/playInApiInterfaces'
import { AccountDetail, CommentLabelWrapper, CommentWrapper, Form, LargeInputLayout } from '../CustomerDetails.style'
import GiftCardForm from './GiftcardForm'
import { useAccountOptions } from './useCustomerOptions'

type FormData = {
  comment?: string | null
  pro?: boolean
  accountState: boolean
  fidelityCardNumber?: string | null
  commercialEmails: boolean
  newsletter: boolean
  vatRegistrationNumber?: string | null
  blacklisted: boolean
}

export default function AccountForm({ customer }: { customer: Customer }) {
  const { t } = useTranslation()
  const { id } = useParams()
  const { accountStateOptions, getAccountStateQueryParam } = useAccountOptions(customer)
  const navigate = useNavigate()
  const [anonymiseModalOpen, toggleAnonymiseModal] = useState(false)
  const { me } = useAuth()

  const { register, control, watch, handleSubmit, setValue } = useForm<FormData>({
    /*No need for yup here because there's no validation condition*/
    defaultValues: {
      pro: customer['@type'] === CustomerType.Retailer,
      comment: customer.comment,
      accountState: customer.deleted!,
      fidelityCardNumber: customer.fidelityCardNumber,
      newsletter: customer.newsletter,
      commercialEmails: customer.commercialEmails,
      blacklisted: customer.blacklisted,
      vatRegistrationNumber: customer?.vatRegistrationNumber
    }
  })

  const queryClient = useQueryClient()

  const { mutate: updateCustomer, isLoading } = useMutation((body: PatchCustomerBody) => patchCustomer(id!, body), {
    onSuccess: (data) => {
      let queryKey = customerByIdQueryKey(customer.id!)
      let prev = queryClient.getQueryData<Customer>(queryKey)
      queryClient.setQueryData(queryKey, { ...prev, ...data })
    }
  })

  const { mutate: updateProMutation, isLoading: isProLoading } = useMutation(
    (pro?: boolean) => updateProCustomer(customer.id!, pro),
    {
      onSuccess: (data) => {
        let queryKey = customerByIdQueryKey(customer.id!)
        let prev = queryClient.getQueriesData(queryKey)
        queryClient.setQueryData(queryKey, { ...prev, ...data })
      }
    }
  )

  const { mutate: anonymiseCustomerMutation, isLoading: isAnonymateLoading } = useMutation(
    (id: number) => anonymiseCustomer(id),
    {
      onSuccess: () => {
        navigate(getPath(RouteName.CustomerManagement))
      }
    }
  )

  const onSubmit = (formData: FormData) => {
    const { accountState, pro, comment, fidelityCardNumber, ...data } = formData

    /*if deleting user*/
    if (accountState) {
      toggleAnonymiseModal(true)
      return
    }

    let mutationData = {
      ...data,
      ...getAccountStateQueryParam(customer.deleted!),
      //mandatory params
      firstname: customer.firstname,
      lastname: customer.lastname,
      comment: comment?.trim(),
      fidelityCardNumber: fidelityCardNumber || null
    }
    setValue('comment', comment?.trim())
    updateCustomer(mutationData)
    //If pro status change
    if (pro !== (customer['@type'] === CustomerType.Retailer)) {
      updateProMutation(pro)
    }
  }

  const [watchPro, watchBlacklisted] = watch(['pro', 'blacklisted'])

  useEffect(() => {
    if (watchBlacklisted) {
      setValue('newsletter', false)
      setValue('commercialEmails', false)
    }
  }, [watchBlacklisted, setValue])

  const FormComponent = (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CommentWrapper>
        <CommentLabelWrapper>
          <Text fontWeight="light">{t('common.label.comment')} </Text>
          <Text fontWeight="light" fontStyle="italic">
            {t('page.customer.details.form.publicData')}
          </Text>
        </CommentLabelWrapper>
        <Textarea register={register} id="comment" rows={2} />
      </CommentWrapper>
      <ShouldDisable
        permissions={[RoleAction.ROLE_ACTION_EDIT_CUSTOMER_RETAILER_CORE]}
        deniedExtraCondition={!_.find(me?.stores, { '@id': WAREHOUSE_STORE_ID })}
      >
        <InputRadio
          control={control}
          id="pro"
          label={t('common.label.proAccount')}
          tooltip={
            <Tooltip id="proAccountTooltip">
              <Trans i18nKey="page.customer.details.form.proAccountTooltip" />
            </Tooltip>
          }
        />
      </ShouldDisable>
      <Select
        options={accountStateOptions}
        control={control}
        id="accountState"
        label={t('common.label.accountState')}
        disabled={customer.deleted!}
      />
      <Input
        register={register}
        id="vatRegistrationNumber"
        disabled={!watchPro}
        label={t('page.customer.details.form.vatRegistrationNumber')}
      />
      <Input label={t('page.customer.manage.fidelityCardNumber')} register={register} id="fidelityCardNumber" />
      <InputRadio
        control={control}
        id="commercialEmails"
        label={t('page.customer.details.form.commercialEmails')}
        disabled={watchBlacklisted}
      />
      <InputRadio
        control={control}
        id="newsletter"
        label={t('page.customer.details.form.newsletter')}
        disabled={watchBlacklisted}
      />

      <InputRadio
        control={control}
        id="blacklisted"
        label={t('page.customer.details.form.blacklist')}
        tooltip={
          <Tooltip id="customerBlacklistTooltip">
            <Trans i18nKey="page.customer.details.form.blacklistTooltip" components={{ br: <br /> }} />
          </Tooltip>
        }
      />

      <Button
        icon={SaveIcon}
        shape="circle"
        buttonType="submit"
        isLoading={isLoading || isProLoading || isAnonymateLoading}
      />
    </Form>
  )

  const AccountTextDetail = (
    <AccountDetail>
      <LargeInputLayout
        className="no-border"
        label={
          <>
            <Text fontWeight="light">{t('common.label.comment')} </Text>
            <Text fontWeight="light" fontStyle="italic">
              {t('page.customer.details.form.publicData')}
            </Text>
          </>
        }
        labelComponent={CommentLabelWrapper}
      >
        <TextFieldDisplay text={customer.comment} />
      </LargeInputLayout>

      <InputLayout className="no-border" label={t('common.label.proAccount')}>
        <TextFieldDisplay
          text={customer['@type'] === CustomerType.Retailer ? t('common.radioButton.yes') : t('common.radioButton.no')}
        />
      </InputLayout>
      <InputLayout className="no-border" label={t('common.label.accountState')}>
        <TextFieldDisplay
          text={accountStateOptions.find((opt) => opt.value === customer.deleted!)?.label}
        />
      </InputLayout>
      <InputLayout className="no-border" label={t('page.customer.details.form.vatRegistrationNumber')}>
        <TextFieldDisplay text={customer?.vatRegistrationNumber} />
      </InputLayout>
      <InputLayout className="no-border" label={t('page.customer.manage.fidelityCardNumber')}>
        <TextFieldDisplay text={customer.fidelityCardNumber} />
      </InputLayout>
      <InputLayout className="no-border" label={t('page.customer.details.form.commercialEmails')}>
        <TextFieldDisplay text={customer.commercialEmails ? t('common.radioButton.yes') : t('common.radioButton.no')} />
      </InputLayout>
      <InputLayout className="no-border" label={t('page.customer.details.form.newsletter')}>
        <TextFieldDisplay text={customer.newsletter ? t('common.radioButton.yes') : t('common.radioButton.no')} />
      </InputLayout>
      <InputLayout
        className="no-border"
        label={t('page.customer.details.form.blacklist')}
        tooltip={<TextTooltip id="customerBlacklistTooltip" text={t('page.customer.details.form.blacklistTooltip')} />}
      >
        <TextFieldDisplay text={customer.blacklisted ? t('common.radioButton.yes') : t('common.radioButton.no')} />
      </InputLayout>
    </AccountDetail>
  )

  return (
    <CardLayout>
      <Title2>{t('page.customer.details.accountTitle')}</Title2>
      <CanAccess
        allowedComponent={FormComponent}
        deniedComponent={AccountTextDetail}
        permissions={[RoleAction.ROLE_ACTION_CUSTOMER_EDIT]}
      />

      <GiftCardForm customer={customer} />

      <ConfirmModal
        callback={() => anonymiseCustomerMutation(customer.id!)}
        title={t('page.customer.details.form.modal.title', {
          firstname: customer.firstname,
          lastname: customer.lastname
        })}
        open={anonymiseModalOpen}
        onClose={() => toggleAnonymiseModal(false)}
      >
        <Text color="secondary">{t('page.customer.details.form.modal.content')}</Text>
      </ConfirmModal>
    </CardLayout>
  )
}
