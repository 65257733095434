import { yupResolver } from '@hookform/resolvers/yup'
import { cancelOrder, CancelOrderBody, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import { FormattedNumberComponent } from 'components/InputFormattedNumber/InputFormattedNumber'
import InputRadio from 'components/InputRadio/InputRadio'
import { ColumnContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import Textarea from 'components/Textarea/Textarea'
import { Title2 } from 'components/Title/Title.styles'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { ArchiveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { OrderStatus } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { getTotalRefunds } from '../../helper'
import { LargeModalBody, OrderTotalPaid, RowButtons } from '../BaseModal.style'
import RefundRow from './RefundRow'

export type FormData = {
  cancelReason?: string
  sendEmail: boolean
  paymentMode?: string
  isLost?: boolean
}

interface Props extends Omit<ModalProps, 'children'> {
  order: GetOrderByIdResponse
}

export type Refund = {
  store: string
  refundedAmount: number
  paymentMode: string
}

const schema = yup.object().shape({
  cancelReason: yup.string().trim(),
  sendEmail: yup.boolean().required(),
  isLost: yup.boolean()
})

const removeEmptyRefunds = (refunds: Refund[]) => {
  return _.filter(refunds, (refund) => !_.some(refund, (e) => _.isNil(e)))
}

export default function CancelOrderModal({ open, onClose, order }: Props) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [refunds, setRefunds] = useState<Map<number, Refund>>(new Map())

  const {
    register,
    handleSubmit,
    setError,
    control,
    reset,
    formState: { errors },
    clearErrors
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      sendEmail: true
    }
  })

  const { mutate: cancelOrderMutation, isLoading: isCancelOrderLoading } = useMutation(
    (body: CancelOrderBody) => cancelOrder(order?.id!, body),
    {
      onSuccess: (updatedOrder) => {
        queryClient.setQueryData(orderByIdQueryKey(`${order?.id}`), updatedOrder)
      },
      onSettled: () => {
        reset()
        onClose()
      }
    }
  )

  // Reset form
  useEffect(() => {
    reset({
      sendEmail: true
    })
  }, [reset, order?.paymentReceivedStore, order?.paymentModes, open])

  const handleRefund = useCallback(
    (id: number, refund: Refund) => setRefunds((prev) => new Map(prev.set(id, refund))),
    []
  )

  const totalPaid = parseFloat((order.totalToPay ?? 0).toFixed(2))

  useEffect(() => {
    if (!!order?.paymentModes?.payments?.length) {
      const totalRefunds = getTotalRefunds([...refunds.values()])

      if (totalRefunds < totalPaid) {
        setError('paymentMode', {
          type: 'tooFewRefund',
          message: t('page.order.detail.modals.cancel.label.tooFewRefund')
        })
      }
      if (totalRefunds > totalPaid) {
        setError('paymentMode', {
          type: 'tooMuchRefund',
          message: t('page.order.detail.modals.cancel.label.tooMuchRefund')
        })
      }
      if (totalRefunds === totalPaid) {
        clearErrors('paymentMode')
      }
    }
  }, [refunds, totalPaid, setError, order, clearErrors, t])

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    //Block submit only if there is too much refund
    if (!(errors.paymentMode && errors.paymentMode.type === 'tooMuchRefund')) handleSubmit(onValidSubmit)()
  }

  const onValidSubmit = (data: FormData) => {
    cancelOrderMutation({
      sendEmail: data.sendEmail,
      cancelReason: data.cancelReason,
      isLost: data.isLost,
      paymentModes: removeEmptyRefunds([...refunds.values()])
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <LargeModalBody>
          <Title2>{t('page.order.detail.modals.cancel.title', { referenceNumber: order?.referenceNumber })}</Title2>

          <ColumnContainer>
            {!!order?.paymentModes?.payments?.length ? (
              <>
                <Text fontWeight="light">
                  <Trans i18nKey="page.order.detail.modals.cancel.withPayment" />
                </Text>
                <OrderTotalPaid>
                  <Text fontWeight="light">{t('page.order.detail.modals.cancel.totalRefundRemaining')}</Text>
                  <FormattedNumberComponent value={totalPaid} decimalScale={2} fontWeight="bold" suffix={`\u00a0€`} />
                </OrderTotalPaid>

                {order?.paymentModes?.payments?.map((paymentMode, index) => (
                  <RefundRow key={index} handleRefund={handleRefund} order={order} paymentMode={paymentMode} />
                ))}
                <BasicFieldValidationError error={errors.paymentMode} messages={[]} className="no-margin" />
              </>
            ) : (
              <Text fontWeight="light">{t('page.order.detail.modals.cancel.noPayments')}</Text>
            )}

            <Textarea
              id="cancelReason"
              register={register}
              label={t('page.order.detail.modals.cancel.label.reason')}
              tooltip={
                <MultiLineTooltip
                  id="cancelOrderTooltip"
                  lines={[
                    t('page.order.detail.modals.cancel.tooltipLineOne'),
                    t('page.order.detail.modals.cancel.tooltipLineTwo')
                  ]}
                />
              }
            />
            <InputRadio
              id="sendEmail"
              label={t('page.order.detail.modals.cancel.label.sendEmail')}
              display="checkbox"
              layout="row-reverse"
              control={control}
            />
            {order.status === OrderStatus.Send && order.stock?.warehouse && (
              <InputRadio
                id="isLost"
                label={t('page.order.detail.modals.cancel.label.isLost')}
                display="checkbox"
                layout="row-reverse"
                control={control}
                tooltip={
                  <TextTooltip id="isLostTooltip" text={t('page.order.detail.modals.cancel.label.isLostTooltip')} />
                }
              />
            )}
          </ColumnContainer>

          <RowButtons>
            <div>
              <Button icon={ArchiveIcon} variant="danger" buttonType="submit" isLoading={isCancelOrderLoading}>
                {t('page.order.detail.orderEntries.buttons.cancel')}
              </Button>
            </div>
            <div>
              <Button variant="white" onClick={() => onClose()}>
                {t('common.button.cancel')}
              </Button>
            </div>
          </RowButtons>
        </LargeModalBody>
      </form>
    </Modal>
  )
}
