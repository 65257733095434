import { Filter } from 'components/FiltersList/FiltersList'
import { useProductSheetStatusOptions, useSheetsCollaboratorsOptions } from 'hooks/entityHooks/productSheetsHook'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { SearchSheetsInputs } from '../form/SearchRequestsForm'

export default function useRequestFilters() {
  const { collaboratorOptions, collaboratorOptionsPagination, getCollaboratorLabel } = useSheetsCollaboratorsOptions()
  const { sheetStatusOptions, getSheetStatusLabel } = useProductSheetStatusOptions()

  const filtersFns = useMemo(
    (): { [key in keyof SearchSheetsInputs]?: any } => ({
      status: (id, value) => ({ id, label: getSheetStatusLabel(value) }),
      createdBy: (id, value) => ({ id, label: getCollaboratorLabel(value) })
    }),
    [getCollaboratorLabel, getSheetStatusLabel]
  )

  const [searchFilters, setSearchFilters] = useState<Filter[]>([filtersFns['status']('status', 'created')])

  const handleFilters = (formData: SearchSheetsInputs) => {
    const queryParams = {}
    const filters: Filter[] = []
    _.forEach(formData, (value, name) => {
      if (name && !_.isNil(value) && value !== '') {
        filters.push(!!filtersFns[name] ? filtersFns[name](name, value) : { id: name, label: value })
      }
    })

    setSearchFilters(_.flattenDeep(filters))
    return queryParams
  }
  return {
    handleFilters,
    searchFilters,
    collaboratorOptions,
    collaboratorOptionsPagination,
    sheetStatusOptions
  }
}
