import { Text } from 'components/Text/Text.styles'
import styled from 'styled-components'
import { GridCardLayout } from '../StockDetails.styles'

export const LabelAndTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`

export const SubmitContainer = styled.div`
  align-self: flex-end;
  display: flex;

  @media screen and (${(props) => props.theme.screenWidth.maxLargeScreen}) {
    grid-column: 1/4;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-column: 1/3;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-column: 1/2;
    justify-content: flex-start;
  }
`

export const FieldsLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem 0.5rem;
  align-items: center;
`

export const PaymentFieldLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: center;

  & > :first-child {
    min-width: 11.5rem;
  }
`

export const PaymentFieldsLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem 0.5rem;
`

export const DisplayCardLayout = styled(GridCardLayout)`
  & > div {
    display: grid;
    grid-template-rows: 4rem auto;
  }
`

export const ErrorDisplay = styled.div`
  grid-column: span 2;
  ${Text} {
    margin: 0;
  }
`
