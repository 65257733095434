import { GetOrderByIdResponse } from 'api/orders'
import { BlockLayout } from 'components/Layouts/BlockLayout/BlockLayout.style'
import AddressStyledTabs from './AddressSection'
import ExternalOrderTitle from './ExternalOrderTitle'
import LinkedOrderSubTitle from './LinkedOrderSubTitle'

type OrderInfoCardProps = {
  order?: GetOrderByIdResponse
}

function OrderInfoCard({ order }: OrderInfoCardProps) {
  return (
    <>
      {order?.sourceReference && (
        <BlockLayout>
          <ExternalOrderTitle
            sourceName={order.sourceName!}
            sourceReference={order.sourceReference!}
            sourceLink={order.sourceLink!}
          />
        </BlockLayout>
      )}
      {order && order.splitOrders && order.splitOrders.length > 0 && (
        <BlockLayout>
          <LinkedOrderSubTitle splitOrders={order?.splitOrders!} />
        </BlockLayout>
      )}
      <AddressStyledTabs order={order} />
    </>
  )
}

export default OrderInfoCard
