import { useGetCountries } from 'api/countries'
import { useGetLangs } from 'api/langs'
import { useGetWarehouse } from 'api/stores'
import { useGetTaxes } from 'api/taxes'
import BackToTop from 'features/BackToTop/BackToTop'
import ModalsContainer from 'features/Modals/ModalsContainer'
import SideBar from 'features/SideBar/SideBar'
import TopBar from 'features/TopBar/TopBar'
import useAuth from 'hooks/useAuth'
import { useEventEmitter } from 'hooks/useEventEmitter'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { EventType } from 'types/events'
import { getJWT } from 'utils/localStorage'
import { MainWrapper } from './mainLayout.styles'

export default function MainLayout() {
  // Fetch "static data" to use reactQuery client as dataStore
  useGetTaxes()
  useGetLangs()
  useGetWarehouse()
  useGetCountries()

  const { logout } = useAuth()

  const location = useLocation()
  const { useListener, emit } = useEventEmitter()

  const checkJwt = () => {
    const jwt = getJWT()
    if (jwt) {
      try {
        const decodedJwt = jwtDecode<JwtPayload>(jwt)
        if (decodedJwt.exp && decodedJwt.exp * 1000 < Date.now()) {
          emit(EventType.Logout, null)
        }
      } catch (e) {
        emit(EventType.Logout, null)
      }
    } else {
      emit(EventType.Logout, null)
    }
  }

  useListener(EventType.Logout, () => {
    logout()
  })

  // Check if JWT is not expired on page change
  useEffect(checkJwt, [location, emit])

  const [collapsed, setCollapsed] = useState(false)

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth < 780) {
        setCollapsed(true)
      }
    }
    const handleResize = () => {
      if (window.innerWidth < 780) {
        setCollapsed(true)
      } else {
        setCollapsed(false)
      }
    }
    window.addEventListener('load', checkMobile)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <TopBar openSidebar={toggle} isSidebarOpen={!collapsed} />
      <MainWrapper className="playin-main-wrapper">
        <SideBar collapsed={collapsed} />
        <Outlet />
        <BackToTop />
        <ModalsContainer />
      </MainWrapper>
    </>
  )
}
