import { Skeleton } from '@mui/material'
import ImageComponent from 'components/Image/Image'
import { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import { CrossIcon } from 'constants/icons'
import { useCallback, useState } from 'react'
import { Control, FieldValues, Path, SetFieldValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ControlledInputFile, { FileError } from './ControlledInputFile'
import { DeleteImage, ImageCard, ImageUploaderWrapper } from './InputFile.styles'

interface Props<T extends FieldValues> extends LayoutProps {
  allowed?: {
    width?: number
    height?: number
    ratio?: number
    types?: Array<'jpeg' | 'png' | 'svg+xml' | 'webp' | 'jpg'>
  }
  display?: {
    width?: number
    height?: number
  }
  id: Path<T>
  control: Control<T>
  setValue: SetFieldValue<T>
  hasError?: boolean
}

const imageTypePrefix = 'image/'
const defaultAuthorizedType = ['jpeg', 'png', 'webp', 'jpg']

export default function ImageUploader<T extends FieldValues>({
  allowed,
  display,
  id,
  control,
  setValue,
  hasError,
  ...layoutProps
}: Props<T>) {
  const [img, setImg] = useState<string | undefined>()
  const { t } = useTranslation()

  const handleFile = useCallback((file: File) => {
    const fileReader = new FileReader()

    fileReader.onload = () => {
      setImg(fileReader.result as string)
    }

    fileReader.readAsDataURL(file)
  }, [])

  const validationFn = (file: File): Promise<FileError[]> => {
    return new Promise(async (resolve) => {
      const allowedTypes = (allowed?.types ?? defaultAuthorizedType).map((e) => `${imageTypePrefix}${e}`)

      // If invalid type
      if (!allowedTypes.includes(file.type)) {
        resolve([{ key: 'type', message: t('common.file.errors.type') }])
      }

      const fileReader = new FileReader()

      fileReader.onload = () => {
        const errors: FileError[] = []
        const image = new Image()
        image.src = fileReader.result as string
        image.onload = () => {
          if (allowed?.width && image.width !== allowed.width) {
            errors.push({ key: 'width', message: t('common.file.errors.width') })
          }
          if (allowed?.height && image.height !== allowed.height) {
            errors.push({ key: 'height', message: t('common.file.errors.height') })
          }

          if (allowed?.ratio && image.width / image.height !== allowed.ratio) {
            errors.push({ key: 'ratio', message: t('common.file.errors.ratio') })
          }
          resolve(errors)
        }

        image.onerror = () => resolve(errors)
      }

      fileReader.onerror = () => resolve([{ key: 'upload', message: t('common.file.errors.upload') }])
      fileReader.readAsDataURL(file)
    })
  }

  const handleDelete = () => {
    setImg(undefined)
    setValue(id, null)
  }

  return (
    <ImageUploaderWrapper>
      <ControlledInputFile
        {...layoutProps}
        handleFile={handleFile}
        control={control}
        id={id}
        validationFn={validationFn}
        hasError={hasError}
      />
      {img && (
        <ImageCard>
          <DeleteImage svg={CrossIcon} color="grey4" size="2rem" onClick={() => handleDelete()} />
          <ImageComponent
            src={img}
            width={display?.width}
            height={display?.height}
            alt={`upload-${id}`}
            skeleton={
              display && (
                <Skeleton
                  variant="rectangular"
                  width={display.width ?? display.height}
                  height={display.height ?? display.width}
                />
              )
            }
          />
        </ImageCard>
      )}
    </ImageUploaderWrapper>
  )
}
