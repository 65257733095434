import TextNumber from 'components/TextNumber/TextNumber'
import { InfoText, PriceContainer, StrokePrice } from '../ProductDetails.styles'

export type ProductPriceProps = {
  price: number
  discount?: number
}

const ProductPrice = ({ price, discount }: ProductPriceProps) => {
  const sellPrice = discount && price ? price - discount : price
  return (
    <PriceContainer>
      <TextNumber
        value={sellPrice}
        color={!!discount && !!price ? 'danger' : 'black'}
        suffix="  €"
        decimalScale={2}
        ContainerComponent={InfoText}
      />
      {!!discount && !!price && (
        <TextNumber value={price} color="danger" suffix="  €" decimalScale={2} ContainerComponent={StrokePrice} />
      )}
    </PriceContainer>
  )
}

export default ProductPrice
