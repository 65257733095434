import { ReactComponent as DoodleFiveSvg } from 'assets/svg/icon/doodle_five.svg'
import { ReactComponent as DoodleFourSvg } from 'assets/svg/icon/doodle_four.svg'
import { ReactComponent as DoodleOneSvg } from 'assets/svg/icon/doodle_one.svg'
import { ReactComponent as DoodleSevenSvg } from 'assets/svg/icon/doodle_seven.svg'
import { ReactComponent as DoodleSixSvg } from 'assets/svg/icon/doodle_six.svg'
import { ReactComponent as DoodleThreeSvg } from 'assets/svg/icon/doodle_three.svg'
import { ReactComponent as DoodleTwoSvg } from 'assets/svg/icon/doodle_two.svg'

export const DoodleOneIcon = DoodleOneSvg
export const DoodleTwoIcon = DoodleTwoSvg
export const DoodleThreeIcon = DoodleThreeSvg
export const DoodleFourIcon = DoodleFourSvg
export const DoodleFiveIcon = DoodleFiveSvg
export const DoodleSixIcon = DoodleSixSvg
export const DoodleSevenIcon = DoodleSevenSvg
