import { GetOrderByIdResponse } from 'api/orders'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import Select from 'components/Select/Select'
import { useMyStoresOptions, usePaymentModeFilters } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { PaymentModes } from 'types/enums'
import { CustomerType, OrderPaymentOutputJsonldOrderReadOrderReadItem } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import { Row } from '../BaseModal.style'
import { Refund } from './CancelOrderModal'

interface RowProps {
  paymentMode: OrderPaymentOutputJsonldOrderReadOrderReadItem
  handleRefund: (id: number, refund: Refund) => void
  order: GetOrderByIdResponse
}

const noVoucherCustomerTypes = [
  CustomerType.INTERNAL,
  CustomerType.SELLERMANIA,
  CustomerType.CARDTRADER,
  CustomerType.CARDMARKET
]
export default function RefundRow({ paymentMode, handleRefund, order }: RowProps) {
  const { paymentModeOptions, getPaymentModeByValue, paymentModeOptionsPaginationProps } = usePaymentModeFilters()
  const { myStoresOptions } = useMyStoresOptions()
  const [myStore] = useAtom(defaultStoreAtom)

  const { control, watch, reset } = useForm<Refund>({
    defaultValues: {}
  })

  useEffect(() => {
    reset({
      store: order.paymentReceivedStore?.['@id'] ?? myStore?.['@id'],
      refundedAmount: paymentMode.value ?? 0,
      paymentMode: paymentMode.paymentMode?.['@id']
    })
  }, [reset, myStore, order.paymentReceivedStore, paymentMode, order.totalToPay])

  useEffect(() => {
    const subscription = watch((data) => {
      handleRefund(paymentMode.id!, {
        store: data.store!,
        refundedAmount: data.refundedAmount!,
        paymentMode: data.paymentMode!
      })
    })
    return () => subscription.unsubscribe()
  }, [watch, handleRefund, paymentMode])

  return (
    <Row>
      <Select
        control={control}
        id="paymentMode"
        options={paymentModeOptions.map((option) => {
          return {
            ...option,
            //Disable voucher option for internal / sellermania / mkm / cardTrader account
            disabled:
              getPaymentModeByValue(option.value)?.codeName === PaymentModes.Voucher &&
              !!order.customer?.accountType &&
              noVoucherCustomerTypes.includes(order.customer?.accountType)
          }
        })}
        {...paymentModeOptionsPaginationProps}
      />
      <InputFormattedNumber
        control={control}
        id="refundedAmount"
        decimalScale={2}
        suffix={`\u00a0€`}
        placeholder="0.00€"
      />
      <Select options={myStoresOptions} control={control} id="store" />
    </Row>
  )
}
