import styled from 'styled-components'

export const VouchersStatisticsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
`
export const VoucherStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const VoucherButtonsContainer = styled.div`
  padding-top: 3.5rem;
  gap: 1rem;
  display: grid;
`
