import FieldDisplay from 'components/Text/FieldDisplay'
import { useTranslation } from 'react-i18next'
import { Collaborator } from 'types/entities'
import { FormWrapper, TwoColsRow } from '../CollaboratorDetails.style'
interface Props {
  collaborator: Collaborator
}
export default function CollaboratorDetailsFormDisplay({ collaborator }: Props) {
  const { t } = useTranslation()
  return (
    <FormWrapper>
      <TwoColsRow>
        <FieldDisplay value={collaborator.lastname} label={t('common.label.lastName')} />
        <FieldDisplay value={collaborator.firstname} label={t('common.label.firstName')} />
      </TwoColsRow>
      <FieldDisplay value={collaborator.email} label={t('common.label.email')} />
    </FormWrapper>
  )
}
