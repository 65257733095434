import { useTranslation } from 'react-i18next'
import { Text, TextProps } from './Text.styles'

type Props = TextProps & {
  text?: string | null | JSX.Element
  placeholder?: JSX.Element | string
}

const TextFieldDisplay = ({ text, placeholder, ...textProps }: Props) => {
  const { t } = useTranslation()

  return text ? (
    <Text fontWeight="medium" {...textProps} whiteSpace="pre-wrap">
      {text}
    </Text>
  ) : placeholder ? (
    typeof placeholder === 'string' ? (
      <Text color="secondary" fontWeight="light" fontStyle="italic">
        {placeholder}
      </Text>
    ) : (
      placeholder
    )
  ) : (
    <Text color="secondary" fontWeight="light" fontStyle="italic">
      {t('common.label.notAvailable')}
    </Text>
  )
}

export default TextFieldDisplay
