import Tooltip from 'components/Tooltip/Tooltip'
import { Trans } from 'react-i18next'

export const ReceptionDateTooltip = () => (
  <Tooltip id="receptionDateTooltip">
    <Trans i18nKey="tooltips.stocks.receptionDate.text" components={{ br: <br /> }} />
  </Tooltip>
)
export const DueDateFrTooltip = () => (
  <Tooltip id="dueDateFrTooltip">
    <Trans i18nKey="tooltips.stocks.dueDateFr.text" components={{ br: <br /> }} />
  </Tooltip>
)
export const DueDateEnTooltip = () => (
  <Tooltip id="dueDateEnTooltip">
    <Trans i18nKey="tooltips.stocks.dueDateEn.text" />
  </Tooltip>
)
