import { yupResolver } from '@hookform/resolvers/yup'
import { postOrderEntry } from 'api/orderEntries'
import { getSealedProductsUri, ProductStoreFromSealedProductCollection as ProductStore } from 'api/sealedProducts'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import Svg from 'components/Svg/Svg'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { MoreIcon, ProductIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { OrderEntry, OrderItem, SealedProduct } from 'types/entities'
import { SortingDirection } from 'types/sorting'
import * as yup from 'yup'
import {
  Form,
  IconContainer,
  ProductAutoComplete,
  ProductOption,
  SealedProductPriceContainer,
  SmallInputNumber,
  StrokePrice,
  UnavailableProductOption
} from './OrderEntriesTable.styles'

const prodcutFormSchema = yup.object().shape({
  product: yup.string().trim().required(),
  quantity: yup.number().moreThan(0).required()
})

type Props = {
  order?: OrderItem
}

type ProductFormData = {
  product: string
  quantity: number
}

const OrderEntryProductForm = ({ order }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const stockId = order?.stock?.['@id']!
  const orderId = order?.['@id']!
  const [selectedProduct, setSelectedProduct] = useState<ProductStore | undefined>(undefined)

  const getProductOptionFormat = (stockId?: string) => {
    return (product: SealedProduct, onClick: () => void) => {
      const productStore = product.productStores?.find((productStore) => stockId === productStore.store?.['@id'])

      return productStore?.quantity! < 1 ? (
        <UnavailableProductOption>
          <Text color="secondary">{product.name}</Text>
          <Text color="secondary" fontStyle="italic">
            {t('common.label.unavailable')}
          </Text>
        </UnavailableProductOption>
      ) : (
        <ProductOption onClick={onClick}>
          <Text> {product.name}</Text>
          <Text color="secondary" fontStyle="italic">
            {`(${productStore?.quantity})`}
          </Text>
        </ProductOption>
      )
    }
  }

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { touchedFields, errors },
    reset,
    clearErrors,
    trigger
  } = useForm<ProductFormData>({
    resolver: yupResolver(prodcutFormSchema),
    defaultValues: {
      quantity: 1
    }
  })

  const { mutate: addProduct, isLoading: isMutationLoading } = useMutation(
    (body: ProductFormData) =>
      postOrderEntry({
        order: orderId,
        product: body.product,
        quantity: body.quantity
      }),
    {
      onSuccess: (newEntry) => {
        queryClient.setQueriesData<OrderEntry[]>(['orderEntries', order?.id!], (prevEntries) => [
          ...(prevEntries || []),
          newEntry
        ])
      }
    }
  )

  const onSubmit = (formData: ProductFormData) => {
    addProduct(formData)
    reset()
    setSelectedProduct(undefined)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <IconContainer>
        <Svg svg={ProductIcon} size="1.6em" />
      </IconContainer>
      <ProductAutoComplete
        id="product"
        className={classnames({ 'is-invalid': errors.product || selectedProduct?.quantity! < 1 })}
        placeholder={t('page.order.detail.orderEntries.table.placeholders.product')}
        formatOption={getProductOptionFormat(stockId)}
        labelKey="name"
        valueKey="@id"
        control={control}
        isTouched={touchedFields.product}
        onEntitySelected={useCallback(
          (product: SealedProduct) => {
            clearErrors('product')
            if (!product) {
              return reset()
            }

            const productStore = product?.productStores?.find((productStore) => stockId === productStore.store?.['@id'])
            setSelectedProduct(productStore)
          },
          [stockId, clearErrors, reset]
        )}
        getUrlFromSearch={(search) =>
          getSealedProductsUri({
            search: search,
            'productStores.store': stockId,
            'order[old]': SortingDirection.Asc,
            'order[name]': SortingDirection.Asc
          })
        }
      />
      <SmallInputNumber
        id="quantity"
        className={errors.quantity ? 'is-invalid' : ''}
        register={register}
        setValue={setValue}
        trigger={trigger}
        max={selectedProduct?.quantity ?? 1}
        min={1}
        disabled={selectedProduct?.quantity! < 1}
      />

      <SealedProductPriceContainer>
        {selectedProduct && (
          <>
            {selectedProduct.customerDiscount && selectedProduct.customerSellPrice ? (
              <>
                <TextNumber
                  value={selectedProduct.customerSellPrice - selectedProduct.customerDiscount}
                  suffix={'\u00A0€'}
                  decimalScale={2}
                />
                <TextNumber
                  value={selectedProduct.customerSellPrice}
                  prefix="("
                  suffix={'\u00A0€)'}
                  decimalScale={2}
                  fontWeight="light"
                  ContainerComponent={StrokePrice}
                />
              </>
            ) : (
              <>
                <TextNumber value={selectedProduct.customerSellPrice} suffix=" €" decimalScale={2} />
              </>
            )}
          </>
        )}
      </SealedProductPriceContainer>
      <Button
        shape="circle"
        icon={MoreIcon}
        buttonType="submit"
        disabled={selectedProduct?.quantity! < 1}
        iconColor="white"
        isLoading={isMutationLoading}
      />
    </Form>
  )
}

export default OrderEntryProductForm
