import { patchStore, PatchStoreBody } from 'api/stores'
import { Props as ButtonProps } from 'components/Button/Button'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useState } from 'react'
import { StoreDetails } from 'types/entities'
import GameRentalFormSection from './forms/GameRentalFormSection'
import PaybackFormSection from './forms/PaybackFormSection'
import PlayAreaFormSection from './forms/PlayAreaFormSection'
import StoreFormSection from './forms/StoreFormSection'
import StoreSellFormSection from './forms/StoreSellFormSection'

interface Props {
  store: StoreDetails
}

export type FormSection = 'description' | 'shortDescription' | 'gameRental' | 'payback' | 'playArea'

export default function StoreSeoTab({ store }: Props) {
  const [formSection, setFormSection] = useState<FormSection>()

  const { mutate: patchStoreMutation, isLoading } = useMutation((body: PatchStoreBody) => patchStore(store.id!, body))

  const getSubmitButtonProps = (id: FormSection): ButtonProps => ({
    buttonType: 'submit',
    icon: SaveIcon,
    shape: 'circle',
    onClick: () => setFormSection(id),
    isLoading: isLoading && formSection === id,
    disabled: isLoading && formSection !== id
  })

  return (
    <>
      <StoreFormSection mutation={patchStoreMutation} getSubmitButtonProps={getSubmitButtonProps} store={store} />
      <StoreSellFormSection mutation={patchStoreMutation} getSubmitButtonProps={getSubmitButtonProps} store={store} />
      <PlayAreaFormSection mutation={patchStoreMutation} getSubmitButtonProps={getSubmitButtonProps} store={store} />
      <GameRentalFormSection mutation={patchStoreMutation} getSubmitButtonProps={getSubmitButtonProps} store={store} />
      <PaybackFormSection mutation={patchStoreMutation} getSubmitButtonProps={getSubmitButtonProps} store={store} />
    </>
  )
}
