import styled from 'styled-components'

export const SearchProductForm = styled.form`
  display: grid;
  gap: 2rem;
`

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem 3.5rem;
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem 3.5rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`

export const InputCol = styled.div`
  display: grid;
  gap: 2rem;
`

export const CategoryCol = styled(InputCol)`
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) and (${(props) =>
      props.theme.screenWidth.minTabletScreen}) {
    grid-column: span 2;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 3.5rem;
  }
`
