import styled, { css } from 'styled-components'

const InputStyle = css`
  width: 100%;
  height: 100%;
  background: none;
  padding: 0 1rem;
  font-size: 1.4rem;
  border-radius: 0.3rem;
`

export const InputTextStyle = styled.input`
  ${InputStyle}

  border: none;

  &:focus,
  &.is-invalid {
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    font-size: 1.3rem;
    font-style: italic;
    font-weight: ${(props) => props.theme.fontWeight.light};
    color: ${(props) => props.theme.textColors.secondary};
  }
`
