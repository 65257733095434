import { OrderStatus, StockStatus, StoreEventStatus } from './playInApiInterfaces'

// ##### Orders #####

export const OngoingStatuses = [OrderStatus.Validated, OrderStatus.Paid, OrderStatus.InPreparation, OrderStatus.Ready]
export const UnmodifiableOrderStatuses = [OrderStatus.Treated, OrderStatus.Canceled, OrderStatus.CanceledRequested]
export const CanceledOrderStatuses = [OrderStatus.Canceled, OrderStatus.CanceledRequested]

export enum OrderProgress {
  ExcludedPrint = 'ExcludedPrint',
  CardPreparedComplete = 'CardPreparedComplete',
  CardPreparedIncomplete = 'CardPreparedIncomplete',
  CardCheckedComplete = 'CardCheckedComplete',
  CardCheckedIncomplete = 'CardCheckedIncomplete',
  CardPreorder = 'CardPreorder',
  CardSav = 'CardSav',
  ProductPreparedComplete = 'ProductPreparedComplete',
  ProductPreparedIncomplete = 'ProductPreparedIncomplete',
  ProductCheckedComplete = 'ProductCheckedComplete',
  ProductCheckedIncomplete = 'ProductCheckedIncomplete',

  ProductPreorder = 'ProductPreorder',
  ProductSav = 'ProductSav',

  CardInTransit = 'CardInTransit',
  ProductInTransit = 'ProductInTransit'
}

// ##### Customers #####
export enum CustomerType {
  Retailer = 'Retailer',
  Customer = 'Customer'
}

// ##### Payment Modes #####

export enum PaymentModes {
  Cash = 'cash',
  Check = 'check',
  Paypal = 'paypal',
  Transfer = 'transfer',
  BankCard = 'bankCard',
  MandatCash = 'mandatCash',
  Voucher = 'voucher',
  Store = 'store',
  Multiple = 'multiple',
  Lcr = 'lcr',
  GiftCard = 'giftCard',
  Payment3 = 'payment3',
  Payment4 = 'payment4',
  TicketRestaurant = 'ticketRestaurant',
  BankCardStore = 'bankCardStore',
  Levy = 'levy',
  Credit = 'credit'
}

// ##### Stocks #####

export const UnmodifiableStockStatus = [StockStatus.Treated, StockStatus.Canceled]

// ##### StoreEvents #####
export const UnmodifiableStoreEventStatus = [StoreEventStatus.Treated, StoreEventStatus.Canceled]

export enum CustomStoreEventStatus {
  Current = 'current'
}

export const StoreEventStatusOptions = [CustomStoreEventStatus.Current, ...UnmodifiableStoreEventStatus]
