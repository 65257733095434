import { useGetSealedProductById } from 'api/sealedProducts'
import { PageLoader } from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import Tabs, { TabProps } from 'components/Tabs/Tabs'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { BNF_STORE_ID, WAREHOUSE_STORE_ID } from 'constants/configs'
import useAuth from 'hooks/useAuth'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Params, useParams } from 'react-router-dom'
import { RoleAction } from 'types/playInApiInterfaces'
import ProductInfo from './ProductDetailHeader/ProductInfo'
import ProductPrice from './ProductDetailHeader/ProductPrice'
import ProductStockModal from './ProductDetailHeader/ProductStockModal'
import {
  CardContainer,
  DescriptionBlock,
  DescriptionContainer,
  Img,
  ImgCard,
  InfoBlockContainer,
  InfoCard,
  InfoText,
  ModalButton,
  PackBlock,
  PricesBlock
} from './ProductDetails.styles'
import { useProductInfoHelpers } from './productDetailsUtils'
import ProductDetailsTab from './tabs/detailsTab/ProductDetailsTab'
import ProductSalesTab from './tabs/salesTab/ProductSalesTab'
import ProductStocksTab from './tabs/stockTab/ProductStocksTab'

interface QueryParams extends Params {
  id: string
}

function ProductDetails() {
  const { checkMatch } = useCheckMatch()
  const { t } = useTranslation()
  const { id } = useParams() as QueryParams
  const { data: product, isLoading } = useGetSealedProductById(id)
  const { me } = useAuth()
  const mainStoreId = me?.mainStore?.['@id']

  const [openStockModal, setOpenStockModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState<number | null>()

  const tabs: TabProps[] = [
    {
      tabLabel: t('page.product.detail.tabs.detail.title'),
      panelComponent: <ProductDetailsTab productId={id} />
    },
    {
      tabLabel: t('page.product.detail.tabs.sales.title'),
      panelComponent: <ProductSalesTab productId={id} active={selectedTab === 1} />,
      disabled: !checkMatch([RoleAction.ROLE_ACTION_ORDERS_READ]),
      noStyle: true
    },
    {
      tabLabel: t('page.product.detail.tabs.buyings.title'),
      panelComponent: <ProductStocksTab productId={id} active={selectedTab === 2} />,
      disabled: !checkMatch([RoleAction.ROLE_ACTION_STOCKS_READ]),
      noStyle: true
    }
  ]

  const {
    getReleaseDateColor,
    getDiscountInfo,
    getStoreInfo,
    getMainStoreProductPrice,
    getReleaseDate,
    getWarehouseInfo
  } = useProductInfoHelpers()

  return (
    <Section>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <ProductStockModal open={openStockModal} onClose={() => setOpenStockModal(false)} product={product!} />

          <MainTitle title={product?.name!} />
          <CardContainer>
            <ImgCard>
              <Img src={product?.mainImageUrl || undefined} />
            </ImgCard>
            <InfoCard>
              <InfoBlockContainer>
                {getWarehouseInfo(product!)}
                {getStoreInfo(
                  product!,
                  //Shows BNF informations if main store is warehouse or an inactive store
                  WAREHOUSE_STORE_ID === mainStoreId || !me?.mainStore?.active ? BNF_STORE_ID : mainStoreId
                )}
              </InfoBlockContainer>
              <ModalButton variant="white" onClick={() => setOpenStockModal(true)}>
                {t('page.product.infos.otherStocks')}
              </ModalButton>
            </InfoCard>
            <InfoCard>
              <InfoBlockContainer>
                <ProductInfo title={t('page.product.infos.releaseDate')}>
                  <InfoText color={getReleaseDateColor(product!)}>{getReleaseDate(product!)}</InfoText>
                </ProductInfo>
                <PricesBlock>
                  <ProductInfo
                    title={t('page.product.infos.price')}
                    info={getDiscountInfo(product!)}
                    infoColor="danger"
                  >
                    <ProductPrice price={product?.price!} discount={product?.discount} />
                  </ProductInfo>
                  {getMainStoreProductPrice(product!, mainStoreId)}
                </PricesBlock>
              </InfoBlockContainer>
              <DescriptionContainer>
                <DescriptionBlock>
                  {product?.pack ? (
                    <PackBlock>{t('page.product.infos.isPack')}</PackBlock>
                  ) : (
                    <Text fontWeight="light" color="secondary">
                      {t('page.product.infos.isNotPack')}
                    </Text>
                  )}
                </DescriptionBlock>
                <DescriptionBlock>
                  {!!product?.specialOffer ? (
                    <Text>{product?.specialOffer}</Text>
                  ) : (
                    <Text fontWeight="light" color="secondary">
                      {t('page.product.infos.noOffer')}
                    </Text>
                  )}
                </DescriptionBlock>
              </DescriptionContainer>
            </InfoCard>
          </CardContainer>
          <Tabs tabs={tabs} onTabChange={setSelectedTab} />
        </>
      )}
    </Section>
  )
}

export default ProductDetails
