import Button from 'components/Button/Button'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { Title2 } from 'components/Title/Title.styles'
import { DeleteIcon } from 'constants/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductLabel } from '../storeLabelUtils'
import AddLabelForm from './Forms/AddLabelForm'
import EditLabelForm from './Forms/EditLabelForm'
import { DeleteContainer, NoMarginTitle } from './LabelEditionModal.styles'

export type EditionMode = 'add' | 'edit'

type Props = ModalProps & {
  editionMode?: EditionMode
  storeId: string
  setLabels: React.Dispatch<React.SetStateAction<ProductLabel[]>>
  selectedLabel?: ProductLabel
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>
}

export default function LabelEditionModal({ open, setOpen, editionMode, storeId, setLabels, selectedLabel }: Props) {
  const { t } = useTranslation()

  const addNewLabel = (updatedLabel: ProductLabel) => {
    setLabels((prevLabels) => {
      const existingLabel = prevLabels.find((prevLabel) => prevLabel.id === updatedLabel.id)
      if (existingLabel) {
        return prevLabels.map((label) => (existingLabel.id === label.id ? updatedLabel : label))
      }

      return [...prevLabels, updatedLabel]
    })
  }

  const [activeLabel, setActiveLabel] = useState<ProductLabel | undefined>(selectedLabel)
  useEffect(() => {
    setActiveLabel(selectedLabel)
  }, [selectedLabel])

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
        setActiveLabel(undefined)
      }}
    >
      {editionMode === 'add' && (
        <>
          <Title2>{t('page.store.labels.print.modal.add.title')}</Title2>
          <AddLabelForm storeId={storeId} setActiveLabel={setActiveLabel} />
        </>
      )}
      {editionMode === 'edit' && <Title2>{t('page.store.labels.print.modal.edit.title')}</Title2>}
      <EditLabelForm
        addNewLabel={addNewLabel}
        activeLabel={activeLabel}
        onEdit={() => {
          setOpen(false)
          setActiveLabel(undefined)
        }}
      />
      {editionMode === 'edit' && (
        <DeleteContainer>
          <NoMarginTitle>{t('page.store.labels.print.modal.delete.title')}</NoMarginTitle>
          <Button
            variant="danger"
            shape="circle"
            icon={DeleteIcon}
            onClick={() => {
              setLabels((prevLabels) => prevLabels.filter((prevLabel) => prevLabel.id !== activeLabel?.id))
              setOpen(false)
              setActiveLabel(undefined)
            }}
          />
        </DeleteContainer>
      )}
    </Modal>
  )
}
