import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import DatePicker from 'components/DatePicker/DatePicker'
import { StyledText } from 'components/FormValidationErrors/FormValidationErrors.styles'
import Input from 'components/Input/Input'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import InputNumber from 'components/InputNumber/InputNumber'
import _ from 'lodash'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

const Form = styled.form`
  width: 100%;
`

type FormData = {
  inputValue: any
}

type ComponentProps = {
  defaultValue?: number | string | null | Date
  onSubmit: ({ inputValue }: FormData) => Promise<any>
  type: 'formattedNumber' | 'number' | 'date' | 'text'
  prefix?: string
  suffix?: string
  decimalScale?: number
  placeholder?: string
  className?: string
  ContainerComponent?: React.ComponentType
  fieldSchema?: yup.AnySchema
  min?: number
  max?: number
  disabled?: boolean
  mode?: 'onChange' | 'onSubmit' | 'onBlur'
  displayError?: boolean
  inputClassName?: string
  submitType?: 'onSubmit' | 'onBlur'
}

const SingleInputForm = ({
  defaultValue,
  onSubmit,
  type,
  prefix,
  suffix,
  decimalScale,
  placeholder,
  className,
  ContainerComponent,
  min,
  max,
  fieldSchema,
  disabled,
  mode = 'onSubmit',
  displayError = true,
  inputClassName,
  submitType = 'onSubmit'
}: ComponentProps) => {
  const schema = fieldSchema && yup.object().shape({ inputValue: fieldSchema })

  const {
    handleSubmit,
    register,
    control,
    setValue,
    trigger,
    formState: { errors },
    watch
  } = useForm<FormData>({
    ...(schema && { resolver: yupResolver(schema) }),
    mode: mode
  })

  useEffect(() => {
    setValue('inputValue', defaultValue)
  }, [defaultValue, setValue])

  const watchValue = watch('inputValue')

  const submitFunction = useCallback(
    async (formData: FormData) => {
      const response = await onSubmit(formData)
      if (response) {
        setValue('inputValue', response)
      }
    },
    [onSubmit, setValue]
  )

  useEffect(() => {
    if (!_.isNil(watchValue) && mode === 'onChange') {
      submitFunction({ inputValue: watchValue })
    }
  }, [watchValue, submitFunction, mode])

  return (
    <Form
      className={className}
      onSubmit={handleSubmit(submitFunction)}
      onBlur={submitType === 'onBlur' ? handleSubmit(submitFunction) : () => {}}
    >
      {
        {
          formattedNumber: (
            <InputFormattedNumber
              id="inputValue"
              control={control}
              prefix={prefix}
              suffix={suffix}
              decimalScale={decimalScale}
              placeholder={placeholder}
              ContainerComponent={ContainerComponent}
              disabled={disabled}
              className={classNames(
                {
                  'is-invalid': errors.inputValue
                },
                inputClassName
              )}
            />
          ),
          number: (
            <InputNumber
              id="inputValue"
              register={register}
              setValue={setValue}
              trigger={trigger}
              min={min}
              max={max}
              disabled={disabled}
              className={classNames(
                {
                  'is-invalid': errors.inputValue
                },
                inputClassName
              )}
            />
          ),
          date: (
            <DatePicker
              defaultValue={watchValue}
              id="inputValue"
              register={register}
              setValue={setValue}
              trigger={trigger}
            />
          ),
          text : (
            <Input id='inputValue' register={register}/>
          )
        }[type]
      }

      {displayError && errors.inputValue && <StyledText color="danger">{errors.inputValue.message}</StyledText>}
    </Form>
  )
}

export default SingleInputForm
