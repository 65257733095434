import styled from 'styled-components'

export const LogoutIcon = styled.img`
  cursor: pointer;
  border-radius: 50%;
  width: 2rem;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey4};
  }
`
