import { API_ROUTES } from 'constants/configs'
import { useQuery } from 'react-query'
import { StorePlaceOrder } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

type FetchStorePlaceOrdersResponse = Api.GetStorePlaceOrders.ResponseBody
const fetchStorePlaceOrders = async (): Promise<StorePlaceOrder[]> => {
  const res = await playInApi.get<FetchStorePlaceOrdersResponse>(API_ROUTES.storePlaceOrders.root)
  return res.data['hydra:member']
}

export const useStorePlaceOrders = () => useQuery('getStores', fetchStorePlaceOrders)
