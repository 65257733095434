import { GetOrderByIdResponse } from 'api/orders'
import PreparationCardDisplay from 'app/OrderDetails/components/PreparationCard/PreparationCardDisplay'
import PreparationCardSection from 'app/OrderDetails/components/PreparationCard/PreparationCardSection'
import PreparationProductDisplay from 'app/OrderDetails/components/PreparationCard/PreparationProductDisplay'
import PreparationProductSection from 'app/OrderDetails/components/PreparationCard/PreparationProductSection'
import ShippingCard from 'app/OrderDetails/components/ShippingCard/ShippingCard'
import { useOrderContext } from 'app/OrderDetails/helper'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import CanAccess from 'features/Permissions/CanAccess'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { RoleAction } from 'types/playInApiInterfaces'
import { PreparationTabCard, PreparationTabCardContainer } from './PreparationTab.style'

interface PreparationTabProps {
  order?: GetOrderByIdResponse
}

export const PreparationTab = ({ order }: PreparationTabProps) => {
  const { t } = useTranslation()
  const hasCard = _.some(order?.entries, { product: { '@type': 'CardProduct' } })
  const hasProduct = _.some(order?.entries, { product: { '@type': 'SealedProduct' } })
  const { inMyStoresOrder } = useOrderContext()
  return (
    <PreparationTabCardContainer>
      <PreparationTabCard>
        {hasCard ? (
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_PREPARATION_CARDS]}
            allowedComponent={<PreparationCardSection order={order} />}
            deniedComponent={<PreparationCardDisplay order={order} />}
            deniedExtraCondition={!inMyStoresOrder}
          />
        ) : (
          <>
            <Title2>{t('page.order.detail.tab.prepare.cardSectionTitle')}</Title2>
            <Text>{t('page.order.detail.tab.prepare.noCards')}</Text>
          </>
        )}
      </PreparationTabCard>
      <PreparationTabCard>
        {hasProduct ? (
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_PREPARATION]}
            allowedComponent={<PreparationProductSection order={order} />}
            deniedComponent={<PreparationProductDisplay order={order} />}
            deniedExtraCondition={!inMyStoresOrder}
          />
        ) : (
          <>
            <Title2>{t('page.order.detail.tab.prepare.productSectionTitle')}</Title2>
            <Text>{t('page.order.detail.tab.prepare.noProducts')}</Text>
          </>
        )}
      </PreparationTabCard>
      <PreparationTabCard>
        <ShippingCard order={order} />
      </PreparationTabCard>
    </PreparationTabCardContainer>
  )
}

export default PreparationTab
