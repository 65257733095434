import { deleteVoucher, patchVoucher, PatchVoucherBody, usePaginatedVouchers } from 'api/vouchers'
import { RowCell } from 'app/StockDetails/StockDetails.styles'
import Button from 'components/Button/Button'
import ApiResponseIcon from 'components/Icon/ApiResponseIcon'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import Cell from 'components/SortedTable/Cell'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { DeleteIcon } from 'constants/icons'
import { endOfDay } from 'date-fns'
import Listing, { Columns } from 'features/Listing/Listing'
import DeleteModal from 'features/Modals/DeleteModal'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldHide from 'features/Permissions/ShouldHide'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEventEmitter } from 'hooks/useEventEmitter'
import SingleInputForm from 'hooks/useSingleInputForm'
import _, { forEach } from 'lodash'
import { useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Customer, Voucher } from 'types/entities'
import { EventType } from 'types/events'
import { RoleAction } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { formatCalendarDate } from 'utils/dates'
import { DeleteFidelityWrapper } from '../CustomerList.style'

export interface Props {
  customer: Customer
  setCount: (count?: number) => void
  columns: Columns<Voucher>
}

const replaceColumns = (cols: Columns<Voucher>, updatedCols: Columns<Voucher>): Columns<Voucher> => {
  updatedCols.forEach((column) => {
    let index = cols.findIndex((e) => e.key === column.key)
    if (index) {
      cols.splice(index, 1, column)
    }
  })
  return cols
}

const countVouchersAmount = (vouchers: Voucher[]) => vouchers.reduce((prev, voucher) => prev + voucher.value!, 0)

export default function ActiveVouchers({ customer, setCount, columns }: Props) {
  const { checkMatch } = useCheckMatch()
  const permissions = [RoleAction.ROLE_ACTION_CUSTOMER_VOUCHER]

  const { t } = useTranslation()
  const [updatedAmounts, setUpdatedAmounts] = useState<number[]>([])
  const [updatedExpirationDates, setUpdatedExpirationDates] = useState<number[]>([])
  const [currentUpdate, setCurrentUpdate] = useState<string>()
  const queryParams = { customer: customer['@id'], active: true, 'order[expiresAt]': SortingDirection.Asc }
  const {
    data: vouchers,
    refetch,
    totalItems,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage
  } = usePaginatedVouchers(queryParams, {
    onSuccess: (data) => {
      setCount(countVouchersAmount(data))
    }
  })

  const [deleteList, setDeleteList] = useState<Array<number | undefined>>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const { useListener } = useEventEmitter()
  useListener(EventType.AddCustomerVoucher, () => refetch())

  const queryClient = useQueryClient()

  const {
    mutate: editVoucherMutation,
    isLoading: isEditLoading,
    isSuccess,
    isError
  } = useMutation(({ id, body }: { body: PatchVoucherBody; id: number }) => patchVoucher(body, id), {
    onSuccess: (updatedVoucher) => {
      const prev = queryClient.getQueryData(['vouchers', queryParams]) as any
      const pages = prev.pages.map((page) => ({
        ...page,
        data: page.data.map((voucher) => {
          if (voucher.id === updatedVoucher.data.id) return updatedVoucher.data
          return voucher
        })
      }))
      queryClient.setQueryData(['vouchers', queryParams], { pages })
    }
  })

  const handleEdit = (id: number, body: PatchVoucherBody, type: 'date' | 'amount') => {
    setCurrentUpdate(`${type}-${id}`)
    if (type === 'date') setUpdatedExpirationDates((prev) => [...prev, ...[id]])
    if (type === 'amount') setUpdatedAmounts((prev) => [...prev, ...[id]])
    editVoucherMutation({ body, id })
  }

  const updatedColumns: Columns<Voucher> = [
    {
      key: 'expiration',
      name: t('common.label.expiration'),
      decorator: (voucher: Voucher) => (
        <RowCell>
          <CanAccess
            permissions={permissions}
            allowedComponent={
              <SingleInputForm
                type="date"
                defaultValue={voucher.expiresAt}
                onSubmit={async ({ inputValue }) =>
                  handleEdit(
                    voucher.id!,
                    {
                      expiresAt: endOfDay(new Date(inputValue)).toLocaleDateString('en-EN')
                    },
                    'date'
                  )
                }
              />
            }
            deniedComponent={<Text>{formatCalendarDate(voucher.expiresAt)}</Text>}
          />

          <ApiResponseIcon
            showIcon={updatedExpirationDates.includes(voucher.id!)}
            isLoading={currentUpdate === `date-${voucher.id}` && isEditLoading}
            isSuccess={isSuccess}
            isError={isError}
          />
        </RowCell>
      )
    },
    {
      key: 'amount',
      name: t('common.label.amount'),
      decorator: (voucher: Voucher) => (
        <RowCell>
          <CanAccess
            permissions={permissions}
            allowedComponent={
              <SingleInputForm
                type="formattedNumber"
                decimalScale={2}
                suffix=" €"
                placeholder="0.00 €"
                defaultValue={voucher.value}
                onSubmit={async ({ inputValue }) =>
                  handleEdit(
                    voucher.id!,
                    {
                      value: inputValue
                    },
                    'amount'
                  )
                }
              />
            }
            deniedComponent={<TextNumber decimalScale={2} suffix=" €" value={voucher.value} />}
          />

          <ApiResponseIcon
            showIcon={updatedAmounts.includes(voucher.id!)}
            isLoading={currentUpdate === `amount-${voucher.id}` && isEditLoading}
            isSuccess={isSuccess}
            isError={isError}
          />
        </RowCell>
      )
    }
  ]

  columns = replaceColumns(
    checkMatch(permissions)
      ? [
          {
            key: 'checkbox',
            decorator: (voucher: Voucher) => (
              <Cell>
                <Checkbox
                  className={deleteList.includes(voucher.id) ? 'checked' : ''}
                  onClick={() => setDeleteList((prev) => _.xor(prev, [voucher.id]))}
                />
              </Cell>
            )
          },
          ...columns
        ]
      : columns,
    updatedColumns
  )

  const { mutate: deleteVoucherMutation, isLoading } = useMutation((id: number) => deleteVoucher(id), {
    onSuccess: () => refetch()
  })
  let isDeletingAll = deleteList.length === vouchers.length

  const handleToggleAll = () => setDeleteList(isDeletingAll ? [] : vouchers.map((point) => point.id))

  const handleDelete = async () => {
    await forEach(deleteList, (toDelete) => {
      deleteVoucherMutation(toDelete!)
    })
  }

  return (
    <>
      <ShouldHide permissions={permissions}>
        <DeleteFidelityWrapper>
          {vouchers && vouchers.length > 0 && (
            <>
              <Checkbox className={isDeletingAll ? 'checked' : ''} onClick={handleToggleAll} />

              <Button
                icon={DeleteIcon}
                variant="white"
                shape="circle"
                onClick={() => setDeleteModalOpen(true)}
                disabled={!(deleteList.length > 0)}
                isLoading={isLoading}
              />
            </>
          )}
        </DeleteFidelityWrapper>
      </ShouldHide>
      <Listing
        data={vouchers}
        totalCount={totalItems}
        emptyText={t('page.customer.details.vouchers.active.emptyText')}
        countText={t('page.customer.details.vouchers.active.countText', {
          current: vouchers?.length ?? 0,
          total: totalItems ?? 0
        })}
        columns={columns}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
      />
      <DeleteModal
        callback={handleDelete}
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false)
        }}
        title={t('page.customer.details.vouchers.active.confirmDelete')}
      />
    </>
  )
}
