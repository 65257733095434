import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { Api } from 'types/playInApiInterfaces'
import playInApi from './basePlayInApi'

export type PostCollectionEntryBody = Api.PostProductCollectionEntries.RequestBody
type PostCollectionEntryResponse = Api.PostProductCollectionEntries.ResponseBody
export const postCollectionEntry = async (body: PostCollectionEntryBody) => {
  const res = await playInApi.post<PostCollectionEntryBody, AxiosResponse<PostCollectionEntryResponse>>(
    API_ROUTES.collectionEntries.root,
    body
  )
  return res.data
}

export type PatchCollectionEntryBody = Api.PatchProductCollectionEntriesId.RequestBody
export type PatchCollectionEntryResponse = Api.PatchProductCollectionEntriesId.ResponseBody
export const patchCollectionEntry = async (id: string, body: PatchCollectionEntryBody) => {
  const res = await playInApi.patch<PatchCollectionEntryBody, AxiosResponse<PatchCollectionEntryResponse>>(
    `${API_ROUTES.collectionEntries.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export const deleteCollectionEntry = async (id: string) =>
  await playInApi.delete(`${API_ROUTES.collectionEntries.root}/${id}`)
