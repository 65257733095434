import { yupResolver } from '@hookform/resolvers/yup'
import { patchStore, PatchStoreBody } from 'api/stores'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StoreDetails } from 'types/entities'
import * as yup from 'yup'
import { DefaultCashboxFormLayout, DefaultCashboxFormWrapper } from '../StoreManageTab.style'

interface Props {
  store: StoreDetails
}
const schema = yup.object({
  defaultCashboxValue: yup.number().required()
})
type FloatInputs = yup.InferType<typeof schema>

export default function DefaultCashboxForm({ store }: Props) {
  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FloatInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      defaultCashboxValue: store.defaultCashboxValue
    }
  })

  const { mutate, isLoading } = useMutation((body: PatchStoreBody) => patchStore(store.id!, body))

  const onSubmit = (formData: FloatInputs) => {
    mutate(formData)
  }

  return (
    <DefaultCashboxFormLayout onSubmit={handleSubmit(onSubmit)}>
      <DefaultCashboxFormWrapper>
        <InputFormattedNumber
          control={control}
          id="defaultCashboxValue"
          suffix={`\u00a0€`}
          decimalScale={2}
          label={t('page.stores.details.storeManage.cashbox.labels.float')}
          className={classNames({ 'is-invalid': errors.defaultCashboxValue })}
        />
        <Button buttonType="submit" shape="circle" icon={SaveIcon} isLoading={isLoading} />
      </DefaultCashboxFormWrapper>
      <BasicFieldValidationError error={errors.defaultCashboxValue} message={t('form.validation.required.value')} />
    </DefaultCashboxFormLayout>
  )
}
