import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { ButtonUpdate, FormContainer } from '../../CollaboratorAccountOptions/CollaboratorAccountOptions.styles'
import { UpdateCredentialsInputs } from '../CollaboratorAccountOptions'

interface Props {
  onSubmit: (formData: UpdateCredentialsInputs) => void
  isLoading?: boolean
}

const schema = yup
  .object()
  .shape({
    oldPassword: yup.string().required(),
    newPassword: yup.string().required(),
    checkPassword: yup.string().required().oneOf([yup.ref('newPassword'), null])
  })

export default function UpdateCredentialsForm({ onSubmit, isLoading }: Props) {
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm<UpdateCredentialsInputs>({
    resolver: yupResolver(schema)
  })

  const handleForm = (formData: UpdateCredentialsInputs) => {
    onSubmit(formData)
  }

  return (
    <CardLayout>
      <Title2>{t('page.collaborator.accountOptions.titlePassword')}</Title2>
      <FormContainer onSubmit={handleSubmit(handleForm)}>
        <div>
          <Input
            id="oldPassword"
            label={t('page.collaborator.accountOptions.oldPassword')}
            register={register}
            type="password"
            className={classNames({ 'is-invalid': errors.oldPassword })}
          />
          <BasicFieldValidationError error={errors.oldPassword} message={t('form.required.field')} />
        </div>

        <div>
          <Input
            id="newPassword"
            label={t('page.collaborator.accountOptions.newPassword')}
            register={register}
            type="password"
            className={classNames({ 'is-invalid': errors.newPassword })}
          />
          <BasicFieldValidationError error={errors.newPassword} message={t('form.required.field')} />
        </div>

        <div>
          <Input
            id="checkPassword"
            label={t('page.collaborator.accountOptions.newPassword')}
            register={register}
            type="password"
            className={classNames({ 'is-invalid': errors.checkPassword })}
            />
          <BasicFieldValidationError error={errors.checkPassword} message={t('form.required.sameAs')} />
        </div>

        <ButtonUpdate buttonType="submit" isLoading={isLoading}>            
          {t('page.collaborator.accountOptions.actionPassword')}
        </ButtonUpdate>
      </FormContainer>
    </CardLayout>
  )
}
