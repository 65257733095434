import StatusBlock, { StatusTypes } from 'components/StatusBlock/StatusBlock'
import { StatusHeader } from 'components/StatusBlock/StatusBlock.styles'
import { DoodleOneIcon, DoodleThreeIcon, DoodleTwoIcon } from 'constants/doodleIcons'
import { useRef } from 'react'

// Copy/paste from src/app/OrderDetails/helper.tsx
// Waiting for event detail page to see what can be definitly removed
export type EventStep = {
  name: string
  status?: StatusTypes
  author?: string | null
  date?: string | null
}

type StatusStep = {
  open: EventStep
  discount: EventStep
  closed: EventStep
}
export default function EventStatusHeader({ statusStep }: { statusStep: StatusStep }) {
  const statusHeaderRef = useRef(null)

  const { open: openStep, discount: voucherStep, closed: closedStep } = statusStep

  return (
    <StatusHeader ref={statusHeaderRef}>
      <StatusBlock
        type={openStep.status || StatusTypes.pending}
        icon={DoodleOneIcon}
        title={openStep.name}
        containerRef={statusHeaderRef}
        boldText={openStep.author}
        lightText={openStep.date}
      />
      <StatusBlock
        type={voucherStep.status || StatusTypes.pending}
        icon={DoodleTwoIcon}
        title={voucherStep.name}
        containerRef={statusHeaderRef}
        boldText={voucherStep.author}
        lightText={voucherStep.date}
      />
      <StatusBlock
        type={closedStep.status || StatusTypes.pending}
        icon={DoodleThreeIcon}
        title={closedStep.name}
        containerRef={statusHeaderRef}
        boldText={closedStep.author}
        lightText={closedStep.date}
      />
    </StatusHeader>
  )
}
