import Alert from '@mui/material/Alert'
import styled from 'styled-components'
import { StyledSvg } from '../Svg/Svg.style'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const StyledAlert = styled(Alert)`
  flex-grow: 1;
  border-radius: 0 !important;
  padding: 1.6rem !important;
  display: grid;
  row-gap: 1rem;
  background-color: ${(props) =>
    // @ts-ignore
    props.theme.backgroundColors[props.severity]} !important;

  & .MuiAlert-message {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 0;
  }

  & .MuiAlert-action {
    display: block;
    margin: 0;
    padding: 0;

    .MuiSvgIcon-root {
      height: 2rem;
      width: 2rem;
    }
    .MuiButtonBase-root {
      padding: 0rem;
    }
  }
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;

  ${StyledSvg} {
    margin-right: 1rem;
  }
`

export const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 1.6rem;
  line-height: 2rem;

  @media screen and (max-width: ${(props) => props.theme.screenWidth.tabletScreen}) {
    font-size: 1.4rem;
  }
`
export const Text = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 1.4rem;
  line-height: 1.6rem;

  @media screen and (max-width: ${(props) => props.theme.screenWidth.tabletScreen}) {
    font-size: 1.3rem;
  }
`
export const SubText = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.4rem;
  line-height: 1.6rem;
`

export const ErrorsList = styled.div`
  display: grid;
  gap: 1rem;
`
