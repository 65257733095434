import { Collaborator } from 'types/entities'

export enum LocalStorageKeys {
  userData = 'user-data',
  playinToken = 'playin-token',
  cashboxAccountDetails = 'cashbox-account-details',
  cashboxFundedDetails = 'cashbox-funded-details',
  defaultStore = 'defaultStore'
}

//########## AUTH ##########

export const getUserData = () => {
  const data = localStorage.getItem(LocalStorageKeys.userData)

  if (!data) {
    return undefined
  }

  return JSON.parse(data) as Collaborator
}

export const setUserData = (me: Collaborator | void) =>
  localStorage.setItem(LocalStorageKeys.userData, JSON.stringify(me))

export const getJWT = () => localStorage.getItem(LocalStorageKeys.playinToken)

export const setJWT = (jwt: string) => localStorage.setItem(LocalStorageKeys.playinToken, jwt)

//########## CASHBOX ##########

export type CashboxesCountDetails = { [key in string]: number }

export const getCashboxAccountDetails = () => {
  let data = localStorage.getItem(LocalStorageKeys.cashboxAccountDetails)
  if (!data) return undefined
  return JSON.parse(data) as CashboxesCountDetails
}
export const setCashboxAccountDetails = (details: CashboxesCountDetails | undefined) =>
  localStorage.setItem(LocalStorageKeys.cashboxAccountDetails, JSON.stringify(details))

export const removeCashboxAccountDetails = () => localStorage.removeItem(LocalStorageKeys.cashboxAccountDetails)

export const getCashboxFundedDetails = () => {
  let data = localStorage.getItem(LocalStorageKeys.cashboxFundedDetails)
  if (!data || data === 'undefined') return undefined
  return JSON.parse(data) as CashboxesCountDetails
}
export const setCashboxFundedDetails = (details: CashboxesCountDetails | undefined) => {
  localStorage.setItem(LocalStorageKeys.cashboxFundedDetails, JSON.stringify(details))
}

const removeCashboxFundedDetails = () => localStorage.removeItem(LocalStorageKeys.cashboxFundedDetails)

export const clearCashboxDetails = () => {
  removeCashboxAccountDetails()
  removeCashboxFundedDetails()
}
