import Image from 'components/Image/Image'
import Svg from 'components/Svg/Svg'
import styled from 'styled-components'

export const ProductItemWrapper = styled.div`
  display: flex;
  gap: 1rem;
  position: relative;
  padding: 2rem;
  padding-bottom: 0.5rem;
  flex-direction: column;
`

export const ImageContainer = styled.div`
  display: flex;
  min-width: 12.5rem;
  justify-content: center;
`

export const ImageProductItem = styled(Image)`
  max-width: 125px;
  height: 125px
`

export const ProductNameWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 12.5rem;
  gap: 1rem;
  flex: 1;
  justify-content: space-between;
`
export const DeleteProductButton = styled(Svg)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`
