import { FetchCollectionParams, FetchPaginatedCollectionResponse, usePaginatedProductCollections } from 'api/collection'
import { OldIcon } from 'app/ProductManagement/ProductManagement.style'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import ClickableCell from 'components/SortedTable/ClickableCell'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { CheckIcon, CrossIcon } from 'constants/icons'
import Listing, { Columns } from 'features/Listing/Listing'
import LinkButton from 'features/Permissions/LinkButton'
import { RouteName } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Colors } from 'theme/theme'
import { OrderParam, SortingDirection } from 'types/sorting'
import { mergeFormQueryParams } from 'utils/queryParams'
import { CreateCollectionCard } from './CollectionManagement.style'
import SearchCollectionForm, { SearchCollectionInputs } from './SearchCollectionForm'

const defaultSort: OrderParam<FetchCollectionParams> = {
  sortedBy: 'name',
  direction: SortingDirection.Asc,
  queryParams: { 'order[name]': SortingDirection.Asc }
}
export default function CollectionManagemement() {
  const { t } = useTranslation()
  const [queryParams, setQueryParams] = useState<FetchCollectionParams>()
  const [orderParam, setOrderParam] = useState<OrderParam<FetchCollectionParams>>(defaultSort)

  const {
    data: productCollections,
    isLoading,
    isIdle,
    totalItems,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage
  } = usePaginatedProductCollections(
    ['fetchCollection', queryParams, orderParam?.queryParams],

    mergeFormQueryParams<FetchCollectionParams>({
      queryParams,
      orderParam: orderParam?.queryParams
    }),

    { enabled: !!queryParams }
  )

  const onSubmit = (formData: SearchCollectionInputs) => {
    setQueryParams(formData)
  }

  const columns: Columns<FetchPaginatedCollectionResponse, FetchCollectionParams> = [
    {
      key: 'id',
      name: t('common.label.id'),
      decorator: (collection) => (
        <ClickableCell route={RouteName.CollectionDetails} value={collection.id}>
          <Text color="secondary" fontWeight="light">
            {collection.id}
          </Text>
        </ClickableCell>
      )
    },
    {
      key: 'name',
      name: t('page.collection.manage.listing.labels.name'),
      sortable: true,
      orderQueryParam: 'order[name]',
      decorator: (collection) => (
        <ClickableCell route={RouteName.CollectionDetails} value={collection.id}>
          {collection.name}
        </ClickableCell>
      )
    },
    {
      key: 'quantity',
      name: t('page.collection.manage.listing.labels.quantity'),
      decorator: (collection) => (
        <ClickableCell route={RouteName.CollectionDetails} value={collection.id}>
          {collection.productsCount}
        </ClickableCell>
      )
    },
    {
      key: 'boardgamePage',
      name: t('page.collection.manage.listing.labels.boardgamePage'),
      decorator: (collection) => {
        const icon = collection.onFamilyHome ? CheckIcon : CrossIcon
        const color: keyof Colors = collection.onFamilyHome ? 'success' : 'danger'
        return (
          <ClickableCell route={RouteName.CollectionDetails} value={collection.id}>
            <OldIcon svg={icon} color={color} />
          </ClickableCell>
        )
      }
    },
    {
      key: 'collectionPage',
      name: t('page.collection.manage.listing.labels.collectionPage'),
      decorator: (collection) => {
        const icon = collection.onCollectionHome ? CheckIcon : CrossIcon
        const color: keyof Colors = collection.onCollectionHome ? 'success' : 'danger'
        return (
          <ClickableCell route={RouteName.CollectionDetails} value={collection.id}>
            <OldIcon svg={icon} color={color} />
          </ClickableCell>
        )
      }
    },
    {
      key: 'dedicatedPage',
      name: t('page.collection.manage.listing.labels.dedicatedPage'),
      decorator: (collection) => {
        const icon = collection.dedicatedPage ? CheckIcon : CrossIcon
        const color: keyof Colors = collection.dedicatedPage ? 'success' : 'danger'
        return (
          <ClickableCell route={RouteName.CollectionDetails} value={collection.id}>
            <OldIcon svg={icon} color={color} />
          </ClickableCell>
        )
      }
    },
    {
      key: 'active',
      name: t('page.collection.manage.labels.state'),
      decorator: (collection) => (
        <ClickableCell route={RouteName.CollectionDetails} value={collection.id}>
          <Text
            fontStyle="italic"
            color={collection.active ? 'black' : 'danger'}
            fontWeight={collection.active ? 'regular' : 'medium'}
          >
            {collection.active
              ? t('page.collection.manage.labels.active')
              : t('page.collection.manage.labels.inactive')}
          </Text>
        </ClickableCell>
      )
    }
  ]

  return (
    <Section>
      <MainTitle title={t('page.collection.manage.title')} />
      <CreateCollectionCard>
        <Title2>{t('page.collection.manage.createTitle')}</Title2>
        <LinkButton size="app" route={RouteName.CollectionCreate}>
          {t('page.collection.manage.createBtn')}
        </LinkButton>
      </CreateCollectionCard>
      <SearchCollectionForm onSubmit={onSubmit} isFormLoading={isLoading} />
      {!isIdle && (
        <CardLayout>
          <Listing
            data={productCollections}
            columns={columns}
            title={t('page.collection.manage.listing.title', {
              count: totalItems ?? 0
            })}
            countText={t('page.collection.manage.listing.countText', {
              current: productCollections?.length,
              total: totalItems ?? 0
            })}
            defaultSort={defaultSort}
            onOrderChanged={setOrderParam}
            totalCount={totalItems}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            emptyText={t('page.collection.manage.listing.empty')}
          />
        </CardLayout>
      )}
    </Section>
  )
}
