import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import { SearchIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { hasOneOfFieldsMethod } from 'utils/formHelper'
import * as yup from 'yup'
import { EmailInputContainer, RegularInputContainer, SearchCustomerFormContainer } from './CustomerEditModal.style'

interface Props {
  onSearch: (params: CustomerSearchQueryInputs) => void
  isLoading?: boolean
}

const schema = yup
  .object()
  .shape({
    firstname: yup.string().trim(),
    lastname: yup.string().trim(),
    email: yup.string().trim()
  })
  .test(hasOneOfFieldsMethod())

export type CustomerSearchQueryInputs = {
  firstname?: string
  lastname?: string
  email?: string
}

export default function SearchCustomerForm({ onSearch, isLoading }: Props) {
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm<CustomerSearchQueryInputs>({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  })

  return (
    <form onSubmit={handleSubmit((data) => onSearch(data))}>
      <SearchCustomerFormContainer>
        <RegularInputContainer>
          <Input register={register} id="lastname" label={t('common.label.lastName')} autofocus={true} />
        </RegularInputContainer>
        <RegularInputContainer>
          <Input register={register} id="firstname" label={t('common.label.firstName')} />
        </RegularInputContainer>
        <EmailInputContainer>
          <Input register={register} id="email" label={t('common.label.email')} />
        </EmailInputContainer>
        <Button buttonType="submit" icon={SearchIcon} shape="circle" isLoading={isLoading} />
      </SearchCustomerFormContainer>
      <BasicFieldValidationError error={errors['oneOf']} message={t('form.required.oneOf')} />
    </form>
  )
}
