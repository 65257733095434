import { ComponentType } from 'react'
import { TextColors } from 'theme/theme'
import { Icon, IconSvg } from './BubbleIcon.styles'

interface Props {
  svg: ComponentType
  backgroundColor: keyof TextColors
  iconSize?: string
}

const BubbleIcon = ({ backgroundColor, svg, iconSize = '1.8rem' }: Props) => (
  <Icon backgroundColor={backgroundColor}>
    <IconSvg svg={svg} size={iconSize} />
  </Icon>
)

export default BubbleIcon
