import Select from 'components/Select/Select'
import styled from 'styled-components'

export const PaymentModeCardFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem 5rem;
`

export const LabelWrapSelect = styled(Select)`
  & > label {
    white-space: pre-wrap;
  }
` as typeof Select
