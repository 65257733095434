import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import styled from 'styled-components'

export const ButtonsLayout = styled.div`
  display: flex;
  gap: 2rem 5rem;
  flex-wrap: wrap;

  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    justify-content: center;
  }
`

export const ButtonsContainer = styled(CardLayout)`
  display: grid;
  grid-template-columns: 1fr;
`

export const ActionButton = styled(Button)`
  width: 20rem;
`
export const WideActionButton = styled(Button)`
  width: 30rem;
`
