import { yupResolver } from '@hookform/resolvers/yup'
import { useMutateCollaborator } from 'api/collaborators'
import Button from 'components/Button/Button'
import MultipleSelect from 'components/Select/MultipleSelect'
import { MoreIcon } from 'constants/icons'
import { usePermissionsByCollaborator } from 'hooks/entityHooks/permissionsHooks'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Collaborator } from 'types/entities'
import * as yup from 'yup'
import { AddRoleFormWrapper } from '../CollaboratorDetails.style'

interface Props {
  collaborator: Collaborator
}

const schema = yup.object({
  roleActions: yup.array().of(yup.string().required()).required()
})

interface AddRoleActionInputs extends yup.InferType<typeof schema> {}

export default function AddRoleActionForm({ collaborator }: Props) {
  const { t } = useTranslation()
  const { availableRoleActionsOptions } = usePermissionsByCollaborator(collaborator)
  const { handleSubmit, control, reset } = useForm<AddRoleActionInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      roleActions: []
    }
  })

  const { mutate, isLoading } = useMutateCollaborator(collaborator.id!, () => reset())

  const onSubmit = async (formData: AddRoleActionInputs) => {
    const { roleActions } = formData
    mutate({
      additionalActions: _.map(collaborator.additionalActions, (e) => e['@id']!).concat(roleActions)
    })
  }
  return (
    <AddRoleFormWrapper onSubmit={handleSubmit(onSubmit)}>
      <MultipleSelect
        control={control}
        id="roleActions"
        options={availableRoleActionsOptions}
        label={t('page.collaborator.details.roleActions.labels.addRole')}
        placeholder={t('page.collaborator.details.roleActions.placeholders.addRole')}
        disabled={!availableRoleActionsOptions.length}
      />
      <Button buttonType="submit" shape="circle" icon={MoreIcon} iconColor="white" isLoading={isLoading} />
    </AddRoleFormWrapper>
  )
}
