import { yupResolver } from '@hookform/resolvers/yup'
import { getRetailersUri } from 'api/retailers'
import { patchStock, PatchStockBody, stockByIdQueryKey } from 'api/stock'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker/DatePicker'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import Input from 'components/Input/Input'
import InputAutoComplete from 'components/InputAutoComplete/InputAutoComplete'
import InputRadio from 'components/InputRadio/InputRadio'
import { LabelStyled } from 'components/Layouts/InputLayout/InputLayout.styles'
import Select from 'components/Select/Select'
import Textarea from 'components/Textarea/Textarea'
import { Title2 } from 'components/Title/Title.styles'
import { SaveIcon } from 'constants/icons'
import { endOfToday, isBefore } from 'date-fns'
import { useMutation } from 'hooks/useAxiosMutation'
import { useEventEmitter } from 'hooks/useEventEmitter'
import { useMyStoresOptions, usePaymentModeFilters } from 'hooks/useSelectOptions'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { UnmodifiableStockStatus } from 'types/enums'
import { EventType } from 'types/events'
import { Stock, StockStockReadItemStockRead } from 'types/playInApiInterfaces'
import * as yup from 'yup'
import { GridCardLayout } from '../StockDetails.styles'
import {
  ErrorDisplay,
  FieldsLayout,
  PaymentFieldLayout,
  PaymentFieldsLayout,
  SubmitContainer
} from './EditStockForm.styles'
import { DueDateEnTooltip, DueDateFrTooltip, ReceptionDateTooltip } from './FormTooltips'

const schema = yup.object().shape({
  store: yup.string().trim().required(),
  retailer: yup.string().trim().required(),
  paymentReceived: yup.boolean(),
  comment: yup.string().trim(),
  paymentMode: yup
    .string()
    .trim()
    .test('paymentReceived', 'form.validation.required.paymentMode', (value, context) => {
      if (context.parent.paymentReceived && value === '') return false
      return true
    }),
  restockedAt: yup
    .string()
    .transform((value) => (value === '' ? null : value))
    .nullable(true)
    .test('needFutureDate', (restockedAt, context) => {
      if (context.parent.productReceived === false && restockedAt && isBefore(new Date(restockedAt), endOfToday()))
        return false
      return true
    }),
  approximateRestockDate: yup.string().trim(),
  approximateRestockDateEn: yup.string().trim(),
  productReceived: yup.boolean(),
  billDate: yup
    .string()
    .transform((value) => (value === '' ? null : value))
    .nullable(true),
  billNumber: yup.string().trim()
})

interface StockFormData {
  store: string
  retailer?: string
  paymentReceived: boolean
  comment?: string
  paymentMode?: string
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  productReceived: boolean
  billDate?: string | null
  billNumber?: string
}

interface Props {
  stockId: string
  stock: StockStockReadItemStockRead
}

const EditStockForm = ({ stockId, stock }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const {
    handleSubmit,
    register,
    control,
    setValue,
    trigger,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setError
  } = useForm<StockFormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur'
  })

  useEffect(() => {
    reset({
      approximateRestockDate: stock?.approximateRestockDate ?? undefined,
      approximateRestockDateEn: stock?.approximateRestockDateEn ?? undefined,
      comment: stock?.comment ?? undefined,
      paymentMode: stock?.paymentMode?.['@id'] ?? '',
      paymentReceived: stock?.paymentReceived ?? false,
      productReceived: stock?.productReceived ?? false,
      restockedAt: stock?.restockedAt || null,
      retailer: stock?.retailer?.['@id'],
      store: stock?.store['@id'],
      billDate: stock?.billDate || null,
      billNumber: stock?.billNumber ?? undefined
    })
  }, [stock, reset])

  const { mutate: putStockMutation, isLoading } = useMutation((body: PatchStockBody) => patchStock(stockId, body), {
    onSuccess: (updatedStock) => {
      const prev = queryClient.getQueryData<Stock>(stockByIdQueryKey(stockId))
      queryClient.setQueryData(stockByIdQueryKey(stockId), {
        ...prev,
        ...updatedStock,
        // if those fields are reseted, patch will not return them,
        // So we manually set them at null to update the form
        billDate: updatedStock.billDate || null,
        restockedAt: updatedStock.restockedAt || null,
        paymentMode: updatedStock.paymentMode || null,
        approximateRestockDate: updatedStock.approximateRestockDate || null,
        approximateRestockDateEn: updatedStock.approximateRestockDateEn || null,
        comment: updatedStock.comment || null
      })
    }
  })

  const onSubmit = async (formData: StockFormData) => {
    putStockMutation({
      store: formData.store,
      approximateRestockDate: formData.approximateRestockDate,
      approximateRestockDateEn: formData.approximateRestockDateEn,
      comment: formData.comment,
      paymentReceived: formData.paymentReceived,
      productReceived: formData.productReceived,
      restockedAt: formData.restockedAt || null,
      retailer: formData.retailer,
      billDate: formData.billDate || null,
      billNumber: formData.billNumber,
      paymentMode: formData.paymentMode || null
    })
  }

  const { myStoresOptions } = useMyStoresOptions()

  const { paymentModeOptions } = usePaymentModeFilters({ availableInStock: true })

  const cantEditStock = UnmodifiableStockStatus.includes(stock.status!)

  const { useListener } = useEventEmitter()

  useListener(EventType.PatchStockError, (errors) => {
    // We're only interested in restock error
    let restockError = errors.find((error) => error.key === 'restockedAt')
    if (!!restockError) setError('restockedAt', { message: 'required' })
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridCardLayout>
        <div>
          <Title2>{t('page.stock.detail.buyers')}</Title2>
          <FieldsLayout>
            <LabelStyled>{t('common.label.mandatory.buyer')}</LabelStyled>
            <Select id="store" control={control} options={myStoresOptions} layout="row" />
            <LabelStyled>{t('common.label.mandatory.retailer')}</LabelStyled>
            <InputAutoComplete
              className={errors.retailer ? 'is-invalid' : ''}
              layout="row"
              defaultLabel={stock?.retailer?.fullName ?? undefined}
              labelKey="fullName"
              valueKey="@id"
              getUrlFromSearch={(search) => getRetailersUri({ search })}
              id="retailer"
              control={control}
              isTouched={touchedFields.retailer}
            />
          </FieldsLayout>
        </div>
        <div>
          <Title2>{t('page.stock.detail.reception')}</Title2>
          <FieldsLayout>
            <LabelStyled>
              {t('common.label.receptionDate')}
              <ReceptionDateTooltip />
            </LabelStyled>
            <DatePicker
              layout="row"
              id="restockedAt"
              register={register}
              defaultValue={getValues('restockedAt') ?? null}
              setValue={setValue}
              trigger={trigger}
              className={errors.restockedAt ? 'is-invalid' : ''}
            />
            {errors.restockedAt?.type === 'needFutureDate' && (
              <ErrorDisplay>
                <BasicFieldValidationError
                  error={errors.restockedAt}
                  messages={{
                    needFutureDate: t('form.validation.format.dates.futureDate')
                  }}
                />
              </ErrorDisplay>
            )}

            <LabelStyled>{t('common.label.productReceived')}</LabelStyled>
            <InputRadio
              className={errors.productReceived ? 'is-invalid' : ''}
              id="productReceived"
              control={control}
              layout="row"
              disabled={stock?.entries?.length === 0}
            />
          </FieldsLayout>
        </div>
        <div>
          <Title2>{t('page.stock.detail.expectedReception')}</Title2>
          <FieldsLayout>
            <LabelStyled>
              {t('common.label.dueDateFr')} <DueDateFrTooltip />
            </LabelStyled>
            <Input id="approximateRestockDate" register={register} layout="row" />
            <LabelStyled>
              {t('common.label.dueDateEn')} <DueDateEnTooltip />
            </LabelStyled>

            <Input id="approximateRestockDateEn" register={register} layout="row" />
          </FieldsLayout>
        </div>
        <div>
          <Title2>{t('page.stock.detail.payement')}</Title2>
          <PaymentFieldsLayout>
            <div>
              <PaymentFieldLayout>
                <LabelStyled>{t('common.label.paymentMethod')}</LabelStyled>
                <Select
                  id="paymentMode"
                  control={control}
                  layout="row"
                  options={paymentModeOptions}
                  className={errors.paymentMode ? 'is-invalid' : ''}
                  placeholder={t('common.select.defaultOptions.paymentMode')}
                  enableUnselect={true}
                />
              </PaymentFieldLayout>
              <BasicFieldValidationError
                error={errors.paymentMode}
                message={t('form.validation.required.paymentMode')}
              />
            </div>
            <div>
              <PaymentFieldLayout>
                <LabelStyled>{t('common.label.paymentReceived.label')}</LabelStyled>
                <InputRadio
                  className={errors.paymentReceived ? 'is-invalid' : ''}
                  id="paymentReceived"
                  control={control}
                  layout="row"
                  labelAccept={t('common.label.paymentReceived.yes')}
                  labelDecline={t('common.label.paymentReceived.no')}
                />
              </PaymentFieldLayout>
            </div>
          </PaymentFieldsLayout>
        </div>
        <div>
          <Title2>{t('page.stock.detail.invoices')}</Title2>
          <FieldsLayout>
            <LabelStyled>{t('common.label.invoiceId')}</LabelStyled>
            <Input id="billNumber" register={register} layout="row" />
            <LabelStyled>{t('common.label.invoiceDate')}</LabelStyled>
            <DatePicker
              layout="row"
              id="billDate"
              register={register}
              defaultValue={getValues('billDate') ?? null}
              setValue={setValue}
              trigger={trigger}
            />
          </FieldsLayout>
        </div>
        <div>
          <Title2>{t('page.stock.detail.comment')}</Title2>
          <Textarea id="comment" register={register} layout="row" rows={4} />
        </div>
        <SubmitContainer>
          <Button buttonType="submit" shape="circle" icon={SaveIcon} disabled={cantEditStock} isLoading={isLoading} />
        </SubmitContainer>
      </GridCardLayout>
    </form>
  )
}

export default EditStockForm
