import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import SortedTable from 'components/SortedTable/SortedTable'
import { StyledCell } from 'components/SortedTable/SortedTable.styles'
import { Text } from 'components/Text/Text.styles'
import SingleInputForm from 'hooks/useSingleInputForm'
import styled from 'styled-components'

export const GridCardLayout = styled(CardLayout)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5rem;
  grid-row-gap: 3.5rem;

  @media screen and (${(props) => props.theme.screenWidth.minLargeScreen}) {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 5rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    grid-template-columns: 350px;
    grid-row-gap: 2rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxMobileScreen}) {
    grid-template-columns: 1fr;
  }
`

export const GridActionsLayout = styled(CardLayout)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const ActionCellContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`

export const ActionLabel = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) and (${(props) =>
      props.theme.screenWidth.minLaptopScreen}) {
    ${Text} {
      height: 3.4rem;
    }
  }
  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    height: auto;
  }
`

export const SmallCardLayout = styled(CardLayout)`
  width: fit-content;
`

export const StyledSortedTable = styled(SortedTable)`
  margin-bottom: 0;
  border-bottom: 0.2rem solid black;
  overflow-x: auto;
` as typeof SortedTable

export const TotalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${(props) => props.theme.backgroundColors.secondary2};
`

export const TotalItem = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4rem;
  align-items: center;

  width: 50%;
  min-height: 4.5rem;
  padding: 0.5rem 2rem;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.borderColors.secondary2};

  @media screen and (${(props) => props.theme.screenWidth.maxLaptopScreen}) {
    width: 100%;
  }
`

export const AlignRight = styled.div`
  justify-self: end;
  margin-right: 2.4rem;
`

export const ProductActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;

  & > *:not(:last-child) {
    margin-right: 2rem;
  }
`

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: 0.3rem;
  }
`
export const MediumSpan = styled.span`
  font-weight: ${(props) => props.theme.fontWeight.medium};
`

export const StatisticsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
`

export const MarginCardLayout = styled(CardLayout)`
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`

export const SplitCell = styled(StyledCell)`
  display: flex;
  align-items: center;
  height: calc(100% - 7px);
  border-right: 2px solid ${(props) => props.theme.colors.grey2};
`

export const RowCell = styled(StyledCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`

export const StyledSingleInputForm = styled(SingleInputForm)`
  width: auto;
`
export const TransferCard = styled(CardLayout)`
  display: grid;
  gap: 1rem;
`

export const PrintOrderSelectWrapper = styled.div`
  max-width: 300px;
  width: 100%;
`
