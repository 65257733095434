import Loader from 'components/Loader/Loader'
import styled from 'styled-components'

export const ProductImageContainer = styled.div`
  position: relative;
  width: auto;
  height: auto;
  padding: 1rem;
`

export const Image = styled.img`
  max-height: 20rem;
`

export const StyledLoader = styled(Loader)`
  padding: 1rem;
`