import { yupResolver } from '@hookform/resolvers/yup'
import { getCollaboratorsUri, usePaginatedCollaborators } from 'api/collaborators'
import Button from 'components/Button/Button'
import DatePicker from 'components/DatePicker/DatePicker'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Select, { Option } from 'components/Select/Select'
import { Title2 } from 'components/Title/Title.styles'
import { SearchIcon } from 'constants/icons'
import * as dateFns from 'date-fns'
import useAuth from 'hooks/useAuth'
import { useMyStoresOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RoleAction } from 'types/playInApiInterfaces'
import { defaultStoreAtom } from 'utils/jotaiAtom'
import * as yup from 'yup'
import { DateContainer, DisplayName, FormLayout } from './CashboxManagementForm.style'

const schema = yup.object().shape({
  createdBefore: yup.date().nullable(true),
  createdAfter: yup.date().nullable(true),
  store: yup.string().trim(),
  createdBy: yup.string().trim()
})

export type FormData = {
  createdBefore: Date | null
  createdAfter: Date | null
  store: string
  createdBy: string
}

interface Props {
  onSubmit: (data: FormData) => void
  isLoading: boolean
  canEdit: boolean
}

export default function CashboxManagementForm({ onSubmit, isLoading, canEdit }: Props) {
  const { t } = useTranslation()
  const [atomLocation] = useAtom(defaultStoreAtom)
  const { me } = useAuth()

  const [collaboratorOptions, setCollaboratorOptions] = useState<Option[]>([])

  const today = new Date()
  const startOfDay = dateFns.startOfDay(today)
  const { handleSubmit, register, trigger, setValue, watch, control, getValues } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      createdAfter: dateFns.subWeeks(startOfDay, 1),
      createdBefore: dateFns.subDays(startOfDay, 1),
      store: atomLocation?.['@id']
    }
  })

  const { myStoresOptions } = useMyStoresOptions()

  const selectedLocation = watch('store')

  const collaboratorQuery = getCollaboratorsUri({
    stores: selectedLocation,
    'collaboratorRoles.collaboratorActions.name': RoleAction.ROLE_ACTION_CASHBOXES_WRITE
  })
  const {
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isCollaboratorLoading
  } = usePaginatedCollaborators(collaboratorQuery, collaboratorQuery, {
    onSuccess: (collaborators) =>
      setCollaboratorOptions(
        collaborators.map((collaborator) => ({
          value: collaborator.id!,
          label: `${collaborator.lastname} ${collaborator.firstname}`
        }))
      ),
    enabled: canEdit
  })

  useEffect(() => {
    setValue('createdBy', '')
  }, [selectedLocation, setValue])

  return (
    <CardLayout>
      <Title2>{t('page.cashbox.manage.formTitle')}</Title2>
      <FormLayout onSubmit={handleSubmit(onSubmit)}>
        <DateContainer>
          <DatePicker
            id="createdAfter"
            trigger={trigger}
            register={register}
            setValue={setValue}
            label={t('common.label.fromIncluded')}
            defaultValue={getValues('createdAfter')}
            className="small-field"
          />
          <DatePicker
            id="createdBefore"
            trigger={trigger}
            register={register}
            setValue={setValue}
            label={t('common.label.toIncluded')}
            defaultValue={getValues('createdBefore')}
            className="small-field"
          />
        </DateContainer>

        <Select
          options={myStoresOptions}
          control={control}
          id="store"
          label={t('common.label.location')}
          className="field"
        />
        {canEdit ? (
          <Select
            options={collaboratorOptions}
            placeholder={t('common.select.defaultOptions.collaborators')}
            control={control}
            id="createdBy"
            label={t('common.label.collaborator')}
            enableUnselect={true}
            className="field"
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            isLoading={isCollaboratorLoading}
          />
        ) : (
          <DisplayName value={`${me?.firstname} ${me?.lastname}`} label={t('common.label.collaborator')} />
        )}
        <Button icon={SearchIcon} shape="circle" isLoading={isLoading} buttonType="submit" />
      </FormLayout>
    </CardLayout>
  )
}
