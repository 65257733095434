import { useMutateCollaborator } from 'api/collaborators'
import Button from 'components/Button/Button'
import { Option } from 'components/Select/MultipleSelect'
import Select from 'components/Select/Select'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { MoreIcon } from 'constants/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Collaborator } from 'types/entities'
import { StoreFormLayout } from '../CollaboratorDetails.style'

interface Props {
  collaborator: Collaborator
}

interface MainStoreInputs {
  mainStore: string
}
export default function MainStoreForm({ collaborator }: Props) {
  const { t } = useTranslation()
  const storeOptions: Option[] =
    collaborator.stores?.map((store) => ({
      label: store.shortName,
      value: store['@id'],
      disabled: store['@id'] === collaborator.mainStore?.['@id']
    })) ?? []

  const { control, handleSubmit } = useForm<MainStoreInputs>({
    defaultValues: {
      mainStore: collaborator.mainStore?.['@id']
    }
  })

  const { mutate, isLoading } = useMutateCollaborator(collaborator.id!)

  const onSubmit = (formData: MainStoreInputs) => {
    mutate({
      mainStore: formData.mainStore
    })
  }
  return (
    <StoreFormLayout onSubmit={handleSubmit(onSubmit)}>
      <Select
        id="mainStore"
        control={control}
        options={storeOptions}
        label={t('page.collaborator.details.stores.labels.mainStoreSelect')}
        placeholder={t('page.collaborator.details.stores.labels.mainStoreSelect')}
        tooltip={<TextTooltip id="mainStoreTooltip" text={t('page.collaborator.details.tooltips.mainStore')} />}
        disabled={!storeOptions.length}
      />
      <Button icon={MoreIcon} shape="circle" iconColor="white" buttonType="submit" isLoading={isLoading} />
    </StoreFormLayout>
  )
}
