import MainTitle from 'components/Title/MainTitle'
import { useTranslation } from 'react-i18next'
import { AppVersion, StyledSection } from './Home.styles'

function Home() {
  const { t } = useTranslation()
  return (
    <StyledSection>
      <MainTitle title={t('page.home.title')} />
      <AppVersion fontStyle="italic" fontWeight="light" color="secondary">
        {process.env.REACT_APP_VERSION}
      </AppVersion>
    </StyledSection>
  )
}

export default Home
