import { fundCashbox, FundCashboxBody } from 'api/cashbox'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Modal from 'components/Modal/Modal'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import ToggleIcon from 'components/Tooltip/ToggleIcon'
import { DEFAULT_CASHBOX_VALUE } from 'constants/configs'
import { useMutation } from 'hooks/useAxiosMutation'
import _, { isNil } from 'lodash'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Cashbox } from 'types/entities'
import { formatCalendarDate } from 'utils/dates'
import {
  CashboxesCountDetails,
  getCashboxAccountDetails,
  removeCashboxAccountDetails,
  setCashboxFundedDetails
} from 'utils/localStorage'
import CashboxDetailForm from '../form/CashboxDetailForm'
import { FundStepInformation, ValidationButton } from '../form/CashboxDetailsForm.style'
import { getCashboxCount, getDifferenceColor } from '../utils/cashHelper'
import {
  ActiveStepLayout,
  DifferenceText,
  FundCashboxModalBody,
  ModalTitle2,
  ModalTitle3,
  SectionBottom,
  TotalText
} from './CashboxStep.style'

interface Props {
  isActive: boolean
  cashbox: Cashbox
}

const calculateDepositDetails = (
  cashFundDetails?: CashboxesCountDetails,
  cashAccountDetails?: CashboxesCountDetails
): CashboxesCountDetails | undefined => {
  if (cashAccountDetails) {
    if (cashFundDetails) {
      _.map(cashFundDetails, (value, key) => {
        if (cashAccountDetails[key]) cashAccountDetails[key] = cashAccountDetails[key] - value
      })
    }
    return cashAccountDetails
  }
  return
}
export default function CashboxFundStep({ isActive, cashbox }: Props) {
  const { t } = useTranslation()

  const [cashFundEndCalculated, setCashFundEndCalculated] = useState<number>()
  const [expectedDifference, setExpectedDifference] = useState<number>(0)
  const [cashboxDetails, setCashboxDetails] = useState<CashboxesCountDetails>()
  const [open, setOpen] = useState(false)

  const cashFundEnd: number = cashbox?.store?.defaultCashboxValue ?? DEFAULT_CASHBOX_VALUE

  const handleCount = (formData) => {
    let cashFundEndCalculated = getCashboxCount(formData)
    setCashFundEndCalculated(cashFundEndCalculated)
    setExpectedDifference(cashFundEndCalculated - cashFundEnd)
    setCashboxDetails(formData)
  }
  const queryClient = useQueryClient()

  const { mutate: fundCashboxMutation, isLoading } = useMutation(
    (body: FundCashboxBody) => fundCashbox(cashbox.id!, body),
    {
      onSuccess: (data) => {
        setCashboxFundedDetails(calculateDepositDetails(cashboxDetails, getCashboxAccountDetails()))
        removeCashboxAccountDetails()

        const prev = queryClient.getQueryData(['cashbox', `${data.id}`])
        queryClient.setQueryData(['cashbox', `${data.id}`], { ...(prev as any), ...data })
      }
    }
  )

  const handleFund = () => {
    fundCashboxMutation({
      cashFundEndCalculated
    })
  }

  return (
    <>
      {cashbox?.validatedAt && (
        <>
          {isActive ? (
            <ActiveStepLayout>
              <CardLayout>
                <Title2>{t('page.cashbox.fundStep.formTitle')}</Title2>
                <FundStepInformation>
                  <Text color="info">
                    <Trans
                      i18nKey="page.cashbox.fundStep.fundInformation"
                      values={{ value: `${cashFundEnd}€` }}
                      components={{ bold: <strong /> }}
                    />
                  </Text>
                  <ToggleIcon onClick={() => setOpen(true)} />
                </FundStepInformation>

                <CashboxDetailForm onSubmit={handleCount} tooltipText={t('page.cashbox.fundStep.calculate')} />
              </CardLayout>
              <CardLayout>
                <Title2>{t('page.cashbox.fundStep.countTitle')}</Title2>
                <TextNumber
                  color={cashFundEndCalculated ? 'black' : 'secondary2'}
                  fontWeight={cashFundEndCalculated ? 'light' : 'regular'}
                  value={cashFundEndCalculated}
                  placeholder={t('common.label.notAvailable')}
                  ContainerComponent={TotalText}
                  suffix="€"
                  decimalScale={2}
                />

                <TextNumber
                  value={expectedDifference}
                  suffix="€"
                  label={t('page.cashbox.expectedDifference')}
                  prefix={expectedDifference > 0 ? '+' : ''}
                  color={getDifferenceColor(expectedDifference)}
                  ContainerComponent={DifferenceText}
                  decimalScale={2}
                />
                <SectionBottom border={true}>
                  <ValidationButton isLoading={isLoading} disabled={isNil(cashFundEndCalculated)} onClick={handleFund}>
                    {t('page.cashbox.fundStep.validate')}
                  </ValidationButton>
                </SectionBottom>
              </CardLayout>
            </ActiveStepLayout>
          ) : (
            <>
              {cashbox && (
                <CardLayout>
                  <Title2>{t('page.cashbox.fundStep.date', { date: formatCalendarDate(cashbox?.fundedAt) })}</Title2>

                  <TextNumber
                    value={cashbox?.cashFundEndCalculated}
                    size="2.8rem"
                    color="black"
                    fontWeight="light"
                    suffix="€"
                    decimalScale={2}
                  />
                </CardLayout>
              )}
            </>
          )}

          <Modal open={open} onClose={() => setOpen(false)}>
            <>
              <ModalTitle2>{t('page.cashbox.fundStep.help.title')}</ModalTitle2>
              <FundCashboxModalBody>
                <div>
                  <ModalTitle3>{t('page.cashbox.fundStep.help.defaultFund.title')}</ModalTitle3>
                  <ul>
                    <li>{t('page.cashbox.fundStep.help.defaultFund.content.line1')}</li>
                    <li>{t('page.cashbox.fundStep.help.defaultFund.content.line2')}</li>
                    <li>{t('page.cashbox.fundStep.help.defaultFund.content.line3')}</li>
                    <li>{t('page.cashbox.fundStep.help.defaultFund.content.line4')}</li>
                  </ul>
                </div>
                <div>
                  <ModalTitle3>{t('page.cashbox.fundStep.help.moreFund.title')}</ModalTitle3>
                  <ul>
                    <li>{t('page.cashbox.fundStep.help.moreFund.content.line1')}</li>
                    <li>{t('page.cashbox.fundStep.help.moreFund.content.line2')}</li>
                    <li>{t('page.cashbox.fundStep.help.moreFund.content.line3')}</li>
                  </ul>
                </div>
              </FundCashboxModalBody>
            </>
          </Modal>
        </>
      )}
    </>
  )
}
