import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Cell from 'components/SortedTable/Cell'
import { Container as SortedTableContainer } from 'components/SortedTable/SortedTable.styles'
import styled from 'styled-components'

export const OrderListLayout = styled(CardLayout)`
  ${SortedTableContainer} {
    margin-bottom: 0;
  }
`

export const DeleteFidelityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  padding-left: 2rem;
`

export const TabNameContainer = styled(RowContainer)`
  width: auto;
`

export const RowCell = styled(Cell)`
  display: flex;
  gap: 0.5rem;
`
