export type ProductLabel = {
  id: string
  product: string
  title?: string
  subtitle?: string
  format: LabelFormatEnum
  arrow: ArrowLabelEnum
  price: number
  discountPrice?: number
  discountPercent?: number
}

export enum ArrowLabelEnum {
  none = 'sans',
  up = 'haut',
  down = 'bas'
}

export enum LabelFormatEnum {
  rectangle = '45x18',
  square = '26x26'
}

export const getTitleFontSize = (title: string, subtitle?: string) => {
  if (title.length <= 15 && !subtitle) return '2.4rem'
  if (title.length > 30 && subtitle) return '1.6rem'
  return '2.1rem'
}

export const getDiscountPercentage = (price: number, discountPrice: number) =>
  Math.floor(-((price - discountPrice) / price) * 100)
