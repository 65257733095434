import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import Svg from 'components/Svg/Svg'
import { useTranslation } from 'react-i18next'
import { getCustomerColorLabel, getCustomerIcon } from 'utils/externalSellers'
import { ExternalOrderTitleText } from './OrderInfoCard.style'

type ExternalOrderTitleProps = {
  sourceName: string | null
  sourceReference: string | null
  sourceLink: string | null
}

function ExternalOrderTitle({ sourceName, sourceReference, sourceLink }: ExternalOrderTitleProps) {
  const { t } = useTranslation()
  const titleText = t('page.order.detail.itemReference', { item: sourceName, id: sourceReference })
  return (
    <RowContainer>
      <Svg svg={getCustomerIcon(null, sourceName)!} size="2.5rem" />
      <a target="_blank" rel="noreferrer" href={`${sourceLink}${sourceReference}`}>
        <ExternalOrderTitleText color={getCustomerColorLabel(null, sourceName)}>{titleText}</ExternalOrderTitleText>
      </a>
    </RowContainer>
  )
}

export default ExternalOrderTitle
