import { Filter } from 'components/FiltersList/FiltersList'
import { getFilterLabel, useMyStoresOptions } from 'hooks/useSelectOptions'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultStoreAtom } from 'utils/jotaiAtom'

export type FormData = {
  location?: string
  withQuantity?: string
}

const useQuantityOptions = () => {
  const { t } = useTranslation()
  const quantityOptions = [
    { value: 'withQuantity', label: t('page.product.detail.tabs.stocks.filters.withQuantity') },
    { value: 'noQuantity', label: t('page.product.detail.tabs.stocks.filters.noQuantity') }
  ]

  const getQuantityQueryParams = (value?: string) => {
    return { withRemainingQuantity: value === 'withQuantity' }
  }

  return {
    quantityOptions,
    getQuantityFilter: (id, value) => ({ id, label: getFilterLabel(quantityOptions, value) }),
    getQuantityQueryParams
  }
}
export default function useStockFilter() {
  const { myStoresOptions, getStoreFilter } = useMyStoresOptions()
  const { quantityOptions, getQuantityFilter, getQuantityQueryParams } = useQuantityOptions()

  const [defaultStore] = useAtom(defaultStoreAtom)

  const [filters, setFilters] = useState<Filter[]>([{ id: 'location', label: defaultStore?.shortName }])
  const [queryParams, setQueryParams] = useState<any>({
    location: { 'stock.store': defaultStore?.['@id'] }
  })
  const filtersFunctions = useMemo(
    () => ({
      location: getStoreFilter,
      withQuantity: getQuantityFilter
    }),
    [getStoreFilter, getQuantityFilter]
  )

  const queryParamsFunctions = useMemo(
    () => ({
      location: (value?: string) => ({ 'stock.store': value }),
      withQuantity: getQuantityQueryParams
    }),
    [getQuantityQueryParams]
  )

  const onSubmit = (formData: FormData) => {
    let toFilters = _.pickBy(formData, (value, name) => value && filtersFunctions[name])
    let toQuery = _.pickBy(formData, (value, name) => value && queryParamsFunctions[name])

    setQueryParams(_.mapValues(toQuery, (value, key) => queryParamsFunctions[key](value)))

    setFilters(_.flattenDeep(_.map(toFilters, (value, key) => filtersFunctions[key](key, value))))
  }
  return {
    queryParams,
    filters,
    myStoresOptions,
    quantityOptions,
    setQueryParams,
    setFilters,
    onSubmit
  }
}
