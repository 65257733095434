import { GetOrderByIdResponse } from 'api/orders'
import { useOrderContext } from 'app/OrderDetails/helper'
import CanAccess from 'features/Permissions/CanAccess'
import { RoleAction } from 'types/playInApiInterfaces'
import CancelReadyStatusDisplay from './forms/CancelReadyStatusDisplay'
import CancelReadyStatusForm from './forms/CancelReadyStatusForm'
import ShippingCardDisplay from './forms/ShippingCardDisplay'
import ShippingForm from './forms/ShippingForm'
import { Separator } from './ShippingCard.style'

type Props = {
  order?: GetOrderByIdResponse
}

function ShippingCard({ order }: Props) {
  const isShipment = order?.stock?.['@id'] !== order?.shippingMode?.store?.['@id']
  const isClickAndCollect = !order?.shippingMode?.store?.warehouse

  const { inMyStoresOrder } = useOrderContext()

  return (
    <>
      {(isShipment || !isClickAndCollect) && (
        <CanAccess
          permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_SEND]}
          allowedComponent={<ShippingForm order={order} />}
          deniedComponent={<ShippingCardDisplay order={order} />}
          deniedExtraCondition={!inMyStoresOrder}
        />
      )}
      {isShipment && isClickAndCollect && <Separator />}
      {isClickAndCollect && (
        <CanAccess
          permissions={[RoleAction.ROLE_ACTION_ORDER_EDIT_READY]}
          allowedComponent={<CancelReadyStatusForm order={order} />}
          deniedComponent={<CancelReadyStatusDisplay order={order} />}
          deniedExtraCondition={!inMyStoresOrder}
        />
      )}
    </>
  )
}

export default ShippingCard
