import { InputTextStyle } from 'components/Input/Input.styles'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import Loader from 'components/Loader/Loader'
import styled from 'styled-components'

export const Options = styled.div`
  --wrapperPadding: 0.6rem;
  display: flex;
  flex-direction: column;

  position: absolute;
  top: calc(100% + 2px);
  left: -2px;
  width: calc(100% + 4px);
  // Avoid threesold not triggering on some case
  // Wrapper height need to be an even number because option height is 2rem
  // Y padding need to be counted too
  max-height: calc(24rem + var(--wrapperPadding));
  padding: var(--wrapperPadding) 0;

  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background-color: white;
  font-size: 1.3rem;

  opacity: 0;
  transition: opacity 100ms ease-in-out;
  pointer-events: none;
  overflow-y: auto;

  &.open {
    opacity: 1;
    pointer-events: auto;
    z-index: 15;
  }
`

export const StyledInputLayout = styled(InputLayout)`
  position: relative;
`

export const Option = styled.div`
  padding: 0.25rem 0.6rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.backgroundColors.secondary};
  }

  &.header {
    margin-bottom: 0.9rem;
    pointer-events: none;
    cursor: none;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
`
export const StyledLoader = styled(Loader)`
  margin-right: 0.5rem;
`

export const InputAutoCompleteInput = styled(InputTextStyle)``
