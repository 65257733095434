import styled from 'styled-components'
import { Colors } from 'theme/theme'

type SvgSize = string | { width?: string; height?: string }
export interface StyledSvgProps {
  color?: keyof Colors
  size?: SvgSize
}

const getSvgSize = (size?: SvgSize) => {
  if (size) {
    if (typeof size === 'string') return { width: size, height: size }
    return { width: size.width || 'auto', height: size.height || 'auto' }
  }

  return { width: '1.6rem', height: '1.6rem' }
}

export const StyledSvg = styled.svg<StyledSvgProps>`
  width: ${(props) => getSvgSize(props.size)['width']};
  height: ${(props) => getSvgSize(props.size)['height']};
  ${(props) =>
    props.color
      ? `
    path {
      fill: ${props.theme.colors[props.color]}!important;
    }
  `
      : ``}
`

export const Container = styled.div<{ size?: SvgSize }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => getSvgSize(props.size)['width']};
  height: ${(props) => getSvgSize(props.size)['height']};
`
