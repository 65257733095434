import { FetchOrdersQueryParams } from 'api/orders'
import { FamilyStatus } from 'api/ranges'
import { Filter } from 'components/FiltersList/FiltersList'
import TextNumber from 'components/TextNumber/TextNumber'
import { formatISO, startOfDay } from 'date-fns'
import { endOfDay } from 'date-fns/esm'
import useAuth from 'hooks/useAuth'
import {
  useClientProfileOptions,
  useFamilyFilters,
  useMyStocksOptions,
  useOrderSecondaryStatusOptions,
  useOrderStatusOptions,
  usePaymentModeFilters,
  usePaymentStatusFilters,
  useShippingStatusOptions,
  useShippingTypeOptions,
  useStockStateOptions
} from 'hooks/useSelectOptions'
import _ from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'types/entities'
import { OrderStatus } from 'types/playInApiInterfaces'
import { dayAsQueryParams, formatCalendarDate } from 'utils/dates'
import { useOrderPrintStatusOptions } from './useOrderOptions'

export type FormData = {
  status?: OrderStatus[]
  secondaryStatus?: string
  stock?: string
  stockStatus?: string
  shippingMode?: string
  shippingStatus?: string
  paymentMode?: string
  paymentStatus?: string
  createdAfter?: string
  createdBefore?: string
  printedAt?: string
  printStatus?: string
  family?: string
  edition?: string
  clientProfile?: string
  sourceReference?: string
  trackingNumber?: string
  totalPaid?: number
  billNumber?: string
  search?: string
  orderContainsCard?: string
  sentOrReceivedByAllStores?: boolean
}

export default function useOrderFilter(
  store: Store | undefined,
  setSearchFilters: Dispatch<SetStateAction<Filter[]>>
) {
  const { t } = useTranslation()
  const { me } = useAuth()

  const { orderStatusOptions, getOrderStatusFilter, getOrderStatusesQueryParams } = useOrderStatusOptions()
  const { orderSecondaryStatusOptions, getOrderSecondaryStatusFilter, getSecondaryStatusQueryParams } =
    useOrderSecondaryStatusOptions()
  const { getStockFilters, getStockQueryParams, myStockOptions } = useMyStocksOptions()
  const { shippingTypeOptions, getShippingTypeFilter } = useShippingTypeOptions()
  const { shippingStatusOptions, getShippingStatusFilter, getShippingStatusQueryParams } = useShippingStatusOptions()
  const { stockStateOptions, getStockStateFilter, getStockStateQueryParams } = useStockStateOptions()
  const { paymentModeOptions, getPaymentModeFilter, getPaymentModeQueryParam } = usePaymentModeFilters()
  const { paymentStatusOptions, getPaymentStatusFilter, getPaymentStatusQueryParams } = usePaymentStatusFilters()
  const { orderPrintStatusOptions, getPrintStatusFilter, getPrintStatusQueryParams } = useOrderPrintStatusOptions()
  const { familyOptions, getFamilyFilter } = useFamilyFilters({
    'stores[]': me!.stores?.map((e) => e['@id'] as string),
    'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage],
    'order[name]': 'asc'
  })
  const { clientProfileOptions, getMultipleClientProfileFilter, getMultipleClientProfileQueryParams } =
    useClientProfileOptions()

  const getSearchFilter = useCallback(
    (id, value) => ({ id, label: t('page.order.manage.filters.search', { value }) }),
    [t]
  )
  const queryParamsFns = useMemo(
    (): { [key in keyof FormData]: any } => ({
      status: getOrderStatusesQueryParams,
      secondaryStatus: getSecondaryStatusQueryParams,
      stock: getStockQueryParams,
      stockStatus: getStockStateQueryParams,
      shippingMode: (value) => ({ 'shippingMode.shippingType[]': value.length ? value : undefined }),
      shippingStatus: getShippingStatusQueryParams,
      paymentMode: getPaymentModeQueryParam,
      paymentStatus: getPaymentStatusQueryParams,
      createdAfter: (value) => ({ 'createdAt[after]': formatISO(startOfDay(new Date(value))) }),
      createdBefore: (value) => ({ 'createdAt[before]': formatISO(endOfDay(new Date(value))) }),
      printedAt: (value) => dayAsQueryParams('printedAt', value),
      printStatus: getPrintStatusQueryParams,
      family: (value) => ({ 'entries.product.family': value }),
      orderContainsCard: (value) => ({ orderContainsCard: value }),
      clientProfile: getMultipleClientProfileQueryParams,
      billNumber: (value) => ({ billNumber: value }),
      sourceReference: (value) => (store?.warehouse ? { sourceReference: value } : { hiboutikId: value }),
      trackingNumber: (value) => ({ trackingNumber: value }),
      totalPaid: (value) => ({ totalPaid: value }),
      sentOrReceivedByAllStores: (value) => ({
        'sentOrReceivedBy[]': value === true ? me?.stores?.map((store) => store['@id']) : [store?.['@id']]
      })
    }),
    [
      getOrderStatusesQueryParams,
      getStockQueryParams,
      getShippingStatusQueryParams,
      getPaymentModeQueryParam,
      getPaymentStatusQueryParams,
      getPrintStatusQueryParams,
      getSecondaryStatusQueryParams,
      getStockStateQueryParams,
      getMultipleClientProfileQueryParams,
      me?.stores,
      store
    ]
  )

  const filtersFns = useMemo(
    (): { [key in keyof FormData]: any } => ({
      status: getOrderStatusFilter,
      secondaryStatus: getOrderSecondaryStatusFilter,
      stock: getStockFilters,
      stockStatus: getStockStateFilter,
      shippingMode: getShippingTypeFilter,
      shippingStatus: getShippingStatusFilter,
      paymentMode: getPaymentModeFilter,
      paymentStatus: getPaymentStatusFilter,
      createdAfter: (id, value) => ({
        id,
        label: t('page.order.manage.filters.createdAfter', { date: formatCalendarDate(value) })
      }),
      createdBefore: (id, value) => ({
        id,
        label: t('page.order.manage.filters.createdBefore', { date: formatCalendarDate(value) })
      }),
      printedAt: (id, value) => ({
        id,
        label: t('page.order.manage.filters.printedAt', { date: formatCalendarDate(value) })
      }),

      printStatus: getPrintStatusFilter,
      family: getFamilyFilter,
      orderContainsCard: (id, value) => ({
        ...(value === true && { id, label: t('page.order.manage.filters.includeCard') })
      }),
      clientProfile: getMultipleClientProfileFilter,
      billNumber: (id, value) => ({ id, label: t('page.order.manage.filters.billNumber', { value }) }),
      sourceReference: (id, value) => ({
        id,
        value,
        label: store?.warehouse
          ? t('page.order.manage.filters.sourceReference', { value })
          : t('page.order.manage.filters.hiboutikReference', { value })
      }),
      trackingNumber: (id, value) => ({ id, label: t('page.order.manage.filters.trackingNumber', { value }) }),
      totalPaid: (id, value) => ({ id, label: <TextNumber suffix=" €" value={value} decimalScale={2} /> }),
      search: getSearchFilter,
      sentOrReceivedByAllStores: (id, value) => ({
        ...(value === true && { id, label: t('page.order.manage.filters.sentOrReceivedByAllStores') })
      })
    }),
    [
      getOrderStatusFilter,
      getOrderSecondaryStatusFilter,
      getStockFilters,
      getStockStateFilter,
      getShippingTypeFilter,
      getShippingStatusFilter,
      getPaymentModeFilter,
      getPaymentStatusFilter,
      getPrintStatusFilter,
      getFamilyFilter,
      getMultipleClientProfileFilter,
      getSearchFilter,
      store?.warehouse,
      t
    ]
  )

  const handleForm = (formData: FormData): { [key in string]: FetchOrdersQueryParams } => {
    const queryParams = {}
    const filters: Filter[] = []
    _.forEach(formData, (value, name) => {
      if (name && !_.isNil(value) && value !== '') {
        if (queryParamsFns[name]) {
          queryParams[name] = queryParamsFns[name](value)
        }

        if (filtersFns[name]) {
          filters.push(filtersFns[name](name, value))
        }
      }
    })

    setSearchFilters(_.flattenDeep(filters))
    return queryParams
  }

  // when store change, we need to change sourceReference filter label
  useEffect(() => {
    setSearchFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.id === 'sourceReference') {
          const sourceRefLabel = store?.warehouse
            ? t('page.order.manage.filters.sourceReference', { value: filter.value })
            : t('page.order.manage.filters.hiboutikReference', { value: filter.value })
          return { ...filter, label: sourceRefLabel }
        }

        return filter
      })
    })
  }, [store, t, setSearchFilters])

  return {
    orderStatusOptions,
    orderSecondaryStatusOptions,
    stockStateOptions,
    shippingTypeOptions,
    shippingStatusOptions,
    paymentModeOptions,
    paymentStatusOptions,
    orderPrintStatusOptions,
    familyOptions,
    clientProfileOptions,
    handleForm,
    setSearchFilters,
    getOrderStatusFilter,
    getSearchFilter,
    myStockOptions
  }
}
