import { endOfDay, format, formatISO, startOfDay } from 'date-fns'
import { fr } from 'date-fns/locale'

export const formatDateAndHour = (date?: string | null) => {
  // Format : 27/11/22 à 19h00

  if (!date) {
    return undefined
  }

  try {
    return format(new Date(date), "dd/MM/yyy à HH'h'mm")
  } catch (error) {
    return undefined
  }
}

export const formatDateAndHourWithSeconds = (date?: string | null) => {
  // Format : 27/11/22 à 19:00:00

  if (!date) {
    return undefined
  }

  try {
    return format(new Date(date), 'dd/MM/yyy à HH:mm:ss')
  } catch (error) {
    return undefined
  }
}

export const formatCalendarDate = (date?: string | Date | null) => {
  // Format : Dimanche 27/11/22

  if (!date) {
    return undefined
  }

  try {
    return format(new Date(date), 'dd/MM/yyy')
  } catch (error) {
    return undefined
  }
}

export const fullDateFormat = (date?: string | Date | null) => {
  // Format : Dimanche 27/11/22 à 19h00

  if (!date) {
    return undefined
  }

  try {
    return format(new Date(date), "EEEE dd/MM/yy à HH'h'mm", { locale: fr })
  } catch (error) {
    return undefined
  }
}

export const dayAsQueryParams = (queryParam: string, date?: Date | string) => {
  if (date) {
    let startParam = `${queryParam}[after]`
    let endParam = `${queryParam}[before]`
    return {
      [startParam]: formatISO(startOfDay(new Date(date))),
      [endParam]: formatISO(endOfDay(new Date(date)))
    }
  }
  return
}

export const hourFormat = (date?: string | Date | null) => {
  // Format : 19:00
  // Format : 09:00

  if (!date) {
    return undefined
  }

  try {
    return format(new Date(date), 'HH:mm')
  } catch (error) {
    return undefined
  }
}

export const shortDateAndHourFormat = (date?: string | Date | null) => {
  // Format : 27/11 à 19:00

  if (!date) {
    return undefined
  }

  try {
    return format(new Date(date), 'dd/MM à H:mm')
  } catch (error) {
    return undefined
  }
}
