import InputFormattedNumber from 'components/InputFormattedNumber/InputFormattedNumber'
import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import InputLayout from 'components/Layouts/InputLayout/InputLayout'
import styled from 'styled-components'

export const PaymentModeCardFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem 5rem;
`

export const PaymentModesLabelStyled = styled.div`
  display: flex;
  gap: 0.5rem;
  font-weight: ${(props) => props.theme.fontWeight.light};
  white-space: nowrap;
  margin-bottom: 0.5rem;
`

export const PaymentModeInputRow = styled(RowContainer)`
  gap: 2rem;
`

export const OverviewTextContainer = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const OverviewTextRow = styled.div`
  display: flex;
  gap: 0.5rem;

  & > :first-child {
    white-space: nowrap;
  }
`

export const PaymentModeFilter = styled.div`
  display: flex;

  & > :not(:first-child):before {
    content: '- ';
    margin-left: 0.2rem;
  }
`

export const AmountInput = styled(InputFormattedNumber)`
  width: 8.5rem;
` as typeof InputFormattedNumber

export const StyledInputLayout = styled(InputLayout)`
  min-height: 3.5rem;
  height: unset;
`
