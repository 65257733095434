import { FormattedNumberComponent } from 'components/InputFormattedNumber/InputFormattedNumber'
import { useTranslation } from 'react-i18next'
import { RentCouponOption } from './CouponCard.style'

export const useRentCouponsOptions = () => {
  const { t } = useTranslation()

  // Labels and values' purpose are subject to changes
  const options = [
    { label: '5', value: 5 },
    { label: '8', value: 8 }
  ]

  return {
    rentCouponOptions: options.map((option) => ({
      ...option,
      label: (
        <RentCouponOption>
          {t('page.order.detail.tab.payment.rent')}
          <FormattedNumberComponent value={Number(option.value)} decimalScale={2} suffix={'\u00a0€'} />
        </RentCouponOption>
      )
    }))
  }
}
