import { CrossIcon } from 'constants/icons'
import { FunctionComponent } from 'react'
import { CloseIcon, Container, StyledModal } from './Modal.styles'

export type ModalProps = {
  open: boolean
  onClose: () => void
  keepMounted?: boolean
}

const Modal: FunctionComponent<ModalProps> = ({ open, children, onClose, keepMounted = false }) => {
  return (
    <StyledModal open={open} onClose={onClose} keepMounted={keepMounted}>
      <Container>
        <CloseIcon svg={CrossIcon} color="grey4" onClick={onClose} />
        {children}
      </Container>
    </StyledModal>
  )
}

export default Modal
