import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey, useQuery } from 'react-query'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

/* -------------------------------------------------------------------------- */
/*                                    FETCH                                   */
/* -------------------------------------------------------------------------- */

export type FetchProductCategoriesParams = Api.GetProductCategories.RequestQuery
type FetchProductCategoriesResponse = Api.GetProductCategories.ResponseBody
const getProductCategoriesUri = (params?: FetchProductCategoriesParams) => {
  const qs = stringify(params ?? {})
  return [API_ROUTES.productCategories.root, qs].join('?')
}
export type PaginatedProductCategoriesOptions = PaginatedHookOptions<FetchProductCategoriesResponse['hydra:member'][0]>
export const usePaginatedProductCategories = (
  queryKey: QueryKey,
  params?: FetchProductCategoriesParams,
  options?: PaginatedProductCategoriesOptions
) =>
  usePaginatedQuery<FetchProductCategoriesResponse['hydra:member'][0]>(
    queryKey,
    getProductCategoriesUri(params),
    options
  )

const productCategoryByIdQueryKey = (id?: string | number) => ['productCategory-by-id', `${id}`]

type FetchProductCategoryByIdResponse = Api.GetProductCategoriesId.ResponseBody
export const useProductCategoryById = (id?: string | number) =>
  useQuery(
    productCategoryByIdQueryKey(id),
    async () => {
      const res = await playInApi.get<FetchProductCategoryByIdResponse>(`${API_ROUTES.productCategories.root}/${id}`)
      return res.data
    },
    {
      enabled: !!id
    }
  )
