import classNames from 'classnames'
import { Dot, Radio } from 'components/InputRadio/InputRadio.styles'
import { Text } from 'components/Text/Text.styles'
import { Customer } from 'types/entities'
import { CustomerBlock, NameBlock } from './CustomerEditModal.style'

interface SelectableCustomerProps {
  customer: Customer
  onClick: (value: string) => void
  selected: boolean
}

export default function SelectableCustomer({ customer, selected, onClick }: SelectableCustomerProps) {
  return (
    <>
      <Radio
        className={classNames({ checked: selected })}
        onClick={() => {
          onClick(customer['@id']!)
        }}
      >
        <Dot className={selected ? 'checked' : ''} />
      </Radio>
      <CustomerBlock>
        <NameBlock>
          {customer.lastname} {customer.firstname}
        </NameBlock>
        <Text color="secondary" fontWeight="light">
          {customer.email}
        </Text>
      </CustomerBlock>
    </>
  )
}
