import { yupResolver } from '@hookform/resolvers/yup'
import { PatchStoreBody } from 'api/stores'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'
import Button, { Props as ButtonProps } from 'components/Button/Button'
import BasicFieldValidationError from 'components/FormValidationErrors/BasicFieldValidationError'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { UseMutateFunction } from 'react-query'
import { StoreDetails } from 'types/entities'
import { useFormSibblingFields } from 'utils/formHelper'
import * as yup from 'yup'
import { FormSection } from '../StoreSeoTab'
import { FieldsWrapper, FormCard } from '../StoreSeoTab.style'

const schema = yup.object().shape(
  {
    payBackDescription: yup
      .string()
      .trim()
      .nullable()
      .when('payBackDescriptionEn', {
        is: (value) => !!value,
        then: (schema) => schema.required()
      }),
    payBackDescriptionEn: yup
      .string()
      .trim()
      .nullable()
      .when('payBackDescription', { is: (value) => !!value, then: (schema) => schema.required() })
  },
  [['payBackDescription', 'payBackDescriptionEn']]
)

interface FormSectionInputs {
  payBackDescription?: string | null
  payBackDescriptionEn?: string | null
}

interface Props {
  getSubmitButtonProps: (id: FormSection) => ButtonProps
  mutation: UseMutateFunction<StoreDetails, AxiosResponse<never>, PatchStoreBody>
  store: StoreDetails
}

const getDefaultValues = (store: StoreDetails): FormSectionInputs => ({
  payBackDescription: store?.payBackDescription,
  payBackDescriptionEn: store?.payBackDescriptionEn
})

export default function PaybackFormSection({ mutation, getSubmitButtonProps, store }: Props) {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    trigger
  } = useForm<FormSectionInputs>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(store)
  })

  const onSubmit = (formData: FormSectionInputs) => {
    mutation(formData)
  }

  useFormSibblingFields([['payBackDescription', 'payBackDescriptionEn']], { watch, trigger })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormCard>
        <Title2>{t('page.stores.details.storeSeo.sections.payback')}</Title2>
        <FieldsWrapper>
          <div>
            <RichTextEditor
              control={control}
              id="payBackDescription"
              label={t('page.stores.details.storeSeo.labels.paybackDescription')}
              className={classNames({ 'is-invalid': errors.payBackDescription })}
              tooltip={
                <Tooltip id="fieldTooltip">
                  <Trans i18nKey="page.stores.details.storeSeo.tooltips.paybackDescription" />
                </Tooltip>
              }
            />
            <BasicFieldValidationError
              error={errors.payBackDescription}
              message={t('form.validation.required.genericFr')}
            />
          </div>

          <div>
            <RichTextEditor
              control={control}
              id="payBackDescriptionEn"
              label={t('page.stores.details.storeSeo.labels.paybackDescriptionEn')}
              className={classNames({ 'is-invalid': errors.payBackDescriptionEn })}
            />
            <BasicFieldValidationError
              error={errors.payBackDescriptionEn}
              message={t('form.validation.required.genericEn')}
            />
          </div>
        </FieldsWrapper>
        <Button {...getSubmitButtonProps('payback')} />
      </FormCard>
    </form>
  )
}
