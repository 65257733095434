import classnames from 'classnames'
import { Options, StyledInputLayout, StyledLoader } from 'components/InputAutoComplete/InputAutoComplete.styles'
import { Checkbox } from 'components/InputRadio/InputRadio.styles'
import { LayoutProps } from 'components/Layouts/InputLayout/InputLayout'
import Loader from 'components/Loader/Loader'
import { useClickAway } from 'hooks/useClickAway'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Control, Controller, FieldPathValue, FieldValues, Path, UnpackNestedValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import {
  CheckboxOption,
  InputContainer,
  OpenIcon,
  PlaceholderLabel,
  SelectContainer,
  SelectedLabel
} from './Select.styles'

export type Option = {
  label: string | JSX.Element
  value: string | number | undefined | boolean
  disabled?: boolean
}

interface ReactHookFormProps<T extends FieldValues> {
  id: Path<T>
  control: Control<T>
}

export interface Props<T extends FieldValues> extends ReactHookFormProps<T>, LayoutProps, SelectComponentProps {}

const mapValues = (opts: Option[]) => opts.map((opt) => opt.value)

function MultipleSelect<T extends FieldValues>({
  id,
  control,
  className,
  label,
  tooltip,
  layout,
  disabled,
  placeholder,
  options,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  isLoading
}: Props<T>) {
  const [displayOptions, setDisplayOptions] = useState(false)
  const [selectOptions, setSelectOptions] = useState<Option[]>([])

  useEffect(() => {
    if (!_.isEqual(mapValues(options), mapValues(selectOptions))) {
      setSelectOptions(options)
    }
  }, [options, selectOptions])

  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => {
        const { value, onBlur, onChange } = field

        return (
          <StyledInputLayout className={className} label={label} layout={layout} tooltip={tooltip} disabled={disabled}>
            <SelectComponent
              value={value}
              options={selectOptions}
              placeholder={placeholder}
              disabled={disabled}
              displayOptions={displayOptions}
              setDisplayOptions={setDisplayOptions}
              onBlur={onBlur}
              onChange={onChange}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
            />
          </StyledInputLayout>
        )
      }}
    />
  )
}

type SelectComponentProps = {
  options: Option[]
  placeholder?: string | JSX.Element
  disabled?: boolean
  hasNextPage?: boolean
  fetchNextPage?: () => void
  isFetchingNextPage?: boolean
  isLoading?: boolean
}

type ControlledProps<T extends FieldValues> = SelectComponentProps & {
  value: UnpackNestedValue<FieldPathValue<T, Path<T>>>
  onChange: (value: Array<Option['value']>) => void
  onBlur: () => void
  displayOptions: boolean
  setDisplayOptions: Dispatch<SetStateAction<boolean>>
}

function SelectComponent<T extends FieldValues>({
  value,
  options,
  placeholder = '',
  disabled,
  displayOptions,
  setDisplayOptions,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  onChange,
  onBlur,
  isLoading
}: ControlledProps<T>) {
  const { t } = useTranslation()
  const [selectedOptions, setSelectedOptions] = useState<Array<Option>>([])
  const activeOptions = options.filter((e) => !e.disabled)

  useEffect(() => {
    if (value) {
      //_.values mandatory because UnpackNestedValue return object type
      setSelectedOptions(options.filter((option) => _.values(value).includes(option.value)) ?? [])
    } else {
      onChange([])
    }
  }, [options, value, onChange])

  // On ClickAway, we close the select panel
  // And we trigger field validation with onBlur
  const { ref } = useClickAway(() => {
    if (displayOptions) {
      setDisplayOptions(false)
      onBlur()
    }
  })

  const { ref: infiniteScrollRef, inView } = useInView({
    threshold: 1 // Trigger component is in view when fully visible
  })

  useEffect(() => {
    if (inView && hasNextPage && fetchNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage])

  const onOptionClick = (event: React.MouseEvent, option: Option) => {
    event.stopPropagation()
    let selectedValues: Array<Option> = []
    if (option.value !== '') {
      if (_.some(selectedOptions, (selectedOption) => selectedOption.value === option.value)) {
        selectedValues = selectedOptions.filter((selectedOption) => selectedOption.value !== option.value)
      } else {
        selectedValues = [...selectedOptions, option]
      }
    }

    setSelectedOptions(selectedValues)
    onChange(selectedValues.map((option) => option.value))
    onBlur()
  }

  const selectAll = () => {
    if (selectedOptions.length === activeOptions.length) {
      setSelectedOptions([])
      onChange([])
    } else {
      setSelectedOptions(activeOptions)
      onChange(activeOptions.map((option) => option.value))
    }
  }

  return (
    <SelectContainer ref={ref}>
      <InputContainer
        onClick={() => {
          return setDisplayOptions((prev) => !!options.length && !prev)
        }}
      >
        {isLoading ? (
          <Loader />
        ) : placeholder && !selectedOptions?.length ? (
          <PlaceholderLabel>{placeholder}</PlaceholderLabel>
        ) : (
          <SelectedLabel>
            {selectedOptions.map((e, index) => (
              <span key={`label-${index}`}>{e.label}</span>
            ))}
          </SelectedLabel>
        )}
        <OpenIcon className={displayOptions && !disabled ? 'open' : 'close'} />
      </InputContainer>
      <Options className={displayOptions && !disabled ? 'open' : 'close'}>
        {!!activeOptions.length && (
          <CheckboxOption onClick={selectAll}>
            <Checkbox
              className={classnames({
                checked: selectedOptions.length === activeOptions.length
              })}
            />
            {selectedOptions.length === options.length ? t('common.select.unselectAll ') : t('common.select.selectAll')}
          </CheckboxOption>
        )}

        {displayOptions &&
          options.map((option, index) => {
            return (
              <CheckboxOption
                className={classnames({
                  disabled: option.disabled
                })}
                key={index}
                onClick={(event) => onOptionClick(event, option)}
                ref={options.length === index + 1 ? infiniteScrollRef : undefined}
              >
                <Checkbox
                  className={classnames({
                    checked: _.some(selectedOptions, (selectedOption) => selectedOption.value === option.value)
                  })}
                />
                {option.label}
              </CheckboxOption>
            )
          })}
        <div>{isFetchingNextPage && <StyledLoader />}</div>
      </Options>
    </SelectContainer>
  )
}

export default MultipleSelect
