import styled from 'styled-components'
import { SpinningIcon } from '../Icon/Icon.styles'
import { InputTextStyle } from '../Input/Input.styles'
import Svg from '../Svg/Svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.borderColors.secondary};
  border-radius: 0.4rem;
`

export const StyledInput = styled(InputTextStyle)`
  padding: 0.9rem 1rem;
  border: none;
  border-radius: 0 0.3rem 0.3rem 0;
  background-color: white;
`

export const Icon = styled(Svg)`
  height: 3.5rem;
  width: 3.5rem;
  padding: 1rem;
  border-right: 1px solid ${(props) => props.theme.borderColors.secondary};
  background-color: white;
  border-radius: 0.3rem 0 0 0.3rem;
`

export const StyledButtonLoader = styled(SpinningIcon)`
  height: 3.5rem;
  width: 3.5rem;
  padding: 1rem;
  border-right: 1px solid ${(props) => props.theme.borderColors.secondary};
  background-color: white;
  border-radius: 0.3rem 0 0 0.3rem;
`
