import { ColumnContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import styled from 'styled-components'

export const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & > * {
    padding: 0 3.5rem;
    border-right: 1px solid ${(props) => props.theme.borderColors.secondary};
  }

  & > *:first-child {
    padding-left: 0;
  }

  & > *:last-child {
    padding-right: 0;
    border: none;
  }

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 0;

    & > *:nth-child(2n) {
      padding-right: 0;
      border: none;
    }

    & > *:nth-child(2n + 1) {
      padding-left: 0;
      padding-right: 3.5rem;
    }
  }

  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
    gap: 2rem 0;

    & > *,
    & > *:nth-child(2n),
    & > *:nth-child(2n + 1) {
      padding: 0 0;
      border: unset;
    }
  }
`

export const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem 7.1rem;
  align-items: center;

  @media screen and (${(props) => props.theme.screenWidth.maxDesktopScreen}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (${(props) => props.theme.screenWidth.maxTabletScreen}) {
    grid-template-columns: 1fr;
  }
`

export const CheckboxLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const PrintPreorderContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem 0;
  border-top: 1px solid ${(props) => props.theme.borderColors.secondary};
`

export const PrintOptionContainer = styled(ColumnContainer)`
  width: fit-content;
`

export const OptionsWrapper = styled(ColumnContainer)`
  padding-top: 2rem;
  gap: 2rem;
`
