import { registerLocale } from 'i18n-iso-countries'
import countryDataEn from 'i18n-iso-countries/langs/en.json'
import countryDataFr from 'i18n-iso-countries/langs/fr.json'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationFR from './locales/fr/translation.json'

// Import new languages here for countries data if needed.
// https://github.com/michaelwittig/node-i18n-iso-countries#installing
registerLocale(countryDataFr)
registerLocale(countryDataEn)

i18n.use(initReactI18next).init({
  fallbackLng: 'fr',
  debug: true,
  resources: {
    fr: {
      translation: translationFR
    }
  },
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
})

export default i18n
