import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import Cell from 'components/SortedTable/Cell'
import Tabs from 'components/Tabs/Tabs'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import { Title2 } from 'components/Title/Title.styles'
import { Columns } from 'features/Listing/Listing'
import Link from 'features/Permissions/Link/Link'
import { useVoucherCategoriesOptions } from 'hooks/useSelectOptions'
import _ from 'lodash'
import { RouteName, useCheckMatch } from 'permissions/permissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Customer, Voucher } from 'types/entities'
import { RoleAction } from 'types/playInApiInterfaces'
import { formatCalendarDate } from 'utils/dates'
import { getIdFromIri } from 'utils/queryParams'
import AddVoucherForm from '../forms/AddVoucherForm'
import { TabNameContainer } from './CustomerList.style'
import ActiveVouchers from './vouchers/ActiveVouchers'
import UsedVouchers from './vouchers/UsedVouchers'
interface Props {
  customer: Customer
}
export default function VoucherList({ customer }: Props) {
  const { checkMatch } = useCheckMatch()
  const permissions = [RoleAction.ROLE_ACTION_CUSTOMER_VOUCHER]

  const { t } = useTranslation()
  const [activeCount, setActiveCount] = useState<number | undefined>(undefined)

  const { getVoucherCategoryTraduction } = useVoucherCategoriesOptions()

  const getUsedName = (voucher: Voucher) => {
    if (voucher.linkedOrder)
      return (
        <Link route={RouteName.OrderDetails} value={getIdFromIri(voucher.linkedOrder['@id']!)}>
          {t('page.customer.details.vouchers.linkedOrder')}
        </Link>
      )

    if (voucher.linkedTournament) return t('page.customer.details.vouchers.linkedTournament')

    return (
      <Text fontStyle="italic" color="danger">
        {voucher.usedName}
      </Text>
    )
  }

  const columns: Columns<Voucher> = [
    {
      key: 'id',
      name: t('common.label.id'),
      decorator: (voucher: Voucher) => (
        <Cell>
          <Text color="secondary" fontWeight="light">
            {getIdFromIri(voucher['@id']!)}
          </Text>
        </Cell>
      )
    },
    {
      key: 'amount',
      name: t('common.label.amount'),
      decorator: (voucher: Voucher) => (
        <Cell>
          <TextNumber value={voucher.value} suffix={` €`} decimalScale={2} />
        </Cell>
      )
    },
    {
      key: 'emittedAt',
      name: t('common.label.issue'),
      decorator: (voucher: Voucher) => <Cell>{formatCalendarDate(voucher.emittedAt)}</Cell>
    },
    {
      key: 'expiration',
      name: t('common.label.expiration'),
      decorator: (voucher: Voucher) => <Cell>{formatCalendarDate(voucher.expiresAt)}</Cell>
    },
    {
      key: 'used',
      name: t('common.label.use'),
      decorator: (voucher: Voucher) => <Cell>{getUsedName(voucher)}</Cell>
    },

    {
      key: 'comment',
      name: t('common.label.comment'),
      decorator: (voucher: Voucher) => (
        <Cell>
          <Text fontStyle="italic">{voucher.comment}</Text>
        </Cell>
      )
    },
    {
      key: 'category',
      name: t('common.label.type'),
      decorator: (voucher: Voucher) => <Cell>{getVoucherCategoryTraduction(voucher.category?.codeName!)}</Cell>
    }
  ]

  return (
    <CardLayout>
      <Title2>{t('page.customer.details.vouchers.title')}</Title2>

      <Tabs
        tabs={[
          {
            panelComponent: <AddVoucherForm customer={customer} />,
            tabLabel: t('page.customer.details.vouchers.add.title'),
            disabled: !checkMatch(permissions)
          },
          {
            tabLabel: _.isNumber(activeCount) ? (
              t('page.customer.details.vouchers.active.title', {
                count: activeCount,
                countText: activeCount.toFixed(2)
              })
            ) : (
              <TabNameContainer>
                {t('page.customer.details.vouchers.active.title_zero')}
                <Loader />
              </TabNameContainer>
            ),
            panelComponent: (
              <ActiveVouchers
                customer={customer}
                setCount={setActiveCount}
                columns={columns.filter((col) => col.key !== 'used')}
              />
            ),
            disabled: !activeCount
          },
          {
            panelComponent: <UsedVouchers customer={customer} columns={columns} />,
            tabLabel: t('page.customer.details.vouchers.used.title')
          }
        ]}
        idle={!checkMatch(permissions)}
      />
    </CardLayout>
  )
}
