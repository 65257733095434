import { Option } from 'components/Select/Select'
import { getFilterLabel } from 'hooks/useSelectOptions'
import { useTranslation } from 'react-i18next'

enum OrderPrintStatus {
  notPrinted = 'notPrinted',
  printed = 'printed',
  excludedPrint = 'excludedPrint'
}

export const useOrderPrintStatusOptions = () => {
  const { t } = useTranslation()

  const getPrintStatusTraduction = (status: OrderPrintStatus) =>
    ({
      [OrderPrintStatus.printed]: t('common.order.printStatus.printed'),
      [OrderPrintStatus.notPrinted]: t('common.order.printStatus.notPrinted'),
      [OrderPrintStatus.excludedPrint]: t('common.order.printStatus.excludedPrint')
    }[status])

  const orderPrintStatusOptions: Option[] = Object.values(OrderPrintStatus).map((value) => ({
    value,
    label: getPrintStatusTraduction(value)
  }))

  const getPrintStatusQueryParams = (value?: OrderPrintStatus) => {
    switch (value) {
      case OrderPrintStatus.excludedPrint:
        return { excludePrint: true }
      default:
        return { printed: value === OrderPrintStatus.printed }
    }
  }

  return {
    orderPrintStatusOptions,
    getPrintStatusFilter: (id, value?: OrderPrintStatus) => ({
      id,
      label: getFilterLabel(orderPrintStatusOptions, value)
    }),
    getPrintStatusQueryParams
  }
}
