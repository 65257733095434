import { useDownloadFile } from 'api/basePlayInApi'
import { FamilyStatus } from 'api/ranges'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Title2 } from 'components/Title/Title.styles'
import MultiLineTooltip from 'components/TooltipContents/MultiLineTooltip'
import { ExportIcon } from 'constants/icons'
import { useFamilyFilters } from 'hooks/useSelectOptions'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Store } from 'types/entities'
import { getIdFromIri } from 'utils/queryParams'
import { CardTitleContainer } from '../StoreLabels.styles'
import { RowContainer, StyledSelect } from './ProductsByFamily.styles'

type FormDate = {
  family: string
}

type Props = {
  store: Store
}

const ProductsByFamily = ({ store }: Props) => {
  const { t } = useTranslation()
  const { control, watch } = useForm<FormDate>()
  const { familyOptions } = useFamilyFilters({
    stores: store.id.toString(),
    'status[]': [FamilyStatus.OnlyBackoffice, FamilyStatus.FrontWoHomePage, FamilyStatus.FrontWithHomePage],
    'order[name]': 'asc'
  })
  const { downloadFile, isLoading } = useDownloadFile()

  const selectedFamillyIri = watch('family')

  const onExportClick = () => {
    downloadFile(`/exports/store-labels/${getIdFromIri(selectedFamillyIri)}/${store.id}`)
  }

  return (
    <CardLayout>
      <CardTitleContainer>
        <Title2>{t('page.store.labels.family.title')}</Title2>
        <MultiLineTooltip
          id="exportByfamily"
          lines={[t('page.store.labels.family.tooltips.line1'), t('page.store.labels.family.tooltips.line2')]}
        />
      </CardTitleContainer>
      <RowContainer>
        <StyledSelect
          id="family"
          control={control}
          options={familyOptions}
          label={t('common.label.familyRequired')}
          placeholder={t('common.placeholder.choose_range')}
        />
        <Button
          shape="circle"
          icon={ExportIcon}
          onClick={onExportClick}
          disabled={!selectedFamillyIri}
          isLoading={isLoading}
        />
      </RowContainer>
    </CardLayout>
  )
}

export default ProductsByFamily
