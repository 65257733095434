import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { useMutation } from 'hooks/useAxiosMutation'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey, useQueryClient } from 'react-query'
import { Role } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

/* -------------------------------------------------------------------------- */
/*                                    FETCH                                   */
/* -------------------------------------------------------------------------- */

export const roleByIdQueryKey = (id?: string | number) => ['role', `${id}`]

type FetchRolesParams = Api.GetCollaboratorRoles.RequestQuery

const getRolesUri = (params: FetchRolesParams): string => {
  const qs = stringify(params)
  return [API_ROUTES.roles.root, qs].join('?')
}
export const fetchRoleById = async (id: number | string) => {
  const res = await playInApi.get<Role>(`${API_ROUTES.roles.root}/${id}`)
  return res.data
}
export const usePaginatedRoles = (queryKey: QueryKey, params: FetchRolesParams, options?: PaginatedHookOptions<Role>) =>
  usePaginatedQuery<Role>(queryKey, getRolesUri(params), options)

/* -------------------------------------------------------------------------- */
/*                                    PATCH                                   */
/* -------------------------------------------------------------------------- */

type PatchRoleParams = Api.PatchCollaboratorRolesId.RequestBody
const patchRole = async (id: string | number, body: PatchRoleParams) => {
  const res = await playInApi.patch<PatchRoleParams, AxiosResponse<Role>>(`${API_ROUTES.roles.root}/${id}`, body, {
    headers: {
      'Content-Type': 'application/merge-patch+json'
    }
  })
  return res.data
}

interface UsePatchRoleOptions {
  callback?: () => void
  emitDefaultSuccessNotification?: boolean
}
export const usePatchRole = (id: string | number, options: UsePatchRoleOptions = {}) => {
  const queryClient = useQueryClient()
  const { callback, emitDefaultSuccessNotification } = options
  return useMutation((body: PatchRoleParams) => patchRole(id, body), {
    onSuccess: (updatedRole) => {
      queryClient.setQueryData(roleByIdQueryKey(id), updatedRole)
      if (callback) callback()
    },
    emitDefaultSuccessNotification: emitDefaultSuccessNotification ?? true
  })
}
