import Modal, { ModalProps } from 'components/Modal/Modal'
import NotAllowedText from 'components/Text/NotAllowedText'
import { Text } from 'components/Text/Text.styles'
import { MainTitleStyle } from 'components/Title/Title.styles'
import CanAccess from 'features/Permissions/CanAccess'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { RoleAction } from 'types/playInApiInterfaces'
import AddStockForm from './form/AddStockForm'
import { ModalLayout } from './StockCreateModal.style'

interface Props extends Omit<ModalProps, 'children'> {}

export default function StockCreateModal({ open, onClose }: Props) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose}>
      <Helmet defer={false}>
        <title>{t('page.stock.create.title')}</title>
      </Helmet>
      <ModalLayout>
        <div className="formContainer">
          <MainTitleStyle as="h2">{t('page.stock.create.new_buy')} </MainTitleStyle>
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_STOCK_CREATE]}
            allowedComponent={<AddStockForm closeModal={onClose} />}
            deniedComponent={<NotAllowedText />}
          />
        </div>
        <div>
          <MainTitleStyle as="h2">{t('page.stock.create.new_transfer')} </MainTitleStyle>
          <CanAccess
            permissions={[RoleAction.ROLE_ACTION_TRANSFER_CREATE]}
            allowedComponent={
              <Text color="secondary" fontStyle="italic">
                Section en cours de développement
              </Text>
            }
            deniedComponent={
              <Text color="secondary" fontStyle="italic">
                Section en cours de développement
              </Text>
            }
          />
        </div>
      </ModalLayout>
    </Modal>
  )
}
