import React, { createContext } from 'react'
import { EventEmitter } from 'utils/eventEmitter'

type PropsType = {}

export const eventEmitter = new EventEmitter()
export const EventEmitterContext = createContext<EventEmitter>(eventEmitter)

const EventEmitterWrapper: React.FunctionComponent<PropsType> = (props) => {
  return <EventEmitterContext.Provider value={eventEmitter}>{props.children}</EventEmitterContext.Provider>
}

export default EventEmitterWrapper
