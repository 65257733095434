import { Props as SelectProps } from 'components/Select/Select'
import { StyledPopover, TooltipContainer } from 'components/Tooltip/Tooltip.styles'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledSelect } from './ShippingSection.style'

type Props<T> = SelectProps<T> & {
  tooltipEnabled?: boolean
}

export default function ShippingModeSelect<T>({
  id,
  label,
  control,
  className,
  placeholder,
  options,
  disabled,
  tooltipEnabled
}: Props<T>) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)

  const handleClose = (event: SyntheticEvent) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const tooltipId = anchorEl ? id : undefined

  const onSelectClick = (event: SyntheticEvent) => {
    if (tooltipEnabled) {
      setAnchorEl(event.currentTarget)
      event.preventDefault()
    }
  }

  useEffect(() => {
    const id = setTimeout(() => setAnchorEl(null), 5000)
    return () => clearTimeout(id)
  }, [anchorEl])

  return (
    <div onClick={onSelectClick}>
      <StyledSelect
        placeholder={placeholder}
        label={label}
        control={control}
        id={id}
        options={options}
        className={className}
        disabled={disabled}
        enableUnselect
      />
      <StyledPopover
        id={tooltipId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <TooltipContainer>{t('page.order.detail.missingOrderEntryWarning')}</TooltipContainer>
      </StyledPopover>
    </div>
  )
}
