import { useGetIncomingCardEditions } from 'api/cardEditions'
import { fetchExcludedFromPrintOrders } from 'api/orders'
import { deletePrintExclusion, GetPrintExclusionsQueryResponse, getPrintExclusionsUri } from 'api/printExclusions'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox.styles'
import { ColumnContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import Loader from 'components/Loader/Loader'
import Cell from 'components/SortedTable/Cell'
import { Column } from 'components/SortedTable/SortedTable'
import Svg from 'components/Svg/Svg'
import { Text, TextWithIcon } from 'components/Text/Text.styles'
import { TooltipHover } from 'components/TooltipContents/HoverTooltip'
import Title2Tooltip from 'components/TooltipContents/Title2Tooltip'
import { TooltipColumnContainer } from 'components/TooltipContents/TootipContents.styles'
import { DeleteIcon, InfoIcon, WarningRedIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import { useMutation } from 'hooks/useAxiosMutation'
import { useFetchQuery } from 'hooks/useFetchQuery'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderItem, Store } from 'types/entities'
import { SortingDirection } from 'types/sorting'
import { formatCalendarDate } from 'utils/dates'
import { getIdFromIri } from 'utils/queryParams'
import ExcludedOrdersModal from './ExcludedOrdersModal'
import PrintExclusionForm from './PrintExclusionForm'
import { ActionButtonContainer, SmallActionButton } from './printExclusions.styles'

type Props = {
  store: Store
  active: boolean
}

const getExcludedOrderByEdition = (excludedOrders: OrderItem[] = [], editionIri?: string) => {
  return excludedOrders.filter((order) => order?.linkedEditions?.some((edition) => edition['@id'] === editionIri))
}

function PrintExclusionTab({ store, active }: Props) {
  const { t } = useTranslation()

  const [excludedEditionDetail, setExcludedEditionDetail] = useState<GetPrintExclusionsQueryResponse | undefined>()
  const [printExclusions, setPrintExclusions] = useState<GetPrintExclusionsQueryResponse[]>([])
  const [selectedEditions, setSelectedEditions] = useState<string[]>([])
  const { refetch } = useGetIncomingCardEditions(store, { enabled: active })

  const {
    isFetching: isFetchingPrintExclusions,
    isFetchingNextPage: isFetchingPrintExclusionsNextPage,
    isFetched: hasFetchedPrintExclusions,
    hasNextPage,
    fetchNextPage
  } = usePaginatedQuery<GetPrintExclusionsQueryResponse>(
    ['excluded-from-print-editions', store.id],
    getPrintExclusionsUri({
      store: `${store.id}`,
      pagination: false
    }),
    {
      enabled: active,
      onSuccess: (printExclusionEditions) => {
        setPrintExclusions(printExclusionEditions)
      }
    }
  )

  const { data: excludedOrders, isFetching } = useFetchQuery(
    ['excluded-from-print-orders', store.id, printExclusions.map((exclusion) => exclusion['@id'])],
    () => fetchExcludedFromPrintOrders(store.id),
    {
      enabled: active && !!printExclusions.length
    }
  )

  const columns: Array<Column<GetPrintExclusionsQueryResponse>> = [
    {
      name: '',
      key: 'isChecked',
      decorator: (item) => (
        <Cell>
          <Checkbox
            className={classnames({ checked: selectedEditions.includes(item['@id']!) })}
            onClick={() => setSelectedEditions((prev) => _.xor(prev, [item['@id']!]))}
          />
        </Cell>
      )
    },
    {
      name: t('common.label.releaseDate'),
      key: 'releasedAt',
      decorator: (item) => (
        <Cell>
          <TextWithIcon iconPosition="left">
            {item.edition?.releasedAt && new Date(item.edition?.releasedAt) < new Date() && (
              <Svg svg={WarningRedIcon} size="2rem" />
            )}
            {formatCalendarDate(item.edition?.releasedAt)}
          </TextWithIcon>
        </Cell>
      )
    },
    {
      name: t('common.label.cardEdition'),
      key: 'editionName',
      decorator: (item) => (
        <Cell>
          <Text>{item.edition?.name}</Text>
        </Cell>
      )
    },
    {
      name: t('page.order.print.details.totalOrders'),
      key: 'totalOrders',
      decorator: (item) => {
        if (isFetching) {
          return (
            <Cell>
              <Loader />
            </Cell>
          )
        }

        const nbOrder = getExcludedOrderByEdition(excludedOrders, item.edition?.['@id']).length
        return (
          <Cell>
            <Text color={nbOrder >= 10 ? 'black' : 'danger'} fontWeight={nbOrder >= 10 ? 'regular' : 'bold'}>
              {nbOrder}
            </Text>
          </Cell>
        )
      }
    },
    {
      name: t('page.order.print.details.excludedOrdersNumbers'),
      key: 'excludedOrders',
      decorator: (item) => (
        <Cell>
          {isFetching ? (
            <Loader />
          ) : (
            <SmallActionButton
              variant="white"
              icon={InfoIcon}
              shape="circle"
              iconSize="1.5rem"
              onClick={() => {
                setExcludedEditionDetail(item)
              }}
            />
          )}
        </Cell>
      )
    }
  ]

  const toggleAll = () => {
    setSelectedEditions((prev) =>
      prev.length === printExclusions.length ? [] : printExclusions.map((exclusion) => exclusion['@id']!)
    )
  }

  const { mutateAsync: removeExclusion, isLoading: isDeleteLoading } = useMutation(
    (exclusionId: string) => deletePrintExclusion(getIdFromIri(exclusionId)),
    {
      onSuccess: (_, removedExclusionId) => {
        setPrintExclusions((prev) => prev.filter((exclusion) => exclusion['@id'] !== removedExclusionId))
      }
    }
  )

  const removePrintExclusions = async () => {
    for (let editionExclusion of selectedEditions) {
      await removeExclusion(editionExclusion)
    }
    setSelectedEditions([])
    refetch()
  }

  return (
    <>
      <CardLayout>
        <Title2Tooltip
          title={t('page.order.print.details.editionsPreorder')}
          text={t('page.order.print.details.tooltips.editionsPreorder')}
        />
        <PrintExclusionForm setPrintExclusions={setPrintExclusions} active={active} store={store} />
      </CardLayout>
      {hasFetchedPrintExclusions && (
        <CardLayout>
          <Title2Tooltip
            title={t('page.order.print.details.exclusionsList')}
            text={
              <TooltipColumnContainer>
                <div>{t('page.order.print.details.tooltips.exclusionsList.line1')}</div>
                <div>{t('page.order.print.details.tooltips.exclusionsList.line2')}</div>
              </TooltipColumnContainer>
            }
          />
          <ColumnContainer>
            {hasFetchedPrintExclusions && printExclusions.length > 0 && (
              <>
                <ActionButtonContainer>
                  <Checkbox
                    className={classnames({ checked: selectedEditions.length === printExclusions.length })}
                    onClick={toggleAll}
                  />
                  <TooltipHover id="remove-exclusions-print" content={t('page.order.print.details.tooltips.delete')}>
                    <Button
                      icon={DeleteIcon}
                      shape="circle"
                      variant={'white'}
                      onClick={() => removePrintExclusions()}
                      disabled={!selectedEditions.length}
                      isLoading={isDeleteLoading}
                    />
                  </TooltipHover>
                </ActionButtonContainer>
                <Listing
                  columns={columns}
                  data={printExclusions}
                  totalCount={printExclusions.length}
                  hasNextPage={hasNextPage}
                  isFetching={isFetchingPrintExclusions}
                  isFetchingNextPage={isFetchingPrintExclusionsNextPage}
                  fetchNextPage={fetchNextPage}
                  countText={t('page.restock.management.currentCount', {
                    current: printExclusions?.length,
                    total: printExclusions.length ?? ''
                  })}
                  emptyText={t('page.restock.management.emptyPrint')}
                  defaultSort={{
                    sortedBy: 'id',
                    direction: SortingDirection.Desc
                  }}
                />
                <Text color="info">{t('page.order.print.details.tips.exclusionsList')}</Text>
              </>
            )}
            {hasFetchedPrintExclusions && printExclusions.length === 0 && (
              <Text color="danger">{t('page.order.print.details.errors.noExclusion')}</Text>
            )}
          </ColumnContainer>
        </CardLayout>
      )}
      <ExcludedOrdersModal
        open={!!excludedEditionDetail}
        onClose={() => setExcludedEditionDetail(undefined)}
        printExclusion={excludedEditionDetail!}
        orders={getExcludedOrderByEdition(excludedOrders, excludedEditionDetail?.edition?.['@id'])}
      />
    </>
  )
}

export default PrintExclusionTab
