import { usePaginatedCollectionCategories } from 'api/collectionCategories'
import { Filter } from 'components/FiltersList/FiltersList'
import { Option } from 'components/Select/Select'
import { getFilterLabel } from 'hooks/useSelectOptions'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export const getCollectionCategoriesKey = (id?: string) => ['collectionCategories', id]

export const useCollectionCategories = ({ isActive }: { isActive: boolean }) => {
  const { id } = useParams()
  const { t } = useTranslation()

  const params = { collection: id }
  const {
    data: collectionCategories,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage
  } = usePaginatedCollectionCategories(getCollectionCategoriesKey(id), params, {
    enabled: isActive && !!id
  })
  const collectionCategoriesOptions: Option[] = (collectionCategories ?? []).map((category) => ({
    value: category['@id'],
    label: category.name!
  }))

  const getCollectionCategoriesFilterList = (values?: string[]): Filter[] =>
    (values ?? []).map((value, index) => ({
      value,
      id: 'collectionCategories',
      filterId: `collectionCategory-${index}`,
      label: t('page.collection.details.form.filters.categories', {
        name: getFilterLabel(collectionCategoriesOptions, value)
      }) as string
    }))
  return {
    collectionCategoriesOptions,
    collectionCategoriesOptionsPagination: { isLoading, fetchNextPage, isFetchingNextPage, hasNextPage },
    getCollectionCategoriesFilterList,
    collectionCategories
  }
}
