import { isAfter as isAfterFn, isBefore as isBeforeFn, startOfToday } from 'date-fns'
import _ from 'lodash'
import { useEffect } from 'react'
import { FieldPath, FieldValues, UseFormTrigger, UseFormWatch } from 'react-hook-form'
import { TestConfig, TestContext, ValidationError } from 'yup'

export const getRequiredLabel = (label: string) => {
  return `${label}\u00a0*`
}

function createYupTest<T extends object>(
  defaultName: string,
  defaultPath: string | string[],
  testFn: (values: T, context: TestContext) => boolean
) {
  return (name: string = defaultName, path: string | string[] = defaultPath): TestConfig<T> => ({
    name,
    test: (values, context) => {
      if (testFn(values, context)) return true

      const errorPaths = Array.isArray(path) ? path : [path]

      return new ValidationError(
        errorPaths.map((errorPath) => new ValidationError('customError', undefined, errorPath, defaultName))
      )
    }
  })
}

const isDefined = (item: any) => item !== '' && item !== undefined && item !== null

const hasOneOfFields = (formData: Object): boolean => {
  return _.some(_.values(formData), (item) => isDefined(item))
}

function hasOneOfChecks<T extends object>(formData: T): boolean {
  return _.some(_.values(formData), Boolean)
}

function hasOnlyOneOfChecks<T extends object>(formData: T): boolean {
  return _.countBy(_.values(formData), (val: boolean) => val === true)?.true === 1
}

export const isHourAfterValidation = (date?: string, dateToCompare?: string) => {
  if (!date || !dateToCompare) {
    return true
  }

  try {
    let splittedDate = date.split(':').map((e) => parseInt(e) || 0)
    let splittedDateToCompare = dateToCompare.split(':').map((e) => parseInt(e) || 0)
    const today = new Date()

    return isAfterFn(
      new Date(today.setHours(splittedDate[0], splittedDate[1], 0)),
      new Date(today.setHours(splittedDateToCompare[0], splittedDateToCompare[1], 0))
    )
  } catch (error) {
    return false
  }
}
export const isTodayOrAfterValidation = (date?: string) => {
  if (!date) {
    return true
  }
  try {
    return !isBeforeFn(new Date(date), startOfToday())
  } catch (error) {
    return false
  }
}

export const hasOneOfChecksMethod = createYupTest('oneOfChecks', 'oneOfChecks', hasOneOfChecks)
export const hasOnlyOneOfChecksMethod = createYupTest('onlyOneOfChecks', 'onlyOneOfChecks', hasOnlyOneOfChecks)
export const hasOneOfFieldsMethod = createYupTest('required', 'oneOf', hasOneOfFields)
export const hasOneOfGroupFieldsMethod = (formKeys: string[]) =>
  createYupTest('required', formKeys, (values) => hasOneOfFields(_.pick(values, formKeys)))

export type SibblingFieldsKeys<T extends FieldValues = {}> = [FieldPath<T>, FieldPath<T>]

type SibblingFieldsFormProps<T extends FieldValues = {}> = {
  trigger: UseFormTrigger<T>
  watch: UseFormWatch<T>
}
export function useFormSibblingFields<T extends FieldValues>(
  keySet: SibblingFieldsKeys<T>[],
  formProps: SibblingFieldsFormProps<T>
) {
  const { watch, trigger } = formProps
  useEffect(() => {
    const subscription = watch((data, { name }) => {
      for (let keys of keySet) {
        if (keys.includes(name!)) {
          trigger(keys)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, trigger, keySet])
}
