import { FetchSealedProductsQueryParams, getSealedProductsUri, useSealedProductsPaginated } from 'api/sealedProducts'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { CardLayout } from 'components/Layouts/CardLayout/CardLayout.styles'
import { Section } from 'components/Section/Section.styles'
import { Column } from 'components/SortedTable/SortedTable'
import { Text } from 'components/Text/Text.styles'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import { CheckIcon, CrossIcon } from 'constants/icons'
import Listing from 'features/Listing/Listing'
import { RouteName } from 'permissions/permissions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation, useNavigate } from 'react-router'
import { SealedProduct } from 'types/entities'
import { OrderParam, SortingDirection } from 'types/sorting'
import { CreateSection, OldIcon, PackInfo, StyledClickableCell } from './ProductManagement.style'
import ProductSearchForm, { ProductFormInputs } from './form/ProductSearchForm'

const defaultSort: OrderParam<FetchSealedProductsQueryParams> = {
  sortedBy: 'old',
  direction: SortingDirection.Asc,
  queryParams: { 'order[old]': SortingDirection.Asc, 'order[name]': SortingDirection.Asc }
}

const addDefaultQueryParams = (queryParams: FetchSealedProductsQueryParams) => {
  let qp = { 'exists[secondHandOf]': false, ...queryParams }
  if (!qp['order[old]']) {
    qp = { 'order[old]': SortingDirection.Asc, ...qp }
  }

  if (!qp['order[name]']) {
    qp = { ...qp, 'order[name]': SortingDirection.Asc }
  }

  return qp
}

function ProductSearch() {
  const { t } = useTranslation()
  const location = useLocation() as Location & { state: null | { search?: string } }
  const navigate = useNavigate()
  const [searchParam, setSearchParam] = useState<FetchSealedProductsQueryParams>()
  const [orderParam, setOrderParam] = useState<OrderParam<FetchSealedProductsQueryParams>>(defaultSort)

  const [sidebarSearch, setSidebarSearch] = useState<boolean>(false)

  const {
    data: sealedProducts,
    totalItems,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    isIdle
  } = useSealedProductsPaginated(
    sidebarSearch
      ? ['sealedProduct:sideSearch', searchParam?.search]
      : ['sealedProduct', searchParam, orderParam?.queryParams],
    getSealedProductsUri(addDefaultQueryParams({ ...searchParam, ...orderParam?.queryParams })),
    {
      staleTime: 1000,
      enabled: !!searchParam,
      onSuccess: (data, total) => {
        // If we fetch only one product, redirect to his detail page
        if (total === 1) {
          navigate(`/product-details/${sealedProducts[0].id}`)
        }
      }
    }
  )

  useEffect(() => {
    // Listen search made from order sidebar search
    // if it change, update queryKey to match the request made by the search bar
    // and display results directly
    if (location.state) {
      setSidebarSearch(true)
      setSearchParam(location.state)

      // as we consumed current state, we reset it for futur reload
      navigate(`/product-manage`, { replace: true, state: null })
    }
  }, [location.state, navigate])

  const handleSearch = (formData: ProductFormInputs) => {
    const { category, subcategory, ...restData } = formData
    setSidebarSearch(false)
    setSearchParam({
      ...restData,
      topCategory: subcategory || category
    })
  }

  const handleSort = (orderParam: OrderParam<FetchSealedProductsQueryParams>) => {
    setOrderParam(orderParam)
    setSidebarSearch(false)
  }

  const columns: Array<Column<SealedProduct, FetchSealedProductsQueryParams>> = [
    {
      name: t('common.label.number_abbreviated'),
      key: 'id',
      decorator: (sealedProduct: SealedProduct) => (
        <StyledClickableCell
          className={classNames({ old: sealedProduct.old })}
          route={RouteName.ProductDetails}
          value={sealedProduct.id}
        >
          <Text color="secondary" fontWeight="light" fontStyle={sealedProduct.old ? 'italic' : 'normal'}>
            {sealedProduct.id}
          </Text>
        </StyledClickableCell>
      )
    },
    {
      name: t('common.label.active'),
      key: 'old',
      sortable: true,
      orderQueryParam: 'order[old]',
      decorator: (sealedProduct: SealedProduct) => (
        <StyledClickableCell
          className={classNames({ old: sealedProduct.old })}
          route={RouteName.ProductDetails}
          value={sealedProduct.id}
        >
          {sealedProduct.old ? (
            <div title={t('common.label.oldProduct')}>
              <OldIcon svg={CrossIcon} color="grey6" size="2rem" />
            </div>
          ) : (
            <OldIcon svg={CheckIcon} color="success" size="2rem" />
          )}
        </StyledClickableCell>
      )
    },
    {
      name: t('common.label.barcode'),
      key: 'barCode',
      decorator: (sealedProduct: SealedProduct) => (
        <StyledClickableCell
          className={classNames({ old: sealedProduct.old })}
          route={RouteName.ProductDetails}
          value={sealedProduct.id}
        >
          <Text fontWeight="light" color="secondary" fontStyle={sealedProduct.old ? 'italic' : 'normal'}>
            {sealedProduct.barCode}
          </Text>
        </StyledClickableCell>
      )
    },
    {
      name: t('common.label.productName'),
      key: 'name',
      sortable: true,
      orderQueryParam: 'order[name]',
      decorator: (sealedProduct: SealedProduct) => (
        <StyledClickableCell
          className={classNames({ old: sealedProduct.old })}
          route={RouteName.ProductDetails}
          value={sealedProduct.id}
        >
          <Text fontStyle={sealedProduct.old ? 'italic' : 'normal'}>
            {sealedProduct.pack && <PackInfo>{t('page.product.management.pack')}</PackInfo>}
            {sealedProduct.name}
          </Text>
        </StyledClickableCell>
      )
    },
    {
      name: t('common.label.range'),
      key: 'range',
      sortable: true,
      orderQueryParam: 'order[family.name]',
      decorator: (sealedProduct: SealedProduct) => (
        <StyledClickableCell
          className={classNames({ old: sealedProduct.old })}
          route={RouteName.ProductDetails}
          value={sealedProduct.id}
        >
          <Text fontStyle={sealedProduct.old ? 'italic' : 'normal'}>{sealedProduct.family?.name}</Text>
        </StyledClickableCell>
      )
    }
  ]

  return (
    <Section>
      <MainTitle title={t('page.product.management.title')} />
      <CardLayout>
        <CreateSection>
          <Title2 style={{ marginBottom: 0 }}>{t('page.product.management.create_title')}</Title2>

          <Button variant="white" disabled={true}>
            {t('page.product.management.create_product')}
          </Button>
        </CreateSection>
      </CardLayout>
      <ProductSearchForm onSearch={handleSearch} isSubmitting={isFetching} currentSearch={searchParam?.search} />
      {!isIdle && (
        <CardLayout>
          <Listing
            columns={columns}
            emptyText={t('page.product.management.empty')}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching && !totalItems}
            isFetchingNextPage={isFetchingNextPage}
            data={sealedProducts}
            title={t('page.product.management.search_count', { count: totalItems ?? 0 })}
            totalCount={totalItems}
            hasNextPage={hasNextPage}
            countText={t('page.product.management.search_current_count', {
              current: sealedProducts?.length,
              total: totalItems ?? 0
            })}
            onOrderChanged={handleSort}
            defaultSort={defaultSort}
          />
        </CardLayout>
      )}
    </Section>
  )
}

export default ProductSearch
